// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';
import { Status } from '@models';

@Component({
  selector: 'lfx-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent implements OnInit {
  iconType = 'circle';
  iconColor = 'danger';
  tooltipText = 'Unknown';

  @Input() iconSize = '1x';

  private currentStatus: Status;

  constructor() {}

  ngOnInit() {}

  get status(): Status {
    return this.currentStatus;
  }

  @Input() set status(s: Status) {
    this.currentStatus = s;

    switch (s) {
      case Status.Accepted: {
        this.iconType = 'circle';
        this.iconColor = 'success';
        this.tooltipText = 'Accepted';
        break;
      }

      case Status.Incomplete: {
        this.iconType = 'circle';
        this.iconColor = 'muted';
        this.tooltipText = 'Incomplete';
        break;
      }

      case Status.Pending: {
        this.iconType = 'circle';
        this.iconColor = 'warning';
        this.tooltipText = 'Pending';
        break;
      }

      case Status.AttentionRequired: {
        this.iconType = 'circle';
        this.iconColor = 'danger';
        this.tooltipText = 'Attention Required';
        break;
      }

      case Status.Locked: {
        this.iconType = 'lock';
        this.iconColor = 'secondary';
        this.tooltipText = 'Locked';
        break;
      }

      case Status.Unset: {
        this.iconType = '';
        this.iconColor = '';
        this.tooltipText = '';
        break;
      }
    }
  }
}
