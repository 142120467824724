// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss'],
})
export class PercentageBarComponent implements OnInit {
  @Input() sections: {
    name: string;
    percent: number;
    value: number;
    bgColor?: string;
  }[] = [];

  @Input() height = '36';
  @Input() showSectionText = false;
  @Input() hasBorderRadius = false;
  colorPalette = [
    '#9267db',
    '#b0db67',
    '#fa8d49',
    '#0068fa',
    '#bd6bff',
    '#46b6c7',
    '#ff3185',
    '#0039b8',
    '#ff7a00',
    '#ffba00',
  ];

  constructor() {}

  ngOnInit() {
    this.fillColor();
  }

  private fillColor() {
    this.sections.forEach(
      (section, index) => (section.bgColor = this.getColor(index))
    );
  }

  private getColor(index) {
    if (index < 10) {
      return this.colorPalette[index];
    }

    // if more than 10, return random color
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }
}
