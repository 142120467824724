// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Status } from '@models';

@Component({
  selector: 'lfx-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit, AfterViewInit {
  @Input() sectionId: string;
  @Input() type = 'primary';
  @Input() title: string;
  @Input() isCollapsed: boolean;
  @Input() overlay: string;
  @Input() status: Status = Status.Unset;
  @Input() toggle = true;
  @Input() set expand(value: void) {
    this.isExpanded = true;
  }

  @ViewChild(NgbAccordion) accordionRef: NgbAccordion;
  isOpened: boolean;
  isExpanded: boolean;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.isCollapsed) {
      this.accordionRef.collapseAll();
    }
  }

  toggleAccordion($event: NgbPanelChangeEvent) {
    if (!this.toggle) {
      $event.preventDefault();
    }
  }
}
