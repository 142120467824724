// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlFormat',
})
export class UrlFormatPipe implements PipeTransform {
  transform(value: any): any {
    if (!value.startsWith('https://' || 'http://')) {
      value = 'https://' + value;
    }

    return value;
  }
}
