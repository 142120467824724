// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const am5geodataWorldLow = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [179.2223, -8.554],
            [179.2023, -8.4653],
            [179.2312, -8.5048],
            [179.2223, -8.554],
          ],
        ],
      },
      properties: {
        name: 'Tuvalu',
        id: 'TV',
      },
      id: 'TV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [3.4624, -54.4471],
            [3.3461, -54.4511],
            [3.3669, -54.3997],
            [3.4814, -54.4001],
            [3.4624, -54.4471],
          ],
        ],
      },
      properties: {
        name: 'Bouvet Island',
        id: 'BV',
      },
      id: 'BV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-5.3345, 36.1623],
            [-5.3382, 36.1122],
            [-5.3562, 36.1264],
            [-5.3551, 36.1455],
            [-5.3345, 36.1623],
          ],
        ],
      },
      properties: {
        name: 'Gibraltar',
        id: 'GI',
      },
      id: 'GI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [47.3029, -11.5752],
            [47.282, -11.5747],
            [47.2789, -11.5574],
            [47.2971, -11.5543],
            [47.3029, -11.5752],
          ],
        ],
      },
      properties: {
        name: 'Glorioso Islands',
        id: 'GO',
      },
      id: 'GO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.7579, -17.075],
            [42.7415, -17.0741],
            [42.7379, -17.0519],
            [42.761, -17.0652],
            [42.7579, -17.075],
          ],
        ],
      },
      properties: {
        name: 'Juan De Nova Island',
        id: 'JU',
      },
      id: 'JU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-160.0213, -0.398],
            [-160.0453, -0.3802],
            [-160.0178, -0.3744],
            [-160.0213, -0.398],
          ],
        ],
      },
      properties: {
        name: 'Jarvis Island',
        id: 'UM-DQ',
      },
      id: 'UM-DQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-176.456, 0.2226],
            [-176.4615, 0.215],
            [-176.4675, 0.2195],
            [-176.456, 0.2226],
          ],
        ],
      },
      properties: {
        name: 'Baker Island',
        id: 'UM-FQ',
      },
      id: 'UM-FQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-176.6311, 0.7952],
            [-176.6433, 0.7934],
            [-176.6429, 0.8081],
            [-176.6329, 0.8085],
            [-176.6311, 0.7952],
          ],
        ],
      },
      properties: {
        name: 'Howland Island',
        id: 'UM-HQ',
      },
      id: 'UM-HQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-169.5237, 16.7305],
            [-169.5388, 16.7243],
            [-169.5388, 16.7296],
            [-169.5237, 16.7305],
          ],
        ],
      },
      properties: {
        name: 'Johnston Atoll',
        id: 'UM-JQ',
      },
      id: 'UM-JQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-177.3881, 28.1865],
            [-177.3879, 28.2144],
            [-177.3646, 28.2042],
            [-177.3881, 28.1865],
          ],
        ],
      },
      properties: {
        name: 'Midway Islands',
        id: 'UM-MQ',
      },
      id: 'UM-MQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [166.6588, 19.2829],
            [166.6087, 19.3073],
            [166.6277, 19.3246],
            [166.6588, 19.3117],
            [166.6588, 19.2829],
          ],
        ],
      },
      properties: {
        name: 'Wake Island',
        id: 'UM-WQ',
      },
      id: 'UM-WQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.2058, 12.1446],
            [-68.2873, 12.1717],
            [-68.3711, 12.2574],
            [-68.3694, 12.3018],
            [-68.2196, 12.2312],
            [-68.2058, 12.1446],
          ],
        ],
      },
      properties: {
        name: 'Bonair, Saint Eustachius and Saba',
        id: 'BQ',
      },
      id: 'BQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [4.2263, 51.3865],
              [3.9023, 51.2076],
              [3.35, 51.3776],
              [4.2263, 51.3865],
            ],
          ],
          [
            [
              [4.2263, 51.3865],
              [3.5871, 51.454],
              [4.4829, 52.3091],
              [4.7687, 52.9412],
              [5.1203, 52.3881],
              [5.5611, 52.5948],
              [5.3755, 53.0957],
              [6.0622, 53.4071],
              [6.8164, 53.4413],
              [7.1973, 53.2823],
              [7.0352, 52.3801],
              [6.7418, 51.9109],
              [5.9486, 51.7625],
              [6.1932, 51.4888],
              [5.8949, 50.9843],
              [5.9939, 50.7504],
              [5.7972, 50.7546],
              [5.6938, 50.7748],
              [5.827, 51.1257],
              [4.5033, 51.4746],
              [4.2263, 51.3865],
            ],
          ],
        ],
      },
      properties: {
        name: 'Netherlands',
        id: 'NL',
      },
      id: 'NL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [31.2877, -22.4021],
            [30.4603, -22.3289],
            [29.6631, -22.1465],
            [29.3648, -22.194],
            [29.0372, -21.8113],
            [28.0455, -21.5729],
            [27.6696, -21.0642],
            [27.6997, -20.5307],
            [27.2807, -20.4788],
            [27.2217, -20.1458],
            [26.6784, -19.8928],
            [26.1683, -19.5381],
            [25.7613, -18.6495],
            [25.2242, -17.9153],
            [25.2588, -17.7936],
            [25.8634, -17.9521],
            [27.0206, -17.9583],
            [27.9323, -16.8961],
            [28.7606, -16.5321],
            [28.9129, -15.9879],
            [29.7297, -15.6448],
            [30.3959, -15.643],
            [30.4377, -15.9954],
            [31.2362, -16.0239],
            [31.9398, -16.4287],
            [32.2434, -16.4487],
            [32.9479, -16.7123],
            [32.9932, -18.3596],
            [32.7166, -19.0019],
            [33.0069, -19.8737],
            [32.4778, -20.7131],
            [32.3713, -21.335],
            [31.2877, -22.4021],
          ],
        ],
      },
      properties: {
        name: 'Zimbabwe',
        id: 'ZW',
      },
      id: 'ZW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.9199, -9.4072],
            [33.3509, -9.8622],
            [33.659, -10.5906],
            [33.2613, -10.8934],
            [33.3798, -11.1579],
            [33.2262, -11.5348],
            [33.2524, -12.1127],
            [33.398, -12.49],
            [33.0216, -12.6303],
            [32.9679, -13.2251],
            [32.6722, -13.6104],
            [32.9812, -14.0095],
            [33.2018, -14.0135],
            [31.729, -14.496],
            [30.2317, -14.9905],
            [30.3959, -15.643],
            [29.7297, -15.6448],
            [28.9129, -15.9879],
            [28.7606, -16.5321],
            [27.9323, -16.8961],
            [27.0206, -17.9583],
            [25.8634, -17.9521],
            [25.2588, -17.7936],
            [24.9325, -17.5433],
            [24.2751, -17.4812],
            [23.3807, -17.6405],
            [22.1507, -16.5974],
            [21.9798, -15.9555],
            [21.9789, -13.0009],
            [23.8432, -13.0009],
            [23.9915, -12.4221],
            [23.9666, -10.8716],
            [24.32, -11.0718],
            [24.5184, -11.4384],
            [25.1847, -11.2431],
            [25.3493, -11.6231],
            [26.0258, -11.8903],
            [26.5962, -11.972],
            [26.9496, -11.8988],
            [27.0459, -11.616],
            [27.5333, -12.1953],
            [28.3576, -12.482],
            [29.0141, -13.3689],
            [29.5543, -13.2491],
            [29.7954, -13.3929],
            [29.7954, -12.1553],
            [29.4274, -12.4314],
            [29.0643, -12.3489],
            [28.4823, -11.8122],
            [28.3572, -11.4828],
            [28.639, -10.6692],
            [28.6301, -9.8311],
            [28.4007, -9.2248],
            [28.9178, -8.7005],
            [28.9724, -8.4648],
            [30.7511, -8.1936],
            [31.0764, -8.6117],
            [31.3508, -8.6069],
            [31.9425, -9.0539],
            [32.4334, -9.1564],
            [32.9199, -9.4072],
          ],
        ],
      },
      properties: {
        name: 'Zambia',
        id: 'ZM',
      },
      id: 'ZM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [31.2877, -22.4021],
            [31.5456, -23.4821],
            [31.7995, -23.8923],
            [31.986, -24.4605],
            [31.9482, -25.9577],
            [31.4151, -25.7464],
            [31.2074, -25.8432],
            [30.8034, -26.4136],
            [30.7941, -26.7643],
            [31.0636, -27.1123],
            [31.4697, -27.2956],
            [31.9584, -27.3058],
            [32.1129, -26.8393],
            [32.8862, -26.8495],
            [32.5346, -28.1998],
            [32.2856, -28.6215],
            [31.7782, -28.9371],
            [31.3352, -29.3779],
            [30.2885, -30.9701],
            [28.8561, -32.2943],
            [27.8604, -33.0538],
            [27.0774, -33.5212],
            [26.4293, -33.7596],
            [25.9894, -33.7112],
            [25.6383, -34.0112],
            [25.0031, -33.9735],
            [24.8273, -34.1688],
            [23.5858, -33.9851],
            [23.2684, -34.0814],
            [22.2456, -34.0694],
            [21.7889, -34.3726],
            [21.06, -34.3646],
            [20.53, -34.4631],
            [20.0204, -34.7858],
            [19.6351, -34.7534],
            [18.8086, -34.108],
            [18.3545, -33.9389],
            [18.4331, -33.7174],
            [17.8782, -32.9614],
            [18.2511, -32.652],
            [18.3105, -32.1225],
            [18.2107, -31.7425],
            [17.6776, -31.019],
            [17.1893, -30.0997],
            [16.95, -29.4036],
            [16.4475, -28.6175],
            [16.7556, -28.4524],
            [17.0561, -28.0311],
            [17.3855, -28.3534],
            [17.4481, -28.6983],
            [18.1028, -28.8719],
            [19.2458, -28.9016],
            [19.6715, -28.5039],
            [19.9805, -28.4515],
            [19.9805, -26.5312],
            [19.9805, -24.777],
            [20.3453, -25.03],
            [20.7932, -25.9155],
            [20.6268, -26.4438],
            [20.74, -26.849],
            [21.6464, -26.8544],
            [22.0907, -26.5801],
            [22.6403, -26.0714],
            [22.8786, -25.4579],
            [23.2662, -25.2666],
            [23.8938, -25.6008],
            [24.7483, -25.8174],
            [25.5837, -25.6062],
            [25.9122, -24.7477],
            [26.3974, -24.6136],
            [26.835, -24.2407],
            [27.1462, -23.5243],
            [27.8906, -23.0737],
            [28.2102, -22.6938],
            [28.8397, -22.4807],
            [29.3648, -22.194],
            [29.6631, -22.1465],
            [30.4603, -22.3289],
            [31.2877, -22.4021],
          ],
          [
            [27.8303, -28.9091],
            [27.2945, -29.5195],
            [27.0517, -29.6642],
            [27.3886, -30.3158],
            [28.018, -30.6421],
            [28.3922, -30.1476],
            [29.098, -29.919],
            [29.3905, -29.2696],
            [29.3013, -29.0898],
            [28.6257, -28.5816],
            [27.8303, -28.9091],
          ],
        ],
      },
      properties: {
        name: 'South Africa',
        id: 'ZA',
      },
      id: 'ZA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [53.0855, 16.6484],
            [52.3278, 16.2937],
            [52.2177, 15.6554],
            [51.6038, 15.3367],
            [50.5269, 15.0384],
            [50.1669, 14.8511],
            [49.3497, 14.638],
            [48.6683, 14.0503],
            [47.9901, 14.0481],
            [47.4077, 13.6614],
            [46.7889, 13.4657],
            [45.6574, 13.3387],
            [45.0386, 12.8158],
            [44.6178, 12.8171],
            [43.9298, 12.6165],
            [43.4752, 12.8389],
            [43.232, 13.2672],
            [43.2826, 13.6397],
            [43.089, 14.0108],
            [43.0211, 14.555],
            [42.7171, 15.6545],
            [42.7992, 16.3718],
            [43.1649, 16.6892],
            [43.1911, 17.3595],
            [43.418, 17.5162],
            [43.9169, 17.3249],
            [44.1557, 17.3986],
            [45.1483, 17.4274],
            [45.4066, 17.32],
            [46.3104, 17.2312],
            [46.6819, 17.2685],
            [46.9758, 16.9533],
            [47.4418, 17.1118],
            [47.5795, 17.4483],
            [48.172, 18.1567],
            [49.0421, 18.582],
            [51.9775, 18.9961],
            [52.729, 17.3005],
            [53.0855, 16.6484],
          ],
        ],
      },
      properties: {
        name: 'Yemen',
        id: 'YE',
      },
      id: 'YE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-172.3335, -13.4653],
            [-172.225, -13.8044],
            [-172.5357, -13.7915],
            [-172.7441, -13.5789],
            [-172.3335, -13.4653],
          ],
        ],
      },
      properties: {
        name: 'Samoa',
        id: 'WS',
      },
      id: 'WS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-178.0467, -14.3184],
            [-178.1585, -14.3118],
            [-178.1423, -14.2425],
            [-178.0467, -14.3184],
          ],
        ],
      },
      properties: {
        name: 'Wallis and Futuna',
        id: 'WF',
      },
      id: 'WF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [34.2454, 31.2085],
              [34.1983, 31.3226],
              [34.4775, 31.5849],
              [34.2454, 31.2085],
            ],
          ],
          [
            [
              [35.4505, 31.4793],
              [34.9507, 31.6022],
              [34.9561, 32.1611],
              [35.1931, 32.5344],
              [35.5513, 32.3955],
              [35.5589, 31.7656],
              [35.4505, 31.4793],
            ],
          ],
        ],
      },
      properties: {
        name: 'Palestinian Territories',
        id: 'PS',
      },
      id: 'PS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [166.7458, -14.8267],
              [166.8102, -15.1574],
              [167.1316, -15.1352],
              [167.1995, -15.4859],
              [166.8257, -15.635],
              [166.6313, -15.406],
              [166.5274, -14.8502],
              [166.7458, -14.8267],
            ],
          ],
          [
            [
              [167.4125, -16.0958],
              [167.8365, -16.4495],
              [167.4494, -16.5552],
              [167.4125, -16.0958],
            ],
          ],
        ],
      },
      properties: {
        name: 'Vanuatu',
        id: 'VU',
      },
      id: 'VU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [107.9727, 21.5081],
            [107.4099, 21.2848],
            [107.3544, 21.0553],
            [106.6757, 20.9603],
            [106.7534, 20.7348],
            [106.5181, 20.2887],
            [105.9841, 19.9389],
            [105.6219, 18.9664],
            [105.8882, 18.5025],
            [106.499, 17.9463],
            [106.479, 17.7195],
            [107.1804, 16.8978],
            [108.0296, 16.331],
            [108.8215, 15.378],
            [109.0847, 14.7161],
            [109.3035, 13.8563],
            [109.272, 13.2792],
            [109.4238, 12.9561],
            [109.2187, 12.6458],
            [109.1988, 11.7247],
            [108.8206, 11.3155],
            [108.2715, 10.9342],
            [107.2616, 10.3984],
            [106.8128, 10.4334],
            [106.5723, 9.6411],
            [105.5011, 9.0933],
            [105.3223, 8.8013],
            [104.8921, 8.5833],
            [104.8184, 8.8017],
            [104.8451, 9.606],
            [105.028, 10.0672],
            [104.6635, 10.1698],
            [104.4265, 10.4112],
            [104.8504, 10.5347],
            [105.3866, 10.9399],
            [106.1679, 11.0123],
            [105.8558, 11.2941],
            [105.8514, 11.6351],
            [106.3401, 11.6817],
            [106.4138, 11.9484],
            [106.9305, 12.0776],
            [107.5062, 12.3644],
            [107.4756, 13.0302],
            [107.6056, 13.4377],
            [107.3313, 14.1266],
            [107.5195, 14.705],
            [107.6531, 15.255],
            [107.1897, 15.7473],
            [107.3966, 16.0429],
            [106.6961, 16.4588],
            [106.5021, 16.9542],
            [105.6916, 17.7377],
            [105.4581, 18.1545],
            [105.0857, 18.4501],
            [105.115, 18.6787],
            [104.7163, 18.8035],
            [103.8965, 19.3401],
            [104.0319, 19.6753],
            [104.5463, 19.6105],
            [104.9281, 20.018],
            [104.6187, 20.3744],
            [104.5832, 20.6465],
            [104.0518, 20.9413],
            [103.635, 20.6971],
            [103.1046, 20.8915],
            [102.8511, 21.2657],
            [102.9488, 21.5698],
            [102.1276, 22.379],
            [102.5173, 22.7408],
            [103.0051, 22.4532],
            [103.3008, 22.7643],
            [104.3719, 22.704],
            [104.7958, 22.9112],
            [104.8646, 23.1363],
            [105.2752, 23.3454],
            [105.8429, 22.9228],
            [106.5421, 22.9081],
            [106.7804, 22.779],
            [106.5363, 22.3954],
            [106.7294, 22.0004],
            [107.3513, 21.6089],
            [107.9727, 21.5081],
          ],
        ],
      },
      properties: {
        name: 'Vietnam',
        id: 'VN',
      },
      id: 'VN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-64.7656, 17.7945],
            [-64.6862, 17.7062],
            [-64.889, 17.7017],
            [-64.8848, 17.7723],
            [-64.7656, 17.7945],
          ],
        ],
      },
      properties: {
        name: 'US Virgin Islands',
        id: 'VI',
      },
      id: 'VI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-64.3952, 18.4648],
            [-64.4261, 18.5132],
            [-64.3246, 18.5176],
            [-64.3952, 18.4648],
          ],
        ],
      },
      properties: {
        name: 'British Virgin Islands',
        id: 'VG',
      },
      id: 'VG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-60.0175, 8.5491],
            [-59.8315, 8.3059],
            [-60.0324, 8.0537],
            [-60.5136, 7.8131],
            [-60.7191, 7.4989],
            [-60.3951, 6.9453],
            [-61.1457, 6.6945],
            [-61.1286, 6.2142],
            [-61.3767, 5.9071],
            [-60.7422, 5.2022],
            [-60.6046, 4.9944],
            [-61.0028, 4.5354],
            [-62.1531, 4.0982],
            [-62.7122, 4.0179],
            [-62.9685, 3.594],
            [-63.2948, 3.9224],
            [-64.0214, 3.9291],
            [-64.2556, 4.1404],
            [-64.5679, 3.8998],
            [-64.2212, 3.5873],
            [-64.2188, 3.2047],
            [-63.9242, 2.4523],
            [-63.3893, 2.4119],
            [-63.394, 2.2223],
            [-64.0354, 1.9045],
            [-64.1149, 1.6191],
            [-65.1037, 1.1082],
            [-65.3609, 0.8685],
            [-65.6814, 0.9834],
            [-66.06, 0.7854],
            [-66.4293, 0.8218],
            [-66.8761, 1.2231],
            [-67.2108, 2.3901],
            [-67.8347, 2.8926],
            [-67.3111, 3.416],
            [-67.6616, 3.8643],
            [-67.8553, 4.507],
            [-67.8249, 5.2705],
            [-67.4394, 6.0256],
            [-67.8591, 6.2897],
            [-68.4717, 6.1565],
            [-69.4392, 6.1348],
            [-70.095, 6.9378],
            [-70.6552, 7.0829],
            [-71.0134, 6.9946],
            [-72.0843, 7.0967],
            [-72.443, 7.4549],
            [-72.4166, 8.3818],
            [-72.6654, 8.6277],
            [-72.7963, 9.1089],
            [-73.3563, 9.2269],
            [-73.0065, 9.7894],
            [-72.8693, 10.4911],
            [-72.6902, 10.8361],
            [-72.2485, 11.1965],
            [-71.958, 11.6666],
            [-71.3197, 11.8619],
            [-71.8686, 11.6275],
            [-71.9469, 11.4144],
            [-71.5942, 10.6572],
            [-72.1129, 9.8155],
            [-71.6868, 9.0725],
            [-71.2979, 9.1257],
            [-71.0858, 9.3481],
            [-71.0527, 9.7059],
            [-71.3867, 10.2639],
            [-71.5445, 10.7788],
            [-71.4695, 10.9643],
            [-70.2326, 11.3732],
            [-70.2865, 11.8859],
            [-70.004, 12.1779],
            [-69.6316, 11.4801],
            [-68.8279, 11.4318],
            [-68.3987, 11.161],
            [-68.234, 10.5693],
            [-67.8715, 10.4721],
            [-66.9891, 10.6106],
            [-66.2471, 10.6323],
            [-65.8518, 10.2577],
            [-65.1292, 10.0699],
            [-64.8504, 10.0979],
            [-63.8734, 10.6638],
            [-62.7024, 10.7499],
            [-62.8429, 10.4179],
            [-62.5151, 10.176],
            [-60.7923, 9.3606],
            [-61.0531, 9.0951],
            [-61.3039, 8.4102],
            [-60.801, 8.5922],
            [-60.0175, 8.5491],
          ],
        ],
      },
      properties: {
        name: 'Venezuela',
        id: 'VE',
      },
      id: 'VE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.1745, 13.1581],
            [-61.2773, 13.2095],
            [-61.1821, 13.356],
            [-61.1239, 13.2939],
            [-61.1745, 13.1581],
          ],
        ],
      },
      properties: {
        name: 'Saint Vincent and the Grenadines',
        id: 'VC',
      },
      id: 'VC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.4392, 41.8983],
            [12.4307, 41.8974],
            [12.4307, 41.9054],
            [12.4383, 41.9063],
            [12.4392, 41.8983],
          ],
        ],
      },
      properties: {
        name: 'Vatican City',
        id: 'VA',
      },
      id: 'VA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [70.9468, 42.2485],
            [70.8421, 42.0195],
            [70.1811, 41.5716],
            [70.7826, 41.2626],
            [71.4085, 41.1361],
            [71.7924, 41.4131],
            [72.6585, 40.8698],
            [73.113, 40.7859],
            [71.6926, 40.1525],
            [70.9579, 40.239],
            [70.3826, 40.4534],
            [70.7511, 40.7397],
            [70.4417, 41.0234],
            [69.7128, 40.6572],
            [69.2063, 40.5666],
            [69.2281, 40.1875],
            [68.6528, 40.1827],
            [68.4632, 39.5368],
            [67.7193, 39.6216],
            [67.3495, 39.242],
            [67.6767, 39.0086],
            [68.1037, 38.9619],
            [68.0478, 38.6694],
            [68.2941, 38.0329],
            [67.8143, 37.4869],
            [67.7592, 37.1722],
            [67.069, 37.3346],
            [66.5221, 37.3484],
            [66.5745, 38.0107],
            [65.8572, 38.2686],
            [65.613, 38.2384],
            [64.1628, 38.9535],
            [62.6505, 39.8586],
            [61.903, 41.0935],
            [60.455, 41.2218],
            [60.0897, 41.3994],
            [60.107, 41.9076],
            [59.8584, 42.2951],
            [59.4509, 42.2996],
            [58.5889, 42.7785],
            [58.2595, 42.688],
            [57.8143, 42.1899],
            [57.3819, 42.1562],
            [56.9642, 41.8566],
            [57.0179, 41.2635],
            [55.9774, 41.3221],
            [55.9766, 42.929],
            [55.9757, 44.9949],
            [58.5551, 45.5555],
            [61.0081, 44.3939],
            [61.1608, 44.1688],
            [61.9904, 43.4923],
            [63.2071, 43.6281],
            [64.4434, 43.5513],
            [64.9054, 43.7147],
            [65.4963, 43.3108],
            [65.803, 42.8771],
            [66.1004, 42.9907],
            [66.0094, 42.0048],
            [66.4986, 41.9951],
            [66.6686, 41.2706],
            [66.8142, 41.1423],
            [67.9359, 41.1965],
            [68.2919, 40.6563],
            [69.0652, 41.367],
            [70.0954, 41.8206],
            [70.4159, 42.0785],
            [70.9468, 42.2485],
          ],
        ],
      },
      properties: {
        name: 'Uzbekistan',
        id: 'UZ',
      },
      id: 'UZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-166.1355, 60.3835],
              [-165.7298, 60.3143],
              [-165.5917, 59.9132],
              [-166.1876, 59.7738],
              [-167.1389, 60.0086],
              [-166.1355, 60.3835],
            ],
          ],
          [
            [
              [-171.4631, 63.6401],
              [-170.4304, 63.6989],
              [-169.7778, 63.0937],
              [-171.4631, 63.6401],
            ],
          ],
          [
            [
              [-141.0021, 69.6509],
              [-141.0021, 68.4819],
              [-141.0021, 65.56],
              [-141.0021, 63.2222],
              [-141.0021, 60.3003],
              [-139.1852, 60.0837],
              [-137.5932, 59.2263],
              [-137.4385, 58.9031],
              [-136.5787, 59.1522],
              [-136.3219, 59.6049],
              [-135.4759, 59.7934],
              [-134.9439, 59.2882],
              [-133.8208, 58.7049],
              [-131.8661, 56.7929],
              [-131.5752, 56.5989],
              [-130.4772, 56.2307],
              [-130.0251, 55.8882],
              [-130.0366, 55.2978],
              [-130.2142, 55.026],
              [-130.5753, 54.7696],
              [-131.0478, 55.1576],
              [-130.9186, 55.736],
              [-131.8439, 56.1601],
              [-132.8143, 57.0408],
              [-133.4659, 57.1722],
              [-133.5543, 57.6951],
              [-134.0634, 58.2111],
              [-134.7761, 58.4539],
              [-135.3025, 58.2559],
              [-135.8618, 58.5771],
              [-136.5827, 58.2453],
              [-137.544, 58.5811],
              [-138.3525, 59.0873],
              [-140.2168, 59.7266],
              [-140.8432, 59.749],
              [-141.6702, 59.9698],
              [-142.9457, 60.097],
              [-143.8051, 60.0129],
              [-144.9012, 60.3351],
              [-145.8989, 60.4783],
              [-146.8741, 61.005],
              [-148.3445, 60.8536],
              [-148.1976, 60.1678],
              [-148.4306, 59.9891],
              [-149.4323, 60.0011],
              [-150.9608, 59.244],
              [-151.7383, 59.1886],
              [-151.7345, 59.9882],
              [-151.4515, 60.2026],
              [-151.3217, 60.7428],
              [-150.4412, 61.0236],
              [-150.6124, 61.3013],
              [-151.7341, 60.9106],
              [-151.7505, 60.7548],
              [-153.1061, 59.875],
              [-154.0883, 59.3632],
              [-154.1298, 59.12],
              [-153.327, 58.8843],
              [-154.0199, 58.493],
              [-154.2469, 58.1594],
              [-155.0069, 58.016],
              [-156.4784, 57.328],
              [-156.4751, 57.1051],
              [-157.5784, 56.6344],
              [-158.4144, 56.4358],
              [-158.2757, 56.1963],
              [-159.5233, 55.8101],
              [-159.9623, 55.7948],
              [-161.0995, 55.4057],
              [-161.7416, 55.3911],
              [-162.0741, 55.1394],
              [-162.8651, 54.9545],
              [-162.9621, 55.1838],
              [-162.1571, 55.7195],
              [-161.2156, 56.0214],
              [-160.8514, 55.7719],
              [-160.3021, 56.3141],
              [-159.2831, 56.6886],
              [-158.321, 57.2978],
              [-157.6077, 57.6014],
              [-157.5235, 58.4213],
              [-158.5032, 58.8503],
              [-159.0827, 58.4697],
              [-159.6702, 58.9111],
              [-160.3631, 59.0512],
              [-161.7554, 58.6119],
              [-161.8286, 59.5887],
              [-162.2425, 60.1782],
              [-163.2193, 59.8455],
              [-163.9069, 59.8067],
              [-164.6622, 60.3038],
              [-165.3538, 60.5413],
              [-164.8051, 60.892],
              [-165.566, 61.1024],
              [-166.0789, 61.8031],
              [-165.1944, 62.4736],
              [-164.409, 63.2151],
              [-163.3589, 63.0458],
              [-161.974, 63.453],
              [-161.0997, 63.558],
              [-160.7786, 63.819],
              [-160.932, 64.5792],
              [-161.7594, 64.8162],
              [-162.807, 64.3743],
              [-163.7132, 64.5883],
              [-164.8995, 64.4607],
              [-166.1428, 64.5827],
              [-167.4039, 65.4221],
              [-168.0884, 65.6578],
              [-164.6742, 66.5549],
              [-163.7755, 66.5312],
              [-163.6954, 66.0838],
              [-161.8164, 66.0536],
              [-161.557, 66.2504],
              [-160.2317, 66.4202],
              [-160.864, 66.6708],
              [-161.8566, 66.7003],
              [-161.9653, 67.0497],
              [-163.0016, 67.0272],
              [-163.7205, 67.1955],
              [-164.1251, 67.6067],
              [-166.3805, 68.4251],
              [-166.2092, 68.8854],
              [-164.3024, 68.9364],
              [-163.1614, 69.3879],
              [-162.9521, 69.7581],
              [-161.881, 70.3318],
              [-160.9963, 70.3045],
              [-159.3144, 70.8785],
              [-157.9985, 70.8454],
              [-156.7833, 71.319],
              [-155.1669, 71.0993],
              [-154.1952, 70.801],
              [-153.2329, 70.9326],
              [-152.233, 70.8103],
              [-151.9447, 70.4521],
              [-149.2694, 70.5007],
              [-147.7053, 70.2173],
              [-145.8232, 70.16],
              [-145.1973, 70.0086],
              [-143.2184, 70.1163],
              [-141.4078, 69.6533],
              [-141.0021, 69.6509],
            ],
          ],
          [
            [
              [-155.5813, 19.0121],
              [-155.8814, 19.0707],
              [-156.0487, 19.7498],
              [-155.8316, 20.2759],
              [-155.1989, 19.9944],
              [-154.8043, 19.5244],
              [-155.5813, 19.0121],
            ],
          ],
          [
            [
              [-72.5098, 40.9861],
              [-73.1943, 40.6541],
              [-73.5738, 40.9195],
              [-72.5098, 40.9861],
            ],
          ],
          [
            [
              [-93.3778, 48.6166],
              [-92.9963, 48.6117],
              [-91.5183, 48.0582],
              [-90.8403, 48.2007],
              [-89.4556, 47.996],
              [-88.3782, 48.3032],
              [-84.8759, 46.9001],
              [-84.5617, 46.4575],
              [-84.1921, 46.5494],
              [-83.9777, 46.0851],
              [-83.5926, 45.817],
              [-82.5511, 45.3473],
              [-82.1378, 43.5709],
              [-82.5453, 42.6245],
              [-83.0731, 42.3005],
              [-83.1419, 41.976],
              [-82.439, 41.675],
              [-81.2778, 42.209],
              [-80.2475, 42.3662],
              [-79.0368, 42.8025],
              [-79.1719, 43.4666],
              [-78.7205, 43.625],
              [-76.82, 43.6286],
              [-76.4646, 44.0574],
              [-75.1793, 44.8994],
              [-74.7088, 45.0038],
              [-71.5175, 45.0078],
              [-71.3272, 45.2901],
              [-70.898, 45.2626],
              [-70.2963, 45.9062],
              [-70.0078, 46.7088],
              [-69.243, 47.4629],
              [-68.8288, 47.2032],
              [-68.311, 47.3546],
              [-67.8067, 47.083],
              [-67.7845, 45.7016],
              [-67.4328, 45.603],
              [-67.4725, 45.2759],
              [-67.1249, 45.1693],
              [-67.1067, 44.8852],
              [-67.9071, 44.4538],
              [-68.2775, 44.5075],
              [-69.7916, 43.8053],
              [-70.1789, 43.7662],
              [-70.829, 42.8251],
              [-71.0463, 42.3311],
              [-70.7382, 42.229],
              [-70.4046, 41.6271],
              [-70.6572, 41.5343],
              [-71.4265, 41.6333],
              [-71.5228, 41.3789],
              [-72.8471, 41.2657],
              [-73.5831, 41.022],
              [-74.2267, 40.6079],
              [-73.9575, 40.3282],
              [-74.1761, 39.7268],
              [-74.4289, 39.3872],
              [-74.897, 39.1453],
              [-75.3922, 39.0929],
              [-75.036, 38.5034],
              [-75.6592, 37.9539],
              [-76.2948, 38.4945],
              [-76.2635, 37.3568],
              [-75.8905, 36.6568],
              [-76.4788, 36.0283],
              [-75.7722, 35.9],
              [-75.7739, 35.647],
              [-76.513, 35.2705],
              [-76.7449, 34.9412],
              [-77.7508, 34.2851],
              [-78.0134, 33.9118],
              [-78.5778, 33.8732],
              [-78.9202, 33.6588],
              [-79.276, 33.1354],
              [-80.8492, 32.114],
              [-81.5203, 30.8747],
              [-81.2496, 29.7938],
              [-80.9, 29.0498],
              [-80.524, 28.4861],
              [-80.6501, 28.1811],
              [-80.2262, 27.2072],
              [-80.0413, 26.5685],
              [-80.1263, 25.8334],
              [-80.3669, 25.3313],
              [-80.7366, 25.1565],
              [-81.0978, 25.3194],
              [-81.365, 25.8312],
              [-81.7154, 25.983],
              [-81.8666, 26.4349],
              [-82.4414, 27.0599],
              [-82.6609, 27.7186],
              [-82.8436, 27.846],
              [-82.6607, 28.4857],
              [-82.6516, 28.8874],
              [-83.2906, 29.452],
              [-83.6943, 29.9261],
              [-84.0443, 30.1036],
              [-84.3828, 29.9074],
              [-85.3189, 29.6802],
              [-85.3536, 29.8759],
              [-86.2573, 30.4929],
              [-87.7903, 30.2918],
              [-88.9051, 30.4152],
              [-89.5885, 30.1658],
              [-90.2253, 30.3793],
              [-90.4131, 30.1405],
              [-89.5894, 29.915],
              [-90.1014, 29.1817],
              [-90.3793, 29.2953],
              [-90.7511, 29.1311],
              [-91.2902, 29.2891],
              [-91.331, 29.5137],
              [-91.8932, 29.836],
              [-92.2608, 29.5568],
              [-93.1756, 29.7792],
              [-93.6947, 29.7698],
              [-94.8884, 29.3708],
              [-95.2734, 28.9637],
              [-95.8534, 28.6401],
              [-96.4487, 28.5944],
              [-96.5616, 28.3671],
              [-97.1564, 28.1443],
              [-97.1715, 27.8797],
              [-97.5546, 26.9675],
              [-97.4024, 26.3967],
              [-97.1462, 25.9617],
              [-97.3582, 25.8707],
              [-98.0828, 26.0642],
              [-99.1078, 26.4469],
              [-99.4436, 27.0368],
              [-99.5053, 27.5482],
              [-100.2961, 28.3276],
              [-100.6587, 29.0685],
              [-101.4404, 29.7769],
              [-102.269, 29.871],
              [-102.7342, 29.6438],
              [-102.892, 29.2163],
              [-103.2577, 29.001],
              [-104.1107, 29.3863],
              [-104.504, 29.6775],
              [-104.9179, 30.5835],
              [-106.2558, 31.5445],
              [-106.4533, 31.77],
              [-108.2118, 31.7793],
              [-108.2145, 31.3292],
              [-111.042, 31.3243],
              [-112.939, 31.916],
              [-114.836, 32.5082],
              [-114.7248, 32.7155],
              [-117.1282, 32.5335],
              [-117.4674, 33.2957],
              [-118.5063, 34.0174],
              [-119.1437, 34.112],
              [-119.6061, 34.4178],
              [-120.4812, 34.4715],
              [-120.6448, 34.5798],
              [-120.6337, 35.0766],
              [-120.8603, 35.3655],
              [-121.8774, 36.331],
              [-121.79, 36.7323],
              [-122.3948, 37.2077],
              [-122.4669, 37.838],
              [-122.9681, 38.0968],
              [-122.9865, 38.277],
              [-123.7012, 38.9073],
              [-123.8845, 39.8608],
              [-124.324, 40.2519],
              [-124.3717, 40.4912],
              [-124.0685, 41.3843],
              [-124.5397, 42.8127],
              [-124.1486, 43.6916],
              [-123.9293, 45.5768],
              [-124.0716, 46.7447],
              [-124.3761, 47.6587],
              [-124.6211, 47.9042],
              [-124.6327, 48.3751],
              [-123.9757, 48.1683],
              [-122.2421, 48.0107],
              [-122.7888, 48.993],
              [-120.1281, 48.993],
              [-118.4226, 48.993],
              [-115.8643, 48.993],
              [-112.4532, 48.993],
              [-109.895, 48.993],
              [-106.4839, 48.993],
              [-103.9256, 48.993],
              [-102.2201, 48.993],
              [-99.2354, 48.993],
              [-97.5297, 48.9932],
              [-95.162, 48.9917],
              [-94.6209, 48.7427],
              [-93.7078, 48.5256],
              [-93.3778, 48.6166],
            ],
          ],
          [
            [
              [-163.4761, 54.9807],
              [-163.3581, 54.7357],
              [-164.5907, 54.4043],
              [-164.8877, 54.6078],
              [-164.5297, 54.8808],
              [-163.4761, 54.9807],
            ],
          ],
          [
            [
              [-130.9792, 55.4892],
              [-131.0827, 55.2668],
              [-131.8111, 55.223],
              [-131.6249, 55.8316],
              [-130.9792, 55.4892],
            ],
          ],
          [
            [
              [-133.566, 56.3392],
              [-133.2029, 56.3199],
              [-131.9764, 55.2086],
              [-131.9809, 54.8049],
              [-132.7823, 55.0484],
              [-133.4471, 55.797],
              [-133.566, 56.3392],
            ],
          ],
          [
            [
              [-133.3663, 57.0035],
              [-132.9541, 56.8803],
              [-133.2127, 56.4646],
              [-133.6029, 56.4642],
              [-133.8661, 57.0687],
              [-133.3663, 57.0035],
            ],
          ],
          [
            [
              [-134.9698, 57.3515],
              [-134.6207, 56.7183],
              [-135.787, 57.3173],
              [-134.9698, 57.3515],
            ],
          ],
          [
            [
              [-152.898, 57.8238],
              [-152.4121, 57.4547],
              [-153.7933, 56.9895],
              [-154.3391, 56.9209],
              [-154.7059, 57.3353],
              [-154.2813, 57.638],
              [-153.8184, 57.5956],
              [-152.898, 57.8238],
            ],
          ],
          [
            [
              [-135.7304, 58.2442],
              [-135.002, 58.0511],
              [-135.0848, 57.5111],
              [-136.0767, 57.6747],
              [-136.5123, 58.0959],
              [-135.7304, 58.2442],
            ],
          ],
          [
            [
              [-134.6802, 58.1616],
              [-133.9611, 57.6143],
              [-133.9253, 57.3369],
              [-134.4354, 57.057],
              [-134.66, 57.638],
              [-134.6802, 58.1616],
            ],
          ],
        ],
      },
      properties: {
        name: 'United States',
        id: 'US',
      },
      id: 'US',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-53.3707, -33.7422],
            [-53.7853, -34.3806],
            [-54.1686, -34.6709],
            [-54.9023, -34.9328],
            [-55.6732, -34.7756],
            [-56.1179, -34.9079],
            [-56.8552, -34.6766],
            [-57.1706, -34.4525],
            [-57.8291, -34.4773],
            [-58.4002, -33.9123],
            [-58.3534, -33.2602],
            [-58.0824, -32.8935],
            [-58.2011, -32.4718],
            [-58.1889, -31.9241],
            [-57.8105, -30.8587],
            [-57.8724, -30.5911],
            [-57.609, -30.188],
            [-57.2144, -30.2834],
            [-56.8328, -30.1072],
            [-56.1761, -30.6283],
            [-55.8736, -31.0696],
            [-55.5573, -30.876],
            [-55.2546, -31.2258],
            [-54.5876, -31.4851],
            [-54.2205, -31.8553],
            [-53.7618, -32.0568],
            [-53.6017, -32.403],
            [-53.1254, -32.7368],
            [-53.5314, -33.171],
            [-53.3707, -33.7422],
          ],
        ],
      },
      properties: {
        name: 'Uruguay',
        id: 'UY',
      },
      id: 'UY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [38.2142, 47.0914],
            [37.5435, 47.0745],
            [37.3398, 46.9169],
            [36.2793, 46.6586],
            [35.827, 46.6244],
            [34.8495, 46.1898],
            [35.0231, 45.7011],
            [35.558, 45.3109],
            [36.1705, 45.453],
            [36.3934, 45.0655],
            [35.5695, 45.1192],
            [35.0879, 44.8027],
            [34.7168, 44.8071],
            [33.9098, 44.3876],
            [33.4508, 44.5537],
            [33.5551, 45.0979],
            [32.508, 45.4037],
            [33.6648, 45.947],
            [33.2022, 46.1756],
            [32.4769, 46.0838],
            [32.0357, 46.2609],
            [31.9744, 46.7088],
            [30.7963, 46.5521],
            [30.2193, 45.8667],
            [29.6018, 45.6825],
            [29.7061, 45.2599],
            [29.2236, 45.4028],
            [28.7606, 45.2341],
            [28.2124, 45.4503],
            [28.9475, 46.05],
            [29.1464, 46.5268],
            [29.838, 46.3505],
            [29.8779, 46.829],
            [29.5494, 47.2467],
            [29.1597, 47.4558],
            [29.1948, 47.8824],
            [27.5493, 48.4777],
            [26.6189, 48.2597],
            [26.1625, 47.9925],
            [24.8935, 47.7177],
            [23.2027, 48.0844],
            [22.8764, 47.9472],
            [22.132, 48.4053],
            [22.5386, 49.0727],
            [22.6496, 49.539],
            [23.7118, 50.3773],
            [24.1056, 50.8449],
            [23.6053, 51.5179],
            [24.3617, 51.8674],
            [25.9251, 51.9136],
            [27.1422, 51.752],
            [27.2701, 51.6135],
            [28.5991, 51.5427],
            [29.1357, 51.6173],
            [29.3466, 51.3825],
            [30.5331, 51.5964],
            [30.9806, 52.0461],
            [31.7636, 52.1012],
            [32.508, 52.3084],
            [33.7353, 52.3448],
            [34.3976, 51.7804],
            [34.2809, 51.3117],
            [35.3121, 51.0438],
            [35.4115, 50.5398],
            [36.3059, 50.2805],
            [37.4228, 50.4115],
            [38.0469, 49.9201],
            [38.2586, 50.0524],
            [39.1748, 49.8559],
            [39.7807, 49.5721],
            [40.0701, 49.2003],
            [39.6866, 49.0079],
            [39.9609, 48.238],
            [39.7359, 47.8447],
            [38.9005, 47.8553],
            [38.2875, 47.5592],
            [38.2142, 47.0914],
          ],
        ],
      },
      properties: {
        name: 'Ukraine',
        id: 'UA',
      },
      id: 'UA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.9031, -1.0021],
            [32.1942, -1.0021],
            [30.8447, -1.0021],
            [30.51, -1.0674],
            [30.1016, -1.3688],
            [29.577, -1.3878],
            [29.5619, -0.9772],
            [29.7177, 0.0983],
            [29.9343, 0.4991],
            [29.9427, 0.8192],
            [30.9424, 1.683],
            [31.2527, 2.0448],
            [31.1763, 2.2703],
            [30.7284, 2.4554],
            [30.8385, 3.4905],
            [31.1523, 3.7857],
            [31.4799, 3.6805],
            [31.7982, 3.8026],
            [32.0996, 3.5291],
            [32.3358, 3.7063],
            [32.8382, 3.7986],
            [33.4894, 3.7551],
            [33.9759, 4.2203],
            [34.165, 3.8128],
            [34.3928, 3.6916],
            [34.4478, 3.1634],
            [34.7425, 2.818],
            [34.964, 2.0625],
            [34.9654, 1.6435],
            [34.7874, 1.2307],
            [34.4815, 1.042],
            [33.9431, 0.1738],
            [33.9031, -1.0021],
          ],
        ],
      },
      properties: {
        name: 'Uganda',
        id: 'UG',
      },
      id: 'UG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.9031, -1.0021],
            [34.7709, -1.4416],
            [36.2633, -2.2747],
            [37.6438, -3.0453],
            [37.6087, -3.4604],
            [37.7974, -3.6743],
            [39.2219, -4.6922],
            [38.8192, -5.8778],
            [38.8738, -6.3315],
            [39.4722, -6.8788],
            [39.5193, -7.1243],
            [39.2884, -7.518],
            [39.4411, -8.0116],
            [39.304, -8.444],
            [39.7838, -9.9146],
            [40.4634, -10.4641],
            [39.9889, -10.821],
            [39.3217, -11.1224],
            [38.7948, -11.2289],
            [38.4917, -11.4131],
            [37.9204, -11.2946],
            [37.5417, -11.675],
            [36.9789, -11.5667],
            [36.5185, -11.7163],
            [35.7852, -11.4531],
            [35.6312, -11.5818],
            [34.9596, -11.5783],
            [34.608, -11.0807],
            [34.6617, -10.71],
            [34.5241, -10.03],
            [34.3208, -9.7317],
            [32.9199, -9.4072],
            [32.4334, -9.1564],
            [31.9425, -9.0539],
            [31.3508, -8.6069],
            [31.0764, -8.6117],
            [30.7511, -8.1936],
            [30.3134, -7.2037],
            [29.7097, -6.6169],
            [29.4802, -6.0252],
            [29.5943, -5.651],
            [29.3426, -4.9829],
            [29.4034, -4.4494],
            [29.7177, -4.456],
            [30.1469, -4.0854],
            [30.4252, -3.5891],
            [30.7901, -3.2744],
            [30.5149, -2.9175],
            [30.5535, -2.3999],
            [30.8287, -2.3387],
            [30.8128, -1.5632],
            [30.51, -1.0674],
            [30.8447, -1.0021],
            [32.1942, -1.0021],
            [33.9031, -1.0021],
          ],
        ],
      },
      properties: {
        name: 'Tanzania',
        id: 'TZ',
      },
      id: 'TZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.009, 22.6205],
            [120.5815, 22.3564],
            [120.3258, 22.5424],
            [120.0723, 23.1496],
            [120.1589, 23.7089],
            [121.0405, 25.0326],
            [121.5936, 25.2754],
            [121.9291, 24.9736],
            [121.2957, 22.9667],
            [121.009, 22.6205],
          ],
        ],
      },
      properties: {
        name: 'Taiwan',
        id: 'TW',
      },
      id: 'TW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [41.5101, 41.5174],
              [42.4663, 41.4398],
              [42.7876, 41.5636],
              [43.4393, 41.1073],
              [43.7225, 40.7193],
              [43.5693, 40.4823],
              [43.7917, 40.0703],
              [44.2893, 40.0406],
              [44.7683, 39.7037],
              [44.8171, 39.6504],
              [44.023, 39.3774],
              [44.4309, 38.3569],
              [44.2112, 37.9081],
              [44.5614, 37.7448],
              [44.7652, 37.1424],
              [44.2818, 36.9782],
              [44.1562, 37.2831],
              [43.5161, 37.2445],
              [42.7748, 37.3719],
              [42.3588, 37.1087],
              [42.2683, 37.2765],
              [41.5154, 37.0892],
              [40.7058, 37.0976],
              [40.0164, 36.8259],
              [39.3568, 36.6817],
              [38.7669, 36.6932],
              [38.1916, 36.9014],
              [37.4365, 36.6435],
              [36.6584, 36.8024],
              [36.1275, 35.8316],
              [35.8927, 35.9164],
              [35.811, 36.3097],
              [36.1883, 36.659],
              [36.0489, 36.9107],
              [35.5375, 36.5969],
              [34.6014, 36.7847],
              [33.6945, 36.1819],
              [32.7947, 36.0358],
              [32.3775, 36.1836],
              [32.0219, 36.5352],
              [31.3525, 36.8011],
              [30.6441, 36.8654],
              [30.4838, 36.3106],
              [29.6893, 36.1565],
              [29.1433, 36.3971],
              [29.0381, 36.6937],
              [28.4837, 36.8037],
              [28.1339, 37.0297],
              [27.3491, 37.0195],
              [27.0779, 37.6875],
              [27.2323, 37.9787],
              [26.6828, 38.1985],
              [26.7636, 38.7098],
              [27.0135, 38.8869],
              [26.6819, 39.2922],
              [26.1133, 39.4675],
              [26.1812, 39.99],
              [26.7378, 40.4002],
              [27.4756, 40.3198],
              [28.2892, 40.4033],
              [29.3648, 40.8094],
              [29.3222, 41.2276],
              [31.2549, 41.1077],
              [31.4582, 41.3199],
              [32.3065, 41.7296],
              [33.3816, 42.0177],
              [34.7505, 41.9569],
              [35.2978, 41.7287],
              [36.0516, 41.6826],
              [36.5097, 41.2626],
              [38.3811, 40.9244],
              [39.4265, 41.1064],
              [40.2654, 40.9612],
              [40.9597, 41.2116],
              [41.5101, 41.5174],
            ],
          ],
          [
            [
              [28.0145, 41.9689],
              [28.3465, 41.4664],
              [29.0572, 41.2298],
              [28.7802, 40.9741],
              [28.172, 41.0806],
              [27.4996, 40.9732],
              [27.2581, 40.6874],
              [26.1053, 40.6114],
              [26.0391, 40.7269],
              [26.6246, 41.4016],
              [26.321, 41.7167],
              [26.6153, 41.9649],
              [27.2949, 42.0794],
              [28.0145, 41.9689],
            ],
          ],
        ],
      },
      properties: {
        name: 'TÃ¼rkiye',
        id: 'TR',
      },
      id: 'TR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.5043, 33.182],
            [11.5048, 32.4137],
            [10.8265, 32.0807],
            [10.115, 31.4637],
            [10.2162, 30.7832],
            [9.8952, 30.3873],
            [9.5188, 30.2293],
            [9.0439, 32.0723],
            [8.3332, 32.5437],
            [8.2111, 32.9268],
            [7.7628, 33.2331],
            [7.5, 33.8323],
            [7.5138, 34.0805],
            [8.2457, 34.7339],
            [8.3944, 35.204],
            [8.2457, 35.8707],
            [8.2306, 36.5454],
            [8.5764, 36.9374],
            [9.6879, 37.3404],
            [10.1962, 37.2059],
            [10.5713, 36.8796],
            [11.1266, 36.8739],
            [10.5256, 36.3235],
            [10.5908, 35.8871],
            [11.0045, 35.6336],
            [11.1199, 35.2404],
            [10.5349, 34.5448],
            [10.0648, 34.2119],
            [10.1589, 33.8501],
            [10.7227, 33.5145],
            [11.0844, 33.5629],
            [11.2025, 33.2491],
            [11.5043, 33.182],
          ],
        ],
      },
      properties: {
        name: 'Tunisia',
        id: 'TN',
      },
      id: 'TN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.0121, 10.1343],
            [-61.5967, 10.0646],
            [-61.4648, 10.5391],
            [-61.0338, 10.67],
            [-61.0121, 10.1343],
          ],
        ],
      },
      properties: {
        name: 'Trinidad and Tobago',
        id: 'TT',
      },
      id: 'TT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-175.162, -21.1695],
            [-175.1567, -21.2636],
            [-175.3354, -21.1579],
            [-175.2255, -21.1188],
            [-175.162, -21.1695],
          ],
        ],
      },
      properties: {
        name: 'Tonga',
        id: 'TO',
      },
      id: 'TO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [125.0679, -9.512],
            [124.9223, -8.9424],
            [125.178, -8.6477],
            [125.8043, -8.4923],
            [126.6198, -8.4595],
            [126.9149, -8.7152],
            [126.3823, -8.9575],
            [125.4079, -9.2758],
            [125.0679, -9.512],
          ],
        ],
      },
      properties: {
        name: 'Timor-Leste',
        id: 'TL',
      },
      id: 'TL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [66.5221, 37.3484],
            [65.7648, 37.569],
            [65.5549, 37.2512],
            [64.8162, 37.1322],
            [64.5108, 36.3408],
            [63.8623, 36.0123],
            [63.1086, 35.8187],
            [63.0566, 35.4459],
            [62.6105, 35.2333],
            [61.262, 35.6194],
            [61.1195, 36.6426],
            [60.3413, 36.6377],
            [60.0626, 36.9631],
            [59.5623, 37.1788],
            [59.2409, 37.5206],
            [58.8157, 37.6835],
            [58.4357, 37.6387],
            [57.4236, 37.9477],
            [57.1937, 38.2162],
            [56.4409, 38.2495],
            [56.2287, 38.0733],
            [55.5784, 38.0999],
            [55.0755, 37.9024],
            [54.7452, 37.502],
            [53.9142, 37.3435],
            [53.8237, 37.9281],
            [53.8685, 38.9491],
            [53.6177, 39.2159],
            [53.125, 39.432],
            [53.4721, 39.6686],
            [53.4042, 39.9603],
            [52.9874, 39.9878],
            [52.7335, 40.3988],
            [53.1454, 40.825],
            [53.8698, 40.6487],
            [54.7101, 40.8911],
            [54.5921, 41.1934],
            [54.0949, 41.5192],
            [53.8463, 42.0914],
            [52.9701, 41.9764],
            [52.4938, 41.7802],
            [53.2502, 42.2059],
            [54.1211, 42.3351],
            [54.9037, 41.9192],
            [55.5451, 41.2626],
            [55.9774, 41.3221],
            [57.0179, 41.2635],
            [56.9642, 41.8566],
            [57.3819, 42.1562],
            [57.8143, 42.1899],
            [58.2595, 42.688],
            [58.5889, 42.7785],
            [59.4509, 42.2996],
            [59.8584, 42.2951],
            [60.107, 41.9076],
            [60.0897, 41.3994],
            [60.455, 41.2218],
            [61.903, 41.0935],
            [62.6505, 39.8586],
            [64.1628, 38.9535],
            [65.613, 38.2384],
            [65.8572, 38.2686],
            [66.5745, 38.0107],
            [66.5221, 37.3484],
          ],
        ],
      },
      properties: {
        name: 'Turkmenistan',
        id: 'TM',
      },
      id: 'TM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-172.4791, -8.5807],
            [-172.4836, -8.5829],
            [-172.4987, -8.5478],
            [-172.4874, -8.5563],
            [-172.4791, -8.5807],
          ],
        ],
      },
      properties: {
        name: 'Tokelau',
        id: 'TK',
      },
      id: 'TK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [70.9579, 40.239],
            [70.4514, 40.049],
            [69.9667, 40.2022],
            [69.5304, 40.0974],
            [69.229, 39.761],
            [69.5987, 39.5736],
            [70.6077, 39.5643],
            [70.7333, 39.4134],
            [71.4045, 39.598],
            [71.8062, 39.2758],
            [72.0428, 39.3521],
            [73.2351, 39.3743],
            [73.6315, 39.4489],
            [73.8015, 38.6068],
            [74.2773, 38.6597],
            [74.7452, 38.5101],
            [74.8912, 37.2317],
            [74.2596, 37.4154],
            [73.7496, 37.2317],
            [73.4814, 37.4718],
            [72.8955, 37.2676],
            [71.8022, 36.6941],
            [71.4329, 37.1273],
            [71.5518, 37.933],
            [71.2558, 38.3068],
            [70.8789, 38.4564],
            [70.2148, 37.9246],
            [70.2512, 37.664],
            [69.4922, 37.553],
            [69.2649, 37.1083],
            [68.9604, 37.3249],
            [68.0677, 36.9498],
            [67.7592, 37.1722],
            [67.8143, 37.4869],
            [68.2941, 38.0329],
            [68.0478, 38.6694],
            [68.1037, 38.9619],
            [67.6767, 39.0086],
            [67.3495, 39.242],
            [67.7193, 39.6216],
            [68.4632, 39.5368],
            [68.6528, 40.1827],
            [69.2281, 40.1875],
            [69.2063, 40.5666],
            [69.7128, 40.6572],
            [70.4417, 41.0234],
            [70.7511, 40.7397],
            [70.3826, 40.4534],
            [70.9579, 40.239],
          ],
        ],
      },
      properties: {
        name: 'Tajikistan',
        id: 'TJ',
      },
      id: 'TJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [100.1225, 20.3167],
            [100.5429, 20.0885],
            [100.3977, 19.7561],
            [100.6254, 19.4999],
            [101.1546, 19.5794],
            [101.2864, 18.977],
            [101.0507, 18.4071],
            [101.1133, 18.0333],
            [100.9086, 17.5837],
            [101.1053, 17.4793],
            [102.1014, 18.2104],
            [102.7175, 17.8922],
            [103.0913, 18.1381],
            [103.3669, 18.4235],
            [103.9497, 18.3192],
            [104.8162, 17.3005],
            [104.7505, 16.6475],
            [105.047, 16.1601],
            [105.4062, 15.9874],
            [105.6223, 15.6998],
            [105.5131, 15.3606],
            [105.4754, 14.5301],
            [105.1833, 14.3464],
            [104.7789, 14.428],
            [103.1996, 14.3326],
            [102.729, 13.8421],
            [102.3198, 13.5398],
            [102.4995, 12.6698],
            [102.7557, 12.4261],
            [102.7366, 12.0896],
            [102.9341, 11.7065],
            [102.7628, 12.0124],
            [101.7236, 12.6893],
            [101.4449, 12.6187],
            [100.8634, 12.7146],
            [100.9628, 13.4319],
            [100.6561, 13.5212],
            [100.0173, 13.3534],
            [100.0901, 13.0457],
            [99.964, 12.6902],
            [99.9889, 12.1708],
            [99.6271, 11.4628],
            [99.4869, 10.8898],
            [99.165, 10.3198],
            [99.1606, 9.7339],
            [99.3936, 9.2136],
            [99.7239, 9.3144],
            [99.9046, 9.1129],
            [99.9605, 8.6712],
            [100.2792, 8.2686],
            [100.4537, 7.4425],
            [100.5864, 7.1762],
            [101.0178, 6.861],
            [101.4977, 6.8654],
            [102.1009, 6.2422],
            [101.8737, 5.8254],
            [101.5563, 5.908],
            [101.2571, 5.7894],
            [101.0533, 6.2427],
            [100.3453, 6.5498],
            [100.119, 6.442],
            [99.6959, 6.8765],
            [99.7203, 7.106],
            [99.051, 7.8877],
            [98.5791, 8.3445],
            [98.2382, 8.4231],
            [98.3714, 9.2904],
            [98.7025, 10.1902],
            [98.7571, 10.6612],
            [99.1903, 11.1051],
            [99.6147, 11.7811],
            [99.4052, 12.5477],
            [99.2196, 12.7399],
            [99.1366, 13.7165],
            [98.2462, 14.8147],
            [98.1911, 15.204],
            [98.5653, 15.4037],
            [98.6892, 16.3052],
            [98.4388, 16.9755],
            [97.793, 17.6813],
            [97.4849, 18.4941],
            [97.7539, 18.621],
            [97.8169, 19.46],
            [98.1112, 19.7623],
            [98.9169, 19.7729],
            [99.0208, 20.0419],
            [99.3382, 20.0788],
            [99.8904, 20.4246],
            [100.1225, 20.3167],
          ],
        ],
      },
      properties: {
        name: 'Thailand',
        id: 'TH',
      },
      id: 'TH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [0.9007, 10.9932],
            [0.7799, 10.3598],
            [1.3299, 9.9971],
            [1.4244, 9.2851],
            [1.6002, 9.0498],
            [1.6224, 6.2169],
            [1.1874, 6.0895],
            [0.7369, 6.4526],
            [0.5256, 6.8508],
            [0.6348, 7.3537],
            [0.5837, 8.1456],
            [0.688, 8.3041],
            [0.4153, 8.6525],
            [0.5291, 9.3583],
            [0.2619, 9.4955],
            [0.3627, 10.2364],
            [-0.0577, 10.6305],
            [-0.0686, 11.1157],
            [0.9007, 10.9932],
          ],
        ],
      },
      properties: {
        name: 'Togo',
        id: 'TG',
      },
      id: 'TG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.86, 10.9195],
            [22.4938, 10.9963],
            [21.7063, 10.5746],
            [21.7258, 10.3664],
            [21.2638, 9.9745],
            [20.7733, 9.4058],
            [20.3422, 9.1271],
            [19.1455, 9.0161],
            [19.1087, 8.6561],
            [18.4553, 8.032],
            [17.6496, 7.9836],
            [16.7849, 7.5508],
            [16.3787, 7.6835],
            [15.8452, 7.4753],
            [15.4803, 7.5237],
            [15.443, 7.8518],
            [15.1163, 8.5571],
            [14.3324, 9.2034],
            [13.9773, 9.6917],
            [14.2432, 9.9798],
            [15.5411, 9.9603],
            [15.1323, 10.6483],
            [15.0297, 11.1135],
            [15.0812, 11.8455],
            [14.7612, 12.6556],
            [14.5162, 12.9796],
            [14.0638, 13.0786],
            [13.6062, 13.7045],
            [13.4481, 14.3805],
            [14.3679, 15.7499],
            [15.4745, 16.9085],
            [15.7351, 19.9039],
            [15.949, 20.3034],
            [15.5873, 20.7335],
            [15.6072, 20.9546],
            [15.182, 21.5232],
            [15.1722, 21.9223],
            [14.9791, 22.996],
            [15.9841, 23.4452],
            [17.7521, 22.5721],
            [19.6684, 21.6257],
            [21.5847, 20.6794],
            [23.9804, 19.4968],
            [23.9822, 17.2663],
            [23.9835, 15.7801],
            [23.105, 15.7024],
            [22.9323, 15.1622],
            [22.6709, 14.7223],
            [22.3815, 14.5505],
            [22.5098, 14.1275],
            [22.1076, 13.7302],
            [22.2283, 13.3294],
            [21.8253, 12.7905],
            [22.3522, 12.6604],
            [22.5564, 11.6697],
            [22.9226, 11.3448],
            [22.86, 10.9195],
          ],
        ],
      },
      properties: {
        name: 'Chad',
        id: 'TD',
      },
      id: 'TD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.6615, 21.7651],
            [-71.8304, 21.7904],
            [-71.8062, 21.8521],
            [-71.6684, 21.8335],
            [-71.6615, 21.7651],
          ],
        ],
      },
      properties: {
        name: 'Turks and Caicos Islands',
        id: 'TC',
      },
      id: 'TC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.3588, 37.1087],
            [41.7884, 36.5973],
            [41.4169, 36.5148],
            [41.2456, 36.0735],
            [41.3543, 35.6403],
            [41.1945, 34.7689],
            [40.9872, 34.4289],
            [40.6894, 34.3322],
            [38.7735, 33.372],
            [36.8182, 32.3173],
            [36.3721, 32.387],
            [35.7875, 32.735],
            [35.8691, 33.4319],
            [35.9424, 33.6676],
            [36.5851, 34.2212],
            [36.4329, 34.6136],
            [35.9761, 34.6291],
            [35.9433, 35.2239],
            [35.7644, 35.5715],
            [35.8927, 35.9164],
            [36.1275, 35.8316],
            [36.6584, 36.8024],
            [37.4365, 36.6435],
            [38.1916, 36.9014],
            [38.7669, 36.6932],
            [39.3568, 36.6817],
            [40.0164, 36.8259],
            [40.7058, 37.0976],
            [41.5154, 37.0892],
            [42.2683, 37.2765],
            [42.3588, 37.1087],
          ],
        ],
      },
      properties: {
        name: 'Syria',
        id: 'SY',
      },
      id: 'SY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [55.5402, -4.6931],
            [55.3835, -4.6092],
            [55.4559, -4.5586],
            [55.5402, -4.6931],
          ],
        ],
      },
      properties: {
        name: 'Seychelles',
        id: 'SC',
      },
      id: 'SC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-63.123, 18.0688],
            [-63.0111, 18.0688],
            [-63.0231, 18.0191],
            [-63.123, 18.0688],
          ],
        ],
      },
      properties: {
        name: 'Sint Maarten',
        id: 'SX',
      },
      id: 'SX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [31.9482, -25.9577],
            [32.1129, -26.8393],
            [31.9584, -27.3058],
            [31.4697, -27.2956],
            [31.0636, -27.1123],
            [30.7941, -26.7643],
            [30.8034, -26.4136],
            [31.2074, -25.8432],
            [31.4151, -25.7464],
            [31.9482, -25.9577],
          ],
        ],
      },
      properties: {
        name: 'Eswatini',
        id: 'SZ',
      },
      id: 'SZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [24.1557, 65.8052],
              [23.1024, 65.7353],
              [21.5656, 65.4081],
              [21.1381, 64.8087],
              [21.4653, 64.3796],
              [20.7626, 63.8678],
              [19.0345, 63.2377],
              [18.4632, 62.8959],
              [17.8955, 62.8305],
              [17.4174, 61.7407],
              [17.1307, 61.5758],
              [17.251, 60.7009],
              [17.9559, 60.5897],
              [18.5573, 60.2537],
              [18.9706, 59.7572],
              [17.876, 59.2709],
              [17.4569, 58.8585],
              [16.6522, 58.4344],
              [16.5279, 57.0681],
              [15.9965, 56.2227],
              [14.782, 56.1619],
              [14.2152, 55.8325],
              [14.3417, 55.5278],
              [13.3212, 55.3464],
              [12.5923, 56.1377],
              [12.8835, 56.6178],
              [12.4214, 56.9063],
              [11.4493, 58.1183],
              [11.147, 58.9886],
              [11.3885, 59.0365],
              [11.7982, 59.29],
              [11.6806, 59.5923],
              [12.4862, 60.1067],
              [12.5888, 60.4508],
              [12.294, 61.0027],
              [12.8809, 61.3523],
              [12.1555, 61.7207],
              [12.3034, 62.2856],
              [12.2181, 63.0007],
              [11.9997, 63.2917],
              [12.6625, 63.9404],
              [13.9604, 64.0141],
              [14.0776, 64.464],
              [13.6501, 64.5816],
              [14.4798, 65.3014],
              [14.5432, 66.1292],
              [15.423, 66.4899],
              [16.4036, 67.055],
              [16.1275, 67.4259],
              [17.3247, 68.1039],
              [17.9168, 67.965],
              [18.3785, 68.5624],
              [19.9698, 68.3565],
              [20.6223, 69.037],
              [21.9975, 68.5207],
              [22.7823, 68.3911],
              [23.639, 67.9543],
              [23.4681, 67.45],
              [23.9884, 66.8106],
              [23.7012, 66.4808],
              [24.1557, 65.8052],
            ],
          ],
          [
            [
              [19.0767, 57.836],
              [18.6998, 57.2428],
              [18.1365, 57.5566],
              [18.5374, 57.8305],
              [19.0767, 57.836],
            ],
          ],
        ],
      },
      properties: {
        name: 'Sweden',
        id: 'SE',
      },
      id: 'SE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.5163, 46.4997],
            [15.6361, 46.2005],
            [15.6246, 45.8338],
            [15.3396, 45.4672],
            [13.8787, 45.4286],
            [13.5778, 45.5169],
            [13.7198, 45.5875],
            [13.3993, 46.3177],
            [13.6998, 46.5201],
            [14.5037, 46.4171],
            [15.0009, 46.6262],
            [15.9575, 46.6777],
            [16.0929, 46.8632],
            [16.5163, 46.4997],
          ],
        ],
      },
      properties: {
        name: 'Slovenia',
        id: 'SI',
      },
      id: 'SI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.5386, 49.0727],
            [22.132, 48.4053],
            [21.4515, 48.5522],
            [20.4901, 48.5269],
            [19.8988, 48.1314],
            [18.7917, 48.0005],
            [18.7242, 47.787],
            [17.7619, 47.7701],
            [17.1475, 48.0058],
            [16.9531, 48.5988],
            [17.1884, 48.861],
            [17.7583, 48.888],
            [18.161, 49.2574],
            [18.8321, 49.5108],
            [19.4416, 49.5976],
            [19.802, 49.1923],
            [20.6161, 49.3916],
            [21.6397, 49.4121],
            [22.5386, 49.0727],
          ],
        ],
      },
      properties: {
        name: 'Slovakia',
        id: 'SK',
      },
      id: 'SK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-54.1559, 5.3589],
            [-54.4522, 5.0135],
            [-54.3508, 4.0543],
            [-53.9904, 3.5895],
            [-54.2032, 3.1381],
            [-54.1954, 2.818],
            [-54.6163, 2.3266],
            [-55.0058, 2.593],
            [-56.1295, 2.2996],
            [-56.0198, 1.8424],
            [-56.4828, 1.9422],
            [-56.7043, 2.0363],
            [-57.2097, 2.8828],
            [-57.3036, 3.3769],
            [-57.5497, 3.3529],
            [-57.8327, 3.6761],
            [-58.0544, 4.1719],
            [-57.7111, 4.9909],
            [-57.3309, 5.0202],
            [-57.1948, 5.5484],
            [-56.9698, 5.9927],
            [-55.9395, 5.7957],
            [-55.8283, 5.9617],
            [-54.8338, 5.9883],
            [-54.0543, 5.8081],
            [-54.1559, 5.3589],
          ],
        ],
      },
      properties: {
        name: 'Suriname',
        id: 'SR',
      },
      id: 'SR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.6602, 0.1205],
            [6.5243, 0.3402],
            [6.6868, 0.4041],
            [6.7498, 0.2435],
            [6.6602, 0.1205],
          ],
        ],
      },
      properties: {
        name: 'Sao Tome and Principe',
        id: 'ST',
      },
      id: 'ST',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.2419, 46.1086],
            [20.775, 45.7499],
            [20.7741, 45.4845],
            [21.4919, 45.1223],
            [21.3845, 44.8701],
            [22.7051, 44.2376],
            [22.421, 44.0077],
            [22.5546, 43.4546],
            [22.9767, 43.1878],
            [22.4667, 42.8425],
            [22.3442, 42.3138],
            [21.5625, 42.2476],
            [21.7529, 42.6698],
            [21.3907, 42.7515],
            [21.0569, 43.0915],
            [20.3445, 42.8278],
            [19.5517, 43.2122],
            [19.1943, 43.5331],
            [19.4882, 43.7036],
            [19.1517, 44.3024],
            [19.3568, 44.8586],
            [19.007, 44.8693],
            [19.0549, 45.5271],
            [18.9054, 45.9315],
            [19.5308, 46.1552],
            [20.2419, 46.1086],
          ],
        ],
      },
      properties: {
        name: 'Serbia',
        id: 'RS',
      },
      id: 'RS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-56.2671, 46.8384],
            [-56.3869, 47.0679],
            [-56.2873, 47.071],
            [-56.3148, 46.9538],
            [-56.2671, 46.8384],
          ],
        ],
      },
      properties: {
        name: 'Saint Pierre and Miquelon',
        id: 'PM',
      },
      id: 'PM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [41.5328, -1.6955],
            [40.9788, -0.8703],
            [40.9699, 1.378],
            [40.9646, 2.8145],
            [41.3419, 3.2016],
            [41.8839, 3.9779],
            [42.0241, 4.1377],
            [42.7916, 4.2922],
            [43.0158, 4.5634],
            [43.5383, 4.8404],
            [44.0283, 4.9509],
            [44.9405, 4.9119],
            [45.9348, 5.9972],
            [47.9781, 7.9969],
            [46.9784, 7.9969],
            [44.023, 8.9859],
            [43.4828, 9.3796],
            [43.1818, 9.8799],
            [42.8418, 10.2031],
            [42.6594, 10.6212],
            [42.9226, 10.9994],
            [43.2462, 11.4997],
            [43.853, 10.7841],
            [44.3865, 10.4303],
            [44.9427, 10.4365],
            [45.8168, 10.8361],
            [46.5652, 10.7459],
            [47.405, 11.1739],
            [48.0193, 11.1392],
            [48.6745, 11.3226],
            [48.9031, 11.2546],
            [50.1101, 11.5294],
            [50.7923, 11.9835],
            [51.2549, 11.8308],
            [51.0844, 11.3354],
            [51.1408, 10.6567],
            [50.8984, 10.2532],
            [50.8247, 9.428],
            [50.4297, 8.8452],
            [50.103, 8.1998],
            [49.3484, 6.9906],
            [49.0492, 6.1739],
            [48.6492, 5.4943],
            [47.9754, 4.4968],
            [46.879, 3.2854],
            [46.0511, 2.4753],
            [44.9201, 1.81],
            [44.3328, 1.3909],
            [43.4677, 0.6217],
            [41.9798, -0.9728],
            [41.5328, -1.6955],
          ],
        ],
      },
      properties: {
        name: 'Somalia',
        id: 'SO',
      },
      id: 'SO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [12.4853, 43.9016],
            [12.397, 43.9344],
            [12.441, 43.9824],
            [12.5146, 43.9531],
            [12.4853, 43.9016],
          ],
        ],
      },
      properties: {
        name: 'San Marino',
        id: 'SM',
      },
      id: 'SM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.3626, 14.416],
            [-89.0572, 14.329],
            [-88.4491, 13.851],
            [-88.151, 13.9872],
            [-87.7153, 13.8128],
            [-87.8143, 13.3991],
            [-87.9308, 13.1807],
            [-88.5121, 13.1838],
            [-89.2776, 13.4781],
            [-89.8043, 13.5602],
            [-90.0952, 13.7364],
            [-89.8726, 14.0454],
            [-89.3626, 14.416],
          ],
        ],
      },
      properties: {
        name: 'El Salvador',
        id: 'SV',
      },
      id: 'SV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-10.2832, 8.4852],
            [-10.6474, 7.7594],
            [-11.2678, 7.2325],
            [-11.5075, 6.9067],
            [-11.7334, 7.0887],
            [-12.4856, 7.3861],
            [-12.5104, 7.6658],
            [-12.8809, 7.8566],
            [-13.2284, 8.696],
            [-13.2928, 9.0494],
            [-12.7559, 9.3734],
            [-12.5016, 9.8622],
            [-11.911, 9.9931],
            [-11.2056, 9.9776],
            [-10.6905, 9.3144],
            [-10.5005, 8.6876],
            [-10.2832, 8.4852],
          ],
        ],
      },
      properties: {
        name: 'Sierra Leone',
        id: 'SL',
      },
      id: 'SL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [161.7152, -10.3873],
              [162.1054, -10.4539],
              [162.1236, -10.8245],
              [161.5381, -10.5666],
              [161.7152, -10.3873],
            ],
          ],
          [
            [
              [159.7505, -9.2727],
              [159.9707, -9.4334],
              [160.3547, -9.4214],
              [160.6254, -9.5888],
              [160.6494, -9.9288],
              [159.8535, -9.7916],
              [159.6218, -9.5319],
              [159.7505, -9.2727],
            ],
          ],
          [
            [
              [160.7493, -8.3139],
              [160.9979, -8.6122],
              [161.2584, -9.3171],
              [160.8736, -9.1568],
              [160.5904, -8.3729],
              [160.7493, -8.3139],
            ],
          ],
          [
            [
              [159.8793, -8.5341],
              [158.944, -8.0409],
              [159.1095, -7.9037],
              [159.8433, -8.3272],
              [159.8793, -8.5341],
            ],
          ],
        ],
      },
      properties: {
        name: 'Solomon Islands',
        id: 'SB',
      },
      id: 'SB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-5.6923, -15.9977],
            [-5.775, -15.9568],
            [-5.7078, -15.9062],
            [-5.6923, -15.9977],
          ],
        ],
      },
      properties: {
        name: 'Saint Helena',
        id: 'SH',
      },
      id: 'SH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-37.1034, -54.0658],
            [-36.3264, -54.2509],
            [-35.7986, -54.7636],
            [-36.0855, -54.8666],
            [-37.1034, -54.0658],
          ],
        ],
      },
      properties: {
        name: 'South Georgia and South Sandwich Islands',
        id: 'GS',
      },
      id: 'GS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [103.9697, 1.3314],
            [103.8201, 1.2653],
            [103.6501, 1.3257],
            [103.8179, 1.4473],
            [103.9697, 1.3314],
          ],
        ],
      },
      properties: {
        name: 'Singapore',
        id: 'SG',
      },
      id: 'SG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.0193, 14.2065],
            [-12.0543, 13.633],
            [-11.6349, 13.3698],
            [-11.3903, 12.9419],
            [-11.3894, 12.4043],
            [-12.2912, 12.328],
            [-13.0828, 12.6334],
            [-13.7294, 12.6738],
            [-15.1962, 12.68],
            [-15.5748, 12.4904],
            [-16.7119, 12.3551],
            [-16.7634, 13.064],
            [-16.6488, 13.1541],
            [-15.8343, 13.1563],
            [-15.5096, 13.5864],
            [-16.5623, 13.5873],
            [-17.168, 14.6407],
            [-17.1471, 14.9221],
            [-16.5352, 15.8383],
            [-16.4411, 16.2045],
            [-16.1683, 16.5472],
            [-15.6208, 16.5068],
            [-15.1128, 16.6448],
            [-14.3, 16.5805],
            [-13.8685, 16.1481],
            [-13.4095, 16.0593],
            [-13.2579, 15.7002],
            [-12.8584, 15.2426],
            [-12.2807, 14.8089],
          ],
        ],
      },
      properties: {
        name: 'Senegal',
        id: 'SN',
      },
      id: 'SN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.078, 9.4613],
            [34.0945, 8.5824],
            [33.9533, 8.4435],
            [33.2342, 8.3964],
            [33.0127, 7.9516],
            [33.2262, 7.7608],
            [33.6661, 7.6711],
            [34.0643, 7.2259],
            [34.7106, 6.6604],
            [35.0821, 5.6731],
            [35.2681, 5.4925],
            [33.9759, 4.2203],
            [33.4894, 3.7551],
            [32.8382, 3.7986],
            [32.3358, 3.7063],
            [32.0996, 3.5291],
            [31.7982, 3.8026],
            [31.4799, 3.6805],
            [31.1523, 3.7857],
            [30.8385, 3.4905],
            [30.5083, 3.8359],
            [30.1949, 3.9819],
            [29.6768, 4.5869],
            [29.1513, 4.3881],
            [28.7269, 4.5048],
            [28.192, 4.3503],
            [27.7881, 4.6446],
            [27.4032, 5.1094],
            [27.0836, 5.777],
            [26.5141, 6.0691],
            [26.3619, 6.6355],
            [25.8891, 7.0647],
            [25.3809, 7.3333],
            [25.2472, 7.7244],
            [24.8535, 8.1376],
            [24.2915, 8.2912],
            [24.1473, 8.6654],
            [24.5321, 8.8869],
            [24.7852, 9.7747],
            [25.067, 10.2936],
            [25.7981, 10.4206],
            [25.8913, 10.2026],
            [26.5514, 9.5257],
            [27.0743, 9.614],
            [27.8857, 9.5998],
            [28.0491, 9.3286],
            [28.8445, 9.3259],
            [28.9995, 9.61],
            [29.4731, 9.7685],
            [29.6054, 10.065],
            [30.0031, 10.2772],
            [30.7395, 9.7427],
            [31.2247, 9.7991],
            [31.6548, 10.2213],
            [31.9331, 10.6625],
            [32.4041, 11.0576],
            [32.3353, 11.7158],
            [32.0721, 12.0066],
            [32.7366, 12.0097],
            [33.1991, 12.2174],
            [33.0731, 11.5916],
            [33.172, 10.8503],
            [33.892, 10.1991],
            [34.078, 9.4613],
          ],
        ],
      },
      properties: {
        name: 'South Sudan',
        id: 'SS',
      },
      id: 'SS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [36.8714, 21.9968],
            [36.9269, 21.5867],
            [37.1507, 21.1037],
            [37.2274, 20.5568],
            [37.2483, 19.5821],
            [37.4711, 18.8203],
            [37.9217, 18.5558],
            [38.6093, 18.0049],
            [38.2537, 17.5845],
            [37.7823, 17.458],
            [37.4112, 17.0616],
            [37.0091, 17.059],
            [36.9136, 16.2964],
            [36.4267, 15.132],
            [36.5243, 14.2567],
            [36.3907, 13.6259],
            [36.2123, 13.2712],
            [36.1075, 12.7266],
            [35.5961, 12.5375],
            [35.1123, 11.8166],
            [34.9312, 10.8649],
            [34.5716, 10.88],
            [34.2756, 10.528],
            [34.2915, 10.1249],
            [34.078, 9.4613],
            [33.892, 10.1991],
            [33.172, 10.8503],
            [33.0731, 11.5916],
            [33.1991, 12.2174],
            [32.7366, 12.0097],
            [32.0721, 12.0066],
            [32.3353, 11.7158],
            [32.4041, 11.0576],
            [31.9331, 10.6625],
            [31.6548, 10.2213],
            [31.2247, 9.7991],
            [30.7395, 9.7427],
            [30.0031, 10.2772],
            [29.6054, 10.065],
            [29.4731, 9.7685],
            [28.9995, 9.61],
            [28.8445, 9.3259],
            [28.0491, 9.3286],
            [27.8857, 9.5998],
            [27.0743, 9.614],
            [26.5514, 9.5257],
            [25.8913, 10.2026],
            [25.7981, 10.4206],
            [25.067, 10.2936],
            [24.7852, 9.7747],
            [24.5321, 8.8869],
            [24.1473, 8.6654],
            [23.5374, 8.8159],
            [23.6461, 9.8231],
            [23.2559, 10.4579],
            [22.86, 10.9195],
            [22.9226, 11.3448],
            [22.5564, 11.6697],
            [22.3522, 12.6604],
            [21.8253, 12.7905],
            [22.2283, 13.3294],
            [22.1076, 13.7302],
            [22.5098, 14.1275],
            [22.3815, 14.5505],
            [22.6709, 14.7223],
            [22.9323, 15.1622],
            [23.105, 15.7024],
            [23.9835, 15.7801],
            [23.9822, 17.2663],
            [23.9804, 19.4968],
            [23.9804, 19.9958],
            [24.9796, 20.0024],
            [24.9805, 21.9959],
            [27.2723, 21.9955],
            [30.3285, 21.9951],
            [32.6061, 21.9959],
            [34.2467, 21.9964],
            [36.8714, 21.9968],
          ],
        ],
      },
      properties: {
        name: 'Sudan',
        id: 'SD',
      },
      id: 'SD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.5314, 29.0964],
            [47.4334, 28.9895],
            [47.6713, 28.5331],
            [48.4424, 28.5429],
            [48.9067, 27.6289],
            [49.4052, 27.1811],
            [49.9862, 26.829],
            [50.214, 26.3084],
            [50.0315, 26.1108],
            [50.558, 25.0868],
            [50.8043, 24.7894],
            [51.2682, 24.6074],
            [51.5683, 24.286],
            [51.5927, 24.0787],
            [52.555, 22.933],
            [55.1043, 22.6214],
            [55.186, 22.704],
            [55.641, 22.0017],
            [54.9774, 19.9958],
            [53.667, 19.559],
            [51.9775, 18.9961],
            [49.0421, 18.582],
            [48.172, 18.1567],
            [47.5795, 17.4483],
            [47.4418, 17.1118],
            [46.9758, 16.9533],
            [46.6819, 17.2685],
            [46.3104, 17.2312],
            [45.4066, 17.32],
            [45.1483, 17.4274],
            [44.1557, 17.3986],
            [43.9169, 17.3249],
            [43.418, 17.5162],
            [43.1911, 17.3595],
            [43.1649, 16.6892],
            [42.7992, 16.3718],
            [42.6989, 16.7372],
            [42.3833, 17.1225],
            [42.294, 17.435],
            [41.7498, 17.8855],
            [41.2296, 18.6783],
            [41.1159, 19.0822],
            [40.7591, 19.7556],
            [40.0808, 20.2661],
            [39.6138, 20.5178],
            [39.0936, 21.3101],
            [38.9879, 21.8819],
            [39.0958, 22.3928],
            [38.9413, 22.882],
            [38.4641, 23.712],
            [37.9195, 24.1852],
            [37.5431, 24.2918],
            [37.1808, 24.82],
            [37.1489, 25.291],
            [36.7627, 25.7513],
            [36.2496, 26.5947],
            [35.5815, 27.4323],
            [35.1807, 28.0347],
            [34.616, 28.1483],
            [34.7798, 28.5074],
            [34.9507, 29.3535],
            [36.0156, 29.1906],
            [36.4759, 29.4951],
            [36.7551, 29.8662],
            [37.4694, 29.9949],
            [37.6336, 30.3132],
            [37.9803, 30.5],
            [36.9584, 31.4917],
            [38.9622, 31.9951],
            [39.1455, 32.1247],
            [40.3693, 31.9391],
            [42.0743, 31.0802],
            [42.8578, 30.4952],
            [44.7163, 29.1937],
            [46.3565, 29.0636],
            [46.5314, 29.0964],
          ],
        ],
      },
      properties: {
        name: 'Saudi Arabia',
        id: 'SA',
      },
      id: 'SA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.6834, 27.6565],
            [-8.6834, 27.2858],
            [-8.6821, 25.9954],
            [-10.5624, 25.9954],
            [-12.0164, 25.9954],
            [-12.0235, 23.4674],
            [-12.6203, 23.2712],
            [-13.0316, 23],
            [-13.0944, 22.4958],
            [-13.0163, 21.3341],
            [-15.0018, 21.3314],
            [-16.9647, 21.3292],
            [-17.0481, 20.8063],
            [-16.9309, 21.9001],
            [-16.5143, 22.3333],
            [-16.2103, 23.0981],
            [-15.5864, 24.0729],
            [-15.0388, 24.5488],
            [-14.856, 24.8715],
            [-14.7949, 25.4041],
            [-14.4138, 26.2538],
            [-13.5758, 26.7349],
            [-13.1774, 27.652],
            [-8.6834, 27.6565],
          ],
        ],
      },
      properties: {
        name: 'Western Sahara',
        id: 'EH',
      },
      id: 'EH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.51, -1.0674],
            [30.8128, -1.5632],
            [30.8287, -2.3387],
            [30.5535, -2.3999],
            [29.9303, -2.3396],
            [29.8682, -2.7164],
            [29.3901, -2.8087],
            [29.0146, -2.7204],
            [28.8765, -2.4004],
            [29.1064, -2.2335],
            [29.1295, -1.8601],
            [29.577, -1.3878],
            [30.1016, -1.3688],
            [30.51, -1.0674],
          ],
        ],
      },
      properties: {
        name: 'Rwanda',
        id: 'RW',
      },
      id: 'RW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-178.8765, 71.5769],
              [-177.5322, 71.2631],
              [-177.8218, 71.0675],
              [-179.5066, 70.9235],
              [-180, 70.993],
              [-180, 71.1843],
              [-180, 71.3998],
              [-180, 71.5379],
              [-178.8765, 71.5769],
            ],
          ],
          [
            [
              [178.8614, 70.8265],
              [178.8911, 71.2311],
              [180, 71.5379],
              [180, 70.993],
              [178.8614, 70.8265],
            ],
          ],
          [
            [
              [60.4505, 69.935],
              [59.5783, 69.7385],
              [58.473, 70.2668],
              [59.0052, 70.4652],
              [60.4505, 69.935],
            ],
          ],
          [
            [
              [169.201, 69.5805],
              [167.789, 69.8369],
              [168.358, 70.0157],
              [169.375, 69.8826],
              [169.201, 69.5805],
            ],
          ],
          [
            [
              [50.265, 69.1857],
              [49.6262, 68.8596],
              [48.667, 68.7331],
              [48.279, 69.0403],
              [48.6315, 69.436],
              [49.225, 69.5113],
              [50.265, 69.1857],
            ],
          ],
          [
            [
              [-179.7985, 68.9404],
              [-178.8739, 68.7542],
              [-177.4075, 68.2451],
              [-175.3451, 67.678],
              [-174.4476, 67.1031],
              [-173.6797, 67.1449],
              [-172.6405, 66.9249],
              [-171.7956, 66.9318],
              [-170.192, 66.2014],
              [-170.6663, 65.6214],
              [-172.2115, 65.4252],
              [-172.2133, 65.0482],
              [-172.9239, 64.705],
              [-173.0092, 64.2975],
              [-173.7298, 64.3646],
              [-174.318, 64.6376],
              [-175.3951, 64.8025],
              [-175.8538, 65.0109],
              [-176.0933, 65.471],
              [-177.0563, 65.6137],
              [-178.3103, 65.4849],
              [-178.8794, 65.9364],
              [-179.7283, 65.8039],
              [-179.3521, 65.5167],
              [-180, 65.0672],
              [-180, 65.312],
              [-180, 65.5569],
              [-180, 65.8014],
              [-180, 66.0462],
              [-180, 66.291],
              [-180, 66.5359],
              [-180, 66.7807],
              [-180, 67.0252],
              [-180, 67.2701],
              [-180, 67.5149],
              [-180, 67.7597],
              [-180, 68.0045],
              [-180, 68.2491],
              [-180, 68.4939],
              [-180, 68.7387],
              [-180, 68.9835],
              [-179.7985, 68.9404],
            ],
          ],
          [
            [
              [163.635, 58.6033],
              [163.7611, 59.015],
              [164.6156, 58.8856],
              [163.635, 58.6033],
            ],
          ],
          [
            [
              [21.2358, 55.2641],
              [22.8311, 54.8384],
              [22.7663, 54.3568],
              [19.6045, 54.4591],
              [19.9747, 54.9212],
              [20.5202, 54.9949],
              [20.8998, 55.2867],
              [20.9579, 55.279],
              [21.2358, 55.2641],
            ],
          ],
          [
            [
              [142.761, 54.3939],
              [142.9181, 53.7942],
              [143.2235, 53.2961],
              [143.3234, 52.6136],
              [143.1556, 52.0838],
              [143.4552, 51.4715],
              [143.8161, 50.2825],
              [144.272, 49.3113],
              [143.105, 49.1988],
              [142.5741, 48.0719],
              [142.5568, 47.7381],
              [143.0056, 47.2228],
              [143.2821, 46.5592],
              [142.5781, 46.7008],
              [142.2088, 46.0891],
              [141.8302, 46.4508],
              [142.0388, 47.1402],
              [141.9642, 47.5872],
              [142.1817, 48.0133],
              [141.8661, 48.75],
              [142.1422, 49.5692],
              [142.0712, 50.5149],
              [142.2066, 51.2225],
              [141.7223, 51.7363],
              [141.8235, 53.3394],
              [142.5262, 53.4475],
              [142.761, 54.3939],
            ],
          ],
          [
            [
              [148.5995, 45.3176],
              [147.9137, 44.9904],
              [147.7694, 45.1906],
              [148.5995, 45.3176],
            ],
          ],
          [
            [
              [96.5265, 81.0755],
              [97.8316, 80.7983],
              [97.1751, 80.241],
              [94.565, 80.1261],
              [93.8725, 80.0102],
              [91.5239, 80.3584],
              [93.2626, 80.7912],
              [93.0651, 80.9885],
              [95.8008, 81.2804],
              [96.5265, 81.0755],
            ],
          ],
          [
            [
              [54.719, 81.1159],
              [57.694, 80.7923],
              [55.7124, 80.6372],
              [54.0665, 80.8136],
              [54.719, 81.1159],
            ],
          ],
          [
            [
              [62.168, 80.8347],
              [61.0511, 80.4186],
              [59.3044, 80.5216],
              [59.5921, 80.8165],
              [62.168, 80.8347],
            ],
          ],
          [
            [
              [97.6744, 80.1582],
              [100.0613, 79.7772],
              [99.4394, 78.8341],
              [97.5555, 78.8266],
              [94.6524, 79.1275],
              [93.0708, 79.4953],
              [94.9871, 80.0968],
              [97.6744, 80.1582],
            ],
          ],
          [
            [
              [102.8848, 79.254],
              [105.146, 78.8188],
              [105.3125, 78.4999],
              [103.7194, 78.2582],
              [101.2043, 78.192],
              [100.2841, 78.6792],
              [101.5905, 79.3504],
              [102.8848, 79.254],
            ],
          ],
          [
            [
              [130.6871, 42.3027],
              [130.5269, 42.5353],
              [130.5771, 42.8118],
              [131.0684, 42.9024],
              [131.262, 43.4333],
              [131.2553, 44.0716],
              [131.0822, 44.9101],
              [131.9775, 45.2439],
              [132.9359, 45.03],
              [133.1858, 45.4947],
              [133.8614, 46.2475],
              [134.1677, 47.3022],
              [134.7523, 47.7155],
              [134.5636, 48.3219],
              [134.2933, 48.3733],
              [133.4686, 48.0972],
              [133.1441, 48.1057],
              [132.5617, 47.7683],
              [131.0027, 47.6915],
              [130.712, 48.1279],
              [130.8043, 48.3414],
              [130.1962, 48.8916],
              [129.5339, 49.3235],
              [128.5267, 49.5943],
              [127.9998, 49.5685],
              [127.5027, 49.8735],
              [127.5901, 50.2091],
              [126.9247, 51.1002],
              [126.313, 52.3997],
              [125.649, 53.0422],
              [124.8122, 53.1339],
              [123.6079, 53.5465],
              [122.338, 53.485],
              [120.704, 53.1718],
              [120.0945, 52.7872],
              [120.6561, 52.5666],
              [120.7497, 52.0965],
              [120.067, 51.6007],
              [119.1921, 50.3797],
              [119.26, 50.0664],
              [118.4517, 49.8444],
              [117.8733, 49.5135],
              [116.6832, 49.8238],
              [116.1346, 50.0109],
              [115.2743, 49.9489],
              [114.743, 50.2337],
              [114.2969, 50.2743],
              [113.1641, 49.7971],
              [113.0557, 49.6162],
              [111.3366, 49.3559],
              [110.8279, 49.1661],
              [110.1997, 49.1704],
              [109.2365, 49.3348],
              [108.6137, 49.3228],
              [108.034, 49.594],
              [107.9479, 49.9247],
              [107.2332, 49.9893],
              [106.7112, 50.3125],
              [106.218, 50.3045],
              [105.3835, 50.4736],
              [103.6328, 50.1387],
              [102.6833, 50.3871],
              [102.2883, 50.585],
              [102.1116, 51.3534],
              [101.3814, 51.4526],
              [100.4687, 51.7261],
              [98.8485, 52.0701],
              [97.947, 51.3483],
              [97.8254, 50.9852],
              [98.2795, 50.5333],
              [98.1032, 50.0779],
              [97.2088, 49.7308],
              [96.986, 49.8828],
              [95.8518, 50.0129],
              [95.5229, 49.9112],
              [94.6147, 50.0237],
              [94.2511, 50.5564],
              [91.8044, 50.6936],
              [89.644, 49.903],
              [89.6542, 49.7175],
              [88.8317, 49.4485],
              [88.1925, 49.4518],
              [87.8143, 49.1624],
              [87.3229, 49.0858],
              [86.6144, 49.6098],
              [86.1807, 49.4993],
              [85.21, 49.6648],
              [84.9893, 50.0615],
              [84.2578, 50.2883],
              [83.9453, 50.7746],
              [83.3571, 50.9945],
              [82.4938, 50.7275],
              [81.4657, 50.7397],
              [80.8771, 51.2815],
              [79.9862, 50.7746],
              [79.4687, 51.493],
              [78.4757, 52.6385],
              [77.7042, 53.3791],
              [76.5758, 53.9424],
              [76.2664, 54.312],
              [74.4518, 53.6472],
              [73.2857, 53.5984],
              [73.3993, 53.8115],
              [72.9141, 54.1073],
              [71.0933, 54.2123],
              [71.1856, 54.5994],
              [70.7382, 55.3052],
              [70.1824, 55.1625],
              [68.9773, 55.3895],
              [68.2062, 55.1609],
              [68.0739, 54.9596],
              [66.2225, 54.6673],
              [65.4767, 54.6233],
              [65.1576, 54.3643],
              [64.4611, 54.3841],
              [63.0739, 54.1053],
              [61.9287, 53.9464],
              [61.3339, 54.0492],
              [60.9797, 53.6217],
              [61.1994, 53.2872],
              [62.0148, 53.1079],
              [61.974, 52.9437],
              [61.0476, 52.9725],
              [60.8021, 52.7448],
              [60.9375, 52.2805],
              [60.0657, 51.9764],
              [60.4647, 51.6513],
              [61.3632, 51.442],
              [61.5851, 51.2298],
              [61.3894, 50.8609],
              [60.6379, 50.6638],
              [59.9552, 50.7992],
              [59.8122, 50.5819],
              [58.8836, 50.6945],
              [58.3594, 51.0638],
              [57.8387, 51.0917],
              [57.4423, 50.8889],
              [57.0117, 51.0651],
              [56.4915, 51.0196],
              [55.7977, 50.6021],
              [55.5424, 50.6017],
              [54.727, 50.9981],
              [54.4216, 50.7804],
              [54.0416, 51.1153],
              [53.3381, 51.4824],
              [52.571, 51.4817],
              [52.3309, 51.6812],
              [51.6091, 51.4839],
              [50.7941, 51.7292],
              [50.2468, 51.2895],
              [49.498, 51.0835],
              [48.8086, 50.6012],
              [48.8432, 50.0131],
              [48.4344, 49.8284],
              [47.706, 50.378],
              [47.3264, 50.2734],
              [46.8022, 49.367],
              [47.0144, 49.0984],
              [46.7028, 48.8055],
              [46.661, 48.4124],
              [47.0042, 48.2846],
              [47.0934, 47.9477],
              [48.1099, 47.7452],
              [48.9595, 46.7744],
              [48.6102, 46.5663],
              [49.2321, 46.3372],
              [48.1592, 45.7371],
              [47.5244, 45.6017],
              [46.7529, 44.4205],
              [47.2301, 44.1923],
              [47.5089, 43.5096],
              [47.4632, 43.0351],
              [48.5729, 41.8446],
              [47.8609, 41.2129],
              [47.2612, 41.315],
              [46.7494, 41.8126],
              [46.4298, 41.8907],
              [45.6388, 42.205],
              [45.7275, 42.4749],
              [45.1602, 42.6751],
              [44.5059, 42.7488],
              [43.9573, 42.5664],
              [43.7824, 42.747],
              [42.4192, 43.2242],
              [41.5807, 43.2193],
              [40.6481, 43.534],
              [39.9782, 43.42],
              [38.7171, 44.2882],
              [38.1814, 44.4196],
              [37.8516, 44.6988],
              [37.4112, 44.7352],
              [36.9411, 45.2896],
              [37.6474, 45.3771],
              [37.9332, 46.0016],
              [38.1836, 46.0948],
              [37.9679, 46.6182],
              [39.2933, 47.1056],
              [38.2142, 47.0914],
              [38.2875, 47.5592],
              [38.9005, 47.8553],
              [39.7359, 47.8447],
              [39.9609, 48.238],
              [39.6866, 49.0079],
              [40.0701, 49.2003],
              [39.7807, 49.5721],
              [39.1748, 49.8559],
              [38.2586, 50.0524],
              [38.0469, 49.9201],
              [37.4228, 50.4115],
              [36.3059, 50.2805],
              [35.4115, 50.5398],
              [35.3121, 51.0438],
              [34.2809, 51.3117],
              [34.3976, 51.7804],
              [33.7353, 52.3448],
              [32.508, 52.3084],
              [31.7636, 52.1012],
              [31.5771, 52.3122],
              [31.4178, 53.196],
              [32.1422, 53.0913],
              [32.7042, 53.3363],
              [31.792, 54.0558],
              [31.4036, 54.1959],
              [31.1213, 54.6484],
              [30.9069, 55.5699],
              [30.2335, 55.8452],
              [29.4824, 55.6845],
              [29.375, 55.9386],
              [28.7948, 55.9426],
              [28.1481, 56.143],
              [27.8063, 56.867],
              [27.797, 57.3169],
              [27.3517, 57.5282],
              [27.753, 57.8411],
              [27.4272, 58.7331],
              [28.0127, 59.4842],
              [28.014, 59.7248],
              [28.9471, 59.8289],
              [29.0692, 60.1915],
              [28.6506, 60.611],
              [27.7978, 60.5362],
              [29.6902, 61.5461],
              [31.2855, 62.5679],
              [31.5341, 62.8855],
              [31.1807, 63.2082],
              [30.0555, 63.689],
              [30.5038, 64.0205],
              [30.1083, 64.3661],
              [30.0728, 64.7649],
              [29.604, 64.9685],
              [29.9032, 66.0911],
              [29.0869, 66.9709],
              [29.9414, 67.5475],
              [29.3439, 68.062],
              [28.4708, 68.4883],
              [28.9657, 69.0219],
              [30.8696, 69.7834],
              [31.9846, 69.9536],
              [32.9417, 69.7519],
              [32.3775, 69.4791],
              [33.6843, 69.3102],
              [35.858, 69.1917],
              [40.3809, 67.8318],
              [40.9663, 67.7135],
              [41.1887, 66.8262],
              [40.5216, 66.4466],
              [39.2889, 66.1321],
              [38.3975, 66.0644],
              [35.5136, 66.3958],
              [34.8247, 66.6111],
              [32.4636, 66.9163],
              [33.5667, 66.321],
              [34.7865, 65.8645],
              [34.4065, 65.3957],
              [34.8034, 64.986],
              [34.8695, 64.5601],
              [35.6472, 64.3783],
              [36.365, 64.0028],
              [37.4423, 63.8135],
              [38.0708, 64.0259],
              [37.9537, 64.3202],
              [37.1835, 64.4085],
              [36.5283, 64.8473],
              [36.883, 65.1724],
              [38.0096, 64.8788],
              [39.7581, 64.577],
              [40.281, 64.998],
              [39.8167, 65.5979],
              [41.4759, 66.1235],
              [42.2106, 66.5197],
              [44.0971, 66.2351],
              [44.4886, 66.6719],
              [44.292, 67.0996],
              [43.7824, 67.2545],
              [44.2254, 67.9956],
              [44.0483, 68.5489],
              [45.8922, 68.4797],
              [46.6903, 67.8489],
              [45.5287, 67.7577],
              [44.9396, 67.4776],
              [45.9859, 66.853],
              [46.6908, 66.8255],
              [47.7091, 67.045],
              [47.8746, 67.5841],
              [48.8778, 67.7315],
              [50.839, 68.3498],
              [52.5502, 68.5924],
              [53.8019, 68.9959],
              [53.9679, 68.2273],
              [54.5614, 68.273],
              [56.0436, 68.649],
              [57.1267, 68.554],
              [58.9191, 69.0039],
              [59.8975, 68.422],
              [60.9335, 68.9868],
              [60.1704, 69.5909],
              [61.0161, 69.8515],
              [63.3616, 69.6753],
              [68.5041, 68.3485],
              [69.1406, 68.9506],
              [68.5427, 68.9671],
              [68.006, 69.48],
              [67.0645, 69.6937],
              [66.9265, 70.0142],
              [67.2847, 70.7386],
              [66.6664, 70.9006],
              [66.9176, 71.2824],
              [68.2693, 71.6828],
              [69.039, 72.67],
              [69.6449, 72.8975],
              [71.5003, 72.9137],
              [72.8121, 72.6915],
              [72.6243, 72.0794],
              [71.9118, 71.5478],
              [72.7317, 70.823],
              [72.5297, 70.1724],
              [72.5275, 69.0805],
              [73.548, 68.5744],
              [73.1521, 67.8651],
              [72.5945, 67.587],
              [71.5514, 66.7605],
              [70.9393, 66.5481],
              [69.877, 66.8455],
              [69.1943, 66.5787],
              [70.3396, 66.3423],
              [71.9172, 66.2467],
              [72.4174, 66.5607],
              [73.7922, 66.9953],
              [74.0745, 67.4141],
              [74.7696, 67.7663],
              [74.3914, 68.4206],
              [74.5796, 68.7511],
              [76.4591, 68.9784],
              [77.2385, 68.4697],
              [77.6509, 68.9029],
              [76.001, 69.2349],
              [74.8149, 69.0907],
              [73.7757, 69.1983],
              [73.56, 69.7072],
              [74.3111, 70.6536],
              [73.0864, 71.4449],
              [73.6719, 71.845],
              [75.7413, 72.2962],
              [76.0325, 71.9103],
              [78.4828, 72.395],
              [79.422, 72.3808],
              [80.7626, 72.0892],
              [81.511, 71.7463],
              [82.9861, 71.7487],
              [82.1835, 72.2376],
              [80.827, 72.4882],
              [80.5096, 73.0861],
              [80.5833, 73.5684],
              [83.5449, 73.6665],
              [86.5913, 73.8943],
              [86.6513, 74.6824],
              [87.6713, 75.1296],
              [90.1851, 75.591],
              [93.5498, 75.854],
              [94.5756, 76.1517],
              [96.879, 75.9311],
              [100.3223, 76.479],
              [100.9899, 76.9904],
              [103.1312, 77.6265],
              [104.0146, 77.7304],
              [105.8944, 77.4889],
              [104.9121, 77.1748],
              [107.4299, 76.9265],
              [108.0278, 76.7185],
              [111.1151, 76.723],
              [112.6194, 76.3836],
              [113.8712, 75.856],
              [113.6137, 75.293],
              [111.8683, 74.7401],
              [109.8402, 74.3219],
              [108.1996, 73.6941],
              [107.2709, 73.621],
              [106.4782, 73.1394],
              [107.7503, 73.1731],
              [109.8553, 73.4726],
              [110.2614, 74.0174],
              [112.1471, 73.7089],
              [114.0607, 73.5846],
              [115.3378, 73.7025],
              [118.4504, 73.5897],
              [118.4304, 73.2466],
              [119.7505, 72.9792],
              [123.1605, 72.955],
              [123.6222, 73.1933],
              [123.3052, 73.5329],
              [124.3883, 73.7549],
              [125.617, 73.5207],
              [127.7406, 73.4817],
              [128.8885, 73.1902],
              [129.4118, 72.3156],
              [128.3589, 72.0883],
              [129.7621, 71.1195],
              [131.1572, 70.7422],
              [131.562, 70.9011],
              [132.5621, 71.8952],
              [134.1029, 71.3789],
              [135.8847, 71.6306],
              [137.3153, 71.3594],
              [138.1183, 71.5663],
              [139.695, 71.7003],
              [139.6014, 72.496],
              [142.0614, 72.7208],
              [145.4856, 72.5422],
              [146.594, 72.3025],
              [148.402, 72.312],
              [149.5015, 72.1644],
              [150.5997, 71.5201],
              [151.5825, 71.2871],
              [152.5089, 70.8345],
              [153.7944, 70.88],
              [155.8953, 71.0955],
              [158.0371, 71.0394],
              [159.3506, 70.7908],
              [160.0062, 70.3096],
              [159.8326, 69.7849],
              [161.5367, 69.3796],
              [162.3757, 69.6491],
              [164.1597, 69.7192],
              [164.5135, 69.6091],
              [166.8204, 69.4995],
              [167.6283, 69.7403],
              [168.303, 69.2716],
              [169.3106, 69.0796],
              [169.6098, 68.7862],
              [170.5375, 68.8254],
              [170.9952, 69.0454],
              [170.5824, 69.5834],
              [170.4869, 70.1076],
              [172.5595, 69.9682],
              [173.2772, 69.8238],
              [176.1075, 69.8604],
              [176.9243, 69.646],
              [178.8485, 69.3872],
              [180, 68.9835],
              [180, 65.0672],
              [178.5196, 64.6029],
              [177.4671, 64.7368],
              [177.433, 64.4445],
              [178.6501, 63.9653],
              [178.7065, 63.5216],
              [179.3324, 63.1902],
              [179.5703, 62.6875],
              [179.1206, 62.3204],
              [177.3513, 62.5874],
              [175.2681, 62.1025],
              [174.5144, 61.8237],
              [173.6235, 61.7161],
              [172.8564, 61.4693],
              [172.3926, 61.0618],
              [170.6081, 60.435],
              [169.9827, 60.067],
              [169.2267, 60.5959],
              [168.1374, 60.5739],
              [166.9642, 60.3069],
              [166.3521, 60.4847],
              [165.0843, 60.0985],
              [163.7802, 60.041],
              [163.41, 59.8349],
              [163.2728, 59.3026],
              [162.1418, 58.4475],
              [161.9602, 58.0768],
              [162.4671, 57.7661],
              [163.2138, 57.6869],
              [162.7792, 57.3577],
              [162.8027, 56.8115],
              [163.2564, 56.6881],
              [163.3354, 56.2325],
              [162.5284, 56.2606],
              [162.085, 56.0897],
              [161.7294, 55.358],
              [162.1058, 54.7521],
              [161.7258, 54.533],
              [160.7728, 54.5414],
              [160.0746, 54.1892],
              [159.8437, 53.7837],
              [159.8975, 53.3807],
              [158.6088, 52.8735],
              [158.4637, 52.3049],
              [158.1037, 51.8095],
              [156.7476, 50.9692],
              [156.5003, 51.475],
              [156.3645, 52.5093],
              [156.0986, 53.0065],
              [155.6201, 54.8646],
              [155.5549, 55.3484],
              [155.9828, 56.6952],
              [156.7285, 57.1522],
              [157.4503, 57.7994],
              [158.2102, 58.0253],
              [159.0367, 58.4239],
              [159.8473, 59.1271],
              [161.7534, 60.1522],
              [162.0037, 60.4201],
              [162.9732, 60.7828],
              [163.71, 60.9168],
              [164.0052, 61.3439],
              [164.2556, 62.6966],
              [163.3318, 62.5508],
              [163.0091, 61.7915],
              [162.3926, 61.6621],
              [160.7666, 60.7533],
              [159.8832, 61.2917],
              [160.3174, 61.7933],
              [159.5525, 61.7194],
              [159.0767, 61.9223],
              [158.07, 61.7536],
              [157.4694, 61.7989],
              [156.6801, 61.4806],
              [156.63, 61.2724],
              [155.8532, 60.7772],
              [154.9707, 60.3766],
              [154.2929, 59.8333],
              [154.5827, 59.5401],
              [155.1669, 59.3601],
              [154.011, 59.0756],
              [153.3611, 59.2147],
              [152.8822, 58.9391],
              [151.99, 59.1601],
              [151.4857, 59.5241],
              [150.4572, 59.5907],
              [149.6427, 59.7705],
              [149.205, 59.4882],
              [147.8746, 59.3881],
              [147.5146, 59.2687],
              [146.4444, 59.4305],
              [145.9317, 59.1983],
              [145.5544, 59.4136],
              [143.192, 59.3701],
              [142.0255, 58.9997],
              [141.7547, 58.7453],
              [140.6849, 58.2122],
              [140.447, 57.8136],
              [140.0022, 57.6875],
              [138.6621, 56.9655],
              [137.6913, 56.1395],
              [136.175, 55.3522],
              [135.2623, 54.9434],
              [135.8514, 54.5838],
              [136.7973, 54.6209],
              [136.6828, 53.9313],
              [137.1555, 53.8217],
              [137.3282, 53.5389],
              [138.2923, 53.5924],
              [138.6608, 53.7449],
              [138.6958, 54.3199],
              [139.7075, 54.2771],
              [140.3471, 53.8126],
              [141.4022, 53.184],
              [141.1696, 52.3684],
              [141.4853, 52.1786],
              [140.6876, 51.2322],
              [140.4763, 50.546],
              [140.5171, 49.596],
              [140.1704, 48.5238],
              [139.3728, 47.8873],
              [139.0012, 47.3835],
              [138.5866, 47.0572],
              [138.3367, 46.5432],
              [137.6855, 45.8183],
              [136.8035, 45.1711],
              [136.1421, 44.4893],
              [135.8745, 44.3734],
              [135.1309, 43.5256],
              [133.7092, 42.83],
              [133.1601, 42.6969],
              [132.4813, 42.9099],
              [132.3344, 43.2388],
              [131.7946, 43.2553],
              [131.1586, 42.6258],
              [130.6871, 42.3027],
            ],
          ],
          [
            [
              [67.7654, 76.2376],
              [66.2824, 75.9837],
              [61.3561, 75.3149],
              [60.2224, 74.7965],
              [58.5347, 74.4988],
              [58.4415, 74.1288],
              [56.9638, 73.3665],
              [55.0067, 73.4539],
              [53.7629, 73.7662],
              [54.6427, 73.9595],
              [57.0876, 75.3837],
              [58.8814, 75.8547],
              [62.9714, 76.2367],
              [65.8629, 76.6133],
              [67.5351, 77.0077],
              [68.4859, 76.9338],
              [68.9, 76.5729],
              [67.7654, 76.2376],
            ],
          ],
          [
            [
              [140.0488, 75.829],
              [140.9442, 75.7004],
              [142.4605, 75.9035],
              [143.6856, 75.8636],
              [145.2552, 75.5857],
              [144.0199, 75.0446],
              [141.9873, 74.9913],
              [139.0993, 74.6566],
              [138.0921, 74.7974],
              [137.0064, 75.235],
              [137.5013, 75.9097],
              [138.8139, 76.1996],
              [140.0488, 75.829],
            ],
          ],
          [
            [
              [146.7951, 75.3709],
              [150.1039, 75.2193],
              [150.6463, 74.9445],
              [149.5969, 74.7725],
              [148.0926, 74.8258],
              [146.1483, 75.1984],
              [146.7951, 75.3709],
            ],
          ],
          [
            [
              [113.3873, 74.4005],
              [112.7823, 74.0951],
              [111.6428, 74.2729],
              [112.0845, 74.549],
              [113.3873, 74.4005],
            ],
          ],
          [
            [
              [142.1848, 73.8958],
              [143.3438, 73.5687],
              [143.1934, 73.2206],
              [140.6627, 73.4519],
              [141.0849, 73.8658],
              [142.1848, 73.8958],
            ],
          ],
          [
            [
              [55.32, 73.3083],
              [56.4293, 73.2011],
              [55.356, 72.4651],
              [55.2978, 71.9354],
              [57.1458, 70.589],
              [54.6009, 70.68],
              [53.7225, 70.8145],
              [53.3638, 71.5416],
              [51.8124, 71.4912],
              [51.5825, 72.0712],
              [52.6221, 72.3009],
              [53.7531, 73.2932],
              [55.32, 73.3083],
            ],
          ],
        ],
      },
      properties: {
        name: 'Russia',
        id: 'RU',
      },
      id: 'RU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.2124, 45.4503],
            [28.7606, 45.2341],
            [29.2236, 45.4028],
            [29.7061, 45.2599],
            [29.5574, 44.8435],
            [29.0483, 44.7574],
            [28.6452, 44.2958],
            [28.5853, 43.7422],
            [27.0867, 44.1675],
            [26.2158, 44.0072],
            [25.4972, 43.6708],
            [23.2244, 43.8741],
            [22.7051, 44.2376],
            [21.3845, 44.8701],
            [21.4919, 45.1223],
            [20.7741, 45.4845],
            [20.775, 45.7499],
            [20.2419, 46.1086],
            [21.1519, 46.3044],
            [22.2905, 47.7279],
            [22.8764, 47.9472],
            [23.2027, 48.0844],
            [24.8935, 47.7177],
            [26.1625, 47.9925],
            [26.6189, 48.2597],
            [26.9806, 48.155],
            [27.3366, 47.6396],
            [28.0717, 46.9786],
            [28.2444, 46.4513],
            [28.0997, 45.9728],
            [28.2124, 45.4503],
          ],
        ],
      },
      properties: {
        name: 'Romania',
        id: 'RO',
      },
      id: 'RO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [55.7972, -21.3395],
            [55.3627, -21.2738],
            [55.3112, -20.904],
            [55.6618, -20.9062],
            [55.7972, -21.3395],
          ],
        ],
      },
      properties: {
        name: 'Reunion',
        id: 'RE',
      },
      id: 'RE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [51.2682, 24.6074],
            [50.8043, 24.7894],
            [50.763, 25.4445],
            [51.0032, 25.9816],
            [51.2624, 26.1534],
            [51.543, 25.9022],
            [51.4853, 25.5249],
            [51.602, 25.148],
            [51.2682, 24.6074],
          ],
        ],
      },
      properties: {
        name: 'Qatar',
        id: 'QA',
      },
      id: 'QA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-149.3215, -17.6902],
            [-149.579, -17.7351],
            [-149.6114, -17.5318],
            [-149.3792, -17.5224],
            [-149.3215, -17.6902],
          ],
        ],
      },
      properties: {
        name: 'French Polynesia',
        id: 'PF',
      },
      id: 'PF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.1598, -20.1645],
            [-57.8303, -20.9981],
            [-57.9856, -22.0461],
            [-56.9374, -22.2712],
            [-56.5239, -22.1025],
            [-55.8492, -22.3076],
            [-55.6541, -22.8105],
            [-55.3662, -23.9913],
            [-54.6254, -23.8124],
            [-54.2418, -24.0472],
            [-54.4731, -25.2204],
            [-54.6158, -25.576],
            [-54.7552, -26.533],
            [-55.1358, -26.9312],
            [-55.4266, -27.0093],
            [-55.7147, -27.415],
            [-56.0673, -27.3076],
            [-56.3705, -27.5375],
            [-57.1118, -27.4701],
            [-58.6186, -27.1323],
            [-58.1914, -26.6302],
            [-58.2031, -26.3816],
            [-57.8906, -26.0065],
            [-57.5872, -25.4051],
            [-57.8216, -25.1365],
            [-58.3654, -24.9594],
            [-59.373, -24.4538],
            [-59.8924, -24.0934],
            [-61.0329, -23.7556],
            [-61.7984, -23.1821],
            [-62.3726, -22.439],
            [-62.6509, -22.2339],
            [-62.2767, -21.066],
            [-62.2763, -20.5627],
            [-61.917, -20.0553],
            [-61.7569, -19.6451],
            [-60.0073, -19.2976],
            [-59.0905, -19.286],
            [-58.1803, -19.8178],
            [-58.1598, -20.1645],
          ],
        ],
      },
      properties: {
        name: 'Paraguay',
        id: 'PY',
      },
      id: 'PY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.4061, 37.1793],
            [-7.8343, 37.0057],
            [-8.5977, 37.1211],
            [-8.8141, 37.431],
            [-8.9147, 38.5123],
            [-9.4742, 38.7307],
            [-9.3748, 39.3384],
            [-9.1482, 39.5426],
            [-8.8867, 40.1795],
            [-8.6557, 41.0296],
            [-8.7771, 41.9409],
            [-8.2131, 42.1336],
            [-7.9208, 41.8836],
            [-7.4037, 41.8335],
            [-6.6182, 41.9422],
            [-6.2125, 41.5321],
            [-6.9285, 41.0092],
            [-6.8102, 40.3429],
            [-7.0146, 40.2084],
            [-7.0368, 39.7139],
            [-7.4452, 39.5363],
            [-7.0461, 38.9069],
            [-7.3431, 38.4572],
            [-7.1855, 38.0062],
            [-7.5036, 37.5854],
            [-7.4061, 37.1793],
          ],
        ],
      },
      properties: {
        name: 'Portugal',
        id: 'PT',
      },
      id: 'PT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [130.5269, 42.5353],
            [130.6871, 42.3027],
            [130.4576, 42.3018],
            [129.7563, 41.7123],
            [129.7088, 40.8574],
            [128.3043, 40.0362],
            [127.5684, 39.7818],
            [127.3943, 39.2079],
            [127.7863, 39.084],
            [128.3745, 38.6233],
            [128.0389, 38.3085],
            [127.1697, 38.3045],
            [126.634, 37.7816],
            [125.9419, 37.8735],
            [125.4492, 37.7301],
            [125.1931, 38.0378],
            [124.691, 38.1292],
            [125.0675, 38.5567],
            [125.4097, 39.2882],
            [125.3609, 39.5266],
            [124.5574, 39.7907],
            [124.3621, 40.0042],
            [124.8895, 40.4596],
            [125.989, 40.9049],
            [126.9034, 41.7811],
            [127.1795, 41.5312],
            [128.1112, 41.3891],
            [128.291, 41.5627],
            [128.0451, 41.9875],
            [128.9236, 42.0381],
            [129.3137, 42.4137],
            [129.7199, 42.4749],
            [129.8983, 42.9983],
            [130.2406, 42.8917],
            [130.5269, 42.5353],
          ],
        ],
      },
      properties: {
        name: 'North Korea',
        id: 'KP',
      },
      id: 'KP',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-66.1295, 18.4448],
            [-65.6288, 18.3813],
            [-65.9708, 17.9743],
            [-67.1968, 17.9943],
            [-67.1587, 18.4994],
            [-66.1295, 18.4448],
          ],
        ],
      },
      properties: {
        name: 'Puerto Rico',
        id: 'PR',
      },
      id: 'PR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.6045, 54.4591],
            [22.7663, 54.3568],
            [23.37, 54.2005],
            [23.4845, 53.9398],
            [23.8872, 53.0276],
            [23.8445, 52.6642],
            [23.1969, 52.257],
            [23.6523, 52.0403],
            [23.6053, 51.5179],
            [24.1056, 50.8449],
            [23.7118, 50.3773],
            [22.6496, 49.539],
            [22.5386, 49.0727],
            [21.6397, 49.4121],
            [20.6161, 49.3916],
            [19.802, 49.1923],
            [19.4416, 49.5976],
            [18.8321, 49.5108],
            [18.5622, 49.8792],
            [17.8746, 49.9722],
            [17.152, 50.3784],
            [16.6792, 50.0974],
            [16.0072, 50.6117],
            [14.8096, 50.8589],
            [15.0164, 51.2527],
            [14.6018, 51.8324],
            [14.7527, 52.0818],
            [14.6196, 52.5284],
            [14.1286, 52.8782],
            [14.4145, 53.2835],
            [14.2587, 53.7296],
            [14.2139, 53.8707],
            [14.2112, 53.9504],
            [16.1865, 54.2904],
            [16.5598, 54.5539],
            [17.2621, 54.7294],
            [18.3234, 54.8382],
            [18.6697, 54.4309],
            [19.6045, 54.4591],
          ],
        ],
      },
      properties: {
        name: 'Poland',
        id: 'PL',
      },
      id: 'PL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [152.9656, -4.7565],
              [152.6807, -4.4986],
              [152.5985, -3.9948],
              [153.1241, -4.2523],
              [152.9656, -4.7565],
            ],
          ],
          [
            [
              [155.9575, -6.687],
              [155.7191, -6.8628],
              [155.344, -6.7217],
              [155.202, -6.3075],
              [154.782, -5.9706],
              [154.7412, -5.5453],
              [154.9969, -5.54],
              [155.467, -6.145],
              [155.8225, -6.3803],
              [155.9575, -6.687],
            ],
          ],
          [
            [
              [151.9158, -4.2967],
              [152.4054, -4.3406],
              [152.4001, -4.7312],
              [152.0135, -5.0038],
              [152.1431, -5.3571],
              [151.8652, -5.5649],
              [151.515, -5.5524],
              [151.2291, -5.92],
              [150.4283, -6.2764],
              [149.6524, -6.2906],
              [149.3825, -6.078],
              [149.1264, -6.1277],
              [148.4011, -5.7651],
              [148.3447, -5.5449],
              [148.999, -5.4845],
              [149.2454, -5.5729],
              [150.2987, -5.5356],
              [150.953, -5.4237],
              [151.3268, -4.9603],
              [151.6712, -4.8835],
              [151.5518, -4.3455],
              [151.9158, -4.2967],
            ],
          ],
          [
            [
              [140.9761, -9.1187],
              [140.9748, -6.3461],
              [140.9739, -4.0232],
              [140.9734, -2.6099],
              [141.1856, -2.6276],
              [142.2115, -3.0835],
              [142.9053, -3.3206],
              [143.509, -3.4311],
              [144.0159, -3.7835],
              [144.4775, -3.8253],
              [145.0879, -4.349],
              [145.3347, -4.3854],
              [145.767, -4.8231],
              [145.8527, -5.4712],
              [146.4036, -5.6164],
              [147.0344, -5.9191],
              [147.3766, -5.9506],
              [147.8018, -6.315],
              [147.8453, -6.6626],
              [147.1191, -6.7217],
              [146.9607, -6.929],
              [147.1902, -7.3782],
              [147.7242, -7.8762],
              [148.1268, -8.1035],
              [148.2471, -8.5545],
              [148.5831, -9.0516],
              [149.2476, -9.0712],
              [149.2032, -9.4068],
              [149.7612, -9.8058],
              [149.8744, -10.0131],
              [150.6672, -10.2573],
              [150.4825, -10.6368],
              [150.0169, -10.5773],
              [149.7541, -10.3531],
              [147.7685, -10.0699],
              [146.6965, -9.0255],
              [146.0334, -8.0764],
              [144.9738, -7.8021],
              [144.5099, -7.5673],
              [143.6137, -8.2003],
              [143.0948, -8.3112],
              [143.366, -8.9611],
              [142.6474, -9.3277],
              [142.2297, -9.1697],
              [141.1332, -9.2212],
              [140.9761, -9.1187],
            ],
          ],
        ],
      },
      properties: {
        name: 'Papua New Guinea',
        id: 'PG',
      },
      id: 'PG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [134.5956, 7.3821],
            [134.5064, 7.4372],
            [134.5561, 7.5939],
            [134.6595, 7.6631],
            [134.5956, 7.3821],
          ],
        ],
      },
      properties: {
        name: 'Palau',
        id: 'PW',
      },
      id: 'PW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [126.0059, 9.3211],
              [126.1932, 9.2767],
              [126.3197, 8.8448],
              [126.4355, 7.8327],
              [126.5816, 7.2476],
              [126.08, 6.7332],
              [125.8243, 7.3333],
              [125.4008, 6.7957],
              [125.5886, 6.4659],
              [125.6681, 5.9785],
              [125.4559, 5.6643],
              [124.6364, 5.9981],
              [124.078, 6.4042],
              [123.9853, 6.9937],
              [124.2068, 7.3963],
              [123.9684, 7.6644],
              [123.493, 7.8078],
              [123.3909, 7.4074],
              [122.4978, 7.6729],
              [122.176, 7.0044],
              [122.0472, 7.3635],
              [122.132, 7.8105],
              [122.3371, 8.0284],
              [122.911, 8.1563],
              [123.0176, 8.3982],
              [123.4344, 8.7032],
              [124.2258, 8.2712],
              [124.4513, 8.6064],
              [124.731, 8.5629],
              [124.869, 8.9722],
              [125.5336, 9.1408],
              [125.5211, 9.7592],
              [126.0059, 9.3211],
            ],
          ],
          [
            [
              [124.5938, 9.7871],
              [124.3599, 9.63],
              [123.9355, 9.6238],
              [123.817, 9.8173],
              [124.1726, 10.1351],
              [124.577, 10.0268],
              [124.5938, 9.7871],
            ],
          ],
          [
            [
              [123.1308, 9.064],
              [122.5626, 9.4826],
              [122.4716, 9.9616],
              [122.8556, 10.0868],
              [122.8169, 10.5036],
              [122.9834, 10.8867],
              [123.2564, 10.9941],
              [123.5676, 10.7806],
              [123.1618, 9.8644],
              [123.3083, 9.357],
              [123.1308, 9.064],
            ],
          ],
          [
            [
              [123.3705, 9.4498],
              [123.386, 9.9669],
              [123.7114, 10.4738],
              [123.952, 10.3167],
              [123.6435, 10.0202],
              [123.3705, 9.4498],
            ],
          ],
          [
            [
              [117.3109, 8.4395],
              [117.35, 8.7134],
              [117.8848, 9.2407],
              [118.1148, 9.3468],
              [119.0239, 10.3535],
              [119.2241, 10.4774],
              [119.2614, 10.8449],
              [119.5268, 10.9532],
              [119.6844, 10.5515],
              [119.1917, 10.061],
              [118.782, 9.9163],
              [118.4348, 9.2562],
              [117.9896, 8.8772],
              [117.3109, 8.4395],
            ],
          ],
          [
            [
              [124.5747, 11.343],
              [124.9299, 11.3727],
              [125.0133, 10.7854],
              [125.2535, 10.2639],
              [125.0266, 10.0331],
              [124.7807, 10.168],
              [124.7869, 10.7815],
              [124.4456, 10.9235],
              [124.5747, 11.343],
            ],
          ],
          [
            [
              [122.4964, 11.6151],
              [123.1583, 11.5356],
              [123.0167, 11.1166],
              [122.7699, 10.8236],
              [122.1977, 10.623],
              [121.9642, 10.8716],
              [122.1036, 11.643],
              [122.4964, 11.6151],
            ],
          ],
          [
            [
              [125.2397, 12.5277],
              [125.5358, 12.1913],
              [125.4568, 11.9524],
              [125.6281, 11.1321],
              [125.2335, 11.145],
              [124.8841, 11.7753],
              [124.3848, 12.2441],
              [124.2946, 12.5695],
              [125.2397, 12.5277],
            ],
          ],
          [
            [
              [120.7045, 13.4794],
              [121.2029, 13.4324],
              [121.5225, 13.131],
              [121.5407, 12.6383],
              [121.2367, 12.2188],
              [120.922, 12.5117],
              [120.7635, 12.9698],
              [120.5082, 13.2601],
              [120.7045, 13.4794],
            ],
          ],
          [
            [
              [121.1017, 18.6153],
              [121.8457, 18.2952],
              [122.2998, 18.4026],
              [122.1511, 17.7563],
              [122.2368, 17.435],
              [122.5191, 17.1247],
              [122.1351, 16.185],
              [121.5953, 15.9333],
              [121.3925, 15.3242],
              [121.6957, 14.7374],
              [121.8004, 14.1138],
              [122.4907, 14.3224],
              [122.856, 14.2509],
              [123.2315, 13.7475],
              [123.4322, 13.9664],
              [123.726, 13.8843],
              [123.6079, 13.5283],
              [124.1428, 13.036],
              [124.0598, 12.5672],
              [123.311, 13.044],
              [123.1632, 13.4417],
              [122.5954, 13.9078],
              [122.4068, 13.4927],
              [121.7778, 13.9375],
              [121.2034, 13.6401],
              [120.6166, 14.1879],
              [120.922, 14.4933],
              [120.8882, 14.7157],
              [120.0821, 14.8511],
              [119.8309, 16.3266],
              [120.1598, 16.0478],
              [120.3893, 16.2218],
              [120.3054, 16.5294],
              [120.4088, 16.9555],
              [120.3582, 17.6383],
              [120.5997, 18.5078],
              [121.1017, 18.6153],
            ],
          ],
        ],
      },
      properties: {
        name: 'Philippines',
        id: 'PH',
      },
      id: 'PH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.9658, -4.2359],
            [-70.7995, -4.1733],
            [-70.9737, -4.3504],
            [-71.8448, -4.5044],
            [-72.6987, -5.0673],
            [-72.8871, -5.1228],
            [-72.9799, -5.635],
            [-73.2355, -6.0984],
            [-73.1374, -6.466],
            [-73.758, -6.9059],
            [-73.7496, -7.3356],
            [-74.0021, -7.5562],
            [-73.5491, -8.3459],
            [-72.9741, -8.993],
            [-73.2093, -9.4116],
            [-72.379, -9.5102],
            [-72.1815, -10.0038],
            [-71.238, -9.9661],
            [-70.637, -9.4782],
            [-70.6423, -11.0101],
            [-70.2202, -11.0478],
            [-69.5785, -10.9519],
            [-68.6852, -12.502],
            [-68.9786, -12.8802],
            [-69.0741, -13.6828],
            [-68.8803, -14.199],
            [-69.235, -14.5972],
            [-69.3748, -14.963],
            [-69.1726, -15.2368],
            [-69.4185, -15.6035],
            [-69.2176, -16.149],
            [-68.9134, -16.2618],
            [-69.0208, -16.6422],
            [-69.6249, -17.2002],
            [-69.5108, -17.506],
            [-69.8522, -17.704],
            [-69.9263, -18.206],
            [-70.4184, -18.3454],
            [-71.337, -17.6827],
            [-71.5323, -17.2943],
            [-72.1114, -17.0026],
            [-72.4676, -16.7079],
            [-73.825, -16.153],
            [-74.1471, -15.9124],
            [-75.1043, -15.4117],
            [-75.5336, -14.899],
            [-75.934, -14.6336],
            [-76.2891, -14.1333],
            [-76.1839, -13.5154],
            [-76.8322, -12.3489],
            [-77.1527, -12.0603],
            [-77.2204, -11.6635],
            [-77.6332, -11.288],
            [-77.7362, -10.8365],
            [-78.1856, -10.089],
            [-78.7622, -8.6171],
            [-79.3772, -7.8354],
            [-79.7621, -7.0666],
            [-80.1103, -6.6497],
            [-80.8117, -6.2822],
            [-81.1805, -5.9426],
            [-80.8827, -5.7589],
            [-81.1084, -5.0278],
            [-81.3366, -4.6695],
            [-81.2833, -4.3224],
            [-80.7986, -3.7312],
            [-80.3247, -3.388],
            [-80.1793, -3.8776],
            [-80.51, -4.0694],
            [-80.4885, -4.3939],
            [-80.1396, -4.2962],
            [-79.6385, -4.4547],
            [-79.3308, -4.9279],
            [-79.0763, -4.9905],
            [-78.4198, -3.7769],
            [-78.3472, -3.4311],
            [-77.8607, -2.9819],
            [-76.679, -2.5628],
            [-76.0898, -2.1331],
            [-75.5706, -1.5312],
            [-75.2632, -0.5555],
            [-75.2845, -0.1063],
            [-74.8018, -0.2],
            [-74.4178, -0.5809],
            [-74.2465, -0.9706],
            [-73.6643, -1.2489],
            [-73.4963, -1.6932],
            [-73.1969, -1.8304],
            [-73.1545, -2.2783],
            [-72.3957, -2.4288],
            [-71.7525, -2.1527],
            [-71.3969, -2.3342],
            [-70.9146, -2.2184],
            [-70.0741, -2.7502],
            [-70.7351, -3.7818],
            [-70.3396, -3.8142],
            [-69.9658, -4.2359],
          ],
        ],
      },
      properties: {
        name: 'Peru',
        id: 'PE',
      },
      id: 'PE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-128.2901, -24.3974],
            [-128.3205, -24.3997],
            [-128.3503, -24.3402],
            [-128.3037, -24.3335],
            [-128.2901, -24.3974],
          ],
        ],
      },
      properties: {
        name: 'Pitcairn Islands',
        id: 'PN',
      },
      id: 'PN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3742, 8.6583],
            [-77.2124, 8.0338],
            [-77.3509, 7.7057],
            [-77.7439, 7.537],
            [-77.9011, 7.2294],
            [-78.1701, 7.5437],
            [-78.4215, 8.0608],
            [-78.1139, 8.3796],
            [-79.0865, 8.997],
            [-79.4416, 9.0059],
            [-79.7505, 8.5953],
            [-80.3687, 8.289],
            [-80.4581, 8.0768],
            [-80.0752, 7.6671],
            [-80.4388, 7.2752],
            [-80.8456, 7.2201],
            [-81.0352, 7.7111],
            [-81.5041, 7.7213],
            [-81.7276, 8.1376],
            [-82.6796, 8.3218],
            [-82.8793, 8.0706],
            [-82.9976, 8.3676],
            [-82.917, 8.7404],
            [-82.9399, 9.4493],
            [-82.5635, 9.5767],
            [-82.2441, 9.0316],
            [-81.3548, 8.7804],
            [-80.8387, 8.8874],
            [-80.1272, 9.2101],
            [-79.5774, 9.598],
            [-78.5043, 9.4063],
            [-78.0828, 9.2363],
            [-77.3742, 8.6583],
          ],
        ],
      },
      properties: {
        name: 'Panama',
        id: 'PA',
      },
      id: 'PA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.7992, 35.496],
            [77.0486, 35.1099],
            [76.5945, 34.7357],
            [75.7093, 34.5031],
            [74.3004, 34.7654],
            [73.9613, 34.6535],
            [74.1499, 33.507],
            [73.9941, 33.2424],
            [74.6578, 32.5189],
            [75.3334, 32.2792],
            [74.5099, 31.7127],
            [74.6329, 31.0345],
            [73.8991, 30.4352],
            [73.809, 30.0934],
            [73.3816, 29.9345],
            [72.903, 29.029],
            [72.342, 28.752],
            [71.8701, 27.9623],
            [70.7373, 27.7293],
            [70.4035, 28.0249],
            [70.1447, 27.8491],
            [69.5681, 27.1744],
            [69.47, 26.8046],
            [70.1567, 26.4713],
            [70.0777, 26.0718],
            [70.2646, 25.7064],
            [70.6521, 25.4228],
            [71.048, 24.6877],
            [71.044, 24.4001],
            [70.5469, 24.4183],
            [69.7163, 24.1728],
            [69.5592, 24.2731],
            [68.7282, 24.2656],
            [68.7242, 23.9646],
            [68.1649, 23.8572],
            [67.6687, 23.811],
            [67.3096, 24.175],
            [67.1715, 24.7561],
            [66.7032, 24.8608],
            [66.6988, 25.2261],
            [66.4284, 25.5755],
            [65.6796, 25.3553],
            [64.7767, 25.3074],
            [64.6591, 25.184],
            [63.7207, 25.3859],
            [63.4912, 25.2106],
            [62.5723, 25.2546],
            [62.3153, 25.1347],
            [61.5878, 25.2022],
            [61.6686, 25.769],
            [61.8697, 26.2422],
            [62.4392, 26.5609],
            [63.1681, 26.6657],
            [63.1667, 27.2525],
            [62.7632, 27.2503],
            [62.7397, 28.0023],
            [62.3531, 28.4146],
            [61.8901, 28.5465],
            [61.6229, 28.7915],
            [61.3184, 29.3725],
            [60.8434, 29.8586],
            [62.4765, 29.4085],
            [63.5676, 29.4982],
            [64.0989, 29.3921],
            [64.521, 29.5643],
            [65.0954, 29.5594],
            [66.1772, 29.8355],
            [66.3974, 30.9124],
            [66.9243, 31.3057],
            [67.2874, 31.2178],
            [67.5781, 31.5063],
            [68.2138, 31.8073],
            [68.869, 31.6342],
            [69.2791, 31.9369],
            [69.2414, 32.4336],
            [69.5015, 33.02],
            [69.9201, 33.1123],
            [70.2841, 33.3689],
            [69.8682, 33.8976],
            [71.0516, 34.0498],
            [70.9655, 34.5306],
            [71.6206, 35.1831],
            [71.4276, 35.8338],
            [71.1852, 36.042],
            [71.6206, 36.4366],
            [72.623, 36.8295],
            [73.7691, 36.8885],
            [74.0017, 36.8233],
            [74.5415, 37.0221],
            [75.3467, 36.9134],
            [75.8403, 36.6497],
            [75.945, 36.0176],
            [76.2518, 35.8108],
            [77.4467, 35.4756],
            [77.7992, 35.496],
          ],
        ],
      },
      properties: {
        name: 'Pakistan',
        id: 'PK',
      },
      id: 'PK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [56.388, 24.9793],
            [56.6406, 24.4702],
            [57.2199, 23.9229],
            [58.5782, 23.6437],
            [59.7998, 22.2201],
            [59.3714, 21.4988],
            [58.8956, 21.1126],
            [58.4743, 20.4068],
            [57.8618, 20.2439],
            [57.7153, 19.6069],
            [57.8116, 19.017],
            [57.1764, 18.9025],
            [56.6553, 18.5873],
            [56.551, 18.166],
            [56.2704, 17.9508],
            [55.479, 17.8433],
            [55.0644, 17.039],
            [54.0683, 17.0057],
            [53.6097, 16.7598],
            [53.0855, 16.6484],
            [52.729, 17.3005],
            [51.9775, 18.9961],
            [53.667, 19.559],
            [54.9774, 19.9958],
            [55.641, 22.0017],
            [55.186, 22.704],
            [55.1997, 23.0346],
            [55.7608, 24.2425],
            [55.7959, 24.8679],
            [56.064, 24.7388],
            [56.388, 24.9793],
          ],
        ],
      },
      properties: {
        name: 'Oman',
        id: 'OM',
      },
      id: 'OM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [173.1152, -41.2791],
              [173.738, -40.9888],
              [174.3026, -41.0194],
              [174.0922, -41.505],
              [174.2831, -41.7407],
              [173.5449, -42.518],
              [173.2213, -42.9765],
              [172.7184, -43.2589],
              [172.9208, -43.8914],
              [172.3855, -43.8297],
              [171.4426, -44.136],
              [171.1133, -45.0393],
              [170.7005, -45.6843],
              [169.6866, -46.5516],
              [168.382, -46.6054],
              [168.1889, -46.3621],
              [167.682, -46.193],
              [166.7316, -46.1979],
              [166.7338, -45.5436],
              [167.1946, -44.9634],
              [167.7872, -44.595],
              [168.3665, -44.0818],
              [168.8064, -43.9922],
              [169.6613, -43.5913],
              [170.3027, -43.1075],
              [170.7417, -42.9273],
              [171.2571, -42.4652],
              [171.4861, -41.7949],
              [172.0108, -41.4447],
              [172.1396, -40.9471],
              [172.6407, -40.5183],
              [172.9887, -40.8481],
              [173.1152, -41.2791],
            ],
          ],
          [
            [
              [173.2693, -34.935],
              [173.8441, -35.0264],
              [174.3204, -35.2466],
              [174.5437, -35.5822],
              [174.391, -35.7739],
              [174.802, -36.3093],
              [174.7226, -36.8411],
              [175.2996, -36.9933],
              [175.4608, -36.4757],
              [175.8762, -36.9578],
              [176.1084, -37.6449],
              [177.3358, -37.9907],
              [178.0091, -37.5548],
              [178.536, -37.692],
              [178.2679, -38.5509],
              [177.9763, -38.7223],
              [177.9656, -39.1426],
              [177.4077, -39.0809],
              [176.954, -39.3677],
              [177.1098, -39.6731],
              [176.8421, -40.1578],
              [175.9828, -41.2134],
              [175.3098, -41.6107],
              [174.6356, -41.2893],
              [175.1625, -40.6217],
              [175.2543, -40.2892],
              [175.0093, -39.9523],
              [173.9342, -39.5093],
              [173.8441, -39.1395],
              [174.5974, -38.7849],
              [174.9281, -37.8043],
              [174.7292, -37.4487],
              [174.782, -36.9436],
              [174.4758, -36.9418],
              [174.4469, -36.4509],
              [173.9453, -36.1761],
              [173.1166, -35.2053],
              [173.2693, -34.935],
            ],
          ],
        ],
      },
      properties: {
        name: 'New Zealand',
        id: 'NZ',
      },
      id: 'NZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [20.898, 80.2499],
              [22.4507, 80.4022],
              [26.8608, 80.16],
              [27.1986, 79.9065],
              [25.641, 79.403],
              [23.9475, 79.1943],
              [20.8611, 79.3978],
              [18.7251, 79.7607],
              [18.3438, 80.0595],
              [20.898, 80.2499],
            ],
          ],
          [
            [
              [16.7867, 79.9068],
              [18.5813, 79.5716],
              [19.0896, 79.157],
              [20.4581, 79.1293],
              [21.3889, 78.7404],
              [19.7687, 78.6228],
              [18.4393, 78.0251],
              [16.7005, 76.5794],
              [14.3657, 77.2345],
              [14.921, 77.6889],
              [13.6807, 78.028],
              [11.7738, 78.7165],
              [10.866, 79.7965],
              [12.7535, 79.7758],
              [13.3838, 79.4809],
              [15.8163, 79.6819],
              [16.7867, 79.9068],
            ],
          ],
          [
            [
              [21.6082, 78.5957],
              [23.1166, 77.9916],
              [24.2383, 77.8986],
              [23.8414, 77.4978],
              [22.057, 77.5011],
              [21.6082, 77.9161],
              [20.2282, 78.4779],
              [21.6082, 78.5957],
            ],
          ],
        ],
      },
      properties: {
        name: 'Svalbard and Jan Mayen',
        id: 'SJ',
      },
      id: 'SJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [166.9584, -0.5165],
            [166.9163, -0.5467],
            [166.9136, -0.4992],
            [166.9584, -0.5165],
          ],
        ],
      },
      properties: {
        name: 'Nauru',
        id: 'NR',
      },
      id: 'NR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.1099, 27.8704],
            [87.9843, 27.134],
            [88.1614, 26.7247],
            [88.0549, 26.43],
            [87.2874, 26.3603],
            [87.0166, 26.5556],
            [86.7014, 26.4349],
            [86.3663, 26.5743],
            [85.293, 26.7412],
            [84.6853, 27.0412],
            [84.6103, 27.2987],
            [84.0909, 27.4913],
            [83.2897, 27.371],
            [82.7335, 27.5189],
            [81.9877, 27.9139],
            [81.8528, 27.8669],
            [80.5868, 28.6494],
            [80.0706, 28.8301],
            [80.4017, 29.7303],
            [81.0103, 30.1645],
            [81.4169, 30.3376],
            [82.0432, 30.3269],
            [82.1591, 30.1152],
            [82.8542, 29.6833],
            [83.1556, 29.6127],
            [83.5835, 29.1834],
            [84.1016, 29.2198],
            [84.2285, 28.9118],
            [84.7141, 28.5957],
            [85.1589, 28.5922],
            [85.1225, 28.3161],
            [85.6783, 28.2775],
            [86.4089, 27.9286],
            [86.5545, 28.0853],
            [87.1413, 27.8385],
            [88.1099, 27.8704],
          ],
        ],
      },
      properties: {
        name: 'Nepal',
        id: 'NP',
      },
      id: 'NP',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.8696, 69.7834],
            [28.9657, 69.0219],
            [29.1415, 69.6715],
            [27.8897, 70.0617],
            [26.5257, 69.915],
            [26.0116, 69.6526],
            [25.7484, 68.9901],
            [24.9414, 68.5933],
            [23.8539, 68.8059],
            [22.4108, 68.7198],
            [21.622, 69.2707],
            [20.6223, 69.037],
            [19.9698, 68.3565],
            [18.3785, 68.5624],
            [17.9168, 67.965],
            [17.3247, 68.1039],
            [16.1275, 67.4259],
            [16.4036, 67.055],
            [15.423, 66.4899],
            [14.5432, 66.1292],
            [14.4798, 65.3014],
            [13.6501, 64.5816],
            [14.0776, 64.464],
            [13.9604, 64.0141],
            [12.6625, 63.9404],
            [11.9997, 63.2917],
            [12.2181, 63.0007],
            [12.3034, 62.2856],
            [12.1555, 61.7207],
            [12.8809, 61.3523],
            [12.294, 61.0027],
            [12.5888, 60.4508],
            [12.4862, 60.1067],
            [11.6806, 59.5923],
            [11.7982, 59.29],
            [11.3885, 59.0365],
            [10.8345, 59.1839],
            [9.6182, 58.946],
            [8.1663, 58.1452],
            [7.0051, 58.0242],
            [5.7067, 58.5236],
            [5.8452, 59.3535],
            [5.1314, 59.2265],
            [5.2441, 60.5695],
            [5.0493, 60.7076],
            [4.9276, 61.7107],
            [5.5336, 62.3109],
            [6.353, 62.6112],
            [7.2838, 62.6023],
            [7.0086, 62.9576],
            [8.1006, 63.091],
            [8.398, 63.5351],
            [9.6968, 63.6246],
            [9.7079, 63.865],
            [11.3499, 64.9059],
            [12.1595, 65.1789],
            [12.2727, 65.5682],
            [12.9763, 66.0192],
            [13.1046, 66.5394],
            [14.755, 67.4991],
            [14.7989, 67.8094],
            [16.0644, 68.2],
            [16.6517, 68.6257],
            [18.1015, 69.1564],
            [18.2932, 69.4751],
            [20.0688, 69.8835],
            [21.163, 69.8895],
            [21.4005, 70.1744],
            [22.6847, 70.3749],
            [23.0464, 70.1019],
            [24.4198, 70.702],
            [24.6582, 71.001],
            [25.7684, 70.8531],
            [27.5972, 71.0913],
            [29.1024, 70.8607],
            [30.9442, 70.2745],
            [29.6014, 69.9767],
            [29.7923, 69.7279],
            [30.8696, 69.7834],
          ],
        ],
      },
      properties: {
        name: 'Norway',
        id: 'NO',
      },
      id: 'NO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-169.8034, -19.0832],
            [-169.9039, -19.1378],
            [-169.9483, -19.0729],
            [-169.834, -18.966],
            [-169.8034, -19.0832],
          ],
        ],
      },
      properties: {
        name: 'Niue',
        id: 'NU',
      },
      id: 'NU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1576, 14.9931],
            [-83.2992, 14.749],
            [-83.1878, 14.3401],
            [-83.4124, 13.9966],
            [-83.5673, 13.3205],
            [-83.511, 12.4119],
            [-83.7693, 11.9316],
            [-83.6517, 11.6422],
            [-83.8679, 11.2999],
            [-83.6419, 10.9173],
            [-84.1684, 10.7806],
            [-84.3484, 10.9799],
            [-84.9092, 10.9453],
            [-85.6215, 11.1845],
            [-85.7444, 11.062],
            [-86.4688, 11.7385],
            [-86.7556, 12.1566],
            [-87.6676, 12.9037],
            [-87.3373, 12.9792],
            [-87.0592, 12.9916],
            [-86.7108, 13.3134],
            [-86.7336, 13.7635],
            [-86.3317, 13.7702],
            [-86.1512, 13.9948],
            [-85.7868, 13.8443],
            [-85.1796, 14.3432],
            [-84.9851, 14.7525],
            [-84.646, 14.6611],
            [-83.8674, 14.7947],
            [-83.1576, 14.9931],
          ],
        ],
      },
      properties: {
        name: 'Nicaragua',
        id: 'NI',
      },
      id: 'NI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.6062, 13.7045],
            [14.0638, 13.0786],
            [14.1974, 12.3839],
            [14.6271, 12.1087],
            [14.5597, 11.4921],
            [13.892, 11.1401],
            [13.5352, 10.6052],
            [13.2697, 10.0362],
            [13.1756, 9.5394],
            [12.9297, 9.4263],
            [12.8067, 8.8865],
            [12.4037, 8.5957],
            [12.0162, 7.5899],
            [11.7676, 7.272],
            [11.5518, 6.6972],
            [11.1532, 6.438],
            [11.0325, 6.6981],
            [10.6064, 7.063],
            [10.1438, 6.9964],
            [9.0603, 6.0092],
            [8.801, 5.1973],
            [8.556, 4.7552],
            [8.2932, 4.5576],
            [7.1546, 4.5146],
            [6.7569, 4.3437],
            [6.0764, 4.2904],
            [5.5877, 4.6473],
            [5.2326, 5.4836],
            [4.8611, 6.0265],
            [4.4314, 6.3487],
            [4.126, 6.4113],
            [2.7064, 6.3692],
            [2.7748, 6.7119],
            [2.686, 7.874],
            [2.7748, 9.0485],
            [3.0451, 9.084],
            [3.3252, 9.7783],
            [3.5573, 9.9074],
            [3.5764, 10.2683],
            [3.8343, 10.6074],
            [3.7163, 11.0797],
            [3.4877, 11.3954],
            [3.5955, 11.6963],
            [3.6466, 12.5299],
            [3.948, 12.775],
            [4.1477, 13.4577],
            [4.8233, 13.76],
            [5.4918, 13.8727],
            [6.3863, 13.6037],
            [6.8044, 13.1074],
            [7.0565, 13],
            [7.7885, 13.3378],
            [8.0953, 13.2912],
            [8.7504, 12.9081],
            [9.616, 12.8105],
            [10.1847, 13.2699],
            [10.9588, 13.3716],
            [11.412, 13.3538],
            [12.4632, 13.0937],
            [12.6549, 13.3267],
            [13.3239, 13.6707],
            [13.6062, 13.7045],
          ],
        ],
      },
      properties: {
        name: 'Nigeria',
        id: 'NG',
      },
      id: 'NG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [167.9394, -29.0175],
            [167.979, -29.0756],
            [167.9204, -29.0827],
            [167.9394, -29.0175],
          ],
        ],
      },
      properties: {
        name: 'Norfolk Island',
        id: 'NF',
      },
      id: 'NF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.9791, 22.996],
            [15.1722, 21.9223],
            [15.182, 21.5232],
            [15.6072, 20.9546],
            [15.5873, 20.7335],
            [15.949, 20.3034],
            [15.7351, 19.9039],
            [15.4745, 16.9085],
            [14.3679, 15.7499],
            [13.4481, 14.3805],
            [13.6062, 13.7045],
            [13.3239, 13.6707],
            [12.6549, 13.3267],
            [12.4632, 13.0937],
            [11.412, 13.3538],
            [10.9588, 13.3716],
            [10.1847, 13.2699],
            [9.616, 12.8105],
            [8.7504, 12.9081],
            [8.0953, 13.2912],
            [7.7885, 13.3378],
            [7.0565, 13],
            [6.8044, 13.1074],
            [6.3863, 13.6037],
            [5.4918, 13.8727],
            [4.8233, 13.76],
            [4.1477, 13.4577],
            [3.948, 12.775],
            [3.6466, 12.5299],
            [3.5955, 11.6963],
            [2.8782, 12.3679],
            [2.3659, 12.2219],
            [2.389, 11.897],
            [2.0912, 12.2778],
            [2.0739, 12.7142],
            [1.5652, 12.6356],
            [0.9872, 13.0418],
            [0.9779, 13.5518],
            [0.6183, 13.7036],
            [0.1638, 14.4973],
            [0.2175, 14.9114],
            [0.9601, 14.9869],
            [1.3002, 15.2723],
            [3.5041, 15.3562],
            [3.8769, 15.7553],
            [4.1823, 16.5818],
            [4.2347, 16.9964],
            [4.2276, 19.1426],
            [5.8367, 19.4791],
            [7.4818, 20.8729],
            [9.3785, 21.9915],
            [11.9678, 23.518],
            [13.4814, 23.1802],
            [14.2307, 22.6183],
            [14.9791, 22.996],
          ],
        ],
      },
      properties: {
        name: 'Niger',
        id: 'NE',
      },
      id: 'NE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [164.2023, -20.2462],
            [164.4358, -20.2821],
            [165.1918, -20.7691],
            [165.6627, -21.2671],
            [166.9425, -22.0901],
            [166.7742, -22.3759],
            [166.096, -21.9565],
            [165.2419, -21.5255],
            [164.4549, -20.829],
            [164.1699, -20.4801],
            [164.2023, -20.2462],
          ],
        ],
      },
      properties: {
        name: 'New Caledonia',
        id: 'NC',
      },
      id: 'NC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.3807, -17.6405],
            [24.2751, -17.4812],
            [24.9325, -17.5433],
            [25.2588, -17.7936],
            [24.359, -17.9783],
            [23.5804, -18.4528],
            [23.2986, -18.0276],
            [20.9739, -18.3188],
            [20.9779, -20.9684],
            [20.9708, -22],
            [19.9774, -22],
            [19.9805, -24.777],
            [19.9805, -26.5312],
            [19.9805, -28.4515],
            [19.6715, -28.5039],
            [19.2458, -28.9016],
            [18.1028, -28.8719],
            [17.4481, -28.6983],
            [17.3855, -28.3534],
            [17.0561, -28.0311],
            [16.7556, -28.4524],
            [16.4475, -28.6175],
            [15.7191, -27.9659],
            [15.3413, -27.3866],
            [14.9676, -26.3182],
            [14.8451, -25.7256],
            [14.8371, -25.0331],
            [14.5015, -24.2021],
            [14.474, -23.281],
            [14.5259, -22.7027],
            [14.3217, -22.19],
            [13.9733, -21.7678],
            [13.4504, -20.9169],
            [13.1685, -20.1845],
            [12.4583, -18.9269],
            [12.0956, -18.5412],
            [11.776, -18.0018],
            [11.7432, -17.2494],
            [12.5479, -17.2126],
            [13.101, -16.9676],
            [13.4038, -17.008],
            [13.9382, -17.3888],
            [16.9136, -17.3915],
            [18.3962, -17.3995],
            [18.8259, -17.7661],
            [19.6396, -17.8687],
            [20.3928, -17.8873],
            [20.625, -17.9965],
            [21.4169, -18.0005],
            [23.3807, -17.6405],
          ],
        ],
      },
      properties: {
        name: 'Namibia',
        id: 'NA',
      },
      id: 'NA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [45.1802, -12.9765],
            [45.0692, -12.8957],
            [45.1349, -12.7093],
            [45.2233, -12.7519],
            [45.1802, -12.9765],
          ],
        ],
      },
      properties: {
        name: 'Mayotte',
        id: 'YT',
      },
      id: 'YT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [102.1009, 6.2422],
              [102.9825, 5.5249],
              [103.4157, 4.8502],
              [103.4686, 4.3934],
              [103.362, 3.7693],
              [103.4397, 2.933],
              [103.8121, 2.5805],
              [104.2884, 1.4806],
              [103.6945, 1.4495],
              [102.7273, 1.8557],
              [101.2953, 2.8851],
              [101.2997, 3.2535],
              [100.7155, 3.9664],
              [100.6143, 4.6522],
              [100.3524, 5.5875],
              [100.3431, 5.9843],
              [100.119, 6.442],
              [100.3453, 6.5498],
              [101.0533, 6.2427],
              [101.2571, 5.7894],
              [101.5563, 5.908],
              [101.8737, 5.8254],
              [102.1009, 6.2422],
            ],
          ],
          [
            [
              [117.5746, 4.1706],
              [117.1005, 4.337],
              [115.8962, 4.3486],
              [115.5686, 3.9389],
              [115.4545, 3.0342],
              [115.0861, 2.8411],
              [115.1509, 2.4931],
              [114.7865, 2.2503],
              [114.8304, 1.98],
              [114.5459, 1.4673],
              [113.9022, 1.4344],
              [113.6222, 1.236],
              [112.476, 1.5592],
              [112.1857, 1.4393],
              [112.0783, 1.1432],
              [111.7698, 0.9994],
              [110.9379, 1.0172],
              [110.5056, 0.8618],
              [109.6542, 1.6151],
              [109.6289, 2.0275],
              [109.9845, 1.7176],
              [110.3493, 1.7198],
              [110.9402, 1.5174],
              [111.1541, 1.7389],
              [111.242, 2.4358],
              [111.7276, 2.854],
              [112.7375, 3.0706],
              [113.0446, 3.2051],
              [113.924, 4.2434],
              [114.0638, 4.5927],
              [114.6085, 4.0241],
              [114.8402, 4.3934],
              [114.7465, 4.7179],
              [115.0266, 4.8999],
              [115.1398, 4.8999],
              [115.5198, 5.049],
              [115.419, 5.413],
              [115.7968, 5.536],
              [116.1386, 6.1295],
              [116.4946, 6.5219],
              [117.294, 6.6768],
              [117.6456, 6.4735],
              [117.6172, 5.9408],
              [118.0038, 6.0531],
              [118.1157, 5.8627],
              [118.5946, 5.5919],
              [119.2236, 5.4126],
              [119.1322, 5.1005],
              [118.1854, 4.8284],
              [118.4979, 4.3623],
              [117.5746, 4.1706],
            ],
          ],
        ],
      },
      properties: {
        name: 'Malaysia',
        id: 'MY',
      },
      id: 'MY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.9596, -11.5783],
            [34.6595, -11.5885],
            [34.3608, -12.2104],
            [34.5636, -13.3601],
            [35.2477, -13.8967],
            [35.8474, -14.6709],
            [35.7555, -16.0585],
            [35.3587, -16.1606],
            [35.1673, -16.5601],
            [35.2903, -17.0967],
            [34.3954, -16.1992],
            [34.248, -15.8876],
            [34.541, -15.2972],
            [34.5055, -14.5981],
            [34.3324, -14.4085],
            [33.6364, -14.5683],
            [33.2018, -14.0135],
            [32.9812, -14.0095],
            [32.6722, -13.6104],
            [32.9679, -13.2251],
            [33.0216, -12.6303],
            [33.398, -12.49],
            [33.2524, -12.1127],
            [33.2262, -11.5348],
            [33.3798, -11.1579],
            [33.2613, -10.8934],
            [33.659, -10.5906],
            [33.3509, -9.8622],
            [32.9199, -9.4072],
            [34.3208, -9.7317],
            [34.5241, -10.03],
            [34.6617, -10.71],
            [34.608, -11.0807],
            [34.9596, -11.5783],
          ],
        ],
      },
      properties: {
        name: 'Malawi',
        id: 'MW',
      },
      id: 'MW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [57.6514, -20.485],
            [57.3859, -20.2284],
            [57.5759, -19.9971],
            [57.7921, -20.2124],
            [57.6514, -20.485],
          ],
        ],
      },
      properties: {
        name: 'Mauritius',
        id: 'MU',
      },
      id: 'MU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-60.8263, 14.4946],
            [-61.0638, 14.4671],
            [-61.2198, 14.8044],
            [-60.9186, 14.7352],
            [-60.8263, 14.4946],
          ],
        ],
      },
      properties: {
        name: 'Martinique',
        id: 'MQ',
      },
      id: 'MQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-62.1484, 16.7403],
            [-62.1542, 16.6812],
            [-62.2217, 16.6994],
            [-62.223, 16.7518],
            [-62.1484, 16.7403],
          ],
        ],
      },
      properties: {
        name: 'Montserrat',
        id: 'MS',
      },
      id: 'MS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-12.2807, 14.8089],
            [-12.8584, 15.2426],
            [-13.2579, 15.7002],
            [-13.4095, 16.0593],
            [-13.8685, 16.1481],
            [-14.3, 16.5805],
            [-15.1128, 16.6448],
            [-15.6208, 16.5068],
            [-16.1683, 16.5472],
            [-16.4411, 16.2045],
            [-16.5352, 15.8383],
            [-16.5356, 16.2866],
            [-16.0789, 17.5459],
            [-16.0849, 18.5212],
            [-16.2132, 19.0032],
            [-16.5146, 19.3619],
            [-16.2835, 19.7871],
            [-16.2105, 20.2279],
            [-16.4298, 20.6523],
            [-17.0481, 20.8063],
            [-16.9647, 21.3292],
            [-15.0018, 21.3314],
            [-13.0163, 21.3341],
            [-13.0944, 22.4958],
            [-13.0316, 23],
            [-12.6203, 23.2712],
            [-12.0235, 23.4674],
            [-12.0164, 25.9954],
            [-10.5624, 25.9954],
            [-8.6821, 25.9954],
            [-8.6834, 27.2858],
            [-6.2387, 25.848],
            [-4.8227, 24.9958],
            [-6.594, 24.9944],
            [-6.3113, 22.5291],
            [-6.1404, 21.0367],
            [-5.8556, 18.5505],
            [-5.6286, 16.5685],
            [-5.36, 16.2831],
            [-5.5125, 15.4965],
            [-7.3555, 15.496],
            [-9.1768, 15.496],
            [-9.9414, 15.374],
            [-10.6965, 15.4228],
            [-10.8956, 15.1502],
            [-11.5026, 15.6367],
            [-11.7603, 15.4255],
            [-11.8728, 14.9953],
            [-12.2807, 14.8089],
          ],
        ],
      },
      properties: {
        name: 'Mauritania',
        id: 'MR',
      },
      id: 'MR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.8862, -26.8495],
            [32.1129, -26.8393],
            [31.9482, -25.9577],
            [31.986, -24.4605],
            [31.7995, -23.8923],
            [31.5456, -23.4821],
            [31.2877, -22.4021],
            [32.3713, -21.335],
            [32.4778, -20.7131],
            [33.0069, -19.8737],
            [32.7166, -19.0019],
            [32.9932, -18.3596],
            [32.9479, -16.7123],
            [32.2434, -16.4487],
            [31.9398, -16.4287],
            [31.2362, -16.0239],
            [30.4377, -15.9954],
            [30.3959, -15.643],
            [30.2317, -14.9905],
            [31.729, -14.496],
            [33.2018, -14.0135],
            [33.6364, -14.5683],
            [34.3324, -14.4085],
            [34.5055, -14.5981],
            [34.541, -15.2972],
            [34.248, -15.8876],
            [34.3954, -16.1992],
            [35.2903, -17.0967],
            [35.1673, -16.5601],
            [35.3587, -16.1606],
            [35.7555, -16.0585],
            [35.8474, -14.6709],
            [35.2477, -13.8967],
            [34.5636, -13.3601],
            [34.3608, -12.2104],
            [34.6595, -11.5885],
            [34.9596, -11.5783],
            [35.6312, -11.5818],
            [35.7852, -11.4531],
            [36.5185, -11.7163],
            [36.9789, -11.5667],
            [37.5417, -11.675],
            [37.9204, -11.2946],
            [38.4917, -11.4131],
            [38.7948, -11.2289],
            [39.3217, -11.1224],
            [39.9889, -10.821],
            [40.4634, -10.4641],
            [40.5447, -11.0656],
            [40.403, -11.3319],
            [40.5806, -12.6356],
            [40.5451, -13.463],
            [40.6401, -14.3899],
            [40.835, -14.7916],
            [40.5589, -15.4734],
            [40.1087, -15.9795],
            [39.8446, -16.4358],
            [39.0842, -16.9729],
            [38.145, -17.2428],
            [37.2443, -17.7399],
            [36.5403, -18.5181],
            [35.6512, -19.1639],
            [34.9481, -19.8129],
            [34.7559, -19.8218],
            [34.6981, -20.4042],
            [34.9822, -20.8063],
            [35.2677, -21.6511],
            [35.3156, -22.3968],
            [35.5753, -22.9632],
            [35.3702, -23.7982],
            [35.4381, -24.171],
            [35.1558, -24.5413],
            [34.6072, -24.8214],
            [33.3474, -25.2608],
            [32.7921, -25.6443],
            [32.5905, -26.0039],
            [32.9337, -26.2525],
            [32.8862, -26.8495],
          ],
        ],
      },
      properties: {
        name: 'Mozambique',
        id: 'MZ',
      },
      id: 'MZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [145.7519, 15.1334],
            [145.6845, 15.1249],
            [145.7133, 15.2155],
            [145.7821, 15.1747],
            [145.7519, 15.1334],
          ],
        ],
      },
      properties: {
        name: 'Northern Mariana Islands',
        id: 'MP',
      },
      id: 'MP',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [116.6832, 49.8238],
            [115.5575, 47.945],
            [115.8984, 47.6871],
            [116.7605, 47.87],
            [117.3508, 47.652],
            [117.7685, 47.988],
            [118.4983, 47.9841],
            [119.711, 47.15],
            [119.8979, 46.8579],
            [119.7066, 46.6062],
            [118.3088, 46.7172],
            [117.3335, 46.3621],
            [116.5625, 46.2897],
            [116.2407, 45.7961],
            [115.6809, 45.4583],
            [114.517, 45.3646],
            [114.0305, 44.9425],
            [113.5871, 44.7459],
            [111.8981, 45.0641],
            [111.4022, 44.3672],
            [111.9331, 43.7116],
            [111.0072, 43.3414],
            [110.4004, 42.7736],
            [109.4434, 42.4558],
            [108.1712, 42.4474],
            [106.7702, 42.2889],
            [105.1971, 41.738],
            [104.4984, 41.6586],
            [104.4984, 41.877],
            [103.7109, 41.7514],
            [103.0726, 42.0062],
            [101.9731, 42.2157],
            [101.7139, 42.4656],
            [99.984, 42.6773],
            [99.4678, 42.5681],
            [97.2057, 42.7896],
            [96.3854, 42.7204],
            [95.9126, 43.2064],
            [95.3502, 44.278],
            [94.7119, 44.3508],
            [93.6563, 44.9008],
            [92.7876, 45.0357],
            [91.5842, 45.0766],
            [90.8771, 45.196],
            [90.6618, 45.5253],
            [91.0019, 46.0358],
            [91.0338, 46.529],
            [90.87, 46.9547],
            [90.4963, 47.2854],
            [90.3134, 47.676],
            [89.561, 48.004],
            [89.0478, 48.0027],
            [88.517, 48.3844],
            [87.9798, 48.5549],
            [87.8143, 49.1624],
            [88.1925, 49.4518],
            [88.8317, 49.4485],
            [89.6542, 49.7175],
            [89.644, 49.903],
            [91.8044, 50.6936],
            [94.2511, 50.5564],
            [94.6147, 50.0237],
            [95.5229, 49.9112],
            [95.8518, 50.0129],
            [96.986, 49.8828],
            [97.2088, 49.7308],
            [98.1032, 50.0779],
            [98.2795, 50.5333],
            [97.8254, 50.9852],
            [97.947, 51.3483],
            [98.8485, 52.0701],
            [100.4687, 51.7261],
            [101.3814, 51.4526],
            [102.1116, 51.3534],
            [102.2883, 50.585],
            [102.6833, 50.3871],
            [103.6328, 50.1387],
            [105.3835, 50.4736],
            [106.218, 50.3045],
            [106.7112, 50.3125],
            [107.2332, 49.9893],
            [107.9479, 49.9247],
            [108.034, 49.594],
            [108.6137, 49.3228],
            [109.2365, 49.3348],
            [110.1997, 49.1704],
            [110.8279, 49.1661],
            [111.3366, 49.3559],
            [113.0557, 49.6162],
            [113.1641, 49.7971],
            [114.2969, 50.2743],
            [114.743, 50.2337],
            [115.2743, 49.9489],
            [116.1346, 50.0109],
            [116.6832, 49.8238],
          ],
        ],
      },
      properties: {
        name: 'Mongolia',
        id: 'MN',
      },
      id: 'MN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.1943, 43.5331],
            [19.5517, 43.2122],
            [20.3445, 42.8278],
            [20.0639, 42.5473],
            [19.6546, 42.6285],
            [19.2809, 42.1726],
            [19.3426, 41.869],
            [18.5174, 42.4327],
            [18.4362, 42.5597],
            [18.4437, 42.9685],
            [18.9404, 43.4967],
            [19.1943, 43.5331],
          ],
        ],
      },
      properties: {
        name: 'Montenegro',
        id: 'ME',
      },
      id: 'ME',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [101.139, 21.5676],
            [100.7564, 21.3128],
            [100.2495, 20.7304],
            [100.1225, 20.3167],
            [99.8904, 20.4246],
            [99.3382, 20.0788],
            [99.0208, 20.0419],
            [98.9169, 19.7729],
            [98.1112, 19.7623],
            [97.8169, 19.46],
            [97.7539, 18.621],
            [97.4849, 18.4941],
            [97.793, 17.6813],
            [98.4388, 16.9755],
            [98.6892, 16.3052],
            [98.5653, 15.4037],
            [98.1911, 15.204],
            [98.2462, 14.8147],
            [99.1366, 13.7165],
            [99.2196, 12.7399],
            [99.4052, 12.5477],
            [99.6147, 11.7811],
            [99.1903, 11.1051],
            [98.7571, 10.6612],
            [98.7025, 10.1902],
            [98.465, 10.6758],
            [98.6754, 10.987],
            [98.7416, 11.5916],
            [98.576, 13.162],
            [98.2453, 13.7333],
            [97.8121, 14.859],
            [97.7104, 15.8756],
            [97.5843, 16.0194],
            [97.6194, 16.5374],
            [97.3757, 16.5228],
            [97.1782, 17.0621],
            [96.6224, 16.564],
            [95.7635, 16.169],
            [95.3897, 15.7229],
            [94.9427, 15.8183],
            [94.5876, 16.2888],
            [94.2143, 16.1268],
            [94.589, 17.5695],
            [94.4309, 18.2016],
            [94.07, 18.8936],
            [93.7056, 19.0267],
            [93.9981, 19.4409],
            [93.3625, 20.0584],
            [93.002, 20.0748],
            [92.7357, 20.5626],
            [92.3242, 20.7917],
            [92.1795, 21.2933],
            [92.5937, 21.4673],
            [92.575, 21.9782],
            [92.9643, 22.0039],
            [93.1618, 22.3604],
            [93.0788, 22.7182],
            [93.366, 23.1323],
            [93.4149, 23.6823],
            [93.3074, 24.0219],
            [94.1277, 23.8763],
            [94.2929, 24.3219],
            [94.7075, 25.0486],
            [94.6227, 25.4099],
            [95.1323, 26.0411],
            [95.1287, 26.5973],
            [96.0613, 27.217],
            [96.6659, 27.3391],
            [97.3353, 27.9379],
            [97.3224, 28.218],
            [97.5377, 28.5101],
            [98.0988, 28.1421],
            [98.299, 27.5499],
            [98.651, 27.5726],
            [98.7318, 26.5836],
            [98.6546, 25.9177],
            [98.0109, 25.2923],
            [97.8196, 25.2519],
            [97.5315, 24.4915],
            [97.686, 23.898],
            [98.2124, 24.1106],
            [98.7642, 24.116],
            [98.8636, 23.1913],
            [99.3408, 23.0959],
            [99.3377, 22.498],
            [99.193, 22.126],
            [99.9179, 22.0279],
            [100.2148, 21.4628],
            [101.139, 21.5676],
          ],
        ],
      },
      properties: {
        name: 'Myanmar',
        id: 'MM',
      },
      id: 'MM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.5663, 35.8529],
            [14.4363, 35.8219],
            [14.3524, 35.8725],
            [14.4482, 35.9572],
            [14.5663, 35.8529],
          ],
        ],
      },
      properties: {
        name: 'Malta',
        id: 'MT',
      },
      id: 'MT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.2276, 19.1426],
            [4.2347, 16.9964],
            [4.1823, 16.5818],
            [3.8769, 15.7553],
            [3.5041, 15.3562],
            [1.3002, 15.2723],
            [0.9601, 14.9869],
            [0.2175, 14.9114],
            [-0.2359, 15.0592],
            [-0.7604, 15.0477],
            [-1.0496, 14.8195],
            [-1.7678, 14.4862],
            [-2.1132, 14.1684],
            [-2.4572, 14.274],
            [-3.1985, 13.673],
            [-3.3017, 13.2806],
            [-3.5758, 13.194],
            [-4.0512, 13.3822],
            [-4.3286, 13.119],
            [-4.2272, 12.7936],
            [-4.4807, 12.6724],
            [-4.4287, 12.3377],
            [-4.798, 12.0319],
            [-5.2881, 11.8277],
            [-5.2999, 11.2058],
            [-5.4905, 11.0425],
            [-5.5236, 10.4259],
            [-6.0345, 10.1946],
            [-6.2611, 10.7242],
            [-6.6932, 10.3495],
            [-7.1049, 10.2035],
            [-7.6611, 10.4272],
            [-7.9907, 10.1627],
            [-8.2315, 10.4379],
            [-8.4075, 11.3865],
            [-8.8219, 11.6732],
            [-9.043, 12.4025],
            [-9.8207, 12.0426],
            [-10.2748, 12.2126],
            [-10.6774, 11.8996],
            [-10.9333, 12.205],
            [-11.3053, 12.0155],
            [-11.3894, 12.4043],
            [-11.3903, 12.9419],
            [-11.6349, 13.3698],
            [-12.0543, 13.633],
            [-12.0193, 14.2065],
            [-12.2807, 14.8089],
            [-11.8728, 14.9953],
            [-11.7603, 15.4255],
            [-11.5026, 15.6367],
            [-10.8956, 15.1502],
            [-10.6965, 15.4228],
            [-9.9414, 15.374],
            [-9.1768, 15.496],
            [-7.3555, 15.496],
            [-5.5125, 15.4965],
            [-5.36, 16.2831],
            [-5.6286, 16.5685],
            [-5.8556, 18.5505],
            [-6.1404, 21.0367],
            [-6.3113, 22.5291],
            [-6.594, 24.9944],
            [-4.8227, 24.9958],
            [-3.2579, 23.981],
            [-1.2931, 22.6964],
            [1.1457, 21.1024],
            [1.1657, 20.8174],
            [1.6855, 20.3784],
            [2.2195, 20.2479],
            [2.4063, 20.0637],
            [3.2036, 19.7898],
            [3.2559, 19.4107],
            [3.1059, 19.1502],
            [3.4007, 18.9886],
            [4.2276, 19.1426],
          ],
        ],
      },
      properties: {
        name: 'Mali',
        id: 'ML',
      },
      id: 'ML',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.3442, 42.3138],
            [22.8369, 41.9937],
            [23.0056, 41.7172],
            [22.9159, 41.3363],
            [22.7246, 41.1783],
            [21.9296, 41.1073],
            [21.5758, 40.8689],
            [20.9641, 40.8498],
            [20.4892, 41.2724],
            [20.566, 41.8739],
            [21.0596, 42.1713],
            [21.5625, 42.2476],
            [22.3442, 42.3138],
          ],
        ],
      },
      properties: {
        name: 'North Macedonia',
        id: 'MK',
      },
      id: 'MK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [171.1017, 7.1384],
            [171.2269, 7.0869],
            [171.2025, 7.0736],
            [171.0955, 7.1091],
            [171.1017, 7.1384],
          ],
        ],
      },
      properties: {
        name: 'Marshall Islands',
        id: 'MH',
      },
      id: 'MH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-97.1462, 25.9617],
            [-97.5071, 25.0144],
            [-97.6676, 24.3899],
            [-97.8578, 22.6245],
            [-97.6376, 21.6035],
            [-97.1214, 20.615],
            [-96.456, 19.8697],
            [-96.2895, 19.3437],
            [-95.821, 18.7546],
            [-95.1818, 18.7005],
            [-94.798, 18.5145],
            [-94.5461, 18.1749],
            [-94.189, 18.1953],
            [-93.5523, 18.4306],
            [-92.8849, 18.4688],
            [-92.7102, 18.6117],
            [-91.9738, 18.716],
            [-91.8031, 18.4706],
            [-91.2753, 18.6246],
            [-91.343, 18.9007],
            [-90.7393, 19.3521],
            [-90.5072, 19.9119],
            [-90.4841, 20.5564],
            [-90.3531, 21.0096],
            [-89.8198, 21.2746],
            [-88.8787, 21.414],
            [-88.1317, 21.616],
            [-86.8242, 21.4215],
            [-86.8648, 20.8849],
            [-87.4672, 20.1023],
            [-87.4248, 19.5834],
            [-87.7619, 18.4461],
            [-88.1967, 18.7196],
            [-88.2957, 18.4723],
            [-88.523, 18.4457],
            [-88.8064, 17.9654],
            [-89.1615, 17.8149],
            [-90.9892, 17.8163],
            [-90.993, 17.2525],
            [-91.4096, 17.2561],
            [-90.7107, 16.7083],
            [-90.417, 16.3909],
            [-90.4472, 16.0726],
            [-91.7365, 16.07],
            [-92.1871, 15.3207],
            [-92.0748, 15.0743],
            [-92.2352, 14.5452],
            [-92.809, 15.1387],
            [-93.916, 16.0536],
            [-94.3741, 16.2848],
            [-94.6615, 16.2018],
            [-94.9004, 16.4176],
            [-95.4643, 15.9745],
            [-96.5108, 15.6518],
            [-97.1846, 15.9093],
            [-97.7548, 15.967],
            [-98.5203, 16.3048],
            [-98.7622, 16.5347],
            [-99.6906, 16.7198],
            [-100.0244, 16.9205],
            [-100.8478, 17.2006],
            [-101.6002, 17.6516],
            [-101.847, 17.9223],
            [-102.6995, 18.0626],
            [-103.4415, 18.3254],
            [-103.9125, 18.8283],
            [-104.9385, 19.3095],
            [-105.4821, 19.9762],
            [-105.6694, 20.3855],
            [-105.237, 21.1193],
            [-105.2086, 21.4908],
            [-105.6492, 21.988],
            [-105.7917, 22.6276],
            [-106.4023, 23.1958],
            [-106.9354, 23.8812],
            [-107.9512, 24.6149],
            [-108.3736, 25.1942],
            [-109.385, 25.7273],
            [-109.4256, 26.0327],
            [-109.1997, 26.3052],
            [-109.4829, 26.7105],
            [-109.7548, 26.703],
            [-109.9441, 27.0794],
            [-110.4779, 27.3227],
            [-110.5298, 27.8642],
            [-111.1215, 27.9672],
            [-111.6801, 28.4706],
            [-112.1618, 29.0188],
            [-112.2235, 29.2696],
            [-112.7384, 29.9856],
            [-113.1104, 30.7934],
            [-113.0467, 31.1792],
            [-114.1493, 31.5072],
            [-114.8395, 31.7984],
            [-114.8819, 31.1565],
            [-114.6334, 30.5067],
            [-114.6498, 30.2381],
            [-114.3726, 29.8302],
            [-113.7556, 29.3677],
            [-113.4997, 28.9269],
            [-112.8709, 28.4244],
            [-112.7339, 27.826],
            [-112.3291, 27.5233],
            [-112.0091, 26.9671],
            [-111.5696, 26.7074],
            [-111.2917, 25.7899],
            [-111.0345, 25.5271],
            [-110.6774, 24.7885],
            [-110.5471, 24.2141],
            [-110.0229, 24.1746],
            [-109.421, 23.4803],
            [-109.458, 23.2149],
            [-109.9234, 22.8859],
            [-110.3627, 23.6051],
            [-111.4194, 24.329],
            [-112.0725, 24.84],
            [-112.0557, 25.488],
            [-112.3773, 26.2138],
            [-113.0207, 26.5831],
            [-113.2058, 26.857],
            [-113.5986, 26.7212],
            [-113.841, 26.9667],
            [-114.4454, 27.2183],
            [-114.9936, 27.7359],
            [-114.3007, 27.8731],
            [-114.0485, 28.4262],
            [-114.1455, 28.6055],
            [-114.9936, 29.3845],
            [-115.6738, 29.7565],
            [-116.0622, 30.8041],
            [-116.6622, 31.5649],
            [-117.1282, 32.5335],
            [-114.7248, 32.7155],
            [-114.836, 32.5082],
            [-112.939, 31.916],
            [-111.042, 31.3243],
            [-108.2145, 31.3292],
            [-108.2118, 31.7793],
            [-106.4533, 31.77],
            [-106.2558, 31.5445],
            [-104.9179, 30.5835],
            [-104.504, 29.6775],
            [-104.1107, 29.3863],
            [-103.2577, 29.001],
            [-102.892, 29.2163],
            [-102.7342, 29.6438],
            [-102.269, 29.871],
            [-101.4404, 29.7769],
            [-100.6587, 29.0685],
            [-100.2961, 28.3276],
            [-99.5053, 27.5482],
            [-99.4436, 27.0368],
            [-99.1078, 26.4469],
            [-98.0828, 26.0642],
            [-97.3582, 25.8707],
            [-97.1462, 25.9617],
          ],
        ],
      },
      properties: {
        name: 'Mexico',
        id: 'MX',
      },
      id: 'MX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [73.5121, 4.1644],
            [73.473, 4.1706],
            [73.5041, 4.2345],
            [73.5285, 4.2434],
            [73.5121, 4.1644],
          ],
        ],
      },
      properties: {
        name: 'Maldives',
        id: 'MV',
      },
      id: 'MV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [49.5383, -12.4323],
            [49.9374, -13.0724],
            [50.174, -14.0401],
            [50.2353, -14.7321],
            [50.4829, -15.3855],
            [50.2091, -15.9604],
            [49.8926, -15.4579],
            [49.6498, -15.5671],
            [49.8389, -16.4864],
            [49.4491, -17.2406],
            [49.478, -17.8984],
            [48.6071, -20.4574],
            [48.3509, -21.3488],
            [47.9084, -22.4661],
            [47.5581, -23.8745],
            [47.1773, -24.7872],
            [46.7285, -25.1498],
            [46.1586, -25.2306],
            [45.5078, -25.5631],
            [45.1154, -25.5431],
            [44.8131, -25.334],
            [44.3457, -25.2262],
            [44.0354, -24.9958],
            [43.6874, -24.3579],
            [43.7225, -23.5296],
            [43.398, -22.8864],
            [43.2666, -22.0492],
            [43.5019, -21.3563],
            [43.8556, -21.0767],
            [44.4047, -19.9221],
            [44.4487, -19.4285],
            [44.2387, -19.0752],
            [44.2329, -18.7405],
            [44.0399, -18.2886],
            [43.9795, -17.3915],
            [44.4216, -16.7026],
            [44.4762, -16.2174],
            [44.9094, -16.1743],
            [45.1669, -15.983],
            [45.5984, -15.9928],
            [45.7, -15.8139],
            [46.3317, -15.7136],
            [46.475, -15.5134],
            [47.8116, -14.5448],
            [47.9954, -13.9606],
            [48.7966, -13.2673],
            [48.8996, -12.4585],
            [49.2072, -12.0794],
            [49.5383, -12.4323],
          ],
        ],
      },
      properties: {
        name: 'Madagascar',
        id: 'MG',
      },
      id: 'MG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.2124, 45.4503],
            [28.0997, 45.9728],
            [28.2444, 46.4513],
            [28.0717, 46.9786],
            [27.3366, 47.6396],
            [26.9806, 48.155],
            [26.6189, 48.2597],
            [27.5493, 48.4777],
            [29.1948, 47.8824],
            [29.1597, 47.4558],
            [29.5494, 47.2467],
            [29.8779, 46.829],
            [29.838, 46.3505],
            [29.1464, 46.5268],
            [28.9475, 46.05],
            [28.2124, 45.4503],
          ],
        ],
      },
      properties: {
        name: 'Moldova',
        id: 'MD',
      },
      id: 'MD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [7.4387, 43.7507],
            [7.3779, 43.7316],
            [7.4143, 43.7711],
            [7.4387, 43.7507],
          ],
        ],
      },
      properties: {
        name: 'Monaco',
        id: 'MC',
      },
      id: 'MC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.2197, 35.1041],
            [-1.7955, 34.7521],
            [-1.6792, 33.3187],
            [-1.5099, 32.8775],
            [-1.0656, 32.4683],
            [-1.2753, 32.0892],
            [-2.4485, 32.13],
            [-2.9308, 32.0426],
            [-3.0174, 31.8344],
            [-3.8268, 31.6617],
            [-3.8335, 31.1978],
            [-4.3228, 30.6989],
            [-4.9683, 30.4654],
            [-5.4488, 29.9567],
            [-6.5008, 29.8093],
            [-6.6353, 29.5687],
            [-7.095, 29.6251],
            [-7.6851, 29.3495],
            [-8.6599, 28.7187],
            [-8.6834, 27.6565],
            [-13.1774, 27.652],
            [-12.949, 27.9144],
            [-11.986, 28.1292],
            [-11.4302, 28.3822],
            [-11.0809, 28.7138],
            [-10.4865, 29.0649],
            [-9.6671, 30.1094],
            [-9.6529, 30.4477],
            [-9.8755, 30.718],
            [-9.8087, 31.4247],
            [-9.3475, 32.0865],
            [-9.2458, 32.5726],
            [-8.5962, 33.1874],
            [-6.901, 33.969],
            [-6.3532, 34.776],
            [-5.9249, 35.7859],
            [-5.3973, 35.9297],
            [-5.1054, 35.4676],
            [-4.3299, 35.1613],
            [-3.6932, 35.2799],
            [-2.84, 35.1281],
            [-2.2197, 35.1041],
          ],
        ],
      },
      properties: {
        name: 'Morocco',
        id: 'MA',
      },
      id: 'MA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-63.0111, 18.0688],
            [-63.123, 18.0688],
            [-63.0631, 18.1154],
            [-63.0111, 18.0688],
          ],
        ],
      },
      properties: {
        name: 'Saint Martin',
        id: 'MF',
      },
      id: 'MF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [113.4788, 22.1957],
            [113.4943, 22.2414],
            [113.5467, 22.2241],
            [113.4788, 22.1957],
          ],
        ],
      },
      properties: {
        name: 'Macau',
        id: 'MO',
      },
      id: 'MO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.3517, 57.5282],
            [27.797, 57.3169],
            [27.8063, 56.867],
            [28.1481, 56.143],
            [27.5768, 55.7988],
            [26.5936, 55.6676],
            [24.9032, 56.3982],
            [24.1206, 56.2642],
            [22.0845, 56.4067],
            [21.0462, 56.07],
            [21.0711, 56.8237],
            [21.7285, 57.571],
            [22.5546, 57.7242],
            [23.6479, 56.9711],
            [24.4034, 57.3251],
            [24.3226, 57.8706],
            [25.111, 58.0635],
            [25.9912, 57.8382],
            [26.5328, 57.5311],
            [27.3517, 57.5282],
          ],
        ],
      },
      properties: {
        name: 'Latvia',
        id: 'LV',
      },
      id: 'LV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.1164, 50.1209],
            [6.4875, 49.7985],
            [6.3441, 49.4527],
            [5.7897, 49.5383],
            [5.744, 49.9196],
            [6.1164, 50.1209],
          ],
        ],
      },
      properties: {
        name: 'Luxembourg',
        id: 'LU',
      },
      id: 'LU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.5936, 55.6676],
            [26.175, 55.0033],
            [25.8594, 54.9192],
            [25.5473, 54.3317],
            [24.7683, 53.9746],
            [23.4845, 53.9398],
            [23.37, 54.2005],
            [22.7663, 54.3568],
            [22.8311, 54.8384],
            [21.2358, 55.2641],
            [21.0462, 56.07],
            [22.0845, 56.4067],
            [24.1206, 56.2642],
            [24.9032, 56.3982],
            [26.5936, 55.6676],
          ],
        ],
      },
      properties: {
        name: 'Lithuania',
        id: 'LT',
      },
      id: 'LT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.8303, -28.9091],
            [28.6257, -28.5816],
            [29.3013, -29.0898],
            [29.3905, -29.2696],
            [29.098, -29.919],
            [28.3922, -30.1476],
            [28.018, -30.6421],
            [27.3886, -30.3158],
            [27.0517, -29.6642],
            [27.2945, -29.5195],
            [27.8303, -28.9091],
          ],
        ],
      },
      properties: {
        name: 'Lesotho',
        id: 'LS',
      },
      id: 'LS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [79.9822, 9.8129],
            [80.253, 9.7965],
            [80.7111, 9.3663],
            [81.373, 8.4315],
            [81.436, 8.119],
            [81.8741, 7.2885],
            [81.8612, 6.9014],
            [81.6375, 6.4251],
            [80.724, 5.979],
            [80.0954, 6.153],
            [79.8593, 6.8295],
            [79.7923, 7.5854],
            [79.929, 8.8994],
            [80.1185, 9.3268],
            [79.9822, 9.8129],
          ],
        ],
      },
      properties: {
        name: 'Sri Lanka',
        id: 'LK',
      },
      id: 'LK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.5801, 47.0572],
            [9.4877, 47.0621],
            [9.5277, 47.2707],
            [9.5801, 47.0572],
          ],
        ],
      },
      properties: {
        name: 'Liechtenstein',
        id: 'LI',
      },
      id: 'LI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-60.8953, 13.8221],
            [-61.0636, 13.9158],
            [-60.8867, 14.0112],
            [-60.8953, 13.8221],
          ],
        ],
      },
      properties: {
        name: 'Saint Lucia',
        id: 'LC',
      },
      id: 'LC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.1505, 31.655],
            [24.8526, 31.335],
            [24.9738, 30.7766],
            [24.7115, 30.1316],
            [24.9805, 29.1817],
            [24.9805, 27.8345],
            [24.9805, 25.8134],
            [24.9805, 24.2416],
            [24.9805, 21.9959],
            [24.9796, 20.0024],
            [23.9804, 19.9958],
            [23.9804, 19.4968],
            [21.5847, 20.6794],
            [19.6684, 21.6257],
            [17.7521, 22.5721],
            [15.9841, 23.4452],
            [14.9791, 22.996],
            [14.2307, 22.6183],
            [13.4814, 23.1802],
            [11.9678, 23.518],
            [11.5075, 24.3144],
            [10.6863, 24.5514],
            [10.2557, 24.5909],
            [10.0009, 25.3322],
            [9.4482, 26.0673],
            [9.4913, 26.3337],
            [9.8593, 26.5521],
            [9.7474, 27.3306],
            [9.9161, 27.7856],
            [9.8424, 28.9668],
            [9.6724, 29.567],
            [9.3102, 30.1152],
            [9.5188, 30.2293],
            [9.8952, 30.3873],
            [10.2162, 30.7832],
            [10.115, 31.4637],
            [10.8265, 32.0807],
            [11.5048, 32.4137],
            [11.5043, 33.182],
            [12.2798, 32.8584],
            [12.7535, 32.8012],
            [13.2835, 32.9148],
            [14.2369, 32.6813],
            [14.5135, 32.5109],
            [15.1767, 32.391],
            [15.4963, 31.6568],
            [16.123, 31.2644],
            [17.3491, 31.0815],
            [18.1902, 30.7775],
            [18.9364, 30.2905],
            [19.2915, 30.2879],
            [19.7132, 30.4885],
            [20.1509, 31.0784],
            [19.9263, 31.8175],
            [20.1216, 32.2188],
            [20.621, 32.5801],
            [21.6357, 32.9374],
            [22.1875, 32.9184],
            [23.0908, 32.6187],
            [23.2866, 32.2139],
            [24.1295, 32.0093],
            [24.6839, 32.0159],
            [25.1505, 31.655],
          ],
        ],
      },
      properties: {
        name: 'Libya',
        id: 'LY',
      },
      id: 'LY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.4863, 7.5584],
            [-8.2966, 7.0741],
            [-8.3325, 6.8015],
            [-8.6035, 6.5077],
            [-7.8886, 6.2347],
            [-7.7965, 5.975],
            [-7.4543, 5.8414],
            [-7.4299, 5.3247],
            [-7.5688, 5.0805],
            [-7.5451, 4.3512],
            [-8.2591, 4.59],
            [-9.1322, 5.0548],
            [-10.2763, 6.0775],
            [-11.5075, 6.9067],
            [-11.2678, 7.2325],
            [-10.6474, 7.7594],
            [-10.2832, 8.4852],
            [-9.7683, 8.5345],
            [-9.5182, 8.3463],
            [-9.369, 7.6396],
            [-9.4598, 7.4425],
            [-9.1175, 7.2157],
            [-8.8896, 7.2627],
            [-8.7083, 7.6591],
            [-8.4863, 7.5584],
          ],
        ],
      },
      properties: {
        name: 'Liberia',
        id: 'LR',
      },
      id: 'LR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.8691, 33.4319],
            [35.411, 33.0755],
            [35.1087, 33.0835],
            [35.9761, 34.6291],
            [36.4329, 34.6136],
            [36.5851, 34.2212],
            [35.9424, 33.6676],
            [35.8691, 33.4319],
          ],
        ],
      },
      properties: {
        name: 'Lebanon',
        id: 'LB',
      },
      id: 'LB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [102.1276, 22.379],
            [102.9488, 21.5698],
            [102.8511, 21.2657],
            [103.1046, 20.8915],
            [103.635, 20.6971],
            [104.0518, 20.9413],
            [104.5832, 20.6465],
            [104.6187, 20.3744],
            [104.9281, 20.018],
            [104.5463, 19.6105],
            [104.0319, 19.6753],
            [103.8965, 19.3401],
            [104.7163, 18.8035],
            [105.115, 18.6787],
            [105.0857, 18.4501],
            [105.4581, 18.1545],
            [105.6916, 17.7377],
            [106.5021, 16.9542],
            [106.6961, 16.4588],
            [107.3966, 16.0429],
            [107.1897, 15.7473],
            [107.6531, 15.255],
            [107.5195, 14.705],
            [106.82, 14.3148],
            [106.5012, 14.5781],
            [105.9788, 14.3432],
            [105.9046, 13.9247],
            [105.1833, 14.3464],
            [105.4754, 14.5301],
            [105.5131, 15.3606],
            [105.6223, 15.6998],
            [105.4062, 15.9874],
            [105.047, 16.1601],
            [104.7505, 16.6475],
            [104.8162, 17.3005],
            [103.9497, 18.3192],
            [103.3669, 18.4235],
            [103.0913, 18.1381],
            [102.7175, 17.8922],
            [102.1014, 18.2104],
            [101.1053, 17.4793],
            [100.9086, 17.5837],
            [101.1133, 18.0333],
            [101.0507, 18.4071],
            [101.2864, 18.977],
            [101.1546, 19.5794],
            [100.6254, 19.4999],
            [100.3977, 19.7561],
            [100.5429, 20.0885],
            [100.1225, 20.3167],
            [100.2495, 20.7304],
            [100.7564, 21.3128],
            [101.139, 21.5676],
            [101.2242, 21.2236],
            [101.705, 21.1503],
            [101.744, 21.778],
            [101.5243, 22.2538],
            [101.6712, 22.4625],
            [102.1276, 22.379],
          ],
        ],
      },
      properties: {
        name: `Lao People's Democratic Republic`,
        id: 'LA',
      },
      id: 'LA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.4424, 28.5429],
            [47.6713, 28.5331],
            [47.4334, 28.9895],
            [46.5314, 29.0964],
            [46.9056, 29.5377],
            [47.1143, 29.9611],
            [47.6727, 30.0956],
            [47.9785, 29.9829],
            [47.9696, 29.6167],
            [48.1836, 28.9793],
            [48.4424, 28.5429],
          ],
        ],
      },
      properties: {
        name: 'Kuwait',
        id: 'KW',
      },
      id: 'KW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.5625, 42.2476],
            [21.0596, 42.1713],
            [20.566, 41.8739],
            [20.4856, 42.2232],
            [20.0639, 42.5473],
            [20.3445, 42.8278],
            [21.0569, 43.0915],
            [21.3907, 42.7515],
            [21.7529, 42.6698],
            [21.5625, 42.2476],
          ],
        ],
      },
      properties: {
        name: 'Kosovo',
        id: 'XK',
      },
      id: 'XK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [128.3745, 38.6233],
            [128.619, 38.1763],
            [129.335, 37.2747],
            [129.4735, 36.742],
            [129.4034, 36.0522],
            [129.5619, 35.9475],
            [129.2143, 35.1818],
            [128.5112, 35.101],
            [128.4442, 34.8706],
            [127.7148, 34.9545],
            [127.0308, 34.6069],
            [126.4817, 34.4937],
            [126.2913, 35.1542],
            [126.7174, 35.769],
            [126.5483, 36.4775],
            [126.2171, 36.8708],
            [126.7463, 37.1935],
            [126.634, 37.7816],
            [127.1697, 38.3045],
            [128.0389, 38.3085],
            [128.3745, 38.6233],
          ],
        ],
      },
      properties: {
        name: 'South Korea',
        id: 'KR',
      },
      id: 'KR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-62.6307, 17.2401],
            [-62.8389, 17.3391],
            [-62.7945, 17.4025],
            [-62.7137, 17.3533],
            [-62.6307, 17.2401],
          ],
        ],
      },
      properties: {
        name: 'Saint Kitts and Nevis',
        id: 'KN',
      },
      id: 'KN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-157.3422, 1.8557],
            [-157.2461, 1.7318],
            [-157.4201, 1.7873],
            [-157.3422, 1.8557],
          ],
        ],
      },
      properties: {
        name: 'Kiribati',
        id: 'KI',
      },
      id: 'KI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [104.4265, 10.4112],
            [104.2622, 10.5413],
            [103.532, 10.6048],
            [103.722, 10.8902],
            [103.5325, 11.1468],
            [103.153, 10.9137],
            [103.1254, 11.4606],
            [102.9341, 11.7065],
            [102.7366, 12.0896],
            [102.7557, 12.4261],
            [102.4995, 12.6698],
            [102.3198, 13.5398],
            [102.729, 13.8421],
            [103.1996, 14.3326],
            [104.7789, 14.428],
            [105.1833, 14.3464],
            [105.9046, 13.9247],
            [105.9788, 14.3432],
            [106.5012, 14.5781],
            [106.82, 14.3148],
            [107.5195, 14.705],
            [107.3313, 14.1266],
            [107.6056, 13.4377],
            [107.4756, 13.0302],
            [107.5062, 12.3644],
            [106.9305, 12.0776],
            [106.4138, 11.9484],
            [106.3401, 11.6817],
            [105.8514, 11.6351],
            [105.8558, 11.2941],
            [106.1679, 11.0123],
            [105.3866, 10.9399],
            [104.8504, 10.5347],
            [104.4265, 10.4112],
          ],
        ],
      },
      properties: {
        name: 'Cambodia',
        id: 'KH',
      },
      id: 'KH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [80.2095, 42.1899],
            [80.2162, 42.0323],
            [79.2938, 41.7829],
            [78.3625, 41.3718],
            [78.1232, 41.0758],
            [76.8239, 40.9821],
            [76.4804, 40.4494],
            [75.8718, 40.3034],
            [75.5837, 40.6052],
            [75.0044, 40.4494],
            [74.4118, 40.1374],
            [73.9915, 40.0433],
            [73.9147, 39.6065],
            [73.6315, 39.4489],
            [73.2351, 39.3743],
            [72.0428, 39.3521],
            [71.8062, 39.2758],
            [71.4045, 39.598],
            [70.7333, 39.4134],
            [70.6077, 39.5643],
            [69.5987, 39.5736],
            [69.229, 39.761],
            [69.5304, 40.0974],
            [69.9667, 40.2022],
            [70.4514, 40.049],
            [70.9579, 40.239],
            [71.6926, 40.1525],
            [73.113, 40.7859],
            [72.6585, 40.8698],
            [71.7924, 41.4131],
            [71.4085, 41.1361],
            [70.7826, 41.2626],
            [70.1811, 41.5716],
            [70.8421, 42.0195],
            [70.9468, 42.2485],
            [71.2567, 42.7337],
            [71.8168, 42.822],
            [72.8551, 42.561],
            [73.422, 42.5934],
            [73.5565, 43.0027],
            [74.209, 43.2402],
            [75.0475, 42.9046],
            [76.9442, 42.9716],
            [79.1646, 42.759],
            [79.49, 42.4576],
            [80.2095, 42.1899],
          ],
        ],
      },
      properties: {
        name: 'Kyrgyzstan',
        id: 'KG',
      },
      id: 'KG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [41.8839, 3.9779],
            [41.3419, 3.2016],
            [40.9646, 2.8145],
            [40.9699, 1.378],
            [40.9788, -0.8703],
            [41.5328, -1.6955],
            [40.9956, -1.9507],
            [40.6441, -2.5393],
            [40.2224, -2.6884],
            [40.1154, -3.2504],
            [39.4909, -4.4787],
            [39.2219, -4.6922],
            [37.7974, -3.6743],
            [37.6087, -3.4604],
            [37.6438, -3.0453],
            [36.2633, -2.2747],
            [34.7709, -1.4416],
            [33.9031, -1.0021],
            [33.9431, 0.1738],
            [34.4815, 1.042],
            [34.7874, 1.2307],
            [34.9654, 1.6435],
            [34.964, 2.0625],
            [34.7425, 2.818],
            [34.4478, 3.1634],
            [34.3928, 3.6916],
            [34.165, 3.8128],
            [33.9759, 4.2203],
            [35.2681, 5.4925],
            [35.7448, 5.3442],
            [35.763, 4.808],
            [36.0818, 4.4498],
            [36.9056, 4.4116],
            [38.0864, 3.649],
            [39.4944, 3.4559],
            [39.8424, 3.8514],
            [40.7653, 4.2731],
            [41.2207, 3.9437],
            [41.8839, 3.9779],
          ],
        ],
      },
      properties: {
        name: 'Kenya',
        id: 'KE',
      },
      id: 'KE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [87.3229, 49.0858],
            [86.7534, 49.0088],
            [86.5496, 48.5287],
            [85.7493, 48.3849],
            [85.526, 47.9157],
            [85.6565, 47.2547],
            [84.786, 46.8308],
            [83.1929, 47.1864],
            [83.0043, 47.0337],
            [82.3264, 45.52],
            [82.4787, 45.1236],
            [81.602, 45.3109],
            [80.059, 45.0064],
            [80.4554, 44.7463],
            [80.3551, 44.0973],
            [80.7857, 43.1616],
            [80.1651, 42.6653],
            [80.2095, 42.1899],
            [79.49, 42.4576],
            [79.1646, 42.759],
            [76.9442, 42.9716],
            [75.0475, 42.9046],
            [74.209, 43.2402],
            [73.5565, 43.0027],
            [73.422, 42.5934],
            [72.8551, 42.561],
            [71.8168, 42.822],
            [71.2567, 42.7337],
            [70.9468, 42.2485],
            [70.4159, 42.0785],
            [70.0954, 41.8206],
            [69.0652, 41.367],
            [68.2919, 40.6563],
            [67.9359, 41.1965],
            [66.8142, 41.1423],
            [66.6686, 41.2706],
            [66.4986, 41.9951],
            [66.0094, 42.0048],
            [66.1004, 42.9907],
            [65.803, 42.8771],
            [65.4963, 43.3108],
            [64.9054, 43.7147],
            [64.4434, 43.5513],
            [63.2071, 43.6281],
            [61.9904, 43.4923],
            [61.1608, 44.1688],
            [61.0081, 44.3939],
            [58.5551, 45.5555],
            [55.9757, 44.9949],
            [55.9766, 42.929],
            [55.9774, 41.3221],
            [55.5451, 41.2626],
            [54.9037, 41.9192],
            [54.1211, 42.3351],
            [53.2502, 42.2059],
            [52.4938, 41.7802],
            [52.5968, 42.7603],
            [51.8439, 42.9104],
            [51.3019, 43.4825],
            [50.8305, 44.1928],
            [50.3311, 44.3255],
            [50.4093, 44.6242],
            [50.8603, 44.6287],
            [51.4156, 45.358],
            [52.5311, 45.3988],
            [52.7739, 45.5728],
            [53.1352, 46.1916],
            [53.1703, 46.6692],
            [52.9159, 46.9542],
            [52.1888, 46.8397],
            [51.1781, 47.11],
            [50.4195, 46.8796],
            [50, 46.6342],
            [49.3475, 46.5192],
            [49.2321, 46.3372],
            [48.6102, 46.5663],
            [48.9595, 46.7744],
            [48.1099, 47.7452],
            [47.0934, 47.9477],
            [47.0042, 48.2846],
            [46.661, 48.4124],
            [46.7028, 48.8055],
            [47.0144, 49.0984],
            [46.8022, 49.367],
            [47.3264, 50.2734],
            [47.706, 50.378],
            [48.4344, 49.8284],
            [48.8432, 50.0131],
            [48.8086, 50.6012],
            [49.498, 51.0835],
            [50.2468, 51.2895],
            [50.7941, 51.7292],
            [51.6091, 51.4839],
            [52.3309, 51.6812],
            [52.571, 51.4817],
            [53.3381, 51.4824],
            [54.0416, 51.1153],
            [54.4216, 50.7804],
            [54.727, 50.9981],
            [55.5424, 50.6017],
            [55.7977, 50.6021],
            [56.4915, 51.0196],
            [57.0117, 51.0651],
            [57.4423, 50.8889],
            [57.8387, 51.0917],
            [58.3594, 51.0638],
            [58.8836, 50.6945],
            [59.8122, 50.5819],
            [59.9552, 50.7992],
            [60.6379, 50.6638],
            [61.3894, 50.8609],
            [61.5851, 51.2298],
            [61.3632, 51.442],
            [60.4647, 51.6513],
            [60.0657, 51.9764],
            [60.9375, 52.2805],
            [60.8021, 52.7448],
            [61.0476, 52.9725],
            [61.974, 52.9437],
            [62.0148, 53.1079],
            [61.1994, 53.2872],
            [60.9797, 53.6217],
            [61.3339, 54.0492],
            [61.9287, 53.9464],
            [63.0739, 54.1053],
            [64.4611, 54.3841],
            [65.1576, 54.3643],
            [65.4767, 54.6233],
            [66.2225, 54.6673],
            [68.0739, 54.9596],
            [68.2062, 55.1609],
            [68.9773, 55.3895],
            [70.1824, 55.1625],
            [70.7382, 55.3052],
            [71.1856, 54.5994],
            [71.0933, 54.2123],
            [72.9141, 54.1073],
            [73.3993, 53.8115],
            [73.2857, 53.5984],
            [74.4518, 53.6472],
            [76.2664, 54.312],
            [76.5758, 53.9424],
            [77.7042, 53.3791],
            [78.4757, 52.6385],
            [79.4687, 51.493],
            [79.9862, 50.7746],
            [80.8771, 51.2815],
            [81.4657, 50.7397],
            [82.4938, 50.7275],
            [83.3571, 50.9945],
            [83.9453, 50.7746],
            [84.2578, 50.2883],
            [84.9893, 50.0615],
            [85.21, 49.6648],
            [86.1807, 49.4993],
            [86.6144, 49.6098],
            [87.3229, 49.0858],
          ],
        ],
      },
      properties: {
        name: 'Kazakhstan',
        id: 'KZ',
      },
      id: 'KZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [141.2296, 41.3727],
              [141.4626, 40.611],
              [141.7969, 40.2914],
              [141.978, 39.8444],
              [141.9007, 39.1115],
              [141.6584, 38.9748],
              [141.4675, 38.404],
              [141.1084, 38.3378],
              [140.9282, 37.9494],
              [141.0365, 37.4674],
              [140.9686, 37.0022],
              [140.6272, 36.5028],
              [140.6219, 36.0593],
              [140.874, 35.7251],
              [140.4572, 35.5102],
              [140.4164, 35.267],
              [139.7994, 34.9567],
              [139.6502, 35.409],
              [139.2494, 35.2781],
              [138.9826, 34.6984],
              [138.5773, 35.0863],
              [138.1889, 34.5963],
              [136.8848, 34.8058],
              [136.5332, 34.6784],
              [136.8537, 34.3242],
              [136.3299, 34.1768],
              [135.9162, 33.5616],
              [135.4528, 33.5536],
              [135.1753, 33.898],
              [135.1003, 34.2882],
              [135.3848, 34.5004],
              [134.7399, 34.7654],
              [134.2081, 34.6975],
              [133.9684, 34.5275],
              [133.1423, 34.3024],
              [132.2381, 34.227],
              [132.0903, 33.8554],
              [131.7405, 34.0521],
              [130.9188, 33.9757],
              [131.0041, 34.3925],
              [131.3543, 34.4134],
              [132.923, 35.5111],
              [133.3767, 35.4588],
              [134.2139, 35.5391],
              [135.1744, 35.7473],
              [135.6805, 35.5031],
              [136.0955, 35.7677],
              [136.0063, 35.9905],
              [136.6983, 36.742],
              [137.3424, 36.7705],
              [137.5142, 36.9516],
              [138.3199, 37.2183],
              [138.8849, 37.8438],
              [139.4012, 38.1425],
              [140.0479, 39.4635],
              [139.9228, 40.5986],
              [140.2814, 40.8463],
              [141.1186, 40.8822],
              [141.2296, 41.3727],
            ],
          ],
          [
            [
              [143.8241, 44.1169],
              [144.7985, 43.9402],
              [145.1012, 43.7644],
              [145.3476, 43.1767],
              [144.6307, 42.9468],
              [143.9693, 42.8815],
              [143.4295, 42.419],
              [143.2364, 42.0004],
              [141.8515, 42.5792],
              [140.9863, 42.3422],
              [140.4803, 42.5592],
              [140.3267, 42.2934],
              [140.7337, 42.1162],
              [140.3848, 41.5192],
              [139.9951, 41.5765],
              [140.1083, 41.9129],
              [139.8211, 42.3875],
              [139.8912, 42.6494],
              [140.7808, 43.2149],
              [141.2962, 43.1998],
              [141.6446, 44.0197],
              [141.7822, 44.7166],
              [141.5829, 45.156],
              [141.9376, 45.5094],
              [142.7042, 44.8191],
              [143.8241, 44.1169],
            ],
          ],
          [
            [
              [131.1745, 33.6024],
              [131.5829, 33.6526],
              [131.8967, 33.2548],
              [131.9766, 32.8438],
              [131.7321, 32.593],
              [131.3374, 31.4047],
              [130.5886, 31.1783],
              [130.2006, 31.2919],
              [130.3218, 31.6013],
              [130.1944, 32.0909],
              [130.6405, 32.6192],
              [129.5801, 33.2362],
              [130.3649, 33.6344],
              [130.4838, 33.8345],
              [130.953, 33.8718],
              [131.1745, 33.6024],
            ],
          ],
          [
            [
              [134.3572, 34.2562],
              [134.6373, 34.2265],
              [134.739, 33.8203],
              [134.3772, 33.6082],
              [134.1815, 33.2473],
              [133.6319, 33.511],
              [133.2861, 33.36],
              [132.9772, 32.842],
              [132.4951, 32.9166],
              [132.3659, 33.5123],
              [132.7841, 33.9926],
              [133.5822, 34.017],
              [134.0758, 34.3583],
              [134.3572, 34.2562],
            ],
          ],
        ],
      },
      properties: {
        name: 'Japan',
        id: 'JP',
      },
      id: 'JP',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.1455, 32.1247],
            [38.9622, 31.9951],
            [36.9584, 31.4917],
            [37.9803, 30.5],
            [37.6336, 30.3132],
            [37.4694, 29.9949],
            [36.7551, 29.8662],
            [36.4759, 29.4951],
            [36.0156, 29.1906],
            [34.9507, 29.3535],
            [34.9734, 29.555],
            [35.174, 30.524],
            [35.4394, 31.1326],
            [35.4505, 31.4793],
            [35.5589, 31.7656],
            [35.5513, 32.3955],
            [35.7875, 32.735],
            [36.3721, 32.387],
            [36.8182, 32.3173],
            [38.7735, 33.372],
            [39.1455, 32.1247],
          ],
        ],
      },
      properties: {
        name: 'Jordan',
        id: 'JO',
      },
      id: 'JO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.0186, 49.2312],
            [-2.2359, 49.1763],
            [-2.2206, 49.2665],
            [-2.0186, 49.2312],
          ],
        ],
      },
      properties: {
        name: 'Jersey',
        id: 'JE',
      },
      id: 'JE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.2614, 18.4572],
            [-76.3499, 18.1518],
            [-76.5246, 17.8664],
            [-76.8533, 17.9738],
            [-77.3615, 17.8336],
            [-77.7681, 17.8775],
            [-78.2167, 18.4479],
            [-77.8735, 18.522],
            [-77.2614, 18.4572],
          ],
        ],
      },
      properties: {
        name: 'Jamaica',
        id: 'JM',
      },
      id: 'JM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.632, 40.8822],
              [9.8051, 40.4996],
              [9.5623, 39.1661],
              [9.0563, 39.2394],
              [8.8814, 38.9127],
              [8.418, 39.2056],
              [8.4712, 40.2927],
              [8.1898, 40.6518],
              [9.2831, 41.2018],
              [9.632, 40.8822],
            ],
          ],
          [
            [
              [15.5766, 38.2202],
              [15.0994, 37.4585],
              [15.2943, 37.0133],
              [15.1127, 36.6879],
              [14.5019, 36.7989],
              [12.4356, 37.8198],
              [13.3518, 38.1807],
              [13.6816, 38.0009],
              [14.5059, 38.0453],
              [14.846, 38.1718],
              [15.5766, 38.2202],
            ],
          ],
          [
            [
              [13.6998, 46.5201],
              [13.3993, 46.3177],
              [13.7198, 45.5875],
              [13.2062, 45.7713],
              [12.2745, 45.4459],
              [12.5235, 44.9678],
              [12.2483, 44.7223],
              [12.3961, 44.2238],
              [13.564, 43.5713],
              [14.0106, 42.6898],
              [14.8659, 42.0523],
              [15.1687, 41.9338],
              [15.9641, 41.9396],
              [16.0125, 41.4353],
              [17.1032, 41.062],
              [17.955, 40.6554],
              [18.4859, 40.105],
              [18.0779, 39.9367],
              [17.8649, 40.2803],
              [16.9283, 40.4579],
              [16.5301, 39.8595],
              [16.5976, 39.6389],
              [17.1147, 39.3805],
              [17.1746, 38.9979],
              [16.5589, 38.7147],
              [16.5456, 38.4093],
              [16.0569, 37.9419],
              [15.7244, 37.9392],
              [15.9726, 38.7125],
              [16.1967, 38.7591],
              [16.0236, 39.3535],
              [15.6929, 39.99],
              [14.9507, 40.239],
              [14.7656, 40.6683],
              [14.1024, 40.8272],
              [13.7331, 41.2356],
              [13.0886, 41.244],
              [12.6309, 41.4695],
              [11.6375, 42.2876],
              [10.7084, 42.9361],
              [10.0475, 44.0201],
              [8.766, 44.4223],
              [8.0815, 43.9189],
              [7.4929, 43.7671],
              [7.6771, 44.0831],
              [6.9673, 44.2802],
              [7.0308, 44.7166],
              [6.6277, 45.1178],
              [7.1533, 45.401],
              [7.021, 45.9257],
              [7.9932, 46.0158],
              [8.4584, 46.2458],
              [8.9537, 45.8298],
              [9.2512, 46.2866],
              [9.9392, 46.3616],
              [10.4528, 46.865],
              [11.0249, 46.7971],
              [11.2442, 46.9755],
              [12.1542, 46.9351],
              [12.3881, 46.7025],
              [13.6998, 46.5201],
            ],
            [
              [12.4853, 43.9016],
              [12.5146, 43.9531],
              [12.441, 43.9824],
              [12.397, 43.9344],
              [12.4853, 43.9016],
            ],
          ],
        ],
      },
      properties: {
        name: 'Italy',
        id: 'IT',
      },
      id: 'IT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.7875, 32.735],
            [35.5513, 32.3955],
            [35.1931, 32.5344],
            [34.9561, 32.1611],
            [34.9507, 31.6022],
            [35.4505, 31.4793],
            [35.4394, 31.1326],
            [35.174, 30.524],
            [34.9734, 29.555],
            [34.9041, 29.4773],
            [34.2454, 31.2085],
            [34.4775, 31.5849],
            [34.6786, 31.8956],
            [35.1087, 33.0835],
            [35.411, 33.0755],
            [35.8691, 33.4319],
            [35.7875, 32.735],
          ],
        ],
      },
      properties: {
        name: 'Israel',
        id: 'IL',
      },
      id: 'IL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-15.5431, 66.2285],
            [-14.6982, 66.0203],
            [-14.7576, 65.7557],
            [-13.6159, 65.4872],
            [-13.853, 64.8622],
            [-15.0215, 64.296],
            [-16.0605, 64.1113],
            [-16.6404, 63.8654],
            [-17.8156, 63.7129],
            [-18.6537, 63.4066],
            [-20.1982, 63.5558],
            [-21.8328, 64.2054],
            [-21.9502, 64.515],
            [-22.684, 65.0264],
            [-22.0057, 65.4934],
            [-22.9024, 65.5804],
            [-24.2241, 65.4872],
            [-23.7371, 66.0695],
            [-22.4261, 66.4302],
            [-21.3035, 65.8765],
            [-21.23, 65.4206],
            [-20.3567, 65.7191],
            [-20.3567, 66.0331],
            [-18.7775, 66.1688],
            [-16.7485, 66.1317],
            [-15.9854, 66.5145],
            [-15.5431, 66.2285],
          ],
        ],
      },
      properties: {
        name: 'Iceland',
        id: 'IS',
      },
      id: 'IS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [44.7652, 37.1424],
            [45.3618, 36.0154],
            [45.7235, 35.8365],
            [46.1337, 35.1276],
            [45.6783, 34.7982],
            [45.4377, 34.4152],
            [45.3968, 33.9708],
            [46.1457, 33.2295],
            [46.1128, 32.9579],
            [46.57, 32.834],
            [47.3713, 32.4239],
            [47.8298, 31.7944],
            [47.6793, 31.0025],
            [48.0105, 30.9896],
            [48.0149, 30.4654],
            [48.5467, 29.9625],
            [47.9785, 29.9829],
            [47.6727, 30.0956],
            [47.1143, 29.9611],
            [46.9056, 29.5377],
            [46.5314, 29.0964],
            [46.3565, 29.0636],
            [44.7163, 29.1937],
            [42.8578, 30.4952],
            [42.0743, 31.0802],
            [40.3693, 31.9391],
            [39.1455, 32.1247],
            [38.7735, 33.372],
            [40.6894, 34.3322],
            [40.9872, 34.4289],
            [41.1945, 34.7689],
            [41.3543, 35.6403],
            [41.2456, 36.0735],
            [41.4169, 36.5148],
            [41.7884, 36.5973],
            [42.3588, 37.1087],
            [42.7748, 37.3719],
            [43.5161, 37.2445],
            [44.1562, 37.2831],
            [44.2818, 36.9782],
            [44.7652, 37.1424],
          ],
        ],
      },
      properties: {
        name: 'Iraq',
        id: 'IQ',
      },
      id: 'IQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.1146, 38.8776],
            [46.4906, 38.9065],
            [47.773, 39.6486],
            [47.9958, 39.6842],
            [48.2422, 38.9788],
            [48.0233, 38.819],
            [48.5929, 38.4111],
            [48.8685, 38.4355],
            [49.0811, 37.6676],
            [49.47, 37.4967],
            [50.1305, 37.407],
            [50.5331, 37.0137],
            [51.1186, 36.7425],
            [52.1902, 36.6217],
            [54.0172, 36.9524],
            [53.9142, 37.3435],
            [54.7452, 37.502],
            [55.0755, 37.9024],
            [55.5784, 38.0999],
            [56.2287, 38.0733],
            [56.4409, 38.2495],
            [57.1937, 38.2162],
            [57.4236, 37.9477],
            [58.4357, 37.6387],
            [58.8157, 37.6835],
            [59.2409, 37.5206],
            [59.5623, 37.1788],
            [60.0626, 36.9631],
            [60.3413, 36.6377],
            [61.1195, 36.6426],
            [61.262, 35.6194],
            [61.08, 34.8555],
            [60.4856, 34.0947],
            [60.5109, 33.6388],
            [60.9171, 33.5052],
            [60.5606, 33.1376],
            [60.8292, 32.2494],
            [60.854, 31.4833],
            [61.6602, 31.3825],
            [61.7844, 30.8321],
            [61.104, 30.1285],
            [60.8434, 29.8586],
            [61.3184, 29.3725],
            [61.6229, 28.7915],
            [61.8901, 28.5465],
            [62.3531, 28.4146],
            [62.7397, 28.0023],
            [62.7632, 27.2503],
            [63.1667, 27.2525],
            [63.1681, 26.6657],
            [62.4392, 26.5609],
            [61.8697, 26.2422],
            [61.6686, 25.769],
            [61.5878, 25.2022],
            [61.412, 25.1023],
            [60.6641, 25.2821],
            [59.4562, 25.4814],
            [59.0461, 25.4175],
            [58.7979, 25.5546],
            [57.7961, 25.6532],
            [57.3344, 25.7917],
            [56.9824, 26.9054],
            [56.3561, 27.2001],
            [55.6503, 26.9777],
            [54.8957, 26.5565],
            [53.7056, 26.7256],
            [53.4548, 26.9431],
            [52.6918, 27.3235],
            [52.476, 27.6165],
            [51.6664, 27.8451],
            [51.2788, 28.1314],
            [51.0622, 28.7262],
            [50.6752, 29.1466],
            [50.6499, 29.42],
            [49.9831, 30.2093],
            [49.5548, 30.0291],
            [48.9191, 30.1209],
            [48.5467, 29.9625],
            [48.0149, 30.4654],
            [48.0105, 30.9896],
            [47.6793, 31.0025],
            [47.8298, 31.7944],
            [47.3713, 32.4239],
            [46.57, 32.834],
            [46.1128, 32.9579],
            [46.1457, 33.2295],
            [45.3968, 33.9708],
            [45.4377, 34.4152],
            [45.6783, 34.7982],
            [46.1337, 35.1276],
            [45.7235, 35.8365],
            [45.3618, 36.0154],
            [44.7652, 37.1424],
            [44.5614, 37.7448],
            [44.2112, 37.9081],
            [44.4309, 38.3569],
            [44.023, 39.3774],
            [44.8171, 39.6504],
            [45.4798, 39.0063],
            [46.1146, 38.8776],
          ],
        ],
      },
      properties: {
        name: 'Iran',
        id: 'IR',
      },
      id: 'IR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.218, 54.0887],
            [-6.0274, 52.927],
            [-6.5612, 52.1888],
            [-7.5273, 52.0989],
            [-8.8135, 51.5849],
            [-10.3786, 51.8688],
            [-9.9558, 52.1367],
            [-9.2993, 53.0975],
            [-9.7954, 53.3951],
            [-9.996, 54.276],
            [-8.6231, 54.3468],
            [-8.764, 54.6813],
            [-8.2746, 55.1463],
            [-7.2468, 55.3531],
            [-7.2186, 55.0921],
            [-8.1183, 54.4143],
            [-7.3553, 54.1213],
            [-6.218, 54.0887],
          ],
        ],
      },
      properties: {
        name: 'Ireland',
        id: 'IE',
      },
      id: 'IE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [72.492, -7.3773],
            [72.4671, -7.3675],
            [72.4738, -7.3098],
            [72.4356, -7.2304],
            [72.4938, -7.2619],
            [72.492, -7.3773],
          ],
        ],
      },
      properties: {
        name: 'British Indian Ocean Territory',
        id: 'IO',
      },
      id: 'IO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [78.7198, 31.8876],
            [78.8445, 31.3017],
            [79.1073, 31.4025],
            [79.3386, 31.1055],
            [79.3697, 31.0798],
            [79.9165, 30.8942],
            [79.9245, 30.8889],
            [80.1913, 30.5684],
            [80.6823, 30.4148],
            [81.0103, 30.1645],
            [80.4017, 29.7303],
            [80.0706, 28.8301],
            [80.5868, 28.6494],
            [81.8528, 27.8669],
            [81.9877, 27.9139],
            [82.7335, 27.5189],
            [83.2897, 27.371],
            [84.0909, 27.4913],
            [84.6103, 27.2987],
            [84.6853, 27.0412],
            [85.293, 26.7412],
            [86.3663, 26.5743],
            [86.7014, 26.4349],
            [87.0166, 26.5556],
            [87.2874, 26.3603],
            [88.0549, 26.43],
            [88.1614, 26.7247],
            [87.9843, 27.134],
            [88.1099, 27.8704],
            [88.6213, 28.0919],
            [88.8485, 27.8686],
            [88.8912, 27.316],
            [88.8579, 26.9613],
            [89.7638, 26.7016],
            [90.3458, 26.8903],
            [90.7395, 26.7718],
            [91.9984, 26.8548],
            [92.0836, 27.2907],
            [91.5949, 27.5575],
            [91.6317, 27.7599],
            [91.9775, 27.7301],
            [92.6642, 27.949],
            [92.702, 28.147],
            [93.2067, 28.5909],
            [93.9022, 28.803],
            [94.6231, 29.3126],
            [94.7696, 29.1759],
            [95.4204, 29.0543],
            [96.0352, 29.4471],
            [96.4355, 29.0507],
            [96.776, 28.3671],
            [97.3224, 28.218],
            [97.3353, 27.9379],
            [96.6659, 27.3391],
            [96.0613, 27.217],
            [95.1287, 26.5973],
            [95.1323, 26.0411],
            [94.6227, 25.4099],
            [94.7075, 25.0486],
            [94.2929, 24.3219],
            [94.1277, 23.8763],
            [93.3074, 24.0219],
            [93.4149, 23.6823],
            [93.366, 23.1323],
            [93.0788, 22.7182],
            [93.1618, 22.3604],
            [92.9643, 22.0039],
            [92.575, 21.9782],
            [92.4916, 22.6853],
            [92.3615, 22.929],
            [92.2461, 23.6836],
            [91.938, 23.5047],
            [91.6198, 22.9796],
            [91.3152, 23.1043],
            [91.1603, 23.6605],
            [91.3672, 24.0933],
            [91.8768, 24.1954],
            [92.2514, 24.895],
            [92.0499, 25.1693],
            [90.4394, 25.1578],
            [89.814, 25.3052],
            [89.8229, 25.9413],
            [89.2893, 26.0376],
            [89.1082, 26.2023],
            [88.4402, 26.3696],
            [88.0846, 25.8884],
            [88.4521, 25.5746],
            [88.4561, 25.1884],
            [88.1454, 24.4857],
            [88.7336, 24.2309],
            [88.5671, 23.6743],
            [88.7407, 23.4368],
            [89.0514, 22.0932],
            [89.0518, 21.6541],
            [88.2537, 21.6222],
            [87.9483, 21.8255],
            [87.1005, 21.5006],
            [86.8595, 21.2369],
            [86.9753, 20.7002],
            [86.7503, 20.3131],
            [86.2793, 19.9194],
            [85.5748, 19.693],
            [84.7709, 19.1253],
            [84.1042, 18.2926],
            [83.6541, 18.0697],
            [83.1982, 17.609],
            [82.3597, 17.0963],
            [82.259, 16.56],
            [81.7618, 16.3297],
            [81.2385, 16.264],
            [80.9788, 15.7584],
            [80.6467, 15.8951],
            [80.2934, 15.7109],
            [80.0533, 15.0739],
            [80.1656, 14.5776],
            [80.1119, 14.2123],
            [80.3422, 13.3614],
            [80.229, 12.6902],
            [79.8584, 11.9888],
            [79.7541, 11.5751],
            [79.8486, 11.1969],
            [79.838, 10.3225],
            [79.3905, 10.306],
            [78.9964, 9.6833],
            [78.9795, 9.2687],
            [78.4215, 9.1049],
            [78.1925, 8.8909],
            [78.0602, 8.3844],
            [77.5178, 8.0782],
            [76.9669, 8.4071],
            [76.5536, 8.9029],
            [76.3246, 9.452],
            [76.3463, 9.9221],
            [75.9224, 10.7841],
            [75.724, 11.3616],
            [75.1966, 12.0576],
            [74.7705, 13.0773],
            [74.6711, 13.6676],
            [74.3821, 14.4946],
            [73.9493, 15.0748],
            [73.932, 15.397],
            [73.4761, 16.0544],
            [73.3376, 16.4597],
            [73.1561, 17.6218],
            [72.8711, 18.6832],
            [72.6678, 19.8311],
            [72.8813, 20.5631],
            [72.8138, 21.117],
            [72.5431, 21.6968],
            [72.5901, 22.2783],
            [72.3326, 22.2703],
            [72.037, 21.8233],
            [72.2541, 21.5312],
            [72.0153, 21.1557],
            [71.0245, 20.7388],
            [70.7191, 20.7406],
            [70.1274, 21.0948],
            [69.0088, 22.1966],
            [70.1771, 22.5726],
            [70.2512, 22.9707],
            [69.6644, 22.759],
            [69.2361, 22.8487],
            [68.6408, 23.19],
            [68.1649, 23.8572],
            [68.7242, 23.9646],
            [68.7282, 24.2656],
            [69.5592, 24.2731],
            [69.7163, 24.1728],
            [70.5469, 24.4183],
            [71.044, 24.4001],
            [71.048, 24.6877],
            [70.6521, 25.4228],
            [70.2646, 25.7064],
            [70.0777, 26.0718],
            [70.1567, 26.4713],
            [69.47, 26.8046],
            [69.5681, 27.1744],
            [70.1447, 27.8491],
            [70.4035, 28.0249],
            [70.7373, 27.7293],
            [71.8701, 27.9623],
            [72.342, 28.752],
            [72.903, 29.029],
            [73.3816, 29.9345],
            [73.809, 30.0934],
            [73.8991, 30.4352],
            [74.6329, 31.0345],
            [74.5099, 31.7127],
            [75.3334, 32.2792],
            [74.6578, 32.5189],
            [73.9941, 33.2424],
            [74.1499, 33.507],
            [73.9613, 34.6535],
            [74.3004, 34.7654],
            [75.7093, 34.5031],
            [76.5945, 34.7357],
            [77.0486, 35.1099],
            [77.7992, 35.496],
            [77.8107, 35.4845],
            [78.2821, 34.654],
            [78.9364, 34.3521],
            [78.7531, 34.0876],
            [78.8019, 33.4999],
            [79.1127, 33.2264],
            [79.1353, 33.1718],
            [79.2165, 32.5641],
            [79.2192, 32.5011],
            [78.3896, 32.5197],
            [78.7198, 31.8876],
          ],
        ],
      },
      properties: {
        name: 'India',
        id: 'IN',
      },
      id: 'IN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.4121, 54.1854],
            [-4.7457, 54.1189],
            [-4.5086, 54.3768],
            [-4.4121, 54.1854],
          ],
        ],
      },
      properties: {
        name: 'Isle of Man',
        id: 'IM',
      },
      id: 'IM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [96.4924, 5.2293],
              [97.4512, 5.2359],
              [97.9084, 4.8799],
              [98.2484, 4.4147],
              [98.3074, 4.0929],
              [99.7323, 3.1829],
              [100.4572, 2.2574],
              [100.6854, 2.1202],
              [101.0462, 2.2574],
              [101.4768, 1.6932],
              [101.7849, 1.6213],
              [102.1573, 1.2591],
              [102.4694, 0.7792],
              [102.8493, 0.7153],
              [103.0664, 0.492],
              [103.4788, 0.4801],
              [103.7868, 0.0468],
              [103.4286, -0.1916],
              [103.4313, -0.5334],
              [103.7211, -0.8867],
              [104.3608, -1.0385],
              [104.5685, -1.9218],
              [104.8451, -2.0932],
              [104.6684, -2.3857],
              [105.3968, -2.3804],
              [105.5819, -2.4918],
              [106.0445, -3.1062],
              [105.8438, -3.6135],
              [105.8873, -5.0096],
              [105.8163, -5.6767],
              [105.6183, -5.7997],
              [105.3493, -5.5493],
              [105.0812, -5.7455],
              [104.6396, -5.5205],
              [104.6014, -5.9044],
              [103.8312, -5.0797],
              [102.919, -4.4707],
              [102.5377, -4.152],
              [102.1276, -3.5993],
              [101.5785, -3.167],
              [100.8478, -2.1438],
              [100.8554, -1.9343],
              [100.4865, -1.2991],
              [100.3081, -0.8268],
              [99.5978, 0.1023],
              [99.1593, 0.3518],
              [98.7962, 1.4948],
              [98.5644, 1.9023],
              [97.7006, 2.3586],
              [97.591, 2.8464],
              [97.3912, 2.9752],
              [96.9691, 3.5753],
              [96.4449, 3.8163],
              [95.4949, 4.7614],
              [95.2068, 5.2839],
              [95.2277, 5.5648],
              [95.629, 5.6092],
              [96.1333, 5.2941],
              [96.4924, 5.2293],
            ],
          ],
          [
            [
              [120.0124, -9.3748],
              [120.5038, -9.674],
              [120.8327, -10.0375],
              [120.439, -10.2941],
              [120.1447, -10.2],
              [119.6009, -9.7734],
              [119.0856, -9.7068],
              [119.1855, -9.3846],
              [120.0124, -9.3748],
            ],
          ],
          [
            [
              [125.0679, -9.512],
              [124.4274, -10.1485],
              [123.8574, -10.3438],
              [123.5893, -9.9669],
              [123.7092, -9.6149],
              [124.0363, -9.3415],
              [124.4442, -9.1901],
              [124.9223, -8.9424],
              [125.0679, -9.512],
            ],
          ],
          [
            [
              [116.6406, -8.614],
              [116.3774, -8.9291],
              [116.0764, -8.7449],
              [116.0613, -8.4373],
              [116.4014, -8.2043],
              [116.7343, -8.3871],
              [116.6406, -8.614],
            ],
          ],
          [
            [
              [122.7828, -8.6117],
              [121.6513, -8.8989],
              [121.0351, -8.9353],
              [119.8078, -8.6974],
              [119.9183, -8.4453],
              [120.6103, -8.2407],
              [121.4444, -8.578],
              [121.9664, -8.4551],
              [122.263, -8.6251],
              [122.6034, -8.4027],
              [122.7828, -8.6117],
            ],
          ],
          [
            [
              [118.2426, -8.3179],
              [118.9879, -8.3379],
              [119.0061, -8.7498],
              [118.1898, -8.8403],
              [117.0614, -9.0991],
              [116.7884, -9.0064],
              [116.835, -8.5323],
              [117.1649, -8.3672],
              [117.567, -8.4266],
              [118.1174, -8.1221],
              [118.2426, -8.3179],
            ],
          ],
          [
            [
              [115.4479, -8.155],
              [115.7045, -8.4071],
              [115.2361, -8.7977],
              [114.4753, -8.1195],
              [115.4479, -8.155],
            ],
          ],
          [
            [
              [126.8009, -7.668],
              [126.4719, -7.9503],
              [125.8434, -7.8167],
              [126.6095, -7.5717],
              [126.8009, -7.668],
            ],
          ],
          [
            [
              [138.5351, -8.2735],
              [138.2963, -8.4053],
              [137.6851, -8.2624],
              [138.0074, -7.6414],
              [138.2954, -7.4385],
              [138.77, -7.3906],
              [138.9893, -7.696],
              [138.5351, -8.2735],
            ],
          ],
          [
            [
              [131.3255, -7.9996],
              [131.0866, -7.8651],
              [131.2602, -7.4705],
              [131.6912, -7.439],
              [131.3255, -7.9996],
            ],
          ],
          [
            [
              [113.8445, -7.1052],
              [113.4708, -7.2184],
              [112.7637, -7.1398],
              [112.868, -6.9001],
              [113.9746, -6.873],
              [113.8445, -7.1052],
            ],
          ],
          [
            [
              [134.537, -6.4424],
              [134.3559, -6.8149],
              [134.0589, -6.7691],
              [134.1681, -6.1761],
              [134.537, -6.4424],
            ],
          ],
          [
            [
              [107.3739, -6.0074],
              [107.6669, -6.2156],
              [108.295, -6.2649],
              [108.6776, -6.7905],
              [110.4261, -6.9471],
              [110.7369, -6.4722],
              [111.1546, -6.6693],
              [111.5403, -6.6484],
              [112.0872, -6.8934],
              [112.5391, -6.9263],
              [112.7943, -7.5526],
              [113.2484, -7.7182],
              [114.0705, -7.633],
              [114.4442, -7.8957],
              [114.387, -8.4053],
              [114.5836, -8.7698],
              [113.2533, -8.2868],
              [112.6789, -8.4093],
              [111.5101, -8.305],
              [110.6072, -8.1497],
              [110.0386, -7.8904],
              [109.2818, -7.7049],
              [108.7411, -7.6671],
              [108.4517, -7.7968],
              [107.9173, -7.724],
              [107.2852, -7.4718],
              [106.6313, -7.4155],
              [106.5199, -7.0537],
              [105.4838, -6.7816],
              [105.8682, -6.1166],
              [106.0751, -5.9142],
              [106.8253, -6.0984],
              [107.0463, -5.9044],
              [107.3739, -6.0074],
            ],
          ],
          [
            [
              [134.747, -5.7069],
              [134.7559, -6.1708],
              [134.4411, -6.335],
              [134.3413, -5.7127],
              [134.747, -5.7069],
            ],
          ],
          [
            [
              [122.6451, -5.2692],
              [122.3961, -5.0699],
              [122.369, -4.7672],
              [122.702, -4.6185],
              [122.6451, -5.2692],
            ],
          ],
          [
            [
              [126.8612, -3.0879],
              [127.2274, -3.3911],
              [127.2297, -3.6331],
              [126.6863, -3.8235],
              [126.2145, -3.6051],
              [126.0263, -3.1705],
              [126.8612, -3.0879],
            ],
          ],
          [
            [
              [129.7545, -2.866],
              [130.3791, -2.9894],
              [130.8598, -3.5705],
              [130.8052, -3.8577],
              [129.8442, -3.3272],
              [129.4678, -3.4533],
              [128.9675, -3.3259],
              [128.4193, -3.416],
              [128.0819, -3.1838],
              [128.1987, -2.866],
              [129.4274, -2.7905],
              [129.7545, -2.866],
            ],
          ],
          [
            [
              [108.2071, -2.9978],
              [107.6145, -3.2096],
              [107.6665, -2.5664],
              [108.0744, -2.597],
              [108.2071, -2.9978],
            ],
          ],
          [
            [
              [124.9694, -1.7057],
              [124.8344, -1.8943],
              [124.4176, -2.0053],
              [124.3808, -1.6875],
              [124.9694, -1.7057],
            ],
          ],
          [
            [
              [135.4741, -1.5916],
              [136.2016, -1.6551],
              [136.1927, -1.8593],
              [135.4741, -1.5916],
            ],
          ],
          [
            [
              [106.0458, -1.6693],
              [106.3658, -2.4647],
              [106.6788, -2.704],
              [106.547, -3.0555],
              [105.9988, -2.8247],
              [105.7857, -2.1815],
              [105.1332, -2.0426],
              [105.5855, -1.5268],
              [106.0458, -1.6693],
            ],
          ],
          [
            [
              [128.153, -1.6604],
              [127.5617, -1.7283],
              [127.6465, -1.3324],
              [128.153, -1.6604],
            ],
          ],
          [
            [
              [99.1637, -1.778],
              [98.8743, -1.6639],
              [98.6017, -1.1979],
              [98.869, -0.9155],
              [99.2671, -1.6275],
              [99.1637, -1.778],
            ],
          ],
          [
            [
              [135.3831, -0.6514],
              [135.8935, -0.726],
              [135.8389, -1.1193],
              [135.3831, -0.6514],
            ],
          ],
          [
            [
              [140.9734, -2.6099],
              [140.9739, -4.0232],
              [140.9748, -6.3461],
              [140.9761, -9.1187],
              [139.9347, -8.1013],
              [139.3856, -8.1892],
              [138.8561, -8.1452],
              [139.0878, -7.5872],
              [138.7478, -7.2517],
              [138.8645, -6.8584],
              [138.4388, -6.3435],
              [138.0611, -5.4654],
              [137.0894, -4.9243],
              [136.6189, -4.8187],
              [135.9797, -4.531],
              [135.1957, -4.4507],
              [134.1473, -3.7968],
              [133.9737, -3.8182],
              [133.6532, -3.3645],
              [133.2488, -4.0623],
              [132.9687, -4.0947],
              [132.7539, -3.7036],
              [132.7512, -3.2948],
              [132.3482, -2.9752],
              [133.1911, -2.4377],
              [133.6088, -2.5473],
              [133.9049, -2.391],
              [133.9218, -2.1021],
              [132.963, -2.2725],
              [132.3078, -2.2423],
              [132.0233, -1.9902],
              [131.9305, -1.5596],
              [131.2935, -1.3936],
              [131.2571, -0.8556],
              [131.8044, -0.7038],
              [132.1285, -0.4539],
              [132.6252, -0.3589],
              [133.4726, -0.726],
              [133.9746, -0.7442],
              [134.2596, -1.363],
              [134.106, -1.7212],
              [134.1557, -2.1953],
              [135.0373, -3.333],
              [135.4865, -3.345],
              [136.2433, -2.5832],
              [136.3898, -2.2734],
              [136.843, -2.1975],
              [137.1236, -1.8411],
              [137.8063, -1.4833],
              [138.6497, -1.7909],
              [139.7896, -2.3484],
              [140.6223, -2.4456],
              [140.9734, -2.6099],
            ],
          ],
          [
            [
              [97.4814, 1.4651],
              [97.9319, 0.9741],
              [97.8764, 0.6283],
              [97.4054, 0.947],
              [97.2443, 1.4238],
              [97.4814, 1.4651],
            ],
          ],
          [
            [
              [124.889, 0.9954],
              [124.4274, 0.4707],
              [123.754, 0.3056],
              [123.2653, 0.3265],
              [123.0824, 0.4858],
              [121.8421, 0.4366],
              [121.4258, 0.4947],
              [120.3489, 0.4494],
              [120.1274, 0.1667],
              [120.012, -0.307],
              [120.0972, -0.6501],
              [120.5176, -1.0394],
              [120.6676, -1.3701],
              [121.0338, -1.4065],
              [121.5194, -0.8556],
              [121.9695, -0.9333],
              [122.2798, -0.7571],
              [122.8888, -0.7553],
              [122.5067, -1.3479],
              [121.7187, -1.8628],
              [121.349, -1.9458],
              [121.8484, -2.3316],
              [122.3992, -3.2007],
              [122.2527, -3.6206],
              [122.8773, -4.1089],
              [122.8973, -4.349],
              [122.207, -4.4964],
              [121.9172, -4.848],
              [121.4866, -4.5812],
              [121.618, -4.0929],
              [120.9144, -3.5558],
              [121.0542, -3.167],
              [121.052, -2.7515],
              [120.6538, -2.6676],
              [120.2539, -3.0529],
              [120.4368, -3.7072],
              [120.3627, -4.0858],
              [120.4199, -4.6176],
              [120.2792, -5.1463],
              [120.3116, -5.5418],
              [119.7172, -5.6932],
              [119.3763, -5.425],
              [119.6116, -4.4236],
              [119.6236, -4.0343],
              [119.4198, -3.4755],
              [118.9946, -3.5376],
              [118.8126, -3.1568],
              [118.7837, -2.7648],
              [119.0922, -2.4829],
              [119.3217, -1.9298],
              [119.3089, -1.4083],
              [119.7718, -0.4836],
              [119.8091, 0.2386],
              [120.2694, 0.971],
              [120.5167, 0.8174],
              [120.9122, 1.2888],
              [121.44, 1.2142],
              [121.5918, 1.0678],
              [122.4365, 1.018],
              [122.8924, 0.8498],
              [123.2781, 0.9279],
              [123.8468, 0.8383],
              [124.2738, 1.022],
              [124.9893, 1.7012],
              [125.2339, 1.5023],
              [124.889, 0.9954],
            ],
          ],
          [
            [
              [127.7326, 0.848],
              [128.1609, 1.1579],
              [128.4242, 1.5174],
              [128.6883, 1.5725],
              [128.7025, 1.1064],
              [128.299, 0.8769],
              [128.6914, 0.3602],
              [127.9829, 0.4721],
              [127.8875, 0.2985],
              [128.0464, -0.706],
              [127.6918, -0.2417],
              [127.7086, 0.2883],
              [127.5555, 0.4898],
              [127.4201, 1.252],
              [127.6318, 1.8437],
              [128.0109, 1.7012],
              [128.0118, 1.3319],
              [127.6527, 1.0141],
              [127.7326, 0.848],
            ],
          ],
          [
            [
              [117.5746, 4.1706],
              [117.6301, 3.6361],
              [117.1662, 3.5922],
              [117.6105, 3.0644],
              [118.0664, 2.3178],
              [117.8649, 1.9684],
              [118.0806, 1.7016],
              [118.9848, 0.9821],
              [118.5347, 0.8134],
              [118.196, 0.8742],
              [117.745, 0.7295],
              [117.5222, 0.2359],
              [117.4627, -0.3238],
              [117.5626, -0.7708],
              [116.5545, -1.474],
              [116.4236, -2.0528],
              [116.5656, -2.2996],
              [116.3308, -2.9024],
              [115.9561, -3.5949],
              [114.6937, -4.1697],
              [114.5361, -3.4946],
              [114.0825, -3.2788],
              [113.7957, -3.4564],
              [113.5258, -3.1838],
              [112.9714, -3.1869],
              [112.6003, -3.4005],
              [112.2851, -3.321],
              [111.8222, -3.5323],
              [111.8231, -3.0573],
              [111.3676, -2.9335],
              [111.0445, -3.0555],
              [110.5739, -2.8913],
              [110.2326, -2.925],
              [110.0191, -1.3989],
              [109.7874, -1.0114],
              [109.454, -0.8689],
              [109.1606, -0.4947],
              [109.2574, 0.0313],
              [108.9444, 0.3558],
              [108.9058, 0.7939],
              [109.0758, 1.4957],
              [109.3785, 1.9227],
              [109.6289, 2.0275],
              [109.6542, 1.6151],
              [110.5056, 0.8618],
              [110.9379, 1.0172],
              [111.7698, 0.9994],
              [112.0783, 1.1432],
              [112.1857, 1.4393],
              [112.476, 1.5592],
              [113.6222, 1.236],
              [113.9022, 1.4344],
              [114.5459, 1.4673],
              [114.8304, 1.98],
              [114.7865, 2.2503],
              [115.1509, 2.4931],
              [115.0861, 2.8411],
              [115.4545, 3.0342],
              [115.5686, 3.9389],
              [115.8962, 4.3486],
              [117.1005, 4.337],
              [117.5746, 4.1706],
            ],
          ],
        ],
      },
      properties: {
        name: 'Indonesia',
        id: 'ID',
      },
      id: 'ID',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.132, 48.4053],
            [22.8764, 47.9472],
            [22.2905, 47.7279],
            [21.1519, 46.3044],
            [20.2419, 46.1086],
            [19.5308, 46.1552],
            [18.9054, 45.9315],
            [17.9639, 45.7704],
            [17.3105, 45.9963],
            [16.5163, 46.4997],
            [16.0929, 46.8632],
            [16.4533, 47.0066],
            [16.4342, 47.3675],
            [17.1475, 48.0058],
            [17.7619, 47.7701],
            [18.7242, 47.787],
            [18.7917, 48.0005],
            [19.8988, 48.1314],
            [20.4901, 48.5269],
            [21.4515, 48.5522],
            [22.132, 48.4053],
          ],
        ],
      },
      properties: {
        name: 'Hungary',
        id: 'HU',
      },
      id: 'HU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7791, 19.7183],
            [-71.6571, 19.1306],
            [-71.7683, 18.0391],
            [-72.0599, 18.2286],
            [-72.8767, 18.1518],
            [-73.3851, 18.2513],
            [-73.885, 18.0418],
            [-74.419, 18.3463],
            [-74.2276, 18.6628],
            [-72.7894, 18.4346],
            [-72.3478, 18.6747],
            [-72.8112, 19.0716],
            [-72.7033, 19.4409],
            [-73.0527, 19.6109],
            [-73.1177, 19.9039],
            [-72.6369, 19.9008],
            [-71.7791, 19.7183],
          ],
        ],
      },
      properties: {
        name: 'Haiti',
        id: 'HT',
      },
      id: 'HT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [18.9054, 45.9315],
              [19.0549, 45.5271],
              [19.007, 44.8693],
              [18.6625, 45.0774],
              [17.8129, 45.0779],
              [16.5305, 45.2168],
              [16.2935, 45.0086],
              [15.7879, 45.1791],
              [15.7369, 44.7658],
              [16.214, 44.215],
              [17.6247, 43.0426],
              [17.5852, 42.9383],
              [16.903, 43.3924],
              [15.9415, 43.6565],
              [15.123, 44.2567],
              [14.8548, 45.081],
              [14.3861, 45.342],
              [13.9657, 44.8355],
              [13.6293, 45.1081],
              [13.5778, 45.5169],
              [13.8787, 45.4286],
              [15.3396, 45.4672],
              [15.6246, 45.8338],
              [15.6361, 46.2005],
              [16.5163, 46.4997],
              [17.3105, 45.9963],
              [17.9639, 45.7704],
              [18.9054, 45.9315],
            ],
          ],
          [
            [
              [17.6678, 42.897],
              [18.4362, 42.5597],
              [18.5174, 42.4327],
              [17.6678, 42.897],
            ],
          ],
        ],
      },
      properties: {
        name: 'Croatia',
        id: 'HR',
      },
      id: 'HR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1576, 14.9931],
            [-83.8674, 14.7947],
            [-84.646, 14.6611],
            [-84.9851, 14.7525],
            [-85.1796, 14.3432],
            [-85.7868, 13.8443],
            [-86.1512, 13.9948],
            [-86.3317, 13.7702],
            [-86.7336, 13.7635],
            [-86.7108, 13.3134],
            [-87.0592, 12.9916],
            [-87.3373, 12.9792],
            [-87.4891, 13.3529],
            [-87.8143, 13.3991],
            [-87.7153, 13.8128],
            [-88.151, 13.9872],
            [-88.4491, 13.851],
            [-89.0572, 14.329],
            [-89.3626, 14.416],
            [-89.2223, 14.8661],
            [-88.9764, 15.1427],
            [-88.2284, 15.7291],
            [-87.6181, 15.9097],
            [-86.9072, 15.7624],
            [-86.3568, 15.7832],
            [-85.7839, 16.003],
            [-85.4836, 15.8995],
            [-84.9738, 15.9901],
            [-84.2614, 15.8227],
            [-83.7604, 15.2204],
            [-83.3691, 15.2399],
            [-83.1576, 14.9931],
          ],
        ],
      },
      properties: {
        name: 'Honduras',
        id: 'HN',
      },
      id: 'HN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [73.7074, -53.1372],
            [73.465, -53.1843],
            [73.3363, -53.0298],
            [73.5858, -53.0271],
            [73.7074, -53.1372],
          ],
        ],
      },
      properties: {
        name: 'Heard Island and McDonald Islands',
        id: 'HM',
      },
      id: 'HM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [114.0154, 22.5117],
            [114.2658, 22.541],
            [114.268, 22.2956],
            [114.0154, 22.5117],
          ],
        ],
      },
      properties: {
        name: 'Hong Kong',
        id: 'HK',
      },
      id: 'HK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-57.1948, 5.5484],
            [-57.3309, 5.0202],
            [-57.7111, 4.9909],
            [-58.0544, 4.1719],
            [-57.8327, 3.6761],
            [-57.5497, 3.3529],
            [-57.3036, 3.3769],
            [-57.2097, 2.8828],
            [-56.7043, 2.0363],
            [-56.4828, 1.9422],
            [-56.8368, 1.8814],
            [-57.1189, 2.0141],
            [-57.5943, 1.7043],
            [-58.3407, 1.5876],
            [-58.5119, 1.2848],
            [-58.8625, 1.2036],
            [-59.2312, 1.3758],
            [-59.7563, 1.9005],
            [-59.7552, 2.2743],
            [-59.9942, 2.6902],
            [-59.8544, 3.5873],
            [-59.5512, 3.9335],
            [-59.8333, 4.476],
            [-60.1112, 4.511],
            [-59.9907, 5.0828],
            [-60.142, 5.239],
            [-60.7422, 5.2022],
            [-61.3767, 5.9071],
            [-61.1286, 6.2142],
            [-61.1457, 6.6945],
            [-60.3951, 6.9453],
            [-60.7191, 7.4989],
            [-60.5136, 7.8131],
            [-60.0324, 8.0537],
            [-59.8315, 8.3059],
            [-60.0175, 8.5491],
            [-59.2003, 8.0746],
            [-58.6266, 7.5459],
            [-58.4806, 7.0381],
            [-57.9825, 6.786],
            [-57.1902, 6.0975],
            [-57.1948, 5.5484],
          ],
        ],
      },
      properties: {
        name: 'Guyana',
        id: 'GY',
      },
      id: 'GY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [144.7416, 13.2593],
            [144.6493, 13.4288],
            [144.7905, 13.5269],
            [144.7416, 13.2593],
          ],
        ],
      },
      properties: {
        name: 'Guam',
        id: 'GU',
      },
      id: 'GU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-51.6526, 4.0614],
            [-51.9906, 3.7018],
            [-52.7006, 2.3635],
            [-52.9648, 2.1837],
            [-53.7678, 2.3551],
            [-54.13, 2.1211],
            [-54.6163, 2.3266],
            [-54.1954, 2.818],
            [-54.2032, 3.1381],
            [-53.9904, 3.5895],
            [-54.3508, 4.0543],
            [-54.4522, 5.0135],
            [-54.1559, 5.3589],
            [-53.8472, 5.7823],
            [-53.4544, 5.5635],
            [-52.8993, 5.425],
            [-52.2905, 4.9421],
            [-51.9196, 4.5244],
            [-51.6526, 4.0614],
          ],
        ],
      },
      properties: {
        name: 'French Guiana',
        id: 'GF',
      },
      id: 'GF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.1615, 17.8149],
            [-89.2374, 15.8942],
            [-88.8941, 15.8907],
            [-88.2284, 15.7291],
            [-88.9764, 15.1427],
            [-89.2223, 14.8661],
            [-89.3626, 14.416],
            [-89.8726, 14.0454],
            [-90.0952, 13.7364],
            [-90.607, 13.9291],
            [-91.1461, 13.9255],
            [-91.8191, 14.2283],
            [-92.2352, 14.5452],
            [-92.0748, 15.0743],
            [-92.1871, 15.3207],
            [-91.7365, 16.07],
            [-90.4472, 16.0726],
            [-90.417, 16.3909],
            [-90.7107, 16.7083],
            [-91.4096, 17.2561],
            [-90.993, 17.2525],
            [-90.9892, 17.8163],
            [-89.1615, 17.8149],
          ],
        ],
      },
      properties: {
        name: 'Guatemala',
        id: 'GT',
      },
      id: 'GT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-25.4324, 70.9213],
              [-25.4022, 70.6527],
              [-27.898, 70.4541],
              [-27.7089, 70.8971],
              [-25.4324, 70.9213],
            ],
          ],
          [
            [
              [-52.7313, 69.9447],
              [-52.0452, 69.8071],
              [-52.1125, 69.4891],
              [-53.5784, 69.2567],
              [-53.7831, 69.5064],
              [-54.804, 69.6304],
              [-54.8304, 70.1611],
              [-53.3751, 70.2213],
              [-52.7313, 69.9447],
            ],
          ],
          [
            [
              [-29.953, 83.5649],
              [-25.9475, 83.2897],
              [-24.4702, 82.8773],
              [-21.5208, 82.5954],
              [-25.1489, 82.0011],
              [-21.3379, 82.0687],
              [-21.1466, 81.6952],
              [-17.2261, 81.4304],
              [-15.5555, 81.8337],
              [-12.1928, 81.649],
              [-11.5288, 81.424],
              [-14.5035, 80.7633],
              [-16.7607, 80.5733],
              [-16.8683, 80.1982],
              [-19.4292, 80.2577],
              [-20.1385, 79.8033],
              [-19.2836, 79.683],
              [-19.0749, 79.1524],
              [-20.9557, 78.5551],
              [-20.8625, 77.9119],
              [-20.2319, 77.3684],
              [-18.339, 77.2152],
              [-18.5103, 76.7782],
              [-20.9422, 76.887],
              [-21.8772, 76.5734],
              [-21.5692, 76.2937],
              [-20.1037, 76.2191],
              [-19.5091, 75.7575],
              [-19.4261, 75.2299],
              [-20.485, 75.3143],
              [-19.2252, 74.4795],
              [-19.3692, 74.284],
              [-21.1295, 74.1109],
              [-20.3673, 73.8483],
              [-20.5096, 73.493],
              [-22.1851, 73.2699],
              [-22.0364, 72.9184],
              [-22.2931, 72.1196],
              [-24.0692, 72.4987],
              [-24.6669, 72.4374],
              [-22.0133, 71.6888],
              [-21.7523, 71.4784],
              [-21.5228, 70.5262],
              [-23.3279, 70.451],
              [-23.7917, 70.5551],
              [-24.377, 71.1463],
              [-25.7422, 71.1836],
              [-26.7179, 70.9506],
              [-27.6887, 70.9934],
              [-28.5409, 70.4769],
              [-28.0149, 70.4024],
              [-26.7472, 70.4756],
              [-23.1732, 70.1145],
              [-23.0882, 69.883],
              [-26.3414, 68.7023],
              [-29.2496, 68.2988],
              [-29.8686, 68.3116],
              [-30.9786, 68.0613],
              [-32.1373, 68.3849],
              [-32.2747, 67.9228],
              [-33.157, 67.6267],
              [-34.1016, 66.7258],
              [-34.6329, 66.4349],
              [-36.3792, 65.8307],
              [-37.7541, 65.593],
              [-39.9609, 65.5562],
              [-39.5779, 65.3407],
              [-40.6554, 64.9154],
              [-40.1822, 64.48],
              [-40.6177, 64.1317],
              [-40.5504, 63.7254],
              [-42.1529, 62.5686],
              [-42.3216, 62.1526],
              [-42.1103, 61.8572],
              [-42.4188, 61.537],
              [-42.7171, 60.7675],
              [-43.3201, 59.9281],
              [-44.0654, 59.9247],
              [-45.3793, 60.2028],
              [-46.0467, 60.6157],
              [-46.0116, 61.0968],
              [-46.8746, 60.8163],
              [-47.7963, 60.829],
              [-48.922, 61.2775],
              [-49.2891, 61.59],
              [-49.5534, 62.2328],
              [-50.3192, 62.4731],
              [-50.3382, 62.8287],
              [-51.4688, 63.6423],
              [-51.4511, 63.9047],
              [-52.0934, 64.4158],
              [-52.259, 65.1549],
              [-53.6346, 66.4136],
              [-53.0382, 66.8268],
              [-53.8845, 67.1356],
              [-53.4189, 67.5746],
              [-53.578, 67.8369],
              [-53.0393, 68.6108],
              [-51.2493, 68.74],
              [-50.5, 69.9358],
              [-52.2545, 70.059],
              [-54.0143, 70.4217],
              [-54.1659, 70.8201],
              [-52.4052, 70.6867],
              [-51.5246, 70.4394],
              [-51.1302, 70.9717],
              [-53.117, 71.3128],
              [-53.4761, 71.6402],
              [-54.6891, 71.3672],
              [-55.5939, 71.5536],
              [-55.3156, 72.1107],
              [-55.6017, 72.4536],
              [-54.7379, 72.8724],
              [-56.104, 73.5582],
              [-55.9295, 73.8954],
              [-56.7063, 74.2192],
              [-56.2556, 74.5268],
              [-58.6035, 75.3853],
              [-58.5163, 75.6891],
              [-61.3747, 76.1801],
              [-63.4388, 76.3394],
              [-66.8262, 75.9688],
              [-69.3728, 76.3319],
              [-69.2521, 76.6861],
              [-70.7928, 76.869],
              [-70.8629, 77.1755],
              [-69.3515, 77.4671],
              [-72.8181, 78.1945],
              [-72.3957, 78.5043],
              [-68.9935, 78.8574],
              [-67.4823, 79.1169],
              [-65.9679, 79.1324],
              [-64.7923, 80.0007],
              [-66.8437, 80.0761],
              [-66.996, 80.413],
              [-62.9932, 81.2069],
              [-61.436, 81.1337],
              [-61.203, 81.7469],
              [-58.7174, 82.0932],
              [-54.7259, 82.3513],
              [-52.7757, 82.3218],
              [-50.8944, 81.8952],
              [-48.8612, 82.4054],
              [-45.2912, 81.8288],
              [-44.239, 82.3682],
              [-46.4782, 82.9519],
              [-43.1945, 83.255],
              [-32.9845, 83.5995],
              [-29.953, 83.5649],
            ],
          ],
        ],
      },
      properties: {
        name: 'Greenland',
        id: 'GL',
      },
      id: 'GL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.7154, 12.0128],
            [-61.715, 12.185],
            [-61.6071, 12.2232],
            [-61.6271, 12.0541],
            [-61.7154, 12.0128],
          ],
        ],
      },
      properties: {
        name: 'Grenada',
        id: 'GD',
      },
      id: 'GD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [23.8521, 35.5355],
              [24.3129, 35.3638],
              [24.7212, 35.425],
              [25.4758, 35.3061],
              [25.7448, 35.1427],
              [24.7998, 34.9345],
              [24.4638, 35.1605],
              [23.5618, 35.295],
              [23.8521, 35.5355],
            ],
          ],
          [
            [
              [26.0391, 40.7269],
              [25.1043, 40.9945],
              [24.3839, 40.9129],
              [23.8787, 40.5444],
              [23.6643, 40.2239],
              [23.3119, 40.2164],
              [22.6296, 40.4956],
              [22.5923, 40.0371],
              [22.919, 39.6291],
              [22.9656, 39.0308],
              [23.2528, 38.6614],
              [23.9671, 38.2748],
              [23.5804, 38.0107],
              [23.1938, 37.9592],
              [23.1614, 37.3338],
              [22.765, 37.3932],
              [23.0735, 36.7749],
              [22.6083, 36.7798],
              [22.0805, 37.0288],
              [21.5829, 37.0807],
              [21.5714, 37.541],
              [21.1448, 37.9192],
              [21.4036, 38.1967],
              [21.8248, 38.3281],
              [22.7996, 37.9814],
              [22.4219, 38.4386],
              [21.4724, 38.3214],
              [21.1133, 38.3849],
              [20.7795, 39.0086],
              [20.301, 39.3273],
              [20.0013, 39.7095],
              [20.6574, 40.1174],
              [21.0312, 40.6585],
              [20.9641, 40.8498],
              [21.5758, 40.8689],
              [21.9296, 41.1073],
              [22.7246, 41.1783],
              [22.9159, 41.3363],
              [23.635, 41.3869],
              [24.0558, 41.5272],
              [25.2512, 41.2436],
              [26.1355, 41.3856],
              [26.321, 41.7167],
              [26.6246, 41.4016],
              [26.0391, 40.7269],
            ],
          ],
          [
            [
              [23.4153, 38.9588],
              [24.0989, 38.6712],
              [23.7589, 38.4013],
              [23.2519, 38.8013],
              [23.4153, 38.9588],
            ],
          ],
        ],
      },
      properties: {
        name: 'Greece',
        id: 'GR',
      },
      id: 'GR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.5907, 1.0318],
            [9.3861, 1.1392],
            [9.8069, 1.9276],
            [9.8007, 2.3044],
            [9.98, 2.1677],
            [11.3286, 2.1673],
            [11.3352, 0.9998],
            [9.5907, 1.0318],
          ],
        ],
      },
      properties: {
        name: 'Equatorial Guinea',
        id: 'GQ',
      },
      id: 'GQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-15.0431, 10.9404],
            [-15.3931, 11.2174],
            [-15.5018, 11.7238],
            [-16.1384, 11.9174],
            [-16.7119, 12.3551],
            [-15.5748, 12.4904],
            [-15.1962, 12.68],
            [-13.7294, 12.6738],
            [-13.7327, 11.7358],
            [-14.6831, 11.5085],
            [-15.0431, 10.9404],
          ],
        ],
      },
      properties: {
        name: 'Guinea-Bissau',
        id: 'GW',
      },
      id: 'GW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-16.7634, 13.064],
            [-16.8248, 13.3409],
            [-16.5623, 13.5873],
            [-15.5096, 13.5864],
            [-15.8343, 13.1563],
            [-16.6488, 13.1541],
            [-16.7634, 13.064],
          ],
        ],
      },
      properties: {
        name: 'Gambia',
        id: 'GM',
      },
      id: 'GM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.3272, 16.2302],
            [-61.5221, 16.228],
            [-61.529, 16.434],
            [-61.4065, 16.4682],
            [-61.3272, 16.2302],
          ],
        ],
      },
      properties: {
        name: 'Guadeloupe',
        id: 'GP',
      },
      id: 'GP',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-11.3894, 12.4043],
            [-11.3053, 12.0155],
            [-10.9333, 12.205],
            [-10.6774, 11.8996],
            [-10.2748, 12.2126],
            [-9.8207, 12.0426],
            [-9.043, 12.4025],
            [-8.8219, 11.6732],
            [-8.4075, 11.3865],
            [-8.2315, 10.4379],
            [-7.9907, 10.1627],
            [-8.1365, 10.022],
            [-8.137, 9.4955],
            [-7.8962, 9.416],
            [-7.951, 8.7866],
            [-7.8236, 8.4674],
            [-8.232, 7.5566],
            [-8.4863, 7.5584],
            [-8.7083, 7.6591],
            [-8.8896, 7.2627],
            [-9.1175, 7.2157],
            [-9.4598, 7.4425],
            [-9.369, 7.6396],
            [-9.5182, 8.3463],
            [-9.7683, 8.5345],
            [-10.2832, 8.4852],
            [-10.5005, 8.6876],
            [-10.6905, 9.3144],
            [-11.2056, 9.9776],
            [-11.911, 9.9931],
            [-12.5016, 9.8622],
            [-12.7559, 9.3734],
            [-13.2928, 9.0494],
            [-13.7005, 9.8511],
            [-14.4269, 10.2483],
            [-14.7758, 10.9315],
            [-15.0431, 10.9404],
            [-14.6831, 11.5085],
            [-13.7327, 11.7358],
            [-13.7294, 12.6738],
            [-13.0828, 12.6334],
            [-12.2912, 12.328],
            [-11.3894, 12.4043],
          ],
        ],
      },
      properties: {
        name: 'Guinea',
        id: 'GN',
      },
      id: 'GN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-0.0686, 11.1157],
            [-0.0577, 10.6305],
            [0.3627, 10.2364],
            [0.2619, 9.4955],
            [0.5291, 9.3583],
            [0.4153, 8.6525],
            [0.688, 8.3041],
            [0.5837, 8.1456],
            [0.6348, 7.3537],
            [0.5256, 6.8508],
            [0.7369, 6.4526],
            [1.1874, 6.0895],
            [0.9499, 5.8103],
            [0.2597, 5.7575],
            [-0.7977, 5.2266],
            [-1.6384, 4.9807],
            [-2.0017, 4.7623],
            [-3.1139, 5.0885],
            [-3.0868, 5.1285],
            [-3.0191, 5.1307],
            [-2.7952, 5.1844],
            [-3.2007, 6.3483],
            [-3.2358, 6.8073],
            [-2.9858, 7.205],
            [-2.7899, 7.9321],
            [-2.5058, 8.2087],
            [-2.7466, 9.1098],
            [-2.6958, 9.4813],
            [-2.7865, 10.4019],
            [-2.9148, 10.5924],
            [-2.8298, 10.9985],
            [-0.7014, 10.9888],
            [-0.0686, 11.1157],
          ],
        ],
      },
      properties: {
        name: 'Ghana',
        id: 'GH',
      },
      id: 'GH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.5124, 49.4946],
            [-2.5475, 49.4287],
            [-2.646, 49.4682],
            [-2.5124, 49.4946],
          ],
        ],
      },
      properties: {
        name: 'Guernsey',
        id: 'GG',
      },
      id: 'GG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.4298, 41.8907],
            [46.1821, 41.6573],
            [46.6189, 41.3439],
            [46.4577, 41.07],
            [45.281, 41.4495],
            [45.0013, 41.291],
            [43.4393, 41.1073],
            [42.7876, 41.5636],
            [42.4663, 41.4398],
            [41.5101, 41.5174],
            [41.7631, 41.9702],
            [41.4888, 42.6591],
            [40.8367, 43.0635],
            [39.9782, 43.42],
            [40.6481, 43.534],
            [41.5807, 43.2193],
            [42.4192, 43.2242],
            [43.7824, 42.747],
            [43.9573, 42.5664],
            [44.5059, 42.7488],
            [45.1602, 42.6751],
            [45.7275, 42.4749],
            [45.6388, 42.205],
            [46.4298, 41.8907],
          ],
        ],
      },
      properties: {
        name: 'Georgia',
        id: 'GE',
      },
      id: 'GE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.2937, 2.1615],
            [13.1627, 1.6479],
            [13.2741, 1.2409],
            [13.8512, 1.4189],
            [14.181, 1.3701],
            [14.4345, 0.8116],
            [14.0874, 0.5364],
            [13.8845, 0.1906],
            [13.8601, -0.2035],
            [14.4806, -0.6181],
            [14.3839, -1.8899],
            [14.0874, -2.4669],
            [13.7842, -2.1638],
            [13.465, -2.3955],
            [12.9918, -2.3134],
            [12.7934, -1.9316],
            [12.4321, -1.929],
            [12.4463, -2.3298],
            [11.5754, -2.3973],
            [11.5376, -2.8367],
            [11.934, -3.3188],
            [11.5043, -3.5203],
            [11.1301, -3.9163],
            [10.3476, -3.0129],
            [9.6245, -2.3671],
            [9.2658, -1.8251],
            [9.3306, -1.5348],
            [9.0647, -1.2982],
            [8.8215, -0.7082],
            [9.2969, -0.3514],
            [9.5907, 1.0318],
            [11.3352, 0.9998],
            [11.3286, 2.1673],
            [11.3485, 2.2996],
            [13.2204, 2.2565],
            [13.2937, 2.1615],
          ],
        ],
      },
      properties: {
        name: 'Gabon',
        id: 'GA',
      },
      id: 'GA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [5.7897, 49.5383],
              [6.3441, 49.4527],
              [6.7356, 49.1606],
              [7.4507, 49.1522],
              [8.1348, 48.9735],
              [7.5843, 48.0644],
              [7.6154, 47.5925],
              [7.0006, 47.3227],
              [6.06, 46.4282],
              [6.7583, 46.4158],
              [7.021, 45.9257],
              [7.1533, 45.401],
              [6.6277, 45.1178],
              [7.0308, 44.7166],
              [6.9673, 44.2802],
              [7.6771, 44.0831],
              [7.4929, 43.7671],
              [7.4387, 43.7507],
              [7.4143, 43.7711],
              [7.3779, 43.7316],
              [6.4941, 43.1692],
              [6.1159, 43.0724],
              [5.4066, 43.2286],
              [5.0599, 43.4444],
              [4.7119, 43.3733],
              [4.0527, 43.5931],
              [3.2591, 43.1931],
              [3.0518, 42.9152],
              [3.2116, 42.431],
              [2.0326, 42.3537],
              [1.7059, 42.5033],
              [1.4284, 42.5961],
              [-0.0411, 42.6893],
              [-1.2855, 43.0595],
              [-1.794, 43.4075],
              [-1.4848, 43.5638],
              [-1.2456, 44.5599],
              [-1.066, 45.8059],
              [-1.1464, 46.3115],
              [-1.7867, 46.5148],
              [-2.0188, 47.0377],
              [-3.1587, 47.6946],
              [-4.312, 47.8229],
              [-4.6291, 48.0857],
              [-4.3932, 48.3676],
              [-4.7208, 48.5398],
              [-3.2315, 48.8408],
              [-2.6924, 48.5367],
              [-2.4463, 48.6483],
              [-1.4375, 48.6415],
              [-1.8564, 49.6837],
              [-1.2587, 49.6802],
              [-1.1386, 49.3879],
              [-0.1636, 49.2969],
              [0.1869, 49.703],
              [1.2456, 49.9982],
              [1.5514, 50.2938],
              [1.5794, 50.7393],
              [1.9123, 50.9905],
              [2.5249, 51.0971],
              [2.8396, 50.7118],
              [4.1744, 50.2466],
              [4.8677, 49.788],
              [5.7897, 49.5383],
            ],
          ],
          [
            [
              [9.4802, 42.8056],
              [9.5508, 42.1296],
              [9.1863, 41.3847],
              [8.8077, 41.5885],
              [8.7132, 42.5499],
              [9.4802, 42.8056],
            ],
          ],
        ],
      },
      properties: {
        name: 'France',
        id: 'FR',
      },
      id: 'FR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [158.315, 6.8135],
            [158.1836, 6.8011],
            [158.1348, 6.9449],
            [158.2946, 6.9511],
            [158.315, 6.8135],
          ],
        ],
      },
      properties: {
        name: 'Federated States of Micronesia',
        id: 'FM',
      },
      id: 'FM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.6311, 62.2279],
            [-7.0135, 62.094],
            [-7.1722, 62.2856],
            [-6.6311, 62.2279],
          ],
        ],
      },
      properties: {
        name: 'Faroe Islands',
        id: 'FO',
      },
      id: 'FO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-58.8501, -51.2698],
              [-58.4737, -51.509],
              [-57.9765, -51.3843],
              [-57.8382, -51.7092],
              [-58.6834, -51.9361],
              [-59.0681, -52.1731],
              [-59.5708, -51.9254],
              [-59.0596, -51.6857],
              [-58.8501, -51.2698],
            ],
          ],
          [
            [
              [-60.2863, -51.462],
              [-59.268, -51.4274],
              [-59.9214, -51.9693],
              [-60.2863, -51.462],
            ],
          ],
        ],
      },
      properties: {
        name: 'Falkland Islands',
        id: 'FK',
      },
      id: 'FK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [178.2804, -17.372],
              [178.5915, -17.6516],
              [178.6679, -18.0808],
              [177.8471, -18.2549],
              [177.3215, -18.0773],
              [177.2638, -17.8633],
              [177.6181, -17.4612],
              [178.2804, -17.372],
            ],
          ],
          [
            [
              [179.9991, -16.1686],
              [179.7483, -16.4464],
              [179.9281, -16.7443],
              [179.007, -16.9001],
              [178.805, -16.6315],
              [179.5517, -16.2498],
              [179.9991, -16.1686],
            ],
          ],
        ],
      },
      properties: {
        name: 'Fiji',
        id: 'FJ',
      },
      id: 'FJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.9657, 69.0219],
            [28.4708, 68.4883],
            [29.3439, 68.062],
            [29.9414, 67.5475],
            [29.0869, 66.9709],
            [29.9032, 66.0911],
            [29.604, 64.9685],
            [30.0728, 64.7649],
            [30.1083, 64.3661],
            [30.5038, 64.0205],
            [30.0555, 63.689],
            [31.1807, 63.2082],
            [31.5341, 62.8855],
            [31.2855, 62.5679],
            [29.6902, 61.5461],
            [27.7978, 60.5362],
            [25.6565, 60.3331],
            [23.7216, 59.9656],
            [22.7934, 60.0768],
            [22.5759, 60.3591],
            [21.436, 60.5964],
            [21.3605, 60.9675],
            [21.606, 61.5916],
            [21.2562, 61.9897],
            [21.1035, 62.6229],
            [22.2434, 63.4379],
            [23.2488, 63.8963],
            [24.5579, 64.8009],
            [25.3729, 65.0095],
            [25.348, 65.4792],
            [24.1557, 65.8052],
            [23.7012, 66.4808],
            [23.9884, 66.8106],
            [23.4681, 67.45],
            [23.639, 67.9543],
            [22.7823, 68.3911],
            [21.9975, 68.5207],
            [20.6223, 69.037],
            [21.622, 69.2707],
            [22.4108, 68.7198],
            [23.8539, 68.8059],
            [24.9414, 68.5933],
            [25.7484, 68.9901],
            [26.0116, 69.6526],
            [26.5257, 69.915],
            [27.8897, 70.0617],
            [29.1415, 69.6715],
            [28.9657, 69.0219],
          ],
        ],
      },
      properties: {
        name: 'Finland',
        id: 'FI',
      },
      id: 'FI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.3784, 12.4665],
            [41.7667, 11.5889],
            [41.7982, 10.9803],
            [42.5577, 11.0806],
            [42.9226, 10.9994],
            [42.6594, 10.6212],
            [42.8418, 10.2031],
            [43.1818, 9.8799],
            [43.4828, 9.3796],
            [44.023, 8.9859],
            [46.9784, 7.9969],
            [47.9781, 7.9969],
            [45.9348, 5.9972],
            [44.9405, 4.9119],
            [44.0283, 4.9509],
            [43.5383, 4.8404],
            [43.0158, 4.5634],
            [42.7916, 4.2922],
            [42.0241, 4.1377],
            [41.8839, 3.9779],
            [41.2207, 3.9437],
            [40.7653, 4.2731],
            [39.8424, 3.8514],
            [39.4944, 3.4559],
            [38.0864, 3.649],
            [36.9056, 4.4116],
            [36.0818, 4.4498],
            [35.763, 4.808],
            [35.7448, 5.3442],
            [35.2681, 5.4925],
            [35.0821, 5.6731],
            [34.7106, 6.6604],
            [34.0643, 7.2259],
            [33.6661, 7.6711],
            [33.2262, 7.7608],
            [33.0127, 7.9516],
            [33.2342, 8.3964],
            [33.9533, 8.4435],
            [34.0945, 8.5824],
            [34.078, 9.4613],
            [34.2915, 10.1249],
            [34.2756, 10.528],
            [34.5716, 10.88],
            [34.9312, 10.8649],
            [35.1123, 11.8166],
            [35.5961, 12.5375],
            [36.1075, 12.7266],
            [36.2123, 13.2712],
            [36.3907, 13.6259],
            [36.5243, 14.2567],
            [37.0246, 14.2718],
            [37.2572, 14.4538],
            [37.571, 14.1493],
            [37.8844, 14.8524],
            [38.5045, 14.4245],
            [39.074, 14.6282],
            [40.2215, 14.4311],
            [40.7697, 14.1444],
            [41.3627, 13.4998],
            [41.7649, 13.1838],
            [42.3784, 12.4665],
          ],
        ],
      },
      properties: {
        name: 'Ethiopia',
        id: 'ET',
      },
      id: 'ET',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [28.0127, 59.4842],
              [27.4272, 58.7331],
              [27.753, 57.8411],
              [27.3517, 57.5282],
              [26.5328, 57.5311],
              [25.9912, 57.8382],
              [25.111, 58.0635],
              [24.3226, 57.8706],
              [24.529, 58.3543],
              [23.7678, 58.3609],
              [23.5094, 58.6585],
              [23.4943, 59.1957],
              [24.3803, 59.4726],
              [25.5091, 59.6389],
              [26.9749, 59.4507],
              [28.0127, 59.4842],
            ],
          ],
          [
            [
              [22.6172, 58.6213],
              [23.2928, 58.4834],
              [22.1875, 58.1543],
              [22.0814, 58.4781],
              [22.6172, 58.6213],
            ],
          ],
        ],
      },
      properties: {
        name: 'Estonia',
        id: 'EE',
      },
      id: 'EE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-1.794, 43.4075],
              [-1.2855, 43.0595],
              [-0.0411, 42.6893],
              [1.4284, 42.5961],
              [1.4861, 42.4345],
              [1.7059, 42.5033],
              [2.0326, 42.3537],
              [3.2116, 42.431],
              [3.248, 41.9445],
              [2.0827, 41.2875],
              [1.0329, 41.062],
              [-0.3269, 39.5199],
              [-0.2049, 39.0627],
              [0.2015, 38.7591],
              [-0.3813, 38.4355],
              [-0.6468, 38.1518],
              [-0.8221, 37.581],
              [-1.3275, 37.561],
              [-1.7975, 37.233],
              [-2.1116, 36.7767],
              [-4.3668, 36.7181],
              [-4.6742, 36.5063],
              [-5.1716, 36.4238],
              [-5.6254, 36.026],
              [-6.0407, 36.1885],
              [-6.4924, 36.9547],
              [-6.8845, 37.1944],
              [-7.4061, 37.1793],
              [-7.5036, 37.5854],
              [-7.1855, 38.0062],
              [-7.3431, 38.4572],
              [-7.0461, 38.9069],
              [-7.4452, 39.5363],
              [-7.0368, 39.7139],
              [-7.0146, 40.2084],
              [-6.8102, 40.3429],
              [-6.9285, 41.0092],
              [-6.2125, 41.5321],
              [-6.6182, 41.9422],
              [-7.4037, 41.8335],
              [-7.9208, 41.8836],
              [-8.2131, 42.1336],
              [-8.7771, 41.9409],
              [-8.691, 42.2743],
              [-9.2352, 42.977],
              [-8.8736, 43.3343],
              [-7.6982, 43.7644],
              [-7.2621, 43.5948],
              [-5.6658, 43.5824],
              [-4.523, 43.416],
              [-3.6046, 43.5194],
              [-3.0455, 43.3716],
              [-2.3371, 43.3281],
              [-1.794, 43.4075],
            ],
          ],
          [
            [
              [3.1454, 39.7902],
              [3.4619, 39.6979],
              [3.0726, 39.3011],
              [2.3713, 39.6131],
              [2.785, 39.8546],
              [3.1454, 39.7902],
            ],
          ],
          [
            [
              [-16.3346, 28.38],
              [-16.6579, 28.0071],
              [-16.9054, 28.3396],
              [-16.3346, 28.38],
            ],
          ],
        ],
      },
      properties: {
        name: 'Spain',
        id: 'ES',
      },
      id: 'ES',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [38.6093, 18.0049],
            [38.9116, 17.427],
            [39.1424, 16.7292],
            [39.2991, 15.9213],
            [39.5064, 15.532],
            [39.8637, 15.4703],
            [40.2042, 15.014],
            [41.1763, 14.6202],
            [41.6584, 13.9832],
            [42.2452, 13.5877],
            [42.7961, 12.8642],
            [43.1166, 12.7084],
            [42.67, 12.3764],
            [42.3784, 12.4665],
            [41.7649, 13.1838],
            [41.3627, 13.4998],
            [40.7697, 14.1444],
            [40.2215, 14.4311],
            [39.074, 14.6282],
            [38.5045, 14.4245],
            [37.8844, 14.8524],
            [37.571, 14.1493],
            [37.2572, 14.4538],
            [37.0246, 14.2718],
            [36.5243, 14.2567],
            [36.4267, 15.132],
            [36.9136, 16.2964],
            [37.0091, 17.059],
            [37.4112, 17.0616],
            [37.7823, 17.458],
            [38.2537, 17.5845],
            [38.6093, 18.0049],
          ],
        ],
      },
      properties: {
        name: 'Eritrea',
        id: 'ER',
      },
      id: 'ER',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-6.218, 54.0887],
              [-7.3553, 54.1213],
              [-8.1183, 54.4143],
              [-7.2186, 55.0921],
              [-6.1293, 55.2175],
              [-5.4703, 54.5002],
              [-5.6068, 54.2727],
              [-6.218, 54.0887],
            ],
          ],
          [
            [
              [-3.1097, 58.5154],
              [-3.9901, 57.959],
              [-3.4029, 57.7082],
              [-2.0741, 57.7024],
              [-1.778, 57.4938],
              [-2.6527, 56.3183],
              [-3.1783, 56.0802],
              [-2.1471, 55.9031],
              [-1.6555, 55.5704],
              [-1.2917, 54.7738],
              [-0.6714, 54.5039],
              [-0.2055, 54.0217],
              [0.5589, 52.967],
              [1.6566, 52.7537],
              [1.5589, 52.0869],
              [0.9553, 51.8077],
              [0.6867, 51.3865],
              [1.4151, 51.3632],
              [0.9601, 50.9259],
              [0.2051, 50.763],
              [-1.5168, 50.7475],
              [-2.5477, 50.6163],
              [-3.4047, 50.6323],
              [-3.7933, 50.2293],
              [-4.1726, 50.3908],
              [-5.3422, 50.2461],
              [-4.583, 50.7764],
              [-4.1881, 51.1885],
              [-3.1359, 51.2049],
              [-4.3863, 51.7412],
              [-4.9023, 51.6262],
              [-5.0881, 51.9959],
              [-4.3832, 52.1973],
              [-3.9804, 52.5417],
              [-4.1111, 53.2189],
              [-3.0646, 53.4268],
              [-2.925, 53.7327],
              [-3.958, 54.7809],
              [-5.1354, 54.8575],
              [-4.7212, 55.421],
              [-5.2457, 55.9293],
              [-5.6097, 56.0553],
              [-5.392, 56.5148],
              [-5.9368, 56.6058],
              [-5.562, 57.2328],
              [-5.8181, 57.4361],
              [-5.3382, 58.2386],
              [-4.7155, 58.5101],
              [-3.1097, 58.5154],
            ],
          ],
        ],
      },
      properties: {
        name: 'United Kingdom',
        id: 'GB',
      },
      id: 'GB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.1983, 31.3226],
            [34.2454, 31.2085],
            [34.9041, 29.4773],
            [34.7363, 29.2705],
            [34.3999, 28.016],
            [34.2201, 27.7643],
            [33.7602, 28.0475],
            [33.2475, 28.5678],
            [33.0757, 29.0729],
            [32.7215, 29.5217],
            [32.6318, 28.9921],
            [32.8982, 28.5651],
            [33.5471, 27.8984],
            [33.5498, 27.6072],
            [33.8929, 27.0497],
            [33.9591, 26.6488],
            [34.565, 25.6913],
            [35.194, 24.4751],
            [35.6246, 24.0658],
            [35.5229, 23.4426],
            [35.6978, 22.9463],
            [36.2296, 22.6289],
            [36.8714, 21.9968],
            [34.2467, 21.9964],
            [32.6061, 21.9959],
            [30.3285, 21.9951],
            [27.2723, 21.9955],
            [24.9805, 21.9959],
            [24.9805, 24.2416],
            [24.9805, 25.8134],
            [24.9805, 27.8345],
            [24.9805, 29.1817],
            [24.7115, 30.1316],
            [24.9738, 30.7766],
            [24.8526, 31.335],
            [25.1505, 31.655],
            [25.3822, 31.513],
            [25.8931, 31.6209],
            [27.2479, 31.3781],
            [27.9674, 31.0975],
            [28.5147, 31.0505],
            [29.0723, 30.8303],
            [29.5916, 31.0114],
            [30.3951, 31.4575],
            [30.7005, 31.4038],
            [31.1941, 31.5876],
            [32.1018, 31.0926],
            [33.1299, 31.1681],
            [33.6665, 31.1304],
            [34.1983, 31.3226],
          ],
        ],
      },
      properties: {
        name: 'Egypt',
        id: 'EG',
      },
      id: 'EG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-91.2722, 0.0251],
              [-90.7997, -0.7522],
              [-90.9055, -0.9404],
              [-91.3692, -0.287],
              [-91.2722, 0.0251],
            ],
          ],
          [
            [
              [-75.2845, -0.1063],
              [-75.2632, -0.5555],
              [-75.5706, -1.5312],
              [-76.0898, -2.1331],
              [-76.679, -2.5628],
              [-77.8607, -2.9819],
              [-78.3472, -3.4311],
              [-78.4198, -3.7769],
              [-79.0763, -4.9905],
              [-79.3308, -4.9279],
              [-79.6385, -4.4547],
              [-80.1396, -4.2962],
              [-80.4885, -4.3939],
              [-80.51, -4.0694],
              [-80.1793, -3.8776],
              [-80.3247, -3.388],
              [-79.9217, -3.0902],
              [-79.7299, -2.5793],
              [-80.4501, -2.6259],
              [-80.9628, -2.1891],
              [-80.7704, -2.0768],
              [-80.8015, -1.3834],
              [-80.9024, -1.0789],
              [-80.554, -0.8481],
              [-80.4823, -0.3682],
              [-80.1334, -0.006],
              [-80.0251, 0.4104],
              [-80.0883, 0.7846],
              [-79.7412, 0.9799],
              [-78.8996, 1.2063],
              [-78.8597, 1.4553],
              [-78.3121, 1.046],
              [-77.4676, 0.6363],
              [-77.4228, 0.425],
              [-76.4946, 0.2355],
              [-76.311, 0.4485],
              [-75.8798, 0.1511],
              [-75.2845, -0.1063],
            ],
          ],
        ],
      },
      properties: {
        name: 'Ecuador',
        id: 'EC',
      },
      id: 'EC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [8.5764, 36.9374],
            [8.2306, 36.5454],
            [8.2457, 35.8707],
            [8.3944, 35.204],
            [8.2457, 34.7339],
            [7.5138, 34.0805],
            [7.5, 33.8323],
            [7.7628, 33.2331],
            [8.2111, 32.9268],
            [8.3332, 32.5437],
            [9.0439, 32.0723],
            [9.5188, 30.2293],
            [9.3102, 30.1152],
            [9.6724, 29.567],
            [9.8424, 28.9668],
            [9.9161, 27.7856],
            [9.7474, 27.3306],
            [9.8593, 26.5521],
            [9.4913, 26.3337],
            [9.4482, 26.0673],
            [10.0009, 25.3322],
            [10.2557, 24.5909],
            [10.6863, 24.5514],
            [11.5075, 24.3144],
            [11.9678, 23.518],
            [9.3785, 21.9915],
            [7.4818, 20.8729],
            [5.8367, 19.4791],
            [4.2276, 19.1426],
            [3.4007, 18.9886],
            [3.1059, 19.1502],
            [3.2559, 19.4107],
            [3.2036, 19.7898],
            [2.4063, 20.0637],
            [2.2195, 20.2479],
            [1.6855, 20.3784],
            [1.1657, 20.8174],
            [1.1457, 21.1024],
            [-1.2931, 22.6964],
            [-3.2579, 23.981],
            [-4.8227, 24.9958],
            [-6.2387, 25.848],
            [-8.6834, 27.2858],
            [-8.6834, 27.6565],
            [-8.6599, 28.7187],
            [-7.6851, 29.3495],
            [-7.095, 29.6251],
            [-6.6353, 29.5687],
            [-6.5008, 29.8093],
            [-5.4488, 29.9567],
            [-4.9683, 30.4654],
            [-4.3228, 30.6989],
            [-3.8335, 31.1978],
            [-3.8268, 31.6617],
            [-3.0174, 31.8344],
            [-2.9308, 32.0426],
            [-2.4485, 32.13],
            [-1.2753, 32.0892],
            [-1.0656, 32.4683],
            [-1.5099, 32.8775],
            [-1.6792, 33.3187],
            [-1.7955, 34.7521],
            [-2.2197, 35.1041],
            [-1.9132, 35.0943],
            [-0.9175, 35.6683],
            [0.0479, 35.9004],
            [0.5149, 36.2618],
            [1.2571, 36.5197],
            [2.5932, 36.6009],
            [2.9727, 36.7847],
            [3.7788, 36.8961],
            [4.7581, 36.8965],
            [5.1958, 36.6768],
            [6.0649, 36.8641],
            [6.4866, 37.0856],
            [6.9274, 36.9196],
            [7.6079, 36.9999],
            [7.9106, 36.8566],
            [8.5764, 36.9374],
          ],
        ],
      },
      properties: {
        name: 'Algeria',
        id: 'DZ',
      },
      id: 'DZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7683, 18.0391],
            [-71.6571, 19.1306],
            [-71.7791, 19.7183],
            [-71.5578, 19.8954],
            [-70.9542, 19.9141],
            [-70.1294, 19.6362],
            [-69.7394, 19.2993],
            [-68.6848, 18.9047],
            [-68.3594, 18.538],
            [-68.6588, 18.222],
            [-68.9349, 18.408],
            [-69.7707, 18.4435],
            [-70.1831, 18.2517],
            [-71.0278, 18.273],
            [-71.3958, 17.6462],
            [-71.7683, 18.0391],
          ],
        ],
      },
      properties: {
        name: 'Dominican Republic',
        id: 'DO',
      },
      id: 'DO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.7399, 54.8255],
              [8.6701, 54.9034],
              [8.6159, 55.4181],
              [8.1321, 55.5999],
              [8.1641, 56.6069],
              [8.6186, 57.1114],
              [9.5543, 57.2325],
              [9.9623, 57.581],
              [10.5371, 57.4485],
              [10.2828, 56.6204],
              [10.1829, 55.8651],
              [9.5912, 55.4932],
              [9.7399, 54.8255],
            ],
          ],
          [
            [
              [12.5688, 55.785],
              [11.6539, 55.1869],
              [11.2864, 55.2044],
              [10.9788, 55.7215],
              [12.219, 56.1186],
              [12.5688, 55.785],
            ],
          ],
          [
            [
              [10.645, 55.6099],
              [10.7852, 55.1334],
              [9.9889, 55.1631],
              [9.8606, 55.5156],
              [10.645, 55.6099],
            ],
          ],
        ],
      },
      properties: {
        name: 'Denmark',
        id: 'DK',
      },
      id: 'DK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.2817, 15.2492],
            [-61.481, 15.5253],
            [-61.3201, 15.5853],
            [-61.2817, 15.2492],
          ],
        ],
      },
      properties: {
        name: 'Dominica',
        id: 'DM',
      },
      id: 'DM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [43.2462, 11.4997],
            [42.9226, 10.9994],
            [42.5577, 11.0806],
            [41.7982, 10.9803],
            [41.7667, 11.5889],
            [42.3784, 12.4665],
            [42.67, 12.3764],
            [43.1166, 12.7084],
            [43.41, 12.1899],
            [43.2719, 11.9698],
            [42.7992, 11.7394],
            [43.2462, 11.4997],
          ],
        ],
      },
      properties: {
        name: 'Djibouti',
        id: 'DJ',
      },
      id: 'DJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.7399, 54.8255],
            [10.3604, 54.4382],
            [11.3996, 53.9446],
            [12.1111, 54.1684],
            [12.5755, 54.4673],
            [13.0287, 54.4109],
            [13.8654, 53.8534],
            [14.2587, 53.7296],
            [14.4145, 53.2835],
            [14.1286, 52.8782],
            [14.6196, 52.5284],
            [14.7527, 52.0818],
            [14.6018, 51.8324],
            [15.0164, 51.2527],
            [14.8096, 50.8589],
            [14.3692, 50.8986],
            [12.4525, 50.3498],
            [12.2763, 50.0424],
            [12.6318, 49.4613],
            [13.8148, 48.7669],
            [13.4095, 48.3942],
            [12.8143, 48.1607],
            [12.686, 47.6693],
            [11.717, 47.5837],
            [11.2979, 47.4247],
            [10.4306, 47.541],
            [10.3129, 47.3133],
            [9.5241, 47.5242],
            [8.6177, 47.7661],
            [7.6154, 47.5925],
            [7.5843, 48.0644],
            [8.1348, 48.9735],
            [7.4507, 49.1522],
            [6.7356, 49.1606],
            [6.3441, 49.4527],
            [6.4875, 49.7985],
            [6.1164, 50.1209],
            [6.3645, 50.3163],
            [5.9939, 50.7504],
            [5.8949, 50.9843],
            [6.1932, 51.4888],
            [5.9486, 51.7625],
            [6.7418, 51.9109],
            [7.0352, 52.3801],
            [7.1973, 53.2823],
            [7.2852, 53.6814],
            [8.0091, 53.6907],
            [8.5387, 53.5569],
            [8.8978, 53.8357],
            [8.9573, 54.5383],
            [8.6701, 54.9034],
            [9.7399, 54.8255],
          ],
        ],
      },
      properties: {
        name: 'Germany',
        id: 'DE',
      },
      id: 'DE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.8096, 50.8589],
            [16.0072, 50.6117],
            [16.6792, 50.0974],
            [17.152, 50.3784],
            [17.8746, 49.9722],
            [18.5622, 49.8792],
            [18.8321, 49.5108],
            [18.161, 49.2574],
            [17.7583, 48.888],
            [17.1884, 48.861],
            [16.9531, 48.5988],
            [16.4777, 48.8001],
            [16.0573, 48.7549],
            [14.972, 48.9839],
            [14.6915, 48.5993],
            [14.0492, 48.6024],
            [13.8148, 48.7669],
            [12.6318, 49.4613],
            [12.2763, 50.0424],
            [12.4525, 50.3498],
            [14.3692, 50.8986],
            [14.8096, 50.8589],
          ],
        ],
      },
      properties: {
        name: 'Czechia',
        id: 'CZ',
      },
      id: 'CZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.4633, 35.5937],
            [33.9418, 35.2918],
            [34.0501, 34.9882],
            [32.9417, 34.5758],
            [32.4489, 34.7294],
            [32.3171, 34.9532],
            [32.9417, 35.3904],
            [33.4588, 35.3358],
            [34.4633, 35.5937],
          ],
        ],
      },
      properties: {
        name: 'Cyprus',
        id: 'CY',
      },
      id: 'CY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.3696, 19.349],
            [-81.1306, 19.3468],
            [-81.3037, 19.2718],
            [-81.3696, 19.349],
          ],
        ],
      },
      properties: {
        name: 'Cayman Islands',
        id: 'KY',
      },
      id: 'KY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [105.7253, -10.493],
            [105.6969, -10.564],
            [105.5842, -10.5125],
            [105.7053, -10.4308],
            [105.7253, -10.493],
          ],
        ],
      },
      properties: {
        name: 'Christmas Island',
        id: 'CX',
      },
      id: 'CX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.7511, 12.0599],
            [-68.8033, 12.0457],
            [-68.995, 12.142],
            [-69.154, 12.2982],
            [-69.0767, 12.3422],
            [-69.0132, 12.2312],
            [-68.8275, 12.1584],
            [-68.7511, 12.0599],
          ],
        ],
      },
      properties: {
        name: 'CuraÃ§ao',
        id: 'CW',
      },
      id: 'CW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.5617, 21.5716],
              [-82.8531, 21.4437],
              [-83.0826, 21.7913],
              [-82.7146, 21.8903],
              [-82.5617, 21.5716],
            ],
          ],
          [
            [
              [-81.8375, 23.1629],
              [-81.1785, 23.0595],
              [-80.6501, 23.103],
              [-80.0752, 22.9423],
              [-79.183, 22.3879],
              [-78.6865, 22.367],
              [-77.8651, 21.9005],
              [-77.4973, 21.8717],
              [-76.6475, 21.2844],
              [-75.7229, 21.1108],
              [-75.6428, 20.7335],
              [-74.8826, 20.6505],
              [-74.2529, 20.0797],
              [-75.1165, 19.9012],
              [-76.1586, 19.9896],
              [-77.7151, 19.8555],
              [-77.2135, 20.3003],
              [-77.2297, 20.6438],
              [-77.9974, 20.7153],
              [-78.4908, 21.0536],
              [-78.6366, 21.5157],
              [-79.2745, 21.5627],
              [-80.2313, 21.8721],
              [-80.4992, 22.0634],
              [-80.9619, 22.0528],
              [-81.8162, 22.2001],
              [-81.9034, 22.6791],
              [-82.7382, 22.6893],
              [-83.3796, 22.2228],
              [-84.3264, 22.0745],
              [-84.3612, 22.379],
              [-84.045, 22.6662],
              [-83.2577, 22.9676],
              [-82.1014, 23.1905],
              [-81.8375, 23.1629],
            ],
          ],
        ],
      },
      properties: {
        name: 'Cuba',
        id: 'CU',
      },
      id: 'CU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.6419, 10.9173],
            [-83.4482, 10.4658],
            [-82.7783, 9.6695],
            [-82.5635, 9.5767],
            [-82.9399, 9.4493],
            [-82.917, 8.7404],
            [-82.9976, 8.3676],
            [-82.8793, 8.0706],
            [-83.1625, 8.5882],
            [-83.734, 8.6144],
            [-83.6373, 9.0352],
            [-83.8956, 9.2762],
            [-84.6589, 9.6469],
            [-85.1145, 9.5816],
            [-85.6248, 9.9025],
            [-85.8305, 10.3979],
            [-85.6672, 10.7451],
            [-85.7444, 11.062],
            [-85.6215, 11.1845],
            [-84.9092, 10.9453],
            [-84.3484, 10.9799],
            [-84.1684, 10.7806],
            [-83.6419, 10.9173],
          ],
        ],
      },
      properties: {
        name: 'Costa Rica',
        id: 'CR',
      },
      id: 'CR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-23.4442, 15.0078],
            [-23.7054, 14.9611],
            [-23.7007, 15.2714],
            [-23.4442, 15.0078],
          ],
        ],
      },
      properties: {
        name: 'Cape Verde',
        id: 'CV',
      },
      id: 'CV',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [43.4659, -11.9014],
            [43.2266, -11.7518],
            [43.3793, -11.6142],
            [43.4659, -11.9014],
          ],
        ],
      },
      properties: {
        name: 'Comoros',
        id: 'KM',
      },
      id: 'KM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.3197, 11.8619],
            [-71.958, 11.6666],
            [-72.2485, 11.1965],
            [-72.6902, 10.8361],
            [-72.8693, 10.4911],
            [-73.0065, 9.7894],
            [-73.3563, 9.2269],
            [-72.7963, 9.1089],
            [-72.6654, 8.6277],
            [-72.4166, 8.3818],
            [-72.443, 7.4549],
            [-72.0843, 7.0967],
            [-71.0134, 6.9946],
            [-70.6552, 7.0829],
            [-70.095, 6.9378],
            [-69.4392, 6.1348],
            [-68.4717, 6.1565],
            [-67.8591, 6.2897],
            [-67.4394, 6.0256],
            [-67.8249, 5.2705],
            [-67.8553, 4.507],
            [-67.6616, 3.8643],
            [-67.3111, 3.416],
            [-67.8347, 2.8926],
            [-67.2108, 2.3901],
            [-66.8761, 1.2231],
            [-67.352, 2.0861],
            [-67.8152, 1.79],
            [-68.1765, 1.7198],
            [-69.8486, 1.7087],
            [-69.8522, 1.0593],
            [-69.3615, 1.0642],
            [-69.1633, 0.864],
            [-70.0539, 0.5786],
            [-70.0706, -0.1387],
            [-69.6675, -0.4823],
            [-69.4003, -1.1948],
            [-69.6691, -2.6676],
            [-69.9658, -4.2359],
            [-70.3396, -3.8142],
            [-70.7351, -3.7818],
            [-70.0741, -2.7502],
            [-70.9146, -2.2184],
            [-71.3969, -2.3342],
            [-71.7525, -2.1527],
            [-72.3957, -2.4288],
            [-73.1545, -2.2783],
            [-73.1969, -1.8304],
            [-73.4963, -1.6932],
            [-73.6643, -1.2489],
            [-74.2465, -0.9706],
            [-74.4178, -0.5809],
            [-74.8018, -0.2],
            [-75.2845, -0.1063],
            [-75.8798, 0.1511],
            [-76.311, 0.4485],
            [-76.4946, 0.2355],
            [-77.4228, 0.425],
            [-77.4676, 0.6363],
            [-78.3121, 1.046],
            [-78.8597, 1.4553],
            [-78.5769, 1.774],
            [-78.6171, 2.3067],
            [-78.1201, 2.4882],
            [-77.67, 2.8788],
            [-77.2428, 3.5855],
            [-77.1666, 3.8621],
            [-77.4272, 4.0605],
            [-77.2863, 4.7219],
            [-77.4017, 5.4161],
            [-77.2492, 5.7801],
            [-77.4694, 6.177],
            [-77.36, 6.5046],
            [-77.9011, 7.2294],
            [-77.7439, 7.537],
            [-77.3509, 7.7057],
            [-77.2124, 8.0338],
            [-77.3742, 8.6583],
            [-76.8519, 8.0906],
            [-76.8879, 8.6197],
            [-76.2769, 8.989],
            [-76.1355, 9.2656],
            [-75.6394, 9.4502],
            [-75.671, 10.1964],
            [-75.4459, 10.611],
            [-74.8446, 11.1095],
            [-74.4542, 10.9892],
            [-74.0592, 11.3408],
            [-73.3134, 11.2959],
            [-72.722, 11.7123],
            [-72.275, 11.8894],
            [-72.1358, 12.1886],
            [-71.7145, 12.4199],
            [-71.2622, 12.3351],
            [-71.3197, 11.8619],
          ],
        ],
      },
      properties: {
        name: 'Colombia',
        id: 'CO',
      },
      id: 'CO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-159.7406, -21.2494],
            [-159.8395, -21.2383],
            [-159.832, -21.2005],
            [-159.7683, -21.1885],
            [-159.7406, -21.2494],
          ],
        ],
      },
      properties: {
        name: 'Cook Islands',
        id: 'CK',
      },
      id: 'CK',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.6102, 3.4785],
            [18.4908, 2.9246],
            [18.0722, 2.0133],
            [18.058, 1.5347],
            [17.9026, 1.1179],
            [17.8875, 0.2341],
            [17.7242, -0.2777],
            [17.753, -0.5489],
            [17.2789, -0.9994],
            [16.8799, -1.2258],
            [16.5407, -1.8402],
            [16.2154, -2.178],
            [16.2171, -3.0302],
            [16.1466, -3.4639],
            [15.8722, -3.9345],
            [15.6001, -4.0308],
            [14.7794, -4.8458],
            [14.4105, -4.8311],
            [14.3581, -4.2993],
            [13.4149, -4.8373],
            [13.0726, -4.6349],
            [12.8809, -4.4454],
            [12.3846, -4.6189],
            [12.0184, -5.0042],
            [11.7773, -4.5657],
            [11.1301, -3.9163],
            [11.5043, -3.5203],
            [11.934, -3.3188],
            [11.5376, -2.8367],
            [11.5754, -2.3973],
            [12.4463, -2.3298],
            [12.4321, -1.929],
            [12.7934, -1.9316],
            [12.9918, -2.3134],
            [13.465, -2.3955],
            [13.7842, -2.1638],
            [14.0874, -2.4669],
            [14.3839, -1.8899],
            [14.4806, -0.6181],
            [13.8601, -0.2035],
            [13.8845, 0.1906],
            [14.0874, 0.5364],
            [14.4345, 0.8116],
            [14.181, 1.3701],
            [13.8512, 1.4189],
            [13.2741, 1.2409],
            [13.1627, 1.6479],
            [13.2937, 2.1615],
            [14.4842, 2.1549],
            [14.9023, 2.0124],
            [15.7417, 1.9152],
            [16.0596, 1.6763],
            [16.1834, 2.2703],
            [16.4684, 2.8318],
            [16.6735, 3.5354],
            [17.4379, 3.6845],
            [18.161, 3.4998],
            [18.6102, 3.4785],
          ],
        ],
      },
      properties: {
        name: 'Republic of Congo',
        id: 'CG',
      },
      id: 'CG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.4032, 5.1094],
            [27.7881, 4.6446],
            [28.192, 4.3503],
            [28.7269, 4.5048],
            [29.1513, 4.3881],
            [29.6768, 4.5869],
            [30.1949, 3.9819],
            [30.5083, 3.8359],
            [30.8385, 3.4905],
            [30.7284, 2.4554],
            [31.1763, 2.2703],
            [31.2527, 2.0448],
            [30.9424, 1.683],
            [29.9427, 0.8192],
            [29.9343, 0.4991],
            [29.7177, 0.0983],
            [29.5619, -0.9772],
            [29.577, -1.3878],
            [29.1295, -1.8601],
            [29.1064, -2.2335],
            [28.8765, -2.4004],
            [29.0146, -2.7204],
            [29.2245, -3.0533],
            [29.2232, -3.9109],
            [29.4034, -4.4494],
            [29.3426, -4.9829],
            [29.5943, -5.651],
            [29.4802, -6.0252],
            [29.7097, -6.6169],
            [30.3134, -7.2037],
            [30.7511, -8.1936],
            [28.9724, -8.4648],
            [28.9178, -8.7005],
            [28.4007, -9.2248],
            [28.6301, -9.8311],
            [28.639, -10.6692],
            [28.3572, -11.4828],
            [28.4823, -11.8122],
            [29.0643, -12.3489],
            [29.4274, -12.4314],
            [29.7954, -12.1553],
            [29.7954, -13.3929],
            [29.5543, -13.2491],
            [29.0141, -13.3689],
            [28.3576, -12.482],
            [27.5333, -12.1953],
            [27.0459, -11.616],
            [26.9496, -11.8988],
            [26.5962, -11.972],
            [26.0258, -11.8903],
            [25.3493, -11.6231],
            [25.1847, -11.2431],
            [24.5184, -11.4384],
            [24.32, -11.0718],
            [23.9666, -10.8716],
            [23.8339, -11.0136],
            [22.5613, -11.0558],
            [22.3069, -10.6914],
            [22.1977, -10.0406],
            [21.8133, -9.4689],
            [21.9056, -8.6934],
            [21.7511, -7.3054],
            [20.6077, -7.2778],
            [20.4821, -6.9161],
            [19.8753, -6.9862],
            [19.5277, -7.1447],
            [19.3408, -7.9668],
            [18.6532, -7.9361],
            [17.536, -8.076],
            [17.1551, -7.4612],
            [16.7094, -6.4717],
            [16.7179, -6.2414],
            [16.3152, -5.8658],
            [14.3985, -5.8925],
            [13.0682, -5.8649],
            [12.4117, -5.9866],
            [12.2137, -5.7589],
            [12.5035, -5.6958],
            [12.5222, -5.1489],
            [12.8298, -4.7366],
            [13.0726, -4.6349],
            [13.4149, -4.8373],
            [14.3581, -4.2993],
            [14.4105, -4.8311],
            [14.7794, -4.8458],
            [15.6001, -4.0308],
            [15.8722, -3.9345],
            [16.1466, -3.4639],
            [16.2171, -3.0302],
            [16.2154, -2.178],
            [16.5407, -1.8402],
            [16.8799, -1.2258],
            [17.2789, -0.9994],
            [17.753, -0.5489],
            [17.7242, -0.2777],
            [17.8875, 0.2341],
            [17.9026, 1.1179],
            [18.058, 1.5347],
            [18.0722, 2.0133],
            [18.4908, 2.9246],
            [18.6102, 3.4785],
            [18.5676, 4.2576],
            [19.0687, 4.8915],
            [19.5011, 5.1276],
            [19.8065, 5.0894],
            [20.2264, 4.8297],
            [20.558, 4.4627],
            [21.5376, 4.2447],
            [22.4223, 4.1351],
            [22.7557, 4.6469],
            [23.4171, 4.6633],
            [24.32, 4.994],
            [24.7656, 4.9301],
            [25.2495, 5.0246],
            [25.5251, 5.3123],
            [26.8222, 5.0623],
            [27.1151, 5.1977],
            [27.4032, 5.1094],
          ],
        ],
      },
      properties: {
        name: 'Democratic Republic of Congo',
        id: 'CD',
      },
      id: 'CD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.4803, 7.5237],
            [15.2459, 7.2636],
            [14.7394, 6.2799],
            [14.6169, 5.8649],
            [14.7088, 4.6655],
            [15.0226, 4.3583],
            [15.1287, 3.827],
            [15.775, 3.127],
            [16.0636, 2.9086],
            [16.1834, 2.2703],
            [16.0596, 1.6763],
            [15.7417, 1.9152],
            [14.9023, 2.0124],
            [14.4842, 2.1549],
            [13.2937, 2.1615],
            [13.2204, 2.2565],
            [11.3485, 2.2996],
            [11.3286, 2.1673],
            [9.98, 2.1677],
            [9.8007, 2.3044],
            [9.8762, 3.3099],
            [9.4252, 3.9224],
            [8.9999, 4.0916],
            [8.9027, 4.4351],
            [8.556, 4.7552],
            [8.801, 5.1973],
            [9.0603, 6.0092],
            [10.1438, 6.9964],
            [10.6064, 7.063],
            [11.0325, 6.6981],
            [11.1532, 6.438],
            [11.5518, 6.6972],
            [11.7676, 7.272],
            [12.0162, 7.5899],
            [12.4037, 8.5957],
            [12.8067, 8.8865],
            [12.9297, 9.4263],
            [13.1756, 9.5394],
            [13.2697, 10.0362],
            [13.5352, 10.6052],
            [13.892, 11.1401],
            [14.5597, 11.4921],
            [14.6271, 12.1087],
            [14.1974, 12.3839],
            [14.0638, 13.0786],
            [14.5162, 12.9796],
            [14.7612, 12.6556],
            [15.0812, 11.8455],
            [15.0297, 11.1135],
            [15.1323, 10.6483],
            [15.5411, 9.9603],
            [14.2432, 9.9798],
            [13.9773, 9.6917],
            [14.3324, 9.2034],
            [15.1163, 8.5571],
            [15.443, 7.8518],
            [15.4803, 7.5237],
          ],
        ],
      },
      properties: {
        name: 'Cameroon',
        id: 'CM',
      },
      id: 'CM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-5.5236, 10.4259],
            [-5.0999, 10.2417],
            [-4.9698, 9.9301],
            [-4.6258, 9.7134],
            [-3.8776, 9.895],
            [-3.2235, 9.8954],
            [-2.6958, 9.4813],
            [-2.7466, 9.1098],
            [-2.5058, 8.2087],
            [-2.7899, 7.9321],
            [-2.9858, 7.205],
            [-3.2358, 6.8073],
            [-3.2007, 6.3483],
            [-2.7952, 5.1844],
            [-3.0191, 5.1307],
            [-4.0372, 5.2301],
            [-5.5646, 5.0894],
            [-6.9229, 4.6384],
            [-7.5451, 4.3512],
            [-7.5688, 5.0805],
            [-7.4299, 5.3247],
            [-7.4543, 5.8414],
            [-7.7965, 5.975],
            [-7.8886, 6.2347],
            [-8.6035, 6.5077],
            [-8.3325, 6.8015],
            [-8.2966, 7.0741],
            [-8.4863, 7.5584],
            [-8.232, 7.5566],
            [-7.8236, 8.4674],
            [-7.951, 8.7866],
            [-7.8962, 9.416],
            [-8.137, 9.4955],
            [-8.1365, 10.022],
            [-7.9907, 10.1627],
            [-7.6611, 10.4272],
            [-7.1049, 10.2035],
            [-6.6932, 10.3495],
            [-6.2611, 10.7242],
            [-6.0345, 10.1946],
            [-5.5236, 10.4259],
          ],
        ],
      },
      properties: {
        name: `CÃ´te d'Ivoire`,
        id: 'CI',
      },
      id: 'CI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [110.8887, 19.9918],
              [111.0138, 19.6553],
              [110.5722, 19.1719],
              [110.4514, 18.748],
              [109.5193, 18.2184],
              [108.7016, 18.5354],
              [108.6936, 19.3384],
              [109.6511, 19.9842],
              [110.5886, 20.0726],
              [110.8887, 19.9918],
            ],
          ],
          [
            [
              [130.5269, 42.5353],
              [130.2406, 42.8917],
              [129.8983, 42.9983],
              [129.7199, 42.4749],
              [129.3137, 42.4137],
              [128.9236, 42.0381],
              [128.0451, 41.9875],
              [128.291, 41.5627],
              [128.1112, 41.3891],
              [127.1795, 41.5312],
              [126.9034, 41.7811],
              [125.989, 40.9049],
              [124.8895, 40.4596],
              [124.3621, 40.0042],
              [124.1056, 39.8409],
              [123.3483, 39.7632],
              [122.3349, 39.3663],
              [121.6499, 38.8652],
              [121.7853, 39.401],
              [121.5177, 39.6389],
              [122.2749, 40.5418],
              [121.859, 40.8423],
              [121.1745, 40.9013],
              [120.479, 40.231],
              [119.5912, 39.9026],
              [118.9125, 39.1666],
              [118.2977, 39.0671],
              [117.7845, 39.1346],
              [117.5577, 38.625],
              [118.0149, 38.1834],
              [118.94, 38.0426],
              [119.0891, 37.7008],
              [118.9529, 37.3311],
              [119.45, 37.1247],
              [120.3702, 37.7008],
              [120.7502, 37.834],
              [121.6402, 37.4603],
              [122.6025, 37.4265],
              [122.5195, 36.9467],
              [121.9327, 36.9595],
              [120.9899, 36.5978],
              [120.3933, 36.054],
              [120.0546, 35.8614],
              [119.2156, 35.0118],
              [119.5827, 34.5821],
              [120.2015, 34.3255],
              [120.8709, 33.0165],
              [120.8532, 32.6613],
              [121.4009, 32.3719],
              [121.3521, 31.8588],
              [121.0556, 31.7194],
              [121.6606, 31.3199],
              [121.8781, 30.9168],
              [120.9974, 30.5582],
              [121.1594, 30.3016],
              [121.9442, 29.8941],
              [121.7174, 29.2562],
              [121.602, 28.3667],
              [121.0986, 28.2903],
              [120.2788, 27.0972],
              [119.6484, 25.9186],
              [119.5392, 25.5915],
              [119.0248, 25.2235],
              [118.5605, 24.5803],
              [118.0873, 24.6269],
              [118.0562, 24.246],
              [116.5381, 23.1798],
              [116.4706, 22.9459],
              [115.4985, 22.719],
              [115.1957, 22.8171],
              [114.854, 22.6169],
              [114.2658, 22.541],
              [114.0154, 22.5117],
              [113.6195, 22.8615],
              [113.5467, 22.2241],
              [113.4943, 22.2414],
              [113.4788, 22.1957],
              [113.0083, 22.1193],
              [112.5861, 21.7767],
              [111.9438, 21.8495],
              [111.6029, 21.5592],
              [110.771, 21.3865],
              [110.1935, 21.0376],
              [110.5114, 20.5182],
              [110.123, 20.2639],
              [109.6626, 20.9168],
              [109.9307, 21.4806],
              [109.5441, 21.5378],
              [109.1486, 21.4255],
              [109.0305, 21.6266],
              [108.3247, 21.6937],
              [107.9727, 21.5081],
              [107.3513, 21.6089],
              [106.7294, 22.0004],
              [106.5363, 22.3954],
              [106.7804, 22.779],
              [106.5421, 22.9081],
              [105.8429, 22.9228],
              [105.2752, 23.3454],
              [104.8646, 23.1363],
              [104.7958, 22.9112],
              [104.3719, 22.704],
              [103.3008, 22.7643],
              [103.0051, 22.4532],
              [102.5173, 22.7408],
              [102.1276, 22.379],
              [101.6712, 22.4625],
              [101.5243, 22.2538],
              [101.744, 21.778],
              [101.705, 21.1503],
              [101.2242, 21.2236],
              [101.139, 21.5676],
              [100.2148, 21.4628],
              [99.9179, 22.0279],
              [99.193, 22.126],
              [99.3377, 22.498],
              [99.3408, 23.0959],
              [98.8636, 23.1913],
              [98.7642, 24.116],
              [98.2124, 24.1106],
              [97.686, 23.898],
              [97.5315, 24.4915],
              [97.8196, 25.2519],
              [98.0109, 25.2923],
              [98.6546, 25.9177],
              [98.7318, 26.5836],
              [98.651, 27.5726],
              [98.299, 27.5499],
              [98.0988, 28.1421],
              [97.5377, 28.5101],
              [97.3224, 28.218],
              [96.776, 28.3671],
              [96.4355, 29.0507],
              [96.0352, 29.4471],
              [95.4204, 29.0543],
              [94.7696, 29.1759],
              [94.6231, 29.3126],
              [93.9022, 28.803],
              [93.2067, 28.5909],
              [92.702, 28.147],
              [92.6642, 27.949],
              [91.9775, 27.7301],
              [91.6317, 27.7599],
              [91.6056, 27.9516],
              [90.3529, 28.0804],
              [89.9809, 28.3112],
              [89.537, 28.1075],
              [88.8912, 27.316],
              [88.8485, 27.8686],
              [88.6213, 28.0919],
              [88.1099, 27.8704],
              [87.1413, 27.8385],
              [86.5545, 28.0853],
              [86.4089, 27.9286],
              [85.6783, 28.2775],
              [85.1225, 28.3161],
              [85.1589, 28.5922],
              [84.7141, 28.5957],
              [84.2285, 28.9118],
              [84.1016, 29.2198],
              [83.5835, 29.1834],
              [83.1556, 29.6127],
              [82.8542, 29.6833],
              [82.1591, 30.1152],
              [82.0432, 30.3269],
              [81.4169, 30.3376],
              [81.0103, 30.1645],
              [80.6823, 30.4148],
              [80.1913, 30.5684],
              [79.9245, 30.8889],
              [79.9165, 30.8942],
              [79.3697, 31.0798],
              [79.3386, 31.1055],
              [79.1073, 31.4025],
              [78.8445, 31.3017],
              [78.7198, 31.8876],
              [78.3896, 32.5197],
              [79.2192, 32.5011],
              [79.2165, 32.5641],
              [79.1353, 33.1718],
              [79.1127, 33.2264],
              [78.8019, 33.4999],
              [78.7531, 34.0876],
              [78.9364, 34.3521],
              [78.2821, 34.654],
              [77.8107, 35.4845],
              [77.7992, 35.496],
              [77.4467, 35.4756],
              [76.2518, 35.8108],
              [75.945, 36.0176],
              [75.8403, 36.6497],
              [75.3467, 36.9134],
              [74.5415, 37.0221],
              [74.8912, 37.2317],
              [74.7452, 38.5101],
              [74.2773, 38.6597],
              [73.8015, 38.6068],
              [73.6315, 39.4489],
              [73.9147, 39.6065],
              [73.9915, 40.0433],
              [74.4118, 40.1374],
              [75.0044, 40.4494],
              [75.5837, 40.6052],
              [75.8718, 40.3034],
              [76.4804, 40.4494],
              [76.8239, 40.9821],
              [78.1232, 41.0758],
              [78.3625, 41.3718],
              [79.2938, 41.7829],
              [80.2162, 42.0323],
              [80.2095, 42.1899],
              [80.1651, 42.6653],
              [80.7857, 43.1616],
              [80.3551, 44.0973],
              [80.4554, 44.7463],
              [80.059, 45.0064],
              [81.602, 45.3109],
              [82.4787, 45.1236],
              [82.3264, 45.52],
              [83.0043, 47.0337],
              [83.1929, 47.1864],
              [84.786, 46.8308],
              [85.6565, 47.2547],
              [85.526, 47.9157],
              [85.7493, 48.3849],
              [86.5496, 48.5287],
              [86.7534, 49.0088],
              [87.3229, 49.0858],
              [87.8143, 49.1624],
              [87.9798, 48.5549],
              [88.517, 48.3844],
              [89.0478, 48.0027],
              [89.561, 48.004],
              [90.3134, 47.676],
              [90.4963, 47.2854],
              [90.87, 46.9547],
              [91.0338, 46.529],
              [91.0019, 46.0358],
              [90.6618, 45.5253],
              [90.8771, 45.196],
              [91.5842, 45.0766],
              [92.7876, 45.0357],
              [93.6563, 44.9008],
              [94.7119, 44.3508],
              [95.3502, 44.278],
              [95.9126, 43.2064],
              [96.3854, 42.7204],
              [97.2057, 42.7896],
              [99.4678, 42.5681],
              [99.984, 42.6773],
              [101.7139, 42.4656],
              [101.9731, 42.2157],
              [103.0726, 42.0062],
              [103.7109, 41.7514],
              [104.4984, 41.877],
              [104.4984, 41.6586],
              [105.1971, 41.738],
              [106.7702, 42.2889],
              [108.1712, 42.4474],
              [109.4434, 42.4558],
              [110.4004, 42.7736],
              [111.0072, 43.3414],
              [111.9331, 43.7116],
              [111.4022, 44.3672],
              [111.8981, 45.0641],
              [113.5871, 44.7459],
              [114.0305, 44.9425],
              [114.517, 45.3646],
              [115.6809, 45.4583],
              [116.2407, 45.7961],
              [116.5625, 46.2897],
              [117.3335, 46.3621],
              [118.3088, 46.7172],
              [119.7066, 46.6062],
              [119.8979, 46.8579],
              [119.711, 47.15],
              [118.4983, 47.9841],
              [117.7685, 47.988],
              [117.3508, 47.652],
              [116.7605, 47.87],
              [115.8984, 47.6871],
              [115.5575, 47.945],
              [116.6832, 49.8238],
              [117.8733, 49.5135],
              [118.4517, 49.8444],
              [119.26, 50.0664],
              [119.1921, 50.3797],
              [120.067, 51.6007],
              [120.7497, 52.0965],
              [120.6561, 52.5666],
              [120.0945, 52.7872],
              [120.704, 53.1718],
              [122.338, 53.485],
              [123.6079, 53.5465],
              [124.8122, 53.1339],
              [125.649, 53.0422],
              [126.313, 52.3997],
              [126.9247, 51.1002],
              [127.5901, 50.2091],
              [127.5027, 49.8735],
              [127.9998, 49.5685],
              [128.5267, 49.5943],
              [129.5339, 49.3235],
              [130.1962, 48.8916],
              [130.8043, 48.3414],
              [130.712, 48.1279],
              [131.0027, 47.6915],
              [132.5617, 47.7683],
              [133.1441, 48.1057],
              [133.4686, 48.0972],
              [134.2933, 48.3733],
              [134.5636, 48.3219],
              [134.7523, 47.7155],
              [134.1677, 47.3022],
              [133.8614, 46.2475],
              [133.1858, 45.4947],
              [132.9359, 45.03],
              [131.9775, 45.2439],
              [131.0822, 44.9101],
              [131.2553, 44.0716],
              [131.262, 43.4333],
              [131.0684, 42.9024],
              [130.5771, 42.8118],
              [130.5269, 42.5353],
            ],
          ],
        ],
      },
      properties: {
        name: 'China',
        id: 'CN',
      },
      id: 'CN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.0799, -55.1538],
              [-68.07, -55.2213],
              [-68.107, -54.9292],
              [-67.2452, -54.9776],
              [-67.0799, -55.1538],
            ],
          ],
          [
            [
              [-69.703, -54.919],
              [-68.9007, -55.0176],
              [-68.2826, -55.255],
              [-68.2932, -55.5214],
              [-69.4119, -55.4441],
              [-69.9798, -55.1476],
              [-69.703, -54.919],
            ],
          ],
          [
            [
              [-72.9233, -53.4817],
              [-72.2055, -53.8075],
              [-73.3048, -53.9438],
              [-73.6865, -53.4266],
              [-72.9233, -53.4817],
            ],
          ],
          [
            [
              [-68.6299, -52.6525],
              [-68.6532, -54.8538],
              [-69.4862, -54.8591],
              [-69.7235, -54.7122],
              [-70.4972, -54.8094],
              [-71.4409, -54.6198],
              [-70.8982, -54.338],
              [-70.8678, -53.8843],
              [-70.0903, -53.4182],
              [-70.3906, -53.0263],
              [-69.7636, -52.7315],
              [-68.6299, -52.6525],
            ],
          ],
          [
            [
              [-74.4762, -49.148],
              [-74.5699, -49.9909],
              [-75.3001, -49.8475],
              [-75.1842, -49.0836],
              [-74.7934, -48.705],
              [-74.4762, -49.148],
            ],
          ],
          [
            [
              [-74.5672, -48.5918],
              [-74.923, -48.6264],
              [-75.2129, -48.1417],
              [-74.7292, -48.1261],
              [-74.5672, -48.5918],
            ],
          ],
          [
            [
              [-72.9861, -44.7801],
              [-73.4451, -44.6411],
              [-73.2078, -44.3348],
              [-72.7641, -44.5488],
              [-72.9861, -44.7801],
            ],
          ],
          [
            [
              [-73.7354, -44.3948],
              [-73.9833, -44.4946],
              [-73.7271, -45.1192],
              [-74.0991, -45.3252],
              [-74.6178, -44.6478],
              [-74.5017, -44.4733],
              [-73.7354, -44.3948],
            ],
          ],
          [
            [
              [-73.7733, -43.3459],
              [-74.355, -43.2637],
              [-74.2094, -42.8789],
              [-74.0368, -41.7953],
              [-73.5278, -41.8961],
              [-73.4708, -42.4665],
              [-73.7893, -42.5859],
              [-73.4364, -42.9366],
              [-73.7733, -43.3459],
            ],
          ],
          [
            [
              [-67.1948, -22.8216],
              [-67.0088, -23.0014],
              [-67.3562, -24.0339],
              [-68.2502, -24.3921],
              [-68.562, -24.8378],
              [-68.3842, -25.0921],
              [-68.6002, -25.4859],
              [-68.4144, -26.1539],
              [-68.5811, -26.5183],
              [-68.3461, -27.0279],
              [-68.8463, -27.1536],
              [-69.1744, -27.9246],
              [-69.6569, -28.4138],
              [-69.8278, -29.1031],
              [-70.0269, -29.3242],
              [-69.9277, -29.7694],
              [-69.9563, -30.358],
              [-70.1614, -30.4401],
              [-70.5196, -31.1486],
              [-70.5853, -31.5694],
              [-70.1696, -32.4718],
              [-70.0198, -33.2717],
              [-69.7978, -33.3987],
              [-69.8524, -34.2243],
              [-70.2899, -34.733],
              [-70.4703, -35.3261],
              [-70.4048, -36.0616],
              [-71.0556, -36.5237],
              [-71.1677, -37.7621],
              [-71.0281, -38.0413],
              [-70.9517, -38.7383],
              [-71.4256, -38.9855],
              [-71.5394, -39.6025],
              [-71.7199, -39.6354],
              [-71.6952, -40.3354],
              [-71.932, -40.6918],
              [-71.9114, -41.6506],
              [-71.7501, -42.0466],
              [-72.1247, -42.2983],
              [-72.1464, -42.9899],
              [-71.7816, -43.167],
              [-71.905, -43.44],
              [-71.6801, -43.9296],
              [-71.82, -44.3832],
              [-71.2127, -44.4414],
              [-71.2611, -44.7632],
              [-71.5962, -44.9794],
              [-71.3537, -45.2306],
              [-71.7727, -45.7242],
              [-71.8757, -46.1606],
              [-71.6952, -46.5783],
              [-71.9403, -46.8313],
              [-71.905, -47.2015],
              [-72.346, -47.4927],
              [-72.518, -47.8762],
              [-72.3549, -48.3658],
              [-72.6143, -48.7929],
              [-73.5762, -49.583],
              [-73.5012, -50.1254],
              [-73.153, -50.7384],
              [-72.5098, -50.6075],
              [-72.2763, -50.9102],
              [-72.4077, -51.541],
              [-71.9187, -51.9898],
              [-69.9603, -52.0084],
              [-68.4433, -52.3569],
              [-69.241, -52.2055],
              [-69.5606, -52.4217],
              [-70.795, -52.7688],
              [-70.9959, -53.7791],
              [-71.2977, -53.8834],
              [-72.1744, -53.6322],
              [-71.8528, -53.2855],
              [-71.4003, -53.107],
              [-71.3878, -52.7644],
              [-72.4583, -53.2544],
              [-73.0527, -53.2433],
              [-74.0143, -52.6392],
              [-73.5183, -52.0408],
              [-73.9733, -51.7847],
              [-73.8958, -51.3315],
              [-74.8147, -51.0629],
              [-75.0948, -50.6812],
              [-74.3337, -49.9745],
              [-74.3411, -48.5958],
              [-74.5772, -48.2744],
              [-74.6549, -47.7022],
              [-74.1584, -47.1824],
              [-74.152, -46.9742],
              [-75.6567, -46.6102],
              [-74.9245, -46.1597],
              [-75.0666, -45.8751],
              [-74.1579, -45.7673],
              [-74.0377, -45.4175],
              [-73.5498, -45.4836],
              [-73.3625, -44.978],
              [-72.739, -44.7343],
              [-72.6638, -44.4365],
              [-73.2651, -44.1688],
              [-72.9965, -43.6317],
              [-72.7852, -42.3014],
              [-72.4993, -41.9809],
              [-72.6698, -41.6595],
              [-73.0149, -41.5441],
              [-73.5214, -41.7971],
              [-73.966, -41.1184],
              [-73.6694, -40.0824],
              [-73.4104, -39.7889],
              [-73.2264, -39.2243],
              [-73.5325, -38.3667],
              [-73.4648, -38.0405],
              [-73.6619, -37.6987],
              [-73.6017, -37.1886],
              [-73.216, -37.1669],
              [-73.1181, -36.6884],
              [-72.8747, -36.3905],
              [-72.6239, -35.5857],
              [-72.2237, -35.0961],
              [-72.0029, -34.1653],
              [-71.6644, -33.6526],
              [-71.743, -33.0951],
              [-71.4522, -32.6596],
              [-71.5259, -31.806],
              [-71.6619, -31.1695],
              [-71.6695, -30.3305],
              [-71.4005, -30.1432],
              [-71.3157, -29.6496],
              [-71.5192, -28.9265],
              [-71.1863, -28.3778],
              [-71.0527, -27.7275],
              [-70.6465, -26.3293],
              [-70.7138, -25.7842],
              [-70.4455, -25.1725],
              [-70.5742, -24.6442],
              [-70.4878, -23.7818],
              [-70.5633, -23.0569],
              [-70.3316, -22.8487],
              [-70.0875, -21.493],
              [-70.1971, -20.7256],
              [-70.1483, -19.8049],
              [-70.4184, -18.3454],
              [-69.9263, -18.206],
              [-69.8522, -17.704],
              [-69.5108, -17.506],
              [-69.3133, -17.9432],
              [-69.094, -18.0507],
              [-68.9684, -18.9682],
              [-68.4628, -19.4329],
              [-68.6983, -19.721],
              [-68.7451, -20.4588],
              [-68.4999, -20.6119],
              [-68.1969, -21.3004],
              [-67.9883, -22.0572],
              [-67.8795, -22.8229],
              [-67.1948, -22.8216],
            ],
          ],
        ],
      },
      properties: {
        name: 'Chile',
        id: 'CL',
      },
      id: 'CL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.5241, 47.5242],
            [9.5277, 47.2707],
            [9.4877, 47.0621],
            [9.5801, 47.0572],
            [10.4528, 46.865],
            [9.9392, 46.3616],
            [9.2512, 46.2866],
            [8.9537, 45.8298],
            [8.4584, 46.2458],
            [7.9932, 46.0158],
            [7.021, 45.9257],
            [6.7583, 46.4158],
            [6.06, 46.4282],
            [7.0006, 47.3227],
            [7.6154, 47.5925],
            [8.6177, 47.7661],
            [9.5241, 47.5242],
          ],
        ],
      },
      properties: {
        name: 'Switzerland',
        id: 'CH',
      },
      id: 'CH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [96.8404, -12.182],
            [96.835, -12.1797],
            [96.8279, -12.1509],
            [96.8257, -12.126],
            [96.8328, -12.126],
            [96.8395, -12.1602],
            [96.8404, -12.182],
          ],
        ],
      },
      properties: {
        name: 'Cocos (Keeling) Islands',
        id: 'CC',
      },
      id: 'CC',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-105.289, 72.9199],
              [-106.9216, 73.4799],
              [-105.5123, 73.7657],
              [-104.583, 73.3538],
              [-105.289, 72.9199],
            ],
          ],
          [
            [
              [-79.5373, 73.6546],
              [-77.2066, 73.4996],
              [-76.2895, 73.081],
              [-76.4005, 72.8207],
              [-77.836, 72.8968],
              [-79.5006, 72.7559],
              [-80.8241, 73.3807],
              [-80.849, 73.7211],
              [-79.5373, 73.6546],
            ],
          ],
          [
            [
              [-86.5894, 71.0107],
              [-84.7896, 71.0933],
              [-84.6995, 71.6315],
              [-85.9115, 71.9866],
              [-85.3411, 72.4216],
              [-85.6445, 72.7745],
              [-85.0184, 73.3356],
              [-82.6596, 73.7296],
              [-81.406, 73.6346],
              [-80.2748, 72.7455],
              [-80.9413, 72.2101],
              [-78.453, 72.4352],
              [-77.7532, 72.7248],
              [-75.2943, 72.4809],
              [-74.9032, 72.1005],
              [-73.7136, 71.7198],
              [-71.6406, 71.5163],
              [-70.7926, 71.1033],
              [-70.5609, 70.7382],
              [-69.1686, 70.7641],
              [-67.3637, 70.0344],
              [-66.6852, 69.2858],
              [-67.7517, 69.0387],
              [-68.038, 68.5507],
              [-67.033, 68.3261],
              [-66.2252, 67.9588],
              [-64.5275, 67.8127],
              [-64.0079, 67.3473],
              [-61.9684, 67.019],
              [-61.2997, 66.6488],
              [-61.9915, 66.0354],
              [-62.6589, 65.6399],
              [-63.3643, 65.5433],
              [-63.4018, 65.1185],
              [-64.6653, 65.1689],
              [-65.4015, 65.7639],
              [-65.3054, 66.0085],
              [-66.4771, 66.2797],
              [-67.1833, 66.0345],
              [-68.2568, 65.9386],
              [-67.3034, 65.4829],
              [-66.8606, 65.0917],
              [-65.2748, 64.6316],
              [-64.6784, 64.0281],
              [-64.5144, 63.2639],
              [-65.0688, 63.2635],
              [-65.2659, 62.7151],
              [-65.9788, 63.0007],
              [-67.676, 63.0935],
              [-66.2813, 62.3027],
              [-66.2567, 61.8683],
              [-67.4401, 62.1513],
              [-69.0823, 62.4052],
              [-69.5452, 62.7446],
              [-70.2362, 62.7634],
              [-71.2538, 63.0424],
              [-73.4437, 64.4236],
              [-74.5124, 64.6702],
              [-75.0675, 64.4567],
              [-76.7239, 64.242],
              [-77.7912, 64.367],
              [-78.0957, 64.9392],
              [-77.4474, 65.1616],
              [-77.3267, 65.4532],
              [-75.8283, 65.227],
              [-73.5607, 65.5429],
              [-74.434, 66.139],
              [-73.0333, 66.7283],
              [-72.2201, 67.2543],
              [-73.3283, 68.2668],
              [-74.648, 68.7076],
              [-74.9172, 68.9828],
              [-76.4034, 68.6923],
              [-75.7873, 69.3186],
              [-77.1289, 69.6526],
              [-78.9797, 70.5813],
              [-79.3475, 70.3722],
              [-78.7778, 70.0477],
              [-79.3033, 69.8948],
              [-81.0982, 70.0912],
              [-82.1387, 69.8413],
              [-86.3219, 70.1454],
              [-86.6244, 70.4013],
              [-87.9006, 70.2519],
              [-88.8483, 70.5229],
              [-89.3655, 71.0671],
              [-88.0387, 70.9512],
              [-87.8724, 71.2085],
              [-89.0794, 71.2879],
              [-89.8458, 71.4924],
              [-90.0251, 71.9489],
              [-89.2631, 73.0691],
              [-87.7197, 73.7229],
              [-85.1105, 73.8081],
              [-86.6293, 72.8709],
              [-86.0361, 71.7709],
              [-85.0233, 71.3532],
              [-86.5894, 71.0107],
            ],
          ],
          [
            [
              [-100.002, 73.946],
              [-99.1579, 73.7316],
              [-97.5819, 73.8876],
              [-97.0017, 73.6665],
              [-97.7959, 73.2852],
              [-97.0519, 72.6367],
              [-96.6133, 71.8339],
              [-98.663, 71.3021],
              [-99.167, 71.3672],
              [-100.5944, 72.1524],
              [-101.7239, 72.3149],
              [-102.7137, 72.783],
              [-100.4847, 72.773],
              [-100.2828, 73.1203],
              [-101.4509, 73.4311],
              [-100.9151, 73.8053],
              [-100.002, 73.946],
            ],
          ],
          [
            [
              [-93.171, 74.161],
              [-92.2228, 73.9724],
              [-90.3547, 73.8687],
              [-92.118, 72.7539],
              [-93.3407, 72.8018],
              [-94.0377, 72.0288],
              [-95.1929, 72.0275],
              [-95.5475, 72.7816],
              [-95.633, 73.6954],
              [-95.0397, 74.0239],
              [-93.171, 74.161],
            ],
          ],
          [
            [
              [-119.7363, 74.1126],
              [-117.5149, 74.2318],
              [-115.5107, 73.6188],
              [-115.4468, 73.4388],
              [-118.9615, 72.6842],
              [-120.18, 72.2126],
              [-120.6192, 71.5057],
              [-123.2107, 71.1235],
              [-124.0077, 71.6775],
              [-125.7626, 72.1375],
              [-124.9871, 72.5881],
              [-124.8364, 73.0762],
              [-123.7973, 73.7682],
              [-124.6962, 74.3481],
              [-121.3153, 74.5299],
              [-119.7363, 74.1126],
            ],
          ],
          [
            [
              [-93.5427, 75.028],
              [-93.5472, 74.691],
              [-94.6973, 74.6422],
              [-96.5658, 75.0988],
              [-95.6707, 75.5287],
              [-94.4272, 75.5935],
              [-93.5427, 75.028],
            ],
          ],
          [
            [
              [-102.2275, 76.0149],
              [-103.3148, 75.7641],
              [-104.3506, 76.1823],
              [-102.5841, 76.2817],
              [-102.2275, 76.0149],
            ],
          ],
          [
            [
              [-97.7009, 76.4664],
              [-97.4097, 75.5522],
              [-97.7994, 75.1167],
              [-100.2344, 75.0078],
              [-100.712, 75.4064],
              [-102.5413, 75.5136],
              [-101.4313, 75.9919],
              [-102.1378, 76.2848],
              [-100.388, 76.6135],
              [-97.7009, 76.4664],
            ],
          ],
          [
            [
              [-108.2924, 76.0571],
              [-105.481, 75.7457],
              [-105.8625, 75.1915],
              [-107.1533, 74.9272],
              [-108.8312, 75.0648],
              [-110.9408, 74.6387],
              [-112.5193, 74.4169],
              [-113.5141, 74.43],
              [-114.4518, 75.0879],
              [-115.7289, 74.9682],
              [-117.6001, 75.2716],
              [-116.6098, 76.0738],
              [-115.5806, 76.4375],
              [-114.1939, 76.4515],
              [-112.6975, 76.2016],
              [-111.0527, 75.5484],
              [-109.0865, 75.5065],
              [-108.9449, 75.6989],
              [-110.2701, 76.4169],
              [-108.467, 76.7376],
              [-108.2924, 76.0571],
            ],
          ],
          [
            [
              [-94.2949, 76.9125],
              [-92.7162, 76.6029],
              [-91.3051, 76.6808],
              [-90.2515, 76.0536],
              [-88.2014, 75.512],
              [-86.0687, 75.5023],
              [-83.932, 75.819],
              [-82.1538, 75.831],
              [-79.6602, 75.4494],
              [-79.508, 74.8801],
              [-80.2777, 74.5816],
              [-81.8089, 74.4766],
              [-88.4231, 74.4942],
              [-89.8444, 74.5485],
              [-91.9616, 74.7931],
              [-92.4281, 75.3828],
              [-92.1851, 75.8465],
              [-93.0917, 76.3541],
              [-95.2739, 76.2644],
              [-96.6397, 76.703],
              [-95.6383, 77.0639],
              [-94.2949, 76.9125],
            ],
          ],
          [
            [
              [-115.5513, 77.3633],
              [-116.2205, 76.6111],
              [-119.3679, 76.2218],
              [-119.9128, 75.8589],
              [-121.9083, 76.0347],
              [-121.6138, 76.4415],
              [-119.0903, 77.3051],
              [-115.5513, 77.3633],
            ],
          ],
          [
            [
              [-95.4843, 77.7921],
              [-93.301, 77.7399],
              [-93.5196, 77.4745],
              [-95.987, 77.484],
              [-95.4843, 77.7921],
            ],
          ],
          [
            [
              [-110.4581, 78.1032],
              [-109.7718, 77.9574],
              [-110.1984, 77.5246],
              [-111.952, 77.3442],
              [-113.1643, 77.5302],
              [-113.1872, 77.9124],
              [-110.4581, 78.1032],
            ],
          ],
          [
            [
              [-109.816, 78.6503],
              [-109.3905, 78.3367],
              [-113.1499, 78.4084],
              [-110.4077, 78.7566],
              [-109.816, 78.6503],
            ],
          ],
          [
            [
              [-96.2045, 78.5314],
              [-94.8873, 78.3452],
              [-95.087, 77.9927],
              [-96.9895, 77.8061],
              [-98.2548, 78.4293],
              [-96.2045, 78.5314],
            ],
          ],
          [
            [
              [-103.426, 79.3157],
              [-99.6094, 78.5831],
              [-99.1664, 77.8569],
              [-100.5859, 77.8917],
              [-101.0742, 78.1938],
              [-102.7313, 78.3711],
              [-103.5183, 78.7691],
              [-105.5358, 79.0325],
              [-105.3877, 79.3235],
              [-103.426, 79.3157],
            ],
          ],
          [
            [
              [-91.8857, 81.1328],
              [-90.643, 80.5937],
              [-87.6749, 80.3722],
              [-87.1442, 79.6626],
              [-85.5014, 79.5303],
              [-85.2899, 79.2083],
              [-87.0164, 78.8987],
              [-88.7911, 78.1925],
              [-90.6144, 78.1498],
              [-92.6782, 78.3891],
              [-95.1032, 79.29],
              [-95.297, 79.6531],
              [-96.7734, 80.1358],
              [-95.6146, 80.3962],
              [-96.1328, 80.6913],
              [-95.2697, 81.0007],
              [-91.8857, 81.1328],
            ],
          ],
          [
            [
              [-69.4889, 83.0169],
              [-63.641, 82.8127],
              [-61.4771, 82.4674],
              [-62.1766, 82.0434],
              [-64.5741, 81.7336],
              [-65.4839, 81.2848],
              [-70.1434, 80.3977],
              [-71.3878, 79.7618],
              [-75.2595, 79.4209],
              [-76.2558, 79.0068],
              [-74.4332, 78.724],
              [-75.866, 78.0098],
              [-78.0127, 77.9461],
              [-78.0762, 77.5191],
              [-79.3408, 77.1584],
              [-78.2888, 76.978],
              [-78.2844, 76.5711],
              [-80.6903, 76.1765],
              [-81.1708, 76.5128],
              [-83.8856, 76.4531],
              [-85.1412, 76.3046],
              [-89.5701, 76.4919],
              [-89.4997, 76.8268],
              [-88.3982, 77.104],
              [-86.8737, 77.2004],
              [-88.0946, 77.7193],
              [-87.0179, 77.8922],
              [-87.5517, 78.1767],
              [-86.808, 78.7744],
              [-84.3162, 78.9753],
              [-85.0899, 79.6122],
              [-86.4207, 79.8453],
              [-86.3073, 80.3194],
              [-83.7236, 80.229],
              [-80.0511, 80.5287],
              [-83.4013, 80.714],
              [-85.3074, 80.526],
              [-88.9214, 80.8057],
              [-89.9474, 81.1725],
              [-88.9784, 81.5414],
              [-90.4903, 81.8772],
              [-86.5807, 82.1873],
              [-82.1225, 82.6019],
              [-79.8864, 82.9386],
              [-69.97, 83.1161],
              [-69.4889, 83.0169],
            ],
          ],
          [
            [
              [-61.1053, 45.9448],
              [-60.8061, 45.738],
              [-60.5049, 46.204],
              [-59.8651, 46.1597],
              [-60.3862, 45.6545],
              [-61.2837, 45.5737],
              [-61.4953, 45.9413],
              [-60.8703, 46.7966],
              [-60.3318, 46.7678],
              [-60.5768, 46.1721],
              [-61.1053, 45.9448],
            ],
          ],
          [
            [
              [-63.8113, 46.4686],
              [-63.1294, 46.422],
              [-62.1635, 46.4872],
              [-62.8784, 46.0012],
              [-63.641, 46.2307],
              [-63.8113, 46.4686],
            ],
          ],
          [
            [
              [-74.7088, 45.0038],
              [-73.558, 45.425],
              [-73.1596, 46.0101],
              [-71.901, 46.632],
              [-71.2611, 46.7562],
              [-70.5196, 47.0323],
              [-68.987, 48.2748],
              [-67.5608, 48.8559],
              [-66.1781, 49.2132],
              [-64.8364, 49.1917],
              [-64.2618, 48.9218],
              [-64.2538, 48.5505],
              [-65.2595, 48.0213],
              [-65.9266, 48.1887],
              [-65.6665, 47.696],
              [-65.2282, 47.8114],
              [-64.7033, 47.7248],
              [-64.9121, 47.3688],
              [-65.3189, 47.1012],
              [-64.9057, 46.8881],
              [-64.5415, 46.2404],
              [-63.2928, 45.7522],
              [-62.4831, 45.6217],
              [-61.9556, 45.868],
              [-61.4924, 45.6869],
              [-61.2837, 45.2355],
              [-63.7611, 44.4862],
              [-64.286, 44.5501],
              [-64.8624, 43.8678],
              [-65.7382, 43.5607],
              [-66.1257, 43.8137],
              [-66.0995, 44.3677],
              [-65.6567, 44.7605],
              [-64.4489, 45.2559],
              [-64.8979, 45.6261],
              [-65.8845, 45.223],
              [-66.8726, 45.0672],
              [-67.1249, 45.1693],
              [-67.4725, 45.2759],
              [-67.4328, 45.603],
              [-67.7845, 45.7016],
              [-67.8067, 47.083],
              [-68.311, 47.3546],
              [-68.8288, 47.2032],
              [-69.243, 47.4629],
              [-70.0078, 46.7088],
              [-70.2963, 45.9062],
              [-70.898, 45.2626],
              [-71.3272, 45.2901],
              [-71.5175, 45.0078],
              [-74.7088, 45.0038],
            ],
          ],
          [
            [
              [-61.8011, 49.094],
              [-62.2195, 49.0791],
              [-63.5658, 49.3994],
              [-64.1315, 49.9416],
              [-62.8584, 49.7055],
              [-61.8171, 49.2835],
              [-61.8011, 49.094],
            ],
          ],
          [
            [
              [-127.1973, 50.6403],
              [-125.4821, 50.3167],
              [-124.6429, 49.4287],
              [-123.9959, 49.2241],
              [-123.497, 48.582],
              [-123.5946, 48.3334],
              [-124.6893, 48.5975],
              [-125.8285, 49.0918],
              [-125.8354, 49.2767],
              [-126.5485, 49.4189],
              [-126.683, 49.8764],
              [-127.1143, 49.8797],
              [-128.2675, 50.6092],
              [-127.9182, 50.8605],
              [-127.1973, 50.6403],
            ],
          ],
          [
            [
              [-55.4588, 51.5365],
              [-55.8714, 50.9073],
              [-56.6939, 50.0597],
              [-55.7648, 49.9605],
              [-55.892, 49.5803],
              [-54.9827, 49.268],
              [-54.579, 49.4908],
              [-53.9577, 49.4418],
              [-53.5733, 49.1413],
              [-53.853, 48.8112],
              [-53.5312, 48.2317],
              [-52.955, 48.0293],
              [-53.1756, 47.6529],
              [-52.6536, 47.5495],
              [-53.0697, 46.6812],
              [-53.6164, 46.6803],
              [-54.1737, 46.8805],
              [-53.8692, 47.387],
              [-54.8566, 47.3848],
              [-56.2211, 47.6716],
              [-56.9525, 47.5743],
              [-58.3332, 47.6769],
              [-59.1169, 47.5708],
              [-59.3624, 47.8655],
              [-58.7105, 48.325],
              [-58.7165, 48.598],
              [-58.0158, 49.5426],
              [-57.4656, 50.4636],
              [-56.7503, 51.2748],
              [-56.0256, 51.5685],
              [-55.4588, 51.5365],
            ],
          ],
          [
            [
              [-80.7318, 52.7472],
              [-81.8391, 52.9579],
              [-81.8473, 53.1862],
              [-81.1357, 53.2058],
              [-80.7318, 52.7472],
            ],
          ],
          [
            [
              [-132.6556, 54.1275],
              [-131.7026, 53.9864],
              [-132.0113, 53.2653],
              [-132.7475, 53.3105],
              [-133.0522, 53.7782],
              [-132.6556, 54.1275],
            ],
          ],
          [
            [
              [-78.9356, 56.2662],
              [-79.1755, 55.8851],
              [-80.0009, 55.9322],
              [-79.5963, 56.2444],
              [-78.9356, 56.2662],
            ],
          ],
          [
            [
              [-79.5452, 62.4117],
              [-79.324, 62.0261],
              [-79.7144, 61.6126],
              [-80.2652, 61.8182],
              [-80.0215, 62.3431],
              [-79.5452, 62.4117],
            ],
          ],
          [
            [
              [-82.0004, 62.9543],
              [-82.1138, 62.6522],
              [-83.0158, 62.2099],
              [-83.699, 62.1602],
              [-83.8994, 62.4765],
              [-83.3765, 62.905],
              [-82.0004, 62.9543],
            ],
          ],
          [
            [
              [-84.9197, 65.261],
              [-83.4071, 65.1039],
              [-82.0501, 64.6442],
              [-81.7161, 64.0219],
              [-80.829, 64.09],
              [-80.3021, 63.7622],
              [-81.0463, 63.4615],
              [-82.3782, 63.7067],
              [-82.4672, 63.9269],
              [-83.4943, 64.0993],
              [-83.7283, 63.8135],
              [-84.6329, 63.3092],
              [-85.4954, 63.1392],
              [-85.7142, 63.6579],
              [-87.1888, 63.6723],
              [-86.252, 64.1368],
              [-86.3743, 64.5659],
              [-85.9617, 65.7042],
              [-85.241, 65.7954],
              [-84.9197, 65.261],
            ],
          ],
          [
            [
              [-75.6758, 68.3225],
              [-75.1538, 68.234],
              [-75.1274, 67.5373],
              [-75.7801, 67.2836],
              [-77.0048, 67.2669],
              [-77.3058, 67.7062],
              [-76.7403, 68.2313],
              [-75.6758, 68.3225],
            ],
          ],
          [
            [
              [-97.4394, 69.6427],
              [-96.2999, 69.3444],
              [-95.6856, 68.7358],
              [-96.4016, 68.4708],
              [-97.472, 68.5438],
              [-99.254, 68.8632],
              [-99.5641, 69.0341],
              [-97.4394, 69.6427],
            ],
          ],
          [
            [
              [-74.7088, 45.0038],
              [-75.1793, 44.8994],
              [-76.4646, 44.0574],
              [-76.82, 43.6286],
              [-78.7205, 43.625],
              [-79.1719, 43.4666],
              [-79.0368, 42.8025],
              [-80.2475, 42.3662],
              [-81.2778, 42.209],
              [-82.439, 41.675],
              [-83.1419, 41.976],
              [-83.0731, 42.3005],
              [-82.5453, 42.6245],
              [-82.1378, 43.5709],
              [-82.5511, 45.3473],
              [-83.5926, 45.817],
              [-83.9777, 46.0851],
              [-84.1921, 46.5494],
              [-84.5617, 46.4575],
              [-84.8759, 46.9001],
              [-88.3782, 48.3032],
              [-89.4556, 47.996],
              [-90.8403, 48.2007],
              [-91.5183, 48.0582],
              [-92.9963, 48.6117],
              [-93.3778, 48.6166],
              [-93.7078, 48.5256],
              [-94.6209, 48.7427],
              [-95.162, 48.9917],
              [-97.5297, 48.9932],
              [-99.2354, 48.993],
              [-102.2201, 48.993],
              [-103.9256, 48.993],
              [-106.4839, 48.993],
              [-109.895, 48.993],
              [-112.4532, 48.993],
              [-115.8643, 48.993],
              [-118.4226, 48.993],
              [-120.1281, 48.993],
              [-122.7888, 48.993],
              [-123.0491, 48.993],
              [-123.3356, 49.4591],
              [-123.859, 49.4829],
              [-124.4833, 49.8082],
              [-125.21, 50.4763],
              [-126.4045, 50.5298],
              [-126.6318, 50.9151],
              [-127.0577, 50.8676],
              [-127.7082, 51.1512],
              [-127.6449, 51.4784],
              [-128.3576, 52.1589],
              [-128.1088, 52.858],
              [-128.6524, 53.2437],
              [-129.2842, 53.3931],
              [-129.5637, 53.2515],
              [-130.3351, 53.724],
              [-130.0433, 54.1335],
              [-130.4304, 54.4209],
              [-129.6666, 55.4366],
              [-130.0251, 55.8882],
              [-130.4772, 56.2307],
              [-131.5752, 56.5989],
              [-131.8661, 56.7929],
              [-133.8208, 58.7049],
              [-134.9439, 59.2882],
              [-135.4759, 59.7934],
              [-136.3219, 59.6049],
              [-136.5787, 59.1522],
              [-137.4385, 58.9031],
              [-137.5932, 59.2263],
              [-139.1852, 60.0837],
              [-141.0021, 60.3003],
              [-141.0021, 63.2222],
              [-141.0021, 65.56],
              [-141.0021, 68.4819],
              [-141.0021, 69.6509],
              [-139.1815, 69.5155],
              [-138.1283, 69.1519],
              [-136.7174, 68.8891],
              [-135.8763, 68.9169],
              [-135.6914, 69.3111],
              [-135.1409, 69.4678],
              [-133.1632, 69.4338],
              [-130.4985, 70.1431],
              [-128.9715, 69.7123],
              [-127.765, 70.2219],
              [-127.1385, 70.2392],
              [-126.6122, 69.7303],
              [-125.9073, 69.4185],
              [-124.7679, 69.99],
              [-123.6091, 69.3774],
              [-123.0258, 69.81],
              [-120.9624, 69.6604],
              [-120.2925, 69.4205],
              [-118.8687, 69.2571],
              [-118.0953, 69.043],
              [-116.0651, 68.8554],
              [-115.6312, 68.9726],
              [-114.4138, 68.6597],
              [-115.1332, 67.8191],
              [-112.4352, 67.6849],
              [-110.9901, 67.7907],
              [-110.0426, 67.9772],
              [-109.6305, 67.7328],
              [-108.5929, 67.591],
              [-107.6263, 67.0031],
              [-108.2611, 68.1498],
              [-108.3134, 68.6108],
              [-106.1646, 68.9198],
              [-105.4286, 68.4584],
              [-104.1935, 68.0311],
              [-103.4741, 68.115],
              [-102.692, 67.8116],
              [-101.555, 67.6931],
              [-100.4561, 67.8396],
              [-99.1468, 67.7237],
              [-98.4171, 67.8265],
              [-98.2184, 68.3174],
              [-97.2659, 68.453],
              [-96.0361, 68.1578],
              [-96.3714, 67.5539],
              [-95.7826, 67.1937],
              [-95.2588, 67.2625],
              [-95.6505, 67.7375],
              [-93.6517, 68.5431],
              [-93.6814, 68.8874],
              [-94.2707, 69.4551],
              [-96.0503, 69.8311],
              [-96.4924, 70.1249],
              [-96.2265, 70.5418],
              [-96.549, 70.8088],
              [-96.4056, 71.2737],
              [-95.2013, 71.9036],
              [-94.3084, 71.7649],
              [-92.9488, 71.2622],
              [-92.9814, 70.8523],
              [-91.5641, 70.1782],
              [-92.751, 69.7139],
              [-90.8221, 69.2904],
              [-90.4683, 68.8638],
              [-89.0567, 69.266],
              [-88.0413, 68.8117],
              [-87.8103, 68.4042],
              [-88.347, 68.2883],
              [-88.1958, 67.7659],
              [-87.2663, 67.1839],
              [-86.5363, 67.5162],
              [-85.9526, 68.0724],
              [-85.7338, 68.6301],
              [-84.8622, 69.074],
              [-85.3869, 69.2318],
              [-85.5074, 69.8453],
              [-82.6183, 69.691],
              [-82.6421, 69.4584],
              [-81.3286, 69.12],
              [-81.2815, 68.6572],
              [-82.5526, 68.4464],
              [-81.9764, 67.862],
              [-81.2702, 67.4598],
              [-81.4675, 67.0699],
              [-82.6416, 66.5876],
              [-84.4784, 66.1794],
              [-85.6039, 66.5683],
              [-86.6331, 66.5314],
              [-86.0429, 66.0225],
              [-87.2914, 65.3549],
              [-87.9701, 65.3489],
              [-89.7494, 65.9359],
              [-89.9241, 65.7804],
              [-88.974, 65.3482],
              [-87.0275, 65.1982],
              [-87.2805, 64.8262],
              [-88.1057, 64.1832],
              [-88.653, 64.0094],
              [-90.1418, 63.9819],
              [-90.0135, 63.8044],
              [-91.3301, 63.5067],
              [-90.7466, 63.3516],
              [-90.8711, 62.9459],
              [-91.4491, 62.8041],
              [-92.3613, 62.8194],
              [-93.4206, 61.7059],
              [-93.9127, 61.4815],
              [-94.6467, 60.4164],
              [-94.7858, 59.9534],
              [-94.7135, 58.9034],
              [-94.2871, 58.716],
              [-93.2782, 58.7564],
              [-92.249, 57.009],
              [-91.1113, 57.2412],
              [-90.5922, 57.2246],
              [-89.7909, 56.9813],
              [-88.9484, 56.8512],
              [-88.0751, 56.4673],
              [-87.5608, 56.0565],
              [-85.8305, 55.6569],
              [-85.061, 55.2856],
              [-83.9107, 55.3147],
              [-82.5775, 55.1487],
              [-82.2195, 54.8136],
              [-82.4181, 54.3559],
              [-82.1413, 53.8177],
              [-82.2605, 52.9612],
              [-81.5994, 52.4325],
              [-81.6613, 52.2938],
              [-80.6579, 51.7582],
              [-80.368, 51.3299],
              [-79.8362, 51.1734],
              [-79.4975, 51.5698],
              [-78.9777, 51.7338],
              [-78.4917, 52.2521],
              [-78.7238, 52.6278],
              [-79.2418, 54.0989],
              [-79.6655, 54.6975],
              [-77.8911, 55.2364],
              [-77.3249, 55.5555],
              [-76.6504, 56.1073],
              [-76.5729, 57.1813],
              [-77.1569, 58.0189],
              [-78.5152, 58.6823],
              [-77.7606, 59.3801],
              [-77.3118, 60.0424],
              [-77.8715, 60.7859],
              [-77.7362, 61.4373],
              [-78.1372, 62.1074],
              [-78.0682, 62.3555],
              [-77.3724, 62.5726],
              [-74.69, 62.1835],
              [-73.6299, 62.4543],
              [-72.0814, 61.7283],
              [-71.4227, 61.159],
              [-70.2792, 61.0687],
              [-69.6405, 60.6898],
              [-69.7559, 60.3886],
              [-69.682, 59.3417],
              [-69.1735, 58.8967],
              [-68.6981, 58.9045],
              [-67.5697, 58.2135],
              [-66.608, 58.5489],
              [-65.6061, 59.1107],
              [-64.9312, 60.2519],
              [-64.4995, 60.2683],
              [-63.1854, 58.8579],
              [-62.5939, 58.4741],
              [-62.3056, 57.9723],
              [-61.899, 57.8613],
              [-61.851, 57.3812],
              [-61.3337, 57.0106],
              [-61.5146, 56.3903],
              [-59.9303, 55.2594],
              [-58.997, 55.1494],
              [-58.7802, 54.8384],
              [-58.1951, 54.8659],
              [-57.4046, 54.5705],
              [-58.1514, 54.3504],
              [-57.4161, 54.1628],
              [-57.1489, 53.8476],
              [-56.4651, 53.7651],
              [-55.7979, 53.212],
              [-55.8336, 52.3104],
              [-55.6951, 52.1378],
              [-56.976, 51.4577],
              [-58.5103, 51.295],
              [-59.1655, 50.7799],
              [-60.0801, 50.2546],
              [-61.2897, 50.202],
              [-62.7155, 50.3016],
              [-64.868, 50.2754],
              [-65.2686, 50.32],
              [-66.4955, 50.2119],
              [-66.9411, 49.9938],
              [-67.3719, 49.3486],
              [-68.2819, 49.1972],
              [-68.9291, 48.829],
              [-69.775, 48.0981],
              [-69.9055, 47.8322],
              [-70.7058, 47.1398],
              [-71.2678, 46.7958],
              [-71.8797, 46.687],
              [-72.981, 46.2098],
              [-73.974, 45.3451],
              [-74.7088, 45.0038],
            ],
          ],
          [
            [
              [-114.5215, 72.593],
              [-113.5001, 72.6944],
              [-112.7537, 72.986],
              [-111.2698, 72.7137],
              [-108.7549, 72.551],
              [-107.72, 73.329],
              [-106.4822, 73.1962],
              [-105.415, 72.7883],
              [-104.3495, 71.434],
              [-104.5697, 71.1039],
              [-103.9535, 70.7626],
              [-100.9732, 70.0295],
              [-100.9351, 69.7152],
              [-102.2343, 69.8422],
              [-102.9197, 69.5648],
              [-101.9756, 69.4069],
              [-101.857, 69.0239],
              [-103.4681, 68.8086],
              [-105.0195, 69.0814],
              [-106.1408, 69.1621],
              [-106.6591, 69.4396],
              [-107.4399, 69.0021],
              [-108.946, 68.7598],
              [-111.3108, 68.542],
              [-113.1277, 68.4941],
              [-113.6941, 69.195],
              [-116.5135, 69.4247],
              [-117.1354, 70.1001],
              [-114.5923, 70.3125],
              [-112.6378, 70.2253],
              [-112.1143, 70.4468],
              [-113.7573, 70.6907],
              [-115.991, 70.5864],
              [-117.587, 70.6296],
              [-118.2642, 70.8882],
              [-117.8141, 71.1585],
              [-118.8683, 71.6868],
              [-118.9447, 71.9855],
              [-118.2136, 72.2629],
              [-118.3745, 72.5339],
              [-117.5517, 72.8311],
              [-114.6382, 73.3727],
              [-114.1091, 72.8611],
              [-114.5215, 72.593],
            ],
          ],
        ],
      },
      properties: {
        name: 'Canada',
        id: 'CA',
      },
      id: 'CA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.86, 10.9195],
            [23.2559, 10.4579],
            [23.6461, 9.8231],
            [23.5374, 8.8159],
            [24.1473, 8.6654],
            [24.2915, 8.2912],
            [24.8535, 8.1376],
            [25.2472, 7.7244],
            [25.3809, 7.3333],
            [25.8891, 7.0647],
            [26.3619, 6.6355],
            [26.5141, 6.0691],
            [27.0836, 5.777],
            [27.4032, 5.1094],
            [27.1151, 5.1977],
            [26.8222, 5.0623],
            [25.5251, 5.3123],
            [25.2495, 5.0246],
            [24.7656, 4.9301],
            [24.32, 4.994],
            [23.4171, 4.6633],
            [22.7557, 4.6469],
            [22.4223, 4.1351],
            [21.5376, 4.2447],
            [20.558, 4.4627],
            [20.2264, 4.8297],
            [19.8065, 5.0894],
            [19.5011, 5.1276],
            [19.0687, 4.8915],
            [18.5676, 4.2576],
            [18.6102, 3.4785],
            [18.161, 3.4998],
            [17.4379, 3.6845],
            [16.6735, 3.5354],
            [16.4684, 2.8318],
            [16.1834, 2.2703],
            [16.0636, 2.9086],
            [15.775, 3.127],
            [15.1287, 3.827],
            [15.0226, 4.3583],
            [14.7088, 4.6655],
            [14.6169, 5.8649],
            [14.7394, 6.2799],
            [15.2459, 7.2636],
            [15.4803, 7.5237],
            [15.8452, 7.4753],
            [16.3787, 7.6835],
            [16.7849, 7.5508],
            [17.6496, 7.9836],
            [18.4553, 8.032],
            [19.1087, 8.6561],
            [19.1455, 9.0161],
            [20.3422, 9.1271],
            [20.7733, 9.4058],
            [21.2638, 9.9745],
            [21.7258, 10.3664],
            [21.7063, 10.5746],
            [22.4938, 10.9963],
            [22.86, 10.9195],
          ],
        ],
      },
      properties: {
        name: 'Central African Republic',
        id: 'CF',
      },
      id: 'CF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [5.6938, 50.7748],
            [5.7972, 50.7546],
            [5.9939, 50.7504],
            [6.3645, 50.3163],
            [6.1164, 50.1209],
            [5.744, 49.9196],
            [5.7897, 49.5383],
            [4.8677, 49.788],
            [4.1744, 50.2466],
            [2.8396, 50.7118],
            [2.5249, 51.0971],
            [3.35, 51.3776],
            [3.9023, 51.2076],
            [4.2263, 51.3865],
            [4.5033, 51.4746],
            [5.827, 51.1257],
            [5.6938, 50.7748],
          ],
        ],
      },
      properties: {
        name: 'Belgium',
        id: 'BE',
      },
      id: 'BE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25.2588, -17.7936],
            [25.2242, -17.9153],
            [25.7613, -18.6495],
            [26.1683, -19.5381],
            [26.6784, -19.8928],
            [27.2217, -20.1458],
            [27.2807, -20.4788],
            [27.6997, -20.5307],
            [27.6696, -21.0642],
            [28.0455, -21.5729],
            [29.0372, -21.8113],
            [29.3648, -22.194],
            [28.8397, -22.4807],
            [28.2102, -22.6938],
            [27.8906, -23.0737],
            [27.1462, -23.5243],
            [26.835, -24.2407],
            [26.3974, -24.6136],
            [25.9122, -24.7477],
            [25.5837, -25.6062],
            [24.7483, -25.8174],
            [23.8938, -25.6008],
            [23.2662, -25.2666],
            [22.8786, -25.4579],
            [22.6403, -26.0714],
            [22.0907, -26.5801],
            [21.6464, -26.8544],
            [20.74, -26.849],
            [20.6268, -26.4438],
            [20.7932, -25.9155],
            [20.3453, -25.03],
            [19.9805, -24.777],
            [19.9774, -22],
            [20.9708, -22],
            [20.9779, -20.9684],
            [20.9739, -18.3188],
            [23.2986, -18.0276],
            [23.5804, -18.4528],
            [24.359, -17.9783],
            [25.2588, -17.7936],
          ],
        ],
      },
      properties: {
        name: 'Botswana',
        id: 'BW',
      },
      id: 'BW',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [91.6317, 27.7599],
            [91.5949, 27.5575],
            [92.0836, 27.2907],
            [91.9984, 26.8548],
            [90.7395, 26.7718],
            [90.3458, 26.8903],
            [89.7638, 26.7016],
            [88.8579, 26.9613],
            [88.8912, 27.316],
            [89.537, 28.1075],
            [89.9809, 28.3112],
            [90.3529, 28.0804],
            [91.6056, 27.9516],
            [91.6317, 27.7599],
          ],
        ],
      },
      properties: {
        name: 'Bhutan',
        id: 'BT',
      },
      id: 'BT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [115.0266, 4.8999],
            [114.7465, 4.7179],
            [114.8402, 4.3934],
            [114.6085, 4.0241],
            [114.0638, 4.5927],
            [114.2995, 4.6074],
            [115.0266, 4.8999],
            [115.1398, 4.8999],
            [115.0266, 4.8999],
          ],
        ],
      },
      properties: {
        name: 'Brunei',
        id: 'BN',
      },
      id: 'BN',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-59.4933, 13.0822],
            [-59.6114, 13.1021],
            [-59.5916, 13.3179],
            [-59.4276, 13.1527],
            [-59.4933, 13.0822],
          ],
        ],
      },
      properties: {
        name: 'Barbados',
        id: 'BB',
      },
      id: 'BB',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-51.8326, -1.4336],
              [-51.5461, -0.6497],
              [-51.1608, -0.6665],
              [-51.2764, -1.0216],
              [-51.8326, -1.4336],
            ],
          ],
          [
            [
              [-49.6287, -0.2293],
              [-49.1169, -0.1636],
              [-48.3798, -0.3527],
              [-48.5396, -0.801],
              [-48.9289, -1.4824],
              [-49.5878, -1.7123],
              [-50.5076, -1.7878],
              [-50.7597, -1.2405],
              [-50.7713, -0.6452],
              [-50.6454, -0.2728],
              [-50.2481, -0.1165],
              [-49.6287, -0.2293],
            ],
          ],
          [
            [
              [-56.4828, 1.9422],
              [-56.0198, 1.8424],
              [-56.1295, 2.2996],
              [-55.0058, 2.593],
              [-54.6163, 2.3266],
              [-54.13, 2.1211],
              [-53.7678, 2.3551],
              [-52.9648, 2.1837],
              [-52.7006, 2.3635],
              [-51.9906, 3.7018],
              [-51.6526, 4.0614],
              [-51.2198, 4.0938],
              [-51.0525, 3.2819],
              [-50.659, 2.1309],
              [-50.459, 1.8295],
              [-49.9572, 1.6599],
              [-49.8988, 1.1628],
              [-50.2945, 0.8356],
              [-50.8163, 0.1724],
              [-51.2829, -0.085],
              [-51.7026, -0.7624],
              [-51.9476, -1.5867],
              [-51.2973, -1.2236],
              [-50.9177, -1.1153],
              [-50.69, -1.7616],
              [-50.4033, -2.0155],
              [-49.9991, -1.8317],
              [-49.7195, -1.9263],
              [-49.3137, -1.7319],
              [-48.9913, -1.83],
              [-48.115, -0.7375],
              [-47.3981, -0.6266],
              [-46.8113, -0.7797],
              [-46.6173, -0.9706],
              [-46.2192, -1.0314],
              [-45.6448, -1.3479],
              [-45.0764, -1.4664],
              [-44.6513, -1.7456],
              [-44.3082, -2.5353],
              [-43.9329, -2.5832],
              [-42.9368, -2.4652],
              [-42.2496, -2.7919],
              [-41.8761, -2.7466],
              [-41.3181, -2.9361],
              [-40.4745, -2.7954],
              [-39.9647, -2.8616],
              [-38.4757, -3.7174],
              [-38.0489, -4.2163],
              [-37.1746, -4.9123],
              [-36.5907, -5.0975],
              [-35.9799, -5.0544],
              [-35.5493, -5.1294],
              [-35.2355, -5.5666],
              [-34.9882, -6.3936],
              [-34.8056, -7.2885],
              [-34.8347, -7.9717],
              [-35.1578, -8.9305],
              [-35.3409, -9.2305],
              [-36.3983, -10.4841],
              [-36.9378, -10.8206],
              [-37.4119, -11.497],
              [-37.6887, -12.0998],
              [-38.2397, -12.8443],
              [-38.788, -12.7826],
              [-38.8352, -13.147],
              [-39.0894, -13.5882],
              [-38.9422, -14.0308],
              [-39.0596, -14.6549],
              [-38.8805, -15.8645],
              [-39.2152, -17.3156],
              [-39.1539, -17.704],
              [-39.4869, -17.9903],
              [-39.7399, -18.6397],
              [-39.6999, -19.278],
              [-40.0013, -19.7419],
              [-40.396, -20.5693],
              [-40.7892, -20.9062],
              [-41.0471, -21.5059],
              [-41.1226, -22.0843],
              [-41.7054, -22.3098],
              [-41.9804, -22.5806],
              [-42.0424, -22.9472],
              [-42.9583, -22.9672],
              [-43.5327, -23.0462],
              [-43.8661, -22.9104],
              [-44.6373, -23.0555],
              [-45.4233, -23.6854],
              [-45.9721, -23.7955],
              [-46.8672, -24.2363],
              [-47.8766, -24.9976],
              [-48.5451, -25.8161],
              [-48.6776, -26.703],
              [-48.5542, -27.1962],
              [-48.6208, -28.0755],
              [-48.7997, -28.5753],
              [-49.2714, -28.871],
              [-49.7461, -29.3633],
              [-50.2994, -30.4259],
              [-51.1572, -30.3642],
              [-51.4637, -31.0527],
              [-51.9724, -31.3839],
              [-52.1935, -31.8854],
              [-52.1274, -32.1678],
              [-52.6523, -33.1377],
              [-53.3707, -33.7422],
              [-53.5314, -33.171],
              [-53.1254, -32.7368],
              [-53.6017, -32.403],
              [-53.7618, -32.0568],
              [-54.2205, -31.8553],
              [-54.5876, -31.4851],
              [-55.2546, -31.2258],
              [-55.5573, -30.876],
              [-55.8736, -31.0696],
              [-56.1761, -30.6283],
              [-56.8328, -30.1072],
              [-57.2144, -30.2834],
              [-57.609, -30.188],
              [-56.9387, -29.595],
              [-55.7255, -28.2043],
              [-55.1014, -27.8669],
              [-54.8291, -27.5508],
              [-54.3271, -27.4234],
              [-53.8381, -27.1212],
              [-53.6712, -26.2249],
              [-53.8912, -25.6687],
              [-54.1546, -25.5231],
              [-54.6158, -25.576],
              [-54.4731, -25.2204],
              [-54.2418, -24.0472],
              [-54.6254, -23.8124],
              [-55.3662, -23.9913],
              [-55.6541, -22.8105],
              [-55.8492, -22.3076],
              [-56.5239, -22.1025],
              [-56.9374, -22.2712],
              [-57.9856, -22.0461],
              [-57.8303, -20.9981],
              [-58.1598, -20.1645],
              [-58.1314, -19.7446],
              [-57.8005, -19.0809],
              [-57.5741, -18.2793],
              [-57.8325, -17.5122],
              [-58.396, -17.2343],
              [-58.538, -16.3284],
              [-60.1756, -16.2693],
              [-60.299, -14.6185],
              [-60.5065, -13.7897],
              [-61.0769, -13.4897],
              [-61.79, -13.5256],
              [-62.176, -13.1337],
              [-62.7654, -12.9974],
              [-63.0675, -12.6694],
              [-63.5858, -12.5189],
              [-64.4205, -12.4399],
              [-64.5135, -12.2508],
              [-64.9925, -11.9751],
              [-65.39, -11.2462],
              [-65.334, -10.8929],
              [-65.4368, -10.449],
              [-65.3132, -10.2533],
              [-65.3962, -9.7126],
              [-66.2635, -9.8262],
              [-66.7301, -9.9754],
              [-67.5824, -10.5058],
              [-68.0717, -10.7029],
              [-68.398, -11.0185],
              [-69.5785, -10.9519],
              [-70.2202, -11.0478],
              [-70.6423, -11.0101],
              [-70.637, -9.4782],
              [-71.238, -9.9661],
              [-72.1815, -10.0038],
              [-72.379, -9.5102],
              [-73.2093, -9.4116],
              [-72.9741, -8.993],
              [-73.5491, -8.3459],
              [-74.0021, -7.5562],
              [-73.7496, -7.3356],
              [-73.758, -6.9059],
              [-73.1374, -6.466],
              [-73.2355, -6.0984],
              [-72.9799, -5.635],
              [-72.8871, -5.1228],
              [-72.6987, -5.0673],
              [-71.8448, -4.5044],
              [-70.9737, -4.3504],
              [-70.7995, -4.1733],
              [-69.9658, -4.2359],
              [-69.6691, -2.6676],
              [-69.4003, -1.1948],
              [-69.6675, -0.4823],
              [-70.0706, -0.1387],
              [-70.0539, 0.5786],
              [-69.1633, 0.864],
              [-69.3615, 1.0642],
              [-69.8522, 1.0593],
              [-69.8486, 1.7087],
              [-68.1765, 1.7198],
              [-67.8152, 1.79],
              [-67.352, 2.0861],
              [-66.8761, 1.2231],
              [-66.4293, 0.8218],
              [-66.06, 0.7854],
              [-65.6814, 0.9834],
              [-65.3609, 0.8685],
              [-65.1037, 1.1082],
              [-64.1149, 1.6191],
              [-64.0354, 1.9045],
              [-63.394, 2.2223],
              [-63.3893, 2.4119],
              [-63.9242, 2.4523],
              [-64.2188, 3.2047],
              [-64.2212, 3.5873],
              [-64.5679, 3.8998],
              [-64.2556, 4.1404],
              [-64.0214, 3.9291],
              [-63.2948, 3.9224],
              [-62.9685, 3.594],
              [-62.7122, 4.0179],
              [-62.1531, 4.0982],
              [-61.0028, 4.5354],
              [-60.6046, 4.9944],
              [-60.7422, 5.2022],
              [-60.142, 5.239],
              [-59.9907, 5.0828],
              [-60.1112, 4.511],
              [-59.8333, 4.476],
              [-59.5512, 3.9335],
              [-59.8544, 3.5873],
              [-59.9942, 2.6902],
              [-59.7552, 2.2743],
              [-59.7563, 1.9005],
              [-59.2312, 1.3758],
              [-58.8625, 1.2036],
              [-58.5119, 1.2848],
              [-58.3407, 1.5876],
              [-57.5943, 1.7043],
              [-57.1189, 2.0141],
              [-56.8368, 1.8814],
              [-56.4828, 1.9422],
            ],
          ],
        ],
      },
      properties: {
        name: 'Brazil',
        id: 'BR',
      },
      id: 'BR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.1598, -20.1645],
            [-58.1803, -19.8178],
            [-59.0905, -19.286],
            [-60.0073, -19.2976],
            [-61.7569, -19.6451],
            [-61.917, -20.0553],
            [-62.2763, -20.5627],
            [-62.2767, -21.066],
            [-62.6509, -22.2339],
            [-62.8434, -21.9973],
            [-63.8186, -22.0053],
            [-63.9762, -22.0728],
            [-64.3739, -22.7612],
            [-64.6056, -22.229],
            [-65.0579, -22.103],
            [-65.771, -22.0994],
            [-66.0986, -21.8353],
            [-66.3652, -22.1136],
            [-66.7117, -22.2161],
            [-67.1948, -22.8216],
            [-67.8795, -22.8229],
            [-67.9883, -22.0572],
            [-68.1969, -21.3004],
            [-68.4999, -20.6119],
            [-68.7451, -20.4588],
            [-68.6983, -19.721],
            [-68.4628, -19.4329],
            [-68.9684, -18.9682],
            [-69.094, -18.0507],
            [-69.3133, -17.9432],
            [-69.5108, -17.506],
            [-69.6249, -17.2002],
            [-69.0208, -16.6422],
            [-68.9134, -16.2618],
            [-69.2176, -16.149],
            [-69.4185, -15.6035],
            [-69.1726, -15.2368],
            [-69.3748, -14.963],
            [-69.235, -14.5972],
            [-68.8803, -14.199],
            [-69.0741, -13.6828],
            [-68.9786, -12.8802],
            [-68.6852, -12.502],
            [-69.5785, -10.9519],
            [-68.398, -11.0185],
            [-68.0717, -10.7029],
            [-67.5824, -10.5058],
            [-66.7301, -9.9754],
            [-66.2635, -9.8262],
            [-65.3962, -9.7126],
            [-65.3132, -10.2533],
            [-65.4368, -10.449],
            [-65.334, -10.8929],
            [-65.39, -11.2462],
            [-64.9925, -11.9751],
            [-64.5135, -12.2508],
            [-64.4205, -12.4399],
            [-63.5858, -12.5189],
            [-63.0675, -12.6694],
            [-62.7654, -12.9974],
            [-62.176, -13.1337],
            [-61.79, -13.5256],
            [-61.0769, -13.4897],
            [-60.5065, -13.7897],
            [-60.299, -14.6185],
            [-60.1756, -16.2693],
            [-58.538, -16.3284],
            [-58.396, -17.2343],
            [-57.8325, -17.5122],
            [-57.5741, -18.2793],
            [-57.8005, -19.0809],
            [-58.1314, -19.7446],
            [-58.1598, -20.1645],
          ],
        ],
      },
      properties: {
        name: 'Bolivia',
        id: 'BO',
      },
      id: 'BO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-64.7303, 32.2934],
            [-64.8202, 32.2596],
            [-64.6946, 32.387],
            [-64.7303, 32.2934],
          ],
        ],
      },
      properties: {
        name: 'Bermuda',
        id: 'BM',
      },
      id: 'BM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.8941, 15.8907],
            [-89.2374, 15.8942],
            [-89.1615, 17.8149],
            [-88.8064, 17.9654],
            [-88.523, 18.4457],
            [-88.2957, 18.4723],
            [-88.0973, 18.1217],
            [-88.2717, 17.6098],
            [-88.3134, 16.6328],
            [-88.8941, 15.8907],
          ],
        ],
      },
      properties: {
        name: 'Belize',
        id: 'BZ',
      },
      id: 'BZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.1481, 56.143],
            [28.7948, 55.9426],
            [29.375, 55.9386],
            [29.4824, 55.6845],
            [30.2335, 55.8452],
            [30.9069, 55.5699],
            [31.1213, 54.6484],
            [31.4036, 54.1959],
            [31.792, 54.0558],
            [32.7042, 53.3363],
            [32.1422, 53.0913],
            [31.4178, 53.196],
            [31.5771, 52.3122],
            [31.7636, 52.1012],
            [30.9806, 52.0461],
            [30.5331, 51.5964],
            [29.3466, 51.3825],
            [29.1357, 51.6173],
            [28.5991, 51.5427],
            [27.2701, 51.6135],
            [27.1422, 51.752],
            [25.9251, 51.9136],
            [24.3617, 51.8674],
            [23.6053, 51.5179],
            [23.6523, 52.0403],
            [23.1969, 52.257],
            [23.8445, 52.6642],
            [23.8872, 53.0276],
            [23.4845, 53.9398],
            [24.7683, 53.9746],
            [25.5473, 54.3317],
            [25.8594, 54.9192],
            [26.175, 55.0033],
            [26.5936, 55.6676],
            [27.5768, 55.7988],
            [28.1481, 56.143],
          ],
        ],
      },
      properties: {
        name: 'Belarus',
        id: 'BY',
      },
      id: 'BY',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-62.832, 17.8766],
            [-62.8655, 17.9184],
            [-62.7996, 17.9086],
            [-62.832, 17.8766],
          ],
        ],
      },
      properties: {
        name: 'Saint Barthelemy',
        id: 'BL',
      },
      id: 'BL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-77.7439, 24.7072],
              [-78.0449, 24.2873],
              [-78.2988, 24.7538],
              [-78.0333, 25.1431],
              [-77.7439, 24.7072],
            ],
          ],
          [
            [
              [-77.2257, 25.904],
              [-77.4032, 26.0247],
              [-77.2468, 26.1565],
              [-77.2062, 26.489],
              [-77.0384, 26.3332],
              [-77.1673, 26.2404],
              [-77.2257, 25.904],
            ],
          ],
        ],
      },
      properties: {
        name: 'Bahamas',
        id: 'BS',
      },
      id: 'BS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [50.6072, 25.8831],
            [50.4661, 25.9657],
            [50.4523, 26.1907],
            [50.6099, 26.1246],
            [50.6072, 25.8831],
          ],
        ],
      },
      properties: {
        name: 'Bahrain',
        id: 'BH',
      },
      id: 'BH',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.007, 44.8693],
            [19.3568, 44.8586],
            [19.1517, 44.3024],
            [19.4882, 43.7036],
            [19.1943, 43.5331],
            [18.9404, 43.4967],
            [18.4437, 42.9685],
            [18.4362, 42.5597],
            [17.6678, 42.897],
            [17.5852, 42.9383],
            [17.6247, 43.0426],
            [16.214, 44.215],
            [15.7369, 44.7658],
            [15.7879, 45.1791],
            [16.2935, 45.0086],
            [16.5305, 45.2168],
            [17.8129, 45.0779],
            [18.6625, 45.0774],
            [19.007, 44.8693],
          ],
        ],
      },
      properties: {
        name: 'Bosnia and Herzegovina',
        id: 'BA',
      },
      id: 'BA',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.5853, 43.7422],
            [28.4655, 43.3893],
            [27.9288, 43.186],
            [27.8888, 42.7497],
            [27.4849, 42.4683],
            [28.0145, 41.9689],
            [27.2949, 42.0794],
            [26.6153, 41.9649],
            [26.321, 41.7167],
            [26.1355, 41.3856],
            [25.2512, 41.2436],
            [24.0558, 41.5272],
            [23.635, 41.3869],
            [22.9159, 41.3363],
            [23.0056, 41.7172],
            [22.8369, 41.9937],
            [22.3442, 42.3138],
            [22.4667, 42.8425],
            [22.9767, 43.1878],
            [22.5546, 43.4546],
            [22.421, 44.0077],
            [22.7051, 44.2376],
            [23.2244, 43.8741],
            [25.4972, 43.6708],
            [26.2158, 44.0072],
            [27.0867, 44.1675],
            [28.5853, 43.7422],
          ],
        ],
      },
      properties: {
        name: 'Bulgaria',
        id: 'BG',
      },
      id: 'BG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.575, 21.9782],
            [92.5937, 21.4673],
            [92.1795, 21.2933],
            [92.3242, 20.7917],
            [92.0561, 21.1747],
            [92.0082, 21.6848],
            [91.693, 22.5046],
            [91.4094, 22.7972],
            [90.9455, 22.597],
            [90.6334, 23.0941],
            [90.435, 22.7519],
            [90.5895, 22.2583],
            [90.1589, 21.8171],
            [89.8118, 21.9835],
            [89.2343, 21.7225],
            [89.0514, 22.0932],
            [88.7407, 23.4368],
            [88.5671, 23.6743],
            [88.7336, 24.2309],
            [88.1454, 24.4857],
            [88.4561, 25.1884],
            [88.4521, 25.5746],
            [88.0846, 25.8884],
            [88.4402, 26.3696],
            [89.1082, 26.2023],
            [89.2893, 26.0376],
            [89.8229, 25.9413],
            [89.814, 25.3052],
            [90.4394, 25.1578],
            [92.0499, 25.1693],
            [92.2514, 24.895],
            [91.8768, 24.1954],
            [91.3672, 24.0933],
            [91.1603, 23.6605],
            [91.3152, 23.1043],
            [91.6198, 22.9796],
            [91.938, 23.5047],
            [92.2461, 23.6836],
            [92.3615, 22.929],
            [92.4916, 22.6853],
            [92.575, 21.9782],
          ],
        ],
      },
      properties: {
        name: 'Bangladesh',
        id: 'BD',
      },
      id: 'BD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [0.2175, 14.9114],
            [0.1638, 14.4973],
            [0.6183, 13.7036],
            [0.9779, 13.5518],
            [0.9872, 13.0418],
            [1.5652, 12.6356],
            [2.0739, 12.7142],
            [2.0912, 12.2778],
            [2.389, 11.897],
            [1.9802, 11.4184],
            [1.4267, 11.4473],
            [0.9007, 10.9932],
            [-0.0686, 11.1157],
            [-0.7014, 10.9888],
            [-2.8298, 10.9985],
            [-2.9148, 10.5924],
            [-2.7865, 10.4019],
            [-2.6958, 9.4813],
            [-3.2235, 9.8954],
            [-3.8776, 9.895],
            [-4.6258, 9.7134],
            [-4.9698, 9.9301],
            [-5.0999, 10.2417],
            [-5.5236, 10.4259],
            [-5.4905, 11.0425],
            [-5.2999, 11.2058],
            [-5.2881, 11.8277],
            [-4.798, 12.0319],
            [-4.4287, 12.3377],
            [-4.4807, 12.6724],
            [-4.2272, 12.7936],
            [-4.3286, 13.119],
            [-4.0512, 13.3822],
            [-3.5758, 13.194],
            [-3.3017, 13.2806],
            [-3.1985, 13.673],
            [-2.4572, 14.274],
            [-2.1132, 14.1684],
            [-1.7678, 14.4862],
            [-1.0496, 14.8195],
            [-0.7604, 15.0477],
            [-0.2359, 15.0592],
            [0.2175, 14.9114],
          ],
        ],
      },
      properties: {
        name: 'Burkina Faso',
        id: 'BF',
      },
      id: 'BF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [3.5955, 11.6963],
            [3.4877, 11.3954],
            [3.7163, 11.0797],
            [3.8343, 10.6074],
            [3.5764, 10.2683],
            [3.5573, 9.9074],
            [3.3252, 9.7783],
            [3.0451, 9.084],
            [2.7748, 9.0485],
            [2.686, 7.874],
            [2.7748, 6.7119],
            [2.7064, 6.3692],
            [1.6224, 6.2169],
            [1.6002, 9.0498],
            [1.4244, 9.2851],
            [1.3299, 9.9971],
            [0.7799, 10.3598],
            [0.9007, 10.9932],
            [1.4267, 11.4473],
            [1.9802, 11.4184],
            [2.389, 11.897],
            [2.3659, 12.2219],
            [2.8782, 12.3679],
            [3.5955, 11.6963],
          ],
        ],
      },
      properties: {
        name: 'Benin',
        id: 'BJ',
      },
      id: 'BJ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.5535, -2.3999],
            [30.5149, -2.9175],
            [30.7901, -3.2744],
            [30.4252, -3.5891],
            [30.1469, -4.0854],
            [29.7177, -4.456],
            [29.4034, -4.4494],
            [29.2232, -3.9109],
            [29.2245, -3.0533],
            [29.0146, -2.7204],
            [29.3901, -2.8087],
            [29.8682, -2.7164],
            [29.9303, -2.3396],
            [30.5535, -2.3999],
          ],
        ],
      },
      properties: {
        name: 'Burundi',
        id: 'BI',
      },
      id: 'BI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [48.5729, 41.8446],
              [49.1064, 41.3017],
              [49.2263, 41.026],
              [49.7758, 40.5839],
              [49.3244, 39.6083],
              [49.363, 39.3495],
              [49.0137, 39.1342],
              [48.8685, 38.4355],
              [48.5929, 38.4111],
              [48.0233, 38.819],
              [48.2422, 38.9788],
              [47.9958, 39.6842],
              [47.773, 39.6486],
              [46.4906, 38.9065],
              [46.5501, 39.2012],
              [45.7897, 39.8812],
              [45.9646, 40.2337],
              [45.376, 40.6381],
              [45.5877, 40.8467],
              [45.0013, 41.291],
              [45.281, 41.4495],
              [46.4577, 41.07],
              [46.6189, 41.3439],
              [46.1821, 41.6573],
              [46.4298, 41.8907],
              [46.7494, 41.8126],
              [47.2612, 41.315],
              [47.8609, 41.2129],
              [48.5729, 41.8446],
            ],
          ],
          [
            [
              [46.1146, 38.8776],
              [45.4798, 39.0063],
              [44.8171, 39.6504],
              [44.7683, 39.7037],
              [45.0315, 39.7649],
              [45.7662, 39.3783],
              [46.1146, 38.8776],
            ],
          ],
        ],
      },
      properties: {
        name: 'Azerbaijan',
        id: 'AZ',
      },
      id: 'AZ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.9531, 48.5988],
            [17.1475, 48.0058],
            [16.4342, 47.3675],
            [16.4533, 47.0066],
            [16.0929, 46.8632],
            [15.9575, 46.6777],
            [15.0009, 46.6262],
            [14.5037, 46.4171],
            [13.6998, 46.5201],
            [12.3881, 46.7025],
            [12.1542, 46.9351],
            [11.2442, 46.9755],
            [11.0249, 46.7971],
            [10.4528, 46.865],
            [9.5801, 47.0572],
            [9.5277, 47.2707],
            [9.5241, 47.5242],
            [10.3129, 47.3133],
            [10.4306, 47.541],
            [11.2979, 47.4247],
            [11.717, 47.5837],
            [12.686, 47.6693],
            [12.8143, 48.1607],
            [13.4095, 48.3942],
            [13.8148, 48.7669],
            [14.0492, 48.6024],
            [14.6915, 48.5993],
            [14.972, 48.9839],
            [16.0573, 48.7549],
            [16.4777, 48.8001],
            [16.9531, 48.5988],
          ],
        ],
      },
      properties: {
        name: 'Austria',
        id: 'AT',
      },
      id: 'AT',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [145.0431, -40.7868],
              [145.2832, -40.7699],
              [146.3175, -41.1637],
              [146.99, -40.9923],
              [147.4547, -41.0017],
              [147.9687, -40.7797],
              [148.2928, -40.9471],
              [148.3017, -42.0399],
              [147.9244, -42.5726],
              [147.9807, -43.1572],
              [147.4081, -42.894],
              [146.8741, -43.6126],
              [146.0431, -43.5474],
              [145.2681, -42.5442],
              [145.2384, -42.0195],
              [144.778, -41.4189],
              [144.6462, -40.9808],
              [145.0431, -40.7868],
            ],
          ],
          [
            [
              [137.5963, -35.7384],
              [137.4485, -36.0749],
              [136.7551, -36.0332],
              [136.6388, -35.7491],
              [137.334, -35.5924],
              [137.5963, -35.7384],
            ],
          ],
          [
            [
              [136.7147, -13.8039],
              [136.8945, -14.2931],
              [136.3632, -14.2288],
              [136.4249, -13.8648],
              [136.7147, -13.8039],
            ],
          ],
          [
            [
              [130.6188, -11.3763],
              [131.2682, -11.1899],
              [131.5385, -11.4367],
              [130.9508, -11.9263],
              [130.645, -11.7425],
              [130.6188, -11.3763],
            ],
          ],
          [
            [
              [143.1787, -11.9547],
              [143.1103, -12.3036],
              [143.4015, -12.6401],
              [143.5866, -13.4435],
              [143.5485, -13.7409],
              [143.7562, -14.349],
              [143.9617, -14.4627],
              [144.4731, -14.2319],
              [144.648, -14.4924],
              [145.2876, -14.943],
              [145.2717, -15.4765],
              [145.4581, -16.0563],
              [145.4261, -16.406],
              [145.902, -17.0701],
              [146.1257, -17.6352],
              [146.0325, -18.2731],
              [146.3334, -18.5536],
              [146.4813, -19.0787],
              [147.1387, -19.393],
              [147.4183, -19.3783],
              [147.9155, -19.8693],
              [148.7593, -20.2897],
              [148.6839, -20.5804],
              [149.205, -21.1251],
              [149.7039, -22.4408],
              [150.0763, -22.1647],
              [150.5415, -22.5593],
              [150.7639, -22.5761],
              [150.8429, -23.4582],
              [151.1537, -23.784],
              [151.9025, -24.2008],
              [152.1298, -24.5976],
              [152.4565, -24.8023],
              [152.9137, -25.4321],
              [153.1649, -25.9644],
              [153.0842, -26.3039],
              [153.117, -27.1944],
              [153.5755, -28.2407],
              [153.6044, -28.8546],
              [153.3483, -29.2905],
              [153.2724, -29.8924],
              [153.0304, -30.5635],
              [152.9439, -31.4349],
              [152.5595, -32.0457],
              [152.4703, -32.439],
              [151.8128, -32.9011],
              [151.4839, -33.3476],
              [151.2313, -34.0294],
              [150.9601, -34.2971],
              [150.6902, -35.1778],
              [150.1953, -35.8334],
              [149.9605, -36.8455],
              [149.9325, -37.5286],
              [149.2986, -37.8021],
              [148.2626, -37.8309],
              [147.3957, -38.2189],
              [146.8568, -38.6632],
              [145.9353, -38.9016],
              [145.791, -38.6668],
              [144.6653, -38.21],
              [143.5391, -38.8208],
              [142.6123, -38.4515],
              [141.725, -38.2713],
              [141.4244, -38.3636],
              [141.0112, -38.0769],
              [140.6272, -38.0285],
              [139.7843, -37.2459],
              [139.8575, -36.6622],
              [139.5486, -36.0967],
              [139.0376, -35.6892],
              [138.1845, -35.6128],
              [138.5112, -35.0242],
              [138.4899, -34.7636],
              [138.0895, -34.1697],
              [137.6918, -35.1432],
              [136.8834, -35.2399],
              [137.0144, -34.9159],
              [137.3908, -34.9132],
              [137.4938, -34.1613],
              [137.9319, -33.5789],
              [137.8524, -33.2007],
              [137.4423, -33.1936],
              [137.2372, -33.6295],
              [136.4307, -34.0299],
              [135.6476, -34.9394],
              [135.1855, -33.9069],
              [134.7909, -33.3285],
              [134.3013, -33.1652],
              [134.1002, -32.7488],
              [134.2343, -32.5486],
              [133.5511, -32.1829],
              [133.212, -32.1837],
              [132.7575, -31.956],
              [132.2146, -32.0071],
              [131.7214, -31.6964],
              [131.1435, -31.4957],
              [130.783, -31.604],
              [130.1296, -31.5792],
              [128.9462, -31.7026],
              [128.0677, -32.0666],
              [127.3198, -32.2641],
              [125.9171, -32.2969],
              [124.759, -32.8829],
              [124.126, -33.1292],
              [123.6506, -33.8364],
              [123.2076, -33.9882],
              [122.7774, -33.891],
              [122.1511, -33.9917],
              [121.4054, -33.8266],
              [119.854, -33.9748],
              [119.4505, -34.3681],
              [118.8951, -34.48],
              [118.1356, -34.9865],
              [117.5817, -35.0979],
              [116.5172, -34.9878],
              [115.9868, -34.7952],
              [115.7262, -34.5262],
              [115.0089, -34.2558],
              [115.1815, -33.6433],
              [115.5154, -33.5314],
              [115.6831, -33.1927],
              [115.6982, -31.6946],
              [115.1767, -30.8081],
              [114.9947, -30.2164],
              [114.9592, -29.4334],
              [114.5375, -28.5429],
              [114.165, -28.0808],
              [114.0283, -27.3471],
              [113.5818, -26.5583],
              [113.853, -26.3319],
              [113.5889, -26.0984],
              [113.5396, -25.6252],
              [114.2156, -26.2893],
              [114.2143, -25.8516],
              [113.671, -24.9772],
              [113.4175, -24.4356],
              [113.5529, -23.7329],
              [113.7571, -23.4182],
              [113.683, -22.6378],
              [113.9586, -21.9392],
              [114.1637, -22.3231],
              [114.3777, -22.3413],
              [114.8588, -21.7359],
              [115.4563, -21.4917],
              [116.0107, -21.0305],
              [116.7068, -20.6536],
              [117.4063, -20.7211],
              [118.1991, -20.3753],
              [118.7513, -20.2617],
              [119.1047, -19.9954],
              [119.5858, -20.0384],
              [120.8785, -19.6651],
              [121.4937, -19.1067],
              [121.8337, -18.4772],
              [122.2372, -17.9685],
              [122.1604, -17.3138],
              [122.7202, -16.7878],
              [122.9705, -16.4367],
              [123.5254, -17.4856],
              [123.778, -16.8677],
              [123.4903, -16.4908],
              [124.577, -16.1135],
              [124.3817, -15.758],
              [124.8389, -15.1609],
              [125.2432, -14.9448],
              [125.1789, -14.7148],
              [125.6814, -14.3881],
              [126.0209, -14.4946],
              [126.1115, -14.1142],
              [126.5696, -14.1608],
              [126.9034, -13.744],
              [127.6727, -14.195],
              [128.1996, -14.7517],
              [129.4589, -14.9332],
              [129.7536, -14.7894],
              [129.3785, -14.3926],
              [129.7097, -13.9802],
              [129.8389, -13.5731],
              [130.2597, -13.3024],
              [130.1682, -12.9574],
              [130.3999, -12.688],
              [130.898, -12.5238],
              [131.0458, -12.1895],
              [131.4382, -12.277],
              [132.3722, -12.2392],
              [132.5839, -12.1101],
              [132.6829, -11.5055],
              [133.0247, -11.4527],
              [133.1854, -11.7057],
              [133.904, -11.8322],
              [134.2369, -12.008],
              [134.7301, -11.9844],
              [135.2179, -12.2215],
              [135.7883, -11.9072],
              [136.0818, -12.4226],
              [136.5403, -11.9578],
              [136.9473, -12.3498],
              [136.5372, -12.7843],
              [136.5945, -13.0036],
              [135.9273, -13.3041],
              [135.9894, -13.8102],
              [135.8833, -14.1533],
              [135.4053, -14.7583],
              [135.5309, -15.0002],
              [136.2052, -15.4033],
              [136.7844, -15.8942],
              [137.0019, -15.8783],
              [137.7037, -16.2329],
              [138.2448, -16.7181],
              [139.0097, -16.8992],
              [139.2485, -17.3285],
              [140.0359, -17.7027],
              [140.5114, -17.6245],
              [140.8305, -17.4146],
              [141.2189, -16.6462],
              [141.412, -16.0696],
              [141.6255, -15.0566],
              [141.523, -14.4702],
              [141.5945, -14.1528],
              [141.4724, -13.7977],
              [141.6455, -13.2593],
              [141.6779, -12.4914],
              [141.9513, -11.8961],
              [142.1684, -10.9466],
              [142.4565, -10.7074],
              [142.8369, -11.3071],
              [142.8724, -11.8215],
              [143.1787, -11.9547],
            ],
          ],
        ],
      },
      properties: {
        name: 'Australia',
        id: 'AU',
      },
      id: 'AU',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [69.185, -49.1093],
            [69.9157, -49.3486],
            [69.6129, -49.6509],
            [68.8721, -49.4445],
            [69.185, -49.1093],
          ],
        ],
      },
      properties: {
        name: 'French Southern and Antarctic Lands',
        id: 'TF',
      },
      id: 'TF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-59.7339, -80.3442],
              [-60.5828, -80.9484],
              [-64.2683, -80.7486],
              [-65.9801, -80.3846],
              [-62.5189, -80.3736],
              [-61.3432, -79.8866],
              [-59.7064, -79.8755],
              [-59.7339, -80.3442],
            ],
          ],
          [
            [
              [-159.053, -79.8076],
              [-161.8655, -79.7037],
              [-164.2259, -79.3206],
              [-163.2562, -78.7223],
              [-161.6431, -78.9012],
              [-159.6842, -79.4023],
              [-159.053, -79.8076],
            ],
          ],
          [
            [
              [-70.334, -79.6797],
              [-71.7836, -79.4445],
              [-70.544, -78.8838],
              [-67.4785, -78.3627],
              [-66.7281, -78.3836],
              [-69.2509, -79.2101],
              [-70.334, -79.6797],
            ],
          ],
          [
            [
              [-45.2226, -78.8106],
              [-43.722, -78.8186],
              [-42.9654, -79.4769],
              [-43.5278, -80.1916],
              [-49.1875, -80.643],
              [-49.773, -80.7842],
              [-54.1626, -80.8703],
              [-53.4823, -80.1889],
              [-52.2971, -80.1414],
              [-50.3391, -79.4796],
              [-50.7331, -79.2829],
              [-50.2197, -78.6051],
              [-49.0811, -78.0476],
              [-46.8257, -77.7852],
              [-45.5302, -77.8815],
              [-43.8086, -78.2864],
              [-43.9473, -78.5975],
              [-45.2226, -78.8106],
            ],
          ],
          [
            [
              [-120.5562, -73.756],
              [-120.2726, -73.9891],
              [-121.0625, -74.3375],
              [-122.8591, -74.3428],
              [-122.6247, -73.9655],
              [-123.1121, -73.6823],
              [-120.5562, -73.756],
            ],
          ],
          [
            [
              [-126.3299, -73.2864],
              [-124.9933, -73.8297],
              [-124.8731, -74.2083],
              [-127.4143, -73.5163],
              [-126.3299, -73.2864],
            ],
          ],
          [
            [
              [-74.3544, -73.0986],
              [-74.5748, -73.6113],
              [-75.9009, -73.3325],
              [-75.3769, -72.8203],
              [-74.3544, -73.0986],
            ],
          ],
          [
            [
              [-91.1608, -73.1821],
              [-91.6699, -72.6236],
              [-90.8072, -72.6108],
              [-91.1608, -73.1821],
            ],
          ],
          [
            [
              [-98.0911, -71.9125],
              [-96.8695, -71.8508],
              [-95.6095, -72.0683],
              [-96.0518, -72.5775],
              [-98.4078, -72.5477],
              [-100.1951, -72.2725],
              [-102.2647, -72.1354],
              [-102.128, -71.9853],
              [-98.6155, -71.7638],
              [-98.0911, -71.9125],
            ],
          ],
          [
            [
              [-73.7067, -70.635],
              [-74.205, -70.924],
              [-76.1763, -71.1326],
              [-76.5004, -70.9413],
              [-75.0076, -70.6088],
              [-73.7067, -70.635],
            ],
          ],
          [
            [
              [-74.9871, -69.7277],
              [-74.8489, -70.1791],
              [-75.7267, -70.0961],
              [-74.9871, -69.7277],
            ],
          ],
          [
            [
              [-70.0511, -69.1892],
              [-69.417, -69.5834],
              [-68.3361, -70.8561],
              [-68.2411, -71.822],
              [-69.2092, -72.5344],
              [-72.3675, -72.6698],
              [-73.0864, -72.4079],
              [-71.1066, -72.047],
              [-72.4121, -71.6622],
              [-73.8994, -72.1522],
              [-75.3531, -71.8783],
              [-75.2925, -71.6151],
              [-74.1872, -71.383],
              [-72.6216, -71.3883],
              [-71.1941, -70.9848],
              [-71.0611, -70.5369],
              [-70.328, -70.3611],
              [-71.8537, -69.9692],
              [-71.869, -68.9411],
              [-70.417, -68.7889],
              [-70.0511, -69.1892],
            ],
          ],
          [
            [
              [-67.9885, -67.4745],
              [-68.5804, -67.7328],
              [-69.0825, -67.403],
              [-68.3359, -66.802],
              [-67.6878, -67.1473],
              [-67.9885, -67.4745],
            ],
          ],
          [
            [
              [-57.0206, -63.3729],
              [-58.2631, -63.7636],
              [-58.8954, -64.389],
              [-59.9632, -64.4312],
              [-61.0598, -64.9812],
              [-61.7361, -65.0335],
              [-62.1453, -65.3318],
              [-62.3051, -65.8405],
              [-61.8754, -66.296],
              [-63.7525, -66.2778],
              [-64.0778, -66.6542],
              [-64.6862, -66.8064],
              [-64.8193, -67.3076],
              [-65.4432, -67.3262],
              [-65.6394, -68.1306],
              [-64.8984, -68.6734],
              [-63.3436, -68.8106],
              [-63.4559, -69.0419],
              [-62.4505, -69.5843],
              [-61.9611, -70.1201],
              [-61.9611, -70.9005],
              [-61.0172, -71.1668],
              [-61.2136, -71.5641],
              [-60.7193, -72.0728],
              [-60.3846, -73.0072],
              [-61.4054, -73.467],
              [-60.8789, -73.6122],
              [-61.0107, -74.4782],
              [-62.2257, -74.5057],
              [-63.9247, -75.0047],
              [-63.3634, -75.4517],
              [-66.3705, -76.0132],
              [-69.3044, -76.3506],
              [-70.0954, -76.6546],
              [-71.7987, -76.7527],
              [-75.2683, -76.5814],
              [-77.19, -76.6298],
              [-76.2487, -77.2747],
              [-75.3869, -77.474],
              [-72.852, -77.5903],
              [-73.2515, -77.8944],
              [-74.812, -78.1776],
              [-80.6015, -77.7519],
              [-81.4411, -77.8855],
              [-77.4325, -78.4346],
              [-77.8691, -78.7454],
              [-80.2923, -78.8226],
              [-83.246, -78.3569],
              [-83.26, -78.7742],
              [-80.1512, -79.2683],
              [-76.499, -79.3255],
              [-76.5578, -79.9035],
              [-75.2366, -80.8028],
              [-73.0296, -80.9173],
              [-70.239, -80.8565],
              [-64.7501, -81.5219],
              [-64.1371, -81.8695],
              [-65.9133, -82.1833],
              [-62.7357, -82.5273],
              [-61.5898, -83.3414],
              [-59.5159, -83.4586],
              [-57.3535, -82.8403],
              [-53.9862, -82.2006],
              [-48.3607, -81.8921],
              [-43.6692, -82.2699],
              [-41.4338, -81.2977],
              [-38.7718, -80.8823],
              [-37.2093, -81.0638],
              [-35.3269, -80.6505],
              [-29.3291, -80.172],
              [-24.2403, -80.0619],
              [-25.2586, -79.7623],
              [-29.9494, -79.599],
              [-30.3161, -79.1631],
              [-32.9943, -79.2288],
              [-36.2391, -78.7742],
              [-36.181, -78.4684],
              [-34.0758, -77.4252],
              [-32.0634, -77.1598],
              [-28.9336, -76.3701],
              [-26.0594, -75.9573],
              [-24.2696, -75.7668],
              [-19.4931, -75.54],
              [-17.2989, -74.334],
              [-15.6725, -74.4072],
              [-15.7488, -73.9456],
              [-16.5188, -73.6441],
              [-15.5959, -73.0968],
              [-14.3211, -73.123],
              [-14.2978, -72.7328],
              [-13.2087, -72.7852],
              [-11.497, -72.4128],
              [-10.8254, -71.5534],
              [-9.8879, -71.0274],
              [-9.2307, -71.1739],
              [-8.6466, -71.6728],
              [-7.7137, -71.5463],
              [-7.8735, -70.9404],
              [-5.9364, -70.7127],
              [-5.5879, -70.8569],
              [-5.9499, -71.3417],
              [-2.8121, -71.3208],
              [-0.8958, -71.3492],
              [-0.5431, -71.7128],
              [0.835, -71.2023],
              [2.6096, -70.9],
              [5.1132, -70.6559],
              [8.5232, -70.4739],
              [9.1415, -70.1836],
              [10.2175, -70.5081],
              [11.7014, -70.7664],
              [12.9292, -70.2133],
              [13.8228, -70.3434],
              [15.807, -70.3238],
              [18.1246, -70.5405],
              [19.1966, -70.2932],
              [19.2649, -70.9022],
              [21.0707, -70.8436],
              [21.705, -70.2586],
              [22.979, -70.8103],
              [23.8037, -70.4046],
              [24.7567, -70.892],
              [25.9743, -71.0372],
              [27.207, -70.9111],
              [30.0035, -70.2999],
              [31.3787, -70.2257],
              [32.6212, -70.0007],
              [32.9892, -69.6243],
              [32.5675, -69.0743],
              [33.1214, -68.689],
              [34.193, -68.7023],
              [35.2246, -69.6371],
              [37.5599, -69.7184],
              [38.8592, -70.006],
              [39.7053, -69.4258],
              [39.8637, -68.9669],
              [41.1328, -68.5749],
              [42.409, -68.3521],
              [42.8196, -68.123],
              [44.6999, -67.9042],
              [44.9893, -67.7692],
              [47.1174, -67.5726],
              [48.6479, -67.7941],
              [48.7136, -67.217],
              [49.2472, -66.9414],
              [50.6059, -67.15],
              [50.3325, -66.4447],
              [51.8843, -66.0199],
              [53.6719, -65.8587],
              [54.9481, -65.9164],
              [55.7102, -66.0798],
              [57.1853, -66.6133],
              [56.4533, -66.7798],
              [56.8919, -67.0563],
              [57.6274, -67.0142],
              [58.7376, -67.2295],
              [59.2507, -67.4851],
              [60.4821, -67.3853],
              [62.6878, -67.6476],
              [63.6989, -67.5082],
              [68.3279, -67.8895],
              [69.4163, -67.743],
              [69.9822, -68.4644],
              [69.5344, -68.7369],
              [69.6293, -69.2314],
              [68.9062, -69.3726],
              [69.0825, -69.8666],
              [68.1783, -69.8373],
              [67.2678, -70.2732],
              [67.9408, -70.4228],
              [69.2503, -70.4313],
              [68.8725, -71.0354],
              [68.0375, -71.391],
              [67.4321, -72.0031],
              [66.8919, -72.9486],
              [67.9714, -73.0857],
              [68.4197, -72.5149],
              [69.5548, -72.3746],
              [71.2766, -71.624],
              [71.9047, -70.7069],
              [72.7601, -70.3957],
              [72.8223, -70.0961],
              [73.9422, -69.7432],
              [75.4239, -69.8933],
              [75.8913, -69.5754],
              [77.8174, -69.069],
              [78.7265, -68.2779],
              [79.288, -68.1195],
              [82.017, -67.6902],
              [83.3043, -67.6032],
              [84.4851, -67.1145],
              [85.7107, -67.1611],
              [87.9803, -66.7882],
              [89.6986, -66.8229],
              [92.0734, -66.5077],
              [93.9644, -66.6897],
              [94.8397, -66.5015],
              [95.5411, -66.6311],
              [98.2577, -66.4673],
              [99.3701, -66.6484],
              [100.8891, -66.3581],
              [101.4746, -65.9511],
              [102.674, -65.8654],
              [103.9511, -65.9879],
              [108.1578, -66.6391],
              [109.4629, -66.9085],
              [110.6223, -66.5241],
              [110.9069, -66.0767],
              [113.0992, -65.8001],
              [113.7096, -65.9297],
              [114.3368, -66.3603],
              [115.3103, -66.561],
              [115.2739, -67.0279],
              [113.991, -67.2117],
              [114.0265, -67.4412],
              [116.7134, -67.047],
              [118.1388, -67.0825],
              [119.1331, -67.3706],
              [121.4875, -67.0905],
              [122.1826, -66.8593],
              [123.9693, -66.608],
              [124.5978, -66.7083],
              [125.8656, -66.3643],
              [126.6646, -66.4975],
              [127.5413, -67.051],
              [129.2369, -67.0417],
              [129.7412, -66.4686],
              [130.9517, -66.1912],
              [131.831, -66.2356],
              [133.4446, -66.0816],
              [134.9716, -66.3301],
              [135.352, -66.1273],
              [136.1941, -66.2924],
              [137.7539, -66.4065],
              [139.9001, -66.715],
              [141.9726, -66.8069],
              [142.8884, -67],
              [143.4481, -66.877],
              [144.6214, -67.1416],
              [143.9422, -67.7941],
              [145.9752, -67.6241],
              [146.8279, -67.9645],
              [147.0938, -68.3685],
              [148.4561, -68.467],
              [151.068, -68.3849],
              [151.562, -68.6939],
              [153.0819, -68.8568],
              [154.1997, -68.4178],
              [155.5202, -69.0246],
              [157.4813, -69.3087],
              [158.1578, -69.2088],
              [159.7838, -69.5217],
              [160.2095, -69.9749],
              [161.0369, -70.3172],
              [161.4244, -70.8268],
              [162.6749, -70.3047],
              [163.3487, -70.6208],
              [164.4034, -70.5103],
              [166.6269, -70.6643],
              [167.5692, -70.8103],
              [168.1725, -71.1832],
              [170.1625, -71.6307],
              [169.8287, -72.7288],
              [169.545, -73.0502],
              [167.6158, -73.337],
              [164.7758, -74.0286],
              [165.3027, -74.5936],
              [163.1672, -74.6021],
              [162.5337, -75.1671],
              [161.6797, -75.2177],
              [162.8156, -75.8463],
              [162.7628, -76.7456],
              [164.4296, -78.0422],
              [166.2087, -78.4515],
              [164.6347, -78.6033],
              [160.4829, -79.2017],
              [160.542, -80.425],
              [160.9078, -81.3901],
              [162.8214, -81.8664],
              [167.2328, -82.9521],
              [168.1099, -83.3618],
              [171.9172, -83.6441],
              [179.403, -84.2061],
              [180, -84.3517],
              [180, -89.9989],
              [-180, -89.9989],
              [-180, -89.583],
              [-180, -89.2931],
              [-180, -88.5869],
              [-180, -87.8811],
              [-180, -87.1753],
              [-180, -86.4695],
              [-180, -85.7633],
              [-180, -85.0575],
              [-180, -84.3517],
              [-171.7037, -84.5426],
              [-162.9335, -84.9012],
              [-157.1276, -85.1858],
              [-156.9864, -84.8111],
              [-163.5685, -84.5288],
              [-164.951, -83.8057],
              [-167.5528, -83.8111],
              [-169.1677, -83.4497],
              [-174.0661, -82.9002],
              [-171.8213, -82.8474],
              [-168.4178, -83.2287],
              [-163.111, -83.329],
              [-159.4445, -83.5434],
              [-157.6796, -83.1292],
              [-155.4594, -82.9805],
              [-153.0098, -82.4496],
              [-154.7175, -81.9409],
              [-153.9566, -81.7003],
              [-157.0326, -81.319],
              [-156.5283, -81.1623],
              [-148.1228, -80.9009],
              [-150.5755, -80.3536],
              [-148.3172, -80.0708],
              [-148.1765, -79.7761],
              [-152.0914, -79.2416],
              [-155.21, -78.9646],
              [-156.4693, -78.6353],
              [-154.7164, -78.3982],
              [-155.3416, -78.1918],
              [-157.2667, -78.1998],
              [-158.5003, -77.7786],
              [-158.2136, -77.1571],
              [-154.8149, -77.1269],
              [-153.4606, -77.4159],
              [-151.719, -77.4261],
              [-149.7177, -77.7976],
              [-145.967, -77.0688],
              [-145.7504, -76.7492],
              [-147.3404, -76.4384],
              [-146.383, -76.0998],
              [-143.5742, -75.5635],
              [-142.3298, -75.4907],
              [-141.5057, -75.6905],
              [-139.6911, -75.2129],
              [-137.6181, -75.0757],
              [-136.5494, -75.1396],
              [-135.362, -74.6904],
              [-133.4748, -74.852],
              [-132.0495, -74.7659],
              [-129.7907, -74.8915],
              [-127.8633, -74.7193],
              [-123.8894, -74.773],
              [-119.6771, -74.6544],
              [-118.3421, -74.3815],
              [-115.1052, -74.4551],
              [-114.7909, -73.9886],
              [-113.5085, -74.0889],
              [-113.9318, -74.982],
              [-111.6963, -74.7921],
              [-111.8064, -74.2696],
              [-110.307, -74.3668],
              [-110.3005, -74.7108],
              [-111.4631, -75.1334],
              [-109.2722, -75.1849],
              [-106.6189, -75.3438],
              [-104.9019, -75.1152],
              [-101.7081, -75.1272],
              [-101.0394, -75.4219],
              [-98.5578, -75.1898],
              [-101.2518, -74.4858],
              [-101.7154, -74.0237],
              [-102.7663, -73.8838],
              [-102.4108, -73.6166],
              [-100.9854, -73.7573],
              [-99.7812, -73.7201],
              [-100.0209, -73.4027],
              [-102.6751, -73.321],
              [-102.4092, -72.9876],
              [-98.0125, -73.0334],
              [-96.1521, -73.3095],
              [-94.2463, -73.313],
              [-92.241, -73.1785],
              [-90.9209, -73.3192],
              [-90.2954, -72.9779],
              [-88.1945, -72.8585],
              [-88.4193, -73.2291],
              [-87.0379, -73.3538],
              [-85.9808, -73.2087],
              [-84.9811, -73.5021],
              [-82.1835, -73.8568],
              [-81.3088, -73.7383],
              [-81.2624, -73.3148],
              [-80.4421, -72.9446],
              [-79.5217, -73.0897],
              [-78.4078, -73.5558],
              [-76.8506, -73.4604],
              [-77.1349, -73.8177],
              [-75.293, -73.6388],
              [-73.9961, -73.6996],
              [-72.9293, -73.4479],
              [-68.821, -73.1053],
              [-67.6671, -72.8345],
              [-66.8277, -72.0905],
              [-67.4603, -71.5268],
              [-67.8884, -70.4215],
              [-68.4033, -70.0198],
              [-68.7078, -69.4321],
              [-67.3717, -69.4125],
              [-66.9749, -69.1608],
              [-67.3906, -68.8612],
              [-66.7933, -68.2402],
              [-67.1498, -68.0245],
              [-66.705, -67.5273],
              [-67.5859, -67.435],
              [-67.4934, -67.1127],
              [-66.371, -66.6089],
              [-65.7664, -66.6249],
              [-65.6172, -66.1353],
              [-64.0658, -65.5538],
              [-64.0381, -65.1791],
              [-62.5761, -64.7557],
              [-61.6317, -64.6047],
              [-60.8643, -64.0734],
              [-59.5102, -63.8208],
              [-58.8721, -63.5518],
              [-57.3897, -63.2264],
              [-57.0206, -63.3729],
            ],
          ],
        ],
      },
      properties: {
        name: 'Antarctica',
        id: 'AQ',
      },
      id: 'AQ',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-170.7262, -14.3513],
            [-170.8205, -14.3122],
            [-170.6891, -14.2576],
            [-170.7262, -14.3513],
          ],
        ],
      },
      properties: {
        name: 'American Samoa',
        id: 'AS',
      },
      id: 'AS',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.4906, 38.9065],
            [46.1146, 38.8776],
            [45.7662, 39.3783],
            [45.0315, 39.7649],
            [44.7683, 39.7037],
            [44.2893, 40.0406],
            [43.7917, 40.0703],
            [43.5693, 40.4823],
            [43.7225, 40.7193],
            [43.4393, 41.1073],
            [45.0013, 41.291],
            [45.5877, 40.8467],
            [45.376, 40.6381],
            [45.9646, 40.2337],
            [45.7897, 39.8812],
            [46.5501, 39.2012],
            [46.4906, 38.9065],
          ],
        ],
      },
      properties: {
        name: 'Armenia',
        id: 'AM',
      },
      id: 'AM',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-62.6509, -22.2339],
              [-62.3726, -22.439],
              [-61.7984, -23.1821],
              [-61.0329, -23.7556],
              [-59.8924, -24.0934],
              [-59.373, -24.4538],
              [-58.3654, -24.9594],
              [-57.8216, -25.1365],
              [-57.5872, -25.4051],
              [-57.8906, -26.0065],
              [-58.2031, -26.3816],
              [-58.1914, -26.6302],
              [-58.6186, -27.1323],
              [-57.1118, -27.4701],
              [-56.3705, -27.5375],
              [-56.0673, -27.3076],
              [-55.7147, -27.415],
              [-55.4266, -27.0093],
              [-55.1358, -26.9312],
              [-54.7552, -26.533],
              [-54.6158, -25.576],
              [-54.1546, -25.5231],
              [-53.8912, -25.6687],
              [-53.6712, -26.2249],
              [-53.8381, -27.1212],
              [-54.3271, -27.4234],
              [-54.8291, -27.5508],
              [-55.1014, -27.8669],
              [-55.7255, -28.2043],
              [-56.9387, -29.595],
              [-57.609, -30.188],
              [-57.8724, -30.5911],
              [-57.8105, -30.8587],
              [-58.1889, -31.9241],
              [-58.2011, -32.4718],
              [-58.1709, -32.9592],
              [-58.4244, -33.1115],
              [-58.5471, -33.6637],
              [-58.2835, -34.6837],
              [-57.3036, -35.1884],
              [-57.1589, -35.5058],
              [-57.354, -35.7202],
              [-57.265, -36.1441],
              [-56.6981, -36.4265],
              [-56.6721, -36.8513],
              [-57.3957, -37.7448],
              [-57.5471, -38.0857],
              [-58.1792, -38.436],
              [-59.8282, -38.8381],
              [-61.1122, -38.993],
              [-62.067, -38.9189],
              [-62.3382, -39.1506],
              [-62.0537, -39.3739],
              [-62.2869, -39.8955],
              [-62.395, -40.8907],
              [-62.959, -41.1095],
              [-63.7731, -41.1499],
              [-64.8531, -40.8139],
              [-65.1334, -40.8805],
              [-65.059, -41.9698],
              [-64.4205, -42.4337],
              [-63.596, -42.4066],
              [-63.6925, -42.8052],
              [-64.1306, -42.8616],
              [-64.6504, -42.5313],
              [-65.0269, -42.759],
              [-64.4323, -43.0591],
              [-64.9856, -43.2935],
              [-65.2837, -43.6299],
              [-65.3613, -44.4773],
              [-65.6476, -44.6615],
              [-65.6388, -45.0078],
              [-66.1901, -44.9647],
              [-66.9414, -45.2573],
              [-67.5566, -45.9701],
              [-67.5633, -46.3452],
              [-66.7769, -47.0057],
              [-65.9985, -47.0936],
              [-65.7382, -47.3449],
              [-65.8101, -47.941],
              [-67.033, -48.6277],
              [-67.6849, -49.2465],
              [-67.7834, -49.8591],
              [-68.9396, -50.3824],
              [-69.2352, -50.9506],
              [-69.0325, -51.6364],
              [-68.4433, -52.3569],
              [-69.9603, -52.0084],
              [-71.9187, -51.9898],
              [-72.4077, -51.541],
              [-72.2763, -50.9102],
              [-72.5098, -50.6075],
              [-73.153, -50.7384],
              [-73.5012, -50.1254],
              [-73.5762, -49.583],
              [-72.6143, -48.7929],
              [-72.3549, -48.3658],
              [-72.518, -47.8762],
              [-72.346, -47.4927],
              [-71.905, -47.2015],
              [-71.9403, -46.8313],
              [-71.6952, -46.5783],
              [-71.8757, -46.1606],
              [-71.7727, -45.7242],
              [-71.3537, -45.2306],
              [-71.5962, -44.9794],
              [-71.2611, -44.7632],
              [-71.2127, -44.4414],
              [-71.82, -44.3832],
              [-71.6801, -43.9296],
              [-71.905, -43.44],
              [-71.7816, -43.167],
              [-72.1464, -42.9899],
              [-72.1247, -42.2983],
              [-71.7501, -42.0466],
              [-71.9114, -41.6506],
              [-71.932, -40.6918],
              [-71.6952, -40.3354],
              [-71.7199, -39.6354],
              [-71.5394, -39.6025],
              [-71.4256, -38.9855],
              [-70.9517, -38.7383],
              [-71.0281, -38.0413],
              [-71.1677, -37.7621],
              [-71.0556, -36.5237],
              [-70.4048, -36.0616],
              [-70.4703, -35.3261],
              [-70.2899, -34.733],
              [-69.8524, -34.2243],
              [-69.7978, -33.3987],
              [-70.0198, -33.2717],
              [-70.1696, -32.4718],
              [-70.5853, -31.5694],
              [-70.5196, -31.1486],
              [-70.1614, -30.4401],
              [-69.9563, -30.358],
              [-69.9277, -29.7694],
              [-70.0269, -29.3242],
              [-69.8278, -29.1031],
              [-69.6569, -28.4138],
              [-69.1744, -27.9246],
              [-68.8463, -27.1536],
              [-68.3461, -27.0279],
              [-68.5811, -26.5183],
              [-68.4144, -26.1539],
              [-68.6002, -25.4859],
              [-68.3842, -25.0921],
              [-68.562, -24.8378],
              [-68.2502, -24.3921],
              [-67.3562, -24.0339],
              [-67.0088, -23.0014],
              [-67.1948, -22.8216],
              [-66.7117, -22.2161],
              [-66.3652, -22.1136],
              [-66.0986, -21.8353],
              [-65.771, -22.0994],
              [-65.0579, -22.103],
              [-64.6056, -22.229],
              [-64.3739, -22.7612],
              [-63.9762, -22.0728],
              [-63.8186, -22.0053],
              [-62.8434, -21.9973],
              [-62.6509, -22.2339],
            ],
          ],
          [
            [
              [-68.6532, -54.8538],
              [-68.6299, -52.6525],
              [-68.2782, -52.9841],
              [-68.0085, -53.5638],
              [-67.2943, -54.0499],
              [-65.346, -54.8777],
              [-66.5112, -55.0322],
              [-66.9305, -54.9248],
              [-68.6532, -54.8538],
            ],
          ],
        ],
      },
      properties: {
        name: 'Argentina',
        id: 'AR',
      },
      id: 'AR',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [56.2979, 25.6505],
            [56.388, 24.9793],
            [56.064, 24.7388],
            [55.7959, 24.8679],
            [55.7608, 24.2425],
            [55.1997, 23.0346],
            [55.186, 22.704],
            [55.1043, 22.6214],
            [52.555, 22.933],
            [51.5927, 24.0787],
            [51.5683, 24.286],
            [51.9061, 23.9855],
            [52.6483, 24.1546],
            [53.8934, 24.0769],
            [54.3972, 24.278],
            [54.747, 24.8102],
            [55.941, 25.7939],
            [56.0804, 26.0624],
            [56.2979, 25.6505],
          ],
        ],
      },
      properties: {
        name: 'United Arab Emirates',
        id: 'AE',
      },
      id: 'AE',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [1.7059, 42.5033],
            [1.4861, 42.4345],
            [1.4284, 42.5961],
            [1.7059, 42.5033],
          ],
        ],
      },
      properties: {
        name: 'Andorra',
        id: 'AD',
      },
      id: 'AD',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.9893, 60.3511],
            [20.2588, 60.2612],
            [19.7461, 60.099],
            [19.6871, 60.2677],
            [19.9893, 60.3511],
          ],
        ],
      },
      properties: {
        name: 'Aland Islands',
        id: 'AX',
      },
      id: 'AX',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.0639, 42.5473],
            [20.4856, 42.2232],
            [20.566, 41.8739],
            [20.4892, 41.2724],
            [20.9641, 40.8498],
            [21.0312, 40.6585],
            [20.6574, 40.1174],
            [20.0013, 39.7095],
            [19.8517, 40.0437],
            [19.3981, 40.2847],
            [19.4407, 41.4247],
            [19.3426, 41.869],
            [19.2809, 42.1726],
            [19.6546, 42.6285],
            [20.0639, 42.5473],
          ],
        ],
      },
      properties: {
        name: 'Albania',
        id: 'AL',
      },
      id: 'AL',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-63.0012, 18.222],
            [-63.1534, 18.2002],
            [-63.026, 18.2699],
            [-63.0012, 18.222],
          ],
        ],
      },
      properties: {
        name: 'Anguilla',
        id: 'AI',
      },
      id: 'AI',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [12.2137, -5.7589],
              [12.0184, -5.0042],
              [12.3846, -4.6189],
              [12.8809, -4.4454],
              [13.0726, -4.6349],
              [12.8298, -4.7366],
              [12.5222, -5.1489],
              [12.5035, -5.6958],
              [12.2137, -5.7589],
            ],
          ],
          [
            [
              [23.9666, -10.8716],
              [23.9915, -12.4221],
              [23.8432, -13.0009],
              [21.9789, -13.0009],
              [21.9798, -15.9555],
              [22.1507, -16.5974],
              [23.3807, -17.6405],
              [21.4169, -18.0005],
              [20.625, -17.9965],
              [20.3928, -17.8873],
              [19.6396, -17.8687],
              [18.8259, -17.7661],
              [18.3962, -17.3995],
              [16.9136, -17.3915],
              [13.9382, -17.3888],
              [13.4038, -17.008],
              [13.101, -16.9676],
              [12.5479, -17.2126],
              [11.7432, -17.2494],
              [11.8191, -16.7043],
              [11.7507, -15.8321],
              [11.9678, -15.6341],
              [12.2803, -14.6376],
              [12.5506, -13.4377],
              [12.9834, -12.7759],
              [13.4171, -12.5202],
              [13.7855, -11.8127],
              [13.8477, -11.0545],
              [13.2093, -9.7033],
              [12.9985, -9.0481],
              [13.3589, -8.6872],
              [13.3785, -8.3698],
              [12.8622, -7.2317],
              [12.8236, -6.9547],
              [12.5213, -6.5903],
              [12.3025, -6.0927],
              [13.0682, -5.8649],
              [14.3985, -5.8925],
              [16.3152, -5.8658],
              [16.7179, -6.2414],
              [16.7094, -6.4717],
              [17.1551, -7.4612],
              [17.536, -8.076],
              [18.6532, -7.9361],
              [19.3408, -7.9668],
              [19.5277, -7.1447],
              [19.8753, -6.9862],
              [20.4821, -6.9161],
              [20.6077, -7.2778],
              [21.7511, -7.3054],
              [21.9056, -8.6934],
              [21.8133, -9.4689],
              [22.1977, -10.0406],
              [22.3069, -10.6914],
              [22.5613, -11.0558],
              [23.8339, -11.0136],
              [23.9666, -10.8716],
            ],
          ],
        ],
      },
      properties: {
        name: 'Angola',
        id: 'AO',
      },
      id: 'AO',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.8912, 37.2317],
            [74.5415, 37.0221],
            [74.0017, 36.8233],
            [73.7691, 36.8885],
            [72.623, 36.8295],
            [71.6206, 36.4366],
            [71.1852, 36.042],
            [71.4276, 35.8338],
            [71.6206, 35.1831],
            [70.9655, 34.5306],
            [71.0516, 34.0498],
            [69.8682, 33.8976],
            [70.2841, 33.3689],
            [69.9201, 33.1123],
            [69.5015, 33.02],
            [69.2414, 32.4336],
            [69.2791, 31.9369],
            [68.869, 31.6342],
            [68.2138, 31.8073],
            [67.5781, 31.5063],
            [67.2874, 31.2178],
            [66.9243, 31.3057],
            [66.3974, 30.9124],
            [66.1772, 29.8355],
            [65.0954, 29.5594],
            [64.521, 29.5643],
            [64.0989, 29.3921],
            [63.5676, 29.4982],
            [62.4765, 29.4085],
            [60.8434, 29.8586],
            [61.104, 30.1285],
            [61.7844, 30.8321],
            [61.6602, 31.3825],
            [60.854, 31.4833],
            [60.8292, 32.2494],
            [60.5606, 33.1376],
            [60.9171, 33.5052],
            [60.5109, 33.6388],
            [60.4856, 34.0947],
            [61.08, 34.8555],
            [61.262, 35.6194],
            [62.6105, 35.2333],
            [63.0566, 35.4459],
            [63.1086, 35.8187],
            [63.8623, 36.0123],
            [64.5108, 36.3408],
            [64.8162, 37.1322],
            [65.5549, 37.2512],
            [65.7648, 37.569],
            [66.5221, 37.3484],
            [67.069, 37.3346],
            [67.7592, 37.1722],
            [68.0677, 36.9498],
            [68.9604, 37.3249],
            [69.2649, 37.1083],
            [69.4922, 37.553],
            [70.2512, 37.664],
            [70.2148, 37.9246],
            [70.8789, 38.4564],
            [71.2558, 38.3068],
            [71.5518, 37.933],
            [71.4329, 37.1273],
            [71.8022, 36.6941],
            [72.8955, 37.2676],
            [73.4814, 37.4718],
            [73.7496, 37.2317],
            [74.2596, 37.4154],
            [74.8912, 37.2317],
          ],
        ],
      },
      properties: {
        name: 'Afghanistan',
        id: 'AF',
      },
      id: 'AF',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.7161, 17.0368],
            [-61.8597, 17.0133],
            [-61.8173, 17.1691],
            [-61.7161, 17.0368],
          ],
        ],
      },
      properties: {
        name: 'Antigua and Barbuda',
        id: 'AG',
      },
      id: 'AG',
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.8992, 12.4518],
            [-69.9421, 12.4385],
            [-70.0661, 12.5468],
            [-69.9732, 12.5677],
            [-69.8992, 12.4518],
          ],
        ],
      },
      properties: {
        name: 'Aruba',
        id: 'AW',
      },
      id: 'AW',
    },
  ],
};
