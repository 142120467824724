// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const toastMessages = {
  notifiedNewMember:
    'Team member added and notified to join and create a profile',
  updatedMemeberRole: name =>
    `Team member role(s) for ${name} updated successfully`,
};

export const employeeDashboardtoastOptions = {
  classname: 'new-employee-toast text-light',
};
