// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lfx-year-range-picker',
  templateUrl: './year-range-picker.component.html',
  styleUrls: ['./year-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: YearRangePickerComponent,
    },
  ],
})
export class YearRangePickerComponent implements OnInit, ControlValueAccessor {
  datePickerOptions = [];
  allTimeObject = { id: 'alltime', name: 'All Time' };
  selectedDateRangeId = 'alltime';
  disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string) => {};

  onTouched = () => {};

  @Output() x: EventEmitter<string> = new EventEmitter<any>();

  constructor() {}

  writeValue(obj: string): void {
    this.selectedDateRangeId = obj;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.fillDatePickerOptions();
  }

  private fillDatePickerOptions() {
    this.datePickerOptions.push(this.allTimeObject);
    this.datePickerOptions.push({
      id: `current_year`,
      name: `${new Date().getFullYear()}`,
    });
    this.datePickerOptions.push({
      id: `last_year`,
      name: new Date().getFullYear() - 1,
    });
    this.datePickerOptions.push({
      id: `prev_year`,
      name: new Date().getFullYear() - 2,
    });
    // this.datePickerOptions.push({
    //   id: `third_last_year`,
    //   name: new Date().getFullYear() - 3,
    // });
  }

  selectDatePicker(selectedOptionId) {
    this.selectedDateRangeId = selectedOptionId;
    this.onChange(this.selectedDateRangeId);
  }
}
