<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="lfx-mini-card">
  <div class="card bg-white rounded">
    <div class="card-body text-center">
      <p class="mb-0">
        <a class='logo-holder' href="javascript:;" (click)="triggerAction()">
          <img
            class="img-thumbnail"
            src="{{ logo }}"
            *ngIf="logo"
            alt=""
            (error)="onLogoError()"
          />
          <i class="img-thumbnail default-icon {{ icon }}" *ngIf="!logo && icon"></i>
        </a>
      </p>
      <p class="m-0 mb-0 text-wrap title" [ngbTooltip]="title?.length > 11 && titleToolTip" container="body">
        <strong><a href="javascript:;" (click)="triggerAction()">{{ title | textShorten : 11 }}</a></strong>
      </p>
      <p class="small details mb-0" [ngbTooltip]="details?.length > 40 && detailsToolTip" container="body">
        {{ details || noDescriptionText | textShorten : 40 }}
      </p>
    </div>
    <ng-content></ng-content>
    <div class="card-footer text-center">
      <div lfx-cta-buttons class="text-center">
        <button class="btn btn-sm btn-oval btn-{{ buttonType || 'success' }}" (click)="triggerAction();">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #titleToolTip>
  <small>
    <strong>
      <p>{{title}}</p>
    </strong>
  </small>
</ng-template>

<ng-template #detailsToolTip>
  <small>
    <strong>
      <p>{{details}}</p>
    </strong>
  </small>
</ng-template>
