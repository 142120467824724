<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="compare-by-dropdown d-flex flex-row">
  <span class="compare-by-text">Compare By: </span>
  <ng-select
    [items]="dropdownData"
    bindLabel="name"
    bindValue="value"
    [searchable]="false"
    class="compare-by-select"
    [(ngModel)]="selectedOptions"
    (change)="onChangeOptions()"
    [clearable]="false"
    [placeholder]="placeholderText"
  >
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index}}" class="mr-2" type="radio" [checked]="item$.selected"/> {{item.name}}
</ng-template>
</ng-select>
</div>
