// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ContributorAcknowledgements {
  contributorsList: ContributorAcknowledgement[];
}

interface ContributorAcknowledgement {
  linuxFoundationId: string;
  githubId: string;
  name: string;
  timestamp: string;
  signatureVersion: string;
  photoUrl: string;
}
