// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NavLink } from '@lfx/config';

@Component({
  selector: 'lfx-quick-nav',
  templateUrl: './quick-nav.component.html',
  styleUrls: ['./quick-nav.component.scss'],
})
export class QuickNavComponent implements OnChanges, AfterViewInit, OnDestroy {
  pageRoute;
  selectedItem: string;

  @Input() links: NavLink[];
  routerSubscription: Subscription;

  constructor(private router: Router) {
    this.traceNavigation();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.selectedItem) {
        this.scrollToSection(this.selectedItem);
      }
    }, 1000);
  }

  ngOnChanges() {
    this.pageRoute = this.router.url.split('#')[0];
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  traceNavigation(): void {
    this.routerSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        const navigation = this.router.getCurrentNavigation();

        this.selectedItem = navigation.finalUrl.fragment;

        if (this.selectedItem) {
          this.scrollToSection(this.selectedItem);
        }
      });
  }

  mouseover(fragment: string) {
    this.selectedItem = fragment;
  }

  scrollToSection(fragment: string) {
    if (fragment) {
      const element = document.querySelector('#' + fragment.toLowerCase());

      if (element) {
        const yOffset = -80;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }
}
