// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export enum Status {
  Accepted = 'Accepted',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  AttentionRequired = 'Attention Required',
  Locked = 'Locked',
  Unset = '',
}
