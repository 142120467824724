// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DataSet } from '../models';
import { ChartsVisualization } from '@lfx/core/models';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { verticalLinePlugin } from '@config';

Chart.register(...registerables);
Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
  display: false,
});

@Component({
  selector: 'lfx-bar-chart',
  templateUrl: './bar-chart.component.html',
})
export class BarChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() chartColors: string[];
  @Input('visualizationData')
  set visualizationData(visualization: ChartsVisualization) {
    this.dataSets = visualization.dataSets;
    this.xAxesLabels = visualization.labels;
  }

  @Input() fill: boolean;
  @ViewChild('chartCanvas') canvas: ElementRef;
  @Input() options = {};
  @Input() verticalAnnotations = [];
  @Input() chartHeight: number;

  dataSets: DataSet[] = [];
  xAxesLabels: (string | number)[] = [];
  chart: Chart;
  defaultOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
  };

  borderWidth = '1';

  /**
   * colors to Use must be HEX to support the hexRoRgb method
   */
  private fillColors = [
    '#D32F2F',
    '536DFE',
    '#FFA000',
    '#4CAF50',
    '#795548',
    '#0288D1',
  ];

  private borderColors = [
    '#D32F2F',
    '536DFE',
    '#FFA000',
    '#4CAF50',
    '#795548',
    '#0288D1',
  ];

  constructor() {
    Chart.register(verticalLinePlugin);
  }

  ngOnInit() {
    this.defaultOptions = Object.assign({}, this.defaultOptions, this.options);
  }

  ngOnChanges(): void {
    if (this.chart) {
      this.updateChart();
      this.setChartHeight();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createChart();
      this.setChartHeight();
    });
  }

  private createChart() {
    const canvas = this.canvas.nativeElement.getContext('2d');
    const datasets = this.dataSets.map((dataSet, index) =>
      Object.assign({}, this.createExtraSettingsForDataSet(index), dataSet)
    );

    this.chart = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: this.xAxesLabels,
        datasets,
      },
      options: this.defaultOptions,
    });
  }

  private setChartHeight() {
    if (this.chartHeight > 0) {
      this.chart.canvas.style.height = `${this.chartHeight}px`;
    }
  }

  private updateChart() {
    this.chart.data = {
      labels: this.xAxesLabels,
      datasets: this.dataSets.map((dataSet, index) =>
        Object.assign({}, this.createExtraSettingsForDataSet(index), dataSet)
      ),
    };
    this.chart.update();
  }

  private hexToRgb(hex, opacity = 1) {
    hex = hex || this.addColor();
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      return hex;
    }

    return (
      'rgba( ' +
      parseInt(result[1], 16) +
      ',' +
      parseInt(result[2], 16) +
      ',' +
      parseInt(result[3], 16) +
      ',' +
      opacity +
      ')'
    );
  }

  private generateRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  private addColor() {
    const newColor = this.generateRandomColor();

    this.fillColors.push(newColor);
    this.borderColors.push(newColor);

    return newColor;
  }

  private createExtraSettingsForDataSet(index: number): any {
    return {
      borderColor: this.borderColors[index],
      backgroundColor: this.hexToRgb(this.fillColors[index], 0.2),
      fill: this.fill ? 'origin' : false,
      borderWidth: this.borderWidth,
      datalabels: {
        display: false,
      },
    };
  }
}
