// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-preview-document',
  templateUrl: './preview-document-modal.component.html',
  styleUrls: ['./preview-document-modal.component.scss'],
})
export class PreviewDocumentModalComponent implements OnInit {
  @Input() modalTitle = generalConstants.pdfModalComponentConstants.title;
  @Input() modalCloseText =
    generalConstants.pdfModalComponentConstants.closeText;

  @Input() modalButtonText =
    generalConstants.pdfModalComponentConstants.buttonText;

  @Input() showModalButton = true;
  @Input() showModalFooter = false;
  @Input() documentSource: string;

  constructor(private modalService: NgbModal) {}

  openScrollableContent(documentContent) {
    this.modalService.open(documentContent, {
      scrollable: true,
      size: generalConstants.pdfModalComponentConstants.pdfTextSize,
    });
  }

  ngOnInit(): void {}
}
