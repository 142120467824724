// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Foundation } from '@models';
import { MutationPayload } from './mutation-payload';

export interface CreateNewProjectResult {
  createNewProject: CreateNewProjectPayload;
}

export interface CreateNewProjectPayload extends MutationPayload {
  newProject: Foundation;
}
