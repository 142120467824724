// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { ContextAccess } from './context-access';
import { ContextSubAccess } from './context-sub-access';

export interface ContextMenuAccess {
  menuId: string;
  contextId: string;
  title: string;
  icon: string;
  url: string;
  accesses: ContextAccess[];
  subMenu: ContextSubAccess[];
  defaultPage: false;
}
