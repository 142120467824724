<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div
  class="cursor-pointer {{ size }} {{ extraClasses }}"
  (click)="projectClicked.emit(project)"
  ngbTooltip="{{ showTooltip ? project.name : '' }}"
  [ngClass]="{
    papers:
      project.projects &&
      project.projects.length &&
      project.name !== linuxFoundationName,
    paper: !project.projects || !project.projects.length,
    book: project.name === linuxFoundationName,
    active: isActive,
    hover: showColorOnHover
  }"
  tooltipClass="project-card-tooltip"
  placement="bottom"
>
  <img
    *ngIf="project.logoUrl && project.name !== linuxFoundationName"
    (error)="project.logoUrl = null"
    [src]="project.logoUrl || project.logo"
    (click)="navigateToProjectGroupOverviewPage()"
    [ngClass]="{
      middle:
        (!project.projects || !project.projects.length) &&
        project.name !== linuxFoundationName &&
        size !== 'medium',
      'linux-logo': project.name === linuxFoundationName
    }"
  />

  <p
    class="community-supported-projects-title"
    *ngIf="project.name === linuxFoundationName"
  >
    {{ communitySupportedProjects }}
  </p>

  <p
    class="community-supported-projects-title"
    (click)="navigateToProjectGroupOverviewPage()"
    *ngIf="
      project.name && !project.logoUrl && project.name !== linuxFoundationName
    "
  >
    {{ project.name }}
  </p>

  <a
    *ngIf="showNumberOfProjects && project.projects && project.projects.length"
    (click)="projectsCountClicked.emit(project)"
  >
    {{ project.projects.length }} Subprojects
  </a>
</div>
