// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const workTimeDistributionQuery = gql`
  query WorkTimeDistribution(
    $organizationId: String!
    $projectId: String
    $dateRange: String
    $isAllOrgs: Boolean
  ) {
    workTimeDistribution(
      organizationId: $organizationId
      projectId: $projectId
      dateRange: $dateRange
      isAllOrgs: $isAllOrgs
    ) {
      month
      quarter
      year
      businessHoursCommits
      nonBusinessHoursCommits
      weekendCommits
    }
  }
`;
