// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FoundationMembershipTier } from '@lfx/core/models';
import { CompanyService } from '@lfx/core/services';

@Component({
  selector: 'lfx-project-group-membership-table',
  templateUrl: './project-group-membership-table.component.html',
  styleUrls: ['./project-group-membership-table.component.scss'],
})
export class ProjectGroupMembershipTableComponent implements OnInit {
  @Input() set uniqueTiers(value: FoundationMembershipTier[]) {
    if (value && value.length) {
      this._uniqueTiers = value.filter(v => !v.doNotDisplayInAutoJoin);
    }
  }

  @Input() showBecomeMemberButton = true;
  @Output() clickBecomeaMemberButton = new EventEmitter();

  _uniqueTiers: FoundationMembershipTier[] = [];
  isAllOrgsRollupView;

  constructor(private companyService: CompanyService) {}

  ngOnInit() {
    this.isAllOrgsRollupView = this.companyService.isAllOrgsRollup();
  }

  handleBecomeMemberClick() {
    this.clickBecomeaMemberButton.emit();
  }
}
