<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="offcanvas-container">
  <div
    class="close-button-box cursor-pointer"
    (click)="activeOffcanvas.close()"
  >
    <i class="fal fa-times"></i>
  </div>
  <div class="main-content-box">
    <div class="main-content">
      <ng-container *ngIf="eventRegistrationInfo">
        <div class="event-cell">
          <div class="event-cell-name">
            {{ eventRegistrationInfo.eventName }}
          </div>
          <div class="event-cell-date">
            {{ eventRegistrationInfo.eventDate }}
          </div>
        </div>
        <div class="title-header">
          <span class="title"
            >Registrants ({{
              eventRegistrationInfo.registrantsCount | number
            }})</span
          >
          <div class="top-right">
            <div class="lf-search-container">
              <span class="far fa-search cursor-pointer"></span>
              <img
                src="assets/img/svgs/normal_clear_u481.svg"
                class="clear-img"
                (click)="clearSearch()"
                *ngIf="employeeSearch.value !== ''"
              />
              <input
                #employeeSearch
                type="text"
                class="form-control"
                placeholder="Search"
                [(ngModel)]="searchValue"
                (ngModelChange)="onSearchChange($event)"
              />
            </div>
            <div class="lf-btn-download-container">
              <i
                class="fal fa-arrow-to-bottom"
                [placement]="'bottom'"
                tooltipClass="lf-box-tooltip"
                ngbTooltip="Download CSV"
                (click)="downloadCsv()"
              ></i>
            </div>
          </div>
        </div>
        <div class="btn-groups" *ngIf="activeFilterPill">
          <ng-container *ngFor="let item of filterPills">
            <button
              class="btn btn-outline-secondary"
              [class.active]="item.text === activeFilterPill.text"
              (click)="onChangeFilterPill(item)"
            >
              {{ item.text }} ({{ item.count | number }})
            </button>
          </ng-container>
        </div>
        <div class="table-container position-relative">
          <ngx-spinner
            *ngIf="isLoading"
            type="ball-clip-rotate"
            [fullScreen]="false"
            name="event-registrants-spinner"
            color="rgba(0,0,0,0.5)"
            bdColor="rgba(255,255,255,0.5)"
            size="default"
          ></ngx-spinner>
          <lfx-new-table
            [columns]="columns"
            [data]="paginatedData"
            [version]="2"
            class="mb-4"
            (columnSortEmmitter)="sortDataColumn($event)"
            [emptyDataMessage]="emptyDataMessage"
          >
          </lfx-new-table>
        </div>
        <!-- Pagination -->
        <lfx-ngb-pagination-wrapper
          *ngIf="paginatedData?.length"
          [pageSize]="pageSize"
          [page]="page"
          [collectionSize]="totalSize"
          (pageChange)="onPageChange($event)"
          [version]="2"
        ></lfx-ngb-pagination-wrapper>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #nameColumn let-row="row.name">
  <lfx-user-profile-cell
    [name]="row.name"
    [imageUrl]="row.imageUrl"
    [organizationName]="row.organizationName"
    [employeeId]="row.userId"
    [options]="{
      activity: { collapsed: false, defaultFilter: 'Events' }
    }"
  ></lfx-user-profile-cell>
</ng-template>
