<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="row">
  <div class="col-md-12 col-lg-12 fix-navbar">
    <div class="text-center quick-nav" *ngIf="pageRoute">
      <ng-template ngFor [ngForOf]="links" let-i="index" let-link>
        <ng-template [lfxPermissionsAll]="link.permissions?.all" [ngxPermissionsOnly]="link.permissions?.any" [ngxPermissionsExcept]="link.permissions?.except">
          <span>
            <a [ngClass]="{'active' : selectedItem === link.name, 'in-active': selectedItem != link.name}"
            (mouseover)="mouseover(link.name)" (mouseout)="mouseover('')" [routerLink]="pageRoute"
            [fragment]="link.fragment" (click)="traceNavigation()"> {{ link.name }}</a>
            <div class="element-separator"></div>
          </span>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
