<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="lfx-toggle-pills btn-group d-flex">
  <button
    class="btn btn-pill-left btn-secondary btn-xs w-100"
    [ngClass]="{
      active: isLeftActive,
      'toggle-btn-md text-uppercase': isMedium,
      'toggle-btn-active-md': isMedium && isLeftActive
    }"
    (click)="isLeftActive = true; isRightActive = false"
  >
    {{ leftButtonText }}
  </button>
  <button
    class="btn btn-pill-right btn-secondary btn-xs w-100"
    [ngClass]="{
      active: isRightActive,
      'toggle-btn-md text-uppercase': isMedium,
      'toggle-btn-active-md': isMedium && isRightActive
    }"
    (click)="isRightActive = true; isLeftActive = false"
  >
    {{ rightButtonText }}
  </button>
</div>
<div *ngIf="isLeftActive">
  <ng-content select="div.lfx-toggle-pills-left"></ng-content>
</div>
<div *ngIf="isRightActive">
  <ng-content select="div.lfx-toggle-pills-right"></ng-content>
</div>
<div class="overlay" *ngIf="overlayText">{{overlayText}}</div>