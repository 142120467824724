<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="no-files-state">
    <img *ngIf="!dashboardView || largeScreen" src="/assets/img/normal_u342.png" class="no-files-img" />
    <div class="title-description-container">
      <div class="title">
        Open source software saves development costs. See how much...
      </div>
      <div class="description">
        Identify the development cost savings for your organization by
        leveraging open source software and get access to a customized inventory
        of your open source software.
      </div>
      <ng-container *ngIf="isCompanyAdmin">
        <div class="lf-primary-btn upload-sbom-btn" (click)="uploadSbomEmitter.emit()">
          <i *ngIf="!dashboardView" class="fal fa-file-upload"></i>Run License Analysis
        </div>
      </ng-container>
    </div>
  </div>