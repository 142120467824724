<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="chart-section d-flex mx-3">
  <div class="chart-container">
    <div echarts [options]="chartOption" class="chart {{size}}"></div>
  </div>
  <div class="legend-container">
    <div class="legend-item d-flex mb-3" (click)="hideChartDataSet(0)" [ngClass]="{ 'clicked': seriesLegendIsClicked[0] }">
      <div class="legend-bullet">
        <span [style.background]="seriesLegendIsClicked[0] ? clickedLegendIconColor : legend.colors[0]"></span>
      </div>
      <div class="legend-text">
        {{ legend.texts[0] }}
      </div>
    </div>
    <div class="legend-item d-flex" (click)="hideChartDataSet(1)" [ngClass]="{ 'clicked': seriesLegendIsClicked[1] }">
      <div class="legend-bullet">
        <span [style.background]="seriesLegendIsClicked[1] ? clickedLegendIconColor : legend.colors[1]"></span>
      </div>
      <div class="legend-text">
        {{ legend.texts[1] }}
      </div>
    </div>
  </div>
</div>
