// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Favorite } from '@models';

export interface AuthSession {
  authToken: string;
  avatarUrl: string;
  expiresAt: number;
  subject: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  favorites?: Favorite[];
}
