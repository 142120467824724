// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export * from './update-current-user-input';
export * from './update-foundation-essentials-input';
export * from './update-foundation-finance-input';
export * from './create-new-foundation-input';
export * from './create-new-project-input';
export * from './create-new-foundation-committees-input';
export * from './update-foundation-legal-input';
export * from './update-foundation-committee-input';
export * from './delete-foundation-committee-input';
export * from './update-project-essentials-input';
export * from './create-new-project-committees-input';
export * from './update-project-committee-input';
export * from './delete-project-committee-input';
export * from './security-project-details-input';
export * from './project-infrastructure-input';
export * from './change-user-password-input';
export * from './update-organization-input';
export * from './project-transitive-dependencies-input';
export * from './project-dependency-input';
export * from './search-user-input';
export * from './get-my-organization-input';
export * from './roles-input';
export * from './create-user-role-scope-input';
export * from './send-new-member-input';
export * from './security-statistics-input';
export * from './foundation-vulnerable-projects-input';
export * from './update-user-input';
export * from './activity-logs-input';
export * from './foundation-activity-logs-input';
export * from './foundation-project-vulnerability-input';
export * from './project-activity-log-input';
export * from './document-signature-input';
export * from './assign-cla-manager-designee-input';
export * from './request-add-cla-manager-designee-input';
export * from './update-invite-input';
export * from './search-foundations-input';
export * from './get-committee-members-input';
export * from './get-contribution-input';
export * from './create-new-repository-role-input';
export * from './edit-org-user-input';
