// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() membersCount;

  constructor() {}

  ngOnInit() {}

  sendTnCEmail(maxCount) {
    if (this.membersCount < maxCount) {
      location.href = 'mailto:sales@training.linuxfoundation.org';
    }
  }
}
