// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lfx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  pageCount = 0;

  @Input() collectionSize: number;
  @Input() page = 1;
  @Input() pageSize: number;

  @Output() pageChange = new EventEmitter<number>(true);

  constructor() {}

  ngOnInit() {
    this.pageCount = Math.ceil(this.collectionSize / this.pageSize);
  }

  hasPrevious(): boolean {
    return this.page > 1;
  }

  hasNext(): boolean {
    return this.page < this.pageCount;
  }

  selectPage(pageNumber: number): void {
    this.updatePages(pageNumber);
  }

  private updatePages(newPage: number): void {
    this.pageCount = Math.ceil(this.collectionSize / this.pageSize);

    // if newPage is above max page range
    if (newPage >= this.pageCount) {
      this.page = this.pageCount;
    } else if (newPage <= 1) {
      this.page = 1;
    } else {
      this.page = newPage;
    }

    this.pageChange.emit(this.page);
  }
}
