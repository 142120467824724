// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  GetResponse,
  ListAccountSponsorshipData,
  MemberAccount,
} from '@models';

export interface ListAccountSponsorshipResults {
  listMemberSponsorships: GetResponse<ListAccountSponsorshipData> | null;
}

export interface GetAccountResults {
  getMemberAccount: MemberAccount | null;
}
