<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ng-template #progressBarTooltip>{{ tooltip }}</ng-template>
<div class="progress" [style.height.px]="height" [ngbTooltip]="tooltip && progressBarTooltip">
  <div
    class="progress-bar"
    [style.width.%]="value"
    role="progressbar"
  ></div>
</div>
