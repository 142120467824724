<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="disclaimer-section">
  <i class="fal fa-exclamation-triangle"></i>
  <span>
    {{ warningMsg }}
    <a
      *ngIf="redirectionLink && redirectionLinkText"
      [href]="redirectionLink"
      target="_blank"
      class="lf-link"
    >
      {{ redirectionLinkText }}
      <span><i class="far fa-angle-double-right"></i></span>
    </a>
  </span>
</div>
