<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="slider-container">
    <div class="row">
        <div class="col-md-6 bold left">{{ value | currency:currency :'symbol':'1.0' }}</div>
        <div class="col-md-6 bold right">{{ max | currency:currency :'symbol':'1.0' }}/yr</div>
        <div class="col-md-12">
            <input #sliderInput type="range" [min]="min" [max]="max" [value]="value" class="slider"
                (change)="updateColorCode()">
        </div>
    </div>
</div>
