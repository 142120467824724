// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Address } from '@models';
import { MutationPayload } from './mutation-payload';

export interface CreateAddressResult {
  createAddress: CreateAddressPayload;
}

export interface CreateAddressPayload extends MutationPayload {
  address?: Address;
}
