// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LineAreaChartOptionsBar,
  StackedChartOptions,
  StackedChartOptionsBar,
} from '@lfx/shared/components/charts/models';
import { isEmpty, sum, map, unzip } from 'lodash';
import {
  BarSeriesOption,
  EChartsOption,
  SeriesOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';

@Component({
  selector: 'lfx-staked-bar-chart',
  templateUrl: './staked-bar-chart.component.html',
  styleUrls: ['./staked-bar-chart.component.scss'],
})
export class StakedBarChartComponent implements OnInit {
  @Input()
  set chartOptions(chartOptions: StackedChartOptions) {
    if (!isEmpty(chartOptions)) {
      this._chartOptions = {
        ...this._chartOptions,
        ...chartOptions,
      };
      this.initChartOptions(this._chartOptions);
    }
  }

  @Output() chartMouseOver = new EventEmitter<any>();
  @Output() chartMouseMove = new EventEmitter<any>();
  @Output() chartMouseOut = new EventEmitter<any>();
  @Output() chartMouseClick = new EventEmitter<any>();

  echartsOptions: EChartsOption = {};
  _chartOptions: StackedChartOptions = {
    tooltipPercentage: true,
    tooltipTotalLabel: '',
    bars: [],
    title: '',
    labels: [],
    extraSeries: [],
    xAxisRotation: 0,
  };

  constructor() {}

  ngOnInit() {}

  get chartTitle() {
    return this._chartOptions.title;
  }

  getTotalValue(bars) {
    return sum(bars);
  }

  toggleBar(series) {
    const oldData = !isEmpty(series.data) ? series.data : series.oldData;
    const data = !isEmpty(series.data) ? [] : series.oldData;
    const newSeries = { ...series, data, oldData, hidden: !series.hidden };
    const chartSeries = (this.echartsOptions.series as any).map(item =>
      series.name !== item.name ? item : newSeries
    );

    this.echartsOptions = { ...this.echartsOptions, series: chartSeries };
  }

  private initChartOptions(chartOptions: StackedChartOptions) {
    const hasExtraSeriesOption =
      (chartOptions.extraSeries && chartOptions.extraSeries.length > 0) ||
      false;
    const { maxBarValue, maxExtraSeriesValue } = this.getMaxValues(
      chartOptions.bars,
      chartOptions.extraSeries
    );

    this.echartsOptions = {
      tooltip: this.getTooltip(chartOptions),
      grid: this.getGrid(hasExtraSeriesOption),
      xAxis: this.getXAxis(chartOptions.labels, hasExtraSeriesOption),
      yAxis: this.getYAxis(
        maxExtraSeriesValue,
        maxBarValue,
        hasExtraSeriesOption
      ),
      series: this.getSeries(chartOptions.bars, chartOptions.extraSeries),
    };
  }

  private getMaxValues(
    bars: LineAreaChartOptionsBar[] = [],
    extraSeries: any[] = []
  ) {
    const barsSum = map(unzip(bars.map(bar => bar.data)), sum);
    const maxBarValue = Math.max(...barsSum);
    const maxExtraSeriesValue = extraSeries.reduce((previous, currentBar) => {
      const maxValue = Math.max(...currentBar.data);

      previous = previous > maxValue ? previous : maxValue;

      return previous;
    }, 0);

    return { maxExtraSeriesValue, maxBarValue };
  }

  private getTooltip(
    chartOptions: StackedChartOptions
  ): TooltipComponentOption {
    return {
      trigger: 'axis',
      enterable: true,
      className: 'waterfall-tooltip-wrapper',
      showContent:
        chartOptions.showTooltipContent === undefined
          ? true
          : chartOptions.showTooltipContent,
      position: (point, params, dom, rect, size) => {
        const obj = { top: '10%' };

        if (point[0] + size.contentSize[0] > size.viewSize[0]) {
          obj['right'] = +(size.viewSize[0] - point[0]);
        } else {
          obj['left'] = point[0];
        }

        return obj;
      },
      formatter: params => {
        const total = params.reduce((previous, current) => {
          previous += current.value || 0;

          return previous;
        }, 0);
        let result = `
        <div style="font-size: 12px; font-family: 'Open Sans'"><b>${
          params[0].name
        }</b></div>
            <div style="font-size: 12px; font-family: 'Open Sans'"> <b>${total}</b> ${
          chartOptions.tooltipTotalLabel
            ? chartOptions.tooltipTotalLabel.toUpperCase()
            : chartOptions.title.toUpperCase()
        }</div>
        `;

        params.forEach(current => {
          if (current.value === 0) {
            return;
          }
          result += `
            <div style="font-size: 12px !important; font-family: 'Open Sans' !important">
            <div class="width-10 height-10 fas fa-circle m-r-10" style="color: ${
              current.color
            }"></div>
            <b>${
              total
                ? chartOptions.tooltipPercentage
                  ? ((current.value / total) * 100).toFixed(2)
                  : current.value
                : (0).toFixed(2)
            } ${chartOptions.tooltipPercentage ? '%' : ''}
            </b> ${current.seriesName}
            </div>
            `;
        });

        return result;
      },
    };
  }

  private getGrid(hasExtraSeriesOption = false) {
    const grid = [
      {
        left: '3%',
        right: '3%',
        top: hasExtraSeriesOption ? '50%' : '10%',
        bottom: '10%',
        containLabel: true,
      },
    ];

    if (hasExtraSeriesOption) {
      grid.push({
        left: '3%',
        right: '3%',
        top: '10%',
        bottom: '10%',
        containLabel: true,
      });
    }

    return grid;
  }

  private getXAxis(
    labels: string[],
    hasExtraSeriesOption = false
  ): XAXisComponentOption[] {
    const xAxis: XAXisComponentOption[] = [
      {
        type: 'category',
        data: labels,
        axisTick: { show: false },
        axisLabel: {
          formatter: value => value.replace(' ', '\n'),
        },
      },
    ];

    if (hasExtraSeriesOption) {
      xAxis.push({
        type: 'category',
        data: labels,
        show: false,
        gridIndex: 1,
      });
    }

    return xAxis;
  }

  private getYAxis(
    maxAreaValue: number,
    maxLineValue: number,
    hasExtraSeriesOption = false
  ): YAXisComponentOption[] {
    const addedValue = Math.pow(
      10,
      Math.floor(Math.log(maxAreaValue) / Math.log(10))
    );
    const difference = maxAreaValue / addedValue;
    const factor = Math.ceil(difference * 2) / 2;
    const max = Math.round(factor * addedValue);
    const addedValue2 = Math.pow(
      10,
      Math.floor(Math.log(maxLineValue) / Math.log(10))
    );
    const difference2 = maxLineValue / addedValue2;
    const factor2 = Math.ceil(difference2 * 2) / 2;
    const min = Math.round((factor2 * addedValue2) / 5);
    const yAxis: YAXisComponentOption[] = [
      {
        type: 'value',
        axisTick: { show: false },
        nameGap: 27,
        show: !hasExtraSeriesOption,
      },
    ];

    if (hasExtraSeriesOption) {
      yAxis.push({
        type: 'value',
        show: true,
        axisTick: { show: false },
        nameGap: 27,
        gridIndex: 1,
        max,
        interval: max / 10,
        min: 0,
        axisLabel: {
          formatter: (value, index) =>
            index > 5 ? value : min * index || value,
        },
      });
    }

    return yAxis;
  }

  private getSeries(
    bars: StackedChartOptionsBar[],
    extraSeries: any[]
  ): SeriesOption[] {
    const barSeriesArray = bars.reduce((previous, current) => {
      previous.push(
        this.getBar(
          current.data,
          current.title,
          'stack',
          current.color,
          true,
          current.barMaxWidth,
          current.legendInfoIconText
        )
      );

      return previous;
    }, []);

    if (extraSeries && extraSeries.length) {
      return [...this.getExtraSeries(extraSeries), ...barSeriesArray];
    } else {
      return barSeriesArray;
    }
  }

  private getBar(
    data: number[],
    title: string,
    stack: string,
    color = 'rgba(0,0,0,0)',
    tooltip = false,
    barMaxWidth: number,
    legendInfoIconText = ''
  ): CustomBarSeriesOption {
    return {
      name: title,
      type: 'bar',
      stack,
      itemStyle: {
        color,
      },
      emphasis: {
        itemStyle: {
          borderColor: color,
          color,
        },
      },
      data,
      barMaxWidth: barMaxWidth || 10,
      barGap: '100%',
      tooltip: {
        show: tooltip,
      },
      legendInfoIconText,
    };
  }

  private getExtraSeries(extraSeries: any[]) {
    return extraSeries.map(series => ({
      ...series,
      yAxisIndex: 1,
      xAxisIndex: 1,
    }));
  }
}

interface CustomBarSeriesOption extends BarSeriesOption {
  legendInfoIconText?: string;
}
