// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import * as faker from 'faker';

export interface ConversationThread {
  groupId: string;
  topic: string;
  response: number;
}

export const generateConversationThread = (
  groupId?: string
): ConversationThread => ({
  groupId: groupId || faker.random.uuid(),
  topic: faker.hacker.phrase(),
  response: faker.random.number({ min: 0, max: 100 }),
});
