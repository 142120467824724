<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-full-card
  [title]="initiative.name"
  [details]="initiative.description"
  [tag]="initiative.tag"
  [color]="initiative.colorBand"
  [logo]="initiative.logoUrl"
  (action) ="gotoCrowdFundingDetail(initiative.id)"
>

<div lfx-crowdfunding-bodyView class="lfx-crowdfunding-funding">
  <div class="row seperator">
    <div class="col-md-6 m-t-30">
      <div class="text">Donations</div>
      <div>
        <lfx-slider [min]="0" [max]="initiative.funding.target"
                    [currency]="initiative.funding.currencyCode" [color]="initiative.colorBand"
                    [value]="initiative.funding?.current" [disabled]="true"></lfx-slider>
      </div>
    </div>

    <div class="col-md-6 m-t-30">
      <div class="text m-l-30">Paid Out</div>
                <div class="bold m-l-30">{{ initiative.funding?.current | currency:initiative.funding.currencyCode:'symbol':'1.0' }}</div>
    </div>
  </div>


</div>

  <div lfx-crowdfunding-footerView class="footer-wrapper">
    <div class="row">
      <div class="col-md-6">
        <div class="text">Sponsors</div>
        <lfx-logo-list [items]="initiative.funding.sponsors" limit="2"></lfx-logo-list>
      </div>

      <div class="col-md-6">
        <div class="text">Backers</div>
        <lfx-logo-list [items]="initiative.funding.backers" limit="2"></lfx-logo-list>
      </div>
    </div>
  </div>
</lfx-full-card>

