// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';
import {
  LineAreaChartOptions,
  LineAreaChartOptionsBar,
} from '@lfx/shared/components/charts/models';
import { isEmpty, sum } from 'lodash';
import {
  EChartsOption,
  LineSeriesOption,
  SeriesOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';

@Component({
  selector: 'lfx-line-area-chart',
  templateUrl: './line-area-chart.component.html',
  styleUrls: ['./line-area-chart.component.scss'],
})
export class LineAreaChartComponent implements OnInit {
  @Input()
  set chartOptions(chartOptions: LineAreaChartOptions) {
    if (!isEmpty(chartOptions)) {
      this._chartOptions = {
        ...this._chartOptions,
        ...chartOptions,
      };
      this.initChartOptions(chartOptions);
    }
  }

  echartsLineAreaOption: EChartsOption = {};
  _chartOptions: LineAreaChartOptions = {
    bars: [],
    title: '',
    labels: [],
  };

  constructor() {}

  ngOnInit() {}

  get chartTitle() {
    return this._chartOptions.title;
  }

  getTotalValue(bars) {
    return sum(bars);
  }

  private initChartOptions(chartOptions: LineAreaChartOptions) {
    const hasAreaOption = this.hasArea(chartOptions.bars);
    const { maxAreaValue, maxLineValue } = this.getMaxValues(chartOptions.bars);

    this.echartsLineAreaOption = {
      tooltip: this.getTooltip(),
      grid: this.getGrid(hasAreaOption),
      xAxis: this.getXAxis(chartOptions.labels, hasAreaOption),
      yAxis: this.getYAxis(hasAreaOption, maxAreaValue, maxLineValue),
      series: this.getSeries(chartOptions.bars),
    };
  }

  private hasArea(bars: LineAreaChartOptionsBar[]) {
    return bars.reduce(
      (previous, currentBar) => previous || currentBar.isArea,
      false
    );
  }

  private getMaxValues(bars: LineAreaChartOptionsBar[]) {
    return bars.reduce(
      (previous, currentBar) => {
        const maxValue = Math.max(...currentBar.data);

        if (currentBar.isArea) {
          previous.maxAreaValue =
            previous.maxAreaValue > maxValue ? previous.maxAreaValue : maxValue;
        } else {
          previous.maxLineValue =
            previous.maxLineValue > maxValue ? previous.maxLineValue : maxValue;
        }

        return previous;
      },
      { maxAreaValue: 0, maxLineValue: 0 }
    );
  }

  private getTooltip(): TooltipComponentOption {
    return {
      trigger: 'axis',
      enterable: true,
      className: 'waterfall-tooltip-wrapper',
      position: (point, params, dom, rect, size) => {
        const obj = { top: '10%' };

        if (point[0] + size.contentSize[0] > size.viewSize[0]) {
          obj['right'] = +(size.viewSize[0] - point[0]);
        } else {
          obj['left'] = point[0];
        }

        return obj;
      },
      formatter: params => {
        let result = `
            <div style="font-size: 12px; font-family: 'Open Sans'"> <b>${params[0].axisValueLabel}</b> </div>
        `;

        params.forEach(current => {
          if (current.value === 0) {
            return;
          }
          result += `
            <div style="font-size: 12px !important; font-family: 'Open Sans' !important" class="m-t-10">
            <i class="width-10 height-10 fas fa-square-full m-r-10" style="color: ${current.color}"></i>
            <b>${current.value}
            </b> ${current.seriesName}
            </div>
            `;
        });

        return result;
      },
    };
  }

  private getGrid(hasAreaOption = false) {
    const grid = [
      {
        left: '7%',
        right: '3%',
        top: hasAreaOption ? '50%' : '10%',
        bottom: '10%',
        containLabel: false,
      },
    ];

    if (hasAreaOption) {
      grid.push({
        left: '7%',
        right: '3%',
        top: '10%',
        bottom: '10%',
        containLabel: false,
      });
    }

    return grid;
  }

  private getXAxis(
    labels: string[],
    hasAreaOption = false
  ): XAXisComponentOption[] {
    const xAxis: XAXisComponentOption[] = [
      {
        type: 'category',
        data: labels,
        boundaryGap: hasAreaOption,
        axisTick: { show: false },
      },
    ];

    if (hasAreaOption) {
      xAxis.push({
        type: 'category',
        data: labels,
        show: false,
        gridIndex: 1,
        boundaryGap: true,
      });
    }

    return xAxis;
  }

  private getYAxis(
    hasAreaOption = false,
    maxAreaValue: number,
    maxLineValue: number
  ): YAXisComponentOption[] {
    const addedValue = Math.pow(
      10,
      Math.floor(Math.log(maxAreaValue) / Math.log(10))
    );
    const difference = maxAreaValue / addedValue;
    const factor = Math.ceil(difference * 2) / 2;
    const max = Math.round(factor * addedValue);
    const addedValue2 = Math.pow(
      10,
      Math.floor(Math.log(maxLineValue) / Math.log(10))
    );
    const difference2 = maxLineValue / addedValue2;
    const factor2 = Math.ceil(difference2 * 2) / 2;
    const min = Math.round((factor2 * addedValue2) / 5);
    const yAxis: YAXisComponentOption[] = [
      {
        type: 'value',
        axisTick: { show: false },
        nameGap: 27,
        show: !hasAreaOption,
      },
    ];

    if (hasAreaOption) {
      yAxis.push({
        type: 'value',
        show: true,
        axisTick: { show: false },
        nameGap: 27,
        gridIndex: 1,
        max,
        interval: max / 10,
        min: 0,
        axisLabel: {
          formatter: (value, index) =>
            index > 5 ? value : min * index || value,
        },
      });
    }

    return yAxis;
  }

  private getSeries(bars: LineAreaChartOptionsBar[]): SeriesOption[] {
    return bars.reduce((previous, current) => {
      previous.push(
        this.getLine(
          current.data,
          current.title,
          current.color,
          current.isArea,
          true
        )
      );

      return previous;
    }, []);
  }

  private getLine(
    data: number[],
    title: string,
    color = 'rgba(0,0,0,0)',
    isArea = false,
    tooltip = false
  ): LineSeriesOption {
    const options: LineSeriesOption = {
      name: title,
      type: 'line',
      itemStyle: {
        color,
      },
      symbol: 'none',
      emphasis: {
        itemStyle: {
          color,
        },
      },
      data,
      tooltip: {
        show: tooltip,
      },
    };

    if (isArea) {
      options.areaStyle = {};
      options.yAxisIndex = 1;
      options.xAxisIndex = 1;
    }

    return options;
  }
}
