// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { FoundationProject } from './foundation-project';
import { Person } from './person';

export enum ClaStatus {
  Signed = 'signed',
  Pending = 'pending',
}

export enum CoverageType {
  GithubOrg = 'GitHub Organization',
  GithubUser = 'GitHub User',
  EmailDomain = 'Email Domain',
}

export enum ContributorCLAStatus {
  signed = 'signed',
  unsigned = 'unsigned',
}

export interface ClaGroup {
  id: string;
  name: string;
  status: ClaStatus;
  description?: string;
  employeesSigned: number;
  employeesPending: number;
  projects: FoundationProject[];
  colorBand: string;
  logoUrl: string;
  companyCLAStatus: CompanyCLAStatus;
}

export interface CompanyCLAWhitelistRules {
  coverageType: string;
  value: string;
  addedOn: Date;
}

export interface CompanyCLAContributors {
  name: string;
  status: ContributorCLAStatus;
  approvedOn: Date;
  id: string;
  coverageType: string;
  lastPullRequest: Date;
}

export interface CompanyCLAStatus {
  status: ClaStatus;
  coverageType: CoverageType;
  signedBy: string;
  signedOn: Date;
}

export interface ContributorLicenseAgreement {
  projectName: string;
  description: string;
  projectLogoUrl: string;
  foundationName: string;
  colorBand: string;
  status: ClaStatus;
  community: {
    contributorsSignatures: number;
    organizationSignatures: number;
  };
  organization: {
    contributorsSignatures: number;
    approvalRequests: number;
    managers: Person[];
  };
}
