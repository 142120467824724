// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FoundationProject } from '@models';
import { Router } from '@angular/router';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-foundation-project-card',
  templateUrl: './foundation-project-card.component.html',
  styleUrls: ['./foundation-project-card.component.scss'],
})
export class FoundationProjectCardComponent implements OnInit {
  @Input() project: FoundationProject;
  @Output() gotoProjectDetail = new EventEmitter<void>();
  viewProjectsDetails = generalConstants.viewProjectsDetails;
  projectDefaultIcon = generalConstants.projectDefaultIcon;
  constructor(private router: Router) {}

  ngOnInit() {}

  gotoProject() {
    this.gotoProjectDetail.emit();
  }
}
