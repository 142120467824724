// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  transform(value: number, total: number, fixedDigit = 0): string {
    if (isNaN(value) || isNaN(total) || total === 0) {
      return '';
    }

    const percentage = (value / total) * 100;

    return `${percentage.toFixed(fixedDigit)}%`;
  }
}
