// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface FoundationProjectVulnerability {
  projectId: string;
  highCount: number;
  mediumCount: number;
  lowCount: number;
  totalCount: number;
  lastUpdated: Date;
  highFixable: number;
  mediumFixable: number;
  lowFixable: number;
  totalFixable: number;
}

export interface GraphData {
  datasets: [
    {
      data: [];
      backgroundColor: string;
      fill: string;
    },
    {
      data: [];
      backgroundColor: string;
      fill: string;
    },
    {
      data: [];
      backgroundColor: string;
      fill: string;
    }
  ];
  labels: [];
}

export interface VulnerableProjectModel {
  id: string;
  name: string;
  parent: string;
  vulnerability: ProjectSecuritySummary;
  updatedAt: number;
}

export interface ProjectSecuritySummary {
  projectId: string;
  sfdcProjectId: string;
  securityIssues: TotalVulnerabilitiesStat;
  totalScannedRepositories: number;
  message: string;
  updatedAt: number;
}

export interface VulnerabilitiesSeverity {
  high: number[];
  medium: number[];
  low: number[];
}

export interface VulnerabilityDetectionStatistics {
  projectScanned: number;
  vulnerabilitiesFound: number;
  fixesRecommended: number;
  uniqueLicenses: number;
  libraries: number;
  vulnerabilitiesFixed: number;
  totalRepositories: number;
}

export interface VulnerabilityDetectionLeaderBoard {
  projectName: string;
  logoUrl: string;
  vulnerabilities: number;
  fixed?: number;
  metrics?: VulnerabilityMetrics;
}

interface VulnerabilityMetrics {
  commits: number;
  contributors: number;
}

export interface VulnerabilityUniqueLicenseDetails {
  licenses: UniqueLicenseDependency[];
  totalDependencies: number;
  totalRecords: number;
}

export interface UniqueLicenseDependency {
  name: string;
  dependencyCount: number;
}

interface TotalVulnerabilitiesStat {
  high: SecurityCount;
  medium: SecurityCount;
  low: SecurityCount;
  total: SecurityCount;
}

interface SecurityCount {
  totalCount: number;
  fixableCount: number;
}

export interface VulnerabilitiesOverview {
  lastScannedAt: number;
  openIssues: VulnerabilitySummary;
  overTimeIssues: OverTimeIssue[];
}

export interface VulnerabilitySummary {
  high: SeveritySummary;
  medium: SeveritySummary;
  low: SeveritySummary;
  total: SeveritySummary;
}

export interface SeveritySummary {
  totalCount: number;
  fixableCount: number;
}

export interface OverTimeIssue {
  label: string;
  total: number;
  fixable: number;
  severity: string;
  month: number;
  quarter: number;
  year: number;
}

export interface FoundationProjectVulnerabilitiesDetected {
  month: number;
  severity: string;
  totalFixableIssue: number;
  year: number;
  totalIssue: number;
}
