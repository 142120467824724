// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BaseListOffcanvasDirective } from '../base-list-offcanvas/base-list-offcanvas.directive';
import { EventService } from '@lfx/core/services/events.service';
import { Column } from '@lfx/shared/interfaces';
import { EventSpeaker } from '@lfx/core/models/event';
import { CompanyService } from '@lfx/core/services';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lfx-event-speakers-offcanvas',
  templateUrl: './event-speakers-offcanvas.component.html',
  styleUrls: [
    '../base-list-offcanvas/base-list-offcanvas.component.scss',
    './event-speakers-offcanvas.component.scss',
  ],
  providers: [DatePipe],
})
export class EventSpeakersOffcanvasComponent
  extends BaseListOffcanvasDirective
  implements OnInit
{
  @Input() eventInfo: EventInfo;

  constructor(
    activeOffcanvas: NgbActiveOffcanvas,
    offcanvasService: NgbOffcanvas,
    companyService: CompanyService,
    spinner: NgxSpinnerService,
    private eventService: EventService,
    private datePipe: DatePipe
  ) {
    super(activeOffcanvas, offcanvasService, companyService, spinner);
  }

  ngOnInit() {
    this.columns = this.getColumns();
    this.fetchData();
  }

  protected getColumns(): Column[] {
    return [
      {
        name: '',
        prop: 'index',
        style: {
          textAlign: 'center',
        },
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: true,
        sortDir: 'asc',
        template: this.nameColumn,
      },
      {
        name: 'Registration Date',
        prop: 'registrationDate',
      },
    ];
  }

  protected fetchData() {
    if (!this.eventInfo?.eventId || !this.eventInfo?.companyId) {
      return;
    }

    this.setIsLoading(true);
    this.eventService
      .getEventSpeakers(
        this.eventInfo.eventId,
        this.eventInfo.companyId,
        this.companyService.isAllOrgsRollup(),
        this.eventInfo.projectId
      )
      .subscribe({
        next: speakers => {
          this.data = this.transformSpeakersData(speakers);
          this.searchData = this.data;
          this.onPageChange(1);
          this.setIsLoading(false);
        },
        error: error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching speakers:', error);
          this.setIsLoading(false);
        },
      });
  }

  protected getCSVData() {
    return this.data.map(row => ({
      Name: row.name.text,
      'Registration Date': row.registrationDate.text,
    }));
  }

  protected getListType(): string {
    return 'Speakers';
  }

  private transformSpeakersData(speakers: EventSpeaker[]) {
    return speakers.map((speaker, index) => ({
      index: this.formatText(`${index + 1}`),
      name: this.formatText(
        `${speaker.speakerFirstName} ${speaker.speakerLastName}`,
        null,
        null,
        null,
        {
          imageUrl: '',
          userId: speaker.speakerId,
          organizationName: speaker.accountName,
        }
      ),
      registrationDate: this.formatText(
        this.formatDate(speaker.submissionDate)
      ),
    }));
  }

  formatDate(date: number | string) {
    if (!date) {
      return null;
    }

    return this.datePipe.transform(date, 'MM/dd/yyyy');
  }
}

export interface EventInfo {
  eventId: string;
  eventName: string;
  eventDate: string;
  count: number;
  dateRange: string;
  companyId: string;
  projectId?: string;
}
