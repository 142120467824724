// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  @Input() title: string;
  @Input() type = 'primary';
  @Input() badge: string;

  constructor() {}

  ngOnInit() {}
}
