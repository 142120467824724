<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="lfx-group" title="">
  <p class="text-{{ type }}" *ngIf="title">
  {{ title }}
    <i  *ngIf="badge" [ngClass]="['fas fa-medal badge-icon', badge]" ></i>
</p>
  <hr class="{{ type }}" *ngIf="title"/>
  <ng-content></ng-content>
</div>
