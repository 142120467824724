// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UserGroup } from '@models';
import { MutationPayload } from './mutation-payload';

export interface CreateProjectCommitteesResult {
  createProjectCommittees: CreateProjectCommitteesPayload;
}

export interface CreateProjectCommitteesPayload extends MutationPayload {
  committees: UserGroup[];
}
