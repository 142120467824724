// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CreateFoundationCommitteesInput {
  foundationId: string;
  committees: CommitteeInput[];
}

export interface CommitteeInput {
  category: string;
  collaborationName: string;
  description: string;
  url: string;
}
