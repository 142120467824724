// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-pii-help-icon',
  templateUrl: './pii-help-icon.component.html',
  styleUrls: ['./pii-help-icon.component.scss'],
})
export class PiiHelpIconComponent implements OnInit {
  @Input() dataType: 'events' | 'tnc' | 'projectContribution' = 'events';

  constructor() {}

  ngOnInit(): void {}
}
