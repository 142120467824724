// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

export * from './autocomplete.interfaces';

export * from './components/charts/models';

export interface DataCell {
  text?: string;
  subtext?: string;
  bold?: boolean;
  italic?: boolean;
  image?: string;
  expandAction?: boolean;
  username?: string;
  color?: string;
  border?: boolean;
  defaultIcon?: string;
  tooltipText?: string;
  clickableText?: boolean;
  extraText?: {
    text: string;
    style?: extraTextStyle;
    bold?: boolean;
  };
  callToAction?: {
    prefixText?: string;
    actionText: string;
  };
  subtextStyle?: { [key: string]: string };
}

export interface DataCellV2 {
  text: string;
  subtext?: string;
  url?: string;
  textStyle?: { [key: string]: string };
  extraData?: { [key: string]: string | Observable<string> };
  clickableText?: boolean;
  sortValue?: string | number;
}

export interface DataRow {
  [key: string]: DataCell | DataCellV2 | string | number | boolean | DataRow[];
  children?: DataRow[];
}

export interface DataRowV2 {
  [key: string]: DataCellV2;
}

export interface SelectableDataRow<RowContext> {
  id: string | number;
  [key: string]: RowContext | string | number;
}

export interface DataActionEvent {
  item: DataRow;
  parent?: DataRow | null;
}
export interface CallToActionEvent {
  column: Column;
  row: DataRow;
}
export interface Column {
  name: string;
  subtext?: string;
  prop: string;
  type?: string;
  hideInChild?: boolean;
  expandAction?: boolean;
  subTextEllipsis?: number;
  textEllipsis?: number;
  flex?: number;
  flexBasis?: string;
  /** Width of the column. Accepts percentage values (e.g. '60%') */
  columnWidth?: string;
  template?: TemplateRef<any>;
  filterData?: FilterInput;
  dataFontSize?: string;
  dataBold?: boolean;
  onlyImage?: boolean;
  hideFilterData?: boolean;
  titlecase?: boolean;
  clickableText?: boolean;
  linkableText?: boolean;
  sortable?: boolean;
  sortDir?: 'asc' | 'desc';
  infoText?: string;
  rowNoHover?: boolean;
  headerIcon?: boolean;
  headerIconTemplate?: TemplateRef<any>;
  style?: { [key: string]: string };
  dataStyle?: { [key: string]: string };
  infoIcon?: {
    action: 'click' | 'hover';
    hoverText?: string;
    useInnerHtmlForHoverText?: boolean;
  };
  headerFaIcon?: {
    icon: string;
    hoverText?: string;
    svgAsset?: string;
  };
}

export interface FilterData {
  id: string;
  label: string;
  image?: string;
  checked?: boolean;
}

export interface FilterInput {
  data: FilterData[];
  title?: string;
}

export interface FilterOutput {
  column: Column;
  filter: null | FilterData[];
}

export enum extraTextStyle {
  warning = 'warning',
  gray = 'gray',
}

export interface GroupedTableData {
  label: string;
  expanded: boolean;
  data: DataRow[];
}
