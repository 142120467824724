// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lfx-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Output() closeAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() type: string;
  @Input() message: string;
  @Input() fontawesomeIcon: string;
  @Input() selfClosing = false;

  isClosed = false;

  constructor() {}

  ngOnInit() {
    if (this.selfClosing) {
      setTimeout(() => this.close(), 30000);
    }
  }

  close() {
    this.isClosed = true;
    this.closeAction.emit(this.isClosed);
  }
}
