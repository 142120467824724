// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-mini-tags',
  templateUrl: './mini-tags.component.html',
  styleUrls: ['./mini-tags.component.scss'],
})
export class MiniTagsComponent implements OnInit {
  @Input() tags: string[];

  constructor() {}

  ngOnInit() {}
}
