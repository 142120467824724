<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<i class="fal fa-times cursor-pointer" (click)="activeOffcanvas.dismiss('Cross click')"></i>
<lfx-lf-box
  [sectionTitle]="sectionTitle"
  [subtitle]="subTitle"
  [version]="4"
  [isLoading]="isLoading"
  spinnerName="training-n-certificate-details-spinner"
  style="position: relative"
>
  <h4 class="inner-title">Enrollees ({{ dataCount$ | async }})</h4>
  <div class="top-right">
    <div class="lf-search-container">
      <span class="far fa-search cursor-pointer"></span>
      <img
        src="assets/img/svgs/normal_clear_u481.svg"
        class="clear-img"
        (click)="clearSearch()"
        *ngIf="employeeSearch.value !== ''"
      />
      <input
        #employeeSearch
        type="text"
        class="form-control"
        placeholder="Search"
        [(ngModel)]="searchValue"
        (ngModelChange)="searchInputChange($event)"
      />
    </div>
    <div class="lf-btn-download-container">
      <i
        class="fal fa-arrow-to-bottom"
        [placement]="'bottom'"
        tooltipClass="lf-box-tooltip"
        ngbTooltip="Download CSV"
        (click)="exportData()"
      ></i>
    </div>
  </div>
  <lfx-new-table
    [columns]="columns"
    [data]="listData$ | async"
    [version]="2"
    class="mb-4"
  ></lfx-new-table>
  <lfx-ngb-pagination-wrapper
    *ngIf="!isLoading && (dataCount$ | async) > 0"
    [collectionSize]="dataCount$ | async"
    [page]="currentPage"
    (pageChange)="onPageChange($event)"
    [pageSize]="pageSize"
  ></lfx-ngb-pagination-wrapper>
  <div class="d-flex justify-content-end mt-auto">
    <!-- <button class="lf-secondary-btn-v2"><a [routerLink]="'/' + companrySlug + '/events-details/attendees'">Learn More</a></button> -->
  </div>
</lfx-lf-box>
<ng-template #nameColumn let-row="row">
  <lfx-user-profile-cell
    [name]="row.name.text"
    [imageUrl]="row.imageUrl"
    [employeeId]="row.userId"
    [organizationName]="row.accountName.text"
    [options]="{
      activity: { collapsed: false, defaultFilter: 'Training' }
    }"
  ></lfx-user-profile-cell>
</ng-template>
