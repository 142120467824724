// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { MutationPayload } from './mutation-payload';
import { FoundationLegal } from '@models';

export interface UpdateFoundationLegalResult {
  updateFoundationLegal: UpdateFoundationLegalPayload;
}

export interface UpdateFoundationLegalPayload extends MutationPayload {
  foundationLegal?: FoundationLegal;
}
