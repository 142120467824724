<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-lf-box
  [sectionTitle]="noFilesState ? '' : 'Open Source Software Valuation'"
  [linkText]="successState && isDashboardPage ? 'Explore Software Inventory' : ''"
  [isLoading]="isLoading"
  [tooltipText]="
    noFilesState
      ? ''
      : 'The estimated value of the open source software you use based on the COCOMO model'
  "
  [spinnerName]="'dashboard-software-valuation'"
  (linkTextClickEmitter)="redirectToSoftwareInventoryPage()"
>
 <lfx-disclaimer *ngIf="showDisclaimer" warningMsg="The valuation is updating and may take up to 24 hours to complete."></lfx-disclaimer>

  <lfx-sbom-no-files-state *ngIf="noFilesState" [isCompanyAdmin]="isCompanyAdmin$ | async" [dashboardView]="true" (uploadSbomEmitter)="uploadSbom()"></lfx-sbom-no-files-state>
  <div class="in-progress-state" *ngIf="inProgressState">
    <i class="fas fa-calculator-alt icons"></i>
    <div class="title-desc-container">
      <div class="title">Software analysis in progress...</div>
      <div class="description">
        It can take up to 24 hours for changes to be reflected on the dashboard.
        Thank you for your patience.
      </div>
    </div>
  </div>

  <div class="coming-soon-parent">
    <div class="coming-soon" *ngIf="comingSoonMode && successState">
      Coming Soon!
    </div>
    <div class="success-state" *ngIf="successState">
      <div class="stat-column" [class.blur-mode]="comingSoonMode">
        <div class="green-title">${{ valuationRes?.costSavings || 0 | shortNumber}}</div>
        <div class="black-desc">Est. Development<br> Cost Savings</div>
      </div>
      <div>
        <div class="stat-column" [class.blur-mode]="comingSoonMode">
          <div class="black-title">{{ valuationRes?.effort || 0 | number}} Years</div>
          <div class="grey-desc">Est. Schedule Effort</div>
        </div>
        <div class="stat-column" [class.blur-mode]="comingSoonMode">
          <div class="black-title">{{ valuationRes?.noOfPeople || 0 | number: '1.0-0'}}</div>
          <div class="grey-desc">Est. People Required</div>
        </div>
      </div>
      <div class="inventory-container" *ngIf="isDashboardPage">
        <div class="inventory-title">Inventory</div>
        <div class="invenory-stat">
          {{ softwareEvaluationStats?.packageCount || '0' | number }}
        </div>
        <div class="inventory-desc">Packages</div>
        <div class="invenory-stat">
          {{ softwareEvaluationStats?.dependencyCount || '0' | number }}
        </div>
        <div class="inventory-desc">Dependencies</div>
      </div>
    </div>
  </div>
</lfx-lf-box>
