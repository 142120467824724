// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { isEmpty } from 'lodash';

@Directive({
  selector: '[lfxImageErrorFallback]',
})
export class ImageErrorFallbackDirective {
  @Input()
  set lfxImageErrorFallback(word: string) {
    this._word = word;
  }

  private _word: string;

  constructor(private el: ElementRef) {}

  @HostListener('error')
  updateUrl() {
    const word = this.word;
    const imageURL = this.generateImage(word);

    this.el.nativeElement.attributes['src'].value = imageURL;
  }

  private get word() {
    const names = this._word ? this._word.split(' ') : [];

    return !isEmpty(names) ? names[0].charAt(0) : 'N/A';
  }

  private generateImage(
    text,
    textColor = 'white',
    backgroundColor = 'rgb(204, 204, 204)'
  ) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 200;
    canvas.height = 200;

    // Draw background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = 'bolder 150px "Open Sans"';
    context.fillStyle = textColor;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL('image/png');
  }
}
