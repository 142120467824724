// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const enum CardTitles {
  accounting = 'Accounting',
  legal = 'Legal',
  membership = 'Membership',
  administration = 'Administration',
}

export const enum CardStatusColors {
  green = 'green',
  red = 'red',
  yellow = 'yellow',
}
export const enum AdminFormWizTab {
  essential = 'essential',
  legal = 'legal',
  finance = 'finance',
}

export enum DomainAvailabilityStatus {
  DEFAULT = 'null',
  CHECKING = 'checking',
  DONE = 'done',
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  INVALID = 'invalid',
  SAVING = 'saving',
}
