<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="chart-section d-flex m-l-3">
  <div class="chart-title-wrapper align-self-stretch d-flex align-items-center">
    <div class="chart-title">
      {{chartTitle}}
    </div>
  </div>
  <div class="chart-container">
    <div echarts [options]="echartsLineAreaOption" class="chart"></div>
  </div>
  <div class="legend-container" *ngIf="_chartOptions && _chartOptions.bars">
    <ng-container *ngFor="let bar of _chartOptions.bars">
      <div class="legend-item d-flex"  *ngIf="getTotalValue(bar.data) !== 0">
        <div class="legend-bullet">
          <span [style.background]="bar.color"></span>
        </div>
        <div class="legend-text">
          {{ bar.title }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
