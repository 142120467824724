// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BugBountyInitiative } from '@models';

@Component({
  selector: 'lfx-crowdfunding-bug-bounty-card',
  templateUrl: './crowdfunding-bug-bounty-card.component.html',
  styleUrls: ['./crowdfunding-bug-bounty-card.component.scss'],
})
export class CrowdfundingBugBountyCardComponent implements OnInit {
  @Input() initiative: BugBountyInitiative;
  @Output() bugBountyEmitter = new EventEmitter<any>();
  showOverlayOnBugBountyCard = false;

  constructor() {}

  ngOnInit() {}

  onClickCard() {
    this.bugBountyEmitter.emit();
  }
}
