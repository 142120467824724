// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { GetResponse } from './get-response';

export interface CommittesGrowthResult {
  code: number;
  message?: string;
  committerGrowthCountsRes: CommittesGrowthRes[];
}

export interface CommittesGrowthRes {
  date: string;
  committerCount: number;
  contributorCount: number;
}

export interface ReviewersGrowthResult {
  code: number;
  message?: string;
  reviewersGrowthCountsRes: ReviewersGrowthCountsRes[];
}

export interface ReviewersGrowthCountsRes {
  date: string;
  countReviewers: number;
}

export interface CommittersProjectsRes {
  projectName: string;
  projectSlug: string;
}

export interface CommittersRes {
  memberId: string;
  memberUsername: string;
  displayName: string;
  logoUrl: string;
  githubUrl: string;
  twitterUrl: string;
  linkedinUrl: string;
  countCommits: number;
  countContributions: number;
  countCommittersProjects: number;
  lastCommitDate: string;
}

export interface SingleCommitterProjectsRes {
  projectName: string;
}

export interface SingleCommitterActivitiesRes {
  type: string;
  platform: string;
  countContributions: number;
}

export interface MaintainersRes {
  memberId: string;
  memberUsername: string;
  displayName: string;
  logoUrl: string;
  githubUrl: string;
  twitterUrl: string;
  linkedinUrl: string;
  countChannels: number;
  lastMaintainDate: string;
  countMaintainersProjects: number;
}

export interface SingleMaintainerProjectsRes {
  projectName: string;
}

export interface SingleMaintainerReposRes {
  projectName: string;
  channel: string;
}

export interface ReviewersRes {
  memberId: string;
  memberUsername: string;
  displayName: string;
  logoUrl: string;
  githubUrl: string;
  twitterUrl: string;
  linkedinUrl: string;
  countCodeReviews: number;
  countContributions: number;
  countReviewersProjects: number;
  lastReviewDate: string;
}

export interface SingleReviewerProjectsRes {
  projectName: string;
}

export interface SingleReviewerActivitiesRes {
  type: string;
  platform: string;
  countContributions: number;
}

export interface ListOrgEventsParticipantsInput {
  orgId: string;
  queryParams: ListOrgEventsParticipantQueryParams;
}

export interface ListOrgEventsParticipantQueryParams {
  pageSize?: string;
  offset?: string;
  sortDir?: string;
  username?: string;
  userID?: string;
  orderBy?: string; // Enum: "lasteventat" "totalevents"
  type?: string; // Enum: "Speaker" "Attendee" or "Speaker,Attendee"
  year?: string;
}

export interface ListOrgEventsParticipantsRes {
  code: number;
  message: string;
  listOrgEventsParticipantsRes: GetResponse<ListOrgEventsParticipantData>;
}

export interface ListOrgEventsParticipantData {
  email: string;
  firstname: string;
  lastEventAt: string;
  lastname: string;
  photoUrl: string;
  totalEvents: number;
  type: string;
  userId: string;
  userName: string;
}

export interface GetOrgEventsParticipantInput {
  orgId: string;
  userId: string;
  queryParams: GetOrgEventsParticipantParams;
}

export interface GetOrgEventsParticipantParams {
  type?: string;
  year?: string;
}

export interface GetOrgEventsParticipantResponse {
  code: number;
  message?: string;
  getOrgEventsParticipantRes: GetOrgEventsParticipantRes;
}

export interface GetOrgEventsParticipantRes {
  email: string;
  events: GetOrgEventsParticipantEvent[];
  firstname: string;
  lastname: string;
  photoUrl: string;
  userId: string;
  userName: string;
  linkedInId: string;
  githubId: string;
  twitterId: string;
}

export interface GetOrgEventsParticipantEvent {
  country: string;
  id: string;
  name: string;
  startDate: string;
  type: string;
}

export interface CommitteeMembersRes {
  data: [
    {
      committees: [
        {
          name: string;
          projectName: string;
        }
      ];
      projects: [{ name: string }];
      githubId: string;
      id: string;
      lfUserName: string;
      linkedinId: string;
      name: string;
      photoUrl: string;
      twitterId: string;
    }
  ];
  metadata: {
    totalSize: number;
  };
}
