// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ClaInsights {
  claManagersCount: number;
  companiesCount: number;
  companiesProjectContributionCount: number;
  contributorsCount: number;
  corporateContributorsCount: number;
  createdAt: string;
  gerritRepositoriesCount: number;
  githubRepositoriesCount: number;
  individualContributorsCount: number;
  projectsCount: number;
  repositoriesCount: number;
}
export interface ActiveClaProject {
  projectId?: string;
  projectLogo?: string;
  projectName: string;
  projectSfid: string;
  projectType: string;
  claGroupName: string;
  signatoryName: string;
  signedOn: string;
  pdf?: string;
  subProjects?: string[];
}
export interface ClaGroupsForProject {
  signedClaList: SignedCla[];
  unsignedProjectList: UnsignedProject[];
}

export interface SignedCla {
  cclaUrl: string;
  projectLogo: string;
  projectName: string;
  projectSfid: string;
  projectType: string;
  signatoryName: string;
  signedOn: string;
  claGroupName: string;
  signatureId: string;
  signingEntityId: string;
  signingEntityName: string;
}

export interface UnsignedProject {
  canSign: string;
  claGroupId: string;
  claGroupName: string;
  projectName: string;
  projectSfid: string;
  cclaEnabled: boolean;
  iclaEnabled: boolean;
  subProjects: string[];
}

export enum ClaProjectType {
  claProjects = 'projects using easycla',
  signed = 'cla signed',
  totalContributors = 'total contributors',
  claRepositories = 'repositories using EasyCLA',
}
