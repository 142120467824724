// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { VulnerabilityDependencyRepositoryDetails } from './vulnerability-dependency-repository-details';

export interface VulnerabilityPackageDetails {
  dependencyName: string;
  dependencyVersion: string;
  licenses: string[];
  repositoryName: string;
  issueHighCount: number;
  issueLowCount: number;
  issueMediumCount: number;
  issueTotalCount: number;
  repositories: VulnerabilityDependencyRepositoryDetails[];
}
