// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface TopTenCertifications {
  similarSizeCompletedCertificatesFromMyOrg: number[];
  similarIndustryCompletedCertificatesFromMyOrg: number[];
  similarSize: number[];
  similarIndustry: number[];
  similarSizeChartLabels: string[];
  similarIndustryChartLabels: string[];
}
