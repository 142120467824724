// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '@lfx/core/models';
import { CompanyService } from '@lfx/core/services';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'lfx-org-nesting-popover',
  templateUrl: './org-nesting-popover.component.html',
  styleUrls: ['./org-nesting-popover.component.scss'],
})
export class OrgNestingPopoverComponent implements OnInit {
  parentCompany: ParentCompany;
  currentCompany: Company;
  loading = false;
  hidden = false;

  constructor(private companyService: CompanyService, public router: Router) {}

  ngOnInit(): void {
    this.companyService.currentCompanySlug$
      .pipe(
        tap(() => (this.hidden = false)),
        tap(() => (this.loading = true)),
        switchMap(slug =>
          this.companyService
            .searchOrganizations(null, 'Customer', true, null, null, slug)
            .pipe(
              switchMap(res => {
                this.currentCompany = res && res[0];

                if (!this.currentCompany) {
                  return of(null);
                }
                const parentId = this.currentCompany.isParent
                  ? this.currentCompany.id
                  : this.currentCompany.parentId;

                return this.companyService.searchOrganizations(
                  '',
                  'Customer',
                  true,
                  null,
                  parentId
                );
              })
            )
        )
      )
      .subscribe(
        res => {
          this.loading = false;

          if (!res || !res.length) {
            this.hidden = true;

            return;
          }

          if (this.currentCompany.isParent) {
            this.parentCompany = {
              ...this.currentCompany,
              children: res,
            };
          } else {
            this.parentCompany = {
              ...this.currentCompany.parentDetail,
              children: res,
            };
          }
        },
        () => (this.loading = false)
      );
  }

  setCompany(company) {
    this.router.navigate([`${company.slug}/dashboard`]).then(() => {
      this.companyService.setCurrentCompanyId(company.id);
      this.companyService.setCurrentCompanyB2BId(
        company.salesforceB2BAccountId
      );
      this.companyService.setCurrentCompanySlug(company.slug);
    });
  }
}

interface ParentCompany {
  id: string;
  name: string;
  salesforceB2BAccountId: string;
  children: Company[];
}
