<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="stints">
  <div class="stint">{{ stint.latest }}</div>
  <div
    *ngIf="stint.past.length > 0"
    [ngbPopover]="stintsPopoverContent"
    popoverClass="stints-popover"
    placement="bottom"
    triggers="mouseenter:mouseleave"
    class="more-stints"
  >
    + {{ stint.past.length }}
  </div>
  <ng-template #stintsPopoverContent>
    <div class="past-stints">
      <div class="stint" *ngFor="let pastStint of stint.past">
        <span>{{ pastStint }}</span>
      </div>
    </div>
  </ng-template>
</div>
