<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="d-flex circle">
  <div class="w-100 text-center circle-content align-self-center">
    <p class="mb-0 text-bold">{{value}}</p>
    <p>
      {{ description }}
    </p>
  </div>
</div>
