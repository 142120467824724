// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface MemberInvite {
  inviteId?: string;
  email: string;
  firstName: string;
  lastName: string;
  scope: string;
  scopeId: string;
  roleName: string;
  type: string;
  status?: string;
  expired?: boolean;
}
