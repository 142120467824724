// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';
import { DashboardAccess, Employee } from '@lfx/core/models';

@Pipe({
  name: 'dashboardAccess',
})
export class DashboardAccessPipe implements PipeTransform {
  transform(employee: Employee): DashboardAccess {
    if (employee.status === 'Removed') {
      return DashboardAccess.Pending_Removal;
    }

    if (employee.status === 'Invited') {
      return DashboardAccess.Pending_Invite;
    }

    if (
      employee.engagementType?.length &&
      this.isAdmin(employee.engagementType)
    ) {
      return DashboardAccess.Admin;
    }

    if (
      employee.engagementType?.length &&
      this.isViewer(employee.engagementType)
    ) {
      return DashboardAccess.Viewer;
    }

    return DashboardAccess.Default;
  }

  isAdmin(engagementType: Employee['engagementType']): boolean {
    return !!engagementType.find(
      engagment =>
        engagment.description === 'company-admin' ||
        engagment.engagementTypeId?.startsWith('company-admin')
    );
  }

  isViewer(engagementType: Employee['engagementType']): boolean {
    return !!engagementType.find(
      engagment =>
        engagment.description === 'viewer' ||
        engagment.engagementTypeId?.startsWith('viewer')
    );
  }
}
