<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="offcanvas-container">
    <div class="close-button-box cursor-pointer" (click)="onCloseModal()">
        <i class="fal fa-times"></i>
    </div>
    <div class="main-content-box">
        <div class="main-content" *ngFor="let infoObject of info">
            <div class="title">
                {{ infoObject.title}}
            </div>
            <div class="intro" [innerHTML]="infoObject.body"></div>
            <div class="definitions" *ngIf="infoObject.children">
                <div class="def" *ngFor="let item of infoObject.children">
                    <div class="def-title">
                        {{ item.title }}
                    </div>
                    <div class="def-description" [innerHTML]="item.body"></div>
                </div>
            </div>
        </div>
    </div>
</div>
