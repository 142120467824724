<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-mini-card
  [title]="initiative.name"
  [details]="initiative.description"
  [logo]="initiative.logoUrl"
  buttonType="success"
  [buttonText]="buttonName"
  (action)="onButtonClick()"
></lfx-mini-card>
