// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface GetMyOrganizationInput {
  withAdmins?: boolean;
  withServiceUsers?: boolean;
  withInvites?: boolean;
  withOrganizationFoundations?: boolean;
  withOrganizationCommitteeFoundations?: boolean;
  withContribution?: boolean;
  withContributionStats?: boolean;
  withContributionPerEmployee?: boolean;
  withContributionPerProject?: boolean;
  withContributionMonthly?: boolean;
  withContributionPerProjectMonthly?: boolean;
  contributionMonthlyProjectSlug?: string;
  contributionFilters?: {
    Jfilter?: string;
    year?: string;
    pageSize?: number;
    compareRedshift?: boolean;
  };
  contributionStatsFilters?: {
    Jfilter?: string;
    year?: string;
    pageSize?: number;
  };
  contributionMonthlyFilters?: {
    Jfilter?: string;
    year?: string;
    projectSlug?: string;
  };
  contributionPerProjectFilters?: {
    Jfilter?: string;
    year?: string;
  };
  contributionPerProjectMonthlyFilters?: {
    Jfilter?: string;
    year?: string;
    pageSize?: number;
  };
  contributionPerEmployeeFilters?: {
    pageSize?: number;
    offset?: number;
    year?: string;
    Jfilter?: string;
  };
  contributionProjectId?: string;
}
