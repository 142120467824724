// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const productivityQuery = gql`
  query (
    $organizationId: String = ""
    $projectId: String = ""
    $dateRange: String = ""
    $isAllOrgs: Boolean
  ) {
    getProductivity(
      organizationId: $organizationId
      projectId: $projectId
      dateRange: $dateRange
      isAllOrgs: $isAllOrgs
    ) {
      countCommits
      countCommitsChange
      countPullrequests
      countPullrequestsChange
      countIssues
      countIssuesChange
      countLinesAdded
      countLinesDeleted
      countCodeReviews
      countCodeReviewsChange
    }
  }
`;
