<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="progress-container">
  <div
    class="circle"
    [ngClass]="{
      done: membersCount > 2,
      active: membersCount > 0 && membersCount < 3
    }"
  >
    <span class="discount-header">10% Discount</span>
    <span class="label" (click)="sendTnCEmail(3)"
      ><i
        [ngClass]="{
          'fal fa-check': membersCount > 2,
          'fas fa-lock-alt': membersCount < 3
        }"
      ></i
    ></span>
    <span class="title">1-2 training, certifications and/or bundles</span>
  </div>
  <span
    class="bar"
    [ngClass]="{
      done: membersCount > 4,
      active: membersCount > 2 && membersCount < 5
    }"
  ></span>
  <div
    class="circle"
    [ngClass]="{
      done: membersCount > 4,
      active: membersCount > 2 && membersCount < 5
    }"
  >
    <span class="discount-header">12% Discount</span>
    <span class="label" (click)="sendTnCEmail(5)"
      ><i
        [ngClass]="{
          'fal fa-check': membersCount > 4,
          'fas fa-lock-alt': membersCount < 5
        }"
      ></i
    ></span>
    <span class="title">3-4 training, certifications and/or bundles</span>
  </div>
  <span
    class="bar"
    [ngClass]="{
      done: membersCount > 20,
      active: membersCount > 4 && membersCount < 21
    }"
  ></span>
  <div
    class="circle"
    [ngClass]="{
      done: membersCount > 20,
      active: membersCount > 4 && membersCount < 21
    }"
  >
    <span class="discount-header">20% Discount</span>
    <span class="label" (click)="sendTnCEmail(21)"
      ><i
        [ngClass]="{
          'fal fa-check': membersCount > 20,
          'fas fa-lock-alt': membersCount < 21
        }"
      ></i
    ></span>
    <span class="title">5-20 training, certifications and/or bundles</span>
  </div>
  <span
    class="bar"
    [ngClass]="{
      done: membersCount > 50,
      active: membersCount > 20 && membersCount < 51
    }"
  ></span>
  <div
    class="circle"
    [ngClass]="{
      done: membersCount > 50,
      active: membersCount > 20 && membersCount < 51
    }"
  >
    <span class="discount-header">25% Discount</span>
    <span class="label" (click)="sendTnCEmail(51)"
      ><i
        [ngClass]="{
          'fal fa-check': membersCount > 50,
          'fas fa-lock-alt': membersCount < 51
        }"
      ></i
    ></span>
    <span class="title">21-50 training, certifications and/or bundles</span>
  </div>
  <span
    class="bar"
    [ngClass]="{
      done: membersCount > 100,
      active: membersCount > 50 && membersCount < 101
    }"
  ></span>
  <div
    class="circle"
    [ngClass]="{
      done: membersCount > 100,
      active: membersCount > 50 && membersCount < 101
    }"
  >
    <span class="discount-header">30% Discount</span>
    <span class="label" (click)="sendTnCEmail(101)"
      ><i
        [ngClass]="{
          'fal fa-check': membersCount > 100,
          'fas fa-lock-alt': membersCount < 101
        }"
      ></i
    ></span>
    <span class="title">51-100 training, certifications and/or bundles</span>
  </div>
  <span
    class="bar"
    [ngClass]="{
      done: membersCount > 500,
      active: membersCount > 100 && membersCount < 501
    }"
  ></span>
  <div
    class="circle"
    [ngClass]="{
      done: membersCount > 500,
      active: membersCount > 100 && membersCount < 501
    }"
  >
    <span class="discount-header">35% Discount</span>
    <span class="label" (click)="sendTnCEmail(501)"
      ><i
        [ngClass]="{
          'fal fa-check': membersCount > 500,
          'fas fa-lock-alt': membersCount < 501
        }"
      ></i
    ></span>
    <span class="title">101-500 training, certifications and/or bundles</span>
  </div>
  <span
    class="bar"
    [ngClass]="{
      active: membersCount > 500
    }"
  ></span>
  <div
    class="circle"
    [ngClass]="{
      active: membersCount > 500
    }"
  >
    <span class="discount-header">40% Discount</span>
    <span class="label" (click)="sendTnCEmail(99999999)"
      ><i class="fas fa-lock-alt"></i
    ></span>
    <span class="title">501+ training, certifications and/or bundles</span>
  </div>
</div>
