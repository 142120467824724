// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface GetResponse<T> {
  data: T[];
  metadata: {
    offset: number;
    pageSize: number;
    totalSize: number;
  };
}
