// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface Role {
  roleId: string;
  roleName: string;
  roleTitle: string;
  isAdmin: boolean;
  description: string;
  roleObjects: [RoleObjects];
}

interface RoleObjects {
  objectRoleId: string;
  roleId: string;
  objectId: string;
  objectName: string;
}
