// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationUserContributionDetailsQuery = gql`
  query organizationUserContributionDetails(
    $organizationId: ID!
    $userId: ID
    $isAllOrgs: Boolean
  ) {
    organizationUserContributionDetails(
      organizationId: $organizationId
      userId: $userId
      isAllOrgs: $isAllOrgs
    ) {
      contributorId
      projectId
      projectName
      foundationId
      foundationName
      isContributor
      isMaintainer
      orgAffiliation {
        endDate
        pkid
        startDate
      }
    }
  }
`;
