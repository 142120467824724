// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface SecurityProjectDetails {
  repositories: Repository[];
}

interface Repository {
  dependencies: Dependency[];
  folderPaths: string;
  name: string;
  repositoryId: string;
  url: string;
}

interface Dependency {
  dependencyId: string;
  licenses: string;
  name: string;
  repositoryId: string;
  repositoryFolderpathId: string;
  rootDependency: boolean;
  version: string;
}
