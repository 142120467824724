// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import * as faker from 'faker';
import { imagePlaceholders } from '@config';
export interface Course {
  id: string;
  courseCategory: CourseCategory;
  name: string;
  code: string;
  numberOfDevs: number;
}

export const mockCourse = (): Course => {
  const name = `${faker.hacker.adjective()} ${faker.hacker.ingverb()}`
    .split(' ')
    .map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join(' ');

  return {
    id: faker.random.uuid(),
    courseCategory: mockCourseCategory(),
    name,
    code: faker.random.alphaNumeric(5).toUpperCase(),
    numberOfDevs: faker.random.number(500),
  };
};

export interface CourseCategory {
  name: string;
  logoUrl: string;
}

export const mockCourseCategory = (): CourseCategory => {
  const name = faker.hacker.ingverb();

  return {
    name,
    logoUrl: imagePlaceholders.logo,
  };
};
