// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';
import html2canvas from 'html2canvas';

@Component({
  selector: 'lfx-screenshot-taker',
  templateUrl: './screenshot-taker.component.html',
  styleUrls: ['./screenshot-taker.component.scss'],
})
export class ScreenshotTakerComponent implements OnInit {
  @Input() elementID = '';
  @Input() screenshotName = 'screenshot';
  @Input() top = 15;
  @Input() right = 15;

  constructor() {}

  ngOnInit(): void {}

  downloadChartImage() {
    html2canvas(document.querySelector('#' + this.elementID)).then(canvas => {
      const a = document.createElement('a');

      a.href = canvas.toDataURL();
      a.download = this.screenshotName + '.png';

      a.click();
      a.remove();
    });
  }
}
