<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="chart-popover-icon">
    <i class="fas fa-info-circle" [ngbPopover]="popContent" popoverClass="chart-popover"
        triggers="mouseenter:mouseleave">
    </i>
    <ng-template #popContent>
        <div [ngSwitch]="dataType">
            <div *ngSwitchCase="'events'">
                We only display event data from users who have affiliated themselves with your corporate email address
                (e.g., test@linuxfoundation.org.)
            </div>
            <div *ngSwitchCase="'tnc'">
                We only display training and certification data <br>
                from users who have affiliated themselves<br>
                with your corporate email address <br>
                (e.g., test@linuxfoundation.org.)
            </div>
            <div *ngSwitchCase="'projectContribution'">
                We only display project contributions data from users who have affiliated themselves with your corporate
                email address (e.g., test@linuxfoundation.org.)
            </div>
            <div *ngSwitchDefault></div>
        </div>
    </ng-template>
</div>