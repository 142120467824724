// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import faker from 'faker';
import { mockList } from '@lfx/core/mocks/generator';
import {
  RepositoryDetail,
  ActiveContributor,
  repositoryDetails,
  organizationDetails,
  generateMostActiveContributors,
} from './statistics';

const repoNames = [
  'kubernetes',
  'cloud-provider-azure',
  'k8s.io',
  'kubeadm',
  'repo-infra',
  'kube-controller-manager',
  'kube-scheduler',
  'kube-aggregator',
  'security',
  'node-api',
];

interface RepositorySecurity {
  name: string;
  high: number;
  low: number;
  medium: number;
}

export interface ProjectStatistics {
  community: RepositoryDetail[];
  repositorySecurity: RepositorySecurity[];
  organizationsRepositoryDetails: RepositoryDetail[];
  activeContributors: ActiveContributor[];
}
const generateRepositorySecurity = () => ({
  name: faker.random.arrayElement(repoNames),
  high: faker.random.number({ min: 30, max: 100 }),
  low: faker.random.number({ min: 0, max: 10 }),
  medium: faker.random.number({ min: 10, max: 30 }),
});

export const mockProjectStatistics = (): ProjectStatistics => ({
  community: [...repositoryDetails],
  repositorySecurity: mockList(generateRepositorySecurity),
  organizationsRepositoryDetails: [...organizationDetails],
  activeContributors: mockList(generateMostActiveContributors, {
    min: 12,
    max: 20,
  }),
});
