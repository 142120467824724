// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface FoundationMemberCompany {
  name: string;
  id: string;
  logoUrl: string;
  website: string;
  tier: FoundationMemberTier;
}

export interface FoundationMemberTier {
  id: string;
  name: string;
  status: string;
  family: string;
}
