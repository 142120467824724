// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss'],
})
export class MiniCardComponent implements OnInit {
  @Input() title: string;
  @Input() details: string;
  @Input() logo: string;
  @Input() icon: string;
  @Input() buttonText: string;
  @Input() buttonType: string;
  @Output() action = new EventEmitter<void>();
  noDescriptionText = generalConstants.noDescriptionText;

  constructor() {}

  ngOnInit() {}

  triggerAction() {
    this.action.emit();
  }

  onLogoError() {
    // On logo load error. Set logo URL to empty string which sets default icon.
    this.logo = '';
  }
}
