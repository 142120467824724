// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { MemberInvite } from './invite';

export interface Contacts {
  endDate: string;
  membershipID: string;
  name: string;
  role: string;
  startDate: string;
  contact: Contact;
  primaryContact: boolean;
  createdDate: string;
  id: string;
  systemModStamp: string;
  invite?: MemberInvite;
}

export interface Contact {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  photoUrl: string;
}

export interface Metadata {
  offset: number;
  pageSize: number;
  totalSize: number;
}

export interface MembershipContacts {
  data: Contacts[];
  metadata: Metadata;
}

export interface GetMembershipContactsQueryParams {
  pageSize?: number;
  offset?: number;
  filter?: string;
  orderBy?: string;
}
