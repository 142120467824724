// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CodeContributionsRes {
  countContributions: number;
  countContributors: number;
  countCommits: number;
  countPullrequests: number;
  countIssues: number;
  countCodeReviews: number;
}

export interface CodeContributionsResult {
  code: number;
  message?: string;
  codeContributionsRes: CodeContributionsRes[];
}

export interface NextEventsResult {
  code: number;
  message?: string;
  nextEvents: NextEvent[];
}

export interface NextEvent {
  attendees: number;
  audience: number;
  eventCity: string;
  eventCountry: string;
  eventId: string;
  eventName: string;
  speakers: number;
  sponsorships: number;
  startDate: string;
  status: string;
}

export interface EngagementSummaryResult {
  code: number;
  message?: string;
  engagementSummary: EngagementSummary;
}

export interface EngagementSummary {
  upcomingEvents: EngagementSummaryUpcomingEvents[];
  trainingAndCertifications: EngagementSummaryTrainingAndCertifications[];
  engagementAndTeam: EngagementSummaryEngagementAndTeam[];
  myOrgVsTopContributingOrgs: MyOrgVsTopContributingOrgs[];
}

export interface MyOrgVsTopContributingOrgs {
  accountName: string;
  commitCountAllTime: number;
}

export interface EngagementSummaryUpcomingEvents {
  eventId: string;
  eventName: string;
  eventStartDate: Date;
  eventEndDate: Date;
  eventIsPast: boolean;
  eventUrl: string;
  eventCountry: string;
  eventCity: string;
  registrationCount: number;
  orgRegistrationCount: number;
  speakerCount: number;
  orgSpeakerCount: number;
}

export interface EngagementSummaryTrainingAndCertifications {
  courseId: string;
  courseName: string;
  enrollmentCount: number;
  courseLink: string;
}

export interface EngagementSummaryEngagementAndTeam {
  accountId: string;
  projectId: string;
  year: string;
  month: string;
  quarter: string;
  eventRegistrationCount: number;
  commitCount: number;
  contributorsCount: number;
  maintainersCount: number;
  enrollmentsCount: number;
  certificationsCount: number;
  committeeMembersCount: number;
  keyContactsCount: number;
  sponsorshipEventReachedCount: number;
  meetingsMinuteCount: number;
  subscribersCount: number;
}
