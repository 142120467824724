<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->

<div class="chart-card-container">
  <div class="chart-card-content">
    <div [id]="chartName" [ngClass]="chartName" class="chart"></div>
    <div class="legend-wrap" *ngIf="config?.showLegend">
      <span class="legend-item" (click)="onToggleSeries(index)" [class.turned-off]="!isVisible(index)" *ngFor="let se of config.series; let index = index">
        <span class="legend-icon">
          <span class="circle" *ngIf="!se.stroke" [style]="{ borderColor: se.color, backgroundColor: se.color }"></span>
          <span class="line" [ngClass]="{ 'border-dashed': se.stroke }" [style]="{ borderColor: se.color }"></span>
        </span>
        {{ se.name }}
      </span>
    </div>
  </div>
</div>
