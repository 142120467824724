// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { FoundationFinance } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateFoundationFinanceResult {
  updateFoundationFinance: UpdateFoundationFinancePayload;
}

export interface UpdateFoundationFinancePayload extends MutationPayload {
  foundationFinance: FoundationFinance;
}
