// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';
import { Person, Company, FoundationMemberCompany } from '@models';
import { imagePlaceholders } from '@config';

@Component({
  selector: 'lfx-logo-list',
  templateUrl: './logo-list.component.html',
  styleUrls: ['./logo-list.component.scss'],
})
export class LogoListComponent implements OnInit {
  @Input() items: (Person | Company | FoundationMemberCompany)[];
  @Input() limit: number;

  constructor() {}

  ngOnInit() {}

  getName(item: Person | Company): string {
    if (item.hasOwnProperty('name')) {
      return (item as Company).name;
    } else if (
      item.hasOwnProperty('firstName') &&
      item.hasOwnProperty('lastName')
    ) {
      return `${(item as Person).firstName} ${(item as Person).lastName}`;
    } else {
      throw new Error(`Unable to getName - invalid type '${typeof item}'`);
    }
  }

  getLogo(item: Person | Company): string {
    if (item.hasOwnProperty('logoUrl')) {
      return (item as Company).logoUrl
        ? (item as Company).logoUrl
        : imagePlaceholders.company;
    } else if (item.hasOwnProperty('avatarUrl')) {
      return (item as Person).avatarUrl
        ? (item as Person).avatarUrl
        : imagePlaceholders.user;
    } else {
      throw new Error(`Unable to getLogo - invalid type '${typeof item}'`);
    }
  }

  getTooltipContent() {
    if (this.items && this.items.length > 0) {
      return this.items
        .slice(0, 10)
        .reduce((current, item) => current + item.name + '\n', '');
    }
  }

  getWebsite(item: Person | Company): string {
    if (item.hasOwnProperty('website')) {
      return (item as Company).website;
    }
  }
}
