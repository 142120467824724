<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-mini-card
  [title]="project.name"
  [details]="project.description"
  [logo]="project.logoUrl"
  [icon]="defaultIcon"
  buttonType="success"
  [buttonText]="buttonName"
  (action)="onButtonClick()"
></lfx-mini-card>
