// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  Input,
  OnChanges,
} from '@angular/core';
import { styles } from '@config';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lfx-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarRightComponent implements OnInit, OnChanges {
  @Output() hideMobileRightSidebar = new EventEmitter<boolean>();
  @Input() iframeSrc: string;

  url: SafeResourceUrl;
  styles = styles;

  constructor(private eRef: ElementRef, public sanitizer: DomSanitizer) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideMobileRightSidebar.emit(true);
    }
  }

  ngOnInit() {}
  ngOnChanges(changes) {
    if (changes.iframeSrc && this.iframeSrc) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
    }
  }
}
