// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  TnCList,
  TnCOverview,
  TnCTrainedEmployeesLeaderboard,
  TnCCertifiedEmployeesLeaderboard,
  TrainingAndCertificationSummary,
  TnCGrowth,
  TnCCorporateVsIndividualEnrollment,
  TnCListDetails,
} from '@models';
import { Observable } from 'rxjs';
import { TrainingCertificationsServiceGql } from '../gql';

@Injectable({
  providedIn: 'root',
})
export class TrainingCertificationsService {
  constructor(
    private trainingCertificationsServiceGql: TrainingCertificationsServiceGql
  ) {}

  getTraningAndCertificationSummary(
    organizationId: string,
    dateRange: string
  ): Observable<TrainingAndCertificationSummary> {
    return this.trainingCertificationsServiceGql.getTraningAndCertificationSummary(
      organizationId,
      dateRange
    );
  }

  getTnCOverview(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string
  ): Observable<TnCOverview> {
    return this.trainingCertificationsServiceGql.getTnCOverview(
      organizationId,
      isAllOrgs,
      dateTime
    );
  }

  getTnCGrowth(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string
  ): Observable<TnCGrowth[]> {
    return this.trainingCertificationsServiceGql.getTnCGrowth(
      organizationId,
      isAllOrgs,
      dateTime
    );
  }

  getTnCCorporateVsIndividualEnrollment(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string
  ): Observable<TnCCorporateVsIndividualEnrollment[]> {
    return this.trainingCertificationsServiceGql.getTnCCorporateVsIndividualEnrollment(
      organizationId,
      isAllOrgs,
      dateTime
    );
  }

  getTnCList(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    filter: string
  ): Observable<TnCList[]> {
    return this.trainingCertificationsServiceGql.getTnCList(
      organizationId,
      isAllOrgs,
      dateTime,
      filter
    );
  }

  getTnCListDetails(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    courseGroupId: string,
    page: number,
    limit: number,
    searchName
  ): Observable<TnCListDetails[]> {
    return this.trainingCertificationsServiceGql.getTnCListDetails(
      organizationId,
      isAllOrgs,
      dateTime,
      courseGroupId,
      page,
      limit,
      searchName
    );
  }

  getTnCListDetailsCount(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    courseGroupId: string,
    searchName: string
  ): Observable<number> {
    return this.trainingCertificationsServiceGql.getTnCListDetailsCount(
      organizationId,
      isAllOrgs,
      dateTime,
      courseGroupId,
      searchName
    );
  }

  getTnCTrainedEmployeesLeaderboard(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    page: number,
    limit: number
  ): Observable<TnCTrainedEmployeesLeaderboard[]> {
    return this.trainingCertificationsServiceGql.getTnCTrainedEmployeesLeaderboard(
      organizationId,
      isAllOrgs,
      dateTime,
      page,
      limit
    );
  }

  getTnCTrainedEmployeesLeaderboardCount(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string
  ): Observable<number> {
    return this.trainingCertificationsServiceGql.getTnCTrainedEmployeesLeaderboardCount(
      organizationId,
      isAllOrgs,
      dateTime
    );
  }

  getTnCCertifiedEmployeesLeaderboard(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string,
    page: number,
    limit: number
  ): Observable<TnCCertifiedEmployeesLeaderboard[]> {
    return this.trainingCertificationsServiceGql.getTnCCertifiedEmployeesLeaderboard(
      organizationId,
      isAllOrgs,
      dateTime,
      page,
      limit
    );
  }

  getTnCCertifiedEmployeesLeaderboardCount(
    organizationId: string,
    isAllOrgs: boolean,
    dateTime: string
  ): Observable<number> {
    return this.trainingCertificationsServiceGql.getTnCCertifiedEmployeesLeaderboardCount(
      organizationId,
      isAllOrgs,
      dateTime
    );
  }
}
