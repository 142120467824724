// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import Wordcloud from 'highcharts/modules/wordcloud';

Wordcloud(Highcharts);

@Component({
  selector: 'lfx-word-cloud-chart',
  templateUrl: './word-cloud-chart.component.html',
})
export class WordCloudChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public width = 500;
  @Input() public height = 300;
  @Input() public data;
  public options: Highcharts.Options;
  chart: Highcharts.Chart;
  chartElement: HTMLElement;

  public constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef
  ) {}

  public ngOnChanges(): void {
    this.options = {};

    if (this.data.length > 0) {
      this.options = {
        chart: {
          backgroundColor: '#fafafa',
          height: this.height,
          width: this.width,
        },
        series: [
          {
            type: 'wordcloud',
            data: this.data,
            name: 'Value',
            rotation: {
              from: 0,
              to: 0,
            },
            minFontSize: 10,
            maxFontSize: 25,
          },
        ],
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
      };

      this.drawChart();
    }
  }

  public ngOnInit(): void {
    this.chartElement = this.renderer2.createElement('chart');
    this.chartElement.id = 'chart' + Math.random();
    this.renderer2.appendChild(
      this.elementRef.nativeElement,
      this.chartElement
    );
    this.drawChart();
  }

  public getHeight(): number {
    return this.height;
  }

  public drawChart(): void {
    if (this.chartElement) {
      this.chart = Highcharts.chart(this.chartElement.id, this.options);

      this.chart.update(this.options);
      this.chart.redraw();
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    this.renderer2.removeChild(
      this.elementRef.nativeElement,
      this.chartElement
    );
  }
}
