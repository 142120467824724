// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface SecurityDependencyRow {
  dependencyId: string;
  name: string;
  version: string;
  issueHighCount: number;
  issueMediumCount: number;
  issueLowCount: number;
  licenses: any;
  parentDependencyId: string;
  treeStatus: string;
  repoName: string;
}
