// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(value: any): any {
    const d = new Date(new Date(0).setUTCSeconds(value));

    return moment(d).format('MM/DD/YYYY hh:mm a');
  }
}
