<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<!-- Left Arrow -->
<a
  *ngIf="hasPrevious()"
  class="carousel-control-prev"
  role="button"
  (click)="selectPage(page-1);"
>
  <span class="page-control-prev-icon"><i class="fas fa-chevron-left"></i></span>
</a>

<!-- Right Arrow  -->
<a
  *ngIf="hasNext()" 
  class="carousel-control-next"
  role="button"
  (click)="selectPage(page+1);"
>
  <span class="page-control-next-icon"><i class="fas fa-chevron-right"></i></span>
</a>

