// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { PipeTransform, Pipe } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  constructor() {}
  transform(val: string, args: number): string {
    if (isNullOrUndefined(args)) {
      return val;
    }

    if (val.length > args) {
      return val.substring(0, args) + '...';
    }

    return val;
  }
}
