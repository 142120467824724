// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';
import { CommunityInitiative } from '@models';

@Component({
  selector: 'lfx-crowdfunding-list-card',
  templateUrl: './crowdfunding-list-card.component.html',
  styleUrls: ['./crowdfunding-list-card.component.scss'],
})
export class CrowdfundingListCardComponent implements OnInit {
  @Input() initiative: CommunityInitiative;

  constructor() {}

  ngOnInit() {}
}
