<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ng-template #documentContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{modalTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <pdf-viewer
        [src]="documentSource"
        [render-text]="true"
        [fit-to-page]="true"
        style="display: block;"></pdf-viewer>
    </div>
    <div class="modal-footer" *ngIf="showModalFooter">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{modalCloseText}}</button>
    </div>
</ng-template>

<button *ngIf="showModalButton; else showIcon" class="btn btn-outline-primary mb-2 mr-2"
    (click)="openScrollableContent(documentContent)">
    {{modalButtonText}}
</button>

<ng-template #showIcon>
  <span (click)="openScrollableContent(documentContent)"> <ng-content ></ng-content></span>
</ng-template>

