// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ViewsEventsSummary {
  myOrgEvents: number;
  allEvents: number;
  myOrgEmployeesEngaged: number;
  myOrgTotalSpeakers: number;
  myOrgTotalPresentations: number;
  eventsSpokenAt: number;
  totalAudienceReached: number;
  myOrgTotalAttendees: number;
  myOrgEventsAttended: number;
}

export interface EventsInput {
  organizationId: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

export interface EventsTableData {
  attendeesFromMyOrg: EventsTableDetails[];
  speakersFromMyOrg: EventsTableDetails[];
  speakersAccrossAllOrg: EventsTableDetails[];
}

export interface EventsTableDetails {
  eventsCount: number;
  firstName: string;
  lastName: string;
  logo: string;
  contactId: string;
}

export interface CompanyEventAttendeeSummary {
  city: string;
  contactId: string;
  country: string;
  email: string;
  firstName: string;
  gender: string;
  jobFunction: string;
  jobLevel: string;
  lastName: string;
  logo: string;
  state: string;
  title: string;
  totalEventsAttended: number;
  events: AttendeeEvent[];
  eventsCount: number;
}

export interface CompanyEventSpeakerSummary {
  city: string;
  contactId: string;
  country: string;
  email: string;
  firstName: string;
  gender: string;
  jobFunction: string;
  jobLevel: string;
  lastName: string;
  logo: string;
  state: string;
  title: string;
  totalEventsSpokenAt: number;
  events: SpeakerEvent[];
  eventsCount: number;
}

export interface AttendeeEvent {
  id: string;
  name: string;
  startDate: string;
}

export interface SpeakerEvent extends AttendeeEvent {
  presentations: number;
}

export interface AttendeeByJobFunction {
  counts: number;
  jobfunction: string;
}

export interface AttendeeByJobLevel {
  count: number;
  joblevel: string;
}

export interface AttendeeByGeography {
  counts: number;
  country: string;
}

export interface AttendeeByGender {
  count: number;
  gender: string;
}

export interface AttendeeDiversityChart {
  byJobFunction: AttendeeByJobFunction[];
  byJobLevel: AttendeeByJobLevel[];
  byGeography: AttendeeByGeography[];
  byGender: AttendeeByGender[];
}

export interface DashboardOrgUserEvents {
  name: string;
  image: string;
  email: string;
  eventName: string;
  startDate: string;
}

export interface SelectedSpeaker {
  name: {
    image: string;
    text: string;
  };
  email: string;
  events: {
    name: {
      text: string;
      subtext: string;
    };
  }[];
}

export interface EventViewDetailSummaryInput {
  type: 'Attendee' | 'Speaker';
  userId: string;
  organizationId: string;
  year: 'alltime' | string;
}

export interface EventViewDetailsSummary {
  email: string;
  eventId: string;
  eventName: string;
  logoUrl: string;
  eventStartdate: string;
}

export interface DashboardEventsData {
  attendees: number;
  speakers: number;
  funding: number;
  sponsorships: number;
  audienceReach: number;
  nextEvents: {
    name: string;
    date: string;
    location: string;
    attendees: number;
    speakers: number;
    sponsorship: number;
    audienceReach: number;
  }[];
}

export interface CommonEventsPageInput {
  organizationId: string;
  dateRange: string;
  isAllOrgs: boolean;
  projectId: string;
}

export interface EventsOverviewStats {
  registrations: number;
  attendees: number;
  eventsAttended: number;
  eventsSpokenAt: number;
  speakers: number;
}

export interface EventsOverviewEventsListInput extends CommonEventsPageInput {
  pastEvents: boolean;
  allAvailable: boolean;
}

export interface EventsOverviewEventsList {
  counts: {
    allCnt: number;
    pastCnt: number;
    upcomingCnt: number;
  };
  list: {
    eventId: string;
    eventName: string;
    eventUrl: string;
    eventCity: string;
    eventCountry: string;
    eventStartDate: string;
    registrationCount: number;
    speakerProposalCount: number;
    sponsorshipExists: string;
    allOrgRegistrantsCount: number;
    myOrgRegistrantsCount: number;
    acceptedSpeakerProposals: number;
  }[];
}

export interface EventsLeaderboardInput extends CommonEventsPageInput {
  leaderBoardType: 'speakers' | 'attendees';
}

export interface EventsLeaderboard {
  userId: string;
  userName: string;
  userFullName: string;
  userEmail: string;
  userTitle: string;
  userGender: string;
  userPhotoUrl: string;
  organizationLogo: string;
  eventsAttended: number;
  eventsSpokenAt: number;
  accountName: string;
}

export interface EventRegistrationDetailsInput extends CommonEventsPageInput {
  eventId: string;
  eventRegistrationType?: 'Virtual' | 'In Person';
}

export interface EventRegistrationDetails {
  list: {
    registrationId: string;
    userFullName: string;
    userPhotoUrl: string;
    registrationTs: string;
    eventRegistrationType: string;
    organizationLogo: string;
    accountName: string;
    userId: string;
  }[];
  counts: {
    allCnt: number;
    virtualCnt: number;
    inPersonCnt: number;
  };
}

export interface EventsAttendeesGrowthInput {
  organizationId: string;
  year: string;
  isAllOrgs: boolean;
  projectId?: string;
}

export interface EventsAttendeesGrowth {
  year: number;
  month: number;
  spanDate: string;
  attendeesCount: number;
  isPersonCount: number;
  virtualCount: number;
}

export interface EventsTravelFunding {
  counts: {
    totalAmount: number;
  };
  list: {
    eventId: string;
    eventName: string;
    eventYear: string;
    approvedAmount: number;
    recepientCount: number;
    accountName: string;
  }[];
}

export interface EventsSponsorship {
  eventId: string;
  eventName: string;
  eventYear: string;
  submitterName: string;
  eventStartDate: string;
  createdTs: string;
  sponsorshipTier: string;
  accountName: string;
  audienceReached: number;
}

export interface eventsProjectsList {
  foundationId: string;
  projectId: string;
  projectName: string;
  projectType: string;
}
