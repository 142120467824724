// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsOverviewStatsQuery = gql`
  query EventsOverviewStats($input: EventsOverviewStatsInput) {
    eventsOverviewStats(input: $input) {
      registrations
      attendees
      eventsAttended
      eventsSpokenAt
      speakers
    }
  }
`;

export const eventsOverviewEventsListQuery = gql`
  query EventsOverviewEventsList($input: EventsOverviewEventsListInput) {
    eventsOverviewEventsList(input: $input) {
      counts {
        allCnt
        pastCnt
        upcomingCnt
      }
      list {
        eventId
        eventName
        eventUrl
        eventStartDate
        registrationCount
        speakerProposalCount
        sponsorshipExists
      }
    }
  }
`;

export const eventsLeaderboardQuery = gql`
  query EventsLeaderboard($input: EventsLeaderboardInput) {
    eventsLeaderboard(input: $input) {
      userId
      userName
      userFullName
      userEmail
      userTitle
      userGender
      userPhotoUrl
      organizationLogo
      eventsAttended
      eventsSpokenAt
      accountName
    }
  }
`;

export const eventRegistrationDetailsQuery = gql`
  query EventRegistrationDetails($input: EventRegistrationDetailsInput) {
    eventRegistrationDetails(input: $input) {
      list {
        registrationId
        userFullName
        userPhotoUrl
        registrationTs
        eventRegistrationType
        organizationLogo
        accountName
        userId
      }
      counts {
        allCnt
        virtualCnt
        inPersonCnt
      }
    }
  }
`;

export const eventsAttendeesGrowthQuery = gql`
  query EventsAttendeesGrowth($input: EventsAttendeesGrowthInput) {
    eventsAttendeesGrowth(input: $input) {
      year
      month
      spanDate
      attendeesCount
      isPersonCount
      virtualCount
    }
  }
`;
