// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { GaugeVisualization } from '@lfx/core/models';
import { Gauge } from 'gaugeJS/dist/gauge.min.js';

@Component({
  selector: 'lfx-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas: ElementRef;
  @ViewChild('chartTitle') chartTitle: ElementRef;
  @Input() visualizationData: GaugeVisualization;
  gauge;
  any;
  opts: any = {
    angle: 0, // The span of the gauge arc
    lineWidth: 0.2, // The line thickness
    radiusScale: 0.9, // Relative radius
    pointer: {
      length: 0.6, // // Relative to gauge radius
      strokeWidth: 0.035, // The thickness
      color: '#000000', // Fill color
    },
    limitMax: false, // If false, max value increases automatically if value > maxValue
    limitMin: false, // If true, the min value of the gauge will be fixed
    highDpiSupport: true, // High resolution support
  };

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    if (this.gauge) {
      this.drawGauge();
    }
  }

  ngAfterViewInit(): void {
    this.drawGauge();
  }

  private drawGauge() {
    this.opts.staticZones = this.visualizationData.zones;
    const max = this.visualizationData.max || 100;
    const labels = [];

    for (
      let current = 0;
      current <= max;
      current += max / ((this.visualizationData.labelsDivision || 5) - 1)
    ) {
      labels.push(Math.round(current));
    }

    this.opts.staticLabels = {
      labels,
    };

    if (!this.gauge) {
      this.gauge = new Gauge(this.chartCanvas.nativeElement).setOptions(
        this.opts
      );
      this.gauge.animationSpeed = 35; // set animation speed (32 is default value)
      this.gauge.setTextField(this.chartTitle.nativeElement);
      this.gauge.maxValue = this.visualizationData.max || 100; // set max gauge value
      this.gauge.setMinValue(this.visualizationData.min || 0); // Prefer setter over gauge.minValue = 0
    } else {
      this.gauge.setOptions(this.opts);
    }

    this.gauge.set(this.visualizationData.value); // set actual value
  }
}
