// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { BaseFoundationProject } from '@lfx/core/gql/services/inputs/base-foundation-project';

export interface FoundationProjectVulnerabilityInput
  extends BaseFoundationProject {
  offset: number;
  sortOrder: string;
  orderBy: string;
  limit: number;
}
