// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ActivityLog {
  activityType: string;
  createdAt: Date;
  message: string;
  tip: string;
  id: string;
  name: string;
  logoUrl: string;
}
