<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div class="chart-card-container">
  <div class="chart-card-content">
    <div [id]="chartName" [ngClass]="chartName" class="chart"></div>
    <div class="legend-wrap" *ngIf="config?.showLegend">
      <span class="legend-item" (click)="onToggleSeries(index)" *ngFor="let se of config.series; let index = index" [class.turned-off]="!isVisible(index)">
        <span class="legend-icon" [class.line-legend]="se.plotAsLineChart">
          <span class="circle" [style]="{ borderColor: se.color, backgroundColor: se.color }"></span>
          <span class="line" [style]="{ borderColor: se.color }" *ngIf="se.plotAsLineChart"></span>
        </span>
        {{ se.name }}
      </span>
    </div>
  </div>
</div>
