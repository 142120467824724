<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="row">
    <div class="col-auto">
        <a *ngIf="hasPrevious()" class="carousel-control-prev" role="button" (click)="selectPage(page-1);">
            <span class="page-control-prev-icon"><i class="fas fa-chevron-left"></i></span>
        </a>
    </div>

    <div class="col">
        <ng-content select="[pagination-content]"></ng-content>
    </div>

    <div class="col-auto">
        <a *ngIf="hasNext()" class="carousel-control-next" role="button" (click)="selectPage(page+1);">
            <span class="page-control-next-icon"><i class="fas fa-chevron-right"></i></span>
        </a>
    </div>
</div>
