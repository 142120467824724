// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectContributionsListQuery = gql`
  query ProjectContributionList($organizationId: ID!, $isAllOrgs: Boolean) {
    projectContributionList(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
    ) {
      code
      message
      projectContributionList {
        projectId
        projectName
        membershipStatus
        commitCountAllTime
        projectType
        foundationId
        pkid
        accountId
        managerName
        managerEmail
      }
    }
  }
`;
