// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface TopTenCoursesCompleted {
  similarSizeCompletedCoursesFromMyOrg: number[];
  similarIndustryCompletedCoursesFromMyOrg: number[];
  similarSize: number[];
  similarIndustry: number[];
  similarSizeChartLabels: string[];
  similarIndustryChartLabels: string[];
}
