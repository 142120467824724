// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CompanyClaManagers {
  approvedOn: string;
  email: string;
  lfUsername: string;
  logoUrl: string;
  name: string;
  projectId: string;
  projectLogoUrl?: string;
  projectName: string;
  projectSfid: string;
  userSfid: string;
}
