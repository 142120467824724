// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { FoundationProjectVulnerability, FoundationMemberCompany } from '.';
import { DevAnalytics } from './dev-analytics';
import { FoundationLegal } from './foundation-legal';
import { FoundationProject } from './foundation-project';
import {
  FoundationMember,
  FoundationMembershipTier,
} from './foundation-membership';
import { FoundationFinance } from './foundation-finance';
import { UserGroup } from './foundation-governance';
import { Owner } from './owner';
import { ProjectSecuritySummary } from './security';

export interface Foundation {
  id: string;
  name: string;
  displayName?: string;
  description: string;
  logoUrl: string; // URL points to logo of foundation
  colorBand: string; // This is a color for the foundation band
  tag?: string;
  slug?: string;
  website?: string;
  autoJoinEnabled: boolean;
  members?: FoundationMemberCompany[];
  community: {
    contributors: number;
    commits: number;
    members: FoundationMember[];
  };
  organization: {
    contributors: number;
    commits: number;
    totalCodeContribution: number; // This is a percentage. So simply this number will be added beside % in the UI. Ex: 5%
  };
  parent?: string;
  projectType?: string;
  securitySummary?: ProjectSecuritySummary;
  projects: FoundationProject[];
  creationDate: Date;
  devAnalytics: DevAnalytics;
  vulnerability: FoundationProjectVulnerability;
  lastUpdated: Date;
  legal: FoundationLegal;
  finance: FoundationFinance;
  status: string;
  projectSector: string;
  foundationMember: boolean;
  committees: UserGroup[];
  prorated: boolean;
  autoRenew: boolean;
  membershipTerm: string;
  paymentFrequency: string;
  tiers: FoundationMembershipTier[];
  owner: Owner;
  opportunityOwner: Owner;
  programManager: Owner;
  executiveDirector: Owner;
  contributionsData: ContributionData;
  type: string;
  foundation: Pick<Foundation, 'name' | 'id'>;
  insightsLink: string;
}

interface ContributionData {
  developers: CommitsData[];
  projects: CommitsData[];
  organizations: CommitsData[];
  organizationsOtherCommits: number;
}

interface CommitsData {
  name: string;
  commits: number;
}
