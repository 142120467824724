// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { userTechnicalRow } from '@lfx/core/gql';
import { chartConfig } from './line-echarts.features';

@Component({
  selector: 'lfx-line-echarts',
  templateUrl: './line-echarts.component.html',
  styleUrls: ['./line-echarts.component.scss'],
})
export class LineEchartsComponent implements OnChanges {
  @Input() yAxisName = '# OF CONTRIBUTIONS';
  @Input() chartData: userTechnicalRow[] = [];
  @Input() fnTableRowsMap = () => ({});

  chartOption = {};

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    const hasData = changes.chartData.currentValue.length > 0;

    if (hasData) {
      this.chartOption = chartConfig({
        chartData: this.chartData,
        fnTableRowsMap: this.fnTableRowsMap,
        yAxisName: this.yAxisName,
      });
    }
  }
}
