// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-insights-statistics-circle',
  templateUrl: './insights-statistics-circle.component.html',
  styleUrls: ['./insights-statistics-circle.component.scss'],
})
export class InsightsStatisticsCircleComponent implements OnInit {
  @Input() value: number;
  @Input() description: string;

  constructor() {}

  ngOnInit(): void {}
}
