// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { EventService } from '../../../core/services/events.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { EventViewDetailSummaryInput, generalConstants } from '@lfx/config';
import { CompanyService } from '@lfx/core/services';
import { Column, DataRow } from '@lfx/shared/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { uniqBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'lfx-events-details-speakers-table',
  templateUrl: './events-details-speakers-table.component.html',
  styleUrls: ['./events-details-speakers-table.component.scss'],
})
export class EventsDetailsSpeakersTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('viewDetails', { static: true }) viewDetails: TemplateRef<any>;
  @Input() company;
  @Input() dateRange;
  @Input() pageType;
  @Input() allOrgsTopSpeakers;
  @Output() viewEmployeeProfile = new EventEmitter<any>();
  endDate = new Date().toDateString();
  startDate = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000 * 365
  ).toDateString();

  paginationSize = 10;
  paginationPage = 1;
  paginationTotal = 0;
  paginationMaxSize = 5;
  paginationOffset: number = (this.paginationPage - 1) * this.paginationSize;
  cascadeTableColumns: Column[] = [];
  cascadeTableData1: DataRow[] = [];
  cascadeTableData2: DataRow[] = [];
  dataSubscription = new Subscription();
  emptyView = false;
  isLoading = false;
  data = [];
  slicedData1 = [];
  slicedData2 = [];
  myOrgSpeakers = generalConstants.myOrgSpeakers;
  allOrgsSpeakers = generalConstants.allOrgsSpeakers;
  myOrgAttendees = generalConstants.myOrgAttendees;
  tncLearners = generalConstants.tncLearners;
  selectedRow: {
    id: string;
    firstName: string;
    lastName: string;
    logo: string;
    email: string;
    learner: {
      id: string;
      photoUrl: string;
      firstName: string;
      lastName: string;
    };
    name: {
      image: string;
      text: string;
    };
  };

  detailsList: {
    name: string;
  }[];

  dateRangeText = '';
  loadingEventIndicator = false;

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private eventService: EventService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRange) {
      this.getData();
      this.dateRangeText = this.eventService.getDateRangeText(this.dateRange);
    }

    if (changes.pageType) {
      this.initializeTableColumns();
      this.getData();
    }

    if (changes.allOrgsTopSpeakers) {
      this.getData();
    }
  }

  initializeTableColumns() {
    if (this.pageType === generalConstants.myOrgSpeakers) {
      this.initializeMyOrgSpeakersColumns();

      return;
    }

    if (this.pageType === generalConstants.allOrgsSpeakers) {
      this.initializeAllOrgsSpeakersColumns();

      return;
    }

    if (this.pageType === generalConstants.myOrgAttendees) {
      this.initializeAttendeesColumns();

      return;
    }

    if (this.pageType === generalConstants.tncLearners) {
      this.initializeLearnersColumns();

      return;
    }
  }

  initializeMyOrgSpeakersColumns() {
    this.cascadeTableColumns = [
      {
        name: 'Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 25,
        dataFontSize: '12px',
        titlecase: true,
        clickableText: true,
      },
      {
        name: 'Events',
        prop: 'events',
      },
      {
        name: '',
        prop: 'details',
        template: this.viewDetails,
        flex: 1,
      },
    ];
  }

  initializeAllOrgsSpeakersColumns() {
    this.cascadeTableColumns = [
      {
        name: 'Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 25,
        dataFontSize: '12px',
        titlecase: true,
        clickableText: false,
      },
      {
        name: 'Events',
        prop: 'events',
      },
      {
        name: '',
        prop: 'details',
        template: this.viewDetails,
        flex: 1,
      },
    ];
  }

  initializeAttendeesColumns() {
    this.cascadeTableColumns = [
      {
        name: 'Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 25,
        dataFontSize: '12px',
        titlecase: true,
        clickableText: true,
      },
      {
        name: 'Job Title',
        prop: 'title',
        flex: 2,
        textEllipsis: 30,
      },
      {
        name: 'Events',
        prop: 'events',
      },
      {
        name: '',
        prop: 'details',
        template: this.viewDetails,
        flex: 1,
      },
    ];
  }

  initializeLearnersColumns() {
    this.cascadeTableColumns = [
      {
        name: 'Employee Name',
        prop: 'name',
        textEllipsis: 25,
        dataFontSize: '12px',
        titlecase: true,
        clickableText: true,
      },
      {
        name: 'Total courses enrolled',
        prop: 'enrolled',
        dataFontSize: '11px',
      },
      {
        name: '',
        prop: 'details',
        template: this.viewDetails,
        flex: 1,
      },
    ];
  }

  getData() {
    this.spinner.show('events-speakers-table-spinner');
    this.isLoading = true;
    this.emptyView = false;

    if (this.pageType === generalConstants.myOrgSpeakers) {
      this.dataSubscription = this.eventService
        .getSpeakersFromMyOrganizationTableData(this.company.id, this.dateRange)
        .subscribe(speakers => {
          this.spinner.hide('events-speakers-table-spinner');
          this.isLoading = false;

          if (speakers && speakers.length > 0) {
            const uniqueSpeakers = uniqBy(speakers, 'contactId');

            this.data = uniqueSpeakers;
            this.paginationTotal = this.data.length;
            this.onPageChange(1);
          } else {
            this.emptyView = true;
          }
        });
    } else if (this.pageType === generalConstants.myOrgAttendees) {
      this.dataSubscription = this.eventService
        .getAttendeesFromMyOrganizationTableData(
          this.company.id,
          this.dateRange
        )
        .subscribe(attendees => {
          this.spinner.hide('events-speakers-table-spinner');
          this.isLoading = false;

          if (attendees && attendees.length > 0) {
            const uniqueAttendees = uniqBy(attendees, 'contactId');

            this.data = uniqueAttendees;
            this.paginationTotal = this.data.length;
            this.onPageChange(1);
          } else {
            this.emptyView = true;
          }
        });
    } else if (this.pageType === generalConstants.allOrgsSpeakers) {
      this.spinner.hide('events-speakers-table-spinner');
      this.isLoading = false;

      if (this.allOrgsTopSpeakers && this.allOrgsTopSpeakers.length > 0) {
        this.data = this.allOrgsTopSpeakers.slice(0, 100);
        this.paginationTotal = this.data.length;
        this.onPageChange(1);
      } else {
        this.emptyView = true;
      }
    } else if (this.pageType === generalConstants.tncLearners) {
      this.dataSubscription = this.companyService
        .getTrainingAndCertificationLearners(
          this.company.id,
          this.dateRange,
          50,
          'CombinedTrainingStats.Enrollments',
          'desc',
          0,
          'CombinedTrainingStats.Enrollments neq 0'
        )
        .subscribe(learners => {
          this.spinner.hide('events-speakers-table-spinner');
          this.isLoading = false;

          if (learners && learners.data && learners.data.length > 0) {
            this.data = learners.data;
            this.paginationTotal = this.data.length;
            this.onPageChange(1);
          } else {
            this.emptyView = true;
          }
        });
    }
  }

  handleData() {
    this.cascadeTableData1 = [];
    this.cascadeTableData2 = [];

    if (this.pageType === generalConstants.myOrgSpeakers) {
      this.handleMyOrgSpeakersData();

      return;
    }

    if (this.pageType === generalConstants.allOrgsSpeakers) {
      this.handleAllOrgsSpeakersData();

      return;
    }

    if (this.pageType === generalConstants.myOrgAttendees) {
      this.handleMyOrgAttendeesData();

      return;
    }

    if (this.pageType === generalConstants.tncLearners) {
      this.handleTncLearnersData();

      return;
    }
  }

  handleTncLearnersData() {
    for (const speaker of this.slicedData1) {
      this.cascadeTableData1.push({
        name: this.formatNameAndLogo(
          speaker.learner.firstName + ' ' + speaker.learner.lastName,
          speaker.learner.photoUrl
        ),
        contactId: speaker.contactId,
        email: speaker.email,
        enrolled: this.formatEvents(speaker.combinedTrainingStats.enrollments),
        userId: speaker.learner.id,
        speaker,
      });
    }

    for (const speaker of this.slicedData2) {
      this.cascadeTableData2.push({
        name: this.formatNameAndLogo(
          speaker.learner.firstName + ' ' + speaker.learner.lastName,
          speaker.learner.photoUrl
        ),
        contactId: speaker.contactId,
        email: speaker.email,
        enrolled: this.formatEvents(speaker.combinedTrainingStats.enrollments),
        userId: speaker.learner.id,
        speaker,
      });
    }
  }

  handleMyOrgSpeakersData() {
    for (const speaker of this.slicedData1) {
      this.cascadeTableData1.push({
        name: this.formatNameAndLogo(
          speaker.firstName + ' ' + speaker.lastName,
          speaker.logo
        ),
        contactId: speaker.contactId,
        email: speaker.email,
        events: this.formatEvents(
          speaker.totalEventsSpokenAt || speaker.eventsCount
        ),
        speaker,
      });
    }

    for (const speaker of this.slicedData2) {
      this.cascadeTableData2.push({
        name: this.formatNameAndLogo(
          speaker.firstName + ' ' + speaker.lastName,
          speaker.logo
        ),
        contactId: speaker.contactId,
        email: speaker.email,
        events: this.formatEvents(
          speaker.totalEventsSpokenAt || speaker.eventsCount
        ),
        speaker,
      });
    }
  }

  handleAllOrgsSpeakersData() {
    for (const speaker of this.slicedData1) {
      this.cascadeTableData1.push({
        name: this.formatNameAndLogo(speaker.name, speaker.logo),
        events: this.formatEvents(speaker.events),
        contactId: speaker.contactId,
        email: speaker.email,
        speaker,
      });
    }

    for (const speaker of this.slicedData2) {
      this.cascadeTableData2.push({
        name: this.formatNameAndLogo(speaker.name, speaker.logo),
        events: this.formatEvents(speaker.events),
        contactId: speaker.contactId,
        email: speaker.email,
        speaker,
      });
    }
  }

  handleMyOrgAttendeesData() {
    for (const speaker of this.slicedData1) {
      this.cascadeTableData1.push({
        name: this.formatNameAndLogo(
          speaker.firstName + ' ' + speaker.lastName,
          speaker.logo
        ),
        title: speaker.title,
        events: this.formatEvents(
          speaker.totalEventsAttended || speaker.eventsCount
        ),
        contactId: speaker.contactId,
        email: speaker.email,
        speaker,
      });
    }

    for (const speaker of this.slicedData2) {
      this.cascadeTableData2.push({
        name: this.formatNameAndLogo(
          speaker.firstName + ' ' + speaker.lastName,
          speaker.logo
        ),
        title: speaker.title,
        events: this.formatEvents(
          speaker.totalEventsAttended || speaker.eventsCount
        ),
        contactId: speaker.contactId,
        email: speaker.email,
        speaker,
      });
    }
  }

  formatNameAndLogo(name, logo) {
    return {
      text: name,
      color: 'black',
      bold: true,
      image: logo || generalConstants.mockAvatar,
    };
  }

  formatEvents(events) {
    return {
      text: events ? events : '0',
      color: 'black',
      bold: true,
    };
  }

  onPageChange(event) {
    this.paginationPage = event;
    this.paginationOffset = (this.paginationPage - 1) * this.paginationSize;
    const dataToShow = this.data.slice(
      this.paginationOffset,
      this.paginationOffset + 10
    );

    this.slicedData1 = dataToShow.filter((value, index) => index % 2 === 0);
    this.slicedData2 = dataToShow.filter((value, index) => index % 2 !== 0);
    this.handleData();
  }

  viewDetailsModal(detailsModal, row) {
    this.selectedRow = null;
    this.detailsList = null;
    this.modalService.open(detailsModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    const allData = this.slicedData1.concat(this.slicedData2);

    if (
      this.pageType === this.myOrgSpeakers ||
      this.pageType === this.myOrgAttendees
    ) {
      const input: EventViewDetailSummaryInput = {
        organizationId: this.company.id,
        type: this.pageType === this.myOrgAttendees ? 'Attendee' : 'Speaker',
        userId: row.contactId,
        year: this.dateRange,
      };

      const convertDateToTargetFormat = (date: string): string => {
        const dateObj: Date = new Date(date);
        const targetFormat: string = dateObj.toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          timeZone: 'UTC',
        });

        return targetFormat;
      };

      this.loadingEventIndicator = true;
      this.eventService
        .getEventViewDetailsSummary({ input })
        .pipe(tap(() => (this.loadingEventIndicator = false)))
        .subscribe(details => {
          this.detailsList = details.map(v => ({
            name: v.eventName,
            startDate: convertDateToTargetFormat(v.eventStartdate),
          }));
        });

      for (const record of allData) {
        if (
          record.firstName + ' ' + record.lastName === row.name.text &&
          record.logo === row.name.image
        ) {
          this.selectedRow = record;
        }
      }
    } else if (this.pageType === this.allOrgsSpeakers) {
      for (const record of allData) {
        if (record.name === row.name.text && record.logo === row.name.image) {
          this.eventService
            .getAllOrgEventSpeakerDetail(record.contactId, this.dateRange)
            .pipe(take(1))
            .subscribe(list => {
              this.selectedRow = record;
              this.detailsList = list;
            });
        }
      }
    } else if (this.pageType === this.tncLearners) {
      for (const record of allData) {
        if (record.learner.id === row.userId) {
          this.selectedRow = record;
        }
      }
    }
  }

  closeDialog() {
    this.modalService.dismissAll();
  }

  onUserClick(event) {
    this.viewEmployeeProfile.emit(event);
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
