// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { MutationPayload } from './mutation-payload';

export interface DeleteFoundationCommitteeResult {
  deleteCommittee: DeleteCommitteePayload;
}

export interface DeleteCommitteePayload extends MutationPayload {
  _empty?: boolean; // An empty(dummy) field since the keeping the interface empty raises a warning.
}
