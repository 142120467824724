// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { responseCodes } from '@config';

export interface MutationResponse<T> {
  code: responseCodes;
  message: string;
  success: boolean;
  payload?: T;
}
