// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lfx-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss'],
})
export class AcknowledgementComponent implements OnInit {
  @Input() titleMessage = 'test Message';
  @Input() detailsMessage = 'test Message';
  @Input() icon = 'fas fa-check-circle';

  @Output() closeAction = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  close() {
    this.closeAction.emit();
  }
}
