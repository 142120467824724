// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface SecurityIssues {
  cveIdentifiers: [string];
  cweIdentifiers: [string];
  dependencyId: string;
  dependencyName: string;
  overview: string;
  references: string;
  remediation: string;
  severity: string;
  snykId: string;
  repositoryId: string;
  status: string;
  title: string;
}
