// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  EChartsOption,
  SeriesOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
  LineSeriesOption,
  BarSeriesOption,
} from 'echarts';
import {
  WaterfallChartOptions,
  WaterfallChartOptionsBar,
  WaterfallChartOptionsBarData,
  WaterfallChartOptionsBarDataSingle,
  WaterfallChartOutput,
} from '@lfx/shared/interfaces';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

type ZRLineType = 'solid' | 'dotted' | 'dashed' | number | number[];

@Component({
  selector: 'lfx-waterfall-chart',
  templateUrl: './waterfall-chart.component.html',
  styleUrls: ['./waterfall-chart.component.scss'],
})
export class WaterfallChartComponent implements OnInit, OnDestroy {
  @Output() waterfallAction = new EventEmitter<WaterfallChartOutput>();
  @Input() set aggregateData(aggregatedData) {
    this.isAggregateData$.next(aggregatedData);
  }

  @Input() set withEndingColumn(withEndingColumn) {
    this.isWithEndingColumn$.next(withEndingColumn);
  }

  @Input() set stacked(isStacked) {
    this.isStacked$.next(isStacked);
  }

  @Input() set single(isSingle) {
    this.isSingle$.next(isSingle);
  }

  @Input()
  set chartOptions(chartOptions: WaterfallChartOptions) {
    this.chartOptions$.next(chartOptions);
  }

  subscription$: Subscription;
  chartOptions$ = new BehaviorSubject(null);
  isStacked$ = new BehaviorSubject(false);
  isSingle$ = new BehaviorSubject(false);
  isWithEndingColumn$ = new BehaviorSubject(true);
  isAggregateData$ = new BehaviorSubject(true);
  echartsWaterFallOption: EChartsOption = {};
  _chartOptions: WaterfallChartOptions;
  isStacked = false;
  isSingle = false;
  isWithEndingColumn = true;
  isAggregateData = true;
  constructor() {}

  ngOnInit() {
    this.subscription$ = combineLatest([
      this.chartOptions$,
      this.isStacked$,
      this.isSingle$,
      this.isAggregateData$,
      this.isWithEndingColumn$,
    ]).subscribe(
      ([
        chartOptions,
        isStacked,
        isSingle,
        isAggregateData,
        isWithEndingColumn,
      ]) => {
        this._chartOptions = chartOptions;
        this.isSingle = isSingle;
        this.isStacked = isStacked;
        this.isWithEndingColumn = isWithEndingColumn;
        this.isAggregateData = isAggregateData;

        if (!chartOptions) {
          return;
        }
        this.initChartOptions(this._chartOptions);
      }
    );
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  @HostListener('window:waterfall-tooltip-action-clicked', ['$event.detail'])
  updateNodes(value) {
    this.waterfallAction.emit({
      type: this._chartOptions.tooltipAction.title,
      value,
    });
  }

  get chartTitle() {
    return this._chartOptions.title;
  }

  private initChartOptions(chartOptions: WaterfallChartOptions) {
    this.echartsWaterFallOption = {
      tooltip: this.getTooltip(chartOptions),
      grid: {
        left: '6.5%',
        right: '3%',
        top: '10%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: this.getXAxis(chartOptions.labels),
      yAxis: this.getYAxis(),
      series: this.getSeries(chartOptions),
    };
  }

  private getTooltip(
    chartOptions: WaterfallChartOptions
  ): TooltipComponentOption {
    return {
      trigger: 'axis',
      enterable: true,
      className: 'waterfall-tooltip-wrapper',
      position: (point, params, dom, rect, size) => {
        const obj = { top: '10%' };

        if (point[0] + size.contentSize[0] > size.viewSize[0]) {
          obj['right'] = +(size.viewSize[0] - point[0]);
        } else {
          obj['left'] = point[0];
        }

        return obj;
      },
      formatter: params => {
        const label = params[0].name;
        const bar1Name = chartOptions.bar1.title;
        let bar1Value = params[0] ? params[0].value[2] : 0;
        const bar1Color = chartOptions.bar1.color;
        const bar2Name = chartOptions.bar2 ? chartOptions.bar2.title : '';
        let bar2Value = params[1] ? params[1].value[2] : 0;
        const bar2Color = chartOptions.bar2 ? chartOptions.bar2.color : '';

        if (bar1Name !== params[0].seriesName) {
          [bar1Value, bar2Value] = [bar2Value, bar1Value];
        }

        bar1Value = bar1Value === '-' ? 0 : bar1Value;
        bar2Value = bar2Value === '-' ? 0 : bar2Value;
        let result = `
            <div style="font-size: 12px; font-family: 'Open Sans'; margin-bottom: 15px"><b>${label}</b></div>
            <div class="waterFall-tooltip-text"><div class="width-10 height-10 fas fa-circle m-r-10" style="color: ${bar1Color}"></div>${bar1Value} ${bar1Name}</div>
        `;

        if (!this.single) {
          result += `<div class="waterFall-tooltip-text"><div class="width-10 height-10 fas fa-circle m-r-10" style="color: ${bar2Color}"></div>${bar2Value} ${bar2Name}</div>`;
        }

        if (chartOptions.tooltipAction) {
          result += `<div
            class="float-right waterfall-action-btn"
            onclick='window.dispatchEvent(new CustomEvent("waterfall-tooltip-action-clicked", {detail:
            {
                bar1Value:${bar1Value},
                bar2Value:${bar2Value},
                bar1Name: "${bar1Name}",
                bar2Name: "${bar2Name}",
                bar1Color: "${bar1Color}",
                bar2Color: "${bar2Color}",
                label:"${label.replace('\n', ' ')}"
            }}));'>${chartOptions.tooltipAction.title}</div>`;
        }

        return result;
      },
    };
  }

  private getXAxis(labels: string[]): XAXisComponentOption[] {
    const xAxis: XAXisComponentOption[] = [
      {
        type: 'category',
        data: labels.map(label => label.replace(' ', '\n')),
        axisTick: { show: false },
      },
    ];

    if (!this.isSingleColumn()) {
      xAxis.push({
        type: 'value',
        max: labels.length * 100,
        show: false,
      });
    }

    return xAxis;
  }

  private getYAxis(): YAXisComponentOption {
    return {
      type: 'value',
      minInterval: 1,
      axisTick: { show: false },
      nameGap: 45,
    };
  }

  private getSeries(waterfallOptions: WaterfallChartOptions): SeriesOption[] {
    if (this.isSingle) {
      return this.getBarWithLine(waterfallOptions.bar1);
    }

    if (this.isStacked) {
      return this.getStackedBarsWithLine(
        waterfallOptions.bar1,
        waterfallOptions.bar2
      );
    }

    return [
      ...this.getBarWithLine(waterfallOptions.bar1),
      ...this.getBarWithLine(waterfallOptions.bar2, false),
    ];
  }

  private getBarWithLine(
    barOptions: WaterfallChartOptionsBar,
    isMain = true
  ): SeriesOption[] {
    const { line, hiddenBar, mainBar } = this.isAggregateData
      ? this.calculateBarDataAggregated(barOptions.data)
      : this.calculateBarData(barOptions.data);
    const lineValue = this.isSingleColumn()
      ? line
      : line.map((x, i, arr) => {
          const linePlaceMargin = isMain
            ? arr.length > 9
              ? 32
              : 48
            : arr.length > 9
            ? 65
            : 53;

          return [linePlaceMargin + i * 100, x];
        });

    return [
      this.getLine(
        lineValue,
        isMain ? 'bar-1-line' : 'bar-2-line',
        barOptions.color,
        isMain ? 'solid' : 'dashed'
      ),
      this.getBar(
        hiddenBar,
        isMain ? 'bar-1-hidden' : 'bar-2-hidden',
        isMain ? 'first' : 'second'
      ),
      this.getBar(
        mainBar,
        barOptions.title,
        isMain ? 'first' : 'second',
        barOptions.color,
        true
      ),
    ];
  }

  private getStackedBarsWithLine(
    bar1Options: WaterfallChartOptionsBar,
    bar2Options: WaterfallChartOptionsBar
  ) {
    const { line, hiddenBar, mainBar1, mainBar2 } =
      this.calculateBarsDataAggregatedStacked(
        bar1Options.data,
        bar2Options.data
      );

    return [
      this.getLine(line, 'stack-line', '#8492A6', 'solid'),
      this.getBar(hiddenBar, 'stack-hidden', 'stack'),
      this.getBar(
        mainBar1,
        bar1Options.title,
        'stack',
        bar1Options.color,
        true
      ),
      this.getBar(
        mainBar2,
        bar2Options.title,
        'stack',
        bar2Options.color,
        true
      ),
    ];
  }

  private getBar(
    data: number[] | number[][],
    title: string,
    stack: string,
    color = 'rgba(0,0,0,0)',
    tooltip = false
  ): BarSeriesOption {
    return {
      name: title,
      type: 'bar',
      stack,
      itemStyle: {
        color,
      },
      emphasis: {
        itemStyle: {
          borderColor: color,
          color,
        },
      },
      data,
      barMaxWidth: 10,
      barGap: '100%',
      tooltip: {
        show: tooltip,
      },
    };
  }

  private getLine(
    data: number[] | number[][],
    title: string,
    color = '#ccc',
    type: ZRLineType
  ): LineSeriesOption {
    return {
      name: title,
      type: 'line',
      step: 'start',
      data,
      connectNulls: true,
      itemStyle: {
        color,
      },
      lineStyle: {
        type,
        width: 1,
      },
      symbol: 'none',
      xAxisIndex: this.isSingleColumn() ? 0 : 1,
      tooltip: {
        show: false,
      },
      zlevel: 0,
      z: 0,
    };
  }

  private calculateBarsDataAggregatedStacked(
    bar1data: WaterfallChartOptionsBarData,
    bar2data: WaterfallChartOptionsBarData
  ) {
    const lineData = [];
    const hiddenBarData = [];
    const mainBar1Data = [];
    const mainBar2Data = [];
    let totalData = 0;
    let totalData1 = 0;
    let totalData2 = 0;

    bar1data.forEach((currentValue, currentIndex) => {
      const bar2Data = bar2data[currentIndex] || 0;
      const bar2DataValue = this.getBarValue(bar2Data);
      const bar2DataColor = this.getBarColor(bar2Data);
      const bar1DataValue = this.getBarValue(currentValue);
      const bar1DataColor = this.getBarColor(currentValue);

      hiddenBarData.push(totalData);
      lineData.push(totalData);
      totalData1 += bar1DataValue;
      totalData2 += bar2DataValue;
      mainBar1Data.push(
        this.getBarDrawnValue(
          currentIndex,
          bar1DataValue,
          totalData1,
          bar1DataColor
        )
      );
      mainBar2Data.push(
        this.getBarDrawnValue(
          currentIndex,
          bar2DataValue,
          totalData2,
          bar2DataColor
        )
      );
      totalData += bar1DataValue + bar2DataValue;
    });

    if (this.isWithEndingColumn) {
      mainBar1Data[mainBar1Data.length - 1] = [
        bar1data.length - 1,
        totalData,
        bar1data[bar1data.length - 1],
      ];
      hiddenBarData[hiddenBarData.length - 1] = 0;
      lineData[lineData.length - 1] = totalData;
    }

    return {
      line: lineData,
      hiddenBar: hiddenBarData,
      mainBar1: mainBar1Data,
      mainBar2: mainBar2Data,
    };
  }

  private calculateBarDataAggregated(data: WaterfallChartOptionsBarData) {
    const lineData = [];
    const hiddenBarData = [];
    const mainBarData = [];
    let totalData = 0;

    data.forEach((currentValue, currentIndex) => {
      const value = this.getBarValue(currentValue);

      hiddenBarData.push(totalData);
      lineData.push(totalData);
      totalData += value;
      mainBarData.push(
        this.getBarDrawnValue(
          currentIndex,
          value,
          totalData,
          this.getBarColor(currentValue)
        )
      );
    });

    if (this.isWithEndingColumn) {
      mainBarData[mainBarData.length - 1] = this.getBarDrawnValue(
        data.length - 1,
        totalData,
        totalData,
        this.getBarColor(data[data.length - 1])
      );
      hiddenBarData[hiddenBarData.length - 1] = 0;
      lineData[lineData.length - 1] = totalData;
    }

    return {
      line: lineData,
      hiddenBar: hiddenBarData,
      mainBar: mainBarData,
    };
  }

  private calculateBarData(data: WaterfallChartOptionsBarData) {
    const { difference } = data.reduce(
      ({ difference, lastValue }, currentValue) => {
        const value = this.getBarValue(currentValue);

        difference.push(value === 0 ? 0 : value - lastValue);
        lastValue = value > 0 ? value : lastValue;

        return { difference, lastValue };
      },
      { difference: [], lastValue: 0 }
    );
    const lineData = [0];
    const hiddenBarData = [0];
    const mainBarData = [];
    let totalDiff = 0;

    difference.forEach((currentValue, currentIndex, arr) => {
      const diff = currentValue;

      totalDiff += diff;
      hiddenBarData.push(
        currentValue <= 0 && arr[currentIndex + 1] < 0
          ? totalDiff + arr[currentIndex + 1] || 0
          : totalDiff
      );
      mainBarData.push(
        this.getBarDrawnValue(
          currentIndex,
          Math.abs(diff),
          this.getBarValue(data[currentIndex]),
          this.getBarColor(data[currentIndex])
        )
      );
      lineData.push(totalDiff);
    });
    const lastBarValue = this.getBarValue(data[data.length - 1]);

    mainBarData[mainBarData.length - 1] = this.getBarDrawnValue(
      data.length - 1,
      lastBarValue,
      lastBarValue,
      this.getBarColor(data[data.length - 1])
    );
    hiddenBarData.length = difference.length;
    hiddenBarData[hiddenBarData.length - 1] = 0;
    lineData.length = difference.length;
    lineData[lineData.length - 1] = lastBarValue;

    return {
      line: lineData,
      hiddenBar: hiddenBarData,
      mainBar: mainBarData,
    };
  }

  private isSingleColumn(): boolean {
    return this.isStacked || this.isSingle;
  }

  private getBarValue(data: WaterfallChartOptionsBarDataSingle) {
    if (typeof data === 'number') {
      return data;
    }

    return data.value;
  }

  private getBarColor(data: WaterfallChartOptionsBarDataSingle) {
    if (!data || typeof data === 'number') {
      return null;
    }

    return data.color;
  }

  private getBarDrawnValue(xValue, yValue, tooltipValue, color = null) {
    const value = [xValue, yValue, tooltipValue];

    if (color) {
      return {
        value,
        itemStyle: {
          color,
        },
      };
    }

    return value;
  }
}
