// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface DependencyTree {
  childDependencyIds: string;
  dependencies: any;
  dependencyId: string;
  dependencyName: string;
  dependencyVersion: string;
  issueHighCount: number;
  issueHighIds: string[];
  issueLowCount: number;
  issueLowIds: string[];
  issueMediumCount: number;
  issueMediumIds: string[];
  licenses: any;
  nodeId: string;
  packageManager: string;
  rootDependency: boolean;
  repositoryName: string;
}
