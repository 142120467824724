<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ng-template #miniCardTooltip><small>{{ tooltipText }}</small></ng-template>
<div class="lfx-cover-mini-card" placement="left" [ngbTooltip]="miniCardTooltip">
  <div class="card rounded">
    <img class="card-img" src="{{ coverUrl }}" alt="Card image" />
    <div class="card-img-overlay text-center">
      <ng-content></ng-content>
      <button class="btn btn-xs btn-oval btn-{{ buttonType || 'success' }}">
          {{ buttonText }}
        </button>
    </div>
  </div>
</div>
