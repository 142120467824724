// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listDiscoverMembershipsQuery = gql`
  query listDiscoverMemberships($accountId: ID!, $isAllOrgs: Boolean) {
    listDiscoverMemberships(accountId: $accountId, isAllOrgs: $isAllOrgs) {
      accountId
      accountName
      foundationId
      foundationLogo
      foundationName
      projectType
      contributorsCount
      contributionCount
    }
  }
`;
