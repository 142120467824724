// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lfx-search-filter-dropdown',
  templateUrl: './search-filter-dropdown.component.html',
  styleUrls: ['./search-filter-dropdown.component.scss'],
})
export class SearchFilterDropdownComponent implements OnInit {
  @Output() selectionChangeAction: EventEmitter<{
    id: string;
    name: string;
    logoUrl?: string;
  }> = new EventEmitter();

  @Input()
  set options(data: { id: string; name: string; logoUrl?: string }[]) {
    this.dropdownData = data;
    this.selectedOptions = this.dropdownData[0].id;
  }

  dropdownData: { id: string; name: string; logoUrl?: string }[] = [];
  selectedOptions = '';

  constructor() {}

  ngOnInit() {}

  onChangeOptions(item) {
    if (item.type === 'change') {
      return;
    }
    this.selectionChangeAction.emit(item);
  }
}
