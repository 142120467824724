// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export enum EventsDateRange {
  'current_year' = 'current_year',
  'last_year' = 'last_year',
  'last_90_days' = 'last_90_days',
  'all_time' = 'all_time',
  'two_years_before_last_year' = 'two_years_before_last_year',
  'one_year_before_last_year' = 'one_year_before_last_year',
}
