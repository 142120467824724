// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { pageSettings } from '@config';
import { PaginatedAppNotifications, AppNotification } from '@models';

@Component({
  selector: 'lfx-local-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() pageSidebarTwo;
  @Input() profile;
  @Input() isLoggedIn: boolean;
  @Input() paginatedNotifications: PaginatedAppNotifications;
  @Input() notificationsLength: number;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter();
  @Output() moreNotifications = new EventEmitter();
  @Output() dismissNotification = new EventEmitter<AppNotification>();

  pageSettings = pageSettings;

  constructor() {}

  ngOnInit() {}

  viewMoreNotifications() {
    this.moreNotifications.emit();
  }

  onDismissNotification(notification: AppNotification) {
    this.dismissNotification.emit(notification);
  }

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }

  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }

  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }

  mobileTopMenuToggle() {
    this.pageSettings.pageMobileTopMenuToggled =
      !this.pageSettings.pageMobileTopMenuToggled;
  }

  mobileMegaMenuToggle() {
    this.pageSettings.pageMobileMegaMenuToggled =
      !this.pageSettings.pageMobileMegaMenuToggled;
  }

  toggleHelpSupportFrame() {
    this.pageSettings.pageHelpMenu = !this.pageSettings.pageHelpMenu;
  }

  logMeOut() {
    this.logout.emit();
  }

  ngOnDestroy() {
    this.pageSettings.pageMobileTopMenuToggled = false;
    this.pageSettings.pageMobileMegaMenuToggled = false;
  }
}
