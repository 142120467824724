<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="custom-modal-container" [ngSwitch]="status">
    <i class="fas fa-times cursor-pointer" (click)="!isLoading && closeModal()" ngbAutofocus></i>
    <ng-container *ngSwitchCase="statusEnum.SUCCESS">
        <div class="top success">
            <i class="fas fa-check-circle"></i>
            <p>Success!</p>
        </div>
        <div class="bottom success">
            <p>
                Your SBOM file has been uploaded. Please allow up to 24 hours for the upload to be fully processed.
            </p>
            <button class="lf-primary-btn action-btn" (click)=" closeModal()">Close</button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="statusEnum.ERROR">
        <div class="top error">
            <i class="fas fa-times-circle"></i>
            <p>Error!</p>
        </div>
        <div class="bottom error">
            <div class="error-message">
                <p>{{ errorMessage }}</p>
                <p>Please upload a valid SBOM file.</p>
            </div>
            <ng-container *ngTemplateOutlet="uploadAction"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <div class="top default">
            <p>
                Want to know the value of the open source software you use?
            </p>
        </div>
        <div class="bottom default">
            <p>
                Identify your development cost savings, and get access to a customized inventory of open source software
                you use, including dependencies, licenses and more.
            </p>
            <ng-container *ngTemplateOutlet="uploadAction"></ng-container>
        </div>
    </ng-container>

    <div class="footer-text">
        <p class="info mb-0">Your Data, Your Control</p>
        <span>
            When uploading an SBOM, rest assured that only your organization<br> will have access to and visibility of the data.
        </span>
    </div>
</div>


<ng-template #uploadAction>
    <form [formGroup]="uploadSBOMForm" (ngSubmit)="onFormSubmit()">
        <div class="form-group">
            <label for="githubRepository">GitHub Repository URL <span class="text-danger">*</span> </label>
            <input
              type="text"
              id="githubRepository"
              class="form-control"
              [class.is-invalid]="!!getControlErrors(githubRepositoryUrlControl)"
              formControlName="githubRepositoryUrl"
              placeholder="Enter GitHub Repository URL…"
            />
            <!-- Validation Errors -->
            <ng-container *ngIf="getControlErrors(githubRepositoryUrlControl) as errors">
              <div class="invalid-feedback" *ngIf="errors['required'] || errors['spaceError']">
                GitHub Repository URL is required.
              </div>
              <div class="invalid-feedback" *ngIf="!(errors['required'] || errors['spaceError']) && errors['pattern']">
                Please enter a valid GitHub Repository URL
              </div>
            </ng-container>
        </div>
        <div class="sbom-file-input-group">
            <label for="sbomFile">SBOM File  <span class="text-danger">*</span></label>
            <div class="custom-file">
                <input
                    id="sbomFile"
                    #upload
                    (change)="onFileUpload($event.target)"
                    class="file-input"
                    type="file"
                    accept=".json, .xml, .spdx"
                />              
              <span class="file-name-container" (click)="upload.click()"><span class="file-name-text">{{ sbomFileNameControl.value || 'No file selected' }}</span></span>
            </div>
            <small id="sbomFile" class="sbom-input-info font-italic"><span class="text-bold">Supported Formats:</span> Cyclone JSON, Cyclone XML <span class="text-bold">Size Limit:</span> 10MB</small>
          </div>
        <button type="submit" [disabled]="isLoading || !uploadSBOMForm.valid" class="lf-primary-btn action-btn">
            <i class="fal fa-file-upload mr-2"></i> Upload
            <ngx-spinner
            type="ball-clip-rotate"
            [fullScreen]="false"
            name="upload-sbom-spinner"
            class="upload-sbom-spinner"
            color="rgba(0,0,0,0.7)"
            bdColor="rgba(255,255,255, 0.8)"
            size="small">
        </ngx-spinner>
        </button>
    </form>
</ng-template>
