// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterData } from '@lfx/shared/interfaces';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'lfx-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent implements OnInit {
  @Output() filterAction = new EventEmitter<any>();
  @Input() filterData: FilterData[];
  @Input() title: string;
  @Input() hideFilterData = false;
  @ViewChild('dropDownRef', { static: true }) dropDown: NgbDropdown;

  filterText: string = null;
  filterCount = 0;
  private oldFilterState: FilterData[];

  constructor() {}

  ngOnInit() {
    this.oldFilterState = cloneDeep(this.filterData);
  }

  openChange(isOpen) {
    if (!isOpen) {
      this.filterData = cloneDeep(this.oldFilterState);
    }
  }

  applyAction() {
    if (this.hideFilterData) {
      if (this.filterText && this.filterText !== '') {
        this.filterCount = 1;
        this.filterAction.emit(this.filterText);
      } else {
        this.clearAction();
      }
    } else {
      const checkFilter = this.filterData.filter(filter => filter.checked);

      this.filterText = '';
      this.filterCount = checkFilter.length;
      this.filterAction.emit(checkFilter);
      this.oldFilterState = cloneDeep(this.filterData);
    }
    this.dropDown.close();
  }

  clearAction() {
    if (this.hideFilterData) {
      this.filterCount = 0;
      this.filterText = '';
      this.filterAction.emit(null);
    } else {
      this.filterCount = 0;
      this.filterData.map(filter => {
        filter.checked = false;
      });
      this.filterText = '';
      this.filterAction.emit(null);
      this.oldFilterState = cloneDeep(this.filterData);
    }
    this.dropDown.close();
  }

  cancelAction() {
    this.dropDown.close();
  }
}
