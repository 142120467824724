// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  AllProjectsCubeResult,
  ContributionProject,
  getProjectWeContributeCodeToResult,
  Productivity,
  ProductivityCubeResult,
  ProjectsWeContributeCodeToResult,
  BaseProjectContributionInput,
  ContributorsGrowthResponse,
  ContributingCountriesResponse,
  Top10OrgsByCommitsResponse,
  AllOrgsContributionsResponse,
  TotalCommitsByOrgResponse,
  IssuesByOrgsResponse,
  PRsMergedByOrgsResponse,
  SubProjectsStatsResponse,
  MailingListResponse,
  DBTInput,
  BoardMeetingsResponse,
  ContributorLeaderboardResponse,
  OrganizationLeaderboardResponse,
  ContributorTeam,
  WorkTimeDistribution,
} from '@lfx/core/models';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  allProjectsCubeQuery,
  productivityQuery,
  projectWeContributeToCubeQuery,
  contributorsGrowthCubeQuery,
  contributingCountriesCubeQuery,
  orgsByCommitsCubeQuery,
  allOrgsContributionsCubeQuery,
  totalCommitsByOrgsCubeQuery,
  issuesByOrgsCubeQuery,
  prsMergedByOrgsCubeQuery,
  subProjectsStatsInputQuery,
  mailingListsQuery,
  boardMeetingsQuery,
  contributorLeaderboardQuery,
  organizationLeaderboardQuery,
  contributorTeamQuery,
  workTimeDistributionQuery,
} from '../queries';

@Injectable({
  providedIn: 'root',
})
export class ProjectContributionsServiceGql {
  constructor(private apollo: Apollo) {}

  getAllProjects(
    organizationId: string,
    dateRange: string,
    isAllOrgs: boolean
  ): Observable<ContributionProject[]> {
    return this.apollo
      .watchQuery<AllProjectsCubeResult>({
        query: allProjectsCubeQuery,
        variables: {
          organizationId,
          dateRange,
          isAllOrgs,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.getProjects),
        map(cloneDeep)
      );
  }

  getProductivity(
    organizationId?: string,
    projectId?: string,
    dateRange?: string,
    isAllOrgs?: boolean
  ): Observable<Productivity[]> {
    return this.apollo
      .watchQuery<ProductivityCubeResult>({
        query: productivityQuery,
        variables: {
          organizationId,
          projectId,
          dateRange,
          isAllOrgs,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.getProductivity),
        map(cloneDeep)
      );
  }

  getProjectsWeContributeCodeTo(
    organizationId?: string,
    foundationId?: string,
    projectId?: string,
    dateRange?: string,
    pageSize?: number,
    offset?: number
  ): Observable<ProjectsWeContributeCodeToResult> {
    return this.apollo
      .watchQuery<getProjectWeContributeCodeToResult>({
        query: projectWeContributeToCubeQuery,
        variables: {
          organizationId,
          foundationId,
          projectId,
          dateRange,
          pageSize,
          offset,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.getProjectWeContributeCodeTo),
        map(cloneDeep)
      );
  }

  getContributorsGrowth(
    input: BaseProjectContributionInput
  ): Observable<ContributorsGrowthResponse> {
    return this.apollo
      .watchQuery<any>({
        query: contributorsGrowthCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.contributorsGrowth),
        map(cloneDeep)
      );
  }

  getContributingCountries(
    input: BaseProjectContributionInput
  ): Observable<ContributingCountriesResponse> {
    return this.apollo
      .watchQuery<any>({
        query: contributingCountriesCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.contributingCountries),
        map(cloneDeep)
      );
  }

  getTop10OrgsByCommits(
    input: BaseProjectContributionInput
  ): Observable<Top10OrgsByCommitsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: orgsByCommitsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getTop10OrgsByCommits),
        map(cloneDeep)
      );
  }

  getAllOrgsContributions(
    input: BaseProjectContributionInput
  ): Observable<AllOrgsContributionsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: allOrgsContributionsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getAllOrgsContributions),
        map(cloneDeep)
      );
  }

  getTotalCommitsByOrgs(
    input: BaseProjectContributionInput
  ): Observable<TotalCommitsByOrgResponse> {
    return this.apollo
      .watchQuery<any>({
        query: totalCommitsByOrgsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getTotalCommitsByMyOrgChart),
        map(cloneDeep)
      );
  }

  getIssuesByOrgs(
    input: BaseProjectContributionInput
  ): Observable<IssuesByOrgsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: issuesByOrgsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getIssuesByOrgsChart),
        map(cloneDeep)
      );
  }

  getPRsMergedByOrgs(
    input: BaseProjectContributionInput
  ): Observable<PRsMergedByOrgsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: prsMergedByOrgsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getPRsMergedByOrgsChart),
        map(cloneDeep)
      );
  }

  getContributorLeaderboard(
    input: BaseProjectContributionInput,
    noCache = false
  ): Observable<ContributorLeaderboardResponse> {
    return this.apollo
      .watchQuery<any>({
        query: contributorLeaderboardQuery,
        variables: { input },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.contributorLeaderboard),
        map(cloneDeep)
      );
  }

  getOrganizationLeaderboard(
    input: BaseProjectContributionInput,
    noCache = false
  ): Observable<OrganizationLeaderboardResponse> {
    return this.apollo
      .watchQuery<any>({
        query: organizationLeaderboardQuery,
        variables: { input },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationLeaderboard),
        map(cloneDeep)
      );
  }

  getSubProjectsStats(
    input: BaseProjectContributionInput
  ): Observable<SubProjectsStatsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: subProjectsStatsInputQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getSubProjectsStats),
        map(cloneDeep)
      );
  }

  getMailingLists(input: DBTInput): Observable<MailingListResponse> {
    return this.apollo
      .watchQuery<any>({
        query: mailingListsQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(cloneDeep)
      );
  }

  getBoardMeetings(input: DBTInput): Observable<BoardMeetingsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: boardMeetingsQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.boardMeetings),
        map(cloneDeep)
      );
  }

  getContributorTeam(
    organizationId?: string,
    projectId?: string,
    dateRange?: string,
    isMaintainer?: boolean,
    isCommitter?: boolean,
    isReviewer?: boolean,
    isOthers?: boolean,
    currentEmployeesOnly?: boolean,
    isAllOrgs?: boolean
  ): Observable<ContributorTeam[]> {
    return this.apollo
      .watchQuery<any>({
        query: contributorTeamQuery,
        variables: {
          organizationId,
          projectId,
          dateRange,
          isMaintainer,
          isCommitter,
          isReviewer,
          isOthers,
          currentEmployeesOnly,
          isAllOrgs,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.contributorTeam),
        map(cloneDeep)
      );
  }

  getWorkTimeDistribution(
    organizationId: string,
    projectId?: string,
    dateRange?: string,
    isAllOrgs?: boolean
  ): Observable<WorkTimeDistribution[]> {
    return this.apollo
      .watchQuery<any>({
        query: workTimeDistributionQuery,
        variables: {
          organizationId,
          projectId,
          dateRange,
          isAllOrgs,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.workTimeDistribution),
        map(cloneDeep)
      );
  }
}
