// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { User } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateUserResult {
  updateUser: UpdateUserPayload;
}

export interface UpdateUserPayload extends MutationPayload {
  user?: User;
}
