// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lfx-lf-metric',
  templateUrl: './lf-metric.component.html',
  styleUrls: ['./lf-metric.component.scss'],
})
export class LfMetricComponent implements OnInit {
  @Input() value: string | number;
  @Input() description: string;
  @Input() percentage: string | number;
  @Input() hidePercentage: boolean;
  @Input() clickable: boolean;
  @Input() percentageDecrease: boolean;
  @Input() fontAwesomeIconClass: string;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() customSize: {
    valueSize: string;
    descriptionSize: string;
    percentageSize: string;
  };

  @Output() metricClicked = new EventEmitter<any>();

  sizeRecord = {
    xxs: {
      valueSize: '14',
      descriptionSize: '14',
      percentageSize: '13',
    },
    xs: {
      valueSize: '20',
      descriptionSize: '14',
      percentageSize: '13',
    },
    sm: {
      valueSize: '24',
      descriptionSize: '16',
      percentageSize: '13',
    },
    md: {
      valueSize: '26',
      descriptionSize: '20',
      percentageSize: '13',
    },
    lg: {
      valueSize: '36',
      descriptionSize: '18',
      percentageSize: '20',
    },
  };

  get valueSize() {
    return this.customSize
      ? this.customSize.valueSize
      : this.sizeRecord[this.size].valueSize;
  }

  get descriptionSize() {
    return this.customSize
      ? this.customSize.descriptionSize
      : this.sizeRecord[this.size].descriptionSize;
  }

  get percentageSize() {
    return this.customSize
      ? this.customSize.percentageSize
      : this.sizeRecord[this.size].percentageSize;
  }

  constructor() {}

  ngOnInit(): void {}

  onMetricClicked() {
    if (!this.clickable) {
      return;
    }

    this.metricClicked.next({
      description: this.description,
    });
  }
}
