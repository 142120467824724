// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface AverageFromOtherOrgs {
  similarSize: number[];
  sponsoredSameTier: number[];
  similarIndustry: number[];
}
export interface EventsSpeakersDataSets {
  topSpeakersFromMyOrg: number[];
  averageSpeakersFromOtherOrgs: AverageFromOtherOrgs;
  chartLabels: string[];
}

export interface TopEventsBySpeakersFromMyOrg {
  pastEventsDataSet: EventsSpeakersDataSets;
  upcomingEventsDataSet: EventsSpeakersDataSets;
  chartLabels: string[];
}

export interface EventsAttendeesDataSets {
  topAttendeesFromMyOrg: number[];
  averageAttendeesFromOtherOrgs: AverageFromOtherOrgs;
  chartLabels: string[];
}

export interface TopEventsByAttendeesFromMyOrg {
  pastEventsDataSet: EventsAttendeesDataSets;
  upcomingEventsDataSet: EventsAttendeesDataSets;
  chartLabels: string[];
}
