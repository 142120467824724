<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<table class="table table-bordered">
  <thead>
    <tr>
      <th
        class="text-white text-center"
        scope="col"
        *ngFor="let tier of _uniqueTiers"
      >
        <h4 class="text-bold text-uppercase">{{ tier.name }}</h4>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="membership-benefits-content">
      <td class="p-0" *ngFor="let tier of _uniqueTiers; let i = index">
        <button>
          <!-- <h5 class="m-b-20" *ngIf="i > 0">
            All of the {{ uniqueTiers[i - 1].name }} benefits plus…
          </h5> -->
          <ng-container *ngFor="let benefit of tier.benefits">
            <h5>{{ benefit.title }} Benefits</h5>
            <ul>
              <li *ngFor="let item of benefit.points">{{ item }}</li>
            </ul>
          </ng-container>
        </button>
      </td>
    </tr>
    <tr class="membership-fees text-center">
      <td *ngFor="let tier of _uniqueTiers">
        <div>
          <h4 *ngIf="tier.pricing > 0">${{ tier.pricing | number }}</h4>
          <h4 *ngIf="tier.pricing <= 0">Free</h4>
          <span>{{ tier.name }} Fees</span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="d-flex justify-content-center">
  <button
    *ngIf="showBecomeMemberButton"
    (click)="handleBecomeMemberClick()"
    class="btn btn-primary table-button my-3"
  >
    Become a Member
  </button>
</div>
