// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { CompanyService } from '@lfx/core/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() project: any;
  @Input() showNumberOfProjects = true;
  @Input() size = ''; // small or large // medium
  @Input() extraClasses: string;
  @Input() showTooltip = false;
  @Input() showColorOnHover = false;
  @Input() isActive = false;
  @Output() projectClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() projectsCountClicked: EventEmitter<any> = new EventEmitter<any>();

  linuxFoundationName = generalConstants.linuxFoundationProjectName;
  communitySupportedProjects = generalConstants.communitySupportedProjects;

  constructor(private router: Router, private companyService: CompanyService) {}

  ngOnInit() {}

  navigateToProjectGroupOverviewPage() {
    if (this.project.projectType === 'Project Group') {
      this.router.navigate([
        `/${this.companyService.getCurrentCompanySlug()}/project/project-group/` +
          this.project.id,
      ]);
    } else {
      this.router.navigate([
        `/${this.companyService.getCurrentCompanySlug()}/project/` +
          (this.project.id || this.project.projectId),
      ]);
    }
  }
}
