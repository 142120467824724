<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div ngbDropdown (openChange)="openChange($event)" class="d-inline-block" #dropDownRef="ngbDropdown">
  <li class="fas fa-filter action-button-filter" id="filterMenu" ngbDropdownToggle><span class="filter-count" *ngIf="filterCount>0">{{filterCount}}</span></li>
  <div ngbDropdownMenu aria-labelledby="filterMenu" class="px-4 py-3 filter-menu-wrapper">
    <div class="title" *ngIf="title">{{title}}</div>
    <div class="input-group search-wrapper">
      <input type="text" class="form-control" [(ngModel)]="filterText" placeholder="Search" aria-label="search">
      <div class="input-group-append">
        <span class="fas fa-search input-group-text"></span>
      </div>
    </div>
    <ul class="list-group list-group-flush filter-list">
      <li class="list-group-item" *ngFor="let filter of filterData | filterArrayOfObjects:filterText:'label'">
        <div class="input-group">
          <div class="input-group-prepend checkbox-wrapper col-1">
            <div class="input-group-text">
              <input type="checkbox" [(ngModel)]="filter.checked" aria-label="Checkbox for following text input" id="check{{filter.id}}">
            </div>
          </div>
          <div class="input-group-text list-text col-11">
            <label for="check{{filter.id}}" [title]="filter.label">
              <img class="filter-image" *ngIf="filter.image" [src]="filter.image"> <span>{{filter.label}}</span>
            </label>
          </div>
        </div>
      </li>

    </ul>
    <div class="action-btn-wrapper clearfix">
      <button type="button" class="btn danger-btn float-left" (click)="clearAction()">Clear</button>
      <button type="button" class="btn float-right" (click)="applyAction()">Apply</button>
      <button type="button" class="btn gray-btn float-right" (click)="cancelAction()">Cancel</button>
    </div>
  </div>
</div>
