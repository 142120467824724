// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-cover-mini-card',
  templateUrl: './cover-mini-card.component.html',
  styleUrls: ['./cover-mini-card.component.scss'],
})
export class CoverMiniCardComponent implements OnInit {
  @Input() coverUrl: string;
  @Input() buttonText: string;
  @Input() buttonType: string;
  @Input() tooltipText: string;

  constructor() {}

  ngOnInit() {}
}
