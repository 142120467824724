// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  DataRow,
  Column,
  DataActionEvent,
  CallToActionEvent,
  FilterOutput,
} from '../../interfaces';
import { DomSanitizer } from '@angular/platform-browser';
import { EllipsisPipe } from '@lfx/shared/pipes/ellipsis.pipe';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-cascading-row-table',
  templateUrl: './cascading-row-table.component.html',
  styleUrls: ['./cascading-row-table.component.scss'],
})
export class CascadingRowTableComponent implements OnInit {
  @Output() editAction = new EventEmitter<DataActionEvent>();
  @Output() deleteAction = new EventEmitter<DataActionEvent>();
  @Output() filterAction = new EventEmitter<FilterOutput>();
  @Output() callToAction = new EventEmitter<CallToActionEvent>();
  @Output() rowClickAction = new EventEmitter<any>();
  @Output() columnTextClickAction = new EventEmitter<any>();
  @Input() data: DataRow[] = [];
  @Input() emptyDataMessage = 'No Data';
  @Input() columns: Column[] = [];
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() expandText = 'Expand';
  @Input() collapseText = 'Collapse';
  @Input() closeText = 'Close';
  @Input() showHeader = true;
  @Input() maxHeight: string;
  @Input() minHeight: string;
  @Input() minHeightRow: string;
  @Input() defaultActionsTitle: string = generalConstants.defaultActionsTitle;
  @Input() ellipsis: number;
  @Input() grayColor = false;
  @Input() onRowClickStyle;
  activeRowDataIndex = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  getType(v) {
    return v ? typeof v : null;
  }

  childrenWidth() {
    if (
      this.getNumberOfHiddenChildColumns() === 1 &&
      this.getNumberOfColumns() === 2
    ) {
      return this.sanitizer.bypassSecurityTrustStyle(`width: 49%;`);
    }

    return this.sanitizer.bypassSecurityTrustStyle(
      `width: calc((100%) - (${Math.ceil(
        (100 / this.getNumberOfColumns()) * this.getNumberOfHiddenChildColumns()
      )}%);`
    );
  }

  toggleChildren(v) {
    v.expanded = !v.expanded;
  }

  edit(item, parent) {
    this.editAction.emit({
      item,
      parent,
    });
  }

  delete(item, parent) {
    this.deleteAction.emit({
      item,
      parent,
    });
  }

  customAction(column, row) {
    this.callToAction.emit({
      column,
      row,
    });
  }

  tooltip(item: string, ellipsisLimit: number) {
    if (ellipsisLimit > 0) {
      const trimItem = new EllipsisPipe().transform(item, ellipsisLimit);

      return item !== trimItem && item;
    }
  }

  checkImage(item) {
    return (
      this.getType(item) === 'object' &&
      item.hasOwnProperty('image') &&
      !item.image
    );
  }

  handleRowClick(item, index) {
    this.activeRowDataIndex = index;
    this.rowClickAction.emit(item);
  }

  handleColumnTextClick(item) {
    this.columnTextClickAction.emit(item);
  }

  private getNumberOfColumns() {
    return this.columns.reduce((previous, column) => {
      let value = 1;

      if (column.flex && column.flex > 1) {
        value = column.flex;
      }

      return previous + value;
    }, 0);
  }

  private getNumberOfChildColumns() {
    return this.columns.filter(i => !i.hideInChild).length;
  }

  private getNumberOfHiddenChildColumns() {
    return this.columns
      .filter(i => i.hideInChild)
      .reduce((previous, column) => {
        let value = 1;

        if (column.flex && column.flex > 1) {
          value = column.flex;
        }

        return previous + value;
      }, 0);
  }
}
