<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="modal-body">
  <div class="text-center main-container">
    <div class="mt-10">
      <i class="icon {{icon}}"></i>
    </div>
    <div class="title mt-10 secondary-font">{{ titleMessage }}</div>
    <div class="text mt-10 secondary-font" [innerHTML]="detailsMessage"></div>
    <div class="button" (click)="close()">Close</div>
    </div>
</div>