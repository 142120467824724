// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lfx-usage-bar',
  templateUrl: './usage-bar.component.html',
  styleUrls: ['./usage-bar.component.scss'],
})
export class UsageBarComponent implements OnInit {
  @Input() usage: number;
  @Output() benefitsClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
