// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface AppNotification {
  id: number;
  title: string;
  body: string;
  icon?: string;
  createdAt: Date;
  readAt?: string;
  showMore?: boolean;
}

export interface AppNotificationMetadata {
  allNotifications: number;
  limit: number;
  offset?: number;
}

export interface PaginatedAppNotifications {
  notifications: AppNotification[];
  metadata: AppNotificationMetadata;
}
