// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCTrainedEmployeesLeaderboardCountQuery = gql`
  query tnCTrainedEmployeesLeaderboardCount(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
  ) {
    getTnCTrainedEmployeesLeaderboardCount(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
    ) {
      count
    }
  }
`;
