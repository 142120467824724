// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ProjectsRemediationRateDetails {
  base: ProjectsRemediationRate;
  upstream: ProjectsRemediationRate;
}

export interface ProjectsRemediationRate {
  totalFixableIssues: number;
  totalOpenIssues: number;
  totalIssues: number;
  totalFixedIssues: number;
}
