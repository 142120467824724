// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NestedProjectRow } from '@lfx/core/models';
import { SortArrayOfObjectsPipeType } from '@lfx/shared/pipes/sort-array-of-objects.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lfx-projects-dropdown',
  templateUrl: './projects-dropdown.component.html',
  styleUrls: ['./projects-dropdown.component.scss'],
})
export class ProjectsDropdownComponent implements OnInit, OnChanges {
  filterByProjectPopover;
  selectedProject;
  filteredProjects = [];
  sortArrayOfObjectsPipeType = SortArrayOfObjectsPipeType;
  childrenFilterValue = '';
  totalCommits = 0;
  allFoundationsSelected = true;

  @Input() projects: NestedProjectRow[] = [];
  @Input() errorMessage = '';
  @Input() projectsLoading = false;
  @Input() salesforceB2BAccountId: string;

  @Output() selectedRowEmitter = new EventEmitter<any>();

  constructor(private spinner: NgxSpinnerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.projectsLoading && this.projectsLoading) {
      this.spinner.show('projects-dropdown-loading');
    } else {
      this.spinner.hide('projects-dropdown-loading');
    }

    if (changes?.projects) {
      this.filteredProjects = this.projects;
      this.calculateTotalCommits();
    }
  }

  ngOnInit(): void {
    this.filteredProjects = this.projects;
  }

  openSearchProjectsPopover(filterByProjectPopover) {
    this.filterByProjectPopover = filterByProjectPopover;
  }

  onSearchChange(searchValue: string) {
    if (searchValue) {
      this.childrenFilterValue = searchValue;
      this.filteredProjects = this.projects.filter(
        project =>
          project.name.text.toLowerCase().includes(searchValue.toLowerCase()) ||
          project.children.some(child =>
            child.name.text.toLowerCase().includes(searchValue.toLowerCase())
          )
      );
    } else {
      this.childrenFilterValue = '';
      this.filteredProjects = this.projects;
    }
  }

  onRowClick(rowData) {
    this.selectedProject = rowData;
    this.allFoundationsSelected = false;
    this.selectedRowEmitter.emit(rowData);

    if (this.filterByProjectPopover) {
      this.filterByProjectPopover.close();
    }
    this.onSearchChange('');
  }

  getAllFoundationsStats() {
    this.allFoundationsSelected = true;
    this.selectedProject = null;
    this.selectedRowEmitter.emit(null);

    if (this.filterByProjectPopover) {
      this.filterByProjectPopover.close();
    }
    this.onSearchChange('');
  }

  toNubmer(value) {
    return Number(value);
  }

  toggleExpandProject(project, event) {
    project.expanded = !project.expanded;
    event.stopPropagation();
  }

  private calculateTotalCommits() {
    this.projects.map(project => {
      this.totalCommits += Number(project.noOfCommits.text);
    });
  }
}
