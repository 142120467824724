<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="reject()"
  ></button>
</div>
<lfx-error-alert
  *ngIf="errorMessage"
  [message]="errorMessage"
></lfx-error-alert>
<div class="modal-body">
  <div class="modal-confirm">
    <ng-container *ngIf="message">{{ message }}</ng-container>
    <ng-container *ngIf="!message">
      <ng-container
        *ngTemplateOutlet="
          messageTemplate.template;
          context: messageTemplate.context
        "
      ></ng-container>
    </ng-container>
  </div>
</div>
<div class="modal-footer justify-content-end gap-16">
  <span class="lf-cancel-link" (click)="reject()" ngbAutofocus>
    {{ rejectLabel }}
    <i class="fa fa-spinner fa-spin" *ngIf="isRejectLoading"></i
  ></span>
  <button
    class="lf-primary-btn"
    [ngClass]="{ 'lf-disabled-btn': isConfirmLoading }"
    (click)="confirm()"
    type="submit"
    [disabled]="isConfirmLoading"
  >
    {{ confirmLabel }}
    <i class="fa fa-spinner fa-spin" *ngIf="isConfirmLoading"></i>
  </button>
</div>
