// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Initiative } from '@models';

@Component({
  selector: 'lfx-crowdfunding-mini-card',
  templateUrl: './crowdfunding-mini-card.component.html',
  styleUrls: ['./crowdfunding-mini-card.component.scss'],
})
export class CrowdfundingMiniCardComponent implements OnInit {
  @Input() initiative: Initiative;
  @Input() buttonName: string;
  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  onButtonClick() {
    this.buttonClick.emit();
  }
}
