// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CreateProjectCommitteesInput {
  projectId: string;
  committees: ProjectCommitteeInput[];
}

export interface ProjectCommitteeInput {
  category: string;
  collaborationName: string;
  description: string;
  url: string;
}
