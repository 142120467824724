// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface UpdateFoundationFinanceInput {
  id: string;
  name: string;
  internationalAddress: string;
  phone: string;
  emailFrom: string;
  website: string;
  emailAddress: string;
  city: string;
  country: string;
  state: string;
  postalCode: string;
  netSuiteClass: string;
  department: string;
  incomeGlAccount: string;
  deferredRevenueAccount: string;
  jobCode: string;
  projectCode: string;
  subsidiaryId: string;
}
