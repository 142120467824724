// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsTravelFundingQuery = gql`
  query EventsTravelFunding($input: EventsTravelFundingInput) {
    eventsTravelFunding(input: $input) {
      counts {
        totalAmount
      }
      list {
        eventId
        eventName
        eventYear
        approvedAmount
        recepientCount
        accountName
      }
    }
  }
`;
