<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="metric">
  <div class="d-flex gap-16">
    <span
      [style.fontSize.px]="valueSize"
      class="value"
      [ngClass]="{ 'lf-link': clickable }"
      (click)="onMetricClicked()"
      >{{ value }}</span
    >
    <span
      class="percentage d-flex"
      *ngIf="!hidePercentage"
      [class.font-bold]="size === 'lg'"
      [class.decrease]="percentageDecrease"
      [style.fontSize.px]="percentageSize"
    >
      <i class="fal fa-arrow-up" *ngIf="!percentageDecrease"></i>
      <i class="fal fa-arrow-down" *ngIf="percentageDecrease"></i>
      {{ percentage }}%
    </span>
  </div>
  <span class="description" [style.fontSize.px]="descriptionSize">
    <i *ngIf="fontAwesomeIconClass" [class]="fontAwesomeIconClass"></i>
    {{ description }}
  </span>
</div>
