// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs

import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { profileSnapshotConfig } from '@lfx/config';
import { downloadCSVFile } from '@lfx/core/helper';
import { CompanyService, EventService } from '@lfx/core/services';
import { Column } from '@lfx/shared/interfaces';
import {
  NgbActiveOffcanvas,
  NgbOffcanvas,
  NgbOffcanvasRef,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'lfx-event-registrants-offcanvas',
  templateUrl: './event-registrants-offcanvas.component.html',
  styleUrls: ['./event-registrants-offcanvas.component.scss'],
  providers: [DatePipe],
})
export class EventRegistrantsOffcanvasComponent implements OnInit {
  @Input() eventRegistrationInfo: EventRegistrationInfo;
  searchValue = '';
  columns: Column[] = [];
  data = [];
  searchData = [];
  paginatedData = [];
  isLoading = false;
  page = 1;
  pageSize = 10;
  totalSize = 0;
  offset = 0;
  activeFilterPill;
  filterPills: { text: EventTypeKey; count: number }[] = [
    {
      text: 'All',
      count: 0,
    },
    {
      text: 'In-person',
      count: 0,
    },
    {
      text: 'Virtual',
      count: 0,
    },
  ];

  emptyDataMessage = 'No registrants found';

  activeFilterPill$ = new BehaviorSubject(this.filterPills[0]);

  @ViewChild('nameColumn', { static: true })
  nameColumn: TemplateRef<any>;

  offCanvasRef: NgbOffcanvasRef;
  subscription: Subscription;

  constructor(
    public activeOffcanvas: NgbActiveOffcanvas,
    private offcanvasService: NgbOffcanvas,
    private eventService: EventService,
    private spinner: NgxSpinnerService,
    private companyService: CompanyService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.activeFilterPill = this.filterPills[0];
    this.activeFilterPill$.next(this.activeFilterPill);
    this.setData();
    this.setColumns();
  }

  setColumns() {
    this.columns = [
      {
        name: ' ',
        prop: 'serialNumber',
        style: {
          textAlign: 'center',
        },
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: true,
        sortDir: 'asc',
        template: this.nameColumn,
      },
      {
        name: 'Registration Date',
        prop: 'registrationDate',
      },
      {
        name: 'Type',
        prop: 'type',
      },
    ];
  }

  setData() {
    this.spinner.show('event-registrants-spinner');
    this.isLoading = true;
    this.subscription = this.activeFilterPill$
      .pipe(
        switchMap(activePill => {
          this.isLoading = true;

          return this.eventService.getEventRegistrationsDetails({
            dateRange: this.eventRegistrationInfo.dateRange,
            isAllOrgs: this.companyService.isAllOrgsRollup(),
            organizationId: this.eventRegistrationInfo.companyId,
            eventId: this.eventRegistrationInfo.eventId,
            eventRegistrationType: EVENT_TYPE_MAP[activePill.text],
          });
        })
      )
      .subscribe(
        response => {
          this.isLoading = false;
          this.filterPills = [
            {
              text: 'All',
              count: response.counts.allCnt,
            },
            {
              text: 'In-person',
              count: response.counts.inPersonCnt,
            },
            {
              text: 'Virtual',
              count: response.counts.virtualCnt,
            },
          ];
          this.data = response.list.map((registrant, i) => ({
            serialNumber: this.formatText(i + 1),
            name: {
              name: registrant.userFullName,
              imageUrl: registrant.userPhotoUrl,
              organizationName: this.companyService.isAllOrgsRollup()
                ? registrant.accountName
                : null,
              userId: registrant.userId,
            },
            registrationDate: this.formatText(
              this.formatDate(Number(registrant.registrationTs))
            ),
            type: this.formatText(
              registrant.eventRegistrationType.replace(' ', '-')
            ),
          }));
          this.searchData = this.data;
          this.onPageChange(this.page);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  clearSearch() {
    this.searchValue = '';
    this.onSearchChange('');
  }

  onSearchChange(value: string) {
    this.searchData = this.data.filter(d =>
      d.name.name.toLowerCase().includes(value.toLowerCase())
    );
    this.onPageChange(1);
  }

  downloadCsv() {
    const csvData = this.data.map(row => ({
      Name: row.name.name,
      'Registration Date': row.registrationDate.text,
      Type: row.type.text,
    }));

    downloadCSVFile(
      csvData,
      `${this.eventRegistrationInfo.eventName} Registrants.csv`
    );
  }

  sortDataColumn(column: Column) {
    this.searchData = this.searchData.sort((a, b) => {
      if (column.sortDir === 'asc') {
        return a[column.prop].name.localeCompare(b[column.prop].name);
      } else {
        return b[column.prop].name.localeCompare(a[column.prop].name);
      }
    });
    this.onPageChange(1);
  }

  openProfile(offCanvasContent) {
    this.offCanvasRef = this.offcanvasService.open(
      offCanvasContent,
      profileSnapshotConfig
    );
  }

  formatText(text, subtext = null, url = null) {
    return {
      text,
      subtext,
      url,
    };
  }

  formatDate(date: number) {
    if (!date) {
      return null;
    }

    return this.datePipe.transform(date, 'MM/dd/yyyy');
  }

  onPageChange(page) {
    this.page = page;
    this.offset = (page - 1) * this.pageSize;
    this.setPaginatedData();
  }

  onChangeFilterPill(pill) {
    this.activeFilterPill = pill;
    this.activeFilterPill$.next(this.activeFilterPill);
  }

  private setPaginatedData() {
    const startIndex = (this.page - 1) * this.pageSize;

    this.totalSize = this.searchData.length;

    this.paginatedData = this.searchData.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }
}

export interface EventRegistrationInfo {
  eventId: string;
  eventName: string;
  eventDate: string;
  registrantsCount: number;
  dateRange: string; // 'all_time' | '[year]' e.g '2024'
  companyId: string;
}

const EVENT_TYPE_MAP = {
  All: undefined,
  'In-person': 'In Person',
  Virtual: 'Virtual',
} as const;

type EventTypeKey = keyof typeof EVENT_TYPE_MAP;
