// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Identity } from '@models';
import { MutationPayload } from './mutation-payload';

export interface LinkSocialIdentityResult {
  linkUserIdentity: LinkSocialIdentityPayload;
}

export interface LinkSocialIdentityPayload extends MutationPayload {
  identities?: Identity[];
}
