// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { RoleTypes } from '@lfx/config/membership-contact';

export interface MembershipContactState {
  index: number;
  valid: boolean;
  message: string;
  saved: boolean;
  role?: { value: string; valid: boolean; message: string };
  firstName?: { value: string; valid: boolean; message: string };
  lastName?: { value: string; valid: boolean; message: string };
  email?: { value: string; valid: boolean; message: string };
}

export interface MembershipContact {
  index?: number;
  firstName: string;
  lastName: string;
  userId?: string;
  role: string;
  email: string;
  name?: string;
  contactId?: string;
  membershipId?: string;
  primaryContact?: boolean;
  hasInvite?: boolean;
}

export interface MembershipContactsFormState {
  valid: boolean;
  message: string;
  existingContacts: MembershipContactState[];
  newContacts: MembershipContactState[];
  invites: any[];
}

export interface RoleInfo {
  occurrence: {
    occurrenceInNew: number;
    occurrenceInExisting: number;
    total: number;
  };
  role: RoleTypes;
  rules: { min: number; max: number };
}
