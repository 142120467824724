<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="speakers-container">
  <div class="speakers-title">
    <span *ngIf="pageType === myOrgSpeakers" class="d-flex justify-content-between">
      SPEAKERS FROM MY ORGANIZATION
      <lfx-pii-help-icon></lfx-pii-help-icon>
    </span>
    <span *ngIf="pageType === allOrgsSpeakers">{{data.length === 100 ? "TOP 100 SPEAKERS ACROSS ALL EVENTS" : "TOP SPEAKERS ACROSS ALL EVENTS"}}</span>
    <span *ngIf="pageType === myOrgAttendees" class="d-flex justify-content-between">
      ATTENDEES FROM MY ORGANIZATION
      <lfx-pii-help-icon></lfx-pii-help-icon>
    </span>
    <span *ngIf="pageType === tncLearners" class="d-flex justify-content-between">
      TRAINED EMPLOYEES
      <lfx-pii-help-icon dataType="tnc"></lfx-pii-help-icon>
    </span>
  </div>
  <div class="date-range-sub-title">
    {{ dateRangeText }}
  </div>
  <div class="line"></div>
  <div *ngIf="isLoading" class="loading-data">
    <ngx-spinner type="ball-clip-rotate"
      [fullScreen]="false"
      name="events-speakers-table-spinner"
      color="rgba(0,0,0,0.5)"
      bdColor="transparent"
      bdOpacity="10"
      size="default"
    >
    </ngx-spinner>
  </div>
  <div *ngIf="!isLoading && emptyView" class="empty-section">
    <span>No Data Available</span>
  </div>
  <ng-container *ngIf="!isLoading && !emptyView">
    <div class="tables-container">
      <div *ngIf="cascadeTableData1.length" class="cascade-table-wrapper">
        <lfx-cascading-row-table
          grayColor="true"
          [canEdit]="false"
          [canDelete]="false"
          [data]="cascadeTableData1"
          [columns]="cascadeTableColumns"
          [maxHeight]="'800px'"
          (columnTextClickAction)="onUserClick($event)"
        ></lfx-cascading-row-table>
      </div>
      <div *ngIf="cascadeTableData2.length" class="cascade-table-wrapper">
        <lfx-cascading-row-table
          grayColor="true"
          [canEdit]="false"
          [canDelete]="false"
          [data]="cascadeTableData2"
          [columns]="cascadeTableColumns"
          [maxHeight]="'800px'"
          (columnTextClickAction)="onUserClick($event)"
        ></lfx-cascading-row-table>
      </div>
    </div>
    <div *ngIf="paginationTotal" class="pagination-wrapper">
    <lfx-ngb-pagination-wrapper
    [boundaryLinks]="true"
    [collectionSize]="paginationTotal"
    [maxSize]="paginationMaxSize"
    [pageSize]="paginationSize"
    (pageChange)="onPageChange($event)"
    [page]="paginationPage"
    >
    </lfx-ngb-pagination-wrapper>
    </div>
  </ng-container>
</div>

<ng-template #viewDetails let-row="row">
  <div class="view-details-button-container">
    <button
      class="lf-secondary-btn view-details-btn"
      (click)="viewDetailsModal(detailsModal, row)"
    >
      <span class="view-details-btn-text">{{
        pageType === myOrgSpeakers || pageType === allOrgsSpeakers
          ? 'View Events'
          : 'View Details'
      }}</span>
    </button>
  </div>
</ng-template>

<ng-template #detailsModal let-modal>
  <div class="loading-data d-flex justify-content-center align-items-center" *ngIf="loadingEventIndicator">
    <i class="fa fa-spinner fa-spin fa-2x"></i>
  </div>
  <ng-container *ngIf="!loadingEventIndicator">
    <lfx-employee-insights-details-dialog
      [company]="company"
      [dateRange]="dateRange"
      [pageType]="pageType"
      [employee]="selectedRow"
      [detailsList]="detailsList ? detailsList : null"
      (closeDialog)="closeDialog()"
    ></lfx-employee-insights-details-dialog>
  </ng-container>
</ng-template>
