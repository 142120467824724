// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { OrganizationAddress } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateAddressResult {
  updateAddress: UpdateAddressPayload;
}

export interface UpdateAddressPayload extends MutationPayload {
  address?: OrganizationAddress;
}
