// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  HostBinding,
} from '@angular/core';
import { NumberVisualization } from '@models';
import { generalConstants } from '@config';
@Component({
  selector: 'lfx-number-widget',
  templateUrl: './number-widget.component.html',
  styleUrls: ['./number-widget.component.scss'],
})
export class NumberWidgetComponent implements OnInit, OnChanges {
  @Input() visualizationData: NumberVisualization;
  @Input() size = generalConstants.numberWidgetDefaultFontSize;
  value: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizationData) {
      this.value =
        (this.visualizationData.prefix || '') +
        this.visualizationData.value.toString() +
        (this.visualizationData.suffix || '');
    }
  }

  @HostBinding('style.color') get textColor() {
    return this.visualizationData.color || '#000000';
  }
}
