// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lfx-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() imageURL = '';
  @Input() name = 'a';

  defaultImage = '';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const imageChanged =
      changes.imageURL &&
      changes.imageURL.currentValue &&
      changes.imageURL.currentValue !== changes.imageURL.previousValue;

    if (imageChanged) {
      this.defaultImage = '';
    }
  }

  removeSpecialCharacters(str) {
    return str.replace(/[^a-zA-Z]/g, '') || 'a';
  }

  getDefaultAvatar() {
    const firstChart =
      this.name && this.removeSpecialCharacters(this.name)[0].toLowerCase();

    if (firstChart) {
      this.defaultImage = `https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/${firstChart}.png`;
    }
  }
}
