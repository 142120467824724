<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="form-group">
  <label >Filter</label>
  <input [(ngModel)]="filterString" (input)="updateList()" type="text" class="form-control">
</div>
<ngx-datatable
        #table
        class="material"
        [columns]="columns"
        [columnMode]="ColumnMode.standard"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [limit]="10"
        [rows]="rows"
        class="material"
      >
      </ngx-datatable>