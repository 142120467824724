// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
interface Domain {
  name: string;
  state: string;
  primary: boolean;
}

interface Alias {
  alias: string;
  recipients: string[];
  status: string;
}

interface MailingList {
  title: string;
  description: string;
  tag: string;
  url: string;
  alias: string;
}

export interface ProjectInfrastructureSummary {
  domains: Domain[];
  aliases: Alias[];
  mailingLists: MailingList[];
}
