// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { BaseListOffcanvasDirective } from '../base-list-offcanvas/base-list-offcanvas.directive';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService, NewDashboardService } from '@lfx/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Column } from '@lfx/shared/interfaces';
import { MeetingAttendanceDetails } from '@lfx/core/models';

@Component({
  selector: 'lfx-meetings-attendees-offcanvas',
  templateUrl: './meetings-attendees-offcanvas.component.html',
  styleUrls: [
    '../base-list-offcanvas/base-list-offcanvas.component.scss',
    './meetings-attendees-offcanvas.component.scss',
  ],
})
export class MeetingsAttendeesOffcanvasComponent
  extends BaseListOffcanvasDirective
  implements OnInit
{
  constructor(
    activeOffcanvas: NgbActiveOffcanvas,
    offcanvasService: NgbOffcanvas,
    companyService: CompanyService,
    spinner: NgxSpinnerService,
    private newDashboardService: NewDashboardService
  ) {
    super(activeOffcanvas, offcanvasService, companyService, spinner);
  }

  public projectId: string;
  emptyDataMessage = 'No attendees found';

  ngOnInit(): void {
    this.columns = this.getColumns();
    this.fetchData();
  }

  protected getColumns(): Column[] {
    return [
      {
        name: '',
        prop: 'index',
        style: {
          textAlign: 'center',
        },
      },
      {
        name: 'Name',
        prop: 'name',
        template: this.nameColumn,
      },
      {
        name: 'Project',
        prop: 'project',
        textEllipsis: 17,
      },
      {
        name: 'Committee',
        prop: 'committee',
      },
      {
        name: 'Hours Spent',
        prop: 'hoursSpent',
        sortable: true,
        sortDir: 'desc',
        style: {
          textAlign: 'right',
        },
        dataStyle: {
          paddingRight: '10px',
        },
      },
    ];
  }

  protected fetchData() {
    this.setIsLoading(true);
    this.newDashboardService
      .getMeetingAttendanceDetails(
        this.listInfo.companyId,
        this.companyService.isAllOrgsRollup(),
        this.projectId
      )
      .subscribe({
        next: response => {
          this.data = this.transformData(response);
          this.searchData = this.data;
          this.onPageChange(1);
          this.setIsLoading(false);
        },
        error: error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching meeting attendees:', error);
          this.setIsLoading(false);
          this.emptyDataMessage = 'Error fetching attendees';
        },
      });
  }

  private transformData(attendees: MeetingAttendanceDetails[]) {
    return attendees.map((attendee, index) => ({
      index: this.formatText(`${index + 1}`),
      name: this.formatText(attendee.userFullName, null, null, null, {
        imageUrl: attendee.userPhotoUrl,
        userId: attendee.userId,
        organizationName: attendee.accountName,
      }),
      project: this.formatText(attendee.projectName),
      committee: this.formatText(attendee.committeeType),
      hoursSpent: this.formatText(
        attendee.hours.toString(),
        null,
        null,
        attendee.hours
      ),
    }));
  }

  protected getCSVData() {
    return this.data.map(row => ({
      Name: row.name.text,
      Project: row.project.text,
      Committee: row.committee.text,
      'Hours Spent': row.hoursSpent.text,
    }));
  }

  protected getListType(): string {
    return 'Meeting Attendees';
  }
}
