// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() height: number;
  @Input() value: number;
  @Input() tooltip: string;

  constructor() {}

  ngOnInit() {}
}
