// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export interface MembershipDetail {
  id: string;
  projectId: string;
  membershipNote: string;
  membershipTier: string;
  account: {
    id: string;
    name: string;
    logoUrl: string;
  };
}

export interface CreateMembershipDetailPayload {
  membershipNote: string;
  membershipTier: string;
}

export interface UpdateMembershipDetailPayload {
  membershipNote?: string;
  membershipTier?: string;
}

export interface MembershipProject {
  projectId: string;
  projectName: string;
  projectStatus: string;
  meetingAttendance?: string;
  mailingList?: string;
  eventRegistration?: string;
  tnc?: string;
  contribution?: string;
}
