// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export enum CoverageTypeEnum {
  EmailAddress = 'Email Address',
  Domain = 'Domain',
  GithubId = 'GitHub Username',
  GithubOrganization = 'GitHub Organization',
}

export interface ApprovedContributor {
  username?: string;
  email?: string;
  image?: string;
  githubUsername?: string;
  githubOrgUrl?: string;
  domainUrl?: string;
  coverageType: CoverageTypeEnum;
  dateCreated: string;
}

export interface FormatedApprovedContributor {
  id: number;
  addedOn: string;
  coverageType: CoverageTypeEnum;
  approvedList: MappedApprovedList;
}

export interface MappedApprovedList {
  text: string;
  image: string;
  subtext: string;
}
