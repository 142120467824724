<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="modal-body success-dialog">
    <i class="fas" [ngClass]="success ? 'success-icon ' + (icon ? icon : successIcon) : 'failed-icon ' +  (icon ? icon : failIcon)" ></i>
    <br/>
    <strong class="success-text">{{iconLabel}}</strong>
    <br/>
    <p class="text-center success-message" [innerHTML]="message"></p>
    <br/>
    <button class="close-button" (click)="onClickClose()">
        Close
    </button>
    <input ngbAutofocus type="text" class="remove-autofocus" />
</div>