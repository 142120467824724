// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsSponsorshipQuery = gql`
  query EventsSponsorship($input: EventsSponsorshipInput) {
    eventsSponsorship(input: $input) {
      eventId
      eventName
      eventYear
      submitterName
      eventStartDate
      createdTs
      sponsorshipTier
      accountName
    }
  }
`;
