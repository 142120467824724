// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
  @Input() warningMsg = '';
  @Input() redirectionLink = '';
  @Input() redirectionLinkText = '';

  constructor() {}

  ngOnInit(): void {}
}
