// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { projectDetailsOffcanvasConfig } from '@lfx/config';
import {
  EmployeeProfileSnapshotComponent,
  UserProfileInput,
} from '@lfx/modules/profile-snapshot/components/employee-profile-snapshot/employee-profile-snapshot.component';
import {
  Info,
  InfoOffcanvasComponent,
} from '@lfx/shared/components/info-offcanvas/info-offcanvas.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class FlyoutService {
  constructor(private offcanvasService: NgbOffcanvas) {}

  openInfo(info: Info[]) {
    const ref = this.offcanvasService.open(
      InfoOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    ref.componentInstance.info = info;
  }

  openUserProfile(userProfileInput: UserProfileInput) {
    if (!userProfileInput?.employeeId) {
      throw new Error('Employee ID is required');
    }

    const ref = this.offcanvasService.open(
      EmployeeProfileSnapshotComponent,
      projectDetailsOffcanvasConfig
    );

    const instance = ref.componentInstance as EmployeeProfileSnapshotComponent;

    instance.employeeId = userProfileInput.employeeId;
    instance.employeeEmail = userProfileInput.employeeEmail;
    instance.usersPageSource = userProfileInput.usersPageSource;
    instance.defaultEmployeeObject = userProfileInput.defaultEmployeeObject;
    instance.options = userProfileInput.options;
  }
}
