<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="statistics-box">
  <div class="total-number-title">{{ title }}</div>
  <div class="count-and-growth">
    <!-- This YOY section will be needed in V2 -->
    <div class="growth" *ngIf="growth > 0">
      <img
        src="assets/img/svgs/ic_arrow_growth.svg"
        class="growth-icon-styles"
      />
      {{ growth }}% YoY Growth
    </div>
    <div class="fall" *ngIf="growth < 0">
      <img src="assets/img/svgs/ic_arrow_fall.svg" class="fall-icon-styles" />
      {{ growth }}% YoY Fall
    </div>
    <div class="equal-growth" *ngIf="growth === 0">
      {{ growth }}% YoY Difference
    </div>
    <div class="total-number-count">{{ count | number }}</div>
  </div>
</div>
