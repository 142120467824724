// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { DataSet } from '../models';
import { ChartsVisualization } from '@lfx/core/models';
import { Chart, ChartTypeRegistry, registerables, Tooltip } from 'chart.js';
import { sum } from 'lodash';
import { formatNumber } from '@angular/common';

declare module 'chart.js' {
  interface TooltipPositionerMap {
    cursor: TooltipPositionerFunction<ChartType>;
  }
}

Chart.register(...registerables);

@Component({
  selector: 'lfx-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
})
export class DoughnutChartComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() chartColors: string[];
  @Input() chartData: any;
  @Input('visualizationData')
  set visualizationData(visualization: ChartsVisualization) {
    this.dataSets = visualization?.dataSets || [];
    this.xAxesLabels = visualization?.labels || [];
  }

  @Input() colorOpacity = 0.6;
  @Input() fill: boolean;
  @Input()
  set extraOptions(extraOptions) {
    const tooltipsCallback = {
      ...(extraOptions?.plugins?.tooltip?.callbacks ?? {}),
      label: context => {
        const value = this.dataSets[0].data[context.dataIndex];
        const label = context.label || '';
        const total = sum(this.dataSets[0].data);
        const valuePercentage = ((value / total) * 100)
          .toFixed(1)
          .replace(/\.?0+$/, '');

        return `${label}: ${formatNumber(
          value,
          'en-US'
        )} (${valuePercentage}%)`;
      },
    };

    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      ...extraOptions,
      plugins: {
        ...(extraOptions?.plugins ?? {}),
        tooltip: {
          ...(extraOptions?.plugins?.tooltip ?? {}),
          callbacks: {
            ...tooltipsCallback,
          },
        },
      },
    };
  }

  @ViewChild('chartCanvas') canvas: ElementRef;
  @Output() chartCreated: EventEmitter<any> = new EventEmitter<any>();

  chartType: keyof ChartTypeRegistry = 'doughnut';
  dataSets: DataSet[] = [];
  xAxesLabels: (string | number)[] = [];
  chart: Chart;
  options: any;
  borderWidth = '1';

  /**
   * colors to Use must be HEX to support the hexRoRgb method
   */
  @Input() fillColors = [
    '#D32F2F',
    '536DFE',
    '#FFA000',
    '#4CAF50',
    '#795548',
    '#0288D1',
  ];

  @Input() borderColors = [
    '#D32F2F',
    '536DFE',
    '#FFA000',
    '#4CAF50',
    '#795548',
    '#0288D1',
  ];

  constructor() {}

  ngOnInit() {
    Tooltip.positioners.cursor = (_chartElements, coordinates) => coordinates;
  }

  ngOnChanges(): void {
    if (this.chart) {
      this.updateChart();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createChart();

      this.chartCreated.emit(this.chart);

      if (this.chartData !== undefined) {
      }
    });
  }

  private createChart() {
    const canvas = this.canvas.nativeElement.getContext('2d');

    this.chart = new Chart(canvas, {
      type: this.chartType,
      data: this.calculateChartData(),
      options: this.options,
    });
  }

  private updateChart() {
    this.chart.data = this.calculateChartData();
    this.chart.options = this.options;
    this.chart.update();
  }

  private calculateChartData() {
    const totals = this.dataSets.reduce(
      (totals, dataSet, index) =>
        dataSet.data.reduce((previous, current) => {
          previous[index] = (previous[index] || 0) + current;

          return previous;
        }, totals),
      []
    );

    return {
      labels: this.xAxesLabels,
      datasets: this.dataSets.map((dataSet, index) =>
        Object.assign({}, this.createExtraSettingsForDataSet(index), dataSet, {
          data: dataSet.data.map(current =>
            current ? Math.max((current / totals[index]) * 100, 1) : 0
          ),
        })
      ),
    };
  }

  private hexToRgb(hex, opacity = 1) {
    hex = hex || this.addColor();
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      return hex;
    }

    return (
      'rgba( ' +
      parseInt(result[1], 16) +
      ',' +
      parseInt(result[2], 16) +
      ',' +
      parseInt(result[3], 16) +
      ',' +
      opacity +
      ')'
    );
  }

  private generateRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  private addColor() {
    const newColor = this.generateRandomColor();

    this.fillColors.push(newColor);
    this.borderColors.push(newColor);

    return newColor;
  }

  private generateBackgrounds(index: number) {
    return this.dataSets[index].data.map((item, i) =>
      this.hexToRgb(this.fillColors[i], this.colorOpacity)
    );
  }

  private generateBorders(index: number) {
    return this.dataSets[index].data.map((item, i) => this.borderColors[i]);
  }

  private createExtraSettingsForDataSet(index: number): any {
    return {
      borderColor: this.generateBorders(index),
      backgroundColor: this.generateBackgrounds(index),
      fill: this.fill ? 'origin' : false,
      borderWidth: this.borderWidth,
    };
  }
}
