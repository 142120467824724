// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { User } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateCurrentUserResult {
  updateCurrentUser: UpdateCurrentUserPayload;
}

export interface UpdateCurrentUserPayload extends MutationPayload {
  currentUser?: User;
}
