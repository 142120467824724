// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface UpdateOrganizationInput {
  id: string;
  logoUrl?: string;
  name?: string;
  link?: string;
  totalEmployees?: number;
  crunchBaseUrl?: string;
  description?: string;
  sector?: string;
  industry?: string;
}
