// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import * as am5 from '@amcharts/amcharts5';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import * as am5map from '@amcharts/amcharts5/map';
import {
  MapChartConfig,
  createBubbleSeries,
  createChartRoot,
  createMapChart,
  createPolygonSeries,
} from '../am-chart-util';

/**
 *
 * INFO:
 * To configure a new chart, you need to review te next files
 * packages/lfx-insights/interfaces/charts.d.ts
 * apps/frontend/src/app/shared/services/chart.service.ts
 */
@Component({
  selector: 'lfx-am-map-chart',
  templateUrl: './am-map-chart.component.html',
  styleUrls: ['./am-map-chart.component.scss'],
})
export class AMMapChartComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input() public config!: MapChartConfig;
  @Input() public chartName!: string;
  @Input() public data: any[] = [];

  private root!: am5.Root;
  private bubbleSeries!: am5map.MapPointSeries;

  public constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data) {
      this.changeData();
    }
  }

  public ngAfterViewInit() {
    // Chart code goes in here
    am5.addLicense('AM5M454672478');
    const root = createChartRoot(this.chartName);
    const chart = createMapChart(root);

    const polygonSeries = createPolygonSeries(root, chart);

    this.bubbleSeries = createBubbleSeries(
      root,
      chart,
      polygonSeries,
      this.config
    );

    this.bubbleSeries.data.setAll(this.data);

    this.root = root;
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    if (this.root) {
      this.root.dispose();
    }
  }

  public checkEmptyData(): boolean {
    // TODO: implement this
    return false;
  }

  private changeData(): void {
    if (this.bubbleSeries) {
      this.bubbleSeries.data.setAll(this.data);
    }
  }
}
