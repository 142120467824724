// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Micro from '@amcharts/amcharts5/themes/Micro';
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';
import moment from 'moment';
import { ISpriteSettings, Percent } from '@amcharts/amcharts5';
import { am5geodataWorldLow } from './geo-data';

export interface AxisSettings {
  field: string;
  axisType: 'category' | 'date';
  granularity?: GranularityEnum;
  dateFormats?: { [index: string]: string | Intl.DateTimeFormatOptions };
  groupData?: boolean;
  endLocation?: number;
  labelLocation?: number;
  labelRotation?: number;
  cellStartLocation?: number;
  cellEndLocation?: number;
  addLabelSkip?: number;
  stroke?: string;
  labelColor?: string;
  ranges?: { start: string; end: string; label: string }[];
  showRangeLabelsOnly?: boolean;
}

export const enum GranularityEnum {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

export interface CreateYAxisOptions {
  max?: number;
  numberFormat?: string;
  label?: string;
}

export interface LineSeriesSettings {
  field: string;
  name: string;
  color?: string;
  bullets?: boolean;
  fills?: boolean;
  stroke?: boolean;
  strokeWidth?: number;
  strokeDasharray?: number[];
  locationX?: number;
  showFirstBullet?: boolean;
  yAxisTitle?: string;
  tooltipWithoutBullets?: boolean;
  verticalShift?: ISpriteSettings['dy'];
}

export interface ColumnSeriesSettings {
  name: string;
  valueYField: string;
  categoryXField?: string;
  valueXField?: string;
  color?: string;
  legendTooltipText?: string;
  plotAsLineChart?: boolean;
  bullets?: boolean;
  locationX?: number;
  showFirstBullet?: boolean;
  yAxisTitle?: string;
}

export interface CommonChartConfig {
  showLegend?: boolean;
  yAxisName?: string;
  height?: number;
  max?: number;
  customTooltipTextAdapter?: (
    text: string | undefined,
    target: am5.Label
  ) => any;
  customAdapter?: 'text' | 'html';
}

export interface BarChartConfig extends CommonChartConfig {
  type?: 'normal' | 'stacked' | 'reversedStacked';
  series: ColumnSeriesSettings[];
  xAxis?: AxisSettings;
  direction?: 'vertical' | 'horizontal';
  barWidth?: number | Percent | null;
  targetValue?: number;
  useMicroTheme?: boolean;
  useExternalData?: boolean; // If this is set to true then the chart will use external data rather than pull it's own
  showLegendTooltip?: boolean;
  applyPadding?: boolean;
  yAxisStroke?: string;
  columnsTemplateWidth?: number; // only for type 'normal'
}
export interface LineChartDataRow {
  value: number;
  date: number | string;
}

export interface LineChartConfig extends CommonChartConfig {
  type?: 'smooth' | 'straight';
  series: LineSeriesSettings[];
  xAxis: AxisSettings;
  useMicroTheme?: boolean;
  targetValue?: number;
  hideYAxis?: boolean;
  hideYAxisLabelsOnly?: boolean;
  hideCursor?: boolean;
  yAxisStroke?: string;
}

export interface RadialSeriesSettings {
  name: string;
  valueField: string;
  color?: string;
  setAppear?: number;
  stacked?: boolean;
  clustered?: boolean;
}
export interface RadialConfig {
  categoryField: string;
  series: RadialSeriesSettings[];
  inversed?: boolean;
  innerRadius?: number;
  padding?: number;
}

export interface RadarChartConfig {
  chart: {
    markPoint?: any;
    seriesName: string;
    radius?: string;
  };
  data?: DataPoint[];
}

export interface DataPoint {
  name: string;
  value: number;
}

export interface MapSeriesSettings {
  idField: string;
  valueField: string;
  calculateAggregates?: boolean;
}

export interface MapChartConfig extends CommonChartConfig {
  series: MapSeriesSettings;
  min?: number;
  max?: number;
  minValue?: number;
  maxValue?: number;
  tooltipHTML?: string;
  useAnimation?: boolean;
}

export function createChartRoot(parentDiv = '', isMicro = false) {
  const root: am5.Root = am5.Root.new(parentDiv);

  const myTheme = am5.Theme.new(root);

  myTheme.rule('Grid').setAll({
    strokeOpacity: 0,
  });

  root.setThemes([am5themes_Animated.new(root), myTheme]);

  if (isMicro) {
    root.setThemes([am5themes_Micro.new(root)]);
  }

  // remove footer logo
  // eslint-disable-next-line no-underscore-dangle
  root._logo?.children.clear();

  // setting the start of the week to Monday
  root.locale.firstDayOfWeek = 1;

  return root;
}

export function createChart(root: am5.Root, withPadding = true) {
  const chart: am5xy.XYChart = root.container.children.push(
    am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      paddingBottom: withPadding ? undefined : 0,
      paddingRight: withPadding ? undefined : 0,
      paddingLeft: withPadding ? undefined : 0,
      maxTooltipDistance: -1,
    })
  );

  return chart;
}

export function createYAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  {
    max = undefined,
    label = undefined,
    numberFormat = undefined,
  }: CreateYAxisOptions = {},
  isHidden = false,
  oppositeAxis?: boolean,
  hideYAxisLabelsOnly?: boolean, // to hide y axis labels
  config?: BarChartConfig | LineChartConfig
) {
  let yAxis;

  if ((config as BarChartConfig)?.direction === 'horizontal') {
    yAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        max,
        numberFormat,
        min: 0,
        extraMax: 0.1,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererX.new(
          root,
          isHidden
            ? {}
            : {
                opposite: oppositeAxis ? true : false,
                fill: am5.color('#807f83'),
                stroke: am5.color(config.yAxisStroke || '#807f83'),
                minGridDistance: 25,
                strokeOpacity: 1,
                strokeWidth: 1,
              }
        ),
      })
    );
  } else {
    yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        max,
        numberFormat,
        min: 0,
        extraMax: 0.1,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(
          root,
          isHidden
            ? {}
            : {
                opposite: oppositeAxis ? true : false,
                fill: am5.color('#807f83'),
                stroke: am5.color(config?.yAxisStroke || '#807f83'),
                minGridDistance: 25,
                strokeOpacity: 1,
                strokeWidth: 1,
              }
        ),
      })
    );
  }

  // Note: all chart y-axis label have same style
  if (label) {
    const yAxisLabel = am5.Label.new(root, {
      rotation: -90,
      text: label,
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      fill: am5.color('#333333'),
      y: am5.p50,
      centerX: am5.p50,
    });

    yAxis.children.unshift(yAxisLabel);
  }

  const yRenderer = yAxis.get('renderer');

  yRenderer.labels.template.setAll(
    isHidden
      ? { visible: false }
      : {
          fill: am5.color('#807f83'),
          fontSize: 12,
          visible: hideYAxisLabelsOnly ? false : true, // to hide y axis labels
        }
  );

  chart.appear(1000, 100);

  return yAxis;
}

export function createXAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  granularity: GranularityEnum,
  xAxisSettings?: AxisSettings
): am5xy.DateAxis<am5xy.AxisRenderer> | am5xy.CategoryAxis<am5xy.AxisRenderer> {
  const renderer = am5xy.AxisRendererX.new(root, {
    stroke: am5.color(xAxisSettings.stroke || '#807f83'),
    minGridDistance: 28,
    strokeOpacity: 1,
    strokeWidth: 1,
    cellStartLocation: xAxisSettings?.cellStartLocation || 0,
    cellEndLocation: xAxisSettings?.cellEndLocation || 1,
  });

  const xAxis =
    xAxisSettings?.axisType === 'category'
      ? chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            categoryField: xAxisSettings?.field,
            endLocation: xAxisSettings?.endLocation || 0.1,
            renderer,
          })
        )
      : chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            markUnitChange: false,
            groupData: true,
            extraMax: xAxisSettings?.endLocation === 0 ? 0.02 : undefined,
            endLocation: xAxisSettings?.endLocation,
            dateFormats:
              xAxisSettings && xAxisSettings.dateFormats
                ? xAxisSettings.dateFormats
                : {
                    day: 'MMM\ndd',
                    week: 'MMM\ndd',
                    month: 'MMM\nyy',
                    year: 'yyyy',
                  },
            baseInterval: {
              timeUnit: granularity as TimeUnit,
              count: 1,
            },
            renderer,
          })
        );

  if (xAxisSettings.axisType === 'date') {
    (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>)
      .get('renderer')
      .labels.template.setAll({
        fill: am5.color(xAxisSettings?.labelColor || '#807f83'),
        fontSize: 12,
        textAlign: 'center',
        location: xAxisSettings?.labelLocation,
        rotation: xAxisSettings?.labelRotation,
      });
  } else {
    (xAxis as am5xy.CategoryAxis<am5xy.AxisRenderer>)
      .get('renderer')
      .labels.template.setAll({
        fill: am5.color(xAxisSettings?.labelColor || '#807f83'),
        fontSize: 12,
        textAlign: 'center',
        location: xAxisSettings?.labelLocation,
        rotation: xAxisSettings?.labelRotation,
      });
  }

  if (xAxisSettings && xAxisSettings.addLabelSkip) {
    (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>)
      .get('renderer')
      .labels.template.adapters.add(
        'text',
        (value: string | undefined, target: am5xy.AxisLabel) => {
          // eslint-disable-next-line no-underscore-dangle
          const { index = 0 } = target.dataItem
            ? (target.dataItem._settings as any)
            : {}; // overriding the type here so we can get the index field
          const { addLabelSkip = 0 } = xAxisSettings;

          if (index % addLabelSkip > 0) {
            return '';
          }

          return value;
        }
      );
  }

  return xAxis;
}

export function createSeriesTarget(
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
  series: am5xy.SmoothedXLineSeries | am5xy.ColumnSeries,
  value: number
) {
  // add series range
  const seriesRangeDataItem = yAxis.makeDataItem({ value, endValue: value });

  series.createAxisRange(seriesRangeDataItem);
  seriesRangeDataItem.get('grid')?.setAll({
    strokeOpacity: 1,
    visible: true,
    stroke: am5.color('#807f83'),
    strokeDasharray: 7,
  });
}

// THIS WILL ONLY CREATE THE OBJECT AND RETURN IT. IT WILL NOT BE ASSIGNED TO A CHART
export function tooltipObj(
  root: am5.Root,
  chart: am5xy.XYChart,
  granularity: GranularityEnum,
  axisType: string,
  xAxisFieldName: string,
  customTooltipTextAdapter?: (
    text: string | undefined,
    target: am5.Label
  ) => any,
  customAdapter: 'text' | 'html' = 'text',
  config?: BarChartConfig
): am5.Tooltip {
  // Add tooltip

  const tooltip = am5.Tooltip.new(root, {
    autoTextColor: false,
    getFillFromSprite: false,
    getStrokeFromSprite: true,
    pointerOrientation: 'horizontal',
    labelText: '[bold]{valueX}[/]',
  });

  tooltip.get('background')?.setAll({
    fill: am5.color('#000000'),
  });

  tooltip.label.setAll({
    fill: am5.color('#ffffff'),
    fontSize: 12,
    fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
  });

  tooltip.label.getDateFormatter().setAll({
    dateFormat: 'MMM dd',
  });

  if (customTooltipTextAdapter) {
    tooltip.label.adapters.add(customAdapter, customTooltipTextAdapter);
  } else {
    tooltip.label.adapters.add('text', (text, target) => {
      if (
        target.dataItem?.dataContext &&
        (target.dataItem?.dataContext as any)[xAxisFieldName]
      ) {
        let newText = '';

        const date = (target.dataItem?.dataContext as any)[xAxisFieldName];
        const dataContext: any = target.dataItem?.dataContext;

        if (axisType === 'date') {
          newText = mapDate(date, granularity);
        } else {
          if (dataContext?.customTooltipTitle) {
            newText = `[bold]${dataContext.customTooltipTitle}[/]`;
          } else {
            newText = `[bold]${date}[/]`;
          }
        }

        chart.series.each(subSeries => {
          const stroke = subSeries.get('stroke')?.toString();
          const fill = subSeries.get('fill')?.toString();
          const color = stroke ? stroke : fill;

          newText +=
            '\n[' +
            color +
            ']●[/] [width:100px]' +
            subSeries.get('name') +
            '[/] [bold]{' +
            subSeries.get(
              config?.direction === 'horizontal' ? 'valueXField' : 'valueYField'
            ) +
            '}';
        });

        return newText;
      }

      return text;
    });
  }

  return tooltip;
}

export const createLineSeries =
  (component: any) =>
  (
    root: am5.Root,
    chart: am5xy.XYChart,
    xAxis:
      | am5xy.DateAxis<am5xy.AxisRenderer>
      | am5xy.CategoryAxis<am5xy.AxisRenderer>,
    yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>,
    settings: LineSeriesSettings,
    granularity: GranularityEnum
  ) => {
    const tooltip = am5.Tooltip.new(root, {
      autoTextColor: false,
      getFillFromSprite: false,
      getStrokeFromSprite: true,
      pointerOrientation: 'horizontal',
      labelText: '[bold]{valueX}[/]',
      keepTargetHover: true,
    });

    tooltip.get('background')?.setAll({
      fill: am5.color('#000000'),
    });

    tooltip.label.setAll({
      fill: am5.color('#ffffff'),
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
    });

    tooltip.label.getDateFormatter().setAll({
      dateFormat: 'MMM dd',
    });

    const xAxisFieldName = component.config.xAxis.field;
    const axisType = component.config.xAxis.axisType;

    tooltip.label.adapters.add('text', (text, target) => {
      if (
        target.dataItem?.dataContext &&
        (target.dataItem?.dataContext as any)[xAxisFieldName]
      ) {
        let newText = '';

        const date = (target.dataItem?.dataContext as any)[xAxisFieldName];
        const dataContext: any = target.dataItem?.dataContext;

        if (axisType === 'date') {
          newText = mapDate(date, granularity);
        } else {
          if (dataContext?.customTooltipTitle) {
            newText = `[bold]${dataContext.customTooltipTitle}[/]`;
          } else {
            newText = `[bold]${date}[/]`;
          }
        }

        chart.series.each(subSeries => {
          const stroke = subSeries.get('stroke')?.toString();
          const fill = subSeries.get('fill')?.toString();
          const color = stroke ? stroke : fill;

          newText +=
            '\n[' +
            color +
            ']●[/] [width:100px]' +
            subSeries.get('name') +
            '[/] [bold]{' +
            subSeries.get(
              component.config?.direction === 'horizontal'
                ? 'valueXField'
                : 'valueYField'
            ) +
            '}';
        });

        return newText;
      }

      return text;
    });

    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(
        root,
        component.config.xAxis.axisType === 'category'
          ? {
              name: settings.name,
              xAxis,
              yAxis,
              valueYField: settings.field,
              categoryXField: component.config.xAxis.field,
              stroke: am5.color(settings.color || '#ff3185'),
              locationX: settings.locationX,
              dy: settings.verticalShift,
              tooltip: settings.tooltipWithoutBullets ? tooltip : undefined,
            }
          : {
              name: settings.name,
              xAxis,
              yAxis,
              valueYField: settings.field,
              valueXField: component.config.xAxis.field,
              stroke: am5.color(settings.color || '#ff3185'),
              locationX: settings.locationX,
              dy: settings.verticalShift,
              tooltip: settings.tooltipWithoutBullets ? tooltip : undefined,
            }
      )
    );

    series.strokes.template.setAll({});
    series.strokes.template.setAll({
      strokeWidth: 1,
    });

    if (settings.bullets) {
      series.bullets.setAll([]);
      series.bullets.push((...parmas) => {
        const [, se, dataItem] = parmas;
        const dataContext = dataItem.dataContext as any;
        const valueField = (se as any).get('valueYField');
        const showFirst = settings.showFirstBullet;
        const hideFirst =
          !showFirst &&
          dataItem.uid === se.dataItems[0].uid &&
          se.dataItems.length > 1;

        if (!dataContext[valueField] || hideFirst) {
          return;
        }

        // creating a dettached tooltip
        const tooltip = tooltipObj(
          component.chartRef.root,
          component.chartRef,
          granularity,
          component.config.xAxis.axisType,
          component.config.xAxis.field,
          component.config.customTooltipTextAdapter
        );

        const circle = am5.Circle.new(root, {
          radius: 4,
          strokeWidth: 1,
          fill: am5.color(settings.color || '#fff'),
          stroke: am5.color(settings.color || '#ff3185'),
          tooltipText:
            component.config.xAxis.axisType === 'category'
              ? '{categoryX}: {valueY}'
              : '{valueY}',
          showTooltipOn: 'hover',
          tooltip, // assigning the dettached tooltip here instead of the series
        });
        // attaching it here vs the series will make sure the tooltip only appears when you hover the circle

        const bullet = am5.Bullet.new(root, {
          sprite: circle,
          locationX: settings.locationX,
        });

        return bullet;
      });
    }

    if (settings.stroke) {
      series.strokes.template.setAll({
        strokeWidth: settings.strokeWidth || 3,
        strokeDasharray: settings.strokeDasharray || [10, 5],
      });
    }

    if (settings.fills) {
      series.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
      });
    }

    if (component.config.targetValue) {
      // adding the dashed line
      createSeriesTarget(yAxis, series, component.config.targetValue);
    }

    // Create modal for a "no data" note
    component.noDateFoundModal = am5.Modal.new(root, {
      content: 'No data to display',
    });

    const self = component;

    series.events.on('datavalidated', ev => {
      if (component.config.useMicroTheme || component.isLoading) {
        return;
      }

      if (ev.target.data.length < 1 || component.noDataFound) {
        component.chartRef.children.each((child: any) => {
          child.set('forceHidden', true);
        });
        self.noDate = true;
        component.noDateFoundModal.open();
      } else if (ev.target.data.length > 1 && !component.noDataFound) {
        component.chartRef.children.each((child: any) => {
          child.set('forceHidden', false);
        });
        self.noDate = false;
        component.noDateFoundModal.close();
      }
    });
    series.data.setAll([]);
    series.data.setAll(component.data);
  };

export function createMapChart(root: am5.Root): am5map.MapChart {
  const chart = root.container.children.push(
    am5map.MapChart.new(root, {
      projection: am5map.geoNaturalEarth1(),
      panX: 'none',
      panY: 'none',
      wheelY: 'none',
      maxZoomLevel: 1,
    })
  );

  return chart;
}

export function createPolygonSeries(root: am5.Root, chart: am5map.MapChart) {
  const polygonSeries = chart.series.push(
    am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodataWorldLow as GeoJSON.GeoJSON,
      exclude: ['AQ'],
      fill: am5.color('#d6d6d6'),
    })
  );

  polygonSeries.mapPolygons.template.setAll({
    interactive: true,
  });

  polygonSeries.mapPolygons.template.states.create('hover', {
    fill: am5.color('#797979'),
  });
  polygonSeries.mapPolygons.template.states.create('active', {
    fill: am5.color('#797979'),
  });

  return polygonSeries;
}

export function createBubbleSeries(
  root: am5.Root,
  chart: am5map.MapChart,
  polygonSeries: am5map.MapPolygonSeries,
  config: MapChartConfig
) {
  const pointSeries = chart.series.push(
    am5map.MapPointSeries.new(root, {
      valueField: config.series.valueField,
      calculateAggregates: config.series.calculateAggregates || true,
      polygonIdField: config.series.idField,
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const circleTemplate: am5.Template<am5.Circle> = am5.Template.new({});

  circleTemplate.events.on('pointerover', ev => {
    const circle = ev.target;

    setPolygonState('hover', polygonSeries, circle);
  });

  circleTemplate.events.on('pointerout', ev => {
    const circle = ev.target;

    setPolygonState('default', polygonSeries, circle);
  });

  pointSeries.bullets.push(
    bubbleMapPointTemplate(
      circleTemplate,
      config.tooltipHTML || '',
      config.useAnimation
    )
  );
  pointSeries.set('heatRules', [
    {
      target: circleTemplate,
      dataField: config.series.valueField,
      min: config.min || 10,
      max: config.max || 30,
      key: 'radius',
    },
  ]);

  return pointSeries;
}

function setPolygonState(
  stateName = 'default',
  polygonSeries: am5map.MapPolygonSeries,
  circle: am5.Circle
) {
  const dataItem = circle.dataItem;

  if (!dataItem) {
    return;
  }
  const dataContext = dataItem.dataContext as { id: string };
  const polygonItem = polygonSeries.getDataItemById(dataContext.id);

  if (!polygonItem) {
    return;
  }
  const polygon = polygonItem.get('mapPolygon');

  polygon.states.applyAnimate(stateName);
}

let isFirstBubble = true;
const bubbleMapPointTemplate =
  (
    circleTemplate: am5.Template<am5.Circle>,
    tooltipHtml = '',
    useAnimation: boolean
  ) =>
  (root: am5.Root) => {
    const container = am5.Container.new(root, {});
    const tooltip = createBulletMapTooltip(root);

    if (!tooltip) {
      return;
    }

    createBubble(
      root,
      container,
      tooltip,
      circleTemplate,
      useAnimation,
      tooltipHtml
    );

    if (isFirstBubble) {
      createBubble(
        root,
        container,
        tooltip,
        circleTemplate,
        useAnimation,
        tooltipHtml
      );
      isFirstBubble = false;
    }

    return am5.Bullet.new(root, {
      sprite: container,
      dynamic: true,
    });
  };

function createBulletMapTooltip(root: am5.Root) {
  const tooltip = am5.Tooltip.new(root, {
    pointerOrientation: 'left',
    keepTargetHover: true,
    getFillFromSprite: false,
    labelText: '[bold]{name}[/]\n{valueX.formatDate()}: {valueY}',
    animationDuration: 0,
  });

  const template = tooltip.get('background');

  if (!template) {
    return;
  }
  template.setAll({
    fill: am5.color('#000'),
  });

  return tooltip;
}

function createBubble(
  root: am5.Root,
  container: am5.Container,
  tooltip: am5.Tooltip,
  circleTemplate: am5.Template<am5.Circle>,
  isAnimated = false,
  tooltipHtml = ''
) {
  const circle = container.children.push(
    am5.Circle.new(
      root,
      {
        radius: 20,
        fillOpacity: 0.7,
        fill: am5.color('#46b6c7'),
        cursorOverStyle: 'pointer',
        tooltip,
        tooltipX: am5.percent(100),
        tooltipHTML: tooltipHtml,
      },
      circleTemplate
    )
  );

  if (isAnimated) {
    circle.animate({
      key: 'scale',
      from: 1,
      to: 1.2,
      duration: 1000,
      easing: am5.ease.inOut(am5.ease.circle),
      loops: Infinity,
    });
    // circle.animate({
    //   key: 'opacity',
    //   from: 1,
    //   to: 0.5,
    //   duration: 1000,
    //   easing: am5.ease.out(am5.ease.bounce),
    //   loops: Infinity,
    // });
  }

  return circle;
}

export function mapDate(date: any, granularity: GranularityEnum) {
  switch (granularity) {
    case GranularityEnum.day:
      return `[bold]${moment(date).format('MMM DD')}[/]`;
    case GranularityEnum.week:
      return `[bold]${
        moment(date).startOf('week').add(1, 'day').format('MMM DD') +
        ' - ' +
        moment(date).endOf('week').add(1, 'day').format('MMM DD')
      }[/]`;
    case GranularityEnum.month:
      return `[bold]${
        moment(date).startOf('month').format('MMM DD') +
        ' - ' +
        moment(date).endOf('month').format('MMM DD')
      }[/]`;
    case GranularityEnum.year:
      return `[bold]${
        moment(date).startOf('year').format('MMM DD') +
        ' - ' +
        moment(date).endOf('year').format('MMM DD')
      }[/]`;
    default:
      return `[bold]${
        moment(date).startOf('week').add(1, 'day').format('MMM DD') +
        ' - ' +
        moment(date).endOf('week').add(1, 'day').format('MMM DD')
      }[/]`;
  }
}
