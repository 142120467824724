// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface Event {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  from: Date;
  to: Date;
  city: string;
  state: string;
  venue: string;
}
