// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { DatePipe } from '@angular/common';
import { generalConstants } from '@config';
import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  format: string;
  constructor() {
    super(generalConstants.defaultLocale);
    const format = generalConstants.dataGridTypes.dateTime;

    this.format = format;
  }

  transform(value, dateFormat: string): any {
    return super.transform(value, dateFormat ? dateFormat : this.format);
  }
}
