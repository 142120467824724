// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface FoundationMeeting {
  pastMeeting: Meeting[];
  upcoming: Meeting[];
}

export interface Meeting {
  name: string;
  date: Date;
}

export interface ZoomMeetingResponse {
  data: ZoomMeeting[];
  metadata: ZoomMeetingResponseMetadata;
}

export interface ZoomMeetingResponseMetadata {
  total: number;
}

export interface ZoomMeeting {
  agenda: string;
  duration: number;
  id: string;
  project: string;
  recordingExtension: string;
  recordingStart: string;
  recordingType: string;
  recordingShareUrl: string;
  recordingPassword: string;
  recordingEnabled: boolean;
  recordingAccess: string;
  recordingTranscriptAccess: string;
  recordingTranscriptEnabled: boolean;
  startTime: string;
  topic: string;
  participants: number;
}

export interface ZoomMeetingsRegistrants {
  registrants: Registrant[];
}

export interface Registrant {
  email: string;
  firstName: string;
  id: string;
  jobTitle: string;
  lastName: string;
  org: string;
  type: string;
  userId?: string;
}

export interface ZoomMeetingOccurrence {
  duration: number;
  occurrenceId: string;
  startTime: string;
  status: string;
}

export interface ZoomMeetingsFilter {
  projectSlug: string;
  visibility?: string;
  limit?: number;
  offset?: number;
  startDate?: string;
  endDate?: string;
  searchTerm?: string;
}

export interface MeetingAttendanceDetails {
  userId: string;
  userFullName: string;
  userPhotoUrl: string;
  accountName?: string;
  projectName: string;
  committeeType: string;
  hours: number;
}
