// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-toggle-pills',
  templateUrl: './toggle-pills.component.html',
  styleUrls: ['./toggle-pills.component.scss'],
})
export class TogglePillsComponent implements OnInit {
  @Input() overlayText: string;
  @Input() leftButtonText: string;
  @Input() rightButtonText: string;
  @Input() isMedium?: boolean;

  isLeftActive = true;
  isRightActive = false;

  constructor() {}

  ngOnInit() {}
}
