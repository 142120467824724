// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface AddRepositoryRoleInput {
  userId: string;
}

export interface CreateRepositoryRoleInput {
  projectId: string;
  repositoryId: string;
  body: AddRepositoryRoleInput;
}

export interface DeleteRepositoryRoleInput {
  projectId: string;
  repositoryId: string;
  roleName: string;
  userId: string;
}
