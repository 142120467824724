// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lfx-ngb-pagination-wrapper',
  templateUrl: './ngb-pagination-wrapper.component.html',
  styleUrls: ['./ngb-pagination-wrapper.component.scss'],
})
export class NgbPaginationWrapperComponent implements OnChanges {
  @Input() boundaryLinks = true;
  @Input() collectionSize = 100;
  @Input() disabled = false;
  @Input() maxSize = 0;
  @Input() page = 1;
  @Input() pageSize = 10;
  // TODO: Remove version input in next PR
  @Input() version = 1;
  @Input() paginationType: PaginationType = PaginationType.DEFAULT;

  @Output() pageChange = new EventEmitter<number>();

  paginationTypeConst = PaginationType;

  getCurrentPageText() {
    return `${this.page * this.pageSize - (this.pageSize - 1)} - ${
      this.page * this.pageSize < this.collectionSize
        ? this.page * this.pageSize
        : this.collectionSize
    } of ${this.collectionSize}`;
  }

  getLastPageIndex() {
    return Math.ceil(this.collectionSize / this.pageSize);
  }

  onPageChange(page: number) {
    if (page !== this.page && page > 0 && page <= this.getLastPageIndex()) {
      this.page = page;
      this.pageChange.emit(page);
    }
  }

  hasReachedEndPage() {
    return this.page === this.getLastPageIndex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.collectionSize) {
      this.page = 1;
    }
  }
}

export enum PaginationType {
  DEFAULT = 'default',
  SHOW_MORE = 'show-more',
}
