// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-page-header',
  templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnInit {
  @Input() pageMainTitle: string;
  @Input() pageSubTitle: string;

  constructor() {}

  ngOnInit() {}
}
