// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { EmailInput } from './update-current-user-input';
export interface UpdateUserInput {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  timezone?: string;
  emails?: EmailInput[];
}
