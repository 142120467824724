// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface WhitelistedDeveloper {
  firstName: string;
  lastName: string;
  requestStatus: WhitelistingStatus;
}

export enum WhitelistingStatus {
  Pending = 'pending',
  Approved = 'approved',
}
