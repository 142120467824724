// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ProjectTransitiveDependency {
  dependencyCount: number;
  sfdcProjectId: string;
}

export interface ProjectDependency {
  projectDependency: ProjectTransitiveDependency[];
}
