<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="custom-modal-container">
  <div class="custom-modal-header">
    <h4 class="custom-modal-title" ngbAutofocus>
      <span *ngIf="pageType === myOrgSpeakers || pageType === allOrgsSpeakers" class="d-flex gap-16">
        Speaker Summary
        <lfx-pii-help-icon *ngIf="pageType === myOrgSpeakers"></lfx-pii-help-icon>
      </span>
      <span *ngIf="pageType === myOrgAttendees" class="d-flex gap-16">
        Attendee Summary
        <lfx-pii-help-icon></lfx-pii-help-icon>
      </span>
      <span *ngIf="pageType === tncLearners" class="d-flex gap-16">
        Courses Enrolled
        <lfx-pii-help-icon dataType="tnc"></lfx-pii-help-icon>
      </span>
      <span *ngIf="pageType === certifiedEmployees" class="d-flex gap-16">
        Exams Passed
        <lfx-pii-help-icon dataType="tnc"></lfx-pii-help-icon>
      </span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="custom-modal-user-data-container">
    <img
      *ngIf="
        pageType === myOrgSpeakers ||
        pageType === allOrgsSpeakers ||
        pageType === myOrgAttendees
      "
      [src]="employee.logo"
      [lfxImageErrorFallback]="employee.name || employee.firstName + employee.lastName"
      class="custom-modal-user-img"
    />
    <img
      *ngIf="pageType === tncLearners"
      [src]="employee.learner.photoUrl"
      [lfxImageErrorFallback]="employee.learner.firstName + employee.learner.lastName"
      class="custom-modal-user-img"
    />
    <img
      *ngIf="pageType === certifiedEmployees"
      [src]="employee.name.image"
      [lfxImageErrorFallback]="employee.name.text"
      class="custom-modal-user-img"
    />
    <div class="flex-column">
      <div class="custom-modal-user-name">
        <span *ngIf="pageType === myOrgSpeakers">{{
          employee.firstName | titlecase
        }} {{employee.lastName | titlecase}}</span>
        <span *ngIf="pageType === allOrgsSpeakers">{{ employee.name }}</span>
        <span *ngIf="pageType === myOrgAttendees">{{
          employee.firstName | titlecase
        }} {{ employee.lastName | titlecase }}</span>
        <span *ngIf="pageType === tncLearners">{{
          employee.learner.firstName | titlecase
        }} {{  employee.learner.lastName | titlecase }}</span>
        <span *ngIf="pageType === certifiedEmployees">{{
          employee.name.text | titlecase
        }}</span>
      </div>
      <div
        class="custom-modal-user-email"
        *ngIf="pageType === myOrgSpeakers || pageType === allOrgsSpeakers"
      >
        {{ employee.email }}
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-data">
    <ngx-spinner type="ball-clip-rotate"
      [fullScreen]="false"
      name="insights-details-dialog-spinner"
      color="rgba(0,0,0,0.5)"
      bdColor="transparent"
      bdOpacity="10"
      size="default"
    >
    </ngx-spinner>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="cascade-table-wrapper">
      <lfx-cascading-row-table
        grayColor="true"
        [canEdit]="false"
        [canDelete]="false"
        [data]="detailsCascadeTableData"
        [columns]="detailsCascadeTableColumns"
        [maxHeight]="'800px'"
      ></lfx-cascading-row-table>
    </div>
    <div *ngIf="paginationTotal" class="pagination-wrapper">
      <lfx-ngb-pagination-wrapper [boundaryLinks]="true"
        [collectionSize]="paginationTotal"
        [maxSize]="paginationMaxSize"
        [pageSize]="paginationSize"
        (pageChange)="onPageChange($event)"
        [page]="paginationPage"
        aria-label="Default pagination"
      >
      </lfx-ngb-pagination-wrapper>
    </div>
  </ng-container>
</div>
