<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MITs -->
<div class="user-profile-cell">
  <div class="avatar">
    <lfx-avatar [name]="name" [imageURL]="imageUrl"></lfx-avatar>
  </div>
  <div class="user-profile-cell-content">
    <span 
      [ngClass]="{'lf-link-v2 clickable': allowProfileClick}"
      (click)="openProfile()"
    >
      {{ name.includes("[unknown]") ? employeeEmail : name }}
    </span>
    <span 
      class="subtext" 
      [class.organization]="organizationName"
      [class.title]="!organizationName && title"
      *ngIf="displaySubtext"
    >
      {{ displaySubtext }}
    </span>
  </div>
</div>
