// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lfx-benefits-used-status',
  templateUrl: './benefits-used-status.component.html',
  styleUrls: ['./benefits-used-status.component.scss'],
})
export class BenefitsUsedStatusComponent implements OnInit {
  @Input() benefitsStatusInputs: any;
  @Output() benefitsMembershipEmitter = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onBenefitsClicked(membership) {
    this.benefitsMembershipEmitter.emit(membership);
  }
}
