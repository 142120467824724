// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ProjectSocialFeed {
  name: string;
  feedItems: FeedItem[];
}

export interface FeedItem {
  network: SocialNetwork;
  date: Date;
  text: string;
}

export enum SocialNetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  LinkedIn = 'LinkedIn',
}
