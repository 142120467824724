// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MentorshipInitiative } from '@models';

@Component({
  selector: 'lfx-crowdfunding-mentorship-card',
  templateUrl: './crowdfunding-mentorship-card.component.html',
  styleUrls: ['./crowdfunding-mentorship-card.component.scss'],
})
export class CrowdfundingMentorshipCardComponent implements OnInit {
  @Input() initiative: MentorshipInitiative;
  @Output() mentorshipEmitter = new EventEmitter<any>();
  showOverlayOnMentorshipCard = false;

  constructor() {}

  ngOnInit() {}

  onClickCard() {
    this.mentorshipEmitter.emit();
  }
}
