// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';
import {
  TwoLayerFunnerChartBackLayer,
  TwoLayerFunnerChartData,
  TwoLayerFunnerChartFrontLayer,
  TwoLayerFunnerChartItem,
  TwoLayerFunnerChartOptions,
} from '@lfx/shared/components/charts/models';
import { isEmpty } from 'lodash';

@Component({
  selector: 'lfx-two-layer-funnel-chart',
  templateUrl: './two-layer-funnel-chart.component.html',
  styleUrls: ['./two-layer-funnel-chart.component.scss'],
})
export class TwoLayerFunnelChartComponent implements OnInit {
  @Input()
  set chartOptions(chartOptions: TwoLayerFunnerChartOptions) {
    if (!isEmpty(chartOptions)) {
      this.initChartOptions(chartOptions);
    }
  }

  echartsFunnelOption: any = {};
  legendOptions: TwoLayerFunnerChartData[] = [];

  constructor() {}

  ngOnInit() {}

  private initChartOptions(chartOptions: TwoLayerFunnerChartOptions) {
    this.legendOptions = chartOptions.backLayer.item.data;
    this.echartsFunnelOption = {
      tooltip: this.getTooltip(chartOptions),
      series: this.getSeries(chartOptions),
    };
  }

  private getTooltip(chartOptions: TwoLayerFunnerChartOptions) {
    return {
      trigger: 'item',
      formatter: params => {
        let dataIndex = params.dataIndex;

        if (params.seriesName === 'front-layer') {
          dataIndex = params.componentIndex - 1;
        }
        let result = `
              <div class="funnel-tooltip-text">
                <div class="width-10 height-10 fas fa-square-full m-r-10" style="color: ${
                  chartOptions.backLayer.item.data[dataIndex].color
                }"></div>
                  <b>${
                    chartOptions.backLayer.item.data[dataIndex].value
                  }</b> ${chartOptions.backLayer.item.data[
          dataIndex
        ].tooltipLabel.toUpperCase()}
              </div>
          `;

        result += chartOptions.frontLayer.items.reduce((template, item) => {
          template += `
              <div class="funnel-tooltip-text">
                <div class="width-10 height-10 fas fa-square-full m-r-10 m-t-5" style="color: ${
                  item.data[dataIndex].color
                }; opacity: ${
            item.data[dataIndex].toolTipOpacity ||
            item.data[dataIndex].opacity ||
            1
          }"></div>
                  <b>${item.data[dataIndex].value}</b> ${item.data[
            dataIndex
          ].tooltipLabel.toUpperCase()}
              </div>
          `;

          return template;
        }, '');

        return result;
      },
    };
  }

  private getSeries(chartOptions: TwoLayerFunnerChartOptions) {
    const series = [];

    series.push(this.getSeriesBack(chartOptions.backLayer));
    series.push(...this.getSeriesFront(chartOptions.frontLayer));

    return series;
  }

  private getSeriesBack(backLayerOptions: TwoLayerFunnerChartBackLayer) {
    return {
      name: 'back-layer',
      type: 'funnel',
      left: '5%',
      top: 0,
      width: '90%',
      height: '100%',
      orient: 'horizontal',
      label: {
        show: false,
      },
      labelLine: {
        show: false,
        formatter: '',
      },
      data: this.calculateBackLayerData(backLayerOptions.item.data),
      minSize: 60,
      gap: 5,
    };
  }

  private getSeriesFront(frontLayerOptions: TwoLayerFunnerChartFrontLayer) {
    return this.calculateFrontLayerData(frontLayerOptions.items).map(
      (current, index) => {
        const maxSize = 80 - 20 * index + '%';
        const minSize = index === 3 ? 60 : 60 - 20 * index + '%';
        const left = 5 + 22.7 * index + '%';

        return {
          name: 'front-layer',
          type: 'funnel',
          left,
          top: '5%',
          orient: 'horizontal',
          width: '22%',
          height: '90%',
          maxSize,
          label: {
            show: false,
            formatter: '',
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            borderWidth: 0,
          },
          data: current,
          z: 100,
          minSize,
        };
      }
    );
  }

  private calculateBackLayerData(data: TwoLayerFunnerChartData[]) {
    return data.reduce((previous, current) => {
      previous.push(this.calculateDataItem(current));

      return previous;
    }, []);
  }

  private calculateFrontLayerData(items: TwoLayerFunnerChartItem[]) {
    const layers = items.reduce(
      (itemsPrevious, itemsCurrent) =>
        itemsCurrent.data.reduce((dataPrevious, dataCurrent, dataIndex) => {
          dataPrevious[dataIndex].push(dataCurrent);

          return dataPrevious;
        }, itemsPrevious),
      [[], [], [], []]
    );
    const layersCalculated = [];

    for (const layer of layers) {
      layersCalculated.push(this.calculateOneFrontLayer(layer));
    }

    return layersCalculated;
  }

  private calculateOneFrontLayer(layerData) {
    const total = layerData.reduce(
      (total, current) => total + current.value,
      0
    );

    return layerData.reduce((previous, current) => {
      previous.push(this.calculateDataItem(current, total));

      return previous;
    }, []);
  }

  private calculateDataItem(data: TwoLayerFunnerChartData, layerTotal = 0) {
    const item: any = {
      value: data.value,
      name: data.name,
      itemStyle: {
        color: data.color,
        opacity: data.opacity === undefined ? 1 : data.opacity,
      },
    };

    if (layerTotal) {
      item.itemStyle.width = Math.round((data.value * 100) / layerTotal) + '%';
    }

    return item;
  }
}
