// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'domainFormat',
})
export class DomainFormatPipe implements PipeTransform {
  constructor() {}

  transform(domain: string): string {
    return domain.replace(
      /(?:https?:\/\/)?(?:www\.)?((?:\w*\.?)*)\/?\??.*/g,
      '$1'
    );
  }
}
