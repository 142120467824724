// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface MailingList {
  name: string;
  members: number;
  messages: number;
}

export interface MailingListSubscriber {
  memberId: string;
  memberDisplayName: string;
  userPhotoUrl: string;
  projectName: string;
  accountName: string;
}
