// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() badgeUrl: string;

  constructor() {}

  ngOnInit() {}
}
