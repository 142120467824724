<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="chart-section d-flex m-l-3">
  <div class="chart-title-wrapper align-self-stretch d-flex align-items-center">
    <div class="chart-title">
      {{chartTitle}}
    </div>
  </div>
  <div class="chart-container">
    <div echarts [options]="echartsOptions" class="chart" (chartMouseOver)="chartMouseOver.emit($event)" style="height: 400px;"
      (chartMouseMove)="chartMouseMove.emit($event)" (chartMouseOut)="chartMouseOut.emit($event)" (chartClick)="chartMouseOut.emit($event)"></div>
  </div>
  <div class="legend-container" *ngIf="echartsOptions && echartsOptions.series">
    <ng-container *ngFor="let series of echartsOptions.series">
      <div class="legend-item d-flex" [class.legend-stork]="series.hidden" *ngIf="getTotalValue(series.data) !== 0 || series.hidden" (click)="toggleBar(series)">
        <div class="legend-bullet">
          <span [style.background]="series.itemStyle?.color"></span>
        </div>
        <div class="legend-text">
          {{ series.name }}
        </div>
        <div *ngIf="series.legendInfoIconText" class="position-absolute chart-popover-icon">
          <i class="fas fa-info-circle"
             [ngbPopover]="series.legendInfoIconText"
             popoverClass="chart-popover"
             placement="left"
             triggers="mouseenter:mouseleave">
          </i>
        </div>
      </div>
    </ng-container>
  </div>
</div>
