// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Boost from 'highcharts/modules/boost';
import noData from 'highcharts/modules/no-data-to-display';

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'lfx-packed-bubble-chart',
  templateUrl: './packed-bubble-chart.component.html',
  styleUrls: ['./packed-bubble-chart.component.scss'],
})
export class PackedBubbleChartComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() public width = 600;
  @Input() public height = 400;
  @Input() options: any;
  @Output() chartCreated: EventEmitter<any> = new EventEmitter<any>();

  chart: Highcharts.Chart;
  chartElement: HTMLElement;
  constructor(private renderer2: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {
    this.chartElement = this.renderer2.createElement('chart');
    this.chartElement.id = 'chart' + Math.random();
    this.renderer2.appendChild(
      this.elementRef.nativeElement,
      this.chartElement
    );
    this.createChart();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    this.renderer2.removeChild(
      this.elementRef.nativeElement,
      this.chartElement
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentOptions = changes.options.currentValue;

    if (currentOptions) {
      this.options = {
        ...currentOptions,
        chart: {
          ...currentOptions.chart,
          type: 'packedbubble',
          height: this.height,
          width: this.width,
        },
        credits: {
          enabled: false,
        },
      };
    }

    this.createChart();
  }

  private createChart() {
    if (this.chartElement) {
      this.chart = Highcharts.chart(this.chartElement.id, this.options);

      this.chart.update(this.options);
      this.chart.redraw();
    }
  }
}
