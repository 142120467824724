// Copyright The Linux Foundation and each contributor to LFX.

import { DataCell } from '@lfx/shared/interfaces';
import { EmployeeOrgAffiliation } from '.';

// SPDX-License-Identifier: MITs
export interface ProjectContribution {
  title: string;
  totalCount: number;
  insights: Insight[];
  tooltipText?: string;
}
export interface Insight {
  count: number;
  subtitle: string;
  tooltipText?: string;
}

export interface MembershipProjectsRow {
  logo: string;
  id: string;
  name: string;
  slug: string;
  start_date: number;
}

export interface ContributionProject {
  projectId: string;
  projectName: string;
  foundationId: string;
  projectType: string;
  projectSlug: string;
  membershipStatus: string;
  commitCount: number;
}

export interface Productivity {
  countCommits: number;
  countPullrequests: number;
  countIssues: number;
  countLinesAdded: number;
  countLinesDeleted: number;
  countCodeReviews: number;
  compareDateRange: string;
}

export interface Contributors {
  displayName: string;
  logoUrl: string;
  countContributions: number;
}

export interface last30DaysActivities {
  day: string;
  countContributions: number;
}

export interface TotalProjectsCountRes {
  countProjects: number;
  compareDateRange: string;
}

export interface ProjectsRes {
  project: string;
  projectName: string;
  projectId: string;
  countCommits: number;
  countPullrequests: number;
  countIssues: number;
  compareDateRange: string;
  contributors: [Contributors];
  last30DaysActivities: [last30DaysActivities];
}

export interface ProjectsWeContributeCodeToResult {
  totalProjectsCountRes: [TotalProjectsCountRes];
  projectsRes: [ProjectsRes];
}

export interface AllProjectsCubeResult {
  getProjects: ContributionProject[];
}

export interface BaseProjectContributionInput {
  organizationUrl?: string;
  organizationId?: string;
  projectSlug?: string;
  projectId?: string;
  foundationId?: string;
  dateRange?: string;
  foundationSlug?: string;
  limit?: number;
  isAllOrg?: boolean;
}

export type ProjectContributionPageParams = Pick<
  BaseProjectContributionInput,
  'dateRange' | 'projectSlug' | 'projectId' | 'foundationId'
>;

export interface ContributorsGrowthResponse {
  code: number;
  message?: string;
  cummulativeCount: {
    totalContributors: CountDiff;
    committers: CountDiff;
    maintainers: CountDiff;
    reviewers: CountDiff;
  };
  chart: ContributorsGrowthResponseChart[];
}

interface ContributorsGrowthResponseChart {
  countCommitters: number;
  countMaintainers: number;
  countReviewers: number;
  timestamp: string;
  label: string;
}

interface CountDiff {
  count: number;
  prevCount: number;
  percentageDiff: number;
}

export interface ProductivityCubeResult {
  getProductivity: Productivity[];
}

export interface getProjectWeContributeCodeToResult {
  getProjectWeContributeCodeTo: ProjectsWeContributeCodeToResult;
}

export interface ContributingCountriesResponse {
  code: number;
  message?: string;
  chart: ContributingCountriesChart[];
  top5: Top5[];
}

interface ContributingCountriesChart {
  countContributors: number;
  country: string;
}
interface Top5 {
  country: string;
  countPercent: string;
  prevCountPercent: string;
  percentageDiff: number;
}

export interface Top10OrgsByCommitsResponse {
  code: number;
  message?: string;
  orgCommitsRes: Top10OrgsByCommits[];
}

interface Top10OrgsByCommits {
  orgName: string;
  logoUrl: string;
  commits: number;
}

export interface AllOrgsContributionsResponse {
  code: number;
  message?: string;
  allOrgsContributionsRes: AllOrgsContributions;
}

export interface AllOrgsContributions {
  countCommits: number;
  countContributions: number;
  countPullrequests: number;
  countIssues: number;
  countLinesAdded: number;
  countLinesDeleted: number;
  countProjects: number;
  countContributors: number;
  countContributorsCommitters: number;
  countContributorsSubmitters: number;
}

export interface TotalCommitsByOrgResponse {
  code: number;
  message?: string;
  totalCommitsByOrgs: TotalCommitsByOrg[];
}

export interface TotalCommitsByOrg {
  countCommits: number;
  timestamp: string;
  timestampMonth: string;
}

export interface IssuesByOrgsResponse {
  code: number;
  message?: string;
  issuesByOrgs: IssuesByOrg[];
}

export interface IssuesByOrg {
  countIssues: number;
  countIssuesCreated: number;
  countIssuesCommented: number;
  countIssuesResolved: number;
  timestamp: string;
  timestampMonth: string;
}

export interface PRsMergedByOrgsResponse {
  code: number;
  message?: string;
  prsMergedByOrgs: PRsMergedByOrg;
}

export interface PRsMergedByOrg {
  merged: number;
  approved: number;
  submitted: number;
  reviewed: number;
}

export interface ContributorLeaderboardResponse {
  code: number;
  message?: string;
  contributorLeaderboard: [ContributorLeaderboard];
}

export interface ContributorLeaderboard {
  contributorId: string;
  displayName: string;
  username: string;
  logoUrl: string;
  accountId: string;
  contributionCount: number;
  contributionPercentage: number;
  isMyContributor: boolean;
  userId: string;
}

export interface OrganizationLeaderboardResponse {
  code: number;
  message?: string;
  organizationLeaderboard: [OrganizationLeaderboard];
}

export interface OrganizationLeaderboard {
  accountId: string;
  accountName: string;
  logoUrl: string;
  contributionPercentage: number;
  isMyOrg: boolean;
}

export interface SubProjectsStatsResponse {
  code: number;
  message?: string;
  subProjectsStatsRes: SubProjectsStat[];
}

export interface SubProjectsStat {
  project: string;
  projectName: string;
  countContributions: number;
  countContributors: number;
  countCommits: number;
  countCommitters: number;
}

export interface MailingListResponse {
  mailingLists: MailingListsRes;
  mailingListsByList: MailingListsByListRes;
}

export interface MailingListsRes {
  code: number;
  message?: string;
  data: MailingListCount[];
}

export interface MailingListsByListRes {
  code: number;
  message?: string;
  data: MailingListByList[];
}

export interface MailingListCount {
  pkId: string;
  activeMailingLists: number;
  totalSubscribers: number;
  newSubscribers: number;
  activeMailingListsChange: number;
  totalSubscribersChange: number;
  newSubscribersChange: number;
}

export interface MailingListByList {
  mailingList: string;
  isListActive: boolean;
  totalSubscribers: number;
  newSubscribers: number;
  newSubscribersChange: number;
}

export enum DBTYearFilter {
  ALL_TIME = 'ALL_TIME',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  PREV_YEAR = 'PREV_YEAR',
  THIRD_LAST_YEAR = 'THIRD_LAST_YEAR',
  FOURTH_LAST_YEAR = 'FOURTH_LAST_YEAR',
}

export interface DBTInput {
  organizationId: string;
  year: DBTYearFilter;
  projectSlug?: string;
}

export interface BoardMeetingsResponse {
  code: number;
  message?: string;
  data: BoardMeeting[];
}

export interface BoardMeeting {
  attendenceFieldCombined: string;
  orgAttendancePercent: number;
  orgAttendancePercentChange: number;
  orgLastMeetingAttended: string;
  orgMeetings: number;
  orgMeetingsChange: number;
  projectId: string;
  projectName: string;
}

export const getYearFromDateRange = (dateRange: string): DBTYearFilter => {
  switch (dateRange) {
    case 'all_time':
      return DBTYearFilter.ALL_TIME;
    case `${new Date().getFullYear()}`:
      return DBTYearFilter.CURRENT_YEAR;
    case `${new Date().getFullYear() - 1}`:
      return DBTYearFilter.LAST_YEAR;
    case `${new Date().getFullYear() - 2}`:
      return DBTYearFilter.PREV_YEAR;
    case `${new Date().getFullYear() - 3}`:
      return DBTYearFilter.THIRD_LAST_YEAR;
    case `${new Date().getFullYear() - 4}`:
      return DBTYearFilter.FOURTH_LAST_YEAR;
    default:
      return DBTYearFilter.ALL_TIME;
  }
};

export interface ContributorTeam {
  contributorId: string;
  userId: string;
  userDisplayName: string;
  userName: string;
  userPhotoUrl: string;
  orgAffiliation: EmployeeOrgAffiliation[];
  commitCount: number;
  lastCommitTs: string;
  isCommitter: boolean;
  isMaintainer: boolean;
  isReviewer: boolean;
  jobTitle: string;
  country: string;
  github: string;
  memberEmail: string;
  accountName: string;
  isContributor: boolean;
  mostActiveProject: string;
  mostActiveFoundation: string;
  highestType: string;
}

export interface WorkTimeDistribution {
  month: number;
  quarter: number;
  year: number;
  businessHoursCommits: number;
  nonBusinessHoursCommits: number;
  weekendCommits: number;
}

export interface ProjectRow {
  name: DataCell;
  type: DataCell;
  membershipStatus: DataCell;
  noOfCommits: DataCell;
  anchorTag: string;
  projectId: string;
  foundationId: string;
  managerEmail?: string;
  managerName?: string;
  projectName: string;
}

export interface NestedProjectRow extends ProjectRow {
  children: ProjectRow[];
}

export interface NestedProjectRow extends ProjectRow {
  children: ProjectRow[];
}
