<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div
  *ngIf="benefitsStatusInputs?.benefitsLoaded && benefitsStatusInputs?.benefitsUsed > -1"
  class="benefits-container"
  (click)="onBenefitsClicked(benefitsStatusInputs.membership)"
>
  <div class="d-flex align-items-center">
    <div class="benefits-progress-bar" *ngIf="benefitsStatusInputs.benefitsUsed <= 100">
      <div
        class="benefits-status-bar"
        [ngStyle]="{ width: benefitsStatusInputs.benefitsUsed + '%' }"
      ></div>
    </div>
    <div *ngIf="benefitsStatusInputs.benefitsUsed >= 0" class="benefit-per">
      {{ benefitsStatusInputs.benefitsUsed + '%' }}
    </div>
  </div>
  <div class="benefits-text lf-link" *ngIf="benefitsStatusInputs.benefitsUsed === 0">
    <span>Claim your Benefits</span>
  </div>
  <div
    class="text-start cursor-pointer"
    *ngIf="benefitsStatusInputs.benefitsUsed > 0 && benefitsStatusInputs.benefitsUsed <= 100"
  >
    Benefits Claimed
  </div>
</div>
<div
  *ngIf="benefitsStatusInputs?.benefitsLoaded && benefitsStatusInputs?.benefitsUsed === -1"
  class="benefits-container"
>
  <div class="no-benefits-text">Benefits not published to LFX</div>
</div>
<div *ngIf="!benefitsStatusInputs?.benefitsLoaded" class="benefits-container">
  <div class="row loading-data">
    <ngx-spinner
      type="ball-clip-rotate"
      [fullScreen]="false"
      name="dashboardBenefitsSpinner"
      color="rgba(0,0,0,0.5)"
      bdColor="#fff"
      bdOpacity="10"
      size="default"
    ></ngx-spinner>
  </div>
</div>
