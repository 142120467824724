// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lfx-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  @Input() public placeholder = 'Search';
  @Input() public triggeringTextLength = 3; // Length after which the event will trigger
  @Input() public triggerWhenEmpty = true; // Triggers textChange event when the search box is empty
  @Input() public searchText = '';
  @Input() public maxLength = 255;
  @Input() public notFound = false;
  @Input() public notFoundText = 'Not found';
  @Input() public useTimeOut = true;

  @Output() public readonly textChange: EventEmitter<any> =
    new EventEmitter<any>();

  @ViewChild('search', { static: true }) public inputBox: any = ''; // accessing the reference element

  public hasError = false;
  public timeout: any = null;
  public errorMsg = '';

  public ngOnInit(): void {}

  public onSearchChange(event: Event): boolean {
    const restrictedChars = /["\\]/;

    this.searchText = (event.target as HTMLInputElement).value;

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    if (restrictedChars.test(this.searchText)) {
      this.hasError = true;
      this.errorMsg = 'Special characters like " and \\ are not allowed.';

      return false;
    }

    if (this.searchText.length >= this.maxLength) {
      this.hasError = true;
      this.errorMsg = 'Search max length is exceeded.';

      return false;
    }

    if (
      (this.searchText.length > this.triggeringTextLength ||
        (!this.searchText.length && this.triggerWhenEmpty)) &&
      (this.searchText.replace(/\s/g, '').length ||
        this.searchText.length <= this.triggeringTextLength)
    ) {
      if (this.useTimeOut) {
        this.timeout = setTimeout(() => {
          this.hasError = false;
          this.textChange.emit(this.searchText);
        }, 400);
      } else {
        this.hasError = false;
        this.textChange.emit(this.searchText);
      }
    } else {
      this.hasError = true;
      this.errorMsg =
        this.searchText.replace(/\s/g, '').length === 0
          ? 'Only spaces are not allowed'
          : 'Minimum ' +
            (this.triggeringTextLength + 1) +
            ' characters are required.';
    }

    return false;
  }

  public clearSearchText(): void {
    this.inputBox.nativeElement.value = '';
    this.searchText = '';
    this.hasError = false;
    this.errorMsg = '';
    this.textChange.emit(this.searchText);
  }
}
