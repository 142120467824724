// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UserGroup } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateFoundationCommitteeResult {
  updateCommittee: UpdateCommitteePayload;
}

export interface UpdateCommitteePayload extends MutationPayload {
  committee?: UserGroup;
}
