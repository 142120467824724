// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Observable } from 'rxjs';

/**
 * Interface to be impemented by any storage/caching service.
 * This would help make sure that we don't lose typesafty through
 * our storage services.
 * we use in the frontend. If a much complex caching service
 * being used in the future, it should extend this interface
 *
 *
 * *usage*
 *
 * ```ts
 *  @Injectable({...})
 *  class ChacheService implements Storage {
 *
 *    getItem<T>(key: T}: Observable<T> { ...}
 *    setItem<T, U>(key: T, value: U) {}
 *  }
 * ```
 */
export interface TypedStorage {
  getItem<T>(key: string): Observable<T>;
  setItem<T>(key: string, value: T): void;
  removeItem(key: string): void;
  clear(key: string): void;
}
