// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
type ProjectTypes = 'foundation' | 'project' | 'Project Group';
type OrderBy =
  | 'createddate'
  | 'name'
  | 'status'
  | 'renewalType'
  | 'projectSector'
  | 'executiveDirector'
  | 'programManager';

export interface SearchFoundationsInput {
  pageSize?: number;
  orderBy?: OrderBy;
  limit?: number;
  sortDir?: 'asc' | 'desc';
  name?: string[];
  description?: string;
  status?: string[];
  projectType?: ProjectTypes[];
  projectSector?: string[];
  repository?: string[];
  slug?: string[];
  type?: string[];
  id?: string[];
  excludeSubproject?: boolean;
  enabledService?: string[];
  filter?: string;
}
