// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { EChartsOption } from 'echarts';

export const chartConfig = ({
  chartData = [],
  fnTableRowsMap,
  yAxisName = '# of Contributions',
}) => {
  const innerChartData =
    (chartData && chartData.filter(item => !item.ym.includes('1970'))) || [];
  const chartLabels = innerChartData.map(item => convertDate(item.ym));

  const chartOption: EChartsOption = {
    title: {
      textStyle: {
        color: '#333333',
        fontFamily: 'Source Sans Pro',
        fontSize: 12,
        fontWeight: 'bold',
      },
      top: 20,
    },
    grid: {
      left: 50,
      right: 20,
      top: 20,
      bottom: 20,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#000',
      borderWidth: 1,
      borderColor: '#D9E0E7',
      padding: 12,
      // opacity: 0.1,
      textStyle: {
        color: '#252525',
        fontFamily: 'Source Sans Pro',
        fontSize: 13,
      },
      axisPointer: {
        type: 'none',
      },
      formatter: tooltipFormatter(fnTableRowsMap),
    },
    xAxis: {
      type: 'category',
      data: chartLabels,
      axisTick: {
        show: false,
        alignWithLabel: true,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        color: '#00070A',
        fontFamily: 'Open Sans',
        fontSize: 11,
        fontWeight: 400,
        lineHeight: 13.2,
        align: 'center',
        fontStyle: 'normal',
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      name: yAxisName,
      nameLocation: 'middle',
      minInterval: 1,
      nameTextStyle: {
        color: '#626469',
        fontFamily: 'Open Sans',
        fontSize: 11,
        fontWeight: 400,
        lineHeight: 13.2,
        align: 'center',
      },
      nameGap: 50,
      axisLabel: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    series: {
      smooth: true,
      data: innerChartData,
      type: 'line',
      symbol: 'circle',
      symbolSize: 0,
      lineStyle: {
        color: '#009ADE',
      },
    },
  };

  return chartOption;
};

// const fnTableRowsMap = (row: userTechnicalRow) => ({
//   ['PR Open']: row.propened,
//   ['PR Merged']: row.prmerged,
//   ['Issues Open']: row.issuesopen,
//   ['Issues Closed']: row.issuesclosed,
// });

const tooltipFormatter = fnTableRowsMap => params => {
  let result = '';

  params.forEach(chartPointInfo => {
    if (chartPointInfo.value === 0) {
      return;
    }

    result += getTooltipContentForUserTechnical(
      fnTableRowsMap(chartPointInfo.data),
      chartPointInfo.name,
      chartPointInfo.value
    );
  });

  return result;
};

const getTooltipContentForUserTechnical = (
  rowMap: { [key: string]: number },
  name = '',
  value = ''
) => {
  const tableTrs = Object.keys(rowMap)
    .map(key => tableRow(key, rowMap[key]))
    .join('');

  return `
  <div style="font-size: 12px !important; font-family: 'Open Sans' !important" class="no-style-table">
    ${tooltipTitle(name, value)}
    <table>
    ${tableTrs}
    </table>
  </div>
  `;
};

const tooltipTitle = (title, value) =>
  `<div class="title">${title}<span style="display:none">: ${value}</span></div>`;

const tableRow = (name, value) => `<tr><td>${name}</td><td>${value}</td></tr>`;

// covert string yyyy-mm to Jan 2020
const convertDate = (date: string) => {
  const [year, month] = date.split('-');

  return (
    new Date(Number(year), Number(month) - 1).toLocaleString('default', {
      month: 'short',
    }) +
    '\n' +
    year
  );
};
