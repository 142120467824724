// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DataSet } from '../models';
import { ChartsVisualization } from '@lfx/core/models';
import { Chart, ChartTypeRegistry, registerables } from 'chart.js';

Chart.register(...registerables);

@Component({
  selector: 'lfx-pie-chart',
  templateUrl: './pie-chart.component.html',
})
export class PieChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() chartColors: string[];
  @Input() chartData: any;
  @Input('visualizationData')
  set visualizationData(visualization: ChartsVisualization) {
    this.dataSets = visualization.dataSets;
    this.xAxesLabels = visualization.labels;
  }

  @Input() fill: boolean;
  @Input() showLegend = true;
  @Input() customTooltip = {};
  @ViewChild('chartCanvas') canvas: ElementRef;

  chartType: keyof ChartTypeRegistry = 'pie';
  dataSets: DataSet[] = [];
  xAxesLabels: (string | number)[] = [];
  chart: Chart;
  options: any = {
    responsive: true,
    maintainAspectRatio: false,
  };

  borderWidth = '1';

  /**
   * colors to Use must be HEX to support the hexRoRgb method
   */
  private fillColors = [
    '#D32F2F',
    '536DFE',
    '#FFA000',
    '#4CAF50',
    '#795548',
    '#0288D1',
  ];

  private borderColors = [
    '#D32F2F',
    '536DFE',
    '#FFA000',
    '#4CAF50',
    '#795548',
    '#0288D1',
  ];

  constructor() {}

  ngOnInit() {}
  ngOnChanges(): void {
    if (this.chart) {
      this.updateChart();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createChart();

      if (this.chartData !== undefined) {
      }
    });
  }

  private createChart() {
    const canvas = this.canvas.nativeElement.getContext('2d');

    this.chart = new Chart(canvas, {
      type: this.chartType,
      data: {
        labels: this.xAxesLabels,
        datasets: this.dataSets.map((dataSet, index) =>
          Object.assign({}, this.createExtraSettingsForDataSet(index), dataSet)
        ),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: this.showLegend,
            position: 'right',
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            ...this.customTooltip,
          },
        },
      },
    });
  }

  private updateChart() {
    this.chart.data = {
      labels: this.xAxesLabels,
      datasets: this.dataSets.map((dataSet, index) =>
        Object.assign({}, this.createExtraSettingsForDataSet(index), dataSet)
      ),
    };
    this.chart.update();
  }

  private hexToRgb(hex, opacity = 1) {
    hex = hex || this.addColor();
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
      return hex;
    }

    return (
      'rgba( ' +
      parseInt(result[1], 16) +
      ',' +
      parseInt(result[2], 16) +
      ',' +
      parseInt(result[3], 16) +
      ',' +
      opacity +
      ')'
    );
  }

  private generateRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  private addColor() {
    const newColor = this.generateRandomColor();

    this.fillColors.push(newColor);
    this.borderColors.push(newColor);

    return newColor;
  }

  private generateBackgrounds(index: number) {
    return this.dataSets[index].data.map((item, i) =>
      this.hexToRgb(this.fillColors[i], 0.6)
    );
  }

  private createExtraSettingsForDataSet(index: number): any {
    return {
      backgroundColor: this.generateBackgrounds(index),
      fill: this.fill ? 'origin' : false,
      borderWidth: this.borderWidth,
    };
  }
}
