// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-outline-btn',
  templateUrl: './outline-btn.component.html',
  styleUrls: ['./outline-btn.component.scss'],
})
export class OutlineBtnComponent implements OnInit {
  @Input() isActive = false;
  @Input() type: 'default' | 'primary' = 'default';
  @Input() label = 'All';

  constructor() {}

  ngOnInit() {}
}
