// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CompanyProjectMetrics {
  claManagersCount: string;
  companyId: string;
  companyName: string;
  contributorsCount: string;
  projectId: string;
  projectName: string;
  projectLogoUrl?: string;
}
