// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface TwoSeriesBarChartLegend {
  colors: string[];
  texts: string[];
}

export interface TwoSeriesBarChartToolTip {
  colors: string[];
  texts: string[];
}

export interface TwoSeriesDataSet {
  data: number[];
}

export interface TwoSeriesMarkAreaDataSet {
  data: MarkAreaDataSetValue[];
}

export interface MarkAreaDataSetValue {
  value: number;
  itemStyle?: {
    color?: string;
    borderWidth?: number;
    borderColor?: string;
    borderRadius?: number[];
  };
}
