// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { generalConstants } from '@lfx/config/general';

export enum AddContributorCriteria {
  DOMAIN = 'Domain' as any,
  EMAIL_ADDRESS = 'Contributor’s Gerrit/GitHub Email' as any,
  GITHUB_ORGANIZATION = 'GitHub Organization' as any,
  GITHUB_USERNAME = 'GitHub Username' as any,
}

export const AddContributorCriteriaTypes = {
  DOMAIN: {
    name: AddContributorCriteria.DOMAIN,
    pattern: generalConstants.domainREgex,
  },
  EMAIL_ADDRESS: {
    name: AddContributorCriteria.EMAIL_ADDRESS,
    pattern: generalConstants.emailRegex,
  },
  GITHUB_ORGANIZATION: {
    name: AddContributorCriteria.GITHUB_ORGANIZATION,
    pattern: '',
  },
  GITHUB_USERNAME: {
    name: AddContributorCriteria.GITHUB_USERNAME,
    pattern: generalConstants.githubUsernameRegex,
  },
};

export const approvalListKeys = [
  'Domain',
  'Email',
  'GithubOrg',
  'GithubUsername',
];

export const approvalCriteriaMessage = `Before newly added contributor(s) can start committing code, they will need to choose "Corporate Contributor"
from the EasyCLA check in their pull request and then select their company. They will
need to do this only once. After completing this selection, the EasyCLA check will be complete and
enabled for all future code contributions for this project.`;

export const githubCriterialMessage = `Only public members of this GitHub organization will be able to commit code as part of this approval list.`;
