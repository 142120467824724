// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface FoundationDomain {
  name: string;
  state: string;
}

export interface DomainAvailability {
  action: string;
  available: boolean;
  domain: string;
  premium: boolean;
  price: number;
}

export interface DomainInfo {
  name: string;
  project: string;
  available: boolean;
}

export interface DomainInfoResult {
  code: string;
  message: string;
  success: string;
}
