// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

import { BillingAddress } from '@models';

import { isEmpty } from 'lodash';

interface AddressResponse {
  addressLine1: string;
  addressLine2: string;
}

@Pipe({
  name: 'fullAddress',
})
export class FullAddress implements PipeTransform {
  constructor() {}

  transform(address: BillingAddress): AddressResponse {
    if (!!!address) {
      return {
        addressLine1: '',
        addressLine2: '',
      };
    }

    let addressLine1 = address.billingStreet;

    let addressLine2 = address.billingCity;

    if (!isEmpty(addressLine2)) {
      addressLine2 = `${addressLine2}, `;
    }

    if (!isEmpty(address.billingState) && !isEmpty(address.billingPostalCode)) {
      addressLine2 += `${address.billingState} ${address.billingPostalCode}, `;
    }

    if (!isEmpty(address.billingCountry)) {
      addressLine2 += address.billingCountry;
    }

    if (isEmpty(addressLine1) && isEmpty(addressLine2)) {
      addressLine1 = 'No address provided';
    }

    return {
      addressLine1: !isEmpty(addressLine1) ? addressLine1 : '',
      addressLine2: !isEmpty(addressLine2) ? addressLine2 : '',
    };
  }
}
