// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  SbomSoftwareEvaluationResponse,
  UploadSbomFileModalStatus,
} from '@lfx/core/models';
import {
  CompanyService,
  SoftwareInventoryService,
  UserService,
} from '@lfx/core/services';
import { UploadSbomFileModalComponent } from '@lfx/modules/software-inventory/components/upload-sbom-file-modal/upload-sbom-file-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lfx-dashboard-software-valuation',
  templateUrl: './dashboard-software-valuation.component.html',
  styleUrls: ['./dashboard-software-valuation.component.scss'],
})
export class DashboardSoftwareValuationComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() sbomFiles = [];
  @Input() softwareEvaluationStats;
  @Input() valuationRes: SbomSoftwareEvaluationResponse;
  @Input() isLoading;
  @Input() isDashboardPage = true;
  isCompanyAdmin$ = this.userService.isCompanyAdmin(true);
  noFilesState = false;
  inProgressState = false;
  successState = false;
  comingSoonMode = false;
  uploadingSbomSubscription = new Subscription();

  constructor(
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private softwareInventoryService: SoftwareInventoryService
  ) {}

  get showDisclaimer() {
    return (
      this.valuationRes &&
      this.valuationRes.status &&
      this.valuationRes.status.toLowerCase() !== 'completed' &&
      this.valuationRes.status.toLowerCase() !== 'failed'
    );
  }

  ngOnDestroy(): void {
    if (this.uploadingSbomSubscription) {
      this.uploadingSbomSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isLoading) {
      if (
        this.sbomFiles.find(file => file.status.toLowerCase() === 'active') ||
        !this.isDashboardPage
      ) {
        this.successState = true;
        this.noFilesState = false;
        this.inProgressState = false;
      } else if (
        this.sbomFiles.find(file => file.status.toLowerCase() === 'uploading')
      ) {
        this.successState = false;
        this.noFilesState = false;
        this.inProgressState = true;
      } else {
        this.successState = false;
        this.noFilesState = true;
        this.inProgressState = false;
      }
    }
  }

  ngOnInit(): void {
    this.uploadingSbomSubscription =
      this.softwareInventoryService.sbomFileUploading$.subscribe(uploading => {
        if (uploading) {
          this.successState = false;
          this.noFilesState = false;
          this.inProgressState = true;
        }
      });
  }

  redirectToSoftwareInventoryPage() {
    this.router.navigate([
      `/${this.companyService.getCurrentCompanySlug()}/software-inventory`,
    ]);
  }

  uploadSbom() {
    const modalRef = this.modalService.open(UploadSbomFileModalComponent, {
      modalDialogClass: 'upload-sbom-file-dialog',
      keyboard: false,
      size: 'sm',
      backdrop: 'static',
    });

    modalRef.closed.pipe(take(1)).subscribe(reason => {
      if (reason.status === UploadSbomFileModalStatus.SUCCESS) {
        this.softwareInventoryService.setSbomFileUploading(true);
      }
    });
  }
}
