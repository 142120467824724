// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface SecurityLanguage {
  externalId: string;
  language: string;
  numberOfBytes: number;
  sfdcProjectId: string;
}

export interface SecurityLanguagesDistribution {
  securityLanguagesDistribution: any;
  languages: [SecurityLanguage];
  summary: {
    totalNumberOfBytes: number;
    totalProjects: number;
  };
}

export interface SecurityLanguagesDistributionInput {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: string;
  createdAt?: string;
}
