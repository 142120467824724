// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'lfx-sbom-no-files-state',
  templateUrl: './sbom-no-files-state.component.html',
  styleUrls: ['./sbom-no-files-state.component.scss'],
})
export class SbomNoFilesStateComponent implements OnInit {
  @Input() isCompanyAdmin;
  @Input() dashboardView;
  largeScreen;

  @Output() uploadSbomEmitter = new EventEmitter();
  constructor() {
    if (window.innerWidth <= 1440) {
      this.largeScreen = false;
    } else {
      this.largeScreen = true;
    }
  }

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth <= 1440) {
      this.largeScreen = false;
    } else {
      this.largeScreen = true;
    }
  }
}
