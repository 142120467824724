// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { environment as defaultEnvironment } from './environment.defaults';

const pageQueryParams = window.location.search || '';

export const environment = {
  ...defaultEnvironment,
  auth0: {
    client_id: 'qIE4NMNqOiv6706tG9zygsgpw4S7dRl3',
    domain: 'linuxfoundation-dev.auth0.com',
    audience: 'https://linuxfoundation-dev.auth0.com/api/v2/',
    redirect_uri: `${window.location.origin}/auth${pageQueryParams}`,
    responseType: 'token id_token',
  },
  auth0Social: {
    domain: 'linuxfoundation-dev.auth0.com',
    audience: 'https://linuxfoundation-dev.auth0.com/api/v2/',
    client_id: 'QQrYjDj2JK6sGW2Tw59rQpjYtSQaBgCz',
    redirect_uri: `${window.location.origin}/user/account-settings`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
  lfxHeaderUrl: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  apiUrl: 'https://lf-backend.dev.platform.linuxfoundation.org',
  graphqlUrl: 'https://lf-backend.dev.platform.linuxfoundation.org/graphql',
  ldClientKey: '65030d40cd1e2d12754b059d',
  joinFoundationUrl:
    'https://joinnow.dev.platform.linuxfoundation.org/?app=lfx&project=',
  cclaUrl: 'https://lfx-cla.s3.amazonaws.com/ccla.pdf',
  gtmId: 'GTM-MJ5SJTD',
  profileManagementUrl: 'https://myprofile.dev.platform.linuxfoundation.org/',
  enrollmentAPI: 'https://xxffhw47ze.execute-api.us-east-2.amazonaws.com/dev',
  enrollmentCDN:
    'https://joinnow.dev.platform.linuxfoundation.org/wc/joinnow-form.min.js',
  lfxAcsUICDN: 'https://lfx-acs-ui.dev.platform.linuxfoundation.org',
  v3Url: 'https://lfx.v3.dev.platform.linuxfoundation.org',
  v2Url: 'https://lfx.v2.dev.platform.linuxfoundation.org',
  intercomId: 'mxl90k6y',
};
