<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-full-card [title]="initiative.name" [details]="initiative.description" [color]="initiative.colorBand"
    [logo]="initiative.logoUrl" [showOverlay]="showOverlayOnBugBountyCard" (action)="onClickCard()">

    <div lfx-crowdfunding-bodyView class="body-wrapper">
        <div class="row seperator">
            <div class="col-md-6">
                <div class="text">Donations</div>
                <div>
                    <lfx-slider [min]="0" [max]="initiative.funding.target" [currency]="initiative.funding.currencyCode"
                        [color]="initiative.colorBand" [value]="initiative.funding?.current" [disabled]="true">
                    </lfx-slider>
                </div>
            </div>

            <div class="col-md-6">
                <div class="text">Bug Reports</div>
                <div class="bold">{{initiative.bugReport}}</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="text">Hackers Paid</div>
                <div class="bold">
                    {{initiative.hackerPaid | currency : initiative.funding.currencyCode : 'symbol' : '1.0-0' }}
                </div>
            </div>

            <div class="col-md-6">
                <div class="text">Bugs Verified</div>
                <div class="bold">{{initiative.bugVerified}}</div>
            </div>
        </div>
    </div>

    <div lfx-crowdfunding-footerView class="footer-wrapper">
        <div class="row">
            <div class="col-md-6">
                <div class="text">Sponsors</div>
                <lfx-logo-list [items]="initiative.funding.sponsors" limit="2"></lfx-logo-list>
            </div>

            <div class="col-md-6">
                <div class="text">Backers</div>
                <lfx-logo-list [items]="initiative.funding.backers" limit="2"></lfx-logo-list>
            </div>
        </div>
    </div>
</lfx-full-card>
