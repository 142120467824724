<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-full-card [title]="initiative.name" [details]="initiative.description" [tag]="initiative.tag"
  [color]="initiative.colorBand" [logo]="initiative.logoUrl" [typeIcon]="initiative.type">

  <lfx-badge [badgeUrl]="initiative.badgeUrl"></lfx-badge>

  <lfx-toggle-pills leftButtonText="Funding" rightButtonText="Mentorship">
    <div class="lfx-toggle-pills-left text-left small" *ngIf="initiative.funding as funding">
      <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Donation</p>
          <p class="m-0">
            <strong>{{ initiative.donation?.current | currency:funding.currencyCode:'symbol':'1.0' }}</strong>
            {{ initiative.donation?.interval }}
          </p>
        </div>
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Subscriber Since</p>
          <p class="m-0">
            <strong>{{ initiative.donation?.subscriberSince | date | timeAgo }}</strong>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Sponsors</p>
          <p class="m-0">
            <lfx-logo-list [items]="funding.sponsors" limit="2"></lfx-logo-list>
          </p>
        </div>
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Backers</p>
          <p class="m-0">
            <lfx-logo-list [items]="funding.backers" limit="2"></lfx-logo-list>
          </p>
        </div>
      </div>
    </div>

    <div class="lfx-toggle-pills-right text-left">
      <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5 small">Mentee Funding</p>
          <p class="m-t-15">
            <!--
              <lfx-progress-bar 
              height="4"
              [value]="menteeFunding.current / menteeFunding.total * 100"
              tooltip="{{ fundingTooltip.replace('%total%', menteeFunding.total | currency:menteeFunding.currencyCode:'symbol':'1.0').replace('%delta%', fundingDelta | currency:menteeFunding.currencyCode:'symbol':'1.0') }}"
              ></lfx-progress-bar>
              -->
          </p>
        </div>
        <div class="col-md-6">
          <p class="m-b-2 m-t-5 small">Terms</p>
          <p>
            <!-- <lfx-mini-tags
              [tags]="mentorship.terms"
            ></lfx-mini-tags> -->
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5 small">Opportunities</p>
          <p class="small">
            <!-- <lfx-logo-list
              [items]="mentorship.opportunities"
              limit="3"
            ></lfx-logo-list> -->
          </p>
        </div>
        <div class="col-md-6">
          <p class="m-b-2 m-t-5 small">Mentors</p>
          <p class="small">
            <!-- <lfx-logo-list
              [items]="mentorship.mentors"
              limit="3"
            ></lfx-logo-list> -->
          </p>
        </div>
      </div>
    </div>
  </lfx-toggle-pills>
</lfx-full-card>
