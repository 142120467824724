// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface Membership {
  createdDate: string;
  docuSignStatus: {
    date: string;
  };
  product: {
    family: string;
    id: string;
    name: string;
  };
  projectName: string;
  systemModStamp: string;
  accountId: string;
  agreementDate: string;
  annualFullPrice: string;
  assetLevel: number;
  autoRenew: boolean;
  cancellationDate: string;
  endDate: string;
  id: string;
  installDate: string;
  name: string;
  numberOfYearsRequired: number;
  order: number;
  paymentFrequency: string;
  paymentTerms: string;
  price: number;
  projectId: string;
  purchaseDate: string;
  quantity: number;
  renewalType: string;
  rootAssetId: string;
  status: string;
  year: string;
  membershipDocDownloadUrl: string;
}

export interface DiscoverMemberships {
  accountId: string;
  accountName: string;
  foundationId: string;
  foundationLogo: string;
  foundationName: string;
  projectType: string;
  contributorsCount: number;
  contributionCount: number;
}

export interface ProjectMembershipWithTiers {
  data: ProjectMembershipWithTiersData;
  loading: boolean;
  networkStatus: number;
  stale: boolean;
}

export interface ProjectMembershipWithTiersData {
  currentUser: ProjectMembershipWithTiersCurrentUser;
  project: ProjectMembershipWithTiersProject;
}

export interface ProjectMembershipWithTiersProject {
  id: string;
  slug: string;
  autoJoinEnabled: boolean;
  membershipAgreementLink: string;
  meMembership: ProjectMembershipWithTiersMeMembership;
  userOrganizationMembership: ProjectMembershipWithTiersUserOrganizationMembership;
  userOrganizationTiers: ProjectMembershipWithTiersUserOrganizationTier[];
  __typename: string;
}

export interface ProjectMembershipWithTiersUserOrganizationTier {
  id: string;
  productId: string;
  name: string;
  price: number;
  benefits: any[];
  __typename: string;
}

export interface ProjectMembershipWithTiersUserOrganizationMembership {
  senderName?: any;
  senderEmail?: any;
  submitted?: any;
  productId: string;
  productName: string;
  applicationStatus?: any;
  stage?: any;
  OpportunityDescription?: any;
  OpportunityProductDescription: string;
  __typename: string;
}

export interface ProjectMembershipWithTiersMeMembership {
  projectId: string;
  accountId: string;
  endDate: string;
  product: ProjectMembershipWithTiersProduct;
  __typename: string;
}

export interface ProjectMembershipWithTiersProduct {
  id: string;
  name: string;
  __typename: string;
}

export interface ProjectMembershipWithTiersCurrentUser {
  roles: string[];
  __typename: string;
}

export interface ListAccountSponsorshipQueryParams {
  pageSize?: number;
  offset?: number;
  orderBy?: string;
  orderType?: string;
  filter?: string;
  year?: string;
}

export interface ListAccountSponsorshipData {
  anualPrice: number;
  closeDate: string;
  contact: Contact;
  createdDate: string;
  description: string;
  endDate: string;
  event: Event;
  id: string;
  installDate: string;
  modifiedDate: string;
  name: string;
  organization: Organization;
  poNumber: number;
  product: Product;
  purchaseDate: string;
  status: string;
  year: number;
  tier: string;
}

interface Contact {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  title: string;
}

interface Event {
  id: string;
  name: string;
  startDate: string;
}

interface Organization {
  id: string;
  logoURL: string;
  name: string;
}

interface Product {
  description: string;
  family: string;
  id: string;
  name: string;
  type: string;
}

export interface MemberAccount {
  accountBillingAddress: AccountBillingAddress;
  accountCompanyType: string;
  accountCreatedAt: string;
  accountCreatedBy: string;
  accountDescription: string;
  accountDomainAlias: string;
  accountId: string;
  accountIndustry: string;
  accountIsMember: boolean;
  accountLastEmployeeSizeVerifiedDate: string;
  accountLastModifiedAt: string;
  accountLastModifiedBy: string;
  accountLink: string;
  accountLogoUrl: string;
  accountName: string;
  accountNumberOfEmployees: string;
  accountNumberOfEmployeesSizeRange: string;
  accountOwner: AccountOwner;
  accountPhone: string;
  accountPrimaryDomain: string;
  accountSector: string;
  accountSource: string;
  accountStatus: string;
  accountType: string;
  accountCrunchBaseUrl: string;
  accountsRelated: AccountRelated[];
}

export interface AccountRelated {
  id: string;
  isMember: boolean;
  logoUrl: string;
  name: string;
  parentId: string;
  slug: string;
  type: string;
}

export interface AccountBillingAddress {
  billingCity: string;
  billingCountry: string;
  billingPostalCode: string;
  billingState: string;
  billingStreet: string;
}

export interface AccountOwner {
  email: string;
  id: string;
  name: string;
}
