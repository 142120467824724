// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstKey',
})
export class FirstKeyPipe implements PipeTransform {
  transform(obj: object): string {
    if (obj === null || obj === undefined) {
      return '';
    }

    const keys = Object.keys(obj);

    if (keys && keys.length) {
      return keys[0];
    }

    return null;
  }
}
