// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  MarkAreaDataSetValue,
  TwoSeriesBarChartLegend,
  TwoSeriesBarChartToolTip,
  TwoSeriesDataSet,
  TwoSeriesMarkAreaDataSet,
} from '@lfx/core/models';

@Component({
  selector: 'lfx-two-series-bar-chart',
  templateUrl: './two-series-bar-chart.component.html',
  styleUrls: ['./two-series-bar-chart.component.scss'],
})
export class TwoSeriesBarChartComponent implements OnInit, OnChanges {
  chartOption = {};
  hiddenData = [];
  seriesLegendIsClicked = [false, false];
  seriesData = [];
  clickedLegendIconColor = 'rgb(37, 37, 37, 0.3)';

  @Input() size = 'normal'; // normal or big

  @Input() legend: TwoSeriesBarChartLegend = {
    colors: ['', ''],
    texts: ['', ''],
  };

  @Input() tooltip: TwoSeriesBarChartToolTip = {
    colors: ['', ''],
    texts: ['', ''],
  };

  @Input() markAreaTexts: string[] = ['', ''];

  @Input() yAxisName = '';

  @Input() markAreaDataSets: TwoSeriesMarkAreaDataSet[] = [
    { data: [] },
    { data: [] },
  ];

  @Input() dataSets: TwoSeriesDataSet[] = [{ data: [] }, { data: [] }];

  @Input() chartLabels: string[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.initClickedSeriesLegend();
    this.initSeriesData();
    this.initChartOption();
  }

  hideChartDataSet(seriesIndex: number) {
    if (!this.seriesLegendIsClicked[seriesIndex]) {
      this.hiddenData[seriesIndex] = this.seriesData[seriesIndex];
      this.seriesData[seriesIndex] = [];
    } else {
      this.seriesData[seriesIndex] = this.hiddenData[seriesIndex];
      this.hiddenData[seriesIndex] = [];
    }
    this.seriesLegendIsClicked[seriesIndex] =
      !this.seriesLegendIsClicked[seriesIndex];
    this.initChartOption();
  }

  private initClickedSeriesLegend() {
    this.hiddenData = [];
    this.seriesLegendIsClicked = [false, false];
  }

  private initSeriesData() {
    this.seriesData[0] = [
      ...this.resetNegativeValuesToZero(this.dataSets[0].data),
      ...this.resetNegativeMarkAreaDataSetValueToZero(
        this.markAreaDataSets[0].data
      ),
    ];
    this.seriesData[1] = [
      ...this.resetNegativeValuesToZero(this.dataSets[1].data),
      ...this.resetNegativeMarkAreaDataSetValueToZero(
        this.markAreaDataSets[1].data
      ),
    ];
  }

  private resetNegativeMarkAreaDataSetValueToZero(
    values: MarkAreaDataSetValue[]
  ): MarkAreaDataSetValue[] {
    return values.map(val => ({
      ...val,
      value: val.value > 0 ? val.value : 0,
    }));
  }

  private resetNegativeValuesToZero(values: number[]): number[] {
    return values.map(num => (num > 0 ? num : 0));
  }

  private getMarkLinexAxis() {
    return this.chartLabels.length - this.markAreaDataSets[1].data.length;
  }

  private initChartOption() {
    this.chartOption = {
      title: {
        textStyle: {
          color: '#333333',
          fontFamily: 'Source Sans Pro',
          fontSize: 12,
          fontWeight: 'bold',
        },
        top: 20,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#FFF',
        borderWidth: 1,
        borderColor: '#D9E0E7',
        padding: 12,
        textStyle: {
          color: '#252525',
          fontFamily: 'Source Sans Pro',
          fontSize: 12,
        },
        axisPointer: {
          type: 'none',
        },
        formatter: `
        <div style="margin-bottom: 10px"><b>{b0}</b></div>
        ${
          !this.seriesLegendIsClicked[0]
            ? `<div style="margin-bottom: 10px"><span style="color:${this.tooltip.colors[0]};">\u2B24</span>
        <b>{c0}</b> {a0}</div>`
            : ''
        }
        ${
          !this.seriesLegendIsClicked[1] && !this.seriesLegendIsClicked[0]
            ? `<div><span style="color:${this.tooltip.colors[1]}"; width="39">\u2B24</span>
        <b>{c1}</b> {a1} </div>`
            : ''
        }
        ${
          !this.seriesLegendIsClicked[1] && this.seriesLegendIsClicked[0]
            ? `<div><span style="color:${this.tooltip.colors[1]}"; width="39">\u2B24</span>
        <b>{c0}</b> {a0} </div>`
            : ''
        }
            `,
      },
      grid: {
        zlevel: 2,
        right: 100,
        left: 150,
      },
      yAxis: {
        type: 'category',
        data: this.chartLabels,
        splitLine: {
          lineStyle: {
            color: ['#D9E0E7'],
            opacity: 0.5,
          },
        },
        axisLabel: {
          show: true,
          align: 'left',
          rotate: 0,
          width: 150,
          margin: 150,
          color: '#8492A6',
          fontFamily: 'Source Sans Pro',
          fontWeight: 'bold',
          fontSize: '12',
          overflow: 'break',
          verticalAlign: 'middle',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          color: '#000000',
          width: '0.5',
        },
      },
      xAxis: {
        name: this.yAxisName,
        nameLocation: 'center',
        nameTextStyle: {
          color: '#333333',
          fontFamily: 'Source Sans Pro',
          fontSize: 12,
          fontWeight: 'bold',
        },
        nameGap: 40,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      series:
        this.markAreaDataSets[1].data.length === 0
          ? [this.getFirstSeries(), this.getSecondSeries()]
          : [
              this.getFirstSeries(),
              this.getSecondSeries(),
              this.getMarkLineSeries(),
            ],
      color: [this.tooltip.colors[0], this.tooltip.colors[1]],
    };
  }

  private getFirstSeries() {
    return {
      name: this.tooltip.texts[0],
      type: 'bar',
      markArea: {
        silent: false,
        label: {
          color: '#333333',
          fontFamily: 'Source Sans Pro',
          fontSize: 12,
          fontWeight: 'bold',
        },
        data: [
          [
            {
              name:
                this.markAreaDataSets[0].data.length === 0 ||
                this.dataSets[0].data.length === 0
                  ? ''
                  : this.markAreaTexts[0],
              coord: [0],
              itemStyle: {
                color: 'transparent',
              },
            },
            {
              coord: [this.getMarkLinexAxis()],
            },
          ],
        ],
      },
      barWidth: 10,
      barGap: '100%',
      data: this.seriesData[0],
      itemStyle: {},
    };
  }

  private getSecondSeries() {
    return {
      name: this.tooltip.texts[1],
      type: 'bar',
      barWidth: 10,
      barGap: '100%',
      data: this.seriesData[1],
      itemStyle: {},
    };
  }

  private getMarkLineSeries() {
    return {
      type: 'bar',
      markArea: {
        silent: false,
        label: {
          color: '#333333',
          fontFamily: 'Source Sans Pro',
          fontSize: 12,
          fontWeight: 'bold',
        },
        data: [
          [
            {
              name:
                this.markAreaDataSets[1].data.length !== 0
                  ? this.markAreaTexts[1]
                  : '',
              coord: [this.getMarkLinexAxis()],
              itemStyle: {
                color: 'transparent',
              },
            },
            {
              coord: [],
            },
          ],
        ],
      },
      markLine: this.getMarkLine(),
      barWidth: 10,
      barGap: '100%',
    };
  }

  private getMarkLine() {
    return this.markAreaDataSets[1].data.length === 0
      ? {}
      : {
          silent: true,
          symbol: 'none',
          label: {
            show: false,
          },
          data: [
            {
              yAxis: this.getMarkLinexAxis(),
              lineStyle: {
                color: '#D9E0E7',
                width: 2,
                type: 'dashed',
              },
            },
          ],
        };
  }
}
