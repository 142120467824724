// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input } from '@angular/core';
import { FlyoutService } from '@lfx/core/services';
import { UserProfileOptions } from '@lfx/modules/profile-snapshot/components/employee-profile-snapshot/employee-profile-snapshot.component';

@Component({
  selector: 'lfx-user-profile-cell',
  templateUrl: './user-profile-cell.component.html',
  styleUrls: ['./user-profile-cell.component.scss'],
})
export class UserProfileCellComponent {
  @Input() name: string;
  @Input() imageUrl: string;
  @Input() employeeId: string;
  @Input() employeeEmail: string;
  @Input() title: string;
  @Input() organizationName: string;
  @Input() allowProfileClick = true;
  @Input() options?: UserProfileOptions;

  constructor(private flyoutService: FlyoutService) {}

  get displaySubtext(): string {
    return this.organizationName || this.title;
  }

  openProfile() {
    if (!this.allowProfileClick) {
      return;
    }

    this.flyoutService.openUserProfile({
      employeeId: this.employeeId,
      employeeEmail: this.employeeEmail,
      defaultEmployeeObject: {
        name: this.name,
        logoUrl: this.imageUrl,
      },
      options: this.options,
    });
  }
}
