// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { GetResponse } from './get-response';

export interface OrgTeamsSummaryV2 {
  committee: OrgTeamsSummaryCommittee[];
  id: string;
  logoUrl: string;
  membershipId: string;
  name: string;
  slug: string;
}

export interface OrgTeamsSummaryCommittee {
  id: string;
  name: string;
}

export interface OrgTeamsSummaryV2QueryParams {
  projectId?: string;
  engagement?: string;
  pageSize?: number;
  offset?: string;
  status?: string;
}

export interface OrgTeamsSummaryV2Results {
  organizationTeamsSummaryV2: GetResponse<OrgTeamsSummaryV2> | null;
}
