<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="chart-section m-l-3">
  <div class="chart-container">
    <div echarts [options]="echartsFunnelOption" class="chart"></div>
  </div>
  <div class="legend-container d-flex ">
    <div *ngFor="let item of legendOptions">
      <div><b>{{item.value}}</b></div>
      <div>{{item.name}}</div>
    </div>
  </div>
</div>
