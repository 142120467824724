// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { generalConstants } from '@config';
@Component({
  selector: 'lfx-icon-status-dialog',
  templateUrl: './icon-status-dialog.component.html',
  styleUrls: ['./icon-status-dialog.component.scss'],
})
export class IconStatusDialogComponent implements OnInit {
  @Input() success = false;
  @Input() message = '';
  @Input() icon: string;
  @Input() iconLabel = generalConstants.claManagerAddFailed;
  @Output() closeDialog = new EventEmitter<boolean>();

  successIcon = generalConstants.claManagerSuccessIcon;
  failIcon = generalConstants.claManagerFailIcon;

  constructor() {}

  ngOnInit(): void {}

  onClickClose() {
    this.closeDialog.emit();
  }
}
