<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<!-- begin #header -->
<div id="header" class="header navbar-default">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <ng-container *ngIf="this.pageSidebarTwo">
      <button type="button" class="navbar-toggle pull-left" (click)="mobileSidebarToggle()">
        <i class="fas fa-bars"></i>
      </button>
      <button type="button" class="navbar-toggle pull-right" (click)="mobileRightSidebarToggle()">
        <i class="fas fa-bars"></i>
      </button>
    </ng-container>
    <a [routerLink]="['/']"  class="navbar-brand"></a>
    <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()"
      *ngIf="!this.pageSettings.pageSidebarTwo && !this.pageSettings.pageWithoutSidebar">
      <i class="fas fa-bars"></i>
    </button>
    <button type="button" class="navbar-toggle p-0 m-r-0" (click)="mobileMegaMenuToggle()"
      *ngIf="this.pageSettings.pageMegaMenu">
      <span class="fas-stack fa-lg text-inverse m-t-2">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fas fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()"
      *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar">
      <i class="fas fa-bars"></i>
    </button>
  </div>
  <!-- end navbar-header -->

  <!-- begin mega-menu -->
  <div class="collapse navbar-collapse pull-left" *ngIf="this.pageSettings.pageMegaMenu"
    [ngStyle]="{ 'display': (this.pageSettings.pageMegaMenu && this.pageSettings.pageMobileMegaMenuToggled) ? 'block' : '' }">
    <ul class="nav navbar-nav">
      <li class="dropdown dropdown-lg" ngbDropdown>
        <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle><i class="fas fa-th-large fa-fw"></i> Mega <b
            class="caret"></b></a>
        <div class="dropdown-menu dropdown-menu-lg" ngbDropdownMenu>
          Mega menu content here
        </div>
      </li>
      <li>
        <a href="javascript:;">
          <i class="fas fa-gem fa-fw"></i> Client
        </a>
      </li>
      <li class="dropdown" ngbDropdown>
        <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
          <i class="fas fa-database fa-fw"></i> New <b class="caret"></b>
        </a>
        <ul class="dropdown-menu" ngbDropdownMenu>
          <li><a href="javascript:;">Action</a></li>
          <li><a href="javascript:;">Another action</a></li>
          <li><a href="javascript:;">Something else here</a></li>
          <li class="divider"></li>
          <li><a href="javascript:;">Separated link</a></li>
          <li class="divider"></li>
          <li><a href="javascript:;">One more separated link</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- end mega-menu -->

  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right">
    <li *ngxPermissionsOnly="['feature_help']">
      <a class="pointer" (click)="toggleHelpSupportFrame();">
        <i class="fas fa-question-circle fa-size" title="Support / Help"></i>
      </a>
    </li>
    <li class="dropdown navbar-language" ngbDropdown *ngIf="this.pageSettings.pageLanguageBar">
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <span class="flag-icon flag-icon-us" title="us"></span>
        <span class="name">EN</span> <b class="caret"></b>
      </a>
      <ul class="dropdown-menu p-b-0" ngbDropdownMenu>
        <li class="arrow"></li>
        <li><a href="javascript:;"><span class="flag-icon flag-icon-us" title="us"></span> English</a></li>
        <li><a href="javascript:;"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</a></li>
        <li><a href="javascript:;"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</a></li>
        <li><a href="javascript:;"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</a></li>
        <li class="divider m-b-0"></li>
        <li class="text-center"><a href="javascript:;">more options</a></li>
      </ul>
    </li>
    <!-- User dropdown is commented due to design changes and will be required in the near future. -->
    <!-- <li class="dropdown navbar-user" ngbDropdown>
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <div class="image image-icon bg-black text-grey-darker">
          <img [src]=" profile?.avatarUrl" alt="">

        </div>
        <span class="d-none d-md-inline">{{profile?.firstName}} {{profile?.lastName}}</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <a [routerLink]="['/user/account-settings']" class="dropdown-item">Edit Profile</a>
        <a href="javascript:;" class="dropdown-item"><span class="badge badge-danger pull-right">2</span> Inbox</a>
        <a href="javascript:;" class="dropdown-item">Calendar</a>
        <a href="javascript:;" class="dropdown-item">Setting</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="logMeOut()" *ngIf="isLoggedIn">Log Out</a>
      </div>
    </li> -->
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->
