// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UpdateOrganizationPayload } from './../gql/services/results/update-organization-result';
import { UpdateOrganizationInput } from './../gql/services/inputs/update-organization-input';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import {
  Company,
  CompanyAdministrators,
  User,
  DeleteUserRoleScopeInput,
  MemberInvite,
  Address,
  CommitteeMembersConnection,
  CreateAddressInput,
  UpdateAddressInput,
  EventsSummary,
  CompanySigningEntity,
  TopEventsBySpeakersFromMyOrg,
  TopEventsByAttendeesFromMyOrg,
  TopTenCoursesCompleted,
  CertificationConnection,
  TncCompanyInsights,
  TrainedEmployeesByTypeOFTraining,
  TrainedEmployeesByGeography,
  CertificationsGrouped,
  TopTenCertifications,
  CertifiedEmployeesByGeography,
  CertifiedEmployeesByAreaOfInterest,
  UserContext,
  UpdateBenefitClaimedInput,
  CompanyProjectContributionConnection,
  OrganizationContributorInsightsQueryParams,
  OrganizationContributorInsightsConnection,
  ContributionsResponse,
  OrganizationResearch,
  OrganizationResearchQueryParams,
  OrganizationCommitteeContactsQueryParams,
  OrganizationCommitteeContact,
  RequestChangeCommitteeInput,
  UpdateCommiteeContactInput,
  UpdateOrganizationUserInput,
  UpdateOrganizationUserPayload,
  OrganizationContributosV2Response,
  OrganizationProjectStatQueryResponse,
  DashboardEventsSummary,
  DashboardAttendeeSummary,
  DashboardSpeakersSummary,
  GetResponse,
  Employee,
  GetOrgEmployeesInput,
  OrgTeamsSummaryV2QueryParams,
  OrgTeamsSummaryV2,
  RemoveAllUserAccessInput,
  ReassignProjectCommitteeContactsInput,
  NewProjectCommitteeContactResult,
  ProjectContributionList,
  ProjectEngagement,
  OpenSourceInvolvement,
  ProductsKpiInput,
  EmployeeMeetingStats,
  EmployeeSubscriptionStats,
  EmployeeCoursesEnrolledStats,
  EmployeeCertificatesReceivedStats,
  EmployeeEventsAttendedStats,
  EmployeeEventsSpokenStats,
  EmployeeDetailsResponse,
} from '@models';

import {
  CreateAddressPayload,
  CreateOrganizationSigningEntityPayload,
  GetCommitteeMembersInput,
  GetContributionInput,
  OrganizationServiceGql,
  UpdateAddressPayload,
  UserTechnicalResponse,
} from '../gql';

import {
  GetMyOrganizationInput,
  CreateUserRoleScopeInput,
  SendNewEmployeesInviteInput,
  SendMemberInviteInput,
  EditOrgUserAccessInput,
} from '../gql/services/inputs';
import { MutationResponse } from '@lfx/core/models/mutation-response';
import { EventsDataGrouped } from '@lfx/core/models/events-speakers';
import {
  CompanyEventAttendeeSummary,
  EventsDateRange,
  generalConstants,
  ViewsEventsSummary,
} from '@config';
import { StorageService } from './storage.service';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UploadLogoDialogComponent } from '@lfx/shared/components/upload-logo-dialog/upload-logo-dialog.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MembershipService } from './membership.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  myOrgInsightsSummary;
  showSelectOrgView = true;
  conglomerateOrgs = [];
  selectedConglomerateOrg;

  currentCompanyIdSource = new BehaviorSubject(
    this.storageService.getItemSync(generalConstants.currentOrganization) ||
      generalConstants.defaultOrgID
  );

  currentCompanySlugSource = new BehaviorSubject(this.getCurrentCompanySlug());

  updateCurrentCompany = new BehaviorSubject(null);
  currentCompanyId$ = this.currentCompanyIdSource.asObservable();
  currentCompanySlug$ = this.currentCompanySlugSource.asObservable();
  updateTodosCookies = new BehaviorSubject(null);
  updateTodosCookiesObservable$ = this.updateTodosCookies.asObservable();
  updatedBenefitsClaimedPerc = new BehaviorSubject(null);
  isTodosWidgetClosedChange = new BehaviorSubject(false);
  conglomerateOrgsLoaded = new BehaviorSubject(null);
  conglomerateOrgIdChanged = new BehaviorSubject(null);

  constructor(
    private organizationServiceGql: OrganizationServiceGql,
    private storageService: StorageService,
    private router: Router,
    private modalService: NgbModal,
    private membershipService: MembershipService,
    private httpClient: HttpClient,
    private location: Location
  ) {}

  setCurrentCompanySlug(slug) {
    const finalSlug = this.isAllOrgsRollup() ? slug + '_allorgs_rollup' : slug;

    this.storageService.setItem(
      generalConstants.currentOrganizationSlug,
      finalSlug
    );
    this.currentCompanySlugSource.next(this.getCurrentCompanySlug());
  }

  getCurrentCompanySlug() {
    return (
      this.storageService.getItemSync(
        generalConstants.currentOrganizationSlug
      ) || generalConstants.defaultOrgName
    );
  }

  setCurrentCompanyId(id) {
    this.storageService.setItem(generalConstants.currentOrganization, id);
    this.currentCompanyIdSource.next(id);
  }

  setCurrentCompanyB2BId(id) {
    this.storageService.setItem(generalConstants.currentOrganizationB2Bid, id);
  }

  setCurrentCompanyIdBySlug(slug) {
    if (this.isForStaffMembers() && slug && !slug.includes('auth?code=')) {
      this.searchOrganizations(
        null,
        'Customer',
        true,
        null,
        null,
        slug.replace('_allorgs_rollup', '')
      ).subscribe(companies => {
        if (companies && companies.length) {
          const company = companies[0];

          if (
            company.id !==
            this.storageService.getItemSync(
              generalConstants.currentOrganization
            )
          ) {
            this.storageService.setItem(
              generalConstants.currentOrganization,
              company.id
            );
            this.storageService.setItem(
              generalConstants.currentOrganizationB2Bid,
              company.salesforceB2BAccountId
            );
            this.currentCompanyIdSource.next(company.id);
          }
        } else {
          this.router.navigate([name + '/notfound']);
        }
      });
    }
  }

  isForStaffMembers() {
    return (
      this.storageService.getItemSync(generalConstants.currentContext) &&
      this.storageService
        .getItemSync(generalConstants.currentContext)
        .includes(UserContext.Staff)
    );
  }

  getCompanyIdForStaff() {
    if (this.isForStaffMembers()) {
      return (
        this.storageService.getItemSync(generalConstants.currentOrganization) ||
        generalConstants.defaultOrgID
      );
    }

    return '';
  }

  getCompanyB2BIdForStaff() {
    if (this.isForStaffMembers()) {
      return (
        this.storageService.getItemSync(
          generalConstants.currentOrganizationB2Bid
        ) || generalConstants.defaultOrgID
      );
    }

    return '';
  }

  getMyCompany(
    options?: GetMyOrganizationInput,
    noCache?: boolean
  ): Observable<Company> {
    const company$ = this.currentCompanyId$.pipe(
      switchMap(() => {
        if (this.isForStaffMembers() || this.isConglomerateOrg()) {
          return this.getCompanyById(
            // fetch on every change in company Id
            this.storageService.getItemSync(
              generalConstants.currentOrganization
            ) || generalConstants.defaultOrgID,
            options,
            noCache
          );
        } else {
          return this.organizationServiceGql.getMyOrganization(
            options,
            noCache
          );
        }
      })
    );

    return company$.pipe(
      switchMap(company =>
        this.membershipService
          .getMemberAccount(company.salesforceB2BAccountId, noCache)
          .pipe(
            map(account => {
              const orgObject = {
                ...company,
                name: account.accountName,
                logoUrl: account.accountLogoUrl,
                description: account.accountDescription,
                lastModifiedAt: account.accountLastModifiedAt,
                link: account.accountLink,
                crunchBaseUrl: account.accountCrunchBaseUrl,
                industry: account.accountIndustry,
                sector: account.accountSector,
                joinNowNoOfEmployees: account.accountNumberOfEmployees,
                accountPrimaryDomain: account.accountPrimaryDomain,
                accountsRelated: account.accountsRelated.filter(
                  account => account.isMember && account.type === 'Customer'
                ),
              };

              if (orgObject.accountsRelated.length) {
                const parentObject = orgObject.accountsRelated.find(
                  account =>
                    !account.parentId || account.id === account.parentId
                );

                this.conglomerateOrgs = [
                  {
                    name: 'All Organizations Roll-up',
                    desc: 'Includes all engagements for all  of your parent and subsidiary organizations',
                    id: parentObject ? parentObject.id : orgObject.id,
                    slug: parentObject
                      ? parentObject.slug + '_allorgs_rollup'
                      : orgObject.slug + '_allorgs_rollup',
                    allOrgsIcon: true,
                  },
                  {
                    myOrg: true,
                    ...orgObject,
                  },
                  ...orgObject.accountsRelated,
                ];
                this.selectedConglomerateOrg = this.isAllOrgsRollup()
                  ? this.conglomerateOrgs[0]
                  : orgObject;
                this.conglomerateOrgsLoaded.next(true);
              } else {
                this.conglomerateOrgs = [];
                this.conglomerateOrgsLoaded.next(false);
              }

              return orgObject;
            })
          )
      )
    );
  }

  isConglomerateOrg() {
    if (this.location.path() && this.location.path().split('/').length > 1) {
      const urlSlug = this.location.path().split('/')[1];

      return (
        this.conglomerateOrgs.findIndex(
          org =>
            org.slug === urlSlug || org.slug + '_allorgs_rollup' === urlSlug
        ) !== -1
      );
    }

    return false;
  }

  isAllOrgsRollup() {
    return this.router.url.includes('_allorgs_rollup');
  }

  getMyCompanyForNonStaff(options?: GetMyOrganizationInput, noCache?: boolean) {
    return this.organizationServiceGql.getMyOrganization(options, noCache);
  }

  getCompanyById(
    salesforceId: string,
    options?: GetMyOrganizationInput,
    noCache?: boolean
  ): Observable<Company> {
    return this.organizationServiceGql.getOrganizationById(
      salesforceId,
      options,
      noCache
    );
  }

  getCompanySigningEntity(
    signingEntityName: string
  ): Observable<CompanySigningEntity> {
    return this.organizationServiceGql.getOrganizationSigningEntity(
      signingEntityName
    );
  }

  getCompanySigningEntityById(
    signingEntityId: string
  ): Observable<CompanySigningEntity> {
    return this.organizationServiceGql.getOrganizationSigningEntityById(
      signingEntityId
    );
  }

  getContribution(
    options: GetContributionInput,
    noCache?: boolean
  ): Observable<ContributionsResponse> {
    return this.organizationServiceGql.getContribution(options, noCache);
  }

  getOrganizationContributorProjects(
    options: any,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getOrganizationContributorProjects(
      options,
      noCache
    );
  }

  organizationProjects(
    options: any,
    noCache?: boolean
  ): Observable<OrganizationProjectStatQueryResponse[]> {
    return this.organizationServiceGql.organizationProjects(options, noCache);
  }

  organizationContributors(
    options: any,
    noCache?: boolean
  ): Observable<OrganizationContributosV2Response> {
    return this.organizationServiceGql.organizationContributors(
      options,
      noCache
    );
  }

  organizationUsersContributors(
    organizationId: string,
    projectType: string,
    projectId?: string,
    isMaintainer?: boolean,
    isContributer?: boolean,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.organizationUsersContributors(
      organizationId,
      projectType,
      projectId,
      isMaintainer,
      isContributer,
      noCache
    );
  }

  organizationUserContributionDetails(
    organizationId: string,
    userId?: string,
    isAllOrgs?: boolean,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.organizationUserContributionDetails(
      organizationId,
      userId,
      isAllOrgs,
      noCache
    );
  }

  getOrganizationStats(
    options: GetContributionInput,
    noCache?: boolean
  ): Observable<ContributionsResponse> {
    return this.organizationServiceGql.getOrganizationStats(options, noCache);
  }

  getOrgEmployeesStats(
    orgId: string,
    projectId?: string,
    activityfilter?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getOrgEmployeesStats(
      orgId,
      projectId,
      activityfilter,
      noCache
    );
  }

  getOrgTeamsSummary(orgId: string, noCache?: boolean): Observable<any> {
    return this.organizationServiceGql.getOrgTeamsSummary(orgId, noCache);
  }

  getOrgTeamsSummaryV2(
    orgId: string,
    queryParams: OrgTeamsSummaryV2QueryParams,
    noCache?: boolean
  ): Observable<GetResponse<OrgTeamsSummaryV2> | null> {
    return this.organizationServiceGql.getOrgTeamsSummaryV2(
      orgId,
      queryParams,
      noCache
    );
  }

  getOrgEmployeesV2(
    input: GetOrgEmployeesInput
  ): Observable<GetResponse<Employee>> {
    return this.getOrgEmployees(
      input.organizationId,
      input.engagement,
      input.pageSize,
      input.offset,
      input.sortDir,
      input.orderBy,
      input.search,
      input.status,
      input.filter,
      input.morefilter,
      input.committeefilter,
      input.lfidfilter,
      input.projectId,
      input.membershipfilter,
      input.ospoLeader,
      input.contributorfilter,
      input.exportToCSV,
      input.activityfilter,
      input.typeview,
      input.noCache,
      input.hasmembershipentitlement
    );
  }

  getOrgEmployees(
    organizationId: string,
    engagement?: string,
    pageSize?: number,
    offset?: number,
    sortDir?: string,
    orderBy?: string,
    search?: string,
    status?: string,
    filter?: string,
    morefilter?: string,
    committeefilter?: string,
    lfidfilter?: string,
    projectId?: string,
    membershipfilter?: string,
    ospoLeader?: boolean | null,
    contributorfilter?: string,
    exportToCSV?: boolean,
    activityfilter?: string,
    typeview?: string,
    noCache?: boolean,
    hasmembershipentitlement?: boolean
  ): Observable<any> {
    if (contributorfilter === 'Contributor') {
      engagement = engagement
        ? engagement + `,${contributorfilter}`
        : contributorfilter;

      contributorfilter = null;
    }

    return this.organizationServiceGql.getOrgEmployees(
      organizationId,
      engagement,
      pageSize,
      offset,
      sortDir,
      orderBy,
      search,
      status,
      filter,
      morefilter,
      committeefilter,
      lfidfilter,
      projectId,
      membershipfilter,
      ospoLeader,
      contributorfilter,
      exportToCSV,
      activityfilter,
      typeview,
      noCache,
      hasmembershipentitlement
    );
  }

  getOrgEmployee({
    organizationId = '',
    userId = '',
    basic = false,
    noCache = false,
    email = '',
  }): Observable<any> {
    return this.organizationServiceGql.getOrgEmployee({
      organizationId,
      userId,
      basic,
      noCache,
      email,
    });
  }

  getOrgEmployeeDetails(
    organizationId = '',
    userId = '',
    isAllOrgs = false
  ): Observable<EmployeeDetailsResponse> {
    return this.httpClient.get<EmployeeDetailsResponse>(
      `${environment.apiUrl}/employee/details`,
      {
        params: {
          organizationId,
          userId,
          isAllOrgs,
        },
      }
    );
  }

  getOrgContributionStats(
    orgId: string,
    projectId: string,
    year?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getOrgContributionStats(
      orgId,
      projectId,
      year,
      noCache
    );
  }

  getAllCompanies(): Observable<Company[]> {
    return this.organizationServiceGql.getAllOrganizations();
  }

  searchOrganizations(
    name: string,
    type?: string,
    ismember?: boolean,
    pageSize?: number,
    parentId?: string,
    slug?: string
  ): Observable<Company[]> {
    return this.organizationServiceGql.searchOrganizations(
      name,
      type,
      ismember,
      pageSize,
      parentId,
      slug
    );
  }

  getMembershipRecommendations(
    organizationId: string,
    noCache?: boolean
  ): Observable<any[]> {
    return this.organizationServiceGql.getMembershipRecommendationsByOrganizationId(
      organizationId,
      noCache
    );
  }

  public uploadLogo(file: File, organizationId: string) {
    const route = `${environment.apiUrl}/company/${organizationId}/logo`;

    const formData = new FormData();

    formData.append('logo', file);

    return this.httpClient.post<{
      success: boolean;
      message: string;
      fileUrl: string;
    }>(route, formData);
  }

  updateOrganizationProfile(
    organizationInfo: UpdateOrganizationInput
  ): Observable<UpdateOrganizationPayload> {
    return this.organizationServiceGql.updateOrganizationProfile(
      organizationInfo
    );
  }

  getCompanyAdmins(salesforceId: string): Observable<CompanyAdministrators> {
    return this.organizationServiceGql.getOrganizationAdmins(salesforceId);
  }

  getCompanyCommitteeMembers(
    options: GetCommitteeMembersInput,
    noCache?: boolean
  ): Observable<CommitteeMembersConnection> {
    return this.organizationServiceGql.getOrganizationCommitteeMembers(
      options,
      noCache
    );
  }

  getCompanyEventsSummary(
    organizationId: string,
    noCache?: boolean
  ): Observable<EventsSummary> {
    return this.organizationServiceGql.getOrganizationEventsSummary(
      organizationId,
      noCache
    );
  }

  getCompanyDashboardEventsSummary(
    organizationId: string,
    noCache?: boolean
  ): Observable<DashboardEventsSummary> {
    return this.organizationServiceGql.getOrganizationDashboardEventsSummary(
      organizationId,
      noCache
    );
  }

  getCompanyViewsEventsSummary(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<ViewsEventsSummary> {
    return this.organizationServiceGql.getOrganizationViewsEventsSummary(
      organizationId,
      dateRange,
      noCache
    );
  }

  getAllOrganizationsEventsSummary(dateRange: string): Observable<any> {
    return this.organizationServiceGql.getAllOrganizationsEventsSummary(
      dateRange
    );
  }

  getCompanySpeakersSummary(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    noCache?: boolean,
    eventId?: string,
    jfilter?: string,
    offset?: number
  ): Observable<any> {
    return this.organizationServiceGql.getOrganizationSpeakersSummary(
      organizationId,
      dateRange,
      pageSize,
      orderBy,
      sortDir,
      noCache,
      eventId,
      jfilter,
      offset
    );
  }

  getDashboardCompanySpeakersSummary(
    organizationId: string,
    eventId?: string,
    noCache?: boolean
  ): Observable<DashboardSpeakersSummary> {
    return this.organizationServiceGql.getDashboardOrganizationSpeakersSummary(
      organizationId,
      eventId,
      noCache
    );
  }

  getCompanyInsightsSummary(
    organizationId: string,
    pageSize?: number,
    noCache?: boolean
  ): Observable<CompanyProjectContributionConnection> {
    return this.organizationServiceGql
      .getOrganizationInsightsSummary(organizationId, pageSize, noCache)
      .pipe(tap(insights => (this.myOrgInsightsSummary = insights)));
  }

  getTrainingAndCertificationLearners(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    offset?: number,
    jfilter?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getTrainingAndCertificationLearners(
      organizationId,
      dateRange,
      pageSize,
      orderBy,
      sortDir,
      offset,
      jfilter,
      noCache
    );
  }

  getOrganizationTravelFundEvents(
    organizationId: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    offset?: number,
    year?: string,
    projectID?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getOrganizationTravelFundEvents(
      organizationId,
      pageSize,
      orderBy,
      sortDir,
      offset,
      year,
      projectID,
      noCache
    );
  }

  getOrgTrainingAndCertificationLearners(
    organizationId: string,
    pageSize?: number,
    offset?: number,
    fromDate?: string,
    toDate?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getOrgTrainingAndCertificationLearners(
      organizationId,
      pageSize,
      offset,
      fromDate,
      toDate,
      noCache
    );
  }

  getTrainingAndCertificationEnrollmentsAndExams(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    offset?: number,
    jfilter?: string,
    timeFilterField?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getTrainingAndCertificationEnrollmentsAndExams(
      organizationId,
      dateRange,
      pageSize,
      orderBy,
      sortDir,
      offset,
      jfilter,
      timeFilterField,
      noCache
    );
  }

  getCompanyAttendeesSummary(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    noCache?: boolean,
    eventId?: string
  ): Observable<CompanyEventAttendeeSummary[]> {
    return this.organizationServiceGql.getOrganizationAttendeesSummary(
      organizationId,
      dateRange,
      pageSize,
      orderBy,
      sortDir,
      noCache,
      eventId
    );
  }

  getDashboardCompanyAttendeesSummary(
    organizationId: string,
    eventId: string,
    noCache?: boolean
  ): Observable<DashboardAttendeeSummary[]> {
    return this.organizationServiceGql.getDashboardOrganizationAttendeesSummary(
      organizationId,
      eventId,
      noCache
    );
  }

  getTopEventsBySpeakersFromMyOrg(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<TopEventsBySpeakersFromMyOrg> {
    return this.organizationServiceGql.getTopEventsBySpeakersFromMyOrg(
      organizationId,
      dateRange,
      noCache
    );
  }

  getCertifiedEmployeesByAreaOfInterest(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<CertifiedEmployeesByAreaOfInterest[]> {
    return this.organizationServiceGql.getCertifiedEmployeesByAreaOfInterest(
      organizationId,
      dateRange,
      noCache
    );
  }

  getTrainedEmployeesByTypeOFTraining(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<TrainedEmployeesByTypeOFTraining> {
    return this.organizationServiceGql.getTrainedEmployeesByTypeOFTraining(
      organizationId,
      dateRange,
      noCache
    );
  }

  getTrainedEmployeesByGeography(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<TrainedEmployeesByGeography[]> {
    return this.organizationServiceGql.getTrainedEmployeesByGeography(
      organizationId,
      dateRange,
      noCache
    );
  }

  getTnCSeries(
    organizationId: string,
    dateRange: string,
    companySize: string,
    industry: string,
    type: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getTnCSeries(
      organizationId,
      dateRange,
      companySize,
      industry,
      type,
      noCache
    );
  }

  getCertifiedEmployeesByGeography(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<CertifiedEmployeesByGeography[]> {
    return this.organizationServiceGql.getCertifiedEmployeesByGeography(
      organizationId,
      dateRange,
      noCache
    );
  }

  getTopEventsByAttendeesFromMyOrg(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<TopEventsByAttendeesFromMyOrg> {
    return this.organizationServiceGql.getTopEventsByAttendeesFromMyOrg(
      organizationId,
      dateRange,
      noCache
    );
  }

  getTopCoursesCompleted(
    organizationId: string,
    dateRange: string,
    companySize: string,
    industry: string,
    noCache?: boolean
  ): Observable<TopTenCoursesCompleted> {
    return this.organizationServiceGql.getTopCoursesCompleted(
      organizationId,
      dateRange,
      companySize,
      industry,
      noCache
    );
  }

  getTopCertifications(
    organizationId: string,
    dateRange: string,
    companySize: string,
    industry: string,
    noCache?: boolean
  ): Observable<TopTenCertifications> {
    return this.organizationServiceGql.getTopCertifications(
      organizationId,
      dateRange,
      companySize,
      industry,
      noCache
    );
  }

  getTncCompanyInsight(
    organizationId: string,
    dateRange: string,
    noCache?: boolean
  ): Observable<TncCompanyInsights> {
    return this.organizationServiceGql.getTncCompanyInsight(
      organizationId,
      dateRange,
      noCache
    );
  }

  getMyOrgEventsSpeakers(
    dateRange: EventsDateRange
  ): Observable<EventsDataGrouped[]> {
    return this.organizationServiceGql.getMyOrgEventsSpeakersQuery(dateRange);
  }

  getOrgEventsSpeakers(
    salesforceId: string,
    dateRange: EventsDateRange
  ): Observable<EventsDataGrouped[]> {
    return this.organizationServiceGql.getOrgEventsSpeakersQuery(
      salesforceId,
      dateRange
    );
  }

  getMyOrgEventsAttendees(
    dateRange: EventsDateRange,
    noCache = false
  ): Observable<EventsDataGrouped[]> {
    if (this.isForStaffMembers()) {
      return this.getOrgEventsAttendees(
        this.getCompanyIdForStaff(),
        dateRange,
        noCache
      ).pipe(first());
    }

    return this.organizationServiceGql.getMyOrgEventsAttendeesQuery(
      dateRange,
      noCache
    );
  }

  getOrgEventsAttendees(
    organizationId: string,
    dateRange: EventsDateRange,
    noCache = false
  ): Observable<EventsDataGrouped[]> {
    return this.organizationServiceGql.getOrgEventsAttendeesQuery(
      organizationId,
      dateRange,
      noCache
    );
  }

  getMyOrgCertificationsQuery(
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.organizationServiceGql.getMyOrgCertificationsQuery(dateRange);
  }

  getAllOrgsCertificationsQuery(
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.organizationServiceGql.getAllOrgsCertificationsQuery(dateRange);
  }

  getOrgTrainingsQuery(
    salesforceId: string,
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.organizationServiceGql.getOrgTrainingsQuery(
      salesforceId,
      dateRange
    );
  }

  getOrgCertificationsQuery(
    salesforceId: string,
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.organizationServiceGql.getOrgCertificationsQuery(
      salesforceId,
      dateRange
    );
  }

  getOrgProjectBenefits(
    organizationId: string,
    slug: string,
    category?: string,
    salesforceMembershipId?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.organizationServiceGql.getOrgProjectBenefits(
      organizationId,
      slug,
      category,
      salesforceMembershipId,
      noCache
    );
  }

  getMyOrgCertificationsGroupedQuery(
    dateRange: EventsDateRange,
    type: 'training' | 'certification' = 'training'
  ): Observable<CertificationsGrouped[]> {
    return this.organizationServiceGql.getMyOrgCertificationsGroupedQuery(
      dateRange,
      type
    );
  }

  getOrgCertificationsGroupedQuery(
    salesforceId: string,
    dateRange: EventsDateRange,
    type: 'training' | 'certification' = 'training'
  ): Observable<CertificationsGrouped[]> {
    return this.organizationServiceGql.getOrgCertificationsGroupedQuery(
      salesforceId,
      dateRange,
      type
    );
  }

  deleteUserRoleScope(input: DeleteUserRoleScopeInput) {
    return this.organizationServiceGql.deleteUserRoleScope(input);
  }

  deleteAdminRoleScope(username: string, organizationId: string) {
    return this.organizationServiceGql.deleteAdminRoleScope(
      username,
      organizationId
    );
  }

  createUserRoleScope(input: CreateUserRoleScopeInput) {
    return this.organizationServiceGql.createUserRoleScope(input);
  }

  createUserRoleScopes(input: CreateUserRoleScopeInput[]) {
    return this.organizationServiceGql.createUserRoleScopes(input);
  }

  searchUsers(input): Observable<User[]> {
    return this.organizationServiceGql.searchUsers(input);
  }

  sendNewMemberInvite(input: MemberInvite): Observable<any> {
    return this.organizationServiceGql.sendNewMemberEmailInvitation(input);
  }

  sendNewEmployeesInvite(input: SendNewEmployeesInviteInput): Observable<any> {
    return this.organizationServiceGql.sendNewEmployeesInvite(input);
  }

  labelEmployeesAsAdministrators(
    input: SendMemberInviteInput[]
  ): Observable<any> {
    return this.organizationServiceGql.labelEmployeesAsAdministrators(input);
  }

  resendInvite(
    inviteId: string,
    inviteEmail: string
  ): Observable<MutationResponse<MemberInvite>> {
    return this.organizationServiceGql.resendEmailInvitation(
      inviteId,
      inviteEmail
    );
  }

  getAddresses(
    organizationId: string,
    inUseOnly?: boolean,
    noCache = false
  ): Observable<Address[]> {
    return this.organizationServiceGql.getAddresses(
      organizationId,
      inUseOnly,
      noCache
    );
  }

  createAddress(input: CreateAddressInput): Observable<CreateAddressPayload> {
    return this.organizationServiceGql.createAddress(input);
  }

  updateAddress(input: UpdateAddressInput): Observable<UpdateAddressPayload> {
    return this.organizationServiceGql.updateAddress(input);
  }

  updateBenefitClaimed(input: UpdateBenefitClaimedInput): Observable<any> {
    return this.organizationServiceGql.updateBenefitClaimed(input);
  }

  createSigningEntity(
    signingEntity: string
  ): Observable<CreateOrganizationSigningEntityPayload> {
    return this.organizationServiceGql.createSigningEntity(signingEntity);
  }

  getMembershipContributionBySlug(slug) {
    if (this.myOrgInsightsSummary && slug) {
      for (const insightObject of this.myOrgInsightsSummary) {
        if (insightObject.project.slug === slug) {
          return insightObject;
        }
      }
    }

    return null;
  }

  getContributorInsights(
    organizationId: string,
    queryString: OrganizationContributorInsightsQueryParams,
    noCache = false
  ): Observable<OrganizationContributorInsightsConnection> {
    return this.organizationServiceGql.getContributorInsights(
      organizationId,
      queryString,
      noCache
    );
  }

  getOrganizationResearch(
    organizationId: string,
    queryParams: OrganizationResearchQueryParams,
    isMember = false
  ): Observable<OrganizationResearch> {
    return this.organizationServiceGql.getOrganizationResearch(
      organizationId,
      queryParams,
      isMember
    );
  }

  getOrganizationBoardMeetingAttendance(
    organizationId: string
  ): Observable<OrganizationCommitteeContact[]> {
    return this.organizationServiceGql.getOrganizationBoardMeetingAttendance(
      organizationId
    );
  }

  getOrganizationCommitteeContacts(
    organizationId: string,
    queryParams: OrganizationCommitteeContactsQueryParams,
    noCache = false
  ): Observable<OrganizationCommitteeContact[]> {
    return this.organizationServiceGql.getOrganizationCommitteeContacts(
      organizationId,
      queryParams,
      noCache
    );
  }

  requestChangeCommittee(
    input: RequestChangeCommitteeInput,
    refetchParams: OrganizationCommitteeContactsQueryParams
  ): Observable<{ message: string }> {
    return this.organizationServiceGql.requestChangeCommittee(
      input,
      refetchParams
    );
  }

  updateCommitteeContact(
    input: UpdateCommiteeContactInput
  ): Observable<{ message: string }> {
    return this.organizationServiceGql.updateCommitteeContact(input);
  }

  updateOrganizationUser(
    input: UpdateOrganizationUserInput
  ): Observable<UpdateOrganizationUserPayload[]> {
    return this.organizationServiceGql.updateOrganizationUser(input);
  }

  getEmailTemplates(input: { filter?: string; name?: string }) {
    return this.organizationServiceGql.getEmailTemplates(
      input.filter,
      input.name
    );
  }

  formatCompanyLink(link: string): string {
    if (!link.startsWith('https://' || 'http://')) {
      link = 'http://' + link;
    }

    return link;
  }

  uploadLogoModal(companyId: string): NgbModalRef {
    const modalRef = this.modalService.open(UploadLogoDialogComponent, {
      modalDialogClass: 'new-widget-dialog',
    });

    modalRef.componentInstance.companyId = companyId;

    return modalRef;
  }

  editOrgUserAccess(
    input: EditOrgUserAccessInput
  ): Observable<MutationResponse<null>> {
    return this.organizationServiceGql.editOrgUserAccess(input);
  }

  removeAllAccess(
    input: RemoveAllUserAccessInput
  ): Observable<{ success: boolean; message?: string }> {
    const updateOrgUserResponse$ = this.updateOrganizationUser({
      orgId: input.organizationId,
      userIds: [input.userId],
      body: {
        status: 'Removed',
      },
    }).pipe(
      map(res => {
        const success = !!(res && res.length);

        return {
          success,
          message: success ? '' : 'update org user failed',
        };
      })
    );

    if (!input.username) {
      return updateOrgUserResponse$;
    }

    return this.editOrgUserAccess({
      changeTo: '',
      currentAccess: '',
      organizationId: input.organizationId,
      username: input.username,
      removeAllAccess: true,
      orgUserId: input.orgUserId,
      userId: input.userId,
    }).pipe(
      switchMap(response => {
        if (!response.success) {
          return of(response);
        }

        return updateOrgUserResponse$;
      })
    );
  }

  reassignCommitteeRoles(
    input: ReassignProjectCommitteeContactsInput
  ): Observable<MutationResponse<NewProjectCommitteeContactResult[]>> {
    return this.organizationServiceGql.reassignCommitteeRoles(input);
  }

  getProjectContributionList(
    organizationId: string,
    isAllOrgs: boolean,
    noCache = false
  ): Observable<ProjectContributionList> {
    return this.organizationServiceGql.getProjectContributionsList(
      organizationId,
      isAllOrgs,
      noCache
    );
  }

  getProjectEngagement(
    organizationId: string,
    projectId: string,
    noCache = false
  ): Observable<ProjectEngagement> {
    return this.organizationServiceGql.getProjectEngagement(
      organizationId,
      projectId,
      noCache
    );
  }

  getOpenSourceInvolvement(
    organizationId: string,
    isAllOrgs: boolean,
    noCache = false
  ): Observable<OpenSourceInvolvement> {
    return this.organizationServiceGql.getOpenSourceInvolvement(
      organizationId,
      isAllOrgs,
      noCache
    );
  }

  isMemberWithLF(organizationId: string): Observable<boolean> {
    return organizationId ? of(Math.random() < 0.5) : of(false);
  }

  createProductsKpi(
    input: ProductsKpiInput
  ): Observable<MutationResponse<null>> {
    return this.organizationServiceGql.createProductsKpi(input);
  }

  getEmployeeMeetings(userId, organizationId, isAllOrgs, timeRange) {
    return this.httpClient.get<EmployeeMeetingStats[]>(
      `${environment.apiUrl}/employee/meetings`,
      {
        params: { userId, organizationId, isAllOrgs, timeRange },
      }
    );
  }

  getEmployeeSubscriptions(userId, organizationId, isAllOrgs, timeRange) {
    return this.httpClient.get<EmployeeSubscriptionStats[]>(
      `${environment.apiUrl}/employee/subscriptions`,
      {
        params: { userId, organizationId, isAllOrgs, timeRange },
      }
    );
  }

  getEmployeeCoursesEnrolled(userId, organizationId, isAllOrgs, timeRange) {
    return this.httpClient.get<EmployeeCoursesEnrolledStats[]>(
      `${environment.apiUrl}/employee/courses-enrolled`,
      {
        params: { userId, organizationId, isAllOrgs, timeRange },
      }
    );
  }

  getEmployeeCertificatesReceived(
    userId,
    organizationId,
    isAllOrgs,
    timeRange
  ) {
    return this.httpClient.get<EmployeeCertificatesReceivedStats[]>(
      `${environment.apiUrl}/employee/certificates-received`,
      {
        params: { userId, organizationId, isAllOrgs, timeRange },
      }
    );
  }

  getEmployeeEventsAttended(userId, organizationId, isAllOrgs, timeRange) {
    return this.httpClient.get<EmployeeEventsAttendedStats[]>(
      `${environment.apiUrl}/employee/events-attended`,
      {
        params: { userId, organizationId, isAllOrgs, timeRange },
      }
    );
  }

  getEmployeeEventsSpoken(userId, organizationId, isAllOrgs, timeRange) {
    return this.httpClient.get<EmployeeEventsSpokenStats[]>(
      `${environment.apiUrl}/employee/events-spoken`,
      {
        params: { userId, organizationId, isAllOrgs, timeRange },
      }
    );
  }

  getEmployeeTechnicalActivity(
    organizationId: string,
    userId: string,
    timeRange: string,
    isAllOrgs: boolean
  ) {
    return this.httpClient.get<UserTechnicalResponse>(
      `${environment.apiUrl}/employee/technical-activity`,
      { params: { organizationId, userId, timeRange, isAllOrgs } }
    );
  }
}
