// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UntypedFormControl } from '@angular/forms';

export const noWhitespaceValueValidator = (control: UntypedFormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;

  return isValid ? null : { whiteSpace: true };
};
