// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface EventsSummary {
  attendeesChartData: number[];
  chartLabels: string[];
  eventsByAttendees: {
    attendees: number;
    name: string;
    speakers: number;
    startDate: string;
  }[];
  eventsBySpeakers: {
    attendees: number;
    name: string;
    speakers: number;
    startDate: string;
  }[];
  metadata: {
    offset: number;
    pageSize: number;
    totalSize: number;
  };
  speakersChartData: number[];
  totalAttendances: number;
  totalSavings: number;
  totalSponsorshipSavings: number;
  totalRegistrationSavings: number;
  totalSpekers: number;
  totalSponsered: number;
  registrations: any[];
  sponsorships: any[];
}

export interface DashboardEventsSummary {
  attendeesChartData: number[];
  chartLabels: string[];
  eventsByAttendees: {
    attendees: number;
    name: string;
    eventId: string;
  }[];
  eventsBySpeakers: {
    speakers: number;
    name: string;
    eventId: string;
  }[];
  totalAttendances: number;
  totalSpeakers: number;
  totalSponsored: number;
  speakers: {
    userId: string;
    counts: number;
    name: string;
    logo: string;
  }[];
}

export interface DashboardAttendeeSummary {
  city?: string;
  contactId: string;
  country?: string;
  email?: string;
  firstName: string;
  gender?: string;
  jobFunction?: string;
  jobLevel?: string;
  lastName: string;
  logo: string;
  state?: string;
  title: string;
  totalEventsAttended?: number;
  events?: { id: string; name: string; startDate: string }[];
}

export interface DashboardSpeakersSummary {
  speakers: DashboardAttendeeSummary[];
}
