// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Subject, Subscription } from 'rxjs';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  generalConstants,
  MenuItem,
  pageMenus,
  allOrgsRollupPageMenus,
  pageSettings,
  contextPriority,
} from '@config';
import {
  Foundation,
  Profile,
  Company,
  UserContext,
  ProjectType,
  FoundationProject,
} from '@models';
import {
  PermissionService,
  StorageService,
  UserService,
  CompanyService,
  FeatureFlagManagerService,
  FoundationService,
} from '@services';
import { NgxPermissionsObject } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { environment } from '@environment';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  AutoCompleteActionEventType,
  AutoCompleteSearchInputActionEvent,
} from '@lfx/shared/interfaces';
import { isEmpty } from 'lodash';
import { InitService } from '@lfx/init.service';
import { Location } from '@angular/common';

export const storedContextList = generalConstants.currentContext;

@Component({
  selector: 'lfx-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expandCollapse', [
      state(
        'expand',
        style({ height: '*', overflow: 'hidden', display: 'block' })
      ),
      state(
        'collapse',
        style({ height: '0px', overflow: 'hidden', display: 'block' })
      ),
      transition('expand <=> collapse', animate(100)),
    ]),
  ],
})
export class SidebarComponent
  implements OnInit, AfterViewChecked, OnDestroy, OnChanges
{
  @Input() profile: Profile;
  @Input() isLoggedIn: boolean;
  @Input() pageSidebarTransparent;
  @Input() pageSidebarMinified;
  @Output() hideMobileSidebar = new EventEmitter<boolean>();
  @Output() sidebarCollapsed = new EventEmitter<boolean>();
  navProfileState = 'collapse';
  menus;
  currentContext: UserContext = null;
  currentContexts: UserContext[] = [];
  contextImageUrl: string;
  contextName: string;
  pageSettings = pageSettings;
  minified = false;
  mobileMode: boolean;
  desktopMode: boolean;
  scrollTop: number;
  myCompany;
  permissions: NgxPermissionsObject;
  userContextEnum = UserContext;
  profileUrl = environment.profileManagementUrl;
  individualNoAccount = generalConstants.individualNoAccount;
  updateCurrentCompanySubscription = new Subscription();
  isAdminOrStaffUser$ = of(false);
  searchResult$: Observable<any>;
  showOrgSearchMenu = false;
  searchLoading = false;
  orgSearchText;
  isCollapsed = pageSettings.pageSidebarMinified;
  defaultImage;

  @ViewChild('sidebarScrollbar')
  private sidebarScrollbar: ElementRef;

  private autoCompleteResultsObservables: {
    [key: string]: Observable<any>;
  } = {};

  private standAloneProjectFoundationId: string;
  private isLoading: { [key: string]: boolean } = {};
  private currentProjectId: string;
  private searchText$: Subject<string> = new Subject();
  isAllOrgsRollup: boolean;
  isConglomerateOrg: boolean;
  separatorPositionAfterUrl: string;
  collabseTimeout: number;

  constructor(
    public router: Router,
    public companyService: CompanyService,
    private eRef: ElementRef,
    private storageService: StorageService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private permissionManager: PermissionService,
    private featureFlagManagerService: FeatureFlagManagerService,
    private foundationService: FoundationService,
    private route: ActivatedRoute,
    private initService: InitService,
    private location: Location
  ) {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }

    this.permissionManager.getPermissions().subscribe(perm => {
      this.permissions = perm;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isCollapsed) {
      this.sidebarCollapsed.emit(this.isCollapsed);

      if (window.innerWidth <= 1024) {
        this.isCollapsed = true;
        this.sidebarCollapsed.emit(this.isCollapsed);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.updateCurrentCompanySubscription) {
      this.updateCurrentCompanySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.fillMenuItems();
      }
    });

    if (
      this.location.path() &&
      this.location.path().split('/') &&
      this.location.path().split('/').length > 2 &&
      this.location.path().split('/')[2].toLowerCase() !== 'home'
    ) {
      this.companyService.showSelectOrgView = false;
    }

    if (
      this.location.path() &&
      this.location.path().split('/') &&
      this.location.path().split('/').length > 1 &&
      this.location.path().split('/')[1].toLowerCase() !== 'company'
    ) {
      this.companyService.setCurrentCompanyIdBySlug(
        this.location.path().split('/')[1]
      );
    }

    this.initService.setContext().subscribe(() => {
      this.init();
    });

    this.companyService.conglomerateOrgIdChanged.subscribe(orgId => {
      if (orgId) {
        this.init();
      }
    });

    if (window.innerWidth <= 1024) {
      this.isCollapsed = true;
      this.sidebarCollapsed.emit(this.isCollapsed);
    }
    this.isAdminOrStaffUser$ = this.userService.isCompanyAdminOrLFStaffUser();

    this.searchResult$ = this.searchText$.pipe(
      debounceTime(200),
      switchMap(term => {
        if (term) {
          this.searchLoading = true;

          return this.companyService
            .searchOrganizations(term, 'Customer', true, 20)
            .pipe(
              map(companies => {
                this.showOrgSearchMenu = true;
                const parents = [];

                companies.map(company => {
                  if (company.isParent) {
                    parents.push(company);
                  }
                });

                companies.map(company => {
                  if (!company.isParent) {
                    const parentIndex = parents.findIndex(
                      parent => parent.id === company.parentDetail.id
                    );

                    if (parentIndex === -1) {
                      const parent: any = company.parentDetail;

                      parent.children = [company];
                      parents.push(parent);
                    } else {
                      if (parents[parentIndex].children) {
                        parents[parentIndex].children.push(company);
                      } else {
                        parents[parentIndex].children = [company];
                      }
                    }
                  }
                });
                this.searchLoading = false;

                return parents;
              })
            );
        } else {
          return new Observable<any>();
        }
      })
    );
  }

  ngAfterViewChecked() {
    if (typeof Storage !== 'undefined' && localStorage.sidebarScroll) {
      if (this.sidebarScrollbar && this.sidebarScrollbar.nativeElement) {
        this.sidebarScrollbar.nativeElement.scrollTop =
          localStorage.sidebarScroll;
      }
    }
  }

  searchOrg(event) {
    this.companyService.showSelectOrgView = false;
    this.orgSearchText = event.term;

    if (event.term) {
      this.searchText$.next(event.term);
    }
  }

  setOrganization(org, orgSearch) {
    if (org) {
      orgSearch.clearModel();
      this.searchText$.next('');
      this.showOrgSearchMenu = false;
      this.router.navigate([`${org.slug}/dashboard`]);
      setTimeout(() => {
        this.companyService.setCurrentCompanyId(org && org.id);
        this.companyService.setCurrentCompanyB2BId(
          org && org.salesforceB2BAccountId
        );
      }, 500);
    }
  }

  toggleSubmenu(currentMenu) {
    if (!currentMenu.state) {
      currentMenu.state = 'expand';
    } else if (currentMenu.state === 'collapse') {
      currentMenu.state = 'expand';
    } else {
      currentMenu.state = 'collapse';
    }
  }

  expandCollapse(currentMenu, active) {
    if (active.isActive) {
      setTimeout(() => {
        currentMenu.state = 'expand';
      });

      return 'expand';
    } else {
      return 'collapse';
    }
  }

  onAutoCompleteSearchInput(
    autoCompletePayload: AutoCompleteSearchInputActionEvent,
    menuItem: MenuItem
  ) {
    switch (menuItem.id) {
      case 'projects':
        this.handleProjectsAutoCompleteSearchInput(
          autoCompletePayload,
          menuItem
        );

        return;
    }
  }

  getAutoCompleteSearchResultObservable(menuItem: MenuItem) {
    if (!this.autoCompleteResultsObservables.hasOwnProperty(menuItem.title)) {
      this.autoCompleteResultsObservables[menuItem.title] = null;
    }

    return this.autoCompleteResultsObservables[menuItem.title];
  }

  autoCompleteSelection(event, menuItem: MenuItem) {
    switch (menuItem.id) {
      case 'projects':
        this.handleProjectAutoCompleteSelection(event, menuItem);

        return;
    }
  }

  updateProjectMenu(projectId: string) {
    const menuItemIndex = this.getMenuIndexByName('Projects');
    const menuItem = this.menus[menuItemIndex];

    this.getAndInitProjectMenu(projectId, menuItem);
  }

  checkIsLoading(menu: MenuItem) {
    return this.isLoading[menu.title] || false;
  }

  getCurrentCompanySlug() {
    return '/' + this.companyService.getCurrentCompanySlug();
  }

  getMenuDefaultTab(menu) {
    return menu.defaultTab ? menu.defaultTab : '';
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.sidebarCollapsed.emit(this.isCollapsed);
  }

  setCollapse(value) {
    if (this.collabseTimeout) {
      clearTimeout(this.collabseTimeout);
      this.collabseTimeout = null;
    }

    if (value) {
      this.collabseTimeout = window.setTimeout(() => {
        this.isCollapsed = value;
        this.collabseTimeout = null;
      }, 300);
    } else {
      this.isCollapsed = value;
    }
  }

  isMenuUrlActive(router, menu) {
    return (
      router.url.includes(menu.url) ||
      (menu.url === '/membership-summary' &&
        (router.url.includes(this.getCurrentCompanySlug() + '/project/') ||
          router.url.includes('/project/project-group/')))
    );
  }

  navigateToEditOrg() {
    this.router.navigate([this.getCurrentCompanySlug() + '/profile/edit'], {
      state: { source: '/company/profile' },
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideMobileSidebar.emit(true);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.scrollTop = this.pageSettings.pageSidebarMinified
      ? event.srcElement.scrollTop + 40
      : 0;

    if (typeof Storage !== 'undefined') {
      localStorage.setItem('sidebarScroll', event.srcElement.scrollTop);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }

    if (window.innerWidth <= 1024) {
      this.isCollapsed = true;
      this.sidebarCollapsed.emit(this.isCollapsed);
    }
  }

  onSwitchVersion() {
    window.open(
      environment.v2Url +
        this.getCurrentCompanySlug() +
        '/overview/project-contributions',
      '_self'
    );
  }

  private init() {
    /** spinner starts on init */
    this.spinner.show();
    this.updateCurrentCompanySubscription = this.companyService
      .getMyCompany()
      .subscribe(
        company => {
          if (company) {
            this.isAllOrgsRollup = this.companyService.isAllOrgsRollup();
            this.isConglomerateOrg = this.companyService.isConglomerateOrg();

            // handling old routes which contacins the word company instead of company name
            if (
              this.location.path() &&
              this.location.path().split('/') &&
              this.location.path().split('/').length > 1 &&
              this.location.path().split('/')[1].toLowerCase() === 'company'
            ) {
              const url = this.location.path().split('/');

              url[1] = company.slug;
              this.router.navigateByUrl(url.join('/'));
            } else if (
              this.location.path() &&
              this.location.path().split('/') &&
              this.location.path().split('/').length > 1 &&
              this.location.path().split('/')[1].toLowerCase() !== 'company' &&
              this.location.path().split('/')[1] !== company.slug &&
              this.location.path().split('/')[1] !==
                company.slug + '_allorgs_rollup' &&
              !this.companyService.isForStaffMembers() &&
              !this.companyService.isConglomerateOrg()
            ) {
              this.router.navigate([
                this.location.path().split('/')[1] + '/notfound',
              ]);
            }

            if (
              this.location.path().split('/')[1] !== company.slug &&
              this.location.path().split('/')[1] !==
                company.slug + '_allorgs_rollup' &&
              this.companyService.isConglomerateOrg() &&
              !this.companyService.showSelectOrgView
            ) {
              const orgObject = this.companyService.conglomerateOrgs.find(
                org =>
                  org.slug === this.location.path().split('/')[1] ||
                  org.slug + '_allorgs_rollup' ===
                    this.location.path().split('/')[1]
              );

              this.companyService.setCurrentCompanyId(orgObject.id);
              this.companyService.setCurrentCompanyB2BId(orgObject.id);
              this.companyService.setCurrentCompanySlug(orgObject.slug);
              setTimeout(() => {
                this.companyService.conglomerateOrgIdChanged.next(orgObject.id);
              }, 200);
            } else {
              this.spinner.hide();
              this.myCompany = company;
              this.companyService.setCurrentCompanySlug(this.myCompany.slug);
              this.getDefaultAvatar();
            }
          } else {
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    this.foundationService.getStandAloneProjectsFoundationId().subscribe(
      foundationId => {
        this.standAloneProjectFoundationId = foundationId;
      },
      () => {}
    );
    this.addRouteListener();

    // Set context if one is not defined yet
    if (!!!this.currentContext) {
      this.currentContexts = this.storageService.getItemSync(
        storedContextList
      ) as UserContext[];
      this.currentContext = this.currentContexts
        ? this.currentContexts[0]
        : UserContext.Company;
      this.fillMenuItems();
      this.menus = this.menus.filter(
        menu =>
          !menu.context ||
          menu.context === this.currentContext ||
          (Array.isArray(menu.context) &&
            menu.context.includes(this.currentContext))
      );
    }
  }

  private removeSpecialCharacters(str) {
    return str.replace(/[^a-zA-Z]/g, '') || 'a';
  }

  private getDefaultAvatar() {
    const firstChart =
      this.myCompany.name &&
      this.removeSpecialCharacters(this.myCompany.name)[0].toLowerCase();

    if (firstChart) {
      this.defaultImage = `https://lfx-cdn-prod.s3.amazonaws.com/users/avatar/${firstChart}.png`;
    }
  }

  private handleProjectsAutoCompleteSearchInput(
    autoCompletePayload: AutoCompleteSearchInputActionEvent,
    menuItem: MenuItem
  ) {
    const { text, type } = autoCompletePayload;

    if (type === AutoCompleteActionEventType.focus || !text || !text.length) {
      this.autoCompleteResultsObservables[menuItem.title] = of([]);

      return;
    }

    this.autoCompleteResultsObservables[menuItem.title] =
      this.foundationService.searchFoundation({
        filter: `name contains ${text} and status eq Active and entityType ne Internal Allocation`,
      });
  }

  private handleProjectAutoCompleteSelection(event, menuItem: MenuItem) {
    const selectedProject: Foundation = event && event.item;

    if (!selectedProject) {
      return;
    }
    this.getAndInitProjectMenu(selectedProject.id, menuItem);
  }

  private getAndInitProjectMenu(projectId: string, menuItem: MenuItem) {
    this.startLoading(menuItem);
    this.foundationService
      .getFoundation(projectId, false, true)
      .pipe(take(1))
      .subscribe(
        project => {
          this.currentProjectId = projectId;
          const projectOverViewLink = this.getProjectOverviewLink(project);

          this.stopLoading(menuItem);
          this.constructProjectMenu(project, menuItem);

          if (
            !this.router.url.includes(projectOverViewLink) &&
            !this.router.url.includes('notfound')
          ) {
            this.router.navigate([
              this.getCurrentCompanySlug() + projectOverViewLink,
            ]);
          }
        },
        () => this.stopLoading(menuItem)
      );
  }

  private constructProjectMenu(project: Foundation, menuItem: MenuItem) {
    const projectType = this.foundationService.getProjectType(project);

    if (projectType === ProjectType.project) {
      this.constructProjectMenuProjectType(project, menuItem);
    } else {
      this.constructProjectMenuProjectGroupStandAloneType(project, menuItem);
    }
  }

  private constructProjectMenuProjectType(
    project: Foundation,
    menuItem: MenuItem
  ) {
    let menu: MenuItem = {
      title: project.name,
      url: this.getDefaultMenuLink(project),
    };

    this.startLoading(menuItem);
    this.foundationService
      .getFoundation(project.parent, false, true)
      .pipe(take(1))
      .subscribe(
        parent => {
          this.stopLoading(menuItem);
          menu = {
            id: parent.id,
            updateMenu: true,
            title: parent.name,
            state: 'expand',
            submenu: [menu],
            url: this.getDefaultMenuLink(parent),
          };
          menuItem.submenu = [menu];
        },
        () => this.stopLoading(menuItem)
      );
  }

  private constructProjectMenuProjectGroupStandAloneType(
    project: Foundation,
    menuItem: MenuItem
  ) {
    const menu: MenuItem = {
      title: project.name,
      url: this.getDefaultMenuLink(project),
    };

    menuItem.submenu = [menu];
  }

  private constructProjectMenuLinks(project: Foundation) {
    return [
      {
        title: 'Project Overview',
        url: this.getDefaultMenuLink(project),
      },
    ];
  }

  private getDefaultMenuLink(project: Foundation) {
    return this.getMenuLinkUrl(project, 'Project Overview');
  }

  private getMenuLinkUrl(project: Foundation, type: string) {
    const baseLink = this.getMenuLinkUrlBase(project);

    switch (type) {
      case 'EasyCla':
        return `${baseLink}/cla`;
      case 'Project Overview':
        return this.getProjectOverviewLink(project);
    }
  }

  private getProjectOverviewLink(project: Foundation) {
    if (project.projectType === ProjectType.projectGroup) {
      return `/project/project-group/${project.id}`;
    }

    return `/project/${project.id}`;
  }

  private getMenuLinkUrlBase(project: Foundation) {
    const projectType = this.foundationService.getProjectType(project);

    switch (projectType) {
      case ProjectType.projectGroup:
        return `foundation/${project.id}`;
      case ProjectType.project:
        return `foundation/${project.parent}/project/${project.id}`;
      case ProjectType.standAlone:
        return `foundation/${this.standAloneProjectFoundationId}/project/${project.id}`;
    }
  }

  private getMenuIndexByName(name) {
    for (let index = 0; index < this.menus.length; index++) {
      if (this.menus[index].title === name) {
        return index;
      }
    }

    return -1;
  }

  private constructToolTipProjectArray(
    project: Foundation,
    projects: FoundationProject[]
  ) {
    const orderedProjects = projects
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((previous, item) => {
        if (item.id === project.id) {
          previous.unshift(item);
        } else {
          previous.push(item);
        }

        return previous;
      }, []);

    return orderedProjects.map(project => ({
      id: project.id,
      title: project.name,
      url: this.getDefaultMenuLink(project),
    }));
  }

  private addRouteListener() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.root),
        switchMap(firstChild =>
          firstChild ? of(this.getRouteRootLastChild(firstChild)) : of(null)
        ),
        filter(route => route.snapshot.data.updateSideNavProject || false),
        filter(route => route.snapshot.params.id || false),
        filter(route => route.snapshot.params.id !== this.currentProjectId)
      )
      .subscribe(route => {
        this.updateProjectMenu(route.snapshot.params.id);
      });
  }

  private fillMenuItems() {
    if (this.companyService.isAllOrgsRollup()) {
      this.menus = allOrgsRollupPageMenus;
      this.separatorPositionAfterUrl = '/training-and-certification';
    } else {
      this.menus = pageMenus;
      this.separatorPositionAfterUrl = '/software-inventory';
    }
  }

  private getRouteRootLastChild(root: ActivatedRoute) {
    return !root.firstChild
      ? root
      : this.getRouteRootLastChild(root.firstChild);
  }

  private startLoading(menu: MenuItem) {
    if (menu) {
      this.isLoading[menu.title] = true;
      this.spinner.show(`menu-${menu.title}-spinner`);
    }
  }

  private stopLoading(menu: MenuItem) {
    this.isLoading[menu.title] = false;
  }

  navigateTohome() {
    this.setCollapse(true);
    this.companyService.showSelectOrgView = true;
    this.router.navigate(['/']);
  }
}
