// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface DataSet {
  [key: string]: any;
  label?: string;
  data: number[];
}

export interface WaterfallChartOptions {
  title: string;
  bar1: WaterfallChartOptionsBar;
  bar2?: WaterfallChartOptionsBar;
  tooltipAction?: WaterfallChartTooltipAction;
  labels: string[];
}

export interface WaterfallChartOptionsBar {
  title: string;
  data: WaterfallChartOptionsBarData;
  color: string;
}

export interface WaterfallChartTooltipAction {
  title: string;
}

export interface WaterfallChartOutput {
  type: string;
  value?: {
    bar1Value: number;
    bar2Value: number;
    bar1Name: string;
    bar2Name: string;
    bar1Color: string;
    bar2Color: string;
    label: string;
  };
}

export type WaterfallChartOptionsBarData = WaterfallChartOptionsBarDataSingle[];

export type WaterfallChartOptionsBarDataSingle =
  | number
  | { value: number; color: string };

export interface StackedChartOptions {
  title: string;
  showTooltipContent?: boolean;
  bars: StackedChartOptionsBar[];
  labels: string[];
  tooltipPercentage?: boolean;
  tooltipTotalLabel?: string;
  extraSeries?: any[];
  xAxisRotation?: number;
}

export interface StackedChartOptionsBar {
  title: string;
  data: number[];
  color: string;
  barMaxWidth?: number;
  legendInfoIconText?: string;
}

export interface LineAreaChartOptions {
  title: string;
  bars: LineAreaChartOptionsBar[];
  labels: string[];
}

export interface LineAreaChartOptionsBar {
  title: string;
  data: number[];
  color: string;
  isArea?: boolean;
}

export interface TwoLayerFunnerChartOptions {
  backLayer: TwoLayerFunnerChartBackLayer;
  frontLayer: TwoLayerFunnerChartFrontLayer;
}

export interface TwoLayerFunnerChartBackLayer {
  item: TwoLayerFunnerChartItem;
}

export interface TwoLayerFunnerChartFrontLayer {
  items: TwoLayerFunnerChartItem[];
}

export interface TwoLayerFunnerChartItem {
  data: TwoLayerFunnerChartData[];
  title: string;
}

export interface TwoLayerFunnerChartData {
  value: number;
  name: string;
  color: string;
  opacity?: number;
  tooltipLabel: string;
  toolTipOpacity?: number;
}
