// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CompanySigningEntity {
  companyAcl: string[];
  companyExternalId: string;
  companyId: string;
  companyManagerId: string;
  companyName: string;
  signingEntityName: string;
}
