<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-full-card
  [title]="initiative.name"
  [details]="initiative.description"
  [color]="initiative.colorBand"
  [logo]="initiative.logoUrl"
  (action) ="gotoMeetupsDetail(initiative.id)">

    <div lfx-crowdfunding-bodyView  class="body-wrapper">
        <div class="row separator">
            <div class="col-md-6">
                <div class="text">Donations</div>
                <div>
                        <lfx-slider [min]="0" [max]="initiative.funding.target"
                        [currency]="initiative.funding.currencyCode" [color]="initiative.colorBand"
                        [value]="initiative.funding.current" [disabled]="true"></lfx-slider>
                </div>
            </div>

            <div class="col-md-6" *ngIf="initiative.registeredPeople" >
                <div class="text"># Of People Registered</div>
                <lfx-logo-list [items]="initiative.registeredPeople" limit="2"></lfx-logo-list>
                <span class="bold ml-2">({{initiative.registeredPeople.length}})</span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="text">Event Date/Time</div>
                <div class="bold">{{ initiative.eventStartDate | date:'d MMM y, h:mm a' }}
                    - {{ initiative.eventEndDate | date:'d MMM y, h:mm a' }}
                </div>
            </div>

            <div class="col-md-6">
                <div class="text">Event Location</div>
                <div class="bold">{{ initiative.city }}</div>
            </div>
        </div>
    </div>

    <div lfx-crowdfunding-footerView class="footer-wrapper">
        <div class="row">
            <div class="col-md-6">
                <div class="text">Sponsors</div>
                <lfx-logo-list [items]="initiative.funding.sponsors" limit="2"></lfx-logo-list>
            </div>

            <div class="col-md-6">
                <div class="text">Backers</div>
                <lfx-logo-list [items]="initiative.funding.backers" limit="2"></lfx-logo-list>
            </div>
        </div>
    </div>
</lfx-full-card>
