// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface UpdateCurrentUserInput {
  username: string;
  firstName: string;
  lastName: string;
  timezone: string;
  emails: EmailInput[];
}

export interface EmailInput {
  emailAddress: string;
  emailType?: string;
  active: boolean;
  isDeleted?: boolean;
  isPrimary?: boolean;
  isVerified?: boolean;
}
