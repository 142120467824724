// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface AdminUser {
  id: string;
  name: string;
  email: string;
  logoUrl: string;
  roleId: string;
  roleName: string;
  scopeId: string;
  isAppointed: boolean;
}
