<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="download-btn" [style.right]="right + 'px'" [style.top]="top + 'px'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    (click)="downloadChartImage()"
    id="audience-industry-download-img-btn"
  >
    <defs>
      <style>
        .a {
          fill: none;
          stroke: #8393a7;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px;
        }
      </style>
    </defs>
    <g transform="translate(-3.5 -3.5)">
      <path
        class="a"
        d="M20.5,22.5v3.556a1.778,1.778,0,0,1-1.778,1.778H6.278A1.778,1.778,0,0,1,4.5,26.056V22.5"
        transform="translate(0 -7.333)"
      />
      <path
        class="a"
        d="M10.5,15l4.444,4.444L19.389,15"
        transform="translate(-2.444 -4.278)"
      />
      <path class="a" d="M18,15.167V4.5" transform="translate(-5.5)" />
    </g>
  </svg>
</div>
