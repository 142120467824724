// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { generalConstants } from '@lfx/config';
import { UploadSbomFileModalStatus } from '@lfx/core/models/software-inventory';
import { CompanyService, SoftwareInventoryService } from '@lfx/core/services';
import { spaceValidator } from '@lfx/shared/validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lfx-upload-sbom-file-modal',
  templateUrl: './upload-sbom-file-modal.component.html',
  styleUrls: ['./upload-sbom-file-modal.component.scss'],
})
export class UploadSbomFileModalComponent implements OnInit, OnDestroy {
  @ViewChild('upload', { static: true })
  uploadInput: ElementRef<HTMLInputElement>;

  status: UploadSbomFileModalStatus = UploadSbomFileModalStatus.DEFAULT;
  statusEnum = UploadSbomFileModalStatus;
  isLoading = false;
  errorMessage = 'Invalid file type';
  maxFileSize = 10000000; // 10mb
  companyId;
  uploadSBOMForm!: FormGroup<{
    githubRepositoryUrl: FormControl<string | null>;
    sbomFileName: FormControl<string | null>;
  }>;

  file: File;
  githubRepoRegex = generalConstants.githubRepoRegex;

  private destroy$ = new Subject<void>();

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private softwareInventoryService: SoftwareInventoryService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder
  ) {
    this.spinnerService
      .getSpinner('upload-sbom-spinner')
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.isLoading = value.show;
      });

    this.companyService
      .getMyCompany()
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => (this.companyId = company.id));
  }

  get githubRepositoryUrlControl() {
    return this.uploadSBOMForm.controls['githubRepositoryUrl'] as FormControl;
  }

  get sbomFileNameControl() {
    return this.uploadSBOMForm.controls['sbomFileName'] as FormControl;
  }

  ngOnInit(): void {
    this.uploadSBOMForm = this.formBuilder.group({
      githubRepositoryUrl: [
        '',
        [
          Validators.required,
          spaceValidator,
          Validators.pattern(this.githubRepoRegex),
        ],
      ],
      sbomFileName: ['', [Validators.required, spaceValidator]],
    });
  }

  onFormSubmit() {
    if (this.file) {
      this.spinnerService.show('upload-sbom-spinner');
      const reader = new FileReader();

      reader.onload = event => {
        const fileData = event.target.result;

        this.softwareInventoryService
          .uploadSbomFile(
            fileData,
            this.sbomFileNameControl.value,
            this.companyId,
            this.githubRepositoryUrlControl.value
          )
          .pipe(take(1))
          .subscribe(
            () => {
              this.status = this.statusEnum.SUCCESS;
            },
            () => {
              this.status = this.statusEnum.ERROR;
              this.errorMessage = 'system error';
              this.spinnerService.hide('upload-sbom-spinner');
            },
            () => {
              this.spinnerService.hide('upload-sbom-spinner');
            }
          );
      };
      reader.readAsArrayBuffer(this.file);
    }
  }

  closeModal() {
    this.ngbActiveModal.close({ status: this.status });
  }

  onClickUpload() {
    const fileInput = this.uploadInput.nativeElement;

    fileInput.click();
  }

  onFileUpload(target: HTMLInputElement) {
    const files = target.files;
    const file = files && files.item(0);

    if (!file) {
      return;
    }
    const isValidFile = this.validateFile(file);

    if (isValidFile) {
      this.file = file;
      this.sbomFileNameControl.setValue(file.name);
      this.status = this.statusEnum.DEFAULT;
    } else {
      this.status = this.statusEnum.ERROR;
    }
  }

  validateFile(file: File): boolean {
    if (!file) {
      return false;
    }
    const allowedFiletypes = [
      'application/xml',
      'text/xml',
      'application/json',
    ];

    const isSpdx = file.name.endsWith('.spdx');

    if (!allowedFiletypes.includes(file.type) && !isSpdx) {
      this.errorMessage = 'Invalid file type.';

      return false;
    }

    if (file.size > this.maxFileSize) {
      this.errorMessage = 'Invalid file size.';

      return false;
    }

    return true;
  }

  getControlErrors(control: FormControl): ValidationErrors | null {
    return !control.pristine && control.touched ? control.errors : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
