// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as echarts from 'echarts';
import { DataPoint, RadarChartConfig } from '../am-chart-util';

@Component({
  selector: 'lfx-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
})
export class RadarChartComponent implements OnChanges, AfterViewInit {
  @Input() config!: RadarChartConfig;
  @ViewChild('eChart', { static: false }) private eChart!: ElementRef;

  eRadarChart: echarts.ECharts | undefined;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.config.currentValue) {
      // this.dataSource = this.config.data?.source;
      setTimeout(() => {
        this.eRadarChart?.resize();
      }, 10);
    }
  }

  public ngAfterViewInit() {
    this.loadData();
  }

  public loadData() {
    const chartConfig = this.buildChart();

    this.eRadarChart = this.loadChart(this.eChart, chartConfig);
    setTimeout(() => {
      this.eRadarChart?.resize();
    }, 200);
  }

  public loadChart(echartObject: any, chartOptions: any) {
    if (!echartObject) {
      return;
    }
    const options = chartOptions;

    const myChart = echarts.init(echartObject.nativeElement, undefined, {
      // height: height !== undefined ? height : 361.5,
    });

    if (options) {
      myChart.setOption(options);
    }

    this.legendSelectionChange(myChart);

    return myChart;
  }

  private legendSelectionChange(myChart: echarts.ECharts) {
    // The legend only trigger legendselectchanged event.
    myChart.on('legendselectchanged', (params: any) => {
      const selected = Object.values(params.selected).filter(
        val => val === true
      );

      // State if none of the legend is selected then reselect the last legend.
      if (selected.length === 0) {
        myChart.setOption({ animation: false });

        // Re-select what the user unselected.
        myChart.dispatchAction({
          type: 'legendSelect',
          name: params.name,
        });

        myChart.setOption({ animation: true });
      }
    });
  }

  private buildChart() {
    const data = this.config.data;

    return this.buildChartConfig(this.config, data);
  }

  private buildChartConfig(config: RadarChartConfig, data?: DataPoint[]) {
    let max = 0;

    data?.forEach(
      dataElement => (max = max < dataElement.value ? dataElement.value : max)
    );
    max *= 1.25;
    const chartConfig = {
      title: {},
      // tooltip: {
      //   extraCssText: 'z-index: 10;',
      //   backgroundColor: '#000',
      //   textStyle: {
      //     color: '#fff',
      //   },
      // },
      // legend: {
      //   bottom: 'bottom',
      //   itemWidth: 10,
      //   itemHeight: 10,
      //   padding: [20, 0, 0, 0],
      //   itemStyle: {
      //     color: '#5196b7',
      //   },
      //   icon: 'circle',
      // },
      radar: {
        // shape: 'circle',
        radius: config.chart.radius ? config.chart.radius : '50%',
        indicator: this.buildIndicators(data, max),
        axisName: {
          formatter: (value: string) => {
            const labels = value.split('|');

            if (labels.length === 2) {
              return (
                '{value|' + labels[0] + '}\n{labelStyle|' + labels[1] + '}'
              );
            }

            return '{value|' + labels[0] + '}';
          },
          rich: {
            value: {
              fontSize: 14,
              color: '#333333',
              fontWeight: 700,
              fontFamily: 'Open Sans Semibold, Open Sans, sans-serif',
            },
            labelStyle: {
              fontSize: 14,
              color: '#807f83',
              fontFamily: 'Open Sans, sans-serif',
            },
          },
        },
        splitLine: {
          show: false,
        },
        splitArea: {
          show: false,
          areaStyle: {
            color: ['#ffffff'],
          },
        },
        axisLine: {
          lineStyle: {
            color: '#e0e0e0',
          },
        },
      },
      series: data
        ? [
            {
              type: 'radar',
              padding: [10, 0, 0, 0],
              data: data.length
                ? [
                    {
                      value: data?.map(item => item.value),
                      name: config.chart.seriesName,
                      areaStyle: {
                        color: '#2968fa',
                        opacity: 0.1,
                      },
                      lineStyle: {
                        color: '#2968fa',
                      },
                      itemStyle: {
                        color: '#2968fa',
                      },
                    },
                  ]
                : [],
            },
          ]
        : [],
    };

    return chartConfig;
  }

  private buildIndicators(dataPoints: DataPoint[] | undefined, max: number) {
    if (!dataPoints) {
      return [];
    }

    const data = dataPoints.map(dataPoint => ({
      name: dataPoint.name,
      max,
      color: '#333333',
    }));

    return data;
  }
}
