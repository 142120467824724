// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
  @Input() min = 0;
  @Input() max = 100;
  @Input() value = 50;
  @Input() currency = '$';
  @Input() disabled = false;
  @Input() color = '';

  @ViewChild('sliderInput') slider: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.slider.nativeElement.disabled = this.disabled;
    this.updateColorCode();
  }

  updateColorCode() {
    const val = (this.value - this.min) / (this.max - this.min);
    const color =
      '-webkit-gradient(linear, left top, right top, ' +
      'color-stop(' +
      val +
      ',' +
      this.color +
      '), ' +
      'color-stop(' +
      val +
      ',' +
      generalConstants.sliderRollerColor +
      ')' +
      ')';

    this.slider.nativeElement.style.background = color;
  }
}
