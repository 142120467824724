// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ContributorProject {
  id: string;
  name: string;
  maintainer: boolean;
  userDetails: ContributorProjectUserDetail[];
}

export interface ContributorProjectUserDetail {
  email: string;
  username: string;
  repositories: ContributorProjectRepository[];
}

export interface ContributorProjectRepository {
  id: string;
  maintainer: boolean;
  shortName: string;
  existingMaintainer?: boolean;
}

export interface ContributorProjectsResult {
  contributorProjects: {
    projects: ContributorProject[];
  };
}

export interface ProjectRepository {
  id: string;
  slug: string;
  url: string;
}

export interface ProjectRepositoriesResult {
  projectRepositories: {
    data: ProjectRepository[];
  };
}

export interface NewRepositoryRole {
  role: string;
  repositoryId: string;
  source: string;
  updatedAt: string;
  createdAt: string;
}

export interface CreateRepositoryRolePayload {
  code: string;
  message: string;
  success: boolean;
  newRepositoryRoles: NewRepositoryRole[];
}

export interface CreateRepositoryRoleResult {
  createRepositoryRole: CreateRepositoryRolePayload;
}

export interface DeletRepositoryRolePayload {
  code: string;
  message: string;
  success: boolean;
}

export interface DeletRepositoryRoleeResult {
  deleteRepositoryRole: DeletRepositoryRolePayload;
}
