// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface UpdateFoundationLegalInput {
  id?: string;
  legalEntity?: string;
  entityType?: EntityType;
  formationDate?: string;
  dissolutionDate?: string;
  msaUrl?: string;
  charterUrl?: string;
  cocUrl?: string;
}

enum EntityType {
  DirectedFund = 'Directed Fund',
  Incorporated = 'Incorporated',
  SeparatelyIncorporated = 'Separately Incorporated',
  SeriesLLC = 'Series LLC',
  Unincorporated = 'Unincorporated',
}
