// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstN',
})
export class FirstNPipe implements PipeTransform {
  transform(value: any[], n: number): any[] {
    return value.slice(0, n);
  }
}
