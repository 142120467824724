// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { MembershipFilters } from '@lfx/config';
import {
  DiscoverMemberships,
  GetMembershipContactsQueryParams,
  GetResponse,
  ListAccountSponsorshipData,
  ListAccountSponsorshipQueryParams,
  MemberAccount,
  Membership,
  MembershipContacts,
} from '@models';
import { sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ListAccountMembershipsRes, MembershipServiceGql } from '../gql';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  selectedMembershipForManageTeams = null;

  constructor(private membershipServiceGql: MembershipServiceGql) {}

  isMember(companyId: string, projectId?: string): Observable<boolean> {
    let filter =
      'membershiptype eq Corporate and membershipstatus in Active,At Risk';

    if (projectId) {
      filter += ` and projectid eq ${projectId}`;
    }

    return this.getMembershipHistory(companyId, filter).pipe(
      switchMap((response: any) =>
        of(response && response.some(value => !this.hasExpired(value.endDate)))
      )
    );
  }

  listMembershipsByAccountID(
    accountID: string,
    loadMainDataOnly?: boolean,
    filters?: MembershipFilters,
    noCache?: boolean
  ): Observable<Membership[]> {
    // loadMainDataOnly, skips all the data returned excluding the name, status, endDate, id and createdAt
    return this.membershipServiceGql.listMembershipsByAccountID(
      accountID,
      loadMainDataOnly,
      filters,
      noCache
    );
  }

  listMembershipsSnowflake(
    accountId: string,
    isAllOrgs?: boolean,
    status?: string,
    noCache?: boolean
  ): Observable<Membership[]> {
    return this.membershipServiceGql.listMembershipsSnowflake(
      accountId,
      isAllOrgs,
      status,
      noCache
    );
  }

  listDiscoverMemberships(
    accountId: string,
    isAllOrgs?: boolean,
    noCache?: boolean
  ): Observable<DiscoverMemberships[]> {
    return this.membershipServiceGql.listDiscoverMemberships(
      accountId,
      isAllOrgs,
      noCache
    );
  }

  listAccountMemberships(
    accountID: string,
    loadMainDataOnly?: boolean,
    filters?: MembershipFilters,
    noCache?: boolean
  ): Observable<ListAccountMembershipsRes> {
    // loadMainDataOnly, skips all the data returned excluding the name, status, endDate, id and createdAt
    return this.membershipServiceGql.listAccountMemberships(
      accountID,
      loadMainDataOnly,
      filters,
      noCache
    );
  }

  getMembershipContacts(
    accountId: string,
    projectId: string,
    queryParams?: GetMembershipContactsQueryParams,
    useCache?: boolean
  ): Observable<MembershipContacts> {
    return this.membershipServiceGql.getMembershipContacts(
      accountId,
      projectId,
      {
        ...queryParams,
      },
      useCache
    );
  }

  getMembershipHistory(
    accountId: string,
    filter?: string,
    pageSize?: number,
    isAllOrgs?: boolean,
    noCache?: boolean
  ): Observable<Membership[]> {
    return this.membershipServiceGql.getMembershipHistory(
      accountId,
      filter,
      pageSize,
      isAllOrgs,
      noCache
    );
  }

  getprojectRolesStats(
    accountId: string,
    projectId?: string,
    noCache?: boolean
  ): Observable<any> {
    return this.membershipServiceGql.getprojectRolesStats(
      accountId,
      projectId,
      noCache
    );
  }

  createMembershipContact(input: any) {
    return this.membershipServiceGql.createMembershipContact(input);
  }

  updateMembershipContact(contact: any) {
    return this.membershipServiceGql.updateContact(contact);
  }

  updateKeyContactRoles(input: any) {
    return this.membershipServiceGql.updateKeyContactRoles(input);
  }

  deleteMembershipContact(contact) {
    return this.membershipServiceGql.deleteMembershipContact(contact);
  }

  updateContactRoles(input: any) {
    return this.membershipServiceGql.updateContactRoles(input);
  }

  getUniqueMembershipTiersFromFoundation(foundation, company) {
    const totalEmployees = company.totalEmployees;
    const type = this.convertOrganizationType(company.companyType);
    const includedTypes = [
      'academic institution',
      'government entity',
      'nonprofit',
    ];
    const isAssociate =
      (type && company.link && includedTypes.includes(type.toLowerCase())) ||
      foundation.id === 'a092M00001JVy4NQAT';

    const excludeAssociate = !isAssociate;

    const noTierDefined = foundation.tiers.filter(
      item =>
        !this.isIndividualProduct(item) &&
        !this.isAssociateProduct(item) &&
        this.tierRangeNotDefined(item)
    );

    const associatedProducts = foundation.tiers.filter(
      item => !this.isIndividualProduct(item) && this.isAssociateProduct(item)
    );

    const commonProducts = foundation.tiers.filter(
      item =>
        !this.isAssociateProduct(item) &&
        !this.isIndividualProduct(item) &&
        !this.tierRangeNotDefined(item)
    );

    commonProducts.forEach(tier => {
      if (tier.sizeMin && !tier.sizeMax) {
        tier.sizeMax = 9999999999;
      }
    });

    let organizationProducts = commonProducts.filter(
      tier =>
        totalEmployees >= tier.sizeMin &&
        (totalEmployees < tier.sizeMax || !tier.sizeMax)
    );

    if (!excludeAssociate) {
      organizationProducts = [...organizationProducts, ...associatedProducts];
    }

    if (noTierDefined && noTierDefined.length) {
      organizationProducts = [...organizationProducts, ...noTierDefined];
    }

    // Handle R Consortium case
    const silverProject25Index = organizationProducts.findIndex(
      product => product.id === 'a0I410000030FZzEAM'
    );

    if (!excludeAssociate && totalEmployees > 99 && silverProject25Index > -1) {
      const silverProject10 = foundation.tiers.find(
        product => product.id === 'a0I410000030FZyEAM'
      );

      organizationProducts.splice(silverProject25Index, 1, silverProject10);
    }
    // end of R Consortium case

    // sort
    return sortBy(organizationProducts, ['pricing']).reverse();
  }

  listMemberSponsorships(
    accountId: string,
    queryParams: ListAccountSponsorshipQueryParams,
    noCache?: boolean
  ): Observable<GetResponse<ListAccountSponsorshipData> | null> {
    return this.membershipServiceGql.listMemberSponsorships(
      accountId,
      queryParams,
      noCache
    );
  }

  getMemberAccount(
    accountId: string,
    noCache?: boolean
  ): Observable<MemberAccount | null> {
    return this.membershipServiceGql.getMemberAccount(accountId, noCache);
  }

  private isIndividualProduct(tier: any) {
    return tier.name.toLowerCase().includes('individual');
  }

  private isAssociateProduct(tier: any) {
    const associateTiers = ['associate', 'non-profit', 'nonprofit'];
    const tierName = tier.name.toLowerCase();

    return associateTiers.some(item => tierName.includes(item));
  }

  private tierRangeNotDefined(tier) {
    return tier.sizeMin === undefined && tier.sizeMax === undefined;
  }

  private convertOrganizationType(type) {
    if (!type) {
      return type;
    }

    type = type.charAt(0).toUpperCase() + type.slice(1);

    if (type === 'Education') {
      type = 'Academic Institution';
    } else if (type === 'Public' || type === 'Private' || type === 'Personal') {
      type = 'Commercial Company';
    } else if (type === 'Government') {
      type = 'Government Entity';
    }

    return type;
  }

  private hasExpired(date: string) {
    const expireDate = new Date(date).getTime();
    const nowDate = new Date().getTime();

    return nowDate > expireDate;
  }
}
