<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="card-accordion" title="">
  <ngb-accordion #accordionRef="ngbAccordion"
    activeIds="{{ sectionId }}"
    type="{{ type }}"
    (panelChange)="toggleAccordion($event)"
  >
    <ngb-panel id="{{ sectionId }}">
      <ng-template ngbPanelHeader>
        <div class="section-header d-flex align-items-center justify-content-between text-white">
          <lfx-status-indicator [status]= "status"></lfx-status-indicator>
          <button ngbPanelToggle class="btn btn-link p-0 text-white">
            {{ title }}
          </button>
          <ng-content select="[header]"></ng-content>
          <ng-container>
            <i (click)="accordionRef.expand(sectionId)" *ngIf="toggle && !accordionRef.isExpanded(sectionId)" class="arrow-indicator float-right fa-fw fas fa-chevron-right"></i>
            <i (click)="accordionRef.collapse(sectionId)" *ngIf="toggle && accordionRef.isExpanded(sectionId)" class="arrow-indicator float-right fa-fw fas fa-chevron-down"></i>
          </ng-container>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="section-content">
          <div *ngIf="!!overlay" class="section-overlay">
            <span class="overlay-text">{{ overlay }}</span>
          </div>
          <ng-content></ng-content>
      </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
