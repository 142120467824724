<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="lf-search-container">
  <span class="far fa-search cursor-pointer"></span>
  <img
    src="assets/img/svgs/normal_clear_u481.svg"
    class="clear-img"
    (click)="clearSearchText()"
    *ngIf="search.value !== ''"
  />
  <input
    #search
    type="text"
    class="form-control"
    [placeholder]="placeholder"
    [value]="searchText"
    (input)="onSearchChange($event)"
  />
</div>
<div class="position-relative">
  <div class="not-found" *ngIf="notFound">
    <span class="text">
      {{ notFoundText }}
    </span>
  </div>
</div>
