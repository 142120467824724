// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import * as faker from 'faker';
import { Company, demoCompanies, randomCompany } from './company';

export enum membershipTerm {
  First = '1 year',
  Second = '2 years',
  Third = '3 years',
}

export enum frequency {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
}

export interface FoundationMembershipTier {
  tier: MembershipTierTemplate;
  name: string;
  description: string;
  sizeMin: number;
  sizeMax: number;
  pricing: number;
  isProrated: boolean;
  doNotDisplayInAutoJoin: boolean;
}

export interface FoundationMember extends Company {
  membershipTier: string;
}

export interface MembershipTierTemplate {
  name: string;
  description?: string;
  icon: string;
  price: number;
}

export interface Members {
  id: string;
  name: string;
  logoUrl: string;
  website: string;
  tier: Tier;
}

export interface Tier {
  id: string;
  name: string;
  status?: string;
  family?: string;
}

export const membershipTiers: MembershipTierTemplate[] = [
  {
    name: 'Platinum Membership',
    icon: 'assets/img/badge-silver.png',
    description:
      'Platinum Members are entities that engage in or support the foundation and related open source-based technologies.',
    price: 350000,
  },
  {
    name: 'Gold Membership',
    icon: 'assets/img/badge-gold.png',
    description:
      'Gold Members are entities that engage in or support the foundation and related open source-based technologies.',
    price: 120000,
  },
  {
    name: 'Silver Membership',
    icon: 'assets/img/badge-silver.png',
    description:
      'Silver Members are entities that engage in or support the foundation and related open source-based technologies.',
    price: 30000,
  },
  {
    name: 'Associate Membership',
    icon: 'assets/img/badge-silver.png',
    description:
      'Associate Members are non-profit or government entities that engage in or support the foundation ' +
      'and related open source-based technologies. An Associate Member has no voting rights,' +
      ' and must meet additional eligibility criteria.',
    price: 500,
  },
];

export const mockFoundationMember = (): FoundationMember => {
  const company = randomCompany();

  return {
    ...company,
    membershipTier: faker.random.arrayElement(membershipTiers),
  };
};

export const demoFoundationMembers = (
  memberMapping: Record<string, string[]>
) => {
  const members: Record<string, FoundationMember> = {};

  Object.keys(memberMapping).map(tierName => {
    const membershipTier = faker.random.arrayElement(membershipTiers);

    memberMapping[tierName].forEach(companyName => {
      members[companyName] = { ...demoCompanies[companyName], membershipTier };
    });
  });

  return members;
};

export const mockMembershipTier = (): FoundationMembershipTier => ({
  name: faker.random.arrayElement(membershipTiers).name,
  description: faker.lorem.sentence(),
  tier: faker.random.arrayElement(membershipTiers),
  sizeMin: faker.random.number({ min: 0, max: 100 }),
  sizeMax: faker.random.number({ min: 100, max: 1000 }),
  pricing: faker.random.number({ min: 10, max: 2000 }),
  isProrated: faker.random.boolean(),
  doNotDisplayInAutoJoin: faker.random.boolean(),
});
