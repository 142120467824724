// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'filterArrayOfObjects',
})
export class FilterArrayOfObjectsPipe implements PipeTransform {
  transform(
    array: { [key: string]: any }[],
    filterText: string,
    attribute: string,
    returnAllIfEmpty = false
  ): { [key: string]: any }[] {
    if (!this.isValid(array, filterText, attribute)) {
      return array;
    }

    const filteredArray = array.filter(
      item =>
        item[attribute] &&
        item[attribute]
          .toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase())
    );

    if (filteredArray.length === 0 && returnAllIfEmpty) {
      return array;
    }

    return filteredArray;
  }

  isValid(
    array: { [key: string]: any }[],
    filterText: string,
    attribute: string
  ) {
    return !(!filterText || !attribute || isEmpty(array));
  }
}
