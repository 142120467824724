// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { InitiativeType } from '@models';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-full-card',
  templateUrl: './full-card.component.html',
  styleUrls: ['./full-card.component.scss'],
})
export class FullCardComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() details: string;
  @Input() logo: string;
  @Input() defaultIcon: string;
  @Input() color: string;
  @Input() tag: string;
  @Input() typeIcon: InitiativeType;
  @Input() status: string;
  @Input() showOverlay: boolean;
  @Output() action = new EventEmitter<void>();
  @ViewChild('cardFooter') cardFooter;
  defaultColor = generalConstants.defaultFoundationColor;
  showFooter: boolean;
  noDescriptionText = generalConstants.noDescriptionText;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  triggerAction() {
    this.action.emit();
  }

  ngAfterViewInit() {
    this.showFooter =
      this.cardFooter.nativeElement &&
      this.cardFooter.nativeElement.children.length > 0 &&
      (this.cardFooter.nativeElement.children[0].children.length > 0 ||
        this.cardFooter.nativeElement.children[1].children.length > 0);
    this.cdRef.detectChanges();
  }

  onLogoError() {
    // On logo load error. Set logo URL to empty string which sets default icon.
    this.logo = '';
  }
}
