<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="img-container">
  <img
    *ngIf="!defaultImage; else tpl"
    (error)="getDefaultAvatar()"
    class="object-cover rounded-full lfx-avartar-img"
    [src]="imageURL"
  />
  <ng-template #tpl>
    <img
      class="object-cover lfx-avartar-img rounded-full"
      [src]="defaultImage"
    />
  </ng-template>
</div>
