<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ngb-alert *ngIf="!isClosed" [type]="type" (close)="close()" animation="true">
  <i
    class="fas {{ fontawesomeIcon }} mr-1"
    [ngClass]="{ 'fa-check-circle': type === 'success' }"
  ></i>

  {{ message }}
</ngb-alert>
