// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import worldMap from '@highcharts/map-collection/custom/world.geo.json';
import { countriesList } from '@lfx/config';
import * as Highcharts from 'highcharts/highmaps';

@Component({
  selector: 'lfx-world-map-chart',
  templateUrl: './world-map-chart.component.html',
  styleUrls: ['./world-map-chart.component.scss'],
})
export class WorldMapChartComponent implements OnInit, OnChanges {
  @Input() data: { country: string; value: number }[];
  @Input() seriesName: string;
  @Input() options: any; // Highcharts options https://api.highcharts.com/highmaps/
  @Input() height = 400;

  Highcharts: typeof Highcharts = Highcharts;

  chartConstructor = 'mapChart';

  chartOptions: any = {
    chart: {
      map: worldMap,
      backgroundColor: 'transparent',
    },
    tooltip: {
      backgroundColor: '#393939',
      borderWidth: 0,
      style: {
        color: '#fff',
        fontSize: '11px',
      },
    },
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoom: true,
      enableMouseWheelZoom: false,
    },
    credits: {
      enabled: false,
    },
    colors: [
      '#F1354A',
      '#F44146',
      '#F64E44',
      '#F75A42',
      '#FD683F',
      '#FE753F',
      '#FF853D',
      '#FF953D',
    ],
    title: {
      text: '',
    },
    legend: {
      enabled: true,
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#FF953D'],
        [0.5, '#F75A42'],
        [1, '#F1354A'],
      ],
    },
    series: [
      {
        type: 'map',
        states: {
          hover: {
            color: '#F1354A',
          },
        },
        borderColor: '#fff',
        allAreas: false,
      },
    ],
  };

  updateChart: boolean;

  private initialWorldMapData = countriesList.map(country => [
    country.id.toLowerCase(),
    0,
  ]);

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.chartOptions = {
        ...this.chartOptions,
        ...this.options,
      };
    }

    if (changes.seriesName && changes.seriesName.currentValue) {
      this.chartOptions.series[0].name = this.seriesName;
    }

    if (changes.data && changes.data.currentValue) {
      const data = this.prepareData(changes.data.currentValue);

      this.chartOptions.series[0].data = data;
      this.updateChart = true;
    }
  }

  ngOnInit(): void {
    this.Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
  }

  private prepareData(data: { country: string; value: number }[]) {
    const countriesData = data.map(country => {
      const countryCode = this.translateCountryNameToCountryCode(
        country.country
      );

      if (countryCode) {
        return [countryCode.toLowerCase(), country.value];
      }

      return null;
    });

    return [
      ...this.initialWorldMapData,
      ...countriesData.filter(country => country),
    ];
  }

  private translateCountryNameToCountryCode(countryName: string) {
    const country = countriesList.find(country => country.name === countryName);

    if (country) {
      return country.id;
    }

    return null;
  }
}
