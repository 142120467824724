// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-stint',
  templateUrl: './stint.component.html',
  styleUrls: ['./stint.component.scss'],
})
export class StintComponent implements OnInit {
  @Input() stint = {
    latest: '',
    past: [],
  };

  constructor() {}

  ngOnInit(): void {}
}
