// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export const spaceValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (control && control.value && !control.value.trim()) {
    return {
      spaceError: { value: `values can't be made up of spaces only` },
    };
  }

  return null;
};
