// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allProjectsCubeQuery = gql`
  query (
    $organizationId: String = ""
    $dateRange: String!
    $isAllOrgs: Boolean
  ) {
    getProjects(
      organizationId: $organizationId
      dateRange: $dateRange
      isAllOrgs: $isAllOrgs
    ) {
      projectId
      projectName
      foundationId
      projectType
      projectSlug
      membershipStatus
      commitCount
    }
  }
`;
