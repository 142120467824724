<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div
  *ngIf="!hidden"
  class="org-nesting-container"
  [ngbPopover]="popContent"
  placement="bottom-left"
  popoverClass="org-nesting-custom-popover-class"
>
  <i *ngIf="!loading" class="fal fa-sitemap"></i>
  <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
</div>

<ng-template #popContent let-row="row">
  <ng-container *ngIf="parentCompany && !loading">
    <div class="parent-item d-flex" (click)="setCompany(parentCompany)">
      {{ parentCompany.name }}
      <span
        class="lf-pills-container ml-2"
        *ngIf="currentCompany.id === parentCompany.id"
        ><span class="lf-pill-dark">Current</span></span
      >
    </div>
    <div
      class="nested-item d-flex"
      *ngFor="let child of parentCompany.children"
      (click)="currentCompany.id !== child.id && setCompany(child)"
    >
      {{ child.name }}
      <span
        class="lf-pills-container ml-2"
        *ngIf="currentCompany.id === child.id"
        ><span class="lf-pill-dark">Current</span></span
      >
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <i class="fa fa-spinner fa-spin org-nesting-spinner"></i>
  </ng-container>
</ng-template>
