// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MeetupInitiative } from '@models';

@Component({
  selector: 'lfx-crowdfunding-meetup-card',
  templateUrl: './crowdfunding-meetup-card.component.html',
  styleUrls: ['./crowdfunding-meetup-card.component.scss'],
})
export class CrowdfundingMeetupCardComponent implements OnInit {
  @Input() initiative: MeetupInitiative;
  @Output() meetupsDetailAction = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  gotoMeetupsDetail(id: string) {
    this.meetupsDetailAction.emit(id);
  }
}
