// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { CurrencyPipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DataGridVisualization } from '@models';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { DateFormatPipe } from '@lfx/shared/pipes/date-format.pipe';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-data-grid-widget',
  templateUrl: './data-grid-widget.component.html',
  styleUrls: ['./data-grid-widget.component.scss'],
})
export class DataGridWidgetComponent implements OnInit, OnChanges {
  filterString: string;
  @Input() visualizationData: DataGridVisualization;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  rows = [];
  ColumnMode = ColumnMode;
  columns: any[];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualizationData.currentValue) {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...changes.visualizationData.currentValue.data);

      this.columns = this.visualizationData.columns.map(i => {
        const pipe = new DateFormatPipe();

        switch (i.type) {
          case generalConstants.dataGridTypes.date:
            pipe.format = generalConstants.dateFormatForDateFormatPipe;
            i.pipe = pipe;
            break;
          case generalConstants.dataGridTypes.time:
            pipe.format = generalConstants.timeFormatForDateFormatPipe;
            i.pipe = pipe;
            break;
          case generalConstants.dataGridTypes.dateTime:
            pipe.format = generalConstants.dateTimeFormatForDateFormatPipe;
            i.pipe = pipe;
            break;
          case generalConstants.dataGridTypes.currency:
            i.pipe = new CurrencyPipe(generalConstants.defaultLocale);
            break;
        }

        return i;
      });
    }
  }

  updateList() {
    let data = this.visualizationData.data;

    if (this.filterString) {
      data = this.visualizationData.data.filter(item => {
        const keys = Object.keys(item);

        for (const key of keys) {
          if (
            item[key] &&
            (item[key].toString() as string)
              .toLowerCase()
              .includes(this.filterString.toLowerCase())
          ) {
            return true;
          }
        }

        return false;
      });
    }

    this.rows = data;
  }
}
