// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationEmployeeDetailsQuery = gql`
  query organizationEmployeeDetails(
    $organizationId: String!
    $userId: String!
    $isAllOrgs: Boolean
  ) {
    organizationEmployeeDetails(
      organizationId: $organizationId
      userId: $userId
      isAllOrgs: $isAllOrgs
    ) {
      memberId
      accountId
      memberDisplayName
      userPhotoUrl
      github
      linkedin
      twitter
      country
      memberEmail
      userStatus
      jobTitle
      userName
      isCurrentEmployer
      lfxUserId
      currentEmployer {
        organizationId
        organizationName
      }
    }
  }
`;
