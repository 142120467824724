// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { PageIds } from '@config';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, HostListener, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '@environment';
import { easyclaHelp, pageSettings } from '@config';
import {
  AuthService,
  UserService,
  LfxAcsUiService,
  FoundationService,
  CompanyService,
} from '@services';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { generalConstants } from '@config';
import { datadogRum } from '@datadog/browser-rum';
import { ProjectType } from '../models';
import { Intercom } from '@supy-io/ngx-intercom';

@Component({
  selector: 'lfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'lfx';
  pageSettings;
  pageHasScroll;
  helpUrl: string;
  currentRoute = '';
  isSidebarCollapsed = true;
  isAdmin$ = of(false);
  intercomeBooted = false;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public router: Router,
    public intercom: Intercom,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private LfxAcsUiService: LfxAcsUiService,
    private foundationService: FoundationService,
    private companyService: CompanyService
  ) {
    this.pageSettings = pageSettings;

    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.pageSettings.pageContentGreyBackground =
          event.snapshot.data.pageContentGreyBackground;
      }

      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
      }
    });

    if (environment.production && environment.gtmId) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };

          this.gtmService.pushTag(gtmTag);
        }
      });
    }

    this.setUserInLFxHeader();
    this.LfxAcsUiService.injectScripts();
  }

  ngOnInit() {
    this.userService.initializeFavourite();
    const script = document.createElement('script');

    script.setAttribute('src', environment.lfxHeaderUrl);
    document.head.appendChild(script);
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        switchMap(() => this.getRouteHelpPath())
      )
      .subscribe(url => this.setHelpPath(url));
    this.initLFXHeaderLinks();

    this.isAdmin$ = this.userService.isCompanyAdminOrLFStaffUser();
  }

  // set page right collapse
  onToggleSidebarRight(val: boolean): void {
    if (this.pageSettings.pageSidebarTwo) {
      this.pageSettings.pageSidebarTwo = false;
    } else {
      this.pageSettings.pageSidebarTwo = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  sidebarCollapsed(isSidebarCollapsed) {
    this.isSidebarCollapsed = isSidebarCollapsed;
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }

  // hide right mobile sidebar
  onHideMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }

  setUserInLFxHeader(): void {
    const lfHeaderElement: any = document.getElementById('lfx-header');

    if (!lfHeaderElement) {
      return;
    }

    lfHeaderElement.beforeLogout = () => {
      this.authService.clearSession();
    };

    if (this.authService.userProfile$) {
      this.authService.userProfile$.subscribe(data => {
        this.loadDataDogScript();

        if (data) {
          lfHeaderElement.authuser = data;

          datadogRum.setUser({
            id: data['https://sso.linuxfoundation.org/claims/username'],
          });

          if (!this.intercomeBooted) {
            this.intercomeBooted = true;
            this.intercom.boot({
              api_base: 'https://api-iam.intercom.io',
              app_id: environment.intercomId,
              user_hash:
                data['https://sso.linuxfoundation.org/claims/intercom'] ||
                data['sub'],
              user_id: data['https://sso.linuxfoundation.org/claims/username'],
              name: data['name'],
              email: data['email'],
            });
          }
        }
      });
    }

    this.companyService.conglomerateOrgIdChanged.subscribe(orgId => {
      if (orgId) {
        this.showSelectOrgDropdown();
      }
    });

    this.companyService.conglomerateOrgsLoaded.subscribe(isLoaded => {
      if (isLoaded && !this.companyService.showSelectOrgView) {
        this.showSelectOrgDropdown();
      } else if (!isLoaded) {
        lfHeaderElement.searchobj = null;
      }
    });
  }

  showSelectOrgDropdown() {
    const lfHeaderElement: any = document.getElementById('lfx-header');

    const dropDownOrgs = this.companyService.conglomerateOrgs.map(org => ({
      name: org.name,
      id: org.slug,
      orgId: org.id,
      icon: org.allOrgsIcon,
      slug: org.slug,
    }));

    lfHeaderElement.searchobj = {
      items: dropDownOrgs,
      hideSearchIcon: true,
      title: 'Currently Viewing',
      position: 'right', // right | left,
      value: {
        id: this.companyService.selectedConglomerateOrg?.slug,
      },
      selectItem: selectedOrg => this.selectOrg(selectedOrg),
    };
  }

  selectOrg(org) {
    org.id = org.orgId;
    this.companyService.selectedConglomerateOrg = org;
    this.router.navigate([`${org.slug}/home`]);
    setTimeout(() => {
      this.companyService.setCurrentCompanyId(org.id);
      this.companyService.setCurrentCompanyB2BId(org.id);
      this.companyService.setCurrentCompanySlug(org.slug);
      this.companyService.conglomerateOrgIdChanged.next(org.id);
    }, 200);
  }

  getCurrentCompanySlug() {
    return '/' + this.companyService.getCurrentCompanySlug();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top > 0) {
      this.pageHasScroll = true;
    } else {
      this.pageHasScroll = false;
    }
  }

  private setHelpPath(helpPath: string) {
    this.helpUrl = helpPath;
  }

  private getRouteHelpPath(): Observable<string> {
    let childRoute = this.route.root;

    while (childRoute.firstChild) {
      childRoute = childRoute.firstChild;
    }

    return childRoute.data.pipe(
      map(
        data =>
          easyclaHelp.easyClaHelpPaths[data.pageId] ||
          easyclaHelp.easyClaHelpPaths[PageIds.Faq]
      )
    );
  }

  private initLFXHeaderLinks() {
    const header: any = document.getElementById('lfx-header');

    if (header) {
      header.docslink = generalConstants.header.docslink;
      header.supportlink = generalConstants.header.supportLink;
    }
  }

  private loadDataDogScript() {
    if (!datadogRum.getInitConfiguration()) {
      datadogRum.init({
        applicationId: '84c11924-25b0-4977-a5de-c219eaee64fa',
        clientToken: 'pubae2606bdae56ae891089061d790ffda9',
        site: 'datadoghq.com',
        service: 'organization-dashboard-beta',
        env: environment.production ? 'prod' : 'dev',
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      });

      datadogRum.startSessionReplayRecording();
    }
  }
}
