<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div
  [class.overflow-style]="freezeHeader"
  [style.max-height]="freezeHeader ? rowsMaxHeight : 'none'"
  *ngIf="version === 1"
>
  <table class="table" [class.table-striped]="striped">
    <thead [class.sticky-header]="freezeHeader">
      <tr>
        <th
          *ngFor="let column of columns"
          scope="col"
          class="table-head-styles"
        >
          <div class="header-with-icon" [style]="column.style">
            {{ column.name }}
            <ng-container *ngIf="column.sortable && column.sortDir">
              <i
                *ngIf="column.sortDir === 'asc'"
                (click)="column.sortDir = toggleSortOrder(column)"
                class="fas fa-sort-up align-self-end"
              ></i>
              <i
                *ngIf="column.sortDir === 'desc'"
                (click)="column.sortDir = toggleSortOrder(column)"
                class="fas fa-sort-down align-self-top"
              ></i>
            </ng-container>
            <div
              *ngIf="column.headerIcon"
              [ngbPopover]="column.infoText && column.infoText"
              triggers="mouseenter:mouseleave"
              popoverClass="tooltip-popover"
              placement="bottom"
            >
              <ng-container
                *ngTemplateOutlet="column.headerIconTemplate"
              ></ng-container>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let rowData of data; let index = index"
        class="table-row-styles"
      >
        <td *ngFor="let column of columns" class="table-cell-styles">
          <div class="cell-container" [style]="column.style">
            <ng-container *ngIf="column.template">
              <ng-container
                *ngTemplateOutlet="column.template; context: { row: rowData }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="!column.template">
              <div
                [class.lf-link]="column.clickableText"
                [class.italic-text]="rowData[column.prop].italic"
                [style]="column.dataStyle"
                (click)="
                  column.clickableText &&
                    handleColumnTextClick({ row: rowData, column })
                "
              >
                {{ rowData[column.prop].text }}
              </div>
              <div
                *ngIf="rowData[column.prop].subtext"
                class="subtext"
                [style]="rowData[column.prop].subtextStyle"
              >
                {{ rowData[column.prop].subtext }}
              </div>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  [class.overflow-style]="freezeHeader"
  [style.max-height]="freezeHeader ? rowsMaxHeight : 'none'"
  *ngIf="version === 2"
  class="version-2"
>
  <table
    class="table"
    [class.table-striped]="striped"
    [class.selectable]="selectable"
  >
    <thead [class.sticky-header]="freezeHeader">
      <tr>
        <th
          *ngFor="let column of columns"
          scope="col"
          class="table-head-styles"
        >
          <div [style]="column.style">
            <div>
              {{ column.name }}
              <ng-container
                *ngIf="(column.sortable && column.sortDir) || column.infoIcon"
              >
                <i
                  *ngIf="column.sortDir === 'asc'"
                  (click)="column.sortDir = toggleSortOrder(column)"
                  class="far fa-arrow-up cursor-pointer"
                ></i>
                <i
                  *ngIf="column.sortDir === 'desc'"
                  (click)="column.sortDir = toggleSortOrder(column)"
                  class="far fa-arrow-down cursor-pointer"
                ></i>
                <i
                  *ngIf="column.infoIcon && column.infoIcon.action === 'click'"
                  class="fal fa-info-circle cursor-pointer clickable-icon"
                  (click)="infoIconClickEmitter.emit(column)"
                ></i>
                <i
                  *ngIf="column.infoIcon && column.infoIcon.action === 'hover'"
                  class="fal fa-info-circle lf-grey-500"
                  [ngbPopover]="column.infoIcon.hoverText"
                  triggers="mouseenter:mouseleave"
                  popoverClass="tooltip-popover"
                ></i>
              </ng-container>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="showGroupedRows">
        <ng-container *ngFor="let group of groupedData">
          <div (click)="group.expanded = !group.expanded" class="group-styles">
            <i *ngIf="group.expanded" class="fas fa-caret-down caret-styles"></i>
            <i *ngIf="!group.expanded" class="fas fa-caret-right caret-styles"></i>
            <span class="grouped-rows-label" [class.grouped-rows-label-expanded]="group.expanded">{{ group.label }} ({{ group.data?.length }})</span>
          </div>
          <ng-container *ngIf="group.expanded">
            <tr
              *ngFor="let rowData of group.data; let index = index"
              class="table-row-styles-2"
              (click)="handleRowClick(rowData, index)"
              [class.selected-row]="selectedIndex === index"
            >
              <td
                *ngFor="let column of columns; let first = first"
                class="table-cell-styles"
              >
                <div class="cell-container" [style]="column.style">
                  <ng-container *ngIf="column.template">
                    <ng-container
                      *ngTemplateOutlet="
                        column.template;
                        context: { row: rowData, column: column }
                      "
                    ></ng-container>
                  </ng-container>
                  <ng-container *ngIf="!column.template">
                    <div
                      [class.first]="first"
                      [class.lf-link-v2]="column.clickableText"
                      [class.italic-text]="rowData[column.prop].italic"
                      [style]="column.dataStyle"
                      (click)="
                        column.clickableText &&
                          handleColumnTextClick({ row: rowData, column })
                      "
                    >
                      <ng-container
                        *ngIf="column.linkableText && rowData[column.prop].url"
                      >
                        <a
                          attr.href="{{ rowData[column.prop].url | urlFormat }}"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="lf-link-v2"
                        >
                          <span>{{ rowData[column.prop].text }}</span>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="!column.linkableText">
                        {{ rowData[column.prop].text }}
                      </ng-container>
                    </div>
                    <div
                      *ngIf="rowData[column.prop].subtext"
                      class="subtext"
                      [style]="rowData[column.prop].subtextStyle"
                    >
                      {{ rowData[column.prop].subtext }}
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!showGroupedRows">
        <ng-container *ngIf="data && data.length">
          <tr
            *ngFor="let rowData of data; let index = index"
            class="table-row-styles-2"
            (click)="handleRowClick(rowData, index)"
            [class.selected-row]="selectedIndex === index"
          >
            <td
              *ngFor="let column of columns; let first = first"
              class="table-cell-styles"
            >
              <div class="cell-container" [style]="column.style">
                <ng-container *ngIf="column.template">
                  <ng-container
                    *ngTemplateOutlet="
                      column.template;
                      context: { row: rowData, column: column }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngIf="!column.template">
                  <div
                    [class.first]="first"
                    [class.lf-link-v2]="column.clickableText"
                    [class.italic-text]="rowData[column.prop].italic"
                    [style]="column.dataStyle"
                    (click)="
                      column.clickableText &&
                        handleColumnTextClick({ row: rowData, column })
                    "
                    [attr.title]="column.textEllipsis ? rowData[column.prop].text : null"
                  >
                    <ng-container
                      *ngIf="column.linkableText && rowData[column.prop].url"
                    >
                      <a
                        attr.href="{{ rowData[column.prop].url | urlFormat }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="lf-link-v2"
                      >
                        <span>{{ column.textEllipsis ? (rowData[column.prop].text | ellipsis: column.textEllipsis) : rowData[column.prop].text }}</span>
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!column.linkableText">
                      {{ column.textEllipsis ? (rowData[column.prop].text | ellipsis: column.textEllipsis) : rowData[column.prop].text }}
                    </ng-container>
                  </div>
                  <div
                    *ngIf="rowData[column.prop].subtext"
                    class="subtext"
                    [style]="rowData[column.prop].subtextStyle"
                  >
                    {{ rowData[column.prop].subtext }}
                  </div>
                </ng-container>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="(!data || !data.length) && emptyDataMessage">
          <tr>
            <td [attr.colspan]="columns.length" class="lf-empty-data-message">
              {{ emptyDataMessage }}
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <ng-content select="[showMore]"></ng-content>
</div>

<div *ngIf="version === 3" class="version-3">
  <virtual-scroller
    #scroll
    [items]="data"
    [style.height]="freezeHeader ? rowsMaxHeight : 'none'"
  >
    <table
      class="table"
      [class.table-striped]="striped"
      [class.selectable]="selectable"
    >
      <thead [class.sticky-header-v3]="freezeHeader" #header>
        <tr>
          <th
            *ngFor="let column of columns"
            scope="col"
            class="table-head-styles"
          >
            <div [style]="column.style">
              <div>
                {{ column.name }}
                <ng-container
                  *ngIf="(column.sortable && column.sortDir) || column.infoText"
                >
                  <i
                    *ngIf="column.sortDir === 'asc'"
                    (click)="column.sortDir = toggleSortOrder(column)"
                    class="far fa-arrow-up cursor-pointer"
                  ></i>
                  <i
                    *ngIf="column.sortDir === 'desc'"
                    (click)="column.sortDir = toggleSortOrder(column)"
                    class="far fa-arrow-down cursor-pointer"
                  ></i>
                  <i
                    *ngIf="column.infoText"
                    class="fal fa-info-circle cursor-pointer clickable-icon"
                    (click)="infoIconClickEmitter.emit(column)"
                  ></i>
                </ng-container>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody #container>
        <tr
          *ngFor="let rowData of scroll.viewPortItems; let index = index"
          class="table-row-styles-2"
          (click)="handleRowClick(rowData, index)"
          [class.selected-row]="selectedIndex === index"
        >
          <td
            *ngFor="let column of columns; let first = first"
            class="table-cell-styles"
          >
            <div class="cell-container" [style]="column.style">
              <ng-container *ngIf="column.template">
                <ng-container
                  *ngTemplateOutlet="column.template; context: { row: rowData }"
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="!column.template">
                <div
                  [class.first]="first"
                  [class.lf-link-v2]="
                    column.clickableText || rowData[column.prop].clickableText
                  "
                  [class.italic-text]="rowData[column.prop].italic"
                  [style]="column.dataStyle"
                  (click)="
                    (column.clickableText ||
                      rowData[column.prop].clickableText) &&
                      handleColumnTextClick({ row: rowData, column })
                  "
                >
                  <ng-container
                    *ngIf="column.linkableText && rowData[column.prop].url"
                  >
                    <a
                      attr.href="{{ rowData[column.prop].url | urlFormat }}"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="lf-link-v2"
                    >
                      <span>{{ rowData[column.prop].text }}</span>
                    </a>
                  </ng-container>
                  <ng-container
                    *ngIf="!column.linkableText || !rowData[column.prop].url"
                  >
                    <div [style]="rowData[column.prop].textStyle">
                      {{ rowData[column.prop].text }}
                    </div>
                  </ng-container>
                </div>
                <div
                  *ngIf="rowData[column.prop].subtext"
                  class="subtext"
                  [style]="rowData[column.prop].subtextStyle"
                >
                  {{ rowData[column.prop].subtext }}
                </div>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-content select="[showMore]"></ng-content>
  </virtual-scroller>
</div>
