<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="table-grid">
  <div class="table-grid-headers">
    <div
      [hidden]="!showHeader"
      *ngFor="let column of columns"
      class="table-grid-header {{ column.flex >= 0 && 'flex-' + column.flex }}"
      [style.flex-basis]="column.flexBasis"
    >
      {{ column.name }}
      <lfx-filter-dropdown
        (filterAction)="filterAction.emit({ column: column, filter: $event })"
        *ngIf="column.filterData"
        [title]="column.filterData.title"
        [filterData]="column.filterData.data"
        [hideFilterData]="column.hideFilterData"
      ></lfx-filter-dropdown>
    </div>
    <div *ngIf="canEdit || canDelete" class="table-grid-header actions-cell">
      {{ defaultActionsTitle }}
    </div>
  </div>
  <div class="items-wrapper scroll" [style.max-height]="maxHeight || 'auto'">
    <div
      *ngIf="!data.length"
      class="table-row height-70 f-s-14 f-w-400 justify-content-center"
    >
      {{ emptyDataMessage }}
    </div>
    <ng-container *ngFor="let item of data; let index = index">
      <div
        class="{{ columns.length >= 2 ? 'table-row' : 'table-child-row table-single-child-row' }}"
        [style.min-height]="minHeight || 'auto'"
        [style]="activeRowDataIndex === index && onRowClickStyle ?  onRowClickStyle : {}"
        [style.cursor]="onRowClickStyle ? 'pointer' : 'auto'"
        (click)="handleRowClick(item, index)"
      >
        <div
          *ngFor="let column of columns"
          [style.flex-basis]="column.flexBasis"
          class="{{ columns.length >= 2 ? 'table-cell' : 'child-cell' }} {{
            column.flex >= 0 && 'flex-' + column.flex
          }}"
          [style.min-height]="minHeightRow || 'auto'"
        >
          <ng-container *ngIf="column.template">
            <ng-container
              *ngTemplateOutlet="column.template; context: { row: item }"
            ></ng-container>
          </ng-container>
          <div
            *ngIf="!column.template"
            class="d-flex align-items-center no-gutters"
          >
            <div
              class="col-auto"
              *ngIf="
                getType(item[column.prop]) === 'object' &&
                item[column.prop].hasOwnProperty('image')
              "
            >
              <span
                class="pull-left mr-2"
                [class.img-border]="item[column.prop].border"
              >
              <lfx-avatar class="cell-image" [class.only-image]="column.onlyImage"
                [imageURL]="item[column.prop].image"
                [name]="item[column.prop].text"
              ></lfx-avatar>
              </span>
            </div>
            <span
              class="expand-action"
              *ngIf="column.expandAction && item.children?.length"
              (click)="toggleChildren(item)"
              >

              <i
                class="fal fa-plus-square fa-lg icon-custom-style"
                *ngIf="!item.expanded"
              ></i>
              <i
                class="fal fa-minus-square fa-lg icon-custom-style"
                *ngIf="item.expanded"
              ></i>
            </span
            >
            <div class="col">
              <span
                data-container="body"
                *ngIf="
                  getType(item[column.prop]) === 'string' ||
                  getType(item[column.prop]) === 'number'
                "
                class="text-large"
                [class.text-grey-600]="grayColor"
                [ngbTooltip]="tooltip(item[column.prop], column.textEllipsis)"
                [placement]="'right bottom'"
                tooltipClass="table-tooltip-popupover"
                [style.font-size]="column.dataFontSize || ''"
                [class.text-bold]="column.dataBold"
                [class.lf-link]="column.clickableText || item.clickableText"
                (click)="(column.clickableText || item.clickableText) && handleColumnTextClick(item)"
                >
                <ng-container *ngIf="column.textEllipsis">
                  {{
                    column.titlecase
                      ? (item[column.prop] | ellipsis: column.textEllipsis | titlecase)
                      : (item[column.prop] | ellipsis: column.textEllipsis)
                  }}
                </ng-container>
                <ng-container *ngIf="!column.textEllipsis">
                  {{
                    column.titlecase
                      ? (item[column.prop] | titlecase)
                     : item[column.prop]
                  }}
                </ng-container>
              </span>
              <span
                data-container="body"
                *ngIf="
                  getType(item[column.prop]) === 'object' &&
                  item[column.prop].text
                "
                class="text-large {{
                  item[column.prop]?.color &&
                    'lfx-text-' + item[column.prop].color
                }}"
                [class.text-bold]="item[column.prop]?.bold || column.dataBold"
                [class.text-grey-600]="!item[column.prop].subtext && grayColor"
                [style.font-size]="column.dataFontSize || ''"
                [ngbTooltip]="
                  tooltip(item[column.prop].text, column.textEllipsis)
                "
                [placement]="'right bottom'"
                tooltipClass="table-tooltip-popupover"
                [class.lf-link]="column.clickableText || item.clickableText"
                (click)="(column.clickableText || item.clickableText) && handleColumnTextClick(item)"
              >
              <ng-container *ngIf="column.textEllipsis">
                {{
                  column.titlecase
                    ? (item[column.prop].text | ellipsis: column.textEllipsis | titlecase)
                    : (item[column.prop].text | ellipsis: column.textEllipsis)
                }}
              </ng-container>
              <ng-container *ngIf="!column.textEllipsis">
                {{
                  column.titlecase
                    ? (item[column.prop].text | titlecase)
                   : item[column.prop].text
                }}
              </ng-container>
                <ng-template #popBody>
                  <i class="fas fa-info-circle"></i>
                  <span>{{ item[column.prop].tooltipText }}</span>
                </ng-template>
                <i
                  *ngIf="item[column.prop].tooltipText"
                  class="fas fa-info-circle {{
                    !item[column.prop].subtext && grayColor && 'text-grey-600'
                  }}"
                  popoverClass="popover-custom {{
                    !item[column.prop].subtext && grayColor && 'text-grey-600'
                  }}"
                  [placement]="'right bottom'"
                  triggers="mouseenter:mouseleave"
                  [ngbPopover]="popBody"
                ></i>
              </span>
              <span
                data-container="body"
                *ngIf="
                  getType(item[column.prop]) === 'object' &&
                  item[column.prop].subtext
                "
                class="text-small text-grey-600"
                [ngbTooltip]="
                  tooltip(item[column.prop].subtext, column.subTextEllipsis)
                "
                [placement]="'right bottom'"
                tooltipClass="table-tooltip-popupover"
                >{{
                  column.subTextEllipsis
                    ? (item[column.prop].subtext
                      | ellipsis: column.subTextEllipsis)
                    : item[column.prop].subtext
                }}</span
              >
              <span
                *ngIf="
                  getType(item[column.prop]) === 'object' &&
                  item[column.prop].callToAction
                "
                class="text-small call-to-action-wrapper"
                >{{ item[column.prop].callToAction?.prefixText }}
                <span
                  class="call-to-action"
                  (click)="customAction(column, item)"
                  >{{ item[column.prop].callToAction?.actionText }}</span
                ></span
              >
              <span
                data-container="body"
                *ngIf="
                  getType(item[column.prop]) === 'object' &&
                  item[column.prop].extraText
                "
                class="text-small"
                [class.lfx-text-warning]="
                  item[column.prop].extraText.style === 'warning'
                "
                [class.text-bold]="item[column.prop].extraText.bold"
                [class.text-grey-600]="
                  item[column.prop].extraText.style === 'gray'
                "
                [ngbTooltip]="
                  tooltip(
                    item[column.prop].extraText.text,
                    column.subTextEllipsis
                  )
                "
                tooltipClass="table-tooltip-popupover"
                >{{
                  column.subTextEllipsis
                    ? (item[column.prop].extraText.text
                      | ellipsis: column.subTextEllipsis)
                    : item[column.prop].extraText.text
                }}</span
              >
            </div>
          </div>
        </div>
        <div *ngIf="canEdit || canDelete" class="table-cell actions-cell">
          <i
            *ngIf="canEdit && !item.manageDisabled"
            class="action-button action-button-edit fas fa-pencil"
            (click)="edit(item, null)"
          ></i>
          <i
            *ngIf="canDelete && !item.manageDisabled"
            class="action-button action-button-delete fas fa-trash"
            (click)="delete(item, null)"
          ></i>
        </div>
      </div>
      <div
        class="children-wrapper"
        [style]="childrenWidth()"
        *ngIf="item.children && item.expanded"
      >
        <div>
          <div *ngFor="let child of item.children" class="table-child-row">
            <!-- <section class='child-cell-empty'></section> -->
            <ng-container *ngFor="let column of columns">
              <div *ngIf="!column.hideInChild" class="child-cell d-flex align-items-center no-gutters column-gap-5">
                <span
                  *ngIf="
                    getType(child[column.prop]) === 'string' ||
                    getType(child[column.prop]) === 'number'
                  "
                  class="text-large"
                  [class.text-grey-600]="grayColor"
                  >{{ child[column.prop] }}</span
                >
                <img
                  [lfxImageErrorFallback]="child[column.prop].text"
                  class="cell-image"
                  [class.only-image]="column.onlyImage"
                  *ngIf="
                    getType(child[column.prop]) === 'object' &&
                    child[column.prop].image
                  "
                  src="{{ child[column.prop].image }}"
                />
                <span
                  *ngIf="
                    getType(child[column.prop]) === 'object' &&
                    child[column.prop].text
                  "
                  [class.text-bold]="child[column.prop]?.bold || column.dataBold"
                  [style.font-size]="column.dataFontSize || ''"
                  >{{ child[column.prop].text }}</span
                >
                <ng-container
                  *ngIf="
                    getType(child[column.prop]) === 'object' &&
                    child[column.prop].elementRef
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="child[column.prop]; context: { row: item }"
                  ></ng-container>
                </ng-container>
              </div>
            </ng-container>
            <div *ngIf="canEdit || canDelete" class="child-cell actions-cell">
              <i
                *ngIf="canEdit && !child.manageDisabled"
                class="action-button action-button-edit fas fa-pencil"
                (click)="edit(child, item)"
              ></i>
              <i
                *ngIf="canDelete && !child.manageDisabled"
                class="action-button action-button-delete fas fa-trash"
                (click)="delete(child, item)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
