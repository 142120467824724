// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import * as am5 from '@amcharts/amcharts5';
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';
import * as am5xy from '@amcharts/amcharts5/xy';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  GranularityEnum,
  LineChartConfig,
  LineChartDataRow,
  LineSeriesSettings,
  createChart,
  createChartRoot,
  createLineSeries,
  createXAxis,
  createYAxis,
} from '../am-chart-util';

@Component({
  selector: 'lfx-am-line-chart',
  templateUrl: './am-line-chart.component.html',
  styleUrls: ['./am-line-chart.component.scss'],
})
export class AMLineChartComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input() public config!: LineChartConfig;
  /**
   * Usage chartName
   * In the case of chartName , this is using the filters to get the data by default and by defined the chartName globally
   * packages/lfx-insights/interfaces/charts.d.ts
   * apps/frontend/src/app/shared/services/chart.service.ts
   */
  @Input() public chartName!: string;
  @Input() public data: LineChartDataRow[] = []; // TODO: change this to a standardized data structure]

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;

  public constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data) {
      this.changeData();
    }

    if (changes) {
      this.initChart();
    }
  }

  public ngAfterViewInit() {
    this.initChart();
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    if (this.root) {
      this.root.dispose();
    }
  }

  public checkEmptyData(): boolean {
    const check = this.chartRef.series.values.every(se => {
      const valueField = se.get('valueYField');

      return se.data.values.every((e: any) => !e[valueField]);
    });

    return check;
  }

  public initChart() {
    if (!document.getElementById(this.chartName)) {
      // cannot found chart render div
      return;
    }

    if (this.root) {
      this.root.dispose();
    }
    am5.addLicense('AM5C454672478');
    const root = createChartRoot(this.chartName, this.config.useMicroTheme);
    const chart = createChart(root);
    const yAxis = createYAxis(
      root,
      chart,
      {
        max: this.config.max,
        label: this.config.hideYAxis ? undefined : this.config.yAxisName,
      },
      this.config.hideYAxis,
      false,
      this.config.hideYAxisLabelsOnly,
      this.config
    );

    if (this.config.useMicroTheme) {
      chart.plotContainer.set('wheelable', false);
      chart.zoomOutButton.set('forceHidden', true);
    }
    const granularity = this.getGranularity();

    const xAxis = createXAxis(root, chart, granularity, this.config.xAxis);

    if (this.config.xAxis.axisType === 'category') {
      xAxis.data.setAll(this.data);
    }

    if (this.config.xAxis.showRangeLabelsOnly) {
      const categoryAxis = xAxis as am5xy.CategoryAxis<am5xy.AxisRenderer>;

      categoryAxis
        .get('renderer')
        .labels.template.adapters.add('html', (html, target) => {
          const { isRange = false } = target.dataItem
            ? (target.dataItem._settings as any)
            : {};

          if (!isRange) {
            return '<span></span>';
          }

          return html;
        });
    }

    // Create series
    this.config.series.forEach((seriesSetting: LineSeriesSettings) => {
      createLineSeries(this)(
        root,
        chart,
        xAxis,
        yAxis,
        seriesSetting,
        granularity
      );
    });

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {}));

    if (this.config.hideCursor) {
      const cursor = chart.get('cursor');

      cursor.lineX.setAll({
        visible: false,
      });

      cursor.lineY.setAll({
        visible: false,
      });
    }
    this.chartRef = chart;

    this.root = root;

    this.config.xAxis.ranges?.forEach(range => {
      this.makeRange(
        range.start,
        range.end,
        range.label,
        xAxis as am5xy.CategoryAxis<am5xy.AxisRenderer>
      );
    });
  }

  public onToggleSeries(index: number): void {
    if (this.chartRef) {
      const series = this.chartRef.series.getIndex(index);

      if (series) {
        this.toggleSeries(series);
      }
    }
  }

  public isVisible(index: number): boolean | undefined {
    if (this.chartRef) {
      const series = this.chartRef.series.getIndex(index);

      if (series) {
        return series.get('visible');
      }

      return true;
    }

    return true;
  }

  private toggleSeries(series: am5xy.XYSeries) {
    if (series.get('visible')) {
      series.hide();
    } else {
      series.show();
    }
  }

  private changeData(): void {
    if (this.chartRef) {
      const granularity = this.getGranularity();

      this.chartRef.xAxes.each((axis: am5xy.Axis<am5xy.AxisRenderer>) => {
        if (this.config.xAxis?.axisType !== 'category') {
          (axis as am5xy.DateAxis<am5xy.AxisRenderer>).set('baseInterval', {
            timeUnit: granularity as TimeUnit,
            count: 1,
          });
        }
        axis.data.setAll([]);
        axis.data.setAll(this.data);
      });

      this.chartRef.series.each((se: am5xy.XYSeries) => {
        se.data.setAll([]);
        se.data.setAll(this.data);
      });
    }
  }

  private getGranularity() {
    let granularity = GranularityEnum.month;

    if (this.config.xAxis?.granularity) {
      granularity = this.config.xAxis?.granularity;
    }

    return granularity;
  }

  private makeRange(
    start,
    end,
    label,
    xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>
  ) {
    const rangeDataItem = xAxis.makeDataItem({
      category: start,
      endCategory: end,
      isRange: true,
    });

    xAxis.createAxisRange(rangeDataItem);

    rangeDataItem.get('label').setAll({
      text: label,
      dy: 10,
    });
  }
}
