// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface GetContributionInput {
  withContribution?: boolean;
  withContributionMonthly?: boolean;
  withContributionPerProject?: boolean;
  withContributionPerProjectMonthly?: boolean;
  withContributionPerProjectStats?: boolean;
  withContributionPerEmployee?: boolean;
  withContributionStats?: boolean;
  projectId?: string;
  organizationId?: string;
  contributionFilters?: {
    Jfilter?: string;
    year?: string;
    pageSize?: number;
  };
  contributionStatsFilters?: {
    Jfilter?: string;
    year?: string;
    pageSize?: number;
  };
  contributionMonthlyFilters?: {
    Jfilter?: string;
    year?: string;
    projectSlug?: string;
  };
  contributionPerProjectFilters?: {
    Jfilter?: string;
    year?: string;
    pageSize?: number;
  };
  contributionPerProjectMonthlyFilters?: {
    Jfilter?: string;
    year?: string;
  };
  contributionPerProjectStatsFilters?: {
    Jfilter?: string;
    year?: string;
  };
  contributionPerEmployeeFilters?: {
    year?: string;
    Jfilter?: string;
  };
}
