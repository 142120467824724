// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Event } from './event';

export interface SponsoredEvent extends Event {
  level: SponsorLevel;
}

export enum SponsorLevel {
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
}
