<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div *ngIf="usage > -1" class="benefits-container" (click)="benefitsClicked.emit()">
  <div class="green-bar" *ngIf="usage < 100">
    <div
      class="gray-bar"
      [ngStyle]="{ width: usage + '%' }"
    ></div>
  </div>
  <div class="benefits-text" *ngIf="usage < 100">
    {{ 100 - usage }}% of benefits available
  </div>
  <div class="used-benefits-text" *ngIf="usage === 100">
    <i class="fas fa-check-circle fa-lg green-color"></i>
    All Benefits Claimed
  </div>
</div>
<div *ngIf="usage === -1" class="benefits-container">
  <div class="no-benefits-text">
    Benefits not published to LFX
  </div>
</div>
