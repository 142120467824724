// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCCertifiedEmployeesLeaderboardCountQuery = gql`
  query tnCCertifiedEmployeesLeaderboardCount(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
  ) {
    getTnCCertifiedEmployeesLeaderboardCount(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
    ) {
      count
    }
  }
`;
