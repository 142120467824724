<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div *ngIf="version === 1" class="lf-box">
  <span
    *ngIf="sectionTitle"
    class="lf-box-title"
    [placement]="'right bottom'"
    tooltipClass="lf-box-tooltip"
    [ngbTooltip]="tooltipText"
    >{{ sectionTitle }}</span
  >
  <p *ngIf="subtitle" class="lf-box-subtitle">
    {{ subtitle }}
  </p>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <span *ngIf="linkText" class="lf-link" (click)="linkTextClickEmitter.emit()"
    >{{ linkText }} <i class="fal fa-angle-double-right"></i
  ></span>
  <ngx-spinner
    *ngIf="isLoading"
    type="ball-clip-rotate"
    [fullScreen]="false"
    [name]="getSpinnerName()"
    color="rgba(0,0,0,0.5)"
    bdColor="rgba(255,255,255,0.5)"
    size="default"
  ></ngx-spinner>
</div>


<div *ngIf="version === 2" class="lf-box-2" [class.grey-bg]="greyBackground">
  <div class="top d-flex justify-content-between align-items-center">
    <div
    *ngIf="sectionTitle"
    class="lf-box-title"
    [placement]="'right bottom'"
    tooltipClass="lf-box-tooltip"
    [ngbTooltip]="tooltipText"
    >{{ sectionTitle }}</div
  >
  </div>
  <p *ngIf="subtitle" class="lf-box-subtitle">
    {{ subtitle }}
  </p>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ngx-spinner
    *ngIf="isLoading"
    type="ball-clip-rotate"
    [fullScreen]="false"
    [name]="getSpinnerName()"
    color="rgba(0,0,0,0.5)"
    bdColor="rgba(255,255,255,0.5)"
    size="default"
  ></ngx-spinner>
</div>


<div *ngIf="version === 3" class="lf-box-2" [class.grey-bg]="greyBackground">
  <div class="top d-flex justify-content-between align-items-center">
    <div
    *ngIf="sectionTitle"
    class="lf-box-title"
    [placement]="'right bottom'"
    tooltipClass="lf-box-tooltip"
    [ngbTooltip]="tooltipText"
    >{{ sectionTitle }}</div
  >
  <lfx-external-link [text]="externalLink.text" [url]="externalLink.url"></lfx-external-link>
  </div>
  <p *ngIf="subtitle" class="lf-box-subtitle">
    {{ subtitle }}
  </p>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ngx-spinner
    *ngIf="isLoading"
    type="ball-clip-rotate"
    [fullScreen]="false"
    [name]="getSpinnerName()"
    color="rgba(0,0,0,0.5)"
    bdColor="rgba(255,255,255,0.5)"
    size="default"
  ></ngx-spinner>
</div>

<div *ngIf="version === 4" class="lf-box-2" [class.grey-bg]="greyBackground">
  <div class="top d-flex justify-content-between align-items-center">
    <div
    *ngIf="sectionTitle"
    class="lf-box-title"
    [placement]="'right bottom'"
    tooltipClass="lf-box-tooltip"
    [ngbTooltip]="tooltipText"
    >{{ sectionTitle }}</div
  >
  <ng-content select="[topRight]"></ng-content>
  </div>
  <p *ngIf="subtitle" class="lf-box-subtitle">
    {{ subtitle }}
  </p>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ngx-spinner
    *ngIf="isLoading"
    type="ball-clip-rotate"
    [fullScreen]="false"
    [name]="getSpinnerName()"
    color="rgba(0,0,0,0.5)"
    bdColor="rgba(255,255,255,0.5)"
    size="default"
  ></ngx-spinner>
</div>


<ng-template #content>
  <ng-content></ng-content>
</ng-template>