// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UserGroup } from '@models';
import { MutationPayload } from './mutation-payload';

export interface CreateFoundationCommitteesResult {
  createCommittees: CreateCommitteesPayload;
}

export interface CreateCommitteesPayload extends MutationPayload {
  committees: UserGroup[];
}
