// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface UpdateProjectEssentialsInput {
  id: string;
  name: string;
  description: string;
  status: string;
  slug: string;
  colorBand?: string;
  projectSector: string;
  ProjectLogo: string;
  ownerId?: string;
  programManagerId?: string;
  opportunityOwnerId?: string;
}
