// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { SocialAuthProviders } from '@lfx/config';

export interface User {
  id: string;
  auth0Id: string;
  type: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  timezone: string;
  emails: Email[];
  username: string;
  modifiedDate: string;
  createdDate: string;
  permissions: UserPermission[];
  socialAccounts?: Identity[];
  roles: [];
  primaryEmail?: string;
  phone?: string;
  title?: string;
  address?: {
    city?: string;
    country?: string;
    postalCode?: number;
    state?: string;
    street?: string;
  };
  githubId: string;
  gender: string;
  account?: {
    id?: string;
    logoUrl?: string;
    name?: string;
    website?: string;
  };
}

interface Identity {
  userId: string;
  provider: SocialAuthProviders;
  connection: string;
  isSocial: boolean;
}

interface UserPermission {
  context: string;
  actions: string[];
  allowed: boolean;
  resource: string;
  scopes: UserScope[];
}

interface UserScope {
  id: string[];
  role: string;
  type: string;
}

interface Email {
  emailAddress: string;
  emailType: string;
  active: boolean;
  isDeleted: boolean;
  isPrimary: boolean;
  isVerified?: boolean;
}
