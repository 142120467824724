<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="lfx-full-card" [ngClass]="{ 'overlay' : showOverlay }">
  <div class="card bg-white rounded">
    <ng-content select="[lfx-membership-level]"></ng-content>
    <div class="ie-fix-flex">
      <div *ngIf="typeIcon" [ngClass]="typeIcon ? 'top-icon' : '' ">
        <i [ngClass]="
        (typeIcon.toString() === 'Bug Bounty') ? 'fas fa-bug f-s-20':
        (typeIcon.toString() === 'Travel Scholarship') ? 'fas fa-plane f-s-20':
        (typeIcon.toString() === 'Meetup')? 'far fa-calendar-alt f-s-20':
        (typeIcon.toString() === 'Mentorship') ? 'fas fa-code f-s-20':
        (typeIcon.toString() === 'Funding') ? 'fas fa-user f-s-20' : '' ">
        </i>
      </div>
      <div class="height-90 logo-container" [ngStyle]="{ 'background': color ? color: defaultColor  }">
        <a class="logo-holder pointer-cursor" (click)="triggerAction()">
          <img *ngIf="logo else icon" (error)="onLogoError()" class="img-thumbnail foundation-logo" [src]="logo" alt="Image" />
          <ng-template #icon><i class="icon img-thumbnail foundation-logo {{ defaultIcon ? defaultIcon : '' }}"></i>
          </ng-template>
        </a>
      </div>
    </div>
    <div class="card-body text-center">
      <h4 class="m-0 foundation-title">
        <a href="javascript:;" (click)="triggerAction()">{{ title }}</a>
      </h4>
      <div  class="text-justify mt-1 foundation-details"
        [ngbTooltip]="details?.length > 200 ? detailsToolTip : ''" container="body">
        <div class="foundation-details-text">
          {{ (details  || noDescriptionText) | truncate:200 }}
        </div>
      </div>
    </div>
    <div class="card-body text-left lfx-full-card-tags pt-0 pb-1">
      <div class="row">
        <div class="col-md-6 col-lg-6 text-left">
          <span *ngIf="tag" class="badge badge-tag badge-pill px-4 py-1 font-weight-normal">
            {{ tag }}
          </span>
          <span *ngIf="status"
            [ngClass]="['badge', 'badge-pill', 'pr-4', 'pl-4', 'pt-2', 'pb-2', ( status === 'pending' ) ? 'bg-warning' : 'bg-success']">
            {{ status }}
          </span>
        </div>
        <div class="col-md-6 col-lg-6 text-left">
          <ng-content select="lfx-badge"></ng-content>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ng-content select="[body]"></ng-content>
      <ng-content select="lfx-dev-analytics-summary"></ng-content>
      <ng-content select="[lfx-easycla-summary]"></ng-content>
      <ng-content select="lfx-security-vulnerabilities"></ng-content>
      <ng-content select="lfx-vulnerability-summary"></ng-content>
      <ng-content select="[lfx-insights]"></ng-content>
      <ng-content select="lfx-toggle-pills"></ng-content>
      <ng-content select="[lfx-no-toogle-pills]"></ng-content>
      <ng-content select="[lfx-crowdfunding-bodyView]"></ng-content>
    </div>

    <div class="card-footer row flex-nowrap align-items-end justify-content-between" #cardFooter [hidden]="!showFooter">
      <div class="col-auto p-0">
        <ng-content select=[lfx-members]></ng-content>
      </div>
      <div class="col-auto p-0">
        <ng-content select="[lfx-cta-buttons]" class="ml-auto"></ng-content>
      </div>
    </div>

    <!-- end to end footer-->
    <ng-content class="chart" select="lfx-dev-analytics-line-chart"></ng-content>
    <ng-content select="[lfx-crowdfunding-footerView]"></ng-content>
  </div>
</div>

<ng-template #detailsToolTip>
  <small>
    <strong>
      <p>{{details}}</p>
    </strong>
  </small>
</ng-template>
