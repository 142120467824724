// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DashboardOrgUserEvents,
  genderMap,
  generalConstants,
  operationMessages,
  roleContextMapping,
} from '@config';
import { environment } from '@environment';
import {
  AuthSession,
  Email,
  Favorite,
  Profile,
  UserContext,
  Role,
  Permission,
  MutationResponse,
  User,
} from '@models';
import * as jwtDecode from 'jwt-decode';
import { camelCase, mapKeys, upperFirst, isEmpty } from 'lodash';
import { NgxPermissionsObject } from 'ngx-permissions';
import {
  BehaviorSubject,
  Observable,
  of,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { map, tap, take, switchMap, first } from 'rxjs/operators';

import {
  UserServiceGql,
  UpdateUserInput,
  UpdateUserPayload,
  userTechnicalGraphQueryVariables,
  userTrainingsGraphQueryVariables,
} from '../gql';
import { AuthService } from './auth.service';
import { PermissionService } from './permission.service';
import { StorageService } from './storage.service';
import { CompanyService } from './company.service';
import { userEventsGraphQueryVariables } from '../gql/queries/user/get-user-events';
export const userStorageKey = 'userSession';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public favorites$: BehaviorSubject<Partial<Favorite[]>>;
  permissions: NgxPermissionsObject;
  roles: any;
  currentUser;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private storageService: StorageService,
    private companyService: CompanyService,
    private userServiceGql: UserServiceGql,
    private permissionService: PermissionService
  ) {
    this.permissionService.getPermissions().subscribe(perm => {
      this.permissions = perm;
    });
  }

  public getUser(userId: string): Observable<User> {
    return this.userServiceGql.getUser(userId);
  }

  public getUserTechnical(args: userTechnicalGraphQueryVariables) {
    return this.userServiceGql.getUserTechnical(args);
  }

  public getUserEvents(args: userEventsGraphQueryVariables) {
    return this.userServiceGql.getUserEvents(args);
  }

  public getUserTrainings(args: userTrainingsGraphQueryVariables) {
    return this.userServiceGql.getUserTrainings(args);
  }

  public updateAvatar(file: File): Observable<{ [Key: string]: string }> {
    return this.userServiceGql.updateUserAvatar(file).pipe(
      map(data => {
        this.getCurrentUserInformation().subscribe(res => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { avatarUrl, username, ...rest } = res;

          return this.authService.updateProfile({
            avatarUrl: data.fileUrl,
            ...rest,
          });
        });

        return { imageUrl: data.fileUrl };
      })
    );
  }

  public updateFavorite(favorite: Favorite[]): Observable<any> {
    // The real implementation will consume an endpoint.
    let allValues;

    this.storageService
      .getItem<Partial<AuthSession>>(userStorageKey)
      .subscribe(session => (allValues = session));
    let favorites = allValues.favorites;

    if (favorites) {
      if (favorites.find(v => v.id === favorite[0].id)) {
        favorites = favorites.filter(v => v.id !== favorite[0].id);
      } else {
        favorites = [...favorites, favorite[0]];
      }
    }

    this.storageService.setItem(userStorageKey, { ...allValues, favorites });

    if (this.favorites$) {
      this.favorites$.next(favorites);
    }

    return of(favorites);
  }

  public resetPassword(userData: any) {
    const route = `${environment.apiUrl}/user/lfx-reset-password`;

    return this.httpClient.post<boolean>(route, userData);
  }

  public checkUserPermissions(
    permissionRequest: string[],
    noCache = false
  ): Observable<Permission[]> {
    return this.userServiceGql.checkUserPermissions(permissionRequest, noCache);
  }

  public getCurrentUserInformation(
    withPermissions = false
  ): Observable<Profile> {
    return forkJoin(
      this.userServiceGql.getCurrentUser(withPermissions),
      this.authService.userProfile$.asObservable().pipe(take(1))
    ).pipe(
      map(([user, profile]) =>
        user
          ? {
              salesforceId: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName,
              primaryEmail:
                user.emails &&
                user.emails.filter(email => email.isPrimary)[0].emailAddress,
              emails:
                user.emails
                  .slice()
                  .sort(
                    (first, second) =>
                      Number(second.isPrimary) - Number(first.isPrimary)
                  ) || [],
              timezone: user.timezone,
              avatarUrl:
                user.avatarUrl ||
                profile.avatarUrl ||
                generalConstants.defaultAvatar,
              username: user.username,
              socialAccounts: user.socialAccounts,
              roles: user.roles,
            }
          : null
      )
    );
  }

  public initializeFavourite() {
    return this.storageService
      .getItem<Partial<Favorite>>(userStorageKey)
      .subscribe((res: any) => {
        if (res && res.favorites) {
          const { favorites } = res;

          this.favorites$ = new BehaviorSubject(
            favorites as Partial<Favorite[]>
          );
        } else {
          const favorites = [];

          this.storageService.setItem(userStorageKey, { favorites });
          this.favorites$ = new BehaviorSubject(
            favorites as Partial<Favorite[]>
          );
        }
      });
  }

  public getCompanyIdForStaff() {
    return (
      this.storageService.getItemSync(generalConstants.currentOrganization) ||
      generalConstants.defaultOrgID
    );
  }

  public getUserContexts(): Observable<UserContext[]> {
    return this.getUserRoles().pipe(
      switchMap(userRoles => {
        const rolesMap = [...new Set(this.mapRolesToContexts(userRoles))];
        const companyIdForStaff = this.getCompanyIdForStaff();

        const getCompany = () => {
          if (rolesMap.includes(UserContext.Staff)) {
            // set context as staff
            this.storageService.setItem(generalConstants.currentContext, [
              UserContext.Staff,
            ]);

            return this.companyService
              .getCompanyById(companyIdForStaff)
              .pipe(first());
          } else {
            return this.companyService.getMyCompanyForNonStaff().pipe(first());
          }
        };

        return combineLatest([getCompany(), of(userRoles)]);
      }),
      map(([company, userRoles]: any[]) => {
        const rolesMap = [...new Set(this.mapRolesToContexts(userRoles))];

        if (rolesMap.includes(UserContext.Staff)) {
          return rolesMap;
        } else if (
          (company && company.name === generalConstants.individualNoAccount) ||
          (userRoles.length && !this.hasValidRoles(userRoles))
        ) {
          return [UserContext.Individual];
        } else {
          if (!userRoles) {
            return [];
          }

          return rolesMap;
        }
      })
    );
  }

  hasValidRoles(roles: string[]) {
    const validRoles = ['viewer', 'company-admin', 'lf-staff'];

    for (const value of roles) {
      if (validRoles.includes(value)) {
        return true;
      }
    }

    return false;
  }

  public updateUser(userInfo: UpdateUserInput): Observable<UpdateUserPayload>;
  public updateUser(
    id: string,
    profile: Partial<Profile>
  ): Observable<UpdateUserPayload>;

  public updateUser(
    input: string | UpdateUserInput,
    profile?: Partial<Profile>
  ): Observable<UpdateUserPayload> {
    if (profile) {
      const { firstName, lastName, emails } = profile;
      const id = input as string;
      const dataToBeUpdated = { id, firstName, lastName, emails };

      return this.userServiceGql.updateUser(dataToBeUpdated).pipe(
        map((response: any) => {
          if (!response.user) {
            throw new Error(response.message);
          }

          return response;
        })
      );
    }

    return this.userServiceGql.updateUser(input as UpdateUserInput);
  }

  public updateProfile(profile: Partial<Profile>): Observable<Profile> {
    const { firstName, lastName, timezone, emails, username, avatarUrl } =
      profile;

    const profileToBeUpdated = {
      firstName,
      lastName,
      timezone,
      emails,
      username,
      avatarUrl,
    };

    if (profileToBeUpdated.emails) {
      profileToBeUpdated.emails = this.parseEmails(
        profileToBeUpdated.emails,
        true
      );
    }

    return this.userServiceGql.updateCurrentUser(profileToBeUpdated).pipe(
      map((response: any) => {
        if (response.currentUser) {
          return {
            salesforceId: response.currentUser.id,
            firstName: response.currentUser.firstName,
            lastName: response.currentUser.lastName,
            primaryEmail: response.currentUser.primaryEmail,
            emails:
              (response.currentUser.emails &&
                this.parseEmails(response.currentUser.emails, true)
                  .slice()
                  .sort(
                    (first, second) =>
                      Number(second.isPrimary) - Number(first.isPrimary)
                  )) ||
              [],
            timezone: response.currentUser.timezone,
            avatarUrl:
              response.currentUser.avatarUrl || generalConstants.defaultAvatar,
            username: response.currentUser.username,
          } as Profile;
        }

        response.message =
          response.message === '409: Conflict'
            ? 'This email belongs to another LF user'
            : response.message;

        throw new Error(response.message);
      }),
      tap(updatedProfile => {
        this.authService.updateProfile(updatedProfile as Partial<Profile>);
      })
    );
  }

  public resendVerficationEmail(email: string, state: string): Observable<any> {
    const route = `${environment.apiUrl}/user/resend-verification-email`;

    const params: any = {
      email: encodeURIComponent(email),
    };

    if (state) {
      params.state = state;
    }

    return this.httpClient.post(route, null, { params });
  }

  public getUserServiceRoles(input: string): Observable<Role[]> {
    return this.userServiceGql
      .getUserRoles({ search: input })
      .pipe(map(roles => roles));
  }

  public listUsers() {
    return this.userServiceGql.listUsers();
  }

  public getInvite(inviteId: string): Observable<any> {
    return this.userServiceGql.getInvitation(inviteId);
  }

  public isLFStaffUser(): Observable<boolean> {
    return this.getUserRoles().pipe(map(value => value.includes('lf-staff')));
  }

  public isCompanyAdminOrLFStaffUser(): Observable<boolean> {
    return this.getUserRoles().pipe(
      map(
        value =>
          this.isCompanyAdminForCurrentOrg(value) || value.includes('lf-staff')
      )
    );
  }

  public isCompanyAdmin(adminOnly: boolean): Observable<boolean> {
    return this.getUserRoles().pipe(
      map(value =>
        adminOnly
          ? this.isCompanyAdminForCurrentOrg(value) &&
            !value.includes('lf-staff')
          : this.isCompanyAdminForCurrentOrg(value)
      )
    );
  }

  updateInvite(inviteId: string, status: string): Observable<any> {
    return this.userServiceGql.updateInvitation(inviteId, status);
  }

  deleteInvite(inviteId: string): Observable<MutationResponse<null>> {
    return this.userServiceGql.deleteInvitation(inviteId);
  }

  isUserAdmin(companyId) {
    const orgDetailPatialUpdate = `org_detail:partial_update:organization:${companyId}`;

    return this.checkUserPermissions([orgDetailPatialUpdate]).pipe(
      map(data => data[0].status)
    );
  }

  hasAllPermission(permissions: string[]): Observable<boolean> {
    return this.checkUserPermissions(permissions).pipe(
      map(permissions =>
        permissions.reduce(
          (previous, current) => previous && current.status,
          true
        )
      )
    );
  }

  mapGenderText(gender: string) {
    return genderMap[gender.replace(/['"-]/, '').toLowerCase()] || 'no data';
  }

  getDashboardOrgUserEvents(
    organizationId: string,
    userId: string
  ): Observable<DashboardOrgUserEvents[]> {
    return this.userServiceGql.getDashboardOrgUserEvents(
      organizationId,
      userId
    );
  }

  private isCompanyAdminForCurrentOrg(userRole) {
    return (
      (userRole.includes('company-admin') &&
        !this.companyService.selectedConglomerateOrg?.id) ||
      (userRole.includes('company-admin') &&
        this.companyService.selectedConglomerateOrg?.id &&
        this.companyService.selectedConglomerateOrg?.id ===
          this.currentUser.account.id)
    );
  }

  private mapRolesToContexts(roles: string[]): UserContext[] {
    return roles.map(r => roleContextMapping[r]);
  }

  private getUserRoles(): Observable<string[]> {
    if (this.roles) {
      return of(this.roles);
    }

    return this.userServiceGql
      .getCurrentUser()
      .pipe(
        tap(response => {
          this.currentUser = response;
          let filtered = [];

          if (response && response.permissions) {
            filtered = response.permissions.filter(perm => {
              if (perm.scopes) {
                return (
                  perm.scopes.filter(
                    scope => scope.id.filter(id => id !== '*').length > 0
                  ).length > 0
                );
              }
            });
          }

          if (filtered.length > 0) {
            this.roles = ['contributor'];
          }
        })
      )
      .pipe(map((response: any) => (response ? response.roles : [])))
      .pipe(
        tap(value => {
          if (value && !isEmpty(value)) {
            if (this.roles) {
              this.roles = this.roles.concat(value);
            } else {
              this.roles = value;
            }
          }
        })
      );
  }

  private handleProfileUpdateError(error, profile: Partial<Profile>) {
    let errorMessage = '';

    switch (error.status) {
      case 409:
        errorMessage = this.handleConflictError(error, profile);
        break;
      case 400:
        errorMessage = this.handleValidationError(error, profile);
        break;
      default:
        errorMessage = operationMessages.profile.updateError;
    }

    error.message = errorMessage || error.message;

    return throwError(error);
  }

  private handleConflictError(conflictError, profile: Partial<Profile>) {
    if (conflictError.message.includes('email')) {
      return this.getEmailConflictErrorMessage(conflictError, profile);
    }
  }

  private handleValidationError(validationError, profile: Partial<Profile>) {
    if (validationError.message.includes('email')) {
      return this.getEmailValidationErrorMessage(validationError, profile);
    }
  }

  private getEmailConflictErrorMessage(
    conflictError,
    profile: Partial<Profile>
  ) {
    const conflictEmail = this.getErrEmail(
      profile.emails,
      conflictError.message
    );

    return `${conflictEmail} ${operationMessages.profile.emailConflictError}`;
  }

  private getEmailValidationErrorMessage(
    validationError,
    profile: Partial<Profile>
  ) {
    const invalidEmail = this.getErrEmail(
      profile.emails,
      validationError.message
    );

    return `Email '${invalidEmail}' ${operationMessages.profile.partialUpdateEmailValidationError}`;
  }

  private getErrEmail(emails: Email[], conflictError: string): string {
    const emls = this.parseEmails(emails, true);

    const errEmail = emls.find(nextEmail =>
      conflictError.includes(nextEmail.emailAddress)
    );

    return (errEmail || ({} as Email)).emailAddress || '';
  }

  private parseEmails(emails: any[], toCamelCase: boolean) {
    return emails.map((email: any) =>
      mapKeys(email, (v, k) => (toCamelCase ? camelCase(k) : upperFirst(k)))
    ) as Email[];
  }

  private getUserIdFromIdToken(idToken: string): string {
    const userInfo = jwtDecode(idToken);

    return userInfo.sub;
  }
}
