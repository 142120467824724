// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCCertifiedEmployeesLeaderboardQuery = gql`
  query tnCCertifiedEmployeesLeaderboard(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $page: Int
    $limit: Int
  ) {
    getTnCCertifiedEmployeesLeaderboard(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      page: $page
      limit: $limit
    ) {
      userId
      userPhotoUrl
      userFullName
      certificationsCount
    }
  }
`;
