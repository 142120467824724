// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCListDetailsCountQuery = gql`
  query TnCListDetailsCount(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $courseGroupId: String
    $searchName: String
  ) {
    getTnCListDetailsCount(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      courseGroupId: $courseGroupId
      searchName: $searchName
    ) {
      count
    }
  }
`;
