// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  AuthService,
  FeatureFlagManagerService,
  StorageService,
  UserService,
} from '@services';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { UserContext } from '@models';
import { contextPriority, generalConstants, pageMenus } from './config';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  storedContextList = generalConstants.currentContext;
  menus = pageMenus;

  constructor(
    public authService: AuthService,
    private featureFlagManagerService: FeatureFlagManagerService,
    private userService: UserService,
    private storageService: StorageService,
    private router: Router
  ) {}

  init() {
    return this.authService.isAuthenticated$
      .pipe(
        switchMap(res => {
          if (!res) {
            return of(false);
          }

          return this.initUser();
        })
      )
      .toPromise();
  }

  initUser(): Observable<[any, any, unknown]> {
    return this.authService.initializeUserProfile().pipe(
      switchMap(() =>
        forkJoin([
          this.authService.setPermissions(),
          this.authService.setRoles(),
          this.authService.userProfile$.pipe(
            first(),
            switchMap(userProfile =>
              this.featureFlagManagerService.initialize(userProfile)
            )
          ),
        ])
      )
    );
  }

  setContext() {
    // Set context if one is not defined yet
    // Get all user contexts based on permissions
    // Filter to the ones actually available (based on flags)
    const userContexts$ = this.userService.getUserContexts().pipe(
      mergeMap(contexts => {
        const flags = this.featureFlagManagerService.flags;

        if (!!flags) {
          return of(contexts.filter(c => !!flags[`${c}-context`]));
        } else {
          return this.featureFlagManagerService.flagChange.pipe(
            map(newFlags => contexts.filter(c => !!newFlags[`${c}-context`]))
          );
        }
      })
    );

    // Get the last context used from localStorage
    const storedContext$ = this.storageService
      .getItem<UserContext[]>(this.storedContextList)
      .pipe(
        map(response => {
          if (response) {
            return response;
          } else {
            return [];
          }
        })
      );

    return combineLatest([userContexts$, storedContext$]).pipe(
      switchMap(responses => {
        const userContexts = responses[0];
        const defaultContext = contextPriority.find(c =>
          userContexts.includes(c)
        );

        // eslint-disable-next-line no-console
        console.debug(
          '(contexts)',
          `default:${defaultContext}`,
          `available:${userContexts}`
        );

        if (!!defaultContext) {
          // Use the default context based on priority
          this.changeContext(defaultContext);
          // this.spinner.hide();
        } else {
          // TODO: What if no context is available?
          // eslint-disable-next-line no-console
          console.error('(contexts)', 'No available contexts for user');
        }

        return of([]);
      })
    );
  }

  private changeContext(context: UserContext) {
    this.storageService.setItem(this.storedContextList, [context]);

    this.menus = pageMenus.filter(
      menu =>
        !menu.context ||
        menu.context === context ||
        (Array.isArray(menu.context) && menu.context.includes(context))
    );
  }
}
