// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Membership } from '@models';

export interface ListMembershipsResult {
  listMemberships: Membership[];
}

export interface Metadata {
  offset?: number;
  pageSize?: number;
  totalSize?: number;
}

export interface ListAccountMembershipsRes {
  memberships: Membership[];
  metaData: Metadata;
}

export interface ListAccountMembershipsResult {
  listAccountMemberships: ListAccountMembershipsRes;
}
