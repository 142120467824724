// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { DependencyTree } from '.';

export interface License {
  licenseName: string;
  dependencies: DependencyTree[];
  dependencyCount: number;
  url: string;
  dependencyId: string;
  repositoryId: string;
  repositoryName: string;
}

export interface Dependency {
  repositoryId: string;
  repositoryName: string;
  dependencyTree: DependencyTree[];
}
