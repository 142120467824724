// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'lfx-section-footer',
  template: `
    <div class="section-footer">
      <span>{{ preText }} </span>
      <a [href]="'mailto:' + email" class="lf-link-v2">{{ linkText }}</a>
    </div>
  `,
  styles: [
    `
      .section-footer {
        text-align: right;
        margin-top: 22px;
        span {
          color: #626469;
          font-family: 'Open Sans';
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
          line-height: 120%;
        }
        a.lf-link-v2 {
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
        }
      }
    `,
  ],
})
export class SectionFooterComponent {
  /** The text to display before the contact link */
  @Input() preText = 'Need to update?';
  /** The text to display for the contact link */
  @Input() linkText = 'Contact Us';
  /** The email address for the contact link */
  @Input() set email(value: string) {
    if (value && !this.isValidEmail(value)) {
      // eslint-disable-next-line no-console
      console.warn('Invalid email format provided');
    }
    this._email = value;
  }

  get email(): string {
    return this._email || environment.supportEmail;
  }

  private _email: string;

  private isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
}
