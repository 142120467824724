// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Foundation } from '@models';
import { MutationPayload } from './mutation-payload';

export interface CreateNewFoundationResult {
  createNewFoundation: CreateNewFoundationPayload;
}

export interface CreateNewFoundationPayload extends MutationPayload {
  newFoundation: Foundation;
}
