// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-insights-card',
  templateUrl: './insights-card.component.html',
  styleUrls: ['./insights-card.component.scss'],
})
export class InsightsCardComponent implements OnInit {
  @Input() name: string;
  @Input() value: number;
  constructor() {}

  ngOnInit() {}
}
