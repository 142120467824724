// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface NewClaManager {
  firstName: string;
  lastName: string;
  email: string;
  projectId: string;
  companyId: string;
  claGroupId: string;
}
