// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FundingInitiative } from '@models';

@Component({
  selector: 'lfx-crowdfunding-funding-card',
  templateUrl: './crowdfunding-funding-card.component.html',
  styleUrls: ['./crowdfunding-funding-card.component.scss'],
})
export class CrowdfundingFundingCardComponent implements OnInit {
  @Input() initiative: FundingInitiative;
  @Output() gotoCrowdFundingDetailAction = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  gotoCrowdFundingDetail(id: string) {
    this.gotoCrowdFundingDetailAction.emit(id);
  }
}
