// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface RequestClaSignatureInput {
  projectSfid: string;
  claGroupId: string;
  companySfid: string;
  sendAsEmail: string;
  authorityName: string;
  authorityEmail: string;
  returnUrl: string;
  signingEntityName?: string;
}
