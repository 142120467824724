// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
} from '@angular/core';

@Component({
  selector: 'lfx-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit, AfterViewInit {
  @ViewChild('panelFooter') panelFooter;
  @Input() title;
  @Input() variant;
  @Input() noBody;
  @Input() noButton;
  @Input() bodyClass;
  @Input() footerClass;
  @Input() panelClass;

  expand = false;
  reload = false;
  collapse = false;
  remove = false;
  showFooter = false;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.showFooter =
        this.panelFooter.nativeElement &&
        this.panelFooter.nativeElement.children.length > 0;
    });
  }

  panelExpand() {
    this.expand = !this.expand;
  }

  panelReload() {
    this.reload = true;

    setTimeout(() => {
      this.reload = false;
    }, 1500);
  }

  panelCollapse() {
    this.collapse = !this.collapse;
  }

  panelRemove() {
    this.remove = !this.remove;
  }
}
