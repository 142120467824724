<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ng-container *ngIf="paginationType === paginationTypeConst.DEFAULT">
  <div class="paginator-container" *ngIf="version === 1">
    <i
      class="fal fa-angle-double-left fa-2x cursor-pointer"
      (click)="onPageChange(1)"
      [ngClass]="{ disabled: page === 1 }"
    ></i>
    <i
      class="fal fa-angle-left fa-2x cursor-pointer"
      (click)="onPageChange(page - 1)"
      [ngClass]="{ disabled: page === 1 }"
    ></i>
    <div class="current-page-text">{{ getCurrentPageText() }}</div>
    <i
      class="fal fa-angle-right fa-2x cursor-pointer"
      (click)="onPageChange(page + 1)"
      [ngClass]="{ disabled: page === getLastPageIndex() }"
    ></i>
    <i
      class="fal fa-angle-double-right fa-2x cursor-pointer"
      (click)="onPageChange(getLastPageIndex())"
      [ngClass]="{ disabled: page === getLastPageIndex() }"
    ></i>
  </div>

  <div class="paginator-container version-2" *ngIf="version === 2">
    <div class="current-page-text">{{ getCurrentPageText() }}</div>
    <i
      class="fal fa-angle-left fa-2x cursor-pointer"
      (click)="onPageChange(page - 1)"
      [ngClass]="{ disabled: page === 1 }"
    ></i>
    <i
      class="fal fa-angle-right fa-2x cursor-pointer"
      (click)="onPageChange(page + 1)"
      [ngClass]="{ disabled: page === getLastPageIndex() }"
    ></i>
  </div>
</ng-container>

<div
  class="pagination-show-more"
  *ngIf="paginationType === paginationTypeConst.SHOW_MORE"
>
  <div class="show-more-link" *ngIf="!hasReachedEndPage()">
    <span class="lf-link-v2" (click)="onPageChange(page + 1)"> Show More </span>
  </div>
  <div class="pagination-counts">
    Showing {{ hasReachedEndPage() ? collectionSize : pageSize * page }} of
    {{ collectionSize }}
  </div>
</div>
