// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lfx-crowdfunding-scholarship-card',
  templateUrl: './crowdfunding-scholarship-card.component.html',
  styleUrls: ['./crowdfunding-scholarship-card.component.scss'],
})
export class CrowdfundingScholarshipCardComponent implements OnInit {
  @Input() initiative;
  @Output() gotoScholarshipDetailAction = new EventEmitter<void>();
  showOverlayOnScholarshipCard = false;

  constructor() {}

  ngOnInit() {}

  onClickCard() {
    this.gotoScholarshipDetailAction.emit();
  }
}
