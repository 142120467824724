// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { projectDetailsOffcanvasConfig } from '@lfx/config';
import {
  EmployeeProfileSnapshotComponent,
  UserProfileInput,
} from '@lfx/modules/profile-snapshot/components/employee-profile-snapshot/employee-profile-snapshot.component';
import {
  Info,
  InfoOffcanvasComponent,
} from '@lfx/shared/components/info-offcanvas/info-offcanvas.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import {
  EventRegistrantsOffcanvasComponent,
  EventRegistrationInfo,
} from '@lfx/shared/components/event-registrants-offcanvas/event-registrants-offcanvas.component';
import {
  EventInfo,
  EventSpeakersOffcanvasComponent,
} from '@lfx/shared/components/event-speakers-offcanvas/event-speakers-offcanvas.component';
import { TrainingNCertificateDetailsComponent } from '@lfx/shared/components/training-n-certificate-details/training-n-certificate-details.component';
import { Observable, of } from 'rxjs';
import { MailingListSubscribersOffcanvasComponent } from '@lfx/shared/components/mailing-list-subscribers-offcanvas/mailing-list-subscribers-offcanvas.component';
import { ListOffcanvasInfo } from '@lfx/shared/components/base-list-offcanvas/base-list-offcanvas.directive';
import { MeetingsAttendeesOffcanvasComponent } from '@lfx/shared/components/meetings-attendees-offcanvas/meetings-attendees-offcanvas.component';

@Injectable({ providedIn: 'root' })
export class FlyoutService {
  constructor(private offcanvasService: NgbOffcanvas) {}

  openInfo(info: Info[]) {
    const ref = this.offcanvasService.open(
      InfoOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    ref.componentInstance.info = info;
  }

  openUserProfile(userProfileInput: UserProfileInput) {
    if (!userProfileInput?.employeeId) {
      throw new Error('Employee ID is required');
    }

    const ref = this.offcanvasService.open(
      EmployeeProfileSnapshotComponent,
      projectDetailsOffcanvasConfig
    );

    const instance = ref.componentInstance as EmployeeProfileSnapshotComponent;

    instance.employeeId = userProfileInput.employeeId;
    instance.employeeEmail = userProfileInput.employeeEmail;
    instance.usersPageSource = userProfileInput.usersPageSource;
    instance.defaultEmployeeObject = userProfileInput.defaultEmployeeObject;
    instance.options = userProfileInput.options;
  }

  openEventRegistrants(eventRegistrationInfo: EventRegistrationInfo) {
    this.validateFields(
      eventRegistrationInfo,
      [
        'eventId',
        'eventName',
        'eventDate',
        'registrantsCount',
        'dateRange',
        'companyId',
      ],
      'event registrants'
    );

    const ref = this.offcanvasService.open(
      EventRegistrantsOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    (
      ref.componentInstance as EventRegistrantsOffcanvasComponent
    ).eventRegistrationInfo = eventRegistrationInfo;
  }

  openEventSpeakers(eventInfo: EventInfo) {
    this.validateFields(
      eventInfo,
      ['eventId', 'eventName', 'eventDate', 'count', 'dateRange', 'companyId'],
      'event speakers'
    );

    const ref = this.offcanvasService.open(
      EventSpeakersOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    (ref.componentInstance as EventSpeakersOffcanvasComponent).eventInfo =
      eventInfo;
  }

  openTrainingNCertificateDetails(
    courseGroupId: string,
    sectionTitle: string,
    timeRange$?: Observable<string>,
    last12MonthsOnly?: boolean
  ) {
    this.validateFields(
      { courseGroupId, sectionTitle },
      ['courseGroupId', 'sectionTitle'],
      'training and certificate details'
    );

    const ref = this.offcanvasService.open(
      TrainingNCertificateDetailsComponent,
      {
        position: 'end',
        panelClass: 'training-offcanvas',
        scroll: false,
      }
    );

    const instance =
      ref.componentInstance as TrainingNCertificateDetailsComponent;

    instance.courseGroupId = courseGroupId;
    instance.timeRange$ = timeRange$ || of('alltime');
    instance.sectionTitle = sectionTitle;
    instance.last12MonthsOnly = last12MonthsOnly;
  }

  openMailingListSubscribers(listInfo: ListOffcanvasInfo, projectId: string) {
    this.validateFields(listInfo, ['companyId'], 'mailing list subscribers');

    const ref = this.offcanvasService.open(
      MailingListSubscribersOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    const instance =
      ref.componentInstance as MailingListSubscribersOffcanvasComponent;

    instance.listInfo = listInfo;
    instance.projectId = projectId;
  }

  openMeetingsAttendees(listInfo: ListOffcanvasInfo, projectId: string) {
    this.validateFields(listInfo, ['companyId'], 'meetings attendees');

    const ref = this.offcanvasService.open(
      MeetingsAttendeesOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    const instance =
      ref.componentInstance as MeetingsAttendeesOffcanvasComponent;

    instance.listInfo = listInfo;
    instance.projectId = projectId;
  }

  private validateFields<T>(
    data: T,
    requiredFields: (keyof T)[],
    context: string
  ): void {
    const missingFields = requiredFields.filter(field => !data[field]);

    if (missingFields.length) {
      throw new Error(
        `Missing required fields for ${context}: ${missingFields.join(', ')}`
      );
    }
  }
}
