// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { SponsoredEvent } from './sponsored-event';

export interface SponsoredEvents {
  events: SponsoredEvent[];
  yearToDateSpend: {
    value: number;
    currencyCode: string;
  };
}
