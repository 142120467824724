// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  eventRegistrationDetailsQuery,
  eventsAttendeesGrowthQuery,
  eventsLeaderboardQuery,
  eventsOverviewEventsListQuery,
  eventsOverviewStatsQuery,
  eventsSponsorshipQuery,
  eventsTravelFundingQuery,
  plannedEventsQuery,
} from '../queries/event';
import { PlannedEventsResults } from './results';
import { cloneDeep } from 'lodash';
import {
  AttendeeDiversityChart,
  CommonEventsPageInput,
  EventRegistrationDetails,
  EventRegistrationDetailsInput,
  EventsAttendeesGrowth,
  EventsAttendeesGrowthInput,
  EventsLeaderboard,
  EventsLeaderboardInput,
  EventsOverviewEventsList,
  EventsOverviewEventsListInput,
  EventsOverviewStats,
  EventsSponsorship,
  EventsTableData,
  EventsTravelFunding,
  EventViewDetailsSummary,
  EventViewDetailSummaryInput,
} from '@lfx/config';
import {
  attendeeDiversityChartQuery,
  eventsSpeakersGroupedQuery,
  getEventViewDetailsSummaryQuery,
  orgEventsTableQuery,
  userEventsDetailsQuery,
} from '../queries';
import { EventsDataGrouped } from '@lfx/core/models/events-speakers';
import { eventsAttendeesParticipationQuery } from '../queries/event/events-attendees-participation';

@Injectable({
  providedIn: 'root',
})
export class EventServiceGql {
  constructor(private apollo: Apollo) {}

  getAllPlannedEvents(noCache: boolean): Observable<any[]> {
    return this.apollo
      .watchQuery<PlannedEventsResults>({
        query: plannedEventsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.plannedEvents),
        map(cloneDeep)
      );
  }

  getEventAttendeesParticipation(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<any[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsAttendeesParticipationQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsAttendeesParticipation),
        map(cloneDeep)
      );
  }

  getAttendeeDiversityChart(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<AttendeeDiversityChart> {
    return this.apollo
      .watchQuery<any>({
        query: attendeeDiversityChartQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            organizationId,
            dateRange,
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgEventsDiversityChart),
        map(cloneDeep)
      );
  }

  getSpeakerDiversityChart(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<AttendeeDiversityChart> {
    return this.apollo
      .watchQuery<any>({
        query: attendeeDiversityChartQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            organizationId,
            dateRange,
            type: 'speaker',
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgEventsDiversityChart),
        map(cloneDeep)
      );
  }

  getOrgEventsTableData(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<EventsTableData> {
    return this.apollo
      .watchQuery<any>({
        query: orgEventsTableQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            organizationId,
            dateRange,
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgEventsTableData),
        map(cloneDeep)
      );
  }

  getEventViewDetailsSummary({
    input,
    noCache = false,
  }: {
    input: EventViewDetailSummaryInput;
    noCache?: boolean;
  }): Observable<EventViewDetailsSummary[]> {
    return this.apollo
      .watchQuery<any>({
        query: getEventViewDetailsSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgEventViewDetailsSummary),
        map(cloneDeep)
      );
  }

  getOrgEventsSpeakersGrouped(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<EventsDataGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsSpeakersGroupedQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            organizationId,
            dateRange,
            type: 'speaker',
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgNumberOfSpeakersGrouped),
        map(cloneDeep)
      );
  }

  getAllOrgEventSpeakerDetail(
    userId: string,
    dateRange: string,
    noCache = false
  ): Observable<{ name: string; startDate: string }[]> {
    return this.apollo
      .watchQuery<any>({
        query: userEventsDetailsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            userId,
            dateRange,
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.userEventsDetails),
        map(cloneDeep)
      );
  }

  getEventsOverviewStats(
    input: CommonEventsPageInput
  ): Observable<EventsOverviewStats> {
    return this.apollo
      .watchQuery<any>({
        query: eventsOverviewStatsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsOverviewStats),
        map(cloneDeep)
      );
  }

  getEventsOverviewEventsList(
    input: EventsOverviewEventsListInput
  ): Observable<EventsOverviewEventsList> {
    return this.apollo
      .watchQuery<any>({
        query: eventsOverviewEventsListQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsOverviewEventsList),
        map(cloneDeep)
      );
  }

  getEventsLeaderboard(
    input: EventsLeaderboardInput
  ): Observable<EventsLeaderboard[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsLeaderboardQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsLeaderboard),
        map(cloneDeep)
      );
  }

  getEventRegistrationsDetails(
    input: EventRegistrationDetailsInput
  ): Observable<EventRegistrationDetails> {
    return this.apollo
      .watchQuery<any>({
        query: eventRegistrationDetailsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventRegistrationDetails),
        map(cloneDeep)
      );
  }

  getEventsAttendeesGrowth(
    input: EventsAttendeesGrowthInput
  ): Observable<EventsAttendeesGrowth[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsAttendeesGrowthQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsAttendeesGrowth),
        map(cloneDeep)
      );
  }

  getEventsTravelFunding(
    input: CommonEventsPageInput
  ): Observable<EventsTravelFunding> {
    return this.apollo
      .watchQuery<any>({
        query: eventsTravelFundingQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsTravelFunding),
        map(cloneDeep)
      );
  }

  getEventsSponsorship(
    input: CommonEventsPageInput
  ): Observable<EventsSponsorship[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsSponsorshipQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsSponsorship),
        map(cloneDeep)
      );
  }
}
