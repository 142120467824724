// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { FoundationProject } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateProjectEssentialsResult {
  updateProjectEssentials: UpdateFoundationProjectPayload;
}

export interface UpdateFoundationProjectPayload extends MutationPayload {
  project?: FoundationProject;
}
