<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-full-card
  [title]="project.name"
  [details]="project.description"
  [tag]="project.tag"
  [color]="project.colorBand"
  [logo]="project.logoUrl"
  [defaultIcon]="projectDefaultIcon"
  (action)="gotoProject()">
  <div lfx-cta-buttons class="row justify-content-end">
    <div class="col-auto">
      <button type="button" class="btn btn-primary btn-sm" (click)="gotoProject()" >{{viewProjectsDetails}}</button>
    </div>
  </div>

  <lfx-badge [badgeUrl]="project.badgeUrl"></lfx-badge>

  <ng-container lfx-insights>
  <lfx-toggle-pills [overlayText]="!project.community && !project.organization ? 'Insights not available': null" leftButtonText="Community" rightButtonText="Organization">
    <div class="lfx-toggle-pills-left text-left small">
      <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Contributors</p>
          <p class="mb-0">
            <ng-container *ngxPermissionsOnly="'feature_statistics_widgets'">
              <strong *ngIf="project.community?.contributors">{{ project.community.contributors | shortNumber }}</strong>
              <strong *ngIf="!project.community?.contributors">No Data</strong>
            </ng-container>
            <ng-container *ngxPermissionsOnly="'!feature_statistics_widgets'">
              <strong>No Data</strong>
            </ng-container>
            </p>
        </div>
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Commits</p>
          <p class="mb-0">
            <ng-container *ngxPermissionsOnly="'feature_statistics_widgets'">
              <strong *ngIf="project.community?.commits">{{ project.community.commits | shortNumber }}</strong>
              <strong *ngIf="!project.community?.commits">No Data</strong>
            </ng-container>
            <ng-container *ngxPermissionsOnly="'!feature_statistics_widgets'">
              <strong>No Data</strong>
            </ng-container>
          </p>
        </div>
      </div>
  </div>

    <div class="lfx-toggle-pills-right text-left small">
      <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Contributors</p>
          <p class="mb-0">
            <ng-container *ngxPermissionsOnly="'feature_statistics_widgets'">
              <strong *ngIf="project.organization?.contributors">{{ project.organization.contributors | shortNumber }}</strong>
              <strong *ngIf="!project.organization?.contributors">No Data</strong>
            </ng-container>
            <ng-container *ngxPermissionsOnly="'!feature_statistics_widgets'">
              <strong>No Data</strong>
            </ng-container>
          </p>
        </div>
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Commits</p>
          <p class="mb-0">
            <ng-container *ngxPermissionsOnly="'feature_statistics_widgets'">
              <strong *ngIf="project.organization?.commits">{{ project.organization.commits | shortNumber }}</strong>
              <strong *ngIf="!project.organization?.commits">No Data</strong>
            </ng-container>
            <ng-container *ngxPermissionsOnly="'!feature_statistics_widgets'">
              <strong>No Data</strong>
            </ng-container>
          </p>
        </div>
        <ng-container *ngxPermissionsOnly="'feature_statistics_widgets'">
          <div *ngIf="!project.organization" class="overlay" >
            Insights not available
          </div>
        </ng-container>
      </div>

      <!-- <div class="row">
        <div class="col-md-6">
          <p class="m-b-2 m-t-5">Total Code Contributions</p>
          <p class="mb-0">
            <strong>{{ project.organization.totalCodeContribution / 100 | percent }}</strong>
          </p>
        </div>
      </div> -->
    </div>
  </lfx-toggle-pills>
  </ng-container>
</lfx-full-card>
