<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<lfx-full-card [title]="initiative.name" [details]="initiative.description" [color]="initiative.colorBand"
    [logo]="initiative.logoUrl" [showOverlay]="showOverlayOnMentorshipCard" (action)="onClickCard()">

    <div lfx-crowdfunding-bodyView class="body-wrapper">
        <div class="row seperator">
            <div class="col-md-6">
                <div class="text">Donation</div>
                <div>
                    <lfx-slider [min]="0" [max]="initiative.funding.target" [currency]="initiative.funding.currencyCode"
                        [color]="initiative.colorBand" [value]="initiative.funding.current" [disabled]="true">
                    </lfx-slider>
                </div>
            </div>

            <div class="col-md-6">
                <div class="text">Job Opportunities</div>
                <div class="bold">({{initiative.jobOpportunities}})</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="text">Stipend Paid Out</div>
                <div class="bold">
                    {{ initiative.stipendPaid  | currency : initiative.funding.currencyCode: 'symbol' : '1.0' }}</div>
            </div>

            <div class="col-md-6">
                <div class="text">Mentees Paid</div>
                <lfx-logo-list [items]="initiative.menteesPaid" limit="2"></lfx-logo-list>
            </div>
        </div>
    </div>

    <div lfx-crowdfunding-footerView class="footer-wrapper">
        <div class="row">
            <div class="col-md-6">
                <div class="text">Corporate Sponsors</div>
                <lfx-logo-list [items]="initiative.funding.sponsors" limit="2"></lfx-logo-list>
            </div>

            <div class="col-md-6">
                <div class="text">Individual Sponsors</div>
                <lfx-logo-list [items]="initiative.funding.backers" limit="2"></lfx-logo-list>
            </div>
        </div>
    </div>
</lfx-full-card>
