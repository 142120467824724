// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface ItServices {
  domains: ItServicesDomain[];
  emails: ItServicesEmail[];
  mailingList: ItServicesMailListItem[];
}

export interface ItServicesDomain {
  name: string;
  status: string;
  statusIcon: string;
  primary?: boolean;
  color: string;
}
export interface ItServicesEmail {
  alias: string;
  recipients: string[];
  status: string;
  statusIcon: string;
  color: string;
}
export interface ItServicesMailListItem {
  title: string;
  alias: string;
  description: string;
  tag: string;
  url: string;
}

export const mockItServices = (): ItServices => ({
  domains: [
    {
      name: 'Domain1.com',
      status: 'We Own it',
      statusIcon: 'fas fa-check-circle',
      color: 'green',
      primary: true,
    },
    {
      name: 'Domain2.com',
      status: 'Transfer Pending ',
      statusIcon: 'fas fa-sync',
      color: 'orange',
    },
    {
      name: 'Domain3.com',
      status: 'Registration Pending',
      statusIcon: 'fas fa-file-invoice-dollar',
      color: 'orange',
    },
    {
      name: 'Domain4.com',
      status: 'Rejected',
      statusIcon: 'fas fa-times-circle',
      color: 'red',
    },
    {
      name: 'Domain5.com',
      status: 'Bid Pending',
      statusIcon: 'fas fa-dollar-sign',
      color: 'orange',
    },
  ],
  emails: [
    {
      alias: 'example@xyz.com',
      recipients: [
        'example1@xyz.com',
        'example2@xyz.com',
        'example3@xyz.com',
        'example4@xyz.com',
      ],
      status: 'Verified',
      statusIcon: 'fas fa-check-circle',
      color: 'green',
    },
    {
      alias: 'example@xyz.com',
      recipients: [
        'example1@xyz.com',
        'example2@xyz.com',
        'example3@xyz.com',
        'example4@xyz.com',
      ],
      status: 'Pending ',
      statusIcon: 'fas fa-exclamation-circle',
      color: 'orange',
    },
    {
      alias: 'example@xyz.com',
      recipients: ['example1@xyz.com'],
      status: 'Not Verified',
      statusIcon: 'fas fa-times-circle',
      color: 'red',
    },
  ],
  mailingList: [
    {
      title: 'Lorem Ipsum',
      alias: 'sales@xyz.com',
      // tslint:disable-next-line: max-line-length
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum repudiandae nisi animi quis quibusdam unde doloremque ab itaque nostrum, eum eius voluptatem dignissimos consequuntur vitae reiciendis obcaecati non nihil dolorum?',
      tag: 'Lorem Ipsum',
      url: 'www.url.com',
    },
    {
      title: 'Lorem Ipsum',
      alias: 'sales@xyz.com',
      // tslint:disable-next-line: max-line-length
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum repudiandae nisi animi quis quibusdam unde doloremque ab itaque nostrum, eum eius voluptatem dignissimos consequuntur vitae reiciendis obcaecati non nihil dolorum?',
      tag: 'Lorem Ipsum',
      url: 'www.url.com',
    },
    {
      title: 'Lorem Ipsum',
      alias: 'sales@xyz.com',
      // tslint:disable-next-line: max-line-length
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum repudiandae nisi animi quis quibusdam unde doloremque ab itaque nostrum, eum eius voluptatem dignissimos consequuntur vitae reiciendis obcaecati non nihil dolorum?',
      tag: 'Lorem Ipsum',
      url: 'www.url.com',
    },
    {
      title: 'Lorem Ipsum',
      alias: 'sales@xyz.com',
      // tslint:disable-next-line: max-line-length
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum repudiandae nisi animi quis quibusdam unde doloremque ab itaque nostrum, eum eius voluptatem dignissimos consequuntur vitae reiciendis obcaecati non nihil dolorum?',
      tag: 'Lorem Ipsum',
      url: 'www.url.com',
    },
  ],
});
