// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lfx-compare-by-dropdown',
  templateUrl: './compare-by-dropdown.component.html',
  styleUrls: ['./compare-by-dropdown.component.scss'],
})
export class CompareByDropdownComponent implements OnInit, OnChanges {
  @Input() nameOfSegment: '';
  @Input() dropdownData: { value: string; name: string }[] = [
    { value: 'similarSize', name: 'Organizations with similar # of employees' },
    {
      value: 'sponsoredSameTier',
      name: 'Organizations at the same event sponsorship level',
    },
    {
      value: 'similarIndustry',
      name: 'Organizations in similar industry segment',
    },
  ];

  @Input() selectedOptions = this.dropdownData[0].value;
  @Output() selectedOptionsChange = new EventEmitter<any>();
  @Input() placeholderText = '';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.nameOfSegment &&
      changes.nameOfSegment.currentValue
    ) {
      this.dropdownData[2].name = `Organizations in similar industry segment - ${this.nameOfSegment}`;
    }
  }

  onChangeOptions() {
    this.selectedOptionsChange.emit(this.selectedOptions);
  }
}
