// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  BaseProjectContributionInput,
  CodeContributionsResult,
  EngagementSummaryResult,
} from '@models';
import { Observable, combineLatest } from 'rxjs';
import { NewDashboardServiceGql } from '../gql/services/new-dashboard.service.gql';
import { DashboardEventsData } from '@lfx/config';
import { CompanyService } from './company.service';
import { map } from 'rxjs/operators';
import { MembershipService } from './membership.service';

@Injectable({
  providedIn: 'root',
})
export class NewDashboardService {
  constructor(
    private newDashboardServiceGql: NewDashboardServiceGql,
    private companyService: CompanyService,
    private memberService: MembershipService
  ) {}

  getCodeContributions(
    input: BaseProjectContributionInput
  ): Observable<CodeContributionsResult> {
    return this.newDashboardServiceGql.getCodeContributions(input);
  }

  getEvents(
    companyId: string,
    salesforceB2BAccountId: string
  ): Observable<DashboardEventsData> {
    const dateRange = `${new Date().getFullYear()}`;

    return combineLatest([
      this.companyService.getCompanyViewsEventsSummary(companyId, dateRange),
      this.companyService.getOrganizationTravelFundEvents(
        companyId,
        10,
        'eventyear',
        'desc',
        0,
        dateRange
      ),
      this.memberService.listMemberSponsorships(salesforceB2BAccountId, {
        pageSize: 10,
        offset: 0,
        orderBy: 'closedate',
        orderType: 'Desc',
        year: dateRange,
      }),
      this.newDashboardServiceGql.nextEvents({ organizationId: companyId }),
    ]).pipe(
      map(([summary, travelFund, sponsorships, nextEventsResult]) => ({
        attendees: summary.myOrgTotalAttendees,
        speakers: summary.myOrgTotalSpeakers,
        funding: Math.round(travelFund.totalEstimatedTravelFund),
        sponsorships: sponsorships?.metadata?.totalSize || 0,
        audienceReach: summary.totalAudienceReached,
        nextEvents: nextEventsResult.nextEvents?.map(
          ({
            eventName,
            attendees,
            audience,
            startDate,
            eventCity,
            eventCountry,
            speakers,
            sponsorships,
          }) => ({
            name: eventName,
            attendees,
            audienceReach: audience,
            date: startDate,
            location: `${eventCity ? eventCity : ''}${
              eventCity && eventCountry ? ',' : ''
            } ${eventCountry ? eventCountry : ''}`,
            speakers,
            sponsorship: sponsorships,
          })
        ),
      }))
    );
  }

  getEngagementSummary(
    organizationId: string,
    projectId: string,
    foundationId: string,
    isAllOrgs: boolean
  ): Observable<EngagementSummaryResult> {
    return this.newDashboardServiceGql.engagementSummary(
      organizationId,
      projectId,
      foundationId,
      isAllOrgs
    );
  }
}
