// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-date-range-toggle-navbar',
  templateUrl: './date-range-toggle-navbar.component.html',
  styleUrls: ['./date-range-toggle-navbar.component.scss'],
})
export class DateRangeToggleNavbarComponent implements OnInit {
  @Input() listItems: string[] = generalConstants.dateList;
  @Output() dateEvent = new EventEmitter<string>();
  selectedItem = this.listItems[0];

  constructor() {}

  ngOnInit() {}

  onSelected(item: string) {
    this.selectedItem = item;
    this.dateEvent.emit(item);
  }
}
