// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FoundationProject } from '@models';
import { generalConstants } from '@config';

@Component({
  selector: 'lfx-mini-project-card',
  templateUrl: './mini-project-card.component.html',
  styleUrls: ['./mini-project-card.component.scss'],
})
export class MiniProjectCardComponent implements OnInit {
  @Input() project: FoundationProject;
  @Input() buttonName: string;
  @Output() buttonClick = new EventEmitter<void>();
  defaultIcon = generalConstants.projectDefaultIcon;

  constructor() {}

  ngOnInit() {}

  onButtonClick() {
    this.buttonClick.emit();
  }
}
