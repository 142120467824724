// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface EditOrgUserAccessInput {
  currentAccess: string;
  changeTo: string;
  organizationId: string;
  username: string;
  removeAllAccess?: boolean;
  orgUserId: string;
  userId: string;
}
