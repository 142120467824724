// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lfx-events-projects-dropdown',
  templateUrl: './events-projects-dropdown.component.html',
  styleUrls: ['./events-projects-dropdown.component.scss'],
})
export class EventsProjectsDropdownComponent implements OnInit, OnChanges {
  @Input() projects = [];
  @Input() errorMessage = '';
  @Input() projectsLoading = false;
  @Input() salesforceB2BAccountId: string;
  @Input() selectedProject: any;

  @Output() selectedRowEmitter = new EventEmitter<any>();
  filteredProjects = [];
  allFoundationsSelected = true;
  filterByProjectPopover;
  childrenFilterValue = '';

  constructor(private spinner: NgxSpinnerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.projectsLoading && this.projectsLoading) {
      this.spinner.show('projects-dropdown-loading');
    } else {
      this.spinner.hide('projects-dropdown-loading');
    }

    if (changes?.projects) {
      this.filteredProjects = this.projects;
    }
  }

  ngOnInit(): void {
    this.filteredProjects = this.projects;
  }

  openSearchProjectsPopover(filterByProjectPopover) {
    this.filterByProjectPopover = filterByProjectPopover;
  }

  onSearchChange(searchValue: string) {
    if (searchValue) {
      this.childrenFilterValue = searchValue;
      this.filteredProjects = this.projects.filter(
        project =>
          project.projectName
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          project.children.some(child =>
            child.projectName.toLowerCase().includes(searchValue.toLowerCase())
          )
      );
    } else {
      this.childrenFilterValue = '';
      this.filteredProjects = this.projects;
    }
  }

  onRowClick(rowData) {
    this.selectedProject = rowData;
    this.allFoundationsSelected = false;
    this.selectedRowEmitter.emit(rowData);

    if (this.filterByProjectPopover) {
      this.filterByProjectPopover.close();
    }
    this.onSearchChange('');
  }

  getAllFoundationsStats() {
    this.allFoundationsSelected = true;
    this.selectedProject = null;
    this.selectedRowEmitter.emit(null);

    if (this.filterByProjectPopover) {
      this.filterByProjectPopover.close();
    }
    this.onSearchChange('');
  }

  toggleExpandProject(project, event) {
    project.expanded = !project.expanded;
    event.stopPropagation();
  }
}
