// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface Chip {
  name: string;
  id: string;
}

@Component({
  selector: 'lfx-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FilterChipsComponent,
    },
  ],
})
export class FilterChipsComponent implements OnInit, ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (chip: Chip) => {};

  onTouched = () => {};
  public selectedValue: any;
  @Input() chips: Chip[] = [];

  constructor() {}

  ngOnInit(): void {
    // if (!this.selectedValue && this.chips && this.chips.length) {
    //   this.selectedValue = this.chips[0];
    // }
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setSelected(chip: Chip) {
    this.selectedValue = chip;
    this.onChange(chip);
  }
}
