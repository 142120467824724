// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lfx-insights-statistics-box',
  templateUrl: './insights-statistics-box.component.html',
  styleUrls: ['./insights-statistics-box.component.scss'],
})
export class InsightsStatisticsBoxComponent implements OnInit {
  @Input() title = '';
  @Input() count = '';
  @Input() growth;

  constructor() {}

  ngOnInit() {}
}
