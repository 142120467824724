// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dash',
})
export class DashPipe implements PipeTransform {
  transform(value: string, ...checks: string[]): string {
    if (!value || checks.includes(value.toLocaleLowerCase())) {
      return '-';
    } else {
      return value || '';
    }
  }
}
