// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lfx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  @Input() items: string[];

  constructor() {}

  ngOnInit() {}
}
