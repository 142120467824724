// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { HelpMenuComponent } from './components/help-menu/help-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrendModule } from 'ngx-trend';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './containers/app.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { LinkyModule } from 'ngx-linky';
import { SharedModule } from '@lfx/shared/shared.module';
import { HelpSupportFrameComponent } from './components/help-support-frame/help-support-frame.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UnauthorizedComponent } from './containers/unauthorized/unauthorized.component';
import { NotSupportedViewComponent } from './containers/not-supported-view/not-supported-view.component';
import { ToastComponent } from './components/toast/toast.component';
import { NgHttpCachingModule } from 'ng-http-caching';

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    NotFoundComponent,
    HelpSupportFrameComponent,
    ForbiddenComponent,
    HelpMenuComponent,
    NotSupportedViewComponent,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    BrowserAnimationsModule,
    NgbModule,
    TrendModule,
    LinkyModule,
    HttpClientModule,
    NgHttpCachingModule,
    NgSelectModule,
  ],
  exports: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    ForbiddenComponent,
    NotSupportedViewComponent,
    ToastComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
