// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Foundation } from '@models';
import { MutationPayload } from './mutation-payload';

export interface UpdateFoundationEssentialsResult {
  updateFoundationEssentials: UpdateFoundationPayload;
}

export interface UpdateFoundationPayload extends MutationPayload {
  currentFoundation?: Foundation;
}
