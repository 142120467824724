<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
  <ng-select
    #select
    [items]="dropdownData"
    bindLabel="name"
    bindValue="id"
    [searchable]="false"
    class="compare-by-select"
    [(ngModel)]="selectedOptions"
    (change)="onChangeOptions($event)"
    [clearable]="false"
  >
    <ng-template ng-header-tmp>
      <div class="search-container">
        <span class="fa fa-search cursor-pointer"></span>
        <input class="form-control search-text" placeholder="Search" type="text" (input)="select.filter($event.target.value)"/>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div *ngIf="item.id === 'all'" id="item-all-projects" class="list-item select-all">{{item.name}}</div>
      <div *ngIf="item.id !== 'all'" id="item-{{index}}" class="list-item d-flex align-items-center justify-content-center" >
        <img
          [src]="item.logoUrl"
          *ngIf="item.logoUrl"
          [ngbTooltip]="item.name"
          />
        <span class="item-text" *ngIf="item.name && !item.logoUrl" [title]="item.name">{{item.name}}</span>
      </div>
      <div *ngIf="item.id !== 'all' &&(index === (select.viewPortItems.length === 1 && select.viewPortItems[0].index))" class="item-tooltip-space"></div>
    </ng-template>
  </ng-select>
