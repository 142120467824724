// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { FormControl } from '@angular/forms';

export const orgDomainAliasValidator =
  (domainAlias: string) => (control: FormControl) => {
    if (!control.value) {
      return null;
    }

    const domain = (control.value || '').substring(
      (control.value || '').indexOf('@') + 1
    );
    const isDomainAlias = !!(
      domain &&
      domainAlias
        .split(',')
        .find(alias => domain.toLowerCase() === alias.toLowerCase())
    );

    return isDomainAlias ? null : { orgDomainAlias: true };
  };
