// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface VulnerabilityDependencyRepositoryDetails {
  dependencyId: string;
  dependencyName: string;
  dependencyVersion: string;
  licenses: string[];
  repositoryId: string;
  repositoryName: string;
  issueHighCount: number;
  issueMediumCount: number;
  issueLowCount: number;
  issueTotalCount: number;
  rootDependency: boolean;
}
