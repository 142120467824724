<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="bar-progress-share">
  <div
    *ngFor="let section of sections; let i = index"
    class="section-container"
    [ngbPopover]="popContent"
    triggers="mouseenter:mouseleave"
    placement="top"
    popoverClass="percentage-tooltip-popover"
    [ngStyle]="{
      width: section.percent + '%',
      backgroundColor: section.bgColor,
      height: height + 'px'
    }"
    [class.has-border-radius]="hasBorderRadius"
  >
  <ng-template #popContent>
    <div>
      <span class="dot" [ngStyle]="{ backgroundColor: section.bgColor }"></span> {{ section.name }} - <span class="text-bold">{{ section.percent }}%</span> ({{ section.value }})
    </div>
  </ng-template>
    <span *ngIf="section.percent && showSectionText" class="section-text">
      {{ section.name }}
    </span>
  </div>
</div>
