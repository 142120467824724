// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lfx-lf-box',
  templateUrl: './lf-box.component.html',
  styleUrls: ['./lf-box.component.scss'],
})
export class LfBoxComponent implements OnInit, OnChanges {
  @Input() sectionTitle = '';
  @Input() subtitle: string;
  @Input() linkText: string;
  @Input() spinnerName: string;
  @Input() isLoading = false;
  @Input() tooltipText = '';
  @Input() version = 1;
  @Input() greyBackground = false;
  @Input() externalLink = {
    text: '',
    url: '',
  };

  @Output() linkTextClickEmitter = new EventEmitter<void>();

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isLoading && this.isLoading) {
      this.spinner.show(this.getSpinnerName());
    } else {
      this.spinner.hide(this.getSpinnerName());
    }
  }

  getSpinnerName() {
    return `${
      this.spinnerName || this.sectionTitle.split(' ').join('-')
    }-spinner`;
  }
}
