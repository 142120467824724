// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
'use strict';

export const foundationMenus = [
  'dashboard',
  'statistics',
  'security',
  'cla',
  'events',
  'training',
  'certification',
];
