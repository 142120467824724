// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lfx-status-dot',
  templateUrl: './status-dot.component.html',
  styleUrls: ['./status-dot.component.scss'],
})
export class StatusDotComponent {
  @Input() status: string;
  @Input() bold = false;
  @Input() colorMap: { [key: string]: string } = {
    Active: 'green',
    None: 'red',
    Non: 'red',
    Expired: 'red',
    'Expiring Soon': 'orange',
    Archived: 'gray',
  };

  get statusColor(): string {
    const normalizedStatus = this.status?.toLowerCase();

    for (const [key, value] of Object.entries(this.colorMap)) {
      if (key.toLowerCase() === normalizedStatus) {
        return value;
      }
    }

    return 'red';
  }
}
