<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ol class="breadcrumb">
  <li
    *ngFor="let item of items; let i = index"
    class="breadcrumb-item"
    [ngClass]="{ 'active': i == items.length - 1 }"
  >
    <a>{{ item }}</a>
  </li>
</ol>
