// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform, Inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'urlFormat',
})
export class UrlFormatPipe implements PipeTransform {
  constructor(@Inject(DomSanitizer) private domSanitizer: DomSanitizer) {}

  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Check for potentially malicious protocols
    const lowercaseUrl = value.toLowerCase().trim();

    if (
      lowercaseUrl.startsWith('javascript:') ||
      lowercaseUrl.startsWith('javascript&') ||
      lowercaseUrl.startsWith('data:') ||
      lowercaseUrl.startsWith('vbscript:')
    ) {
      return '';
    }

    try {
      // Add https if no protocol is specified
      if (
        !lowercaseUrl.startsWith('https://') &&
        !lowercaseUrl.startsWith('http://')
      ) {
        value = 'https://' + value;
      }

      // Validate URL structure
      new URL(value);

      return this.domSanitizer.sanitize(SecurityContext.URL, value) || '';
    } catch (e) {
      return '';
    }
  }
}
