// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { ProjectContribution } from './project-contribution';
import { ProjectLeader } from './project-leader';
import { DeveloperContribution } from './developer-contribution';

export interface MemberCollaboration {
  projectContributions: ProjectContribution[];
  projectLeadership: ProjectLeader[];
  developerContributions: DeveloperContribution[];
}
