<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div >
  <a *ngFor="let item of items | slice: 0:limit" class="inline" [ngClass]="{'disabled-pointer': !getWebsite(item)}" target="_blank" href="{{ getWebsite(item) }}">
    <img class="rounded-circle thumb24 logo-circle" src="{{ getLogo(item) }}"  />
  </a>
  <p *ngIf="items?.length > limit" class="inline m-0">
    <span class="btn btn-default btn-icon btn-circle btn-sm">
      +{{ items?.length - limit }}
    </span>
  </p>
</div>