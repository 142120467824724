// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { BaseListOffcanvasDirective } from '../base-list-offcanvas/base-list-offcanvas.directive';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService, NewDashboardService } from '@lfx/core/services';
import { Column } from '@lfx/shared/interfaces';
import { MailingListSubscriber } from '@lfx/core/models';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lfx-mailing-list-subscribers-offcanvas',
  templateUrl: './mailing-list-subscribers-offcanvas.component.html',
  styleUrls: [
    '../base-list-offcanvas/base-list-offcanvas.component.scss',
    './mailing-list-subscribers-offcanvas.component.scss',
  ],
})
export class MailingListSubscribersOffcanvasComponent
  extends BaseListOffcanvasDirective
  implements OnInit
{
  constructor(
    activeOffcanvas: NgbActiveOffcanvas,
    offcanvasService: NgbOffcanvas,
    companyService: CompanyService,
    spinner: NgxSpinnerService,
    private newDashboardService: NewDashboardService
  ) {
    super(activeOffcanvas, offcanvasService, companyService, spinner);
  }

  public projectId: string;
  emptyDataMessage = 'No subscribers found';

  ngOnInit() {
    this.columns = this.getColumns();
    this.fetchData();
  }

  protected getColumns(): Column[] {
    return [
      {
        name: '',
        prop: 'index',
        style: {
          textAlign: 'center',
        },
      },
      {
        name: 'Name',
        prop: 'name',
        sortable: true,
        sortDir: 'asc',
        template: this.nameColumn,
      },
      {
        name: 'Project',
        prop: 'project',
      },
    ];
  }

  protected fetchData() {
    this.setIsLoading(true);
    this.newDashboardService
      .getMailingListSubscribers(
        this.listInfo.companyId,
        this.companyService.isAllOrgsRollup(),
        this.projectId
      )
      .subscribe({
        next: response => {
          this.data = this.transformData(response);
          this.searchData = this.data;
          this.onPageChange(1);
          this.setIsLoading(false);
        },
        error: error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching mailing list subscribers:', error);
          this.setIsLoading(false);
          this.emptyDataMessage = 'Error fetching subscribers';
        },
      });
  }

  protected getCSVData() {
    return this.data.map(row => ({
      Name: row.name.text,
      Project: row.project.text,
    }));
  }

  protected getListType(): string {
    return 'Mailing List Subscribers';
  }

  private transformData(mailingListSubscribers: MailingListSubscriber[]) {
    return mailingListSubscribers.map((subscriber, index) => ({
      index: this.formatText(`${index + 1}`),
      name: this.formatText(subscriber.memberDisplayName, null, null, null, {
        imageUrl: subscriber.userPhotoUrl,
        userId: subscriber.memberId,
        organizationName: subscriber.accountName,
      }),

      project: this.formatText(subscriber.projectName),
    }));
  }
}
