// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { CompanyService } from '@services';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { generalConstants } from '@lfx/config';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lfx-employee-insights-details-dialog',
  templateUrl: './employee-insights-details-dialog.component.html',
  styleUrls: ['./employee-insights-details-dialog.component.scss'],
})
export class EmployeeInsightsDetailsDialogComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() company;
  @Input() dateRange;
  @Input() employee: {
    id: string;
    firstName: string;
    lastName: string;
    logo: string;
    email: string;
    learner: {
      id: string;
      photoUrl: string;
      firstName: string;
      lastName: string;
    };
    name: {
      image: string;
      text: string;
    };
  };

  @Input() detailsList: {
    name: string;
  }[];

  @Input() pageType;
  @Output() closeDialog = new EventEmitter();
  dataSubscription = new Subscription();
  slicedList = [];
  detailsCascadeTableData = [];
  detailsCascadeTableColumns = [];
  paginationSize = 5;
  paginationPage = 1;
  paginationTotal = 0;
  paginationMaxSize = 5;
  paginationOffset: number = (this.paginationPage - 1) * this.paginationSize;
  myOrgSpeakers = generalConstants.myOrgSpeakers;
  allOrgsSpeakers = generalConstants.allOrgsSpeakers;
  myOrgAttendees = generalConstants.myOrgAttendees;
  tncLearners = generalConstants.tncLearners;
  certifiedEmployees = generalConstants.certifiedEmployees;
  isLoading = false;

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageType) {
      this.initializeTableColumns();

      if (
        this.pageType === generalConstants.tncLearners ||
        this.pageType === generalConstants.certifiedEmployees
      ) {
        this.loadData();
      }
    }

    if (changes.detailsList && this.detailsList) {
      this.paginationTotal = this.detailsList.length;
      this.onPageChange(1);
    }
  }

  ngOnInit() {}

  initializeTableColumns() {
    if (this.pageType === generalConstants.myOrgSpeakers) {
      this.initializeMyOrgSpeakersColumns();

      return;
    }

    if (this.pageType === generalConstants.allOrgsSpeakers) {
      this.initializeAllOrgsSpeakersColumns();

      return;
    }

    if (this.pageType === generalConstants.myOrgAttendees) {
      this.initializeAttendeesColumns();

      return;
    }

    if (this.pageType === generalConstants.tncLearners) {
      this.initializeLearnersColumns();

      return;
    }

    if (this.pageType === generalConstants.certifiedEmployees) {
      this.initializeCertifiedEmployeesColumns();

      return;
    }
  }

  initializeMyOrgSpeakersColumns() {
    this.detailsCascadeTableColumns = [
      {
        name: 'Event Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 225,
        dataFontSize: '13px',
      },
    ];
  }

  initializeAllOrgsSpeakersColumns() {
    this.detailsCascadeTableColumns = [
      {
        name: 'Event Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 225,
        dataFontSize: '13px',
      },
    ];
  }

  initializeAttendeesColumns() {
    this.detailsCascadeTableColumns = [
      {
        name: 'Event Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 25,
        dataFontSize: '13px',
      },
      {
        name: 'Date',
        prop: 'date',
        flex: 1,
        dataFontSize: '12px',
      },
    ];
  }

  initializeLearnersColumns() {
    this.detailsCascadeTableColumns = [
      {
        name: 'Training Program Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 25,
        dataFontSize: '13px',
      },
      {
        name: 'Date',
        prop: 'date',
        flex: 1,
        dataFontSize: '12px',
      },
    ];
  }

  initializeCertifiedEmployeesColumns() {
    this.detailsCascadeTableColumns = [
      {
        name: 'Certification Name',
        prop: 'name',
        flex: 2,
        textEllipsis: 25,
        dataFontSize: '13px',
      },
      {
        name: 'Date',
        prop: 'date',
        flex: 1,
        dataFontSize: '12px',
      },
    ];
  }

  loadData() {
    this.spinner.show('insights-details-dialog-spinner');
    this.isLoading = true;

    this.dataSubscription = this.companyService
      .getTrainingAndCertificationEnrollmentsAndExams(
        this.company.id,
        this.dateRange,
        200,
        this.pageType === generalConstants.tncLearners
          ? 'EnrollmentDate'
          : 'CertificationAchievementDate',
        'desc',
        0,
        this.pageType === generalConstants.tncLearners
          ? 'User.ID eq ' + this.employee.learner.id
          : 'User.ID eq ' + this.employee.id,
        this.pageType === generalConstants.tncLearners
          ? 'EnrollmentDate'
          : 'CertAchievementDate'
      )
      .subscribe(response => {
        this.spinner.hide('insights-details-dialog-spinner');
        this.isLoading = false;

        if (response && response.data && response.data.length > 0) {
          this.detailsList =
            this.pageType === generalConstants.tncLearners
              ? this.filterOutCertifications(response.data)
              : this.filterOutNonCertifications(response.data);
          this.paginationTotal = this.detailsList.length;
          this.onPageChange(1);
        }
      });
  }

  onPageChange(event) {
    this.slicedList = [];
    this.paginationPage = event;
    this.paginationOffset = (this.paginationPage - 1) * this.paginationSize;
    this.slicedList = this.detailsList.slice(
      this.paginationOffset,
      this.paginationOffset + 5
    );
    this.handleData();
  }

  handleData() {
    this.detailsCascadeTableData = [];

    if (this.pageType === generalConstants.myOrgSpeakers) {
      this.handleMyOrgSpeakersData();

      return;
    }

    if (this.pageType === generalConstants.allOrgsSpeakers) {
      this.handleAllOrgsSpeakersData();

      return;
    }

    if (this.pageType === generalConstants.myOrgAttendees) {
      this.handleMyOrgAttendeesData();

      return;
    }

    if (this.pageType === generalConstants.tncLearners) {
      this.handleTncLearnersData();

      return;
    }

    if (this.pageType === generalConstants.certifiedEmployees) {
      this.handleCertifiedEmployeesData();

      return;
    }
  }

  handleMyOrgSpeakersData() {
    for (const record of this.slicedList) {
      this.detailsCascadeTableData.push({
        name: this.formatMemberNameWithSubtext(
          record.name,
          new Date(record.startDate).toDateString()
        ),
      });
    }
  }

  handleAllOrgsSpeakersData() {
    for (const record of this.slicedList) {
      this.detailsCascadeTableData.push({
        name: this.formatMemberNameWithSubtext(
          record.name,
          new Date(record.startDate).toDateString()
        ),
      });
    }
  }

  handleMyOrgAttendeesData() {
    for (const record of this.slicedList) {
      this.detailsCascadeTableData.push({
        name: this.formatMemberName(record.name),
        date: record.startDate,
      });
    }
  }

  handleTncLearnersData() {
    for (const record of this.slicedList) {
      this.detailsCascadeTableData.push({
        name: this.formatMemberName(record.product.name),
        date: new Date(
          this.convertDateToTargetFormat(record.enrollmentDate)
        ).toDateString(),
      });
    }
  }

  handleCertifiedEmployeesData() {
    for (const record of this.slicedList) {
      this.detailsCascadeTableData.push({
        name: this.formatMemberName(record.product.name),
        date: new Date(
          this.convertDateToTargetFormat(record.certificationAchievementDate)
        ).toDateString(),
      });
    }
  }

  formatMemberName(text) {
    return {
      text,
      color: 'black',
      bold: true,
    };
  }

  formatMemberNameWithSubtext(text, subtext) {
    return {
      text,
      color: 'black',
      bold: true,
      subtext,
    };
  }

  dismiss() {
    this.closeDialog.emit();
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  private filterOutCertifications(data) {
    return data.filter(value => value.product.type !== 'Certification');
  }

  private filterOutNonCertifications(data) {
    return data.filter(value => value.product.type === 'Certification');
  }

  private convertDateToTargetFormat(date: string): string {
    const dateObj: Date = new Date(date);
    const targetFormat: string = dateObj.toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    });

    return targetFormat;
  }
}
