// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column, DataRow } from '@lfx/shared/interfaces';

@Component({
  selector: 'lfx-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: ['./new-table.component.scss'],
})
export class NewTableComponent implements OnInit {
  @Input() columns: Column[] = [];
  @Input() data: DataRow[] = [];
  @Input() emptyDataMessage = 'No Data';
  @Input() freezeHeader = false;
  @Input() rowsMaxHeight = '300px';
  @Input() striped = true;
  @Input() version = 1;
  @Input() selectable = false;
  @Input() showGroupedRows = false;
  @Input() groupedData: {
    label: string;
    expanded: boolean;
    data: DataRow[];
  }[] = [];

  @Output() columnTextClickAction = new EventEmitter<{
    row: DataRow;
    column: Column;
  }>();

  @Output() columnSortEmmitter = new EventEmitter<Column>();
  @Output() selectedRowEmitter = new EventEmitter<DataRow>();
  @Output() infoIconClickEmitter = new EventEmitter<Column>();

  selectedIndex = -1;

  constructor() {}

  ngOnInit(): void {}

  handleColumnTextClick(item) {
    this.columnTextClickAction.emit(item);
  }

  toggleSortOrder(column: Column) {
    if (column.sortDir === 'asc') {
      this.columnSortEmmitter.emit({ ...column, sortDir: 'desc' });

      return 'desc';
    } else {
      this.columnSortEmmitter.emit({ ...column, sortDir: 'asc' });

      return 'asc';
    }
  }

  handleRowClick(rowData, index) {
    if (this.selectable) {
      this.selectedIndex = index;
      this.selectedRowEmitter.emit(rowData);
    }
  }
}
