// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import am5themes_Micro from '@amcharts/amcharts5/themes/Micro';
import { Percent } from '@amcharts/amcharts5';
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';
import {
  BarChartConfig,
  ColumnSeriesSettings,
  GranularityEnum,
  createLineSeries,
  createSeriesTarget,
  createYAxis,
  tooltipObj,
} from '../am-chart-util';

@Component({
  selector: 'lfx-am-bar-chart',
  templateUrl: './am-bar-chart.component.html',
  styleUrls: ['./am-bar-chart.component.scss'],
})
export class AMBarChartComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() public config!: BarChartConfig;
  @Input() public chartName!: string;
  // Changing this to an input property instead of a private one
  // some charts like the Average Wait Time needs to pull both previous and current period to complete this chart
  // See BarChartConfig for changes that will enable this component to function differently
  @Input() public data: any[] = [];

  private root!: am5.Root;
  private chartRef!: am5xy.XYChart;

  public constructor() {}

  public ngOnInit(): void {
    if (!this.config.type) {
      this.config.type = 'normal';
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data) {
      this.changeData();
    }
  }

  public ngAfterViewInit() {
    this.initChart();
  }

  public ngOnDestroy() {
    // Clean up chart when the component is removed
    if (this.root) {
      this.root.dispose();
    }
  }

  public initChart() {
    if (this.root) {
      this.root.dispose();
    }

    am5.addLicense('AM5C454672478');
    const root = am5.Root.new(this.chartName);
    const myTheme = am5.Theme.new(root);

    root.locale.firstDayOfWeek = 1;

    // hiding all the grid lines by default
    myTheme.rule('Grid').setAll({
      strokeOpacity: 0,
    });

    root.setThemes([myTheme]);

    if (this.config.useMicroTheme) {
      root.setThemes([am5themes_Micro.new(root)]);
    }

    // remove footer logo
    // eslint-disable-next-line no-underscore-dangle
    root._logo?.children.clear();

    let padding = false;

    if (this.config.applyPadding) {
      padding = this.config.applyPadding;
    }
    const chart = root.container.children.push(
      this.createChartWithTopPadding(root, padding)
    );
    const granularity = this.getGranularity();
    let xAxis;
    let yAxis;

    if (this.config.direction === 'horizontal') {
      xAxis = createYAxis(
        root,
        chart,
        { max: this.config.max, label: this.config.yAxisName },
        undefined,
        undefined,
        undefined,
        this.config
      );

      yAxis = this.createXAxis(root, chart, granularity);
    } else {
      xAxis = this.createXAxis(root, chart, granularity);

      yAxis = createYAxis(
        root,
        chart,
        { max: this.config.max, label: this.config.yAxisName },
        undefined,
        undefined,
        undefined,
        this.config
      );
    }

    if (this.config.useMicroTheme) {
      chart.plotContainer.set('wheelable', false);
      chart.zoomOutButton.set('forceHidden', true);
    }
    // Create series
    this.config.series.forEach((seriesSettings: ColumnSeriesSettings) => {
      if (seriesSettings.plotAsLineChart) {
        const totalYAxis = createYAxis(
          root,
          chart,
          { max: this.config.max, label: this.config.yAxisName },
          undefined,
          true,
          undefined
        );
        const lineXAxis: any = xAxis;
        const lineSeriesSettings: any = {
          ...seriesSettings,
          field: seriesSettings.valueYField,
        };

        if (seriesSettings.yAxisTitle) {
          this.addLeftYAxisLabel(root, totalYAxis, seriesSettings.yAxisTitle);
        }
        createLineSeries(this)(
          root,
          chart,
          lineXAxis,
          totalYAxis,
          lineSeriesSettings,
          granularity
        );
      } else {
        if (seriesSettings.yAxisTitle) {
          this.addRightYAxisLabel(root, yAxis, seriesSettings.yAxisTitle);
        }
        this.createSeries(
          root,
          chart,
          xAxis,
          yAxis,
          seriesSettings,
          granularity
        );
      }
    });

    // Add cursor
    chart.set('cursor', am5xy.XYCursor.new(root, {}));
    this.chartRef = chart;

    this.root = root;
  }

  public createChartWithTopPadding(root: am5.Root, applyPadding: boolean) {
    if (applyPadding) {
      return am5xy.XYChart.new(root, {
        panY: false,
        layout: root.horizontalLayout,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: this.config.applyPadding ? 10 : 0,
        paddingTop: this.config.applyPadding ? 25 : 0,
        maxTooltipDistance: -1,
      });
    }

    return am5xy.XYChart.new(root, {
      panY: false,
      layout: root.horizontalLayout,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      maxTooltipDistance: -1,
    });
  }

  public addLeftYAxisLabel(root: any, yAxis: any, label: string) {
    let x = -20;

    if (label.length > 10) {
      x = -70;
    }
    const yAxisLabel = am5.Label.new(root, {
      rotation: 0,
      text: label,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      fill: am5.color('#807f83'),
      x,
      y: -25,
      paddingTop: 0,
    });

    yAxis.children.push(yAxisLabel);
  }

  public addRightYAxisLabel(root: any, yAxis: any, label: string) {
    let x = -5;

    if (label.length > 3) {
      x = -15;
    }
    const yAxisLabel = am5.Label.new(root, {
      rotation: 0,
      text: label,
      position: 'absolute',
      fontSize: 12,
      fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
      fill: am5.color('#807f83'),
      x,
      y: -25,
      paddingTop: 0,
    });

    yAxis.children.push(yAxisLabel);
  }

  public onToggleSeries(index: number): void {
    if (this.chartRef) {
      const series = this.chartRef.series.getIndex(index);

      if (series) {
        this.toggleSeries(series);
      }
    }
  }

  public isVisible(index: number): boolean | undefined {
    if (this.chartRef) {
      const series = this.chartRef.series.getIndex(index);

      if (series) {
        return series.get('visible');
      }

      return true;
    }

    return true;
  }

  private toggleSeries(series: am5xy.XYSeries) {
    if (series.get('visible')) {
      series.hide();
    } else {
      series.show();
    }
  }

  private changeData(): void {
    if (this.chartRef) {
      const granularity = this.getGranularity();

      this.chartRef.xAxes.each(axis => {
        if (this.config.xAxis?.axisType !== 'category') {
          (axis as am5xy.DateAxis<am5xy.AxisRenderer>).set('baseInterval', {
            timeUnit: granularity as TimeUnit,
            count: 1,
          });
        }
        axis.data.setAll([]);
        axis.data.setAll(this.data);
      });
      this.chartRef.series.each((se: am5xy.XYSeries) => {
        // createTooltip(this.chartRef.root, this.chartRef, se, granularity);
        se.data.setAll(this.data);
        se.data.setAll(this.data);
      });
    }
  }

  private getGranularity() {
    let granularity = GranularityEnum.week;

    if (this.config.xAxis?.granularity) {
      granularity = this.config.xAxis?.granularity;
    }

    return granularity;
  }

  private createSeries(
    root: am5.Root,
    chart: am5xy.XYChart,
    xAxis:
      | am5xy.CategoryAxis<am5xy.AxisRenderer>
      | am5xy.DateAxis<am5xy.AxisRenderer>
      | am5xy.ValueAxis<am5xy.AxisRenderer>,
    yAxis:
      | am5xy.CategoryAxis<am5xy.AxisRenderer>
      | am5xy.DateAxis<am5xy.AxisRenderer>
      | am5xy.ValueAxis<am5xy.AxisRenderer>,
    s: ColumnSeriesSettings,
    granularity: GranularityEnum
  ): void {
    let columnSeries;

    if (this.config.direction === 'horizontal') {
      columnSeries = am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        categoryYField: s.valueYField,
        ...s,
        fill: s.color ? am5.color(s.color) : undefined,
        stacked: this.config.type === 'stacked',
        userData: s.legendTooltipText,
      });
    } else {
      columnSeries = am5xy.ColumnSeries.new(root, {
        xAxis,
        yAxis,
        categoryXField: s.valueXField,
        ...s,
        fill: s.color ? am5.color(s.color) : undefined,
        stacked: this.config.type === 'stacked',
        userData: s.legendTooltipText,
      });
    }
    const series = chart.series.push(columnSeries);

    series.data.setAll([]);
    series.data.setAll(this.data);

    series.columns.template.setAll({
      // making the barWidth property override this "responsive" width code
      width:
        this.config.barWidth ||
        (this.config.type === 'normal'
          ? this.data.length > 7
            ? new Percent(70)
            : new Percent(40)
          : new Percent(40)),
      tooltip: tooltipObj(
        root,
        chart,
        granularity,
        this.config.xAxis.axisType,
        this.config.xAxis.field,
        this.config.customTooltipTextAdapter,
        this.config.customAdapter,
        this.config
      ),
      tooltipText:
        this.config.customAdapter === 'html' ? undefined : '{valueY}',
      tooltipHTML:
        this.config.customAdapter === 'html'
          ? '<div style="color: #fff">{valueY}</div>'
          : undefined,
      showTooltipOn: 'hover',
    });

    if (this.config.type === 'normal' && this.config.columnsTemplateWidth) {
      series.columns.template.set(
        'width',
        am5.percent(this.config.columnsTemplateWidth)
      );
    }

    if (this.config.targetValue) {
      // adding the dashed line
      createSeriesTarget(
        yAxis as am5xy.ValueAxis<am5xy.AxisRenderer>,
        series,
        this.config.targetValue
      );
    }
  }

  private createXAxis(
    root: am5.Root,
    chart: am5xy.XYChart,
    granularity: GranularityEnum
  ):
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.DateAxis<am5xy.AxisRenderer> {
    const {
      axisType,
      groupData,
      dateFormats,
      cellStartLocation,
      cellEndLocation,
      stroke,
      labelColor,
    } = this.config.xAxis || {};

    let xRenderer;

    if (this.config?.direction === 'horizontal') {
      xRenderer = am5xy.AxisRendererY.new(root, {
        stroke: am5.color(stroke || '#807f83'),
        minGridDistance: 28,
        strokeOpacity: 1,
        strokeWidth: 1,
        cellStartLocation,
        cellEndLocation,
      });
    } else {
      xRenderer = am5xy.AxisRendererX.new(root, {
        stroke: am5.color(stroke || '#807f83'),
        minGridDistance: 28,
        strokeOpacity: 1,
        strokeWidth: 1,
        cellStartLocation,
        cellEndLocation,
      });
    }

    if (this.config.type === 'normal') {
      xRenderer.set('cellStartLocation', cellStartLocation || 0.2);
      xRenderer.set('cellEndLocation', cellEndLocation || 0.8);
    }

    const axis =
      axisType === 'category'
        ? am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: this.config.xAxis.field,
          })
        : am5xy.DateAxis.new(root, {
            renderer: xRenderer,
            maxDeviation: 0,
            markUnitChange: false,
            groupData: groupData || true,
            dateFormats: {
              day: 'MMM\ndd',
              week: 'MMM\ndd',
              month: 'MMMM',
              year: 'yyyy',
              ...(dateFormats || {}),
            },
            baseInterval: {
              timeUnit: granularity as TimeUnit,
              count: 1,
            },
          });
    let xAxis;

    if (this.config.direction === 'horizontal') {
      xAxis = chart.yAxes.push(axis);
    } else {
      xAxis = chart.xAxes.push(axis);
    }

    (xAxis as am5xy.DateAxis<am5xy.AxisRenderer>)
      .get('renderer')
      .labels.template.setAll({
        fill: am5.color(labelColor || '#807f83'),
        fontSize: 12,
        textAlign: 'center',
      });
    xAxis.data.setAll([]);
    xAxis.data.setAll(this.data);
    xAxis.appear(1000);

    return xAxis;
  }
}
