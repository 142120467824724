// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tnCListDetailsQuery = gql`
  query tnCListDetails(
    $organizationId: String!
    $isAllOrgs: Boolean
    $timeRange: String
    $courseGroupId: String
    $page: Int
    $limit: Int
    $searchName: String
    $last12MonthsOnly: Boolean
  ) {
    getTnCListDetails(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
      timeRange: $timeRange
      courseGroupId: $courseGroupId
      page: $page
      limit: $limit
      searchName: $searchName
      last12MonthsOnly: $last12MonthsOnly
    ) {
      organizationLogo
      userFullName
      enrollmentTs
      userPhotoUrl
      accountName
      userId
    }
  }
`;
