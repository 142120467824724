<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="lf-date-range-container">
  <div
    class="lf-date-range-selector"
    *ngFor="let option of datePickerOptions"
    [ngClass]="{
      'lf-active-date-range-selector': option.id === selectedDateRangeId
    }"
    (click)="selectDatePicker(option.id)"
  >
    <span>
      {{ option.name }}
    </span>
  </div>
</div>
