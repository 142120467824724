<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="chart-section d-flex">
  <div class="chart-title-wrapper align-self-stretch d-flex align-items-center">
    <div class="chart-title">
      {{chartTitle}}
    </div>
  </div>
  <div class="chart-container">
    <div echarts [options]="echartsWaterFallOption" class="chart"></div>
  </div>
  <div class="legend-container" *ngIf="_chartOptions && _chartOptions.bar1">
    <div class="legend-item d-flex mb-3" *ngIf="_chartOptions.bar2 ">
      <div class="legend-bullet">
        <span [style.background]="_chartOptions.bar2.color"></span>
      </div>
      <div class="legend-text">
        {{ _chartOptions.bar2.title }}
      </div>
    </div>
    <div class="legend-item d-flex">
      <div class="legend-bullet">
        <span [style.background]="_chartOptions.bar1.color"></span>
      </div>
      <div class="legend-text">
        {{ _chartOptions.bar1.title }}
      </div>
    </div>
  </div>
</div>
