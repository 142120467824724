// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface UserBlock {
  id: string;
  logoUrl?: string;
  name: string;
  email: string;
  roleId?: string;
  scopeId?: string;
  isPendingInvite?: boolean;
}
