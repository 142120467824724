// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface CreateUserRoleScopeInput {
  organizationId: string;
  userEmail: string;
  roleId: string;
  roleName: string;
  firstName: string;
  lastName: string;
  scopeId: string;
  objectName: string;
  objectId: string;
  createNew?: boolean;
}
